import React, { createContext } from 'react';
import { gql, useQuery } from 'urql';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const afclVersionFilter = autodorVersionUserId => ({
  or: [
    { authorId: { equalTo: autodorVersionUserId } },
    { author: { userType: { notEqualTo: 'SYS' } } },
  ],
});

const AutodorVersionContext = createContext(null);

const AutodorVersion = ({ children, loggedIn }) => {
  const [{ fetching, data, error }] = useQuery({
    query: gql`
      query latestAutodorVersionUserId {
        latestAutodorVersionUserId
      }
    `,
    pause: !loggedIn,
  });

  return !loggedIn ? (
    // do nothing if not logged in
    <>{children}</>
  ) : !data ? (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      style={{ width: '100vw', height: '100vh' }}
      delay={1000}
    />
  ) : (
    <AutodorVersionContext.Provider
      value={{
        afclFilter: afclVersionFilter(data.latestAutodorVersionUserId),
        latestAutodorVersionUserId: data.latestAutodorVersionUserId,
      }}
    >
      {children}
    </AutodorVersionContext.Provider>
  );
};

export { AutodorVersion, AutodorVersionContext };
