import { AuditOutlined, IssuesCloseOutlined } from '@ant-design/icons';

/**
 * SubQuery and Rendering definitions for auditProgress Statistics
 */
const auditProgressStatistics = {
  totalAuditFindings: {
    query: `totalAuditFindings: auditFindings(
    filter: {
      and: [
        { batchClaim: $batchClaimFilter }
        { isActive: { equalTo: true } }
      ]
    }
  ) {
   totalCount
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].totalCount;
    },
    isDollar: false,
    description:
      'Total number of Claim Review Findings returned from this Search',
    title: (
      <IssuesCloseOutlined
        style={{
          fontSize: '140%',
        }}
      />
    ),
  },
  totalClaims: {
    query: `totalClaims: batchClaims(filter: $batchClaimFilter) {
    totalCount
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].totalCount;
    },
    isDollar: false,
    description: 'Total number of Claims returned from this Search',
    title: (
      <AuditOutlined
        style={{
          fontSize: '140%',
        }}
      />
    ),
  },
  determinedAdjustedAllowedAmount: {
    query: `determinedAdjustedAllowedAmount: batchClaimStates(
		filter: {
			batchClaimById: $batchClaimFilter
			determinedAdjustedAllowedAmount: { greaterThan: 0 }
		}
	) {
		aggregates {
			sum {
				determinedAdjustedAllowedAmount
			}
		}
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].aggregates.sum[statKey];
    },
    isDollar: true,
    description:
      'Sum of Determined Adjusted Allowed Amount for all claims returned from this Search',
    title: 'Det Adj Allowed Amount',
  },
  potentialAdjustedAllowedAmount: {
    query: `potentialAdjustedAllowedAmount: batchClaimStates(
		filter: {
			batchClaimById: $batchClaimFilter
			potentialAdjustedAllowedAmount: { greaterThan: 0 }
		}
	) {
		aggregates {
			sum {
				potentialAdjustedAllowedAmount
			}
		}
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].aggregates.sum[statKey];
    },
    isDollar: true,

    description:
      'Sum of Potential Adjusted Allowed Amount for all claims returned from this Search',
    title: 'Pot Adj Allowed Amount',
  },
  determinedClientSavings: {
    query: `determinedClientSavings: batchClaimStates(
		filter: {
			batchClaimById: $batchClaimFilter
			determinedClientSavings: { greaterThan: 0 }
		}
	) {
		aggregates {
			sum {
				determinedClientSavings
			}
		}
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].aggregates.sum[statKey];
    },
    isDollar: true,

    description:
      'Sum of Determined Client Savings for all claims returned from this Search',
    title: 'Det Savings',
  },
  potentialClientSavings: {
    query: `potentialClientSavings: batchClaimStates(
		filter: {
			batchClaimById: $batchClaimFilter
			potentialClientSavings: { greaterThan: 0 }
		}
	) {
		aggregates {
			sum {
				potentialClientSavings
			}
		}
  }`,
    parseQueryResult: (data, statKey) => {
      return data[statKey].aggregates.sum[statKey];
    },
    isDollar: true,
    description:
      'Sum of Potential Client Savings for all claims returned from this Search',
    title: 'Pot Savings',
  },
};

export { auditProgressStatistics };
