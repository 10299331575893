import { useContext } from 'react';
import { useQuery, useMutation } from 'urql';
import { useState } from 'react';
import { Table, Spin, Typography, Button, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';

import moment from 'moment-timezone';

//Mutation
import { softDeleteQueueMutation } from '../../graphql/mutations';

// queries
import { queuesQuery, claimCountQuery } from '../../graphql/queries';

// components
import { BatchClaimSearchFilter } from '../search/batchClaimSearchFilter';
import { FilterTags } from '../search/search';

// context
import { UserContext } from '../context/user';
import { useLog } from '../../common/log/useLog';

const { Title } = Typography;

/**
 * A component for displaying the claim count in a queue
 * @param BatchClaimFilter!
 * @returns <div>Int</div>
 */
const ClaimCount = ({ batchClaimFilter }) => {
  const {
    permissions: { baseQueryType },
  } = useContext(UserContext);

  const [{ fetching, data, error }] = useQuery({
    query: claimCountQuery({
      queryTitle: 'claimsInQueue',
      queryType: baseQueryType.batchClaims,
    }),
    variables: { batchClaimFilter },
  });

  return (
    <div>{data ? data[baseQueryType.batchClaims].totalCount : <Spin />}</div>
  );
};

/**
 * The columns for a
 * user created queue
 */
const queueColumns = (setQueueToDelete, log) => [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Filter',
    render: ({ id, indexedFilterTags }) => {
      const searchFilter = new BatchClaimSearchFilter(indexedFilterTags);
      return (
        <FilterTags
          id={`queue-filter-tags-${id}`}
          key={`queue-filter-tagsn-${id}`}
          filter={searchFilter}
        />
      );
    },
  },
  {
    title: 'Description',
    render: ({ id, description }) => {
      return (
        <div
          id={`queue-description-col-${id}`}
          key={`queue-description-col-${id}`}
        >
          {description ? description : 'No Description'}
        </div>
      );
    },
  },
  {
    title: 'Creation Date',
    render: ({ id, createdAt }) => {
      return (
        <div id={`queue-createdAt-col-${id}`} key={`queue-createdAt-col-${id}`}>
          {moment(createdAt).utc().format('MM/DD/YYYY')}
        </div>
      );
    },
  },
  {
    title: 'Claim Count',
    render: ({ id, indexedFilterTags }) => {
      const searchFilter = new BatchClaimSearchFilter(indexedFilterTags);
      log?.debug(searchFilter.gqlFilter);
      return (
        <ClaimCount
          id={`claim-Count-column-${id}`}
          key={`claim-Count-column-${id}`}
          batchClaimFilter={searchFilter.gqlFilter}
        />
      );
    },
  },
  {
    title: 'Actions',
    render: ({ id }) => {
      return (
        <Button
          id={`delete-button-${id}`}
          key={`delete-button-${id}`}
          onClick={() => {
            setQueueToDelete(id);
          }}
        >
          Delete
        </Button>
      );
    },
  },
];

/**
 * Formatted Queue title
 * Defaults to "My Queues"
 */
const QueueTitle = ({ title }) => (
  <Title
    level={4}
    css={css`
      margintop: 10px;
      marginbottom: 10px;
      float: left;
    `}
  >
    {title || 'My Queues'}
  </Title>
);

/**
 * The "My Queues" component, queries for the queues
 * made by the logged in user
 * @returns <Table<Queues>>
 */
const Queues = () => {
  const log = useLog('Queues');
  const history = useHistory();
  const [{ fetching, data, error }, refreshQuery] = useQuery({
    query: queuesQuery,
    requestPolicy: 'network-only', // to ensure it refreshes
  });

  const [queueToDelete, setQueueToDelete] = useState(null);

  const [{ fetching: deletingQueueLoading }, softDeleteQueue] = useMutation(
    softDeleteQueueMutation
  );

  return (
    <div data-cy="queues">
      <Modal
        open={queueToDelete !== null}
        confirmLoading={deletingQueueLoading}
        onCancel={() => {
          setQueueToDelete(null);
        }}
        onOk={async () => {
          await softDeleteQueue({
            queueId: queueToDelete,
          }).then(({ data, error }) => {
            if (!data && error) {
              const errorMessage = `Queue Deletion failed`;
              log.error(`${errorMessage}: ${error.message}`);
              message.error(errorMessage);
            } else {
              refreshQuery({ requestPolicy: 'network-only' });
              message.success(`Queue Deletion success`);
            }
            setQueueToDelete(null);
          });
        }}
      >
        Are you Sure you want to delete this Queue?
      </Modal>
      {!fetching ? (
        <Table
          title={() => <QueueTitle />}
          columns={queueColumns(setQueueToDelete, log)}
          dataSource={
            (data &&
              data.queues.nodes.map((node, i) => {
                node.key = `queues-row-${i}`; // each row needs a unique key for react to work best
                return node;
              })) ||
            [] // when no queues are returned
          }
          onRow={(record, _i) => {
            return {
              onDoubleClick: _ => {
                history.push(`/queue/${record.id}`);
              },
            };
          }}
        />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '5%' }}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        open={queueToDelete !== null}
        confirmLoading={deletingQueueLoading}
        onCancel={() => {
          setQueueToDelete(null);
        }}
        onOk={async () => {
          await softDeleteQueue({
            queueId: queueToDelete,
          }).then(({ data, error }) => {
            if (!data && error) {
              const errorMessage = `Queue Deletion failed`;
              log.error(`${errorMessage}: ${error.message}`);
              message.error(errorMessage);
            } else {
              refreshQuery();
              message.success(`Queue Deletion success`);
            }
            setQueueToDelete(null);
          });
        }}
      >
        Are you Sure you want to delete this Queue?
      </Modal>
    </div>
  );
};

export { Queues, QueueTitle };
