import {
  AutodorFinding,
  Finding,
  ItemizedBillLine,
} from '../../../../../gql/graphql';
import { FindingInfo, IBGridLine } from './types';
import { Dictionary, groupBy, isFunction, keyBy, omit } from 'lodash';
import {
  isAutodorFinding,
  isAutodorIbFinding,
} from '../../../util/findingType';
import { DenialCodes } from '../../../queries/denialCodes/useDenialCodes';
import { isSet } from '../../../util/isSet';
import { AuditFindingSeedType } from '../../createAuditFinding/auditFindingUtil';

export type ItemizedBillLineOrNull = ItemizedBillLine | null;

export const isNonNullIbLinesArray = (
  lines: (ItemizedBillLine | null)[]
): lines is ItemizedBillLine[] => lines.every(e => e !== null);

export const mapAutodorFindingsByLineId = (
  autodorFindings: AutodorFinding[]
) => {
  return groupBy(
    (autodorFindings ?? []).filter(isAutodorIbFinding),
    'ibLineId'
  );
};

// could also filter props e.g. more than 2 char ending 'id', 'fingerprint' etc.
// we should be mindful of performance tho as the searchable string is created
// for all findings/autodor findings to allow searching the IB table for rows
// whose findings contain specific text...
const searchableStringExclusions: string[] = [
  '__typename',
  'id',
  'authorId',
  'alaRowId',
  'billType',
  'versionId',
  'claimId',
  'batchClaimId',
  'batchClaimLineId',
  'ibLineId',
  'ubLineId',
  'seedType',
  'lineNumber',
  's3InputSourceId',
  'versionFingerprint',
  'ibLineVersionFingerprint',
];

export const objToSearchableString = (
  object: Record<any, any>,
  initialValues: Set<string> = new Set<string>()
): string =>
  Array.from(
    Object.values(omit(object, searchableStringExclusions)).reduce((acc, o) => {
      if (typeof o === 'object') {
        if (o instanceof Date) {
          // objects of type -- push
          acc.add(o.toLocaleDateString());
          acc.add(o.toISOString());
        } else {
          // nested objects
          objToSearchableString(o, acc);
        }
      } else if (Array.isArray(o)) {
        o.forEach(ox => objToSearchableString(ox, acc));
      } else if (isFunction(o) || !isSet(o) || o === '') {
        // no op
      } else {
        const val = `${o}`;
        if (!acc.has(val)) {
          acc.add(val);
        }
      }
      return acc;
    }, initialValues)
  ).join(' ');

export const extractFindingInfo = (
  finding: Finding | AutodorFinding,
  denialCodes: DenialCodes
): FindingInfo => {
  return isAutodorFinding(finding)
    ? {
        id: finding.id,
        denialCode: finding.denialCode!,
        label:
          denialCodes?.byKey[finding.denialCode!]?.displayName ??
          finding.denialCode,
        searchText: objToSearchableString(finding),
        type: 'suggestion',
        isActive: true,
        accepted: false,
        needsReview: true,
        seedType: finding.seedType as unknown as AuditFindingSeedType,
      }
    : {
        id: finding.id,
        denialCode: finding.ruleType!,
        label:
          denialCodes?.byKey[finding.ruleType!]?.displayName ??
          finding.ruleType,
        searchText: objToSearchableString(finding),
        type: 'finding',
        isActive: finding.isActive ?? false,
        accepted: finding.accepted ?? false,
        needsReview: finding.needsReview ?? true,
        seedType: finding.seedType as AuditFindingSeedType,
      };
};

/**
 * Augments ibLine input by adding metadata for the view, and replacing / adding
 * references to the full findings/autodor findings to the line in place of the
 * array of ids the server sends on the line.  This is by design, v.s. sending the
 * full finding over the wire both at the root of Claim and within the line; having
 * the full finding on the line here is not a mem concern as it's not a
 * @param ibLines
 * @param findings
 * @param autodorFindings
 * @param denialCodes
 */
export const buildIbGridLines = (
  ibLines: ItemizedBillLine[],
  findings: Finding[],
  autodorFindings: AutodorFinding[],
  denialCodes: DenialCodes
): {
  ibGridLines: IBGridLine[] | undefined;
  findingsMap: Dictionary<Finding>;
  autodorFindingsMap: Dictionary<AutodorFinding>;
  linesByFindingId: Dictionary<string>;
  linesByAutodorFindingId: Dictionary<string>;
} => {
  const findingsMap = keyBy(findings ?? [], 'id');
  const autodorFindingsMap = keyBy(autodorFindings, 'id');
  // n.b. autodor finding ids aren't currently sent on the ibLine so we need a map-by-line-id here
  // to look them up by line, in the .map() op.
  // todo when the server sends autodor ids on the line, this can be handled like findings are here.
  const autodorFindingsMapByLineId =
    mapAutodorFindingsByLineId(autodorFindings);
  const linesByFindingId: Dictionary<string> = {};
  const linesByAutodorFindingId: Dictionary<string> = {};
  const ibGridLines =
    ibLines.length < 1
      ? undefined
      : ibLines.map(ibl => {
          const autodorFindings = autodorFindingsMapByLineId[ibl.id] ?? [];
          // n.b. findings includes old CSV findings mapped by alaRowId, see note at linesByFindingId below
          const findings = (ibl.findings ?? []).map(
            (f: string) => findingsMap[f]
          );
          const ibGridLine: IBGridLine = {
            ...ibl,
            autodorFindings,
            findings,
            findingInfo: [
              ...findings.map(f => extractFindingInfo(f, denialCodes)),
              ...autodorFindings.map(f => extractFindingInfo(f, denialCodes)),
            ],
          };
          ibGridLine.findings.forEach(f => {
            if (f) {
              // n.b. the server sends old CSV IB findings in the ibLine.finding[]
              // so .findings[] and linesByFindingId{} is correct for those, however
              // the findings themselves don't have an findingItemizedBillData.ibLineId value
              // ∴ this map can provide direct access to the line id from finding id w/o filtering/finding,
              // however, (along with others) it may be a consideration for performance optimization at some point
              linesByFindingId[f.id] = ibGridLine.id;
            }
          });
          ibGridLine.autodorFindings.forEach(f => {
            if (f) {
              linesByAutodorFindingId[f.id] = ibGridLine.id;
            }
          });
          return ibGridLine;
        });
  return {
    ibGridLines,
    findingsMap,
    autodorFindingsMap,
    linesByFindingId,
    linesByAutodorFindingId,
  };
};
