import React, { useContext } from 'react';
import {
  FindingContext,
  FindingContextProvider,
} from '../../components/audit/claimWorkspace/findingEdit/findingProvider';
import { FindingInline } from '../../components/audit/claimWorkspace/findingEdit/inline/findingInline';
import {
  AuditFindingSeedType,
  BatchClaim,
  Claim,
  ItemizedBillLine,
} from '../../gql/graphql';
import {
  Finding,
  FindingEditorOp,
  isReviewFindingOp,
} from '../../components/audit/claimWorkspace/findingEdit/types';
import tw, { css } from 'twin.macro';

// This file is a leftover *experiment* for being able to switch between finding editors
// for example open the full modal from a small popup...  after the basic concept was
// validated it has not been kept in sync with the finding editor code (and the mocks are
// removed at the end of the file.  Keeping for now as this is work we plan to resume in the
// near future.

const CreateFindingButton = ({ operation }: { operation: FindingEditorOp }) => {
  const { findingEditor, inlineOpenId } = useContext(FindingContext);
  return (
    <button onClick={() => findingEditor.showModal({ operation })}>
      Create modal
    </button>
  );
};

const EditFindingButton = ({ operation }: { operation: FindingEditorOp }) => {
  const { findingEditor, inlineOpenId } = useContext(FindingContext);
  let disabled = true;
  if (isReviewFindingOp(operation)) {
    disabled = !operation?.finding?.id;
  }
  return (
    <button
      disabled={disabled}
      onClick={() => findingEditor.showModal({ operation })}
    >
      Edit modal
    </button>
  );
};

export const FindingEdits = () => {
  return (
    <FindingContextProvider
      claimId={data.id}
      batchClaim={batchClaim}
      claim={data}
    >
      <div>
        <div
          css={css({
            ...tw`_p-8 _grid _grid-cols-8 _gap-2`,
            '& div': tw`_bg-white`,
          })}
        >
          {data?.itemizedBillLines?.map((line: ItemizedBillLine) => {
            const findingToEdit =
              (data?.findings?.length ?? 0) === 0
                ? null
                : data.findings!.find(f => f.id === line!.findings![0]);
            return (
              <>
                <div>{line.lineNumber}</div>
                <div tw="_col-span-3">{line.description}</div>
                <div>
                  {(line.findings ?? [])
                    .map(fId => data?.findings?.find(f => f.id === fId))
                    .map((f: any) =>
                      f?.ruleType
                        ? f.ruleType.substring(f.ruleType.length - 2)
                        : '?'
                    )
                    .join(', ')}
                </div>
                <div>
                  <FindingInline
                    claimId={data.id}
                    rowId={line.id}
                    ibData={[line as ItemizedBillLine]}
                    seedType={AuditFindingSeedType.IB_CLAIM_LINE}
                  >
                    <div>Inline</div>
                  </FindingInline>
                </div>
                <div>
                  <CreateFindingButton
                    operation={{
                      operation: 'create',
                      ibData: [line],
                      seedType: AuditFindingSeedType.IB_CLAIM_LINE,
                    }}
                  ></CreateFindingButton>
                </div>
                <div>
                  {!!findingToEdit && (
                    <EditFindingButton
                      operation={{
                        operation: 'update',
                        ibData: [line],
                        finding: findingToEdit as Finding,
                        seedType: AuditFindingSeedType.IB_CLAIM_LINE,
                      }}
                    ></EditFindingButton>
                  )}
                </div>
              </>
            );
          })}
        </div>
        <div>yoyoyo</div>
      </div>
    </FindingContextProvider>
  );
};

// NOTE THAT MOCK DATA IS OMITTED AS IT WAS STALE
const data: Claim = {} as unknown as Claim;

const batchClaim: BatchClaim = {} as unknown as BatchClaim;
