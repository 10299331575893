import React from 'react';
import { Form, Select, InputNumber, Input, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import accounting from 'accounting-js';

import { auditTypeLabels, inputPropsCurrencyFormatter } from '../../util';
import { FormExtra } from '../../shared/components/form/formExtra';

const getUniqueProcedureCodes = batchClaim =>
  [
    ...new Set(
      batchClaim.batchClaimLines.nodes
        .map(claimLine => claimLine.procedureCode)
        .filter(value => value !== null && value !== undefined)
    ),
  ].map(procedureCode => ({
    label: procedureCode,
    value: procedureCode,
  }));

const SelectProcedureCodes = ({ batchClaim, mode, ...props }) => {
  const [newProcedureCodes, setNewProcedureCodes] = React.useState(new Set());
  return (
    <Select
      mode={mode}
      showSearch
      placeholder="Select a procedure code"
      options={getUniqueProcedureCodes(batchClaim).concat(
        [...newProcedureCodes].map(newCode => ({
          label: newCode,
          value: newCode,
        }))
      )}
      optionFilterProp="label"
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <Input.Search
            allowClear
            placeholder={'Add a code'}
            enterButton={<PlusOutlined />}
            onSearch={newCode =>
              newCode &&
              // must create a new Set to trigger a re-render since React
              // will only update state if new state is not equal to old state
              // according to Object.is()
              // Object.is(mySet, mySet.add(newValue)) === true
              // Object.is(mySet, new Set(mySet.add(newValue))) === false
              setNewProcedureCodes(existing => new Set(existing.add(newCode)))
            }
            style={{ padding: '4px 10px' }}
          />
        </div>
      )}
      {...props}
    />
  );
};

const createFormItems = (ruleType, batchClaim, originalAuditFinding) => {
  switch (ruleType) {
    // DOC: (MV)  Per US184 these are kept for historical data but with the new denial
    // they won't have replacement codes, in an effort to standardize the form
    case 'UB_CLAIM_LINE_NCCI_MUE':
      return {
        label: (ruleType = 'UB_CLAIM_LINE_MUE'
          ? auditTypeLabels.MUE
          : auditTypeLabels.NCCI_MUE),
        items: (
          <>
            <Form.Item
              key="metaDataNcciMueOhpMueValue"
              name="metaDataNcciMueOhpMueValue"
              label="Max. allowable units"
              rules={[
                {
                  required: true,
                  message:
                    'Enter the maximum allowable units according to NCCI',
                },
              ]}
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding &&
                    originalAuditFinding.metaDataNcciMueOhpMueValue}
                </FormExtra>
              }
              initialValue={
                originalAuditFinding?.metaDataNcciMueOhpMueValue || 0
              }
            >
              <InputNumber style={{ width: '50%' }} precision={0} min={0} />
            </Form.Item>
            <Form.Item
              key="metaDataNcciMueAjudicationValue"
              name="metaDataNcciMueAjudicationValue"
              label="MAI"
              rules={[
                { required: true, message: 'Select an adjudication index' },
              ]}
              initialValue={
                originalAuditFinding?.metaDataNcciMueAjudicationValue
              }
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding &&
                    originalAuditFinding.metaDataNcciMueAjudicationValue}
                </FormExtra>
              }
            >
              <Select
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                ]}
              />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_NCCI_PTP':
      return {
        label: (ruleType = 'UB_CLAIM_LINE_EB1'
          ? auditTypeLabels.EB1
          : auditTypeLabels.NCCI_PTP),
        items: (
          <>
            <Form.Item
              key="metaDataNcciPtpCode"
              name="metaDataNcciPtpCode"
              label="Conflicting Proc Code"
              rules={[{ required: true, message: 'Select a procedure code' }]}
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding &&
                    originalAuditFinding.metaDataNcciPtpCode}
                </FormExtra>
              }
              initialValue={originalAuditFinding?.metaDataNcciPtpCode}
            >
              <SelectProcedureCodes batchClaim={batchClaim} />
            </Form.Item>
            <Form.Item
              key="metaDataNcciPtpModifier"
              name="metaDataNcciPtpModifier"
              label="Modifier Rule"
              rules={[{ required: true, message: 'Select a modifier' }]}
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding &&
                    originalAuditFinding.metaDataNcciPtpModifier}
                </FormExtra>
              }
              initialValue={originalAuditFinding?.metaDataNcciPtpModifier}
            >
              <Select
                options={[
                  { label: 0, value: 0 },
                  { label: 1, value: 1 },
                  { label: 9, value: 9 },
                ]}
              />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_AGE_MODIFIER': // deprecated/historical
      return {
        label: auditTypeLabels.AGE_MODIFIER,
        items: <></>,
        visible: true,
      };
    case 'UB_CLAIM_LINE_GENDER_MODIFIER': // deprecated/historical
      return {
        label: auditTypeLabels.GENDER_MODIFIER,
        items: <></>,
        visible: true,
      };
    case 'UB_CLAIM_LINE_ADD_ON_CODE': // deprecated/historical
      return {
        label: auditTypeLabels.ADD_ON_CODE,
        items: (
          <>
            <Form.Item
              key="metaDataAocPrimaryCode"
              name="metaDataAocPrimaryCode"
              label="Primary procedure code(s)"
              rules={[{ required: false }]}
            >
              <SelectProcedureCodes batchClaim={batchClaim} mode="multiple" />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_EM': // deprecated/historical
      return {
        label: auditTypeLabels.EM,
        items: (
          <>
            <Form.Item
              key="metaDataEmOriginalLevel"
              name="metaDataEmOriginalLevel"
              label="Original level"
              rules={[{ required: true, message: 'Select an E/M Level' }]}
            >
              <Select
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                ]}
              />
            </Form.Item>
            <Form.Item
              key="metaDataEmCorrectLevel"
              name="metaDataEmCorrectLevel"
              label="Correct level"
              dependencies={['metaDataEmOriginalLevel']}
              rules={[
                { required: true, message: 'Select an E/M Level' },
                ({ getFieldValue }) => ({
                  validator: async (rule, value) =>
                    getFieldValue('metaDataEmOriginalLevel') === value
                      ? Promise.reject(
                          'Correct E/M Level must differ from the original E/M level'
                        )
                      : Promise.resolve(),
                }),
              ]}
            >
              <Select
                options={[
                  { label: 1, value: 1 },
                  { label: 2, value: 2 },
                  { label: 3, value: 3 },
                  { label: 4, value: 4 },
                  { label: 5, value: 5 },
                ]}
              />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_PRICING': // deprecated/historical
      return {
        label: auditTypeLabels.PRICING,
        items: (
          <>
            <Form.Item
              key="metaDataPricingCorrectPrice"
              name="metaDataPricingCorrectPrice"
              label="Correct unit price"
              rules={[
                { required: true, message: 'Enter the correct unit price' },
              ]}
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding &&
                    accounting.formatMoney(
                      originalAuditFinding.metaDataPricingCorrectPrice
                    )}
                </FormExtra>
              }
              initialValue={0}
            >
              <InputNumber
                style={{ width: '50%' }}
                min={0}
                {...inputPropsCurrencyFormatter}
              />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_UNITS': // deprecated/historical
      return {
        label: auditTypeLabels.UNITS,
        items: (
          <>
            <Form.Item
              key="metaDataUnitsCorrectUnits"
              name="metaDataUnitsCorrectUnits"
              label="Correct number of units"
              rules={[
                {
                  required: true,
                  message: 'Enter the correct number of quantity',
                },
              ]}
              initialValue={0}
            >
              <InputNumber style={{ width: '50%' }} precision={0} min={0} />
            </Form.Item>
          </>
        ),
        visible: true,
      };
    case 'UB_CLAIM_LINE_OTHER': // deprecated/historical
      return {
        label: auditTypeLabels.OTHER,
        items: <></>,
        visible: true,
      };
    // types are not implemented in this form
    // see ALA-1636
    case 'UB_CLAIM_LINE_CPT_ICD10': // deprecated/historical
      return {
        label: auditTypeLabels.CPT_ICD10,
        items: <></>,
        visible: false,
      };
    case 'UB_CLAIM_LINE_CPT_MOD': // deprecated/historical
      return {
        label: auditTypeLabels.CPT_MOD,
        items: <></>,
        visible: false,
      };
  }
};

export { createFormItems };
