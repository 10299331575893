import { Breadcrumb, Layout, Menu, MenuProps, theme } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import tw, { css } from 'twin.macro';
import { AuditDocumentation } from '../../gql/graphql';
import { PdfViewerContext } from '../../common/pdf/pdfViewerProvider';
import { UserContext } from '../context/user';
import { PdfViewerControlsEx } from '../../common/pdf/types';
import { formatBytesAsDisplayString } from '../../common/util/fn/bytes';
import { UiAuditDocumentation } from './types';
import { useLog } from '../../common/log/useLog';
import { FilePdfOutlined } from '@ant-design/icons';
import { DesignProvider } from '../app/design/designProvider';

const { Header, Content, Footer, Sider } = Layout;

const clsMenuItem = css({
  height: 'auto',
  '& span': {
    display: 'flex',
    flexDirection: 'column',
    height: '2.75em',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
});
const clsMenuItemName = css({
  lineHeight: '1.75em',
});
const clsMenuItemType = css({
  color: 'darkgray',
  lineHeight: '1em',
  fontSize: '.75em',
  ...tw`_flex _gap-2`,
});

const COLLAPSED_WIDTH = 40;
const EXPANDED_WIDTH = 225;

type DocumentFileMenuProps = {
  claimId: string | null;
  files: UiAuditDocumentation[];
  activeFile: UiAuditDocumentation | null;
  // setActiveFile: SetActiveFileFn;
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
  fetching: boolean;
};

export const DocumentFileMenu = ({
  claimId,
  files,
  activeFile,
  // setActiveFile,
  collapsed,
  setCollapsed,
  fetching,
}: DocumentFileMenuProps) => {
  const log = useLog('documentFileMenu');
  log.css = 'color: green';
  // log.setLevel(log.levels.DEBUG, false);
  const pdfCtx = useContext(PdfViewerContext);

  const viewer = useMemo(
    () => pdfCtx?.getViewer && pdfCtx.getViewer('documentViewer'),
    [pdfCtx]
  );

  useEffect(() => {
    if (!activeFile) {
      viewer.closeDocument();
    }
  }, [activeFile]);

  const changeActiveFile = useCallback(
    (k: any) => {
      const af = files.find(f => f.id === k);
      log.trace('current active file ', { claimId, k, af, files });
      if (af) {
        viewer
          .loadDocument({
            url: af.signedUrl,
            filename: af.fileName,
            expiresMs: af.expiresMs,
            fileSize: af.size,
            documentId: af.id,
          })
          .then(result => log.debug('%c loadDocument result', log.css, result));
      }
    },
    [pdfCtx, files, activeFile]
  );

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    title: string,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      title,
    } as MenuItem;
  }

  const items: MenuItem[] =
    fetching && files.length < 1
      ? []
      : files.map(file => {
          return getItem(
            <>
              <div css={clsMenuItemName}>{file.fileName || file.id}</div>

              <div css={clsMenuItemType}>
                <div>{file.fileDisplayType}</div>
                <div>{formatBytesAsDisplayString(file.size, 0)}</div>
              </div>
            </>,
            file.fileName,
            file.id,
            <FilePdfOutlined />
          );
        });

  const {
    options: { darkMode },
  } = useContext(DesignProvider);

  return (
    <Layout
      css={css(
        tw`_h-full _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer`
      )}
    >
      <Sider
        tw=""
        collapsedWidth={COLLAPSED_WIDTH}
        width={EXPANDED_WIDTH}
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        theme={darkMode ? 'dark' : 'light'}
      >
        <Menu
          css={css(tw`_w-fit _border-none!`)}
          theme={darkMode ? 'dark' : 'light'}
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          selectedKeys={[activeFile?.id]}
          onClick={({ key }) => {
            changeActiveFile(key);
          }}
        />
      </Sider>
    </Layout>
  );
};

DocumentFileMenu.COLLAPSED_WIDTH = COLLAPSED_WIDTH;
DocumentFileMenu.EXPANDED_WIDTH = EXPANDED_WIDTH;
