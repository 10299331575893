export const AutodorIcon = ({ width }: { width?: number }) => {
  return (
    <img
      src={require('../../resources/favicon-32x32.png')}
      style={{
        width: width ?? 14 + 'px',
      }}
    ></img>
  );
};
