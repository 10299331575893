import { FormOutlined, FileExcelOutlined } from '@ant-design/icons';

// for SignOff we combine both providerSignOff and clientSignOff as one stateType despite them being 2 different tables
const signOff = {
  auditProgress: {
    stateTypeLabel: 'Sign Offs',
    defaultLabel: 'No Client or Provider Sign Off',
    tooltipLabel: 'Claims with Client/Provider Sign Offs',

    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds 2 subQueries:
     *     first one runs a groupedAggregate (groupedBy: CLIENT_SIGN_OFF_STATE) on the clientSignOffClaimStates table filtered on a $batchClaimFilter
     *     Second one runs a groupedAggregate (groupedBy: PROVIDER_SIGN_OFF_STATE) on the providerSignOffClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: ({ permissions }) => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `signOffClient: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
            { clientSignOffClaimState: { equalTo: CLIENT_SIGN_OFF } }
          ]
        }
      ) {
        totalCount
      }
      signOffProvider: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
            { providerSignOffClaimState: { equalTo: PROVIDER_SIGN_OFF } }
          ]
        }
      ) {
        totalCount
      }`;
      return query;
    },

    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e CLIENT_SIGN_OFF): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims: { totalCount },
        signOffClient,
        signOffProvider,
      },
      permissions,
      userType,
      queryableStates,
      states,
    }) => {
      const signOffProgressStatsProps = {};
      const formattedQueryResults = {
        PROVIDER_NO_SIGN_OFF: totalCount - signOffProvider.totalCount || 0,
        CLIENT_NO_SIGN_OFF: totalCount - signOffClient.totalCount || 0,
        PROVIDER_SIGN_OFF: signOffProvider.totalCount || 0,
        CLIENT_SIGN_OFF: signOffClient.totalCount || 0,
      };
      queryableStates.forEach(stateName => {
        if (permissions.claimStatesToShow.signOff.has(stateName))
          signOffProgressStatsProps[stateName] = {
            totalCount: formattedQueryResults[stateName],
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            tagColor: states[stateName].tagColor,
            icon: states[stateName].icon,
            description: states[stateName].description(userType),
          };
      });
      return signOffProgressStatsProps;
    },
  },
  // CLIENT_PROVIDER_SIGN_OFF not included
  queryableStates: [
    'PROVIDER_NO_SIGN_OFF',
    'CLIENT_NO_SIGN_OFF',
    'PROVIDER_SIGN_OFF',
    'CLIENT_SIGN_OFF',
  ],
  defaultStates: ['PROVIDER_NO_SIGN_OFF', 'CLIENT_NO_SIGN_OFF'],
  dataType: 'enum',
  onTableQuery: 'batchClaims',
  states: {
    // default state for provider
    PROVIDER_NO_SIGN_OFF: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'No Provider Sign Off';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: 'geekblue',
      icon: (
        <FileExcelOutlined style={{ color: 'lightgray', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) have not been signed off by Provider';
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: userType => 'Removed Provider Sign Off',
        renderingAllowed: event => true,
      },
    },
    // default state for client
    CLIENT_NO_SIGN_OFF: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'No Client Sign Off';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: 'magenta',
      icon: (
        <FileExcelOutlined style={{ color: 'lightgray', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) have been not signed off by Client';
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: userType => 'Removed Client Sign Off',
        renderingAllowed: event => true,
      },
    },
    PROVIDER_SIGN_OFF: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Provider Sign Off';
      },
      primaryColor: '#3760ba',
      strokeColor: 'white',
      tagColor: 'geekblue',
      icon: <FormOutlined style={{ color: '#3760ba', fontSize: '120%' }} />,
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) that have been signed off by Provider';
      },
      displayCondition: ({
        batchClaimState: { providerSignOffClaimState, clientSignOffClaimState },
      }) =>
        clientSignOffClaimState !== 'CLIENT_SIGN_OFF' &&
        providerSignOffClaimState === 'PROVIDER_SIGN_OFF',
      timelineEventRendering: {
        description: userType => 'Provider Signed Off',
        renderingAllowed: event => true,
      },
    },
    CLIENT_SIGN_OFF: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Client Sign Off';
      },
      primaryColor: '#e06d64',
      strokeColor: 'white',
      tagColor: 'magenta',
      icon: <FormOutlined style={{ color: '#e06d64', fontSize: '120%' }} />,
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) that have been signed off by Client';
      },
      displayCondition: ({
        batchClaimState: { providerSignOffClaimState, clientSignOffClaimState },
      }) =>
        clientSignOffClaimState === 'CLIENT_SIGN_OFF' &&
        providerSignOffClaimState !== 'PROVIDER_SIGN_OFF',
      timelineEventRendering: {
        renderingAllowed: event => true,
        description: userType => 'Client Signed Off',
      },
    },
    // A special tag that is rendered only in <EnumClaimStates /> when both client and provider have signed off
    // -- Again purpose of this is that it looks nicer in claim list state columns in UI
    CLIENT_PROVIDER_SIGN_OFF: {
      name: userType => {
        if (userType === 'PROVIDER') return 'Provider Sign Off';
        // different userTypes don't make a difference so we just return
        return 'Provider & Client Sign Off';
      },
      primaryColor: 'purple',
      strokeColor: 'white',
      tagColor: 'purple',
      icon: <FormOutlined style={{ color: 'purple', fontSize: '120%' }} />,
      description: userType => {
        if (userType === 'PROVIDER')
          return 'Claim(s) that have been signed off by Provider';
        // different userTypes don't make a difference so we just return
        return 'Claim(s) have been signed off by both Client & Provider';
      },
      displayCondition: ({
        batchClaimState: { providerSignOffClaimState, clientSignOffClaimState },
      }) =>
        clientSignOffClaimState === 'CLIENT_SIGN_OFF' &&
        providerSignOffClaimState === 'PROVIDER_SIGN_OFF',
      timelineEventRendering: {
        renderingAllowed: event => false,
        description: userType => 'Client & Provider Removed Signed Off',
      },
    },
  },
  filterBuilder: values => {
    const valuesObj = {
      CLIENT: values.filter(value => value.includes('CLIENT')),
      PROVIDER: values.filter(value => value.includes('PROVIDER')),
    };
    const filter = { and: [] };
    /* 
      POSSIBLE VALUES = [PROVIDER_NO_SIGN_OFF, CLIENT_NO_SIGN_OFF, PROVIDER_SIGN_OFF,  CLIENT_SIGN_OFF]

      filter required for each value:
      PROVIDER_NO_SIGN_OFF
        - providerSignOffClaimStatesExist = false
          -- or --
        - providerSignOffClaimState = PROVIDER_NO_SIGN_OFF

      CLIENT_NO_SIGN_OFF
        - clientSignOffClaimStatesExist = false
          -- or --
        - clientSignOffClaimState = CLIENT_NO_SIGN_OFF

      CLIENT_SIGN_OFF   
        - clientSignOffClaimStatesExist: true   
        `-- and --
        - clientignOffClaimState = CLIENT_SIGN_OFF
      
      PROVIDER_SIGN_OFF
     ` - providerSignOffClaimStatesExist: true   
        `-- and --
      `- providerSignOffClaimState = PROVIDER_SIGN_OFF
    */

    Object.entries(valuesObj).forEach(([userType, userTypeVals]) => {
      if (valuesObj[userType].includes(`${userType}_NO_SIGN_OFF`)) {
        /*
         when PROVIDER_NO_SIGN_OFF and PROVIDER_SIGN_OFF or CLIENT_NO_SIGN_OFF and CLIENT_SIGN_OFF is in values we add the 'OR'
         because for the case when thetable entry doesnt exist that handles the 'NOT_SIGN_OFF' scenerio
         and the pg function handles the remaining scenerios
        */
        filter['and'].push(
          userType === 'CLIENT'
            ? {
                or: [
                  { clientSignOffClaimStatesExist: false },
                  {
                    batchClaimStateById: {
                      clientSignOffClaimState: { in: userTypeVals },
                    },
                  },
                ],
              }
            : {
                or: [
                  { providerSignOffClaimStatesExist: false },
                  {
                    batchClaimStateById: {
                      providerSignOffClaimState: { in: userTypeVals },
                    },
                  },
                ],
              }
        );
      } else if (userTypeVals.length > 0) {
        const keys = [
          `${userType.toLowerCase()}SignOffClaimStatesExist`,
          `${userType.toLowerCase()}SignOffClaimState`,
        ];

        values = [
          true,
          {
            in: userTypeVals,
          },
        ];
        keys.forEach((key, i) => {
          const tempObj = {};
          if (key.includes('Exist')) tempObj[key] = values[i];
          else {
            tempObj['batchClaimStateById'] = {};
            tempObj['batchClaimStateById'][key] = values[i];
          }
          filter['and'].push(tempObj);
        });
      }
    });
    return filter;
  },
};

export { signOff };
