import { CloseSquareOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { parseGroupedAggregatesQueryResIntoProgessStatProps } from '../../claimStateUtil';

const dispute = {
  auditProgress: {
    stateTypeLabel: 'Disputes',
    defaultLabel: 'No Claim is disputed by provider',
    tooltipLabel: 'Claims that are disputed by provider',
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that aggregates on the disputedFindings table and accepts a $batchClaimFilter
     */
    buildProgressSubQuery: ({ permissions }) => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `
      disputed: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
            { disputedFindingClaimState: { equalTo: DISPUTED } }
          ]
        }
      ) {
        totalCount
      }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e DISPUTED): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims: { totalCount },
        disputed,
      },
      permissions,
      userType,
      queryableStates,
      states,
    }) => {
      const progressStatProps = {};
      const formattedQueryResults = {
        DISPUTED: disputed.totalCount || 0,
        NOT_DISPUTED: totalCount - disputed.totalCount || 0,
      };
      queryableStates.forEach(stateName => {
        if (permissions.claimStatesToShow['dispute'].has(stateName)) {
          progressStatProps[stateName] = {
            totalCount: formattedQueryResults[stateName],
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            tagColor: states[stateName].tagColor,
            icon: states[stateName].icon,
            description: states[stateName].description(userType),
          };
        }
      });
      return progressStatProps;
    },
  },
  queryableStates: ['NOT_DISPUTED', 'DISPUTED'],
  defaultStates: ['NOT_DISPUTED'],
  dataType: 'enum',
  onTableQuery: 'batchClaims',
  states: {
    NOT_DISPUTED: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Not In Dispute';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: 'volcano',
      icon: (
        <CheckSquareOutlined style={{ color: 'green', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) that have not been disputed by Provider';
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: userType => 'Claim Dispute was resolved',
        renderingAllowed: event => true,
      },
    },
    DISPUTED: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Disputed';
      },
      primaryColor: 'red',
      strokeColor: 'white',
      tagColor: 'volcano',
      icon: <CloseSquareOutlined style={{ color: 'red', fontSize: '120%' }} />,
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) that have been disputed';
      },
      displayCondition: ({ batchClaimState: { disputedFindingClaimState } }) =>
        disputedFindingClaimState === 'DISPUTED',
      timelineEventRendering: {
        description: userType => 'Claim was Disputed',
        renderingAllowed: event => true,
      },
    },
  },
  // since a binary selection this will alway come as an array of values of size 1
  filterBuilder: values => {
    /* 
      POSSIBLE VALUE = NOT_DISPUTED OR DISPUTED -- only ONE Values accepted

      filter required for each values:
      NOT_DISPUTED
        - disputedFindingsExist = false
          --- OR  ---
        - disputedFindingClaimState = NOT_DISPUTED

      DISPUTED
        - disputedFindingsExist = true 
         --- AND ---
        - disputedFindingClaimState = DISPUTED
    */
    if (values.includes('NOT_DISPUTED')) {
      return {
        or: [
          { disputedFindingsExist: false },
          {
            batchClaimStateById: {
              disputedFindingClaimState: { equalTo: 'NOT_DISPUTED' },
            },
          },
        ],
      };
    } else {
      return {
        disputedFindingsExist: true,
        batchClaimStateById: {
          disputedFindingClaimState: { equalTo: 'DISPUTED' },
        },
      };
    }
  },
};

export { dispute };
