import { useObservableSubscription } from '../../util/Observable';
import { useLog } from '../log/useLog';
import { useContext, useMemo } from 'react';
import { PdfViewerContext } from './pdfViewerProvider';

export const usePdfViewer = () => {
  useLog('usePdfViewer');
  const pdfViewerCtx = useContext(PdfViewerContext);

  const pdfViewer = useMemo(
    () =>
      pdfViewerCtx?.getViewer ? pdfViewerCtx.getViewer('documentViewer') : null,
    [pdfViewerCtx]
  );

  const currentPageNumber = useObservableSubscription(
    pdfViewer?.pdfViewerInstance?.pageNumberObs ?? null
  );
  const currentDocumentId = useObservableSubscription(
    pdfViewer?.pdfViewerInstance?.documentIdObs ?? null
  );
  const currentDocumentName = useObservableSubscription(
    pdfViewer?.pdfViewerInstance?.documentNameObs ?? null
  );
  const loading = useObservableSubscription(
    pdfViewer?.pdfViewerInstance?.loadingObs ?? null
  );

  return {
    pdfViewer,
    currentPageNumber,
    currentDocumentId,
    currentDocumentName,
    loading,
  };
};
