import React, { useContext } from 'react';
import { useQuery, gql, useMutation } from 'urql';
import accounting from 'accounting-js';
import {
  Collapse,
  Button,
  List,
  Tag,
  Space,
  Tooltip,
  message,
  Typography,
  Skeleton,
} from 'antd';
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  AimOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { AuditFindingComment } from './auditFindingComment';
import { confidenceToString } from '../../util';
import { UserContext } from '../../../context/user';
import {
  auditFindingWithReviewFragment,
  reviewedAuditFindingFragment,
  adminAndAuditorWorkstationBatchClaimFragment,
} from '../../../../fragments';
import { AuditFindingSeedType } from '../createAuditFinding/auditFindingUtil';
import { DesignProvider } from '../../../app/design/designProvider';
import tw, { css } from 'twin.macro';
import { useClaimPostFindingRefresh } from '../../queries/claim/useClaimFindingsAndLine';
import { toSlug } from '../../../../common/navigation/utils';
import { AutodorIcon } from '../../../misc/autodorIcon';
import { isAutodorFinding } from '../../util/findingType';
import { FindingContext } from '../findingEdit/findingProvider';
import { LineConvertAudotorFindingButton } from '../../shared/components/claimLine/lineConvertAudotorFindingButton';
import { LineReviewFindingButton } from '../../shared/components/claimLine/lineReviewFindingButton';
import { useHashParams } from '../../../../common/navigation/useHashParam';
const { Text } = Typography;

const createReviewedAuditFindingMutation = gql`
  mutation createReviewedAuditFinding(
    $reviewedAuditFinding: ReviewedAuditFindingInput!
  ) {
    createReviewedAuditFinding(
      input: { reviewedAuditFinding: $reviewedAuditFinding }
    ) {
      reviewedAuditFinding {
        ...reviewedAuditFindingFragment
        auditFinding {
          ...auditFindingWithReviewFragment
          # update determined and potential amounts in the cache
          batchClaim {
            # because ONLY auditors & Admins can manipulate findings (Create, Decline, Review, etc) we don't select the return fragment based on users
            # and return the 'adminAndAuditorWorkstationBatchClaimFragment' by default
            ...adminAndAuditorWorkstationBatchClaimFragment
          }
        }
      }
    }
  }
  ${reviewedAuditFindingFragment}
  ${auditFindingWithReviewFragment}
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

const ClaimWorkspaceAuditFindingList = props => {
  const {
    auditFindings,
    setCreatingAuditFinding = null,
    setDecliningAuditFinding = null,
    navigateToIbinRow = null,
    updateAuditFindingErrorHandler,
    batchClaimLine = null, // bcl is only required when creating a new finding else defaulted to existing finding
    readOnly = true,
    afclFilter,
    hideReviewStatus = true,
    expandAllFindings = false,
    ...panelProps
  } = props;

  const inferredReadOnly =
    setCreatingAuditFinding && setDecliningAuditFinding ? readOnly : true;

  if (inferredReadOnly !== readOnly) {
    console.info(
      'AuditFindingList entries set to read-only mode due to missing setCreating/setDeclining functions'
    );
  }
  const expanded = expandAllFindings ? auditFindings.map(af => af.id) : [];

  const sorter = (a, b) => {
    const a_first = -1;
    const b_first = 1;
    if (!a) {
      return b_first;
    }
    if (!b) {
      return a_first;
    }
    if (a.accepted !== b.accepted) {
      return a.accepted ? a_first : b_first;
    }
    const aIsIb = a.auditFindingSeedType === AuditFindingSeedType.IB_CLAIM_LINE;
    if (a.auditFindings != b.auditFindingSeedType) {
      return aIsIb ? a_first : b_first;
    }
    if (!aIsIb) {
      return a.auditFindingRuleTypeByAuditFindingRuleType?.displayName.localeCompare(
        b.auditFindingRuleTypeByAuditFindingRuleType?.displayName
      );
    }
    const aServiceDesc =
      a.batchClaimLineItemAudit?.batchClaimLineItem?.description;
    const bServiceDesc =
      a.batchClaimLineItemAudit?.batchClaimLineItem?.description;
    return aServiceDesc.localeCompare(bServiceDesc);
  };

  return (
    <Collapse
      className="claim_workspace_table_row_collapse"
      defaultActiveKey={expanded}
    >
      {auditFindings
        .sort(sorter)
        .map(({ id: auditFindingId, batchClaimLineId }, i) => (
          <ClaimWorkspaceAuditFinding
            {...{
              key: auditFindingId,
              auditFindingId,
              setCreatingAuditFinding:
                setCreatingAuditFinding ||
                (() =>
                  console.error('No setCreatingAuditFinding handler defined')),
              setDecliningAuditFinding:
                setDecliningAuditFinding ||
                (() =>
                  console.error('No setDecliningAuditFinding handler defined')),
              navigateToIbinRow:
                navigateToIbinRow ||
                (() => console.error('No navigateToIbinRow handler defined')),

              updateAuditFindingErrorHandler,
              batchClaimLine,
              readOnly: inferredReadOnly,
              afclFilter,
            }}
          />
        ))}
    </Collapse>
  );
};

const ClaimWorkspaceAuditFinding = props => {
  const {
    auditFindingId,
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    updateAuditFindingErrorHandler,
    batchClaimLine,
    readOnly,
    afclFilter,
    hideReviewStatus,
    ...panelProps
  } = props;

  const { id: authorId } = React.useContext(UserContext);

  const [{ fetching, data, error }] = useQuery({
    query: gql`
      query ClaimWorkspaceAuditFinding($auditFindingId: UUID!) {
        auditFinding(id: $auditFindingId) {
          ...auditFindingWithReviewFragment
        }
      }
      ${auditFindingWithReviewFragment}
    `,
    variables: { auditFindingId },
  });

  if (!data) console.error(`'no data for ${auditFindingId}...`);

  const { auditFinding } = data ?? {};

  const auditFindingActionsProps = {
    authorId,
    auditFinding,
    batchClaimLine,
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    updateAuditFindingErrorHandler,
    afclFilter,
    readOnly,
  };

  return !auditFinding ? (
    <Collapse.Panel
      header={<Text type={'secondary'}>loading...</Text>}
      {...panelProps}
    >
      <Skeleton active />
    </Collapse.Panel>
  ) : (
    <Collapse.Panel
      {...panelProps}
      header={
        <Space>
          <AuditFindingTags auditFinding={auditFinding} />
          <Text type={'secondary'}>
            {auditFinding.auditFindingsSeedType ===
            AuditFindingSeedType.UB_CLAIM_LINE
              ? ''
              : auditFinding.batchClaimLineItemAudit?.batchClaimLineItem
                  .description}
          </Text>
        </Space>
      }
      extra={
        <AuditFindingActions
          {...auditFindingActionsProps}
          hideReviewStatus={hideReviewStatus}
        />
      }
    >
      <List
        key={`${auditFinding.id}:review:List`}
        className="claim_workspace_table_row_collapse_list"
        split={false}
      >
        <AuditFindingComment auditFinding={auditFinding} />
      </List>
    </Collapse.Panel>
  );
};

const AuditFindingTags = props => {
  const {
    auditFinding: {
      auditType,
      auditFindingSeedType,
      auditFindingRuleTypeByAuditFindingRuleType: {
        displayName: auditFindingRuleTypeName,
      },
      confidence,
      needsReview,
    },
  } = props;

  return (
    <Space className="claim_workspace_table_row_collapse_panel_header">
      <Tag
        color={
          auditFindingSeedType === 'UB_CLAIM_LINE' ? 'geekblue' : 'magenta'
        }
      >
        {auditFindingRuleTypeName}
      </Tag>
      <Tag color="default">
        {confidence
          ? confidenceToString(confidence)
          : needsReview
            ? 'Semi-Auto'
            : 'Auto'}
      </Tag>
      {/* <Tag color="gold">Docs Requested</Tag> */}
      {/* <Tag color="blue">Docs Received</Tag> */}
    </Space>
  );
};

const AuditFindingActions = props => {
  const {
    authorId,
    auditFinding,
    auditFinding: { id: auditFindingId, improperPaymentCost, superseded },
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    updateAuditFindingErrorHandler = e => message.error(e.message),
    batchClaimLine,
    afclFilter,
    readOnly,
    hideReviewStatus,
    ibData,
    __typename,
  } = props;

  const reviewed =
    auditFinding.reviewedAuditFinding && auditFinding.reviewedAuditFinding;

  const [{ fetching }, createReviewedAuditFinding] = useMutation(
    createReviewedAuditFindingMutation
  );
  const claimRefresher = useClaimPostFindingRefresh();

  const hasIbinRowIds =
    !!auditFinding.batchClaimLineItemAudit?.batchClaimLineItem?.ibLineId ||
    !!auditFinding.batchClaimLineItemAudit?.batchClaimLineItem?.alaRowId;

  const [hashParams] = useHashParams();

  const activeFindingTags = () => {
    const {
      options: { darkMode },
    } = useContext(DesignProvider);

    return isAutodorFinding(auditFinding) ? (
      <div data-cy={'claim-line-finding-status-tags _flex'}>
        <Tag
          icon={
            <span tw=" _rounded-full ">
              <AutodorIcon></AutodorIcon>
            </span>
          }
          tw="_bg-antd-lt-gold-2 dark:_bg-antd-dk-gold-6"
        >
          <span tw="_pl-1.5 _align-middle">Autodor</span>
        </Tag>
        <LineConvertAudotorFindingButton
          autodorFinding={auditFinding}
          fetching={fetching}
          readOnly={readOnly}
          ibData={ibData}
        ></LineConvertAudotorFindingButton>
      </div>
    ) : (
      <div data-cy={'claim-line-finding-status-tags'}>
        {auditFinding?.id === '' && (
          <Tag icon={<AutodorIcon></AutodorIcon>}>Suggested</Tag>
        )}
        {ibData &&
          auditFinding?.batchClaimLineItemAudit?.batchClaimLineItem?.stale && (
            <Tag color="gold">Stale</Tag>
          )}
        {ibData && ibData[0]?.units && (
          <span tw="_text-[0.75em] _px-2 _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary">
            {auditFinding.improperPaymentUnitsCharged + '/' + ibData[0].units}
          </span>
        )}
        <Tag color={darkMode ? 'gray' : 'lightgray'}>
          {accounting.formatMoney(improperPaymentCost)}
        </Tag>
        {hideReviewStatus ? (
          ''
        ) : reviewed ? (
          reviewed.deleted ? (
            // Deleted reviews shouldn't exist for current findings, but may exist
            // on findings displayed in report version history (reportsVersionList hides via hideReviewStatus)
            // however, it's better to show deleted if it exists than inadvertently fall through to 'needs review'
            <Tag color="orange">Deleted</Tag>
          ) : reviewed.accepted ? (
            <Tag color="green">Accepted</Tag>
          ) : (
            <Tag color={darkMode ? 'gray' : 'lightgray'}>Declined</Tag>
          )
        ) : (
          <Tag color="red">Needs Review</Tag>
        )}
        {!readOnly && (!reviewed || !reviewed.accepted) && (
          <Tooltip title="Accept" key="tt_accept">
            <Button
              data-cy={'claim-line-finding-btn-accept'}
              loading={fetching}
              size="small"
              style={{
                backgroundColor: '#52c41a',
                borderColor: '#52c41a',
                marginRight: '2px',
              }}
              disabled={readOnly}
              icon={<CheckCircleOutlined style={{ color: 'white' }} />}
              onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                // create an reviewedAuditFinding
                createReviewedAuditFinding({
                  reviewedAuditFinding: {
                    auditFindingId,
                    authorId,
                    accepted: true,
                  },
                  afclFilter,
                }).then(async ({ data, error }) => {
                  if (error) {
                    console.error('createReviewedAuditFinding error:', error);
                    updateAuditFindingErrorHandler(error);
                  } else {
                    // todo dedupe this with the same code in declineAuditFindingModal -> maybe a hook for any of these updates??
                    const claimId =
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimId;
                    const ibLineId =
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimLineItemAudit
                        ?.batchClaimLineItem?.ibLineId ??
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimLineItemAudit
                        ?.batchClaimLineItem?.alaRowId;

                    if (claimId && ibLineId) {
                      const result = await claimRefresher({
                        claimId,
                        ibLineIds: [ibLineId],
                      }).catch(err => {
                        console.log(
                          'Error refreshing claim itemizedBillLines and Findings',
                          err
                        );
                        return { data: null, fetching: false, error: err };
                      });
                      message.success('Reviewed audit finding');
                    }
                  }
                });
              }}
            />
          </Tooltip>
        )}
        {!readOnly && (!reviewed || reviewed.accepted) && (
          <Tooltip title="Decline" key="tt_decline">
            <Button
              data-cy={'claim-line-finding-btn-decline'}
              loading={fetching}
              size="small"
              style={{
                backgroundColor: 'red',
                borderColor: 'red',
                marginRight: '2px',
              }}
              disabled={readOnly}
              icon={<CloseCircleOutlined style={{ color: 'white' }} />}
              onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                setDecliningAuditFinding({
                  visible: true,
                  auditFinding,
                });
              }}
            />
          </Tooltip>
        )}
        {!readOnly &&
          (!navigateToIbinRow || !hasIbinRowIds) &&
          hashParams['IBIN'] !== undefined && (
            <>
              <LineReviewFindingButton
                finding={auditFinding}
                fetching={fetching}
                readOnly={readOnly}
                ibData={ibData}
              ></LineReviewFindingButton>
            </>
          )}
        {!readOnly &&
          (!navigateToIbinRow || !hasIbinRowIds) &&
          hashParams['IBIN'] === undefined && (
            <>
              <Tooltip title="Review" key="tt_review_ub_tab">
                <Button
                  data-cy={'claim-line-finding-btn-review-old'}
                  type="ghost"
                  size="small"
                  disabled={readOnly}
                  style={{ marginRight: '2px' }}
                  icon={<EditOutlined />}
                  onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                    setCreatingAuditFinding({
                      visible: true,
                      title: 'Review Finding',
                      auditFinding,
                      batchClaimLine,
                      updateAuditFindingErrorHandler,
                      auditFindingSeedType: auditFinding.auditFindingSeedType,
                      ibData,
                    });
                  }}
                />
              </Tooltip>
            </>
          )}
        {navigateToIbinRow && hasIbinRowIds && (
          <Tooltip title="Show finding in IBIN tab" key="tt_nav_to_ib_row">
            <Button
              data-cy={'claim-line-finding-btn-show-ibin-finding'}
              size="small"
              style={{ marginRight: '2px' }}
              icon={<AimOutlined />}
              onClick={event => {
                event.stopPropagation();
                navigateToIbinRow({
                  rowId: toSlug(
                    auditFinding.batchClaimLineItemAudit.batchClaimLineItem
                      .ibLineId
                  ),
                  findingId: toSlug(auditFinding.id),
                });
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <Space data-cy={'claim-line-finding-status-tags'}>
      {superseded ? <Tag color="default">Superseded</Tag> : activeFindingTags()}
    </Space>
  );
};

export {
  ClaimWorkspaceAuditFindingList,
  ClaimWorkspaceAuditFinding,
  AuditFindingTags,
  AuditFindingActions,
};
