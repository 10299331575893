import { createContext, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useQuery } from 'urql';
import tw from 'twin.macro';

import { ClaimWorkspace } from './claimWorkspace/claimWorkspace';
import { AmaVerbiage } from './amaVerbiage';

import { UserContext } from '../context/user';

const RefreshBatchClaimContext = createContext(null);
// Renders the ClaimWorkspace
// and the AMA verbiage footer
const AuditWorkspace = ({ setDocumentationViewerProps }) => {
  const { batchClaimId } = useParams();
  const {
    workstation: { query },
  } = useContext(UserContext);

  const [{ fetching, data, error }, refreshQuery] = useQuery({
    query,
    variables: { batchClaimId, afclFilter: { isActive: { equalTo: true } } },
    // requestPolicy: 'network-only',
  });

  return !data && fetching ? (
    <div style={{ textAlign: 'center', marginTop: '5%' }}>
      <Spin size="large" />
    </div>
  ) : (
    <div tw="_flex _flex-col _h-full _min-h-full _max-h-full _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer">
      <div tw="_grow">
        <RefreshBatchClaimContext.Provider
          value={args => {
            refreshQuery(args ?? {});
          }}
        >
          <ClaimWorkspace
            {...{
              style: { height: '100%', minHeight: '100%', padding: 0 },
              batchClaim: data.batchClaim,
              setDocumentationViewerProps,
            }}
          />
        </RefreshBatchClaimContext.Provider>
      </div>
      <div>
        <AmaVerbiage id="auditor_workspace_ama_verbiage" />
      </div>
    </div>
  );
};

export { AuditWorkspace, RefreshBatchClaimContext };
