import React from 'react';
import { css } from 'twin.macro';
import { Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AuditFindingRuleType } from '../types';

export enum RationaleUpdateModalOptions {
  APPEND_EXISTING = 'append-existing',
  APPEND_NEW = 'append-new',
  KEEP_EXISTING = 'keep-existing',
  REPLACE = 'replace',
}

export const rationaleUpdateModal = (
  ruleType: AuditFindingRuleType,
  onOk: (
    ruleType: AuditFindingRuleType,
    choice: RationaleUpdateModalOptions
  ) => void,
  onCancel: () => void
) => {
  let choice: RationaleUpdateModalOptions =
    RationaleUpdateModalOptions.APPEND_EXISTING;

  const onRadioChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    choice = e.target.value as RationaleUpdateModalOptions;
  };

  return Modal.confirm({
    icon: <ExclamationCircleOutlined />,
    title: 'Replace edited rationale?',
    content: (
      <RationaleUpdateModal onChange={onRadioChange}></RationaleUpdateModal>
    ),
    onOk: () => onOk(ruleType, choice!),
    onCancel: () => onCancel(),
  });
};

export const RationaleUpdateModal = ({
  onChange,
}: {
  onChange: (e: RadioChangeEvent) => void;
}) => {
  return (
    <div
      css={css({
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        padding: '0 0.5em 0 0.5em',
        '> div > div': {
          margin: '1em 0',
        },
      })}
    >
      <div>
        <div>
          The default Rationale Template for the previous Denial Code selection
          has been modified.
        </div>
        <div>
          Choose an option below to keep or replace the modified text, and click
          OK to accept the change. To cancel the update and restore the previous
          Denial Code value, click Cancel.
        </div>
        <Radio.Group
          onChange={onChange}
          defaultValue={RationaleUpdateModalOptions.APPEND_EXISTING}
        >
          <Space direction="vertical">
            <Radio value={RationaleUpdateModalOptions.APPEND_EXISTING}>
              Keep both, new rationale first
            </Radio>
            <Radio value={RationaleUpdateModalOptions.APPEND_NEW}>
              Keep both, existing rationale first
            </Radio>
            <Radio value={RationaleUpdateModalOptions.REPLACE}>
              Replace existing with new rationale
            </Radio>
            <Radio value={RationaleUpdateModalOptions.KEEP_EXISTING}>
              Keep existing
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};
