import { gql } from 'urql';

// Auditor User
const auditorUserFragment = gql`
  fragment auditorUserFragment on AuditorUser {
    id
    firstName
    lastName
    userType
    email
  }
`;

// Queues
const queueFragment = gql`
  fragment queueFragment on Queue {
    id
    createdAt
    name
    author {
      ...auditorUserFragment
    }
    description
    indexedFilterTags
  }
  ${auditorUserFragment}
`;

const queueAuthorFragment = gql`
  fragment queueAuthorFragment on AuditorUser {
    id
    authoredQueues {
      nodes {
        ...queueFragment
      }
    }
  }

  ${queueFragment}
`;

export {
  // auditor user
  auditorUserFragment,
  // queues
  queueFragment,
  queueAuthorFragment,
};
