import React from 'react';

import { gql, useQuery } from 'urql';

import { providerFragment, batchClaimState } from '../../../fragments';
import { Drawer } from 'antd';

import { BatchClaimMetaData } from './batchClaimMetaData';

import { EventHistoryTimeline } from './eventHistoryTimeline';
import { UserContext } from '../../context/user';
import { ClaimActionMenu } from '../../claimActionMenu/claimActionMenu';

const BatchClaimOverviewDrawer = ({
  batchClaimId,
  isVisible,
  onClose,
  width,
  placement,
  setClaimActionMenuIsActionLoading,
  isActionLoading,
}) => {
  const { userType, permissions } = React.useContext(UserContext);

  const [{ fetching, error, data }] = useQuery({
    query: gql`
    query batchClaimAssigneeAuthorAndProviderInfoQuery($batchClaimId: UUID!) {
      batchClaim(id: $batchClaimId) {
        id
        computationStrategy
        paymentRate
        provider {
          ...providerFragment
        }
        ...batchClaimState
        ${
          !['PAYER', 'PROVIDER'].includes(userType)
            ? `
                activeDueDate: batchClaimStateById {
                  id
                  dueDate
                }
                activeAssignees {
                  nodes {
                    id
                    firstName
                    lastName
                  }
                }
            `
            : ''
        }
      }
    }
  
    ${batchClaimState}
    ${providerFragment}
  `,
    variables: {
      batchClaimId,
    },
    pause: !batchClaimId,
  });

  return (
    <Drawer
      data-cy="claim-list-table-batch-claim-overview-drawer"
      id="claim-list-table-batch-claim-overview-drawer"
      key="claim-list-table-batch-claim-overview-drawer"
      width={width ? width : '550'}
      title={
        <span>
          <span css={{ paddingRight: '50%' }}>Batch Claim Overview</span>
          {!fetching && data && (
            <span
              id={'batch-claim-overview-claim-action-menu'}
              key={'batch-claim-overview-claim-action-menu'}
            >
              <ClaimActionMenu
                setIsActionLoading={setClaimActionMenuIsActionLoading}
                batchClaims={[data.batchClaim]}
              />
            </span>
          )}
        </span>
      }
      placement={placement ? placement : 'right'}
      onClose={onClose}
      open={isVisible}
    >
      {!fetching && data && (
        <div>
          <BatchClaimMetaData
            batchClaim={data.batchClaim}
            permissions={permissions}
          />
          <EventHistoryTimeline
            batchClaim={data.batchClaim}
            refreshTrigger={!isActionLoading}
          />
        </div>
      )}
    </Drawer>
  );
};

export { BatchClaimOverviewDrawer };
