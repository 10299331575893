// external
import { useQuery } from 'urql';
import { useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';
import tw, { css } from 'twin.macro';

// queries
import { queueQuery } from '../../graphql/queries';

// components and classes
import { ClaimListTable } from './claimListTable';
import { BatchClaimSearchFilter } from '../search/batchClaimSearchFilter';
import { FilterTags } from '../search/search';

const Queue = ({ setDocumentationViewerProps }) => {
  const {
    params: { queueId },
  } = useRouteMatch();

  const [{ fetching, error, data }] = useQuery({
    query: queueQuery,
    variables: { queueId: queueId },
  });

  const batchClaimFilter = new BatchClaimSearchFilter(
    data && data.queue.indexedFilterTags
  );

  return (
    <div tw="_bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer">
      {data && batchClaimFilter ? (
        <div>
          <div
            css={css`
              padding-left: 10px;
              padding-top: 15px;
              padding-bottom: 15px;
            `}
          >
            Filter: <FilterTags filter={batchClaimFilter} />
          </div>
          <ClaimListTable
            setDocumentationViewerProps={setDocumentationViewerProps}
            batchClaimFilter={batchClaimFilter.gqlFilter}
            tableName={data.queue.name}
            tableDescription={data.queue.description}
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '5%' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export { Queue };
