import { Form, Radio } from 'antd';
import { FormExtra } from '../../../../shared/components/form/formExtra';
import React, { FC } from 'react';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';
import { AuditFindingSeedType } from '../../../createAuditFinding/auditFindingUtil';

const displayNameMap = {
  UB_CLAIM: [{ label: 'Entire Claim', value: 'UB_CLAIM' }],
  UB_CLAIM_LINE: [
    { label: 'Rev. Code', value: 'UB_CLAIM_LINE' },
    { label: 'Itemized', value: 'IB_CLAIM_LINE' },
  ],
  IB_CLAIM_LINE: [{ label: 'Itemized', value: 'IB_CLAIM_LINE' }],
};

export type SeedTypeProps = CommonFieldProps & {
  formLayout?: any;
};

export const SeedType: FC<SeedTypeProps> = ({
  dataKey,
  label = 'Type',
  initialValues,
  formLayout = {},
}) => {
  const { defaultValue, existingFindingValue } =
    useInitialValues<AuditFindingSeedType>(dataKey, initialValues);
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      tabIndex={-1}
      extra={<FormExtra visible={false}></FormExtra>}
      rules={[{ required: true, message: 'Select a finding type' }]}
      initialValue={existingFindingValue ?? defaultValue}
      {...formLayout}
    >
      <Radio.Group
        options={
          displayNameMap[
            (defaultValue ?? 'UB_CLAIM_LINE') as keyof typeof displayNameMap
          ]
        }
        optionType="button"
      />
    </Form.Item>
  );
};
