import { Button, message, Space, Tag, Tooltip } from 'antd';
import { gql, useMutation } from 'urql';
import { useClaimPostFindingRefresh } from '../../../queries/claim/useClaimFindingsAndLine';
import { useHashParams } from '../../../../../common/navigation/useHashParam';
import React, { FC, useContext } from 'react';
import { DesignProvider } from '../../../../app/design/designProvider';
import { isAutodorFinding } from '../../../util/findingType';
import { AutodorIcon } from '../../../../misc/autodorIcon';
import { LineConvertAudotorFindingButton } from '../claimLine/lineConvertAudotorFindingButton';
import accounting from 'accounting-js';
import {
  AimOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { LineReviewFindingButton } from '../claimLine/lineReviewFindingButton';
import { toSlug } from '../../../../../common/navigation/utils';
import { UUID } from '../../../../../../types/ids';
import { Finding } from '../../../claimWorkspace/findingEdit/types';
import {
  adminAndAuditorWorkstationBatchClaimFragment,
  auditFindingWithReviewFragment,
  reviewedAuditFindingFragment,
} from '../../../../../fragments';
import { IBGridLine } from '../../../claimWorkspace/itemizedBillTab/ibTable/types';
import { AuditFinding } from '../../../../../gql/graphql';
import { findingToAF } from '../../../queries/claim/interimUtils';

// TODO MOVE TO TYPED QUERIES:
const createReviewedAuditFindingMutation = gql`
  mutation createReviewedAuditFinding(
    $reviewedAuditFinding: ReviewedAuditFindingInput!
  ) {
    createReviewedAuditFinding(
      input: { reviewedAuditFinding: $reviewedAuditFinding }
    ) {
      reviewedAuditFinding {
        ...reviewedAuditFindingFragment
        auditFinding {
          ...auditFindingWithReviewFragment
          # update determined and potential amounts in the cache
          batchClaim {
            # because ONLY auditors & Admins can manipulate findings (Create, Decline, Review, etc) we don't select the return fragment based on users
            # and return the 'adminAndAuditorWorkstationBatchClaimFragment' by default
            ...adminAndAuditorWorkstationBatchClaimFragment
          }
        }
      }
    }
  }
  ${reviewedAuditFindingFragment}
  ${auditFindingWithReviewFragment}
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

export type AuditFindingActionsProps = {
  authorId: UUID;
  auditFinding: Finding;
  setDecliningAuditFinding: ({
    visible,
    auditFinding,
  }: {
    visible: boolean;
    auditFinding: AuditFinding;
  }) => void;
  navigateToIbinRow: ({
    rowId,
    findingId,
  }: {
    rowId: UUID;
    findingId: UUID;
  }) => void;
  updateAuditFindingErrorHandler: (e: any) => void;
  afclFilter: Record<string, any>;
  readOnly: boolean;
  hideReviewStatus: boolean;
  ibData: IBGridLine[];
  __typeName: string; // todo 'AutodorFinding' | 'Finding'
};

export const AuditFindingActions: FC<AuditFindingActionsProps> = ({
  authorId,
  auditFinding,
  setDecliningAuditFinding,
  navigateToIbinRow,
  updateAuditFindingErrorHandler, //: e => message.error(e.message),
  afclFilter,
  readOnly,
  hideReviewStatus,
  ibData,
  __typeName,
}: AuditFindingActionsProps) => {
  const { id: auditFindingId, improperPaymentCost, superseded } = auditFinding;

  const [{ fetching }, createReviewedAuditFinding] = useMutation(
    createReviewedAuditFindingMutation
  );
  const claimRefresher = useClaimPostFindingRefresh();

  const hasIbinRowIds =
    auditFinding?.findingItemizedBillData?.ibLineId ||
    auditFinding?.findingItemizedBillData?.alaRowId;

  const [hashParams] = useHashParams();

  const activeFindingTags = () => {
    const {
      options: { darkMode },
    } = useContext(DesignProvider);

    return isAutodorFinding(auditFinding) ? (
      <div data-cy={'claim-line-finding-status-tags _flex'}>
        <Tag
          icon={
            <span tw=" _rounded-full ">
              <AutodorIcon></AutodorIcon>
            </span>
          }
          tw="_bg-antd-lt-gold-2 dark:_bg-antd-dk-gold-6"
        >
          <span tw="_pl-1.5 _align-middle">Autodor</span>
        </Tag>
        <LineConvertAudotorFindingButton
          autodorFinding={auditFinding}
          fetching={fetching}
          readOnly={readOnly}
          ibData={ibData}
        ></LineConvertAudotorFindingButton>
      </div>
    ) : (
      <div data-cy={'claim-line-finding-status-tags'}>
        {auditFinding?.id === '' && (
          <Tag icon={<AutodorIcon></AutodorIcon>}>Suggested</Tag>
        )}
        {ibData && auditFinding?.findingItemizedBillData?.stale && (
          <Tag color="gold">Stale</Tag>
        )}
        {ibData && ibData[0]?.units && (
          <span tw="_text-[0.75em] _px-2 _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary">
            {auditFinding.improperPaymentUnitsCharged + '/' + ibData[0].units}
          </span>
        )}
        <Tag color={darkMode ? 'gray' : 'lightgray'}>
          {accounting.formatMoney(improperPaymentCost)}
        </Tag>
        {hideReviewStatus ? (
          ''
        ) : !auditFinding.needsReview ? (
          auditFinding.deleted ? (
            // Deleted reviews shouldn't exist for current findings, but may exist
            // on findings displayed in report version history (reportsVersionList hides via hideReviewStatus)
            // however, it's better to show deleted if it exists than inadvertently fall through to 'needs review'
            <Tag color="orange">Deleted</Tag>
          ) : auditFinding.accepted ? (
            <Tag color="green">Accepted</Tag>
          ) : (
            <Tag color={darkMode ? 'gray' : 'lightgray'}>Declined</Tag>
          )
        ) : (
          <Tag color="red">Needs Review</Tag>
        )}
        {!readOnly && (auditFinding.needsReview || !auditFinding.accepted) && (
          <Tooltip title="Accept" key="tt_accept">
            <Button
              data-cy={'claim-line-finding-btn-accept'}
              loading={fetching}
              size="small"
              style={{
                backgroundColor: '#52c41a',
                borderColor: '#52c41a',
                marginRight: '2px',
              }}
              disabled={readOnly}
              icon={<CheckCircleOutlined style={{ color: 'white' }} />}
              onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                // create an reviewedAuditFinding
                createReviewedAuditFinding({
                  reviewedAuditFinding: {
                    auditFindingId,
                    authorId,
                    accepted: true,
                  },
                  afclFilter,
                }).then(async ({ data, error }) => {
                  if (error) {
                    console.error('createReviewedAuditFinding error:', error);
                    updateAuditFindingErrorHandler(error);
                  } else {
                    // todo dedupe this with the same code in declineAuditFindingModal -> maybe a hook for any of these updates??
                    const claimId =
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimId;
                    const ibLineId =
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimLineItemAudit
                        ?.batchClaimLineItem?.ibLineId ??
                      data.createReviewedAuditFinding?.reviewedAuditFinding
                        ?.auditFinding?.batchClaimLineItemAudit
                        ?.batchClaimLineItem?.alaRowId;

                    if (claimId && ibLineId) {
                      const result = await claimRefresher({
                        claimId,
                        ibLineIds: [ibLineId],
                      }).catch(err => {
                        console.log(
                          'Error refreshing claim itemizedBillLines and Findings',
                          err
                        );
                        return { data: null, fetching: false, error: err };
                      });
                      message.success('Reviewed audit finding');
                    }
                  }
                });
              }}
            />
          </Tooltip>
        )}
        {!readOnly && !auditFinding.needsReview && auditFinding.accepted && (
          <Tooltip title="Decline" key="tt_decline">
            <Button
              data-cy={'claim-line-finding-btn-decline'}
              loading={fetching}
              size="small"
              style={{
                backgroundColor: 'red',
                borderColor: 'red',
                marginRight: '2px',
              }}
              disabled={readOnly}
              icon={<CloseCircleOutlined style={{ color: 'white' }} />}
              onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                setDecliningAuditFinding({
                  visible: true,
                  auditFinding: findingToAF(auditFinding, null),
                });
              }}
            />
          </Tooltip>
        )}
        {!readOnly && (!navigateToIbinRow || !hasIbinRowIds) && (
          <>
            <LineReviewFindingButton
              finding={auditFinding}
              fetching={fetching}
              readOnly={readOnly}
              ibData={ibData}
            ></LineReviewFindingButton>
          </>
        )}

        {/*TODO we'll need these in both directions when we complete UBIN table*/}
        {/*/!* TODO confirm hide logic *!/*/}
        {/*{navigateToIbinRow && hasIbinRowIds && (*/}
        {/*  <Tooltip title="Show finding in IBIN tab" key="tt_nav_to_ib_row">*/}
        {/*    <Button*/}
        {/*      data-cy={'claim-line-finding-btn-show-ibin-finding'}*/}
        {/*      // type="primary"*/}
        {/*      size="small"*/}
        {/*      // disabled={readOnly}*/}
        {/*      style={{ marginRight: '2px' }}*/}
        {/*      icon={<AimOutlined />}*/}
        {/*      onClick={event => {*/}
        {/*        event.stopPropagation();*/}
        {/*        navigateToIbinRow({*/}
        {/*          rowId: toSlug(*/}
        {/*            auditFinding?.findingItemizedBillData?.ibLineId || ''*/}
        {/*          ),*/}
        {/*          findingId: toSlug(auditFinding.id),*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
      </div>
    );
  };

  return (
    <Space data-cy={'claim-line-finding-status-tags'}>
      {superseded ? <Tag color="default">Superseded</Tag> : activeFindingTags()}
    </Space>
  );
};
