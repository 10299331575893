import { SWClient } from './swIpc/client';

export const registerSw = async () => {
  const result: {
    registration: ServiceWorkerRegistration | null;
    swClient: SWClient;
  } = {
    registration: null,
    swClient: new SWClient(),
  };

  // console.log('SW REG -- SWREGISTER');

  // ok parcel... this pattern must be exactly right or parcel generates
  // code with window.xxx checks that bomb:
  // https://github.com/parcel-bundler/parcel/issues/8852#issuecomment-1441567069
  // await navigator.serviceWorker.register(
  //    new URL("../backend/serviceWorker", import.meta.url),
  //    { type: "module", scope: "/api/" }
  // );
  result.registration = await navigator.serviceWorker
    .register(new URL('../sw', import.meta.url), {
      type: 'module',
      scope: '/',
    })
    .then(reg => {
      console.debug('sw scope', reg.scope);
      return reg;
    });

  await navigator.serviceWorker.ready.then(registration => {
    //console.log('sw ready');
  });

  await result.swClient
    .getReadyPromise()
    .then(r => console.debug('sw client ready'));

  return result;
};
