// external
import { useState } from 'react';
import { gql, useQuery } from 'urql';
import { Button, Table, Spin, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';

// internal
import { QueueTitle } from '../../queues/queues';
import { ColTitle } from '../../queues/util';
import TableColumnSelector from '../../misc/tableColumnSelector';

// filters
import {
  claimsAwaitingDocumentationFilter,
  claimsDocumentationReceivedFilter,
  claimsToDoFilter,
  claimsInProgressFilter,
  claimsCompletedFilter,
} from '../../../graphql/filters';

// (AT) Deciding to leave this query here
// instead of moving it to the /graphql/queries file
// since it is closely coupled with this component. Will
// move if we decide to use this query elsewhere
const clientDefaultQueueQuery = gql`
  # query for all providers, security policy read_own_provider
  # restricts the queryable facilities
  query clientDefaultQueueQuery(
    $claimsToDoFilter: BatchClaimFilter!
    $claimsInProgressFilter: BatchClaimFilter!
    $claimsCompletedFilter: BatchClaimFilter!
    $claimsAwaitingDocumentationFilter: BatchClaimFilter!
    $claimsDocumentationReceivedFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
  ) {
    insurersAssignedToAuditor(first: $first, offset: $offset) {
      totalCount
      nodes {
        id
        name
        assignedClaims: claimsAssignedToAuditor {
          totalCount
        }
        claimsToDo: claimsAssignedToAuditor(filter: $claimsToDoFilter) {
          totalCount
        }
        claimsInProgress: claimsAssignedToAuditor(
          filter: $claimsInProgressFilter
        ) {
          totalCount
        }
        claimsCompleted: claimsAssignedToAuditor(
          filter: $claimsCompletedFilter
        ) {
          totalCount
        }
        claimsAwaitingDocumentation: claimsAssignedToAuditor(
          filter: $claimsAwaitingDocumentationFilter
        ) {
          totalCount
        }
        claimsDocumentationReceived: claimsAssignedToAuditor(
          filter: $claimsDocumentationReceivedFilter
        ) {
          totalCount
        }
      }
    }
  }
`;

/**
 * The default queues rendered on the <Dashboard />
 * component for auditor users, returns a list of
 * clients for the logged in auditor users with the
 * claim counts for all claims they are assigned to
 * @returns <Table<Provider>>
 */
const AuditorClientQueues = () => {
  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });

  // data state
  const [{ fetching, data, error }, clientQueueRefreshQuery] = useQuery({
    query: clientDefaultQueueQuery,
    variables: {
      ...paginationArgs,
      claimsAwaitingDocumentationFilter,
      claimsDocumentationReceivedFilter,
      claimsToDoFilter,
      claimsInProgressFilter,
      claimsCompletedFilter,
    },
  });

  // browser state
  const history = useHistory();

  // columns need to be inside of the react
  // component so it can use the useHistory() hook
  const columns = [
    {
      title: (
        <ColTitle
          tooltipTitle="Name of client"
          title="Client Name"
          id="client-name"
        />
      ),
      dataIndex: 'name',
      key: 'clientName',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name}/` +
                JSON.stringify({
                  insurerId: [record.id],
                })
            )
          }
        >
          {record.name}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims assigned to Auditor"
          title="Assigned Claims"
          id="assigned-claims"
        />
      ),
      key: 'assignedClaims',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - All Claims/` +
                JSON.stringify({
                  insurerId: [record.id],
                })
            )
          }
        >
          {record.assignedClaims.totalCount}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims to do"
          title="Claims To Do"
          id="claims-toDO"
        />
      ),
      key: 'claimsToDo',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - To Do/` +
                JSON.stringify({
                  insurerId: [record.id],
                  workflow: ['TO_DO'],
                })
            )
          }
        >
          {record.claimsToDo.totalCount}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims in progress"
          title="Claims In Progress"
          id="claims-in-progress"
        />
      ),
      key: 'claimsInProgress',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - In Progress/` +
                JSON.stringify({
                  insurerId: [record.id],
                  workflow: ['IN_PROGRESS'],
                })
            )
          }
        >
          {record.claimsInProgress.totalCount}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims that are completed"
          title="Claims Completed"
          id="claims-completed"
        />
      ),
      key: 'claimsCompleted',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - Review Completed/` +
                JSON.stringify({
                  insurerId: [record.id],
                  workflow: ['COMPLETED'],
                })
            )
          }
        >
          {record.claimsCompleted.totalCount}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims with documentation requests"
          title="Documentation Requested"
          id="claims-documentation-request"
        />
      ),
      key: 'claimsDocumentationRequested',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - Awaiting Documentation/` +
                JSON.stringify({
                  insurerId: [record.id],
                  documentation: ['AWAITING_UPLOAD'],
                })
            )
          }
        >
          {record.claimsAwaitingDocumentation.totalCount}
        </Button>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of claims that have received documentation"
          title="Documentation Received"
          id="claims-documentation-received"
        />
      ),
      key: 'claimsDocumentationReceived',
      default: true,
      render: (_text, record) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.name} - Documentation Received/` +
                JSON.stringify({
                  insurerId: [record.id],
                  documentation: ['DOCUMENTS_RECEIVED'],
                })
            )
          }
        >
          {record.claimsDocumentationReceived.totalCount}
        </Button>
      ),
    },
  ];

  // state for the selected columns - filter columns by 'default'
  // to determine whether this column should show when the page loads.
  // This state is an array of column.key values
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter(col => col.default).map(i => i.key)
  );

  const paginationConfig = {
    current: currentPageNumber,
    position: ['bottomLeft'],
    pageSizeOptions: ['10', '25', '30', '50'],
    showSizeChanger: true,
    pageSize: 10,
    total: data && data.insurersAssignedToAuditor.totalCount,
    onChange: (nextPage, pageSize) => {
      setPaginationOptions({ pageSize });
      setPaginationArgs({
        first: pageSize,
        offset:
          nextPage > 1 ? (nextPage - 1) * paginationOptions.pageSize : null,
      });
      setCurrentPageNumber(nextPage);
      clientQueueRefreshQuery();
    },
    ...paginationOptions,
  };

  return (
    <div
      id="auditor-client-default-queues"
      data-cy="auditor-client-default-queues"
    >
      {!fetching && data ? (
        <Table
          title={() => (
            <>
              <span>
                <QueueTitle title="Clients" />
              </span>
              <span
                css={css`
                  padding-left: 1%;
                `}
              >
                <TableColumnSelector
                  columns={columns}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </span>
            </>
          )}
          pagination={paginationConfig}
          // determine the columns to render by filtering the column.key values
          // by the keys present in selectedColumns[]
          columns={columns.filter(({ key }) => selectedColumns.includes(key))}
          dataSource={data.insurersAssignedToAuditor.nodes.map((node, i) => {
            node.key = `auditor-client-queues-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
            return node;
          })}
        />
      ) : (
        <div
          css={css`
            text-align: center;
            margin-top: 5%;
          `}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export { AuditorClientQueues };
