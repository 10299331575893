import tw, { css } from 'twin.macro';
import { toFormattedDate } from '../../../util/date';
import { uniq } from 'lodash';
import { ItemizedBillLine } from '../../../../../gql/graphql';

const toJoined = (
  key: keyof ItemizedBillLine,
  ibLines: ItemizedBillLine[],
  formatter?: (v: any) => string
) =>
  uniq(
    ibLines
      .filter((en: ItemizedBillLine) => !!en)
      .map((en: ItemizedBillLine) => (formatter ? formatter(en[key]) : en[key]))
  ).join(', ');

export type IbLineInfoProps = {
  ibLines?: ItemizedBillLine[];
};

export const IbLineInfo: React.FC<IbLineInfoProps> = ({ ibLines }) => {
  return ibLines ? (
    <div
      css={css({
        ...tw`_grid _grid-cols-8 _text-xs _py-2`,
        '& > div': tw`_px-2 _py-0.5`,
        '& > div.val': tw`_col-span-6 _h-4 _line-clamp-1 _break-all`,
        '& > div.lbl': tw`_col-span-2 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary`,
      })}
    >
      <div className="lbl">Date of Service</div>
      <div className="val">
        {toJoined('dateOfService', ibLines, toFormattedDate)}
      </div>

      <div className="lbl">CPT/HCPCS Code</div>
      <div className="val">{toJoined('procedureCode', ibLines)}</div>

      <div className="lbl">IB Item Number</div>
      <div className="val">{toJoined('itemNumber', ibLines)}</div>

      <div className="lbl">Service Name</div>
      <div className="val">{toJoined('description', ibLines)}</div>

      <div className="lbl">Billed Quantity</div>
      <div className="val">{toJoined('units', ibLines)}</div>

      <div className="lbl">Billed Amount</div>
      <div className="val">{toJoined('totalCharge', ibLines)}</div>
    </div>
  ) : (
    <> </>
  );
};
