import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLog } from '../log/useLog';
import { PdfViewerControlsEx, PdfViewerCtx } from './types';
import { useObservableSubscription } from '../../util/Observable';
import PdfViewerInstance, { enablePdfViewer } from '../../pdf/viewer';
import pdfContainer from '../../pdf/container';
import { message } from 'antd';
import { gql, useQuery } from 'urql';

export const PdfViewerContext = createContext<PdfViewerCtx>({} as PdfViewerCtx);

const foxitCredentialsQuery = gql`
  query FoxitCredentialsQuery {
    foxitCredentials {
      sn
      key
    }
  }
`;

export const PdfViewerProvider: FC<{
  loggedIn: boolean;
  children: ReactNode;
}> = ({ loggedIn, children }) => {
  const log = useLog('pdfViewerProvider');
  //log.setLevel(log.levels.DEBUG, false);

  log.css = 'color: red; background-color: yellow';

  const pdfViewerInstance = useObservableSubscription(PdfViewerInstance);

  // handle logout
  useEffect(() => {
    if (!loggedIn && pdfContainer && pdfContainer.isOpen()) {
      pdfContainer.close();
      if (pdfViewerInstance) {
        pdfViewerInstance.closeDocument();
      }
    }
  }, [loggedIn]);

  const [{ fetching, data: foxitCredentialsResponse, error }] = useQuery({
    query: foxitCredentialsQuery,
    pause: !loggedIn,
  });

  useEffect(() => {
    if (!pdfViewerInstance && foxitCredentialsResponse?.foxitCredentials) {
      const creds = foxitCredentialsResponse.foxitCredentials;
      log.debug('viewer credentials received, enabling viewer', {
        details: {
          sn:
            typeof creds.sn === 'string'
              ? creds.sn.substring(0, 16) + '...'
              : '?',
          key:
            typeof creds.key === 'string'
              ? creds.key.substring(0, 16) + '...'
              : '?',
        },
      });
      enablePdfViewer(creds);
    }
  }, [foxitCredentialsResponse]);

  const pdfViewerControls: PdfViewerCtx = useMemo(
    () => ({
      getViewer: (claimId: string) => {
        if (!loggedIn) {
          throw new Error('Please login before retrieving viewer.');
        }
        const answer: PdfViewerControlsEx = {
          name: 'pdfViewerProvider_viewer_instance',

          get ready() {
            return !!pdfViewerInstance;
          },

          get isOpen() {
            return pdfContainer.isOpen();
          },

          setIsOpen: (val: boolean, claimId: string) => {
            if (!loggedIn) {
              if (pdfContainer) {
                pdfContainer.close();
              }
              throw new Error('Please login before opening viewer.');
            }
            val ? pdfContainer.open() : pdfContainer.close();
          },

          setPage: (page: number) => pdfViewerInstance?.gotoPage(page),

          getNavHtmlElement: () => pdfContainer?.refs?.getNav() ?? null,

          getTitleHtmlElement: () => pdfContainer?.refs?.getTitle() ?? null,

          getOverlayHtmlElement: () => pdfContainer?.refs?.getOverlay() ?? null,

          loadDocument: ({
            url,
            filename,
            documentId,
            expiresMs,
            fileSize,
            pageNumber = 0,
          }) => {
            if (!loggedIn) {
              message.error('Please login before opening viewer.');
              return Promise.resolve({ documentId: '' });
            }
            if (!pdfViewerInstance) {
              message.error('PDF Viewer not loaded.');
              return Promise.resolve({ documentId: '' });
            }
            return pdfViewerInstance.loadDocument({
              url,
              filename,
              documentId,
              expiresMs,
              fileSize,
              page: pageNumber,
            });
          },
          closeDocument: () => {
            if (!pdfViewerInstance) {
              log.warn('No pdf viewer instance at closeDocument...');
              return;
            }
            return pdfViewerInstance.closeDocument();
          },
          getCurrentDocumentName: () => {
            if (!pdfViewerInstance) {
              message.error('PDF Viewer not loaded.');
              return null;
            }
            return pdfViewerInstance.getCurrentDocumentName();
          },
          getCurrentDocumentId: () => {
            if (!pdfViewerInstance) {
              message.error('PDF Viewer not loaded.');
              return null;
            }
            return pdfViewerInstance.getCurrentDocumentId();
          },

          // TODO do we want to restrict access to these at all?
          //  The assumed use would be thru usePdfViewer so that can restrict as
          //  a 'guideline' so they're not likely to be accidentally misused...
          pdfViewerInstance,
          pdfContainer,
        };
        return answer;
      },
    }),
    [loggedIn, pdfViewerInstance, pdfContainer]
  );

  const renderCount = React.useRef(0);
  renderCount.current++;
  log.trace(
    '%c (%s) render pdf provider',
    'color: #dd8888',
    renderCount.current
  );

  return (
    <PdfViewerContext.Provider value={pdfViewerControls}>
      {children}
    </PdfViewerContext.Provider>
  );
};
