import { dollarStatProps } from '../util';
import { Statistic, Tooltip } from 'antd';

import { css } from '@emotion/react';

/* 
    - Returns a JSX component that renders the specific stat
    - Takes as props { statKey, progressStatesObj } where:
      - statKey: <str: name of the stat> i.e 'state.onHold.ON_HOLD' or 'stat.determinedAdjustedAllowedAmount'
        - the key used to access the progressStatesObj props object
        - This key comes from the showAsStats field on the auditProgress object in the user context 
      - progressStatesObj: <Obj: the formatted and filtered version of the return from the auditProgressQuery>
        - The Keys are the name of the stateType (accessibleby the statKey prop)
        - The keys ultimately map to a props object that represents the value of the statistic and the required props to render that stat
*/
const AuditProgressSingleStatistic = ({ statKey, progressStatesObj }) => {
  const [isStateAndNotStat, stateType, stateName] = statKey.split('.');
  /* 
    'statPropsSource' is 'progressStatesObj' indexed to the correct stateType resulting in the exact props needed to render the statistic
   */
  const statPropsSource =
    isStateAndNotStat === 'state' && isStateAndNotStat !== 'stat' && stateName
      ? /* 
          If statKey has a prefix of 'state' and is NOT 'stat'
          that means it represents a state object (i.e state.onHold.ON_HOLD)
          By splitting the string on '.' we will retrieve 3 values (isStateAndNotStat identifier, stateType, and stateName) 
          We can use stateType and stateName to access the specific props object under progressStatesObj i.e progressStatesObj.onHold.ON_HOLD
        */
        progressStatesObj[stateType][stateName]
      : /* 
          If statKey has a prefix of 'stat' and is NOT 'state' that means it represents a stat object. (i.e stat.determinedAdjustedAllowedAmount)
          By splitting the string on '.' we will retrieve 2 values (isStateAndNotStat identifier and stateType) 
          We can use stateType to access the specific props object under progressStatesObj i.e progressStatesObj.determinedAdjustedAllowedAmount
        */
        progressStatesObj[stateType];

  return (
    <Tooltip
      key={`progress-card-stats-${statKey}-tooltip`}
      id={`progress-card-stats-${statKey}-tooltip`}
      placement="topLeft"
      title={statPropsSource.description}
    >
      <Statistic
        key={`progress-card-stats-${statKey}-statistic`}
        id={`progress-card-stats-${statKey}-statistic`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        }}
        title={
          <span
            key={`progress-card-stats-${statKey}-statistic-title-span`}
            id={`progress-card-stats-${statKey}-statistic-title-span`}
            css={css`
              margin-right: 5;
              font-size: 12;
            `}
          >
            {/*
                All "States" are represented with a 'icon' field on their state object and render their state Icon
                All "Stats" are represented with a 'title' field on their state object that can either be an Icon or a string such as 'Pot Adj Allowed Amount'
            */}
            {isStateAndNotStat === 'state' && isStateAndNotStat !== 'stat'
              ? statPropsSource.icon
              : statPropsSource.title}
          </span>
        }
        value={statPropsSource.totalCount}
        valueStyle={{ fontSize: '13px', marginLeft: '3px' }}
        {...(statPropsSource.isDollar && dollarStatProps)}
      />
    </Tooltip>
  );
};

export { AuditProgressSingleStatistic };
