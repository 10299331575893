import tw from 'twin.macro';
import { AskAutodorButton } from '../../components/audit/askAutodor/askAutodorButton';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  AuditDocumentation,
  AuditDocumentationProcessTypes,
} from '../../gql/graphql';
import { gql, useQuery } from 'urql';
import { batchClaimDocumentationFragment } from '../../fragments';
import { useLog } from '../log/useLog';
import { Button, Input, message } from 'antd';
import { AskAutodorFileChooser } from '../../components/audit/askAutodor/askAutodorFileChooser';
import pdfContainer from '../../pdf/container';
import { createPortal } from 'react-dom';
import { DocumentViewerNav } from '../../components/documentViewer/documentViewerNav';
import { mapFilesToUiFiles } from '../../components/documentViewer/documentViewerUtil';
import { UiAuditDocumentation } from '../../components/documentViewer/types';
import PdfViewerInstance from '../../pdf/viewer';
import { takeWhile } from 'rxjs';
import { useObservableSubscription } from '../../util/Observable';
import { PdfViewerContext } from '../pdf/pdfViewerProvider';
import { usePdfViewer } from '../pdf/usePdfViewer';
import { title } from 'process';
// import PdfViewerInstance from '../../pdf/apryseViewer';

import { Broadcast } from '../../common/util/class/broadcast';
import {
  SignedUrlUpdater,
  SignedUrlUpdaterResponse,
} from '../../sw/swIpc/client';
import { SwClientContext } from '../../sw/swProvider';
// import {
//   docServiceChannel,
//   DocServiceClient,
//   DocServiceWorker,
// } from '../../pdf/birpc';
// import { createBirpc } from 'birpc';

export const AA: React.FC = () => {
  const [askAutodorOpen, setAskAutodorOpen] = React.useState<boolean>(false);
  // const claimId = '03bc6e68-0092-527a-bf7e-65467c88fa9e';
  const claimId = 'dca27b39-ca00-5521-883d-18511109e8e3';
  const url = `https://rc-1587.staging.alaffiahealth.com/workspace/${claimId}#UB`;

  // const broadcastCnt = useRef(0);
  // ///////
  // const broadcast = useMemo(() => {
  //   const bc = new Broadcast('docs-service-worker');
  //   console.log('Subscribing to broadcast channel....');
  //   bc.observable.subscribe(msg => {
  //     console.log(
  //       '%c dsw msg',
  //       'color: orange; background-color:lightblue',
  //       msg
  //     );
  //     broadcastCnt.current++;
  //     bc.sendMessage({
  //       type: 'aa-resp',
  //       data: { bcCount: broadcastCnt.current },
  //     });
  //   });
  //   return bc;
  // }, []);

  // const workerRpc: DocServiceClient = {
  //   getSignedUrl: (docId: string) => {
  //     return { docId, signedUrl: 'xyzabc', expires: new Date() };
  //   },
  // };
  //
  // const rpc = createBirpc<DocServiceWorker, DocServiceClient>(workerRpc, {
  //   post: data => docServiceChannel.port1.postMessage(data),
  //   on: data => docServiceChannel.port2.addEventListener('message', data),
  // });
  //
  // rpc
  //   .isReady('ImaCaller')
  //   .then(r => console.log('%c Ready r', 'color: red', r));

  const {
    pdfViewer,
    currentDocumentId,
    currentDocumentName,
    currentPageNumber,
  } = usePdfViewer();

  // useEffect(() => {
  //   console.log('PDF VIEWER', {
  //     currentDocumentName,
  //     currentPageNumber,
  //     currentDocumentId,
  //     pdfViewer,
  //   });
  // }, [pdfViewer, currentDocumentName, currentDocumentId, currentPageNumber]);

  // const [document, setDocument] = React.useState<UiAuditDocumentation | null>(
  //   null
  // );

  const setDocumentationViewerProps = () => {
    console.error('Not implemented');
  };
  const [{ fetching, data, error }, reexecuteQuery] = useQuery({
    query: gql`
      query documentationViewer($batchClaimId: UUID!) {
        batchClaim(id: $batchClaimId) {
          ...batchClaimDocumentationFragment
        }
      }
      ${batchClaimDocumentationFragment}
    `,
    variables: { batchClaimId: claimId },
    requestPolicy: 'network-only',
    pause: !claimId,
  });

  const documents: UiAuditDocumentation[] = useMemo(() => {
    return mapFilesToUiFiles(
      data?.batchClaim?.auditDocumentation ?? [],
      claimId
    );
  }, [data]);

  // SIGNED URL UPDATER REG
  const signedUrlUpdater: SignedUrlUpdater = useCallback<SignedUrlUpdater>(
    async (documentId: string) => {
      // TODO WE NEED TO SWITCH TO A PROMISABLE URLQ FETCH...
      console.log(`Search ${documentId} in `, documents);
      const f = documents.find(f1 => f1.id === documentId);
      if (!f) {
        return Promise.reject(
          `Document id for document ${documentId} not found!`
        );
      }
      return Promise.resolve<SignedUrlUpdaterResponse>({
        documentId,
        url: f.signedUrl,
        expires: f.expiresMs,
      });
    },
    [documents]
  );
  const swClient = useContext(SwClientContext);
  useEffect(() => {
    if (swClient) {
      swClient.registerSignedUrlUpdater(signedUrlUpdater);
    }
    return () => {
      if (swClient) {
        swClient.removeSignedUrlUpdater();
      }
    };
  }, [swClient, documents]);

  const log = useLog('playground -aa');
  log.css = 'background-color: lightpink;';

  const document = useMemo(
    () => documents.find(d => d.id === currentDocumentId) ?? null,
    [currentDocumentId]
  );

  const setDocument = useCallback(
    (
      document: UiAuditDocumentation | null,
      page: number = currentPageNumber ?? 0
    ) => {
      if (pdfViewer?.ready && document) {
        pdfViewer.loadDocument({
          url: document.signedUrl,
          filename: document.fileName,
          documentId: document.id,
          expiresMs: document.expiresMs,
          fileSize: document.size,
          pageNumber: page,
        });
      }
    },
    [pdfViewer]
  );

  const go = useCallback(
    (page: number) => {
      setDocument(document, page);
    },
    [document]
  );

  const [fooVal, setFooVal] = React.useState<string>('');

  const titleEl = pdfViewer?.getTitleHtmlElement();
  const navEl = pdfViewer?.getNavHtmlElement();

  return (
    <div tw="_w-full _h-full _bg-amber-50">
      {/*MANUAL INSTALL OF THE VIEWER COMPS*/}
      {titleEl &&
        createPortal(
          <>
            [NODOCVWR] - {currentDocumentName} - {currentPageNumber}
          </>,
          titleEl
        )}
      {navEl &&
        createPortal(
          <div className="_w-fit _h-full _flex _mr-2  _content-center _align-middle _self-center">
            <DocumentViewerNav
              claimId={claimId}
              files={documents}
              activeFile={
                documents.find(d => d.id === currentDocumentId) ?? null
              }
              setActiveFile={file => setDocument(file)}
              fetching={fetching}
            ></DocumentViewerNav>
          </div>,
          navEl
        )}
      <div>Ask Autodor -- {fooVal}</div>

      <div tw="_flex _content-center _align-middle _self-center">
        <div tw="_flex _content-center _align-middle _self-center">
          <Button
            size="small"
            onClick={() => {
              console.log('SHOW viewer', pdfContainer);
              pdfViewer?.setIsOpen(true, claimId);
            }}
          >
            Show
          </Button>
        </div>
        {[
          3, 7, 9, 29, 99, 499, 999, 1599, 3999, 8999, 9999, 1000, 1299, 14999,
          15098, 15099, 15100, 15101, 15102,
        ].map(page => (
          <div tw="_flex _content-center _align-middle _self-center _text-xs">
            <Button size="small" onClick={() => go(page)}>
              {page}
            </Button>
          </div>
        ))}
      </div>

      <AskAutodorButton
        documents={documents}
        askAutodorOpen={askAutodorOpen}
        setAskAutodorOpen={setAskAutodorOpen}
        claimId={claimId}
        setDocumentationViewerProps={setDocumentationViewerProps}
      ></AskAutodorButton>

      <AskAutodorFileChooser
        documents={documents}
        document={document}
        setDocument={(doc: UiAuditDocumentation) => {
          console.log('SETDOC', doc);
          setDocument(doc);
        }}
      ></AskAutodorFileChooser>

      <div tw="_py-32">
        <div tw="_p-16 _border _border-solid">
          if ya feel the urge to <span tw="_text-orange-300">freak</span> do the
          jitterbug
          <div tw="_p-16 _border _border-solid">
            <Button onClick={() => message.info('Yes, you can!')}>
              Can I click it?
            </Button>
            <div tw="_m-8 _p-8 _border _border-solid">
              <Input
                name={'foo'}
                defaultValue="bar"
                onChange={val => setFooVal(val.target.value)}
              ></Input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
