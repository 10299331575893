import React, { ElementType } from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Switch as HuiSwitch } from '@headlessui/react';
import { SwitchProps } from '@headlessui/react/dist/components/switch/switch';
import { tailwindPreflight } from '../../components/app/design/tw-preflight';

/**
 * Wraps a headless-ui switch control to theme it, as an illustration (antd already has a 'switch')
 * however this pattern is one we may adopt in places (e.g. the search filter's server-based typeahead that's
 * been difficult to control for our needs like allowing paste while still limiting the selected options)
 * @param props - same as headless-ui doc
 */
export const Switch = (props: SwitchProps<ElementType>) => {
  return (
    <div css={css(tailwindPreflight)}>
      <HuiSwitch
        {...props}
        css={css([
          tailwindPreflight,
          props.checked
            ? tw`!_bg-antd-lt-colorPrimaryActive dark:!_bg-antd-dk-colorPrimaryActive`
            : tw`!_bg-gray-400 dark:!_bg-gray-200`,
          tw`_relative _inline-flex _h-6 _w-11 _items-center _rounded-full`,
        ])}
      >
        <span tw="_sr-only _translate-x-9">{props.name}</span>
        <span
          css={css([
            tailwindPreflight,
            props.checked ? tw`_translate-x-6` : tw`_translate-x-1`,
            tw`_inline-block _h-4 _w-4 _transform _rounded-full _bg-white _transition`,
          ])}
        />
      </HuiSwitch>
    </div>
  );
};
