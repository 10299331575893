import { useState } from 'react';
import {
  batchClaimState,
  assignableAuditorUsersQuery,
  batchClaimEventHistoryTimeLineQuery,
} from '../../../fragments';
import { gql, useQuery, useMutation } from 'urql';
import { Menu, Modal, Select, message, Typography, Spin } from 'antd';
import { batchClaimsNodeKey } from '../util';

const assignUserMutation = gql`
  mutation assignUserMutation(
    $batchClaimIds: [UUID!]!
    $auditorUserId: UUID!
    $unassigned: Boolean!
  ) {
    setAssignees(
      input: {
        batchClaimIds: $batchClaimIds
        auditorUserId: $auditorUserId
        unassigned: $unassigned
      }
    ) {
      batchClaimAssignees {
        auditorUserId
        batchClaim {
          activeAssignees {
            nodes {
              id
              firstName
              lastName
            }
          }
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

/*
    When we are doing bulk unassigns we need to pre-select the users that are unassignable
    Meaning we must get the intersection of all assignees for all selected claims
    This function generates that list of users for us
    In the case of action === 'assign' we just return an empty array which signaling that
    we need to query for all auditor users so that the application user can select who to assign
  */
const getValidUsersBasedOnAction = (batchClaims, action) => {
  if (action === 'assign') {
    return [];
  } else if (action === 'unassign') {
    const assigneeArrOfArr = batchClaims
      .filter(({ activeAssignees: { nodes } }) => nodes.length > 0)
      .map(({ activeAssignees }) =>
        activeAssignees.nodes.map(assignee => assignee)
      );
    return assigneeArrOfArr.length === 0
      ? []
      : assigneeArrOfArr.reduce((previousArr, currentArr) => {
          return previousArr.filter(assigneeObj =>
            currentArr.some(
              currentAssignee => currentAssignee.id === assigneeObj.id
            )
          );
        });
  }
};

// The modal when an assign button is clicked
const AssignModal = ({
  assignModalVisible,
  setAssignModalVisible,
  action,
  actionClaims,
  validUsers,
}) => {
  const reactNodeKey = batchClaimsNodeKey(actionClaims);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [assigneeId, setAssigneeId] = useState(null);
  const [{ fetching: fetchingAuditorUsers, data: auditorUsersData }] = useQuery(
    {
      query: assignableAuditorUsersQuery,
      pause: action === 'unassign',
    }
  );

  const formattedActionName = action === 'assign' ? 'Assign' : 'Un-Assign';

  const [{ fetching: assignUserFetching }, assignUser] =
    useMutation(assignUserMutation);

  const users =
    action === 'assign'
      ? fetchingAuditorUsers && !auditorUsersData
        ? []
        : auditorUsersData.auditorUsers.nodes
      : validUsers;
  const [eventHistoryTimeLine, refreshHistoryTimeLine] = useQuery({
    query: batchClaimEventHistoryTimeLineQuery,
    variables: {
      batchClaimIds: actionClaims.map(({ id }) => id),
    },
    pause: true,
  });
  return (
    <Modal
      key={`${action}-modal-${reactNodeKey}`}
      okButtonProps={{
        key: `${action}-action-modal-ok-button-${reactNodeKey}`,
        'data-cy': `${formattedActionName.toLocaleLowerCase()}-modal-ok-button`,
      }}
      cancelButtonProps={{
        key: `${action}-action-modal-cancel-button`,
        'data-cy': `${formattedActionName.toLocaleLowerCase()}-modal-cancel-button`,
      }}
      open={assignModalVisible}
      confirmLoading={confirmLoading}
      onCancel={() => {
        setAssignModalVisible(false);
      }}
      onOk={async _ => {
        if (assigneeId !== null) {
          setConfirmLoading(true);
          const { data, error } = await assignUser({
            batchClaimIds: actionClaims.map(({ id }) => id),
            auditorUserId: assigneeId,
            unassigned: action === 'unassign',
          });

          if (error) {
            console.error(
              `Error ${formattedActionName}ing user to claim:`,
              assigneeId,
              error
            );
            message.error(`Error ${formattedActionName}ing user to claim(s)`);
            setAssignModalVisible(false);
            setConfirmLoading(false);
          } else {
            if (actionClaims.length === 1 && refreshHistoryTimeLine)
              await refreshHistoryTimeLine({ requestPolicy: 'network-only' });
            message.success(
              `${actionClaims.length} Claim(s) ${formattedActionName}ed`
            );
            setAssignModalVisible(false);
            setConfirmLoading(false);
          }
        } else {
          message.error(`Please select a user to ${formattedActionName}`);
        }
      }}
    >
      {fetchingAuditorUsers && !auditorUsersData ? (
        <Spin />
      ) : (
        <div>
          <Typography.Title level={5}>
            {formattedActionName} Users:
          </Typography.Title>
          <Select
            data-cy={`${formattedActionName.toLocaleLowerCase()}-modal-select-input`}
            id={`${formattedActionName.toLocaleLowerCase()}-modal-select-input`}
            key={`${formattedActionName.toLocaleLowerCase()}-modal-select-input-${reactNodeKey}`}
            style={{ width: '100%', marginTop: '25px' }}
            optionFilterProp="label"
            showSearch={true}
            placeholder={`Select Users to ${formattedActionName}`}
            onChange={userId => setAssigneeId(userId)}
            options={users.map(({ firstName, lastName, id }) => ({
              label: `${firstName} ${lastName}`,
              value: id,
            }))}
          />
        </div>
      )}
    </Modal>
  );
};

// NOTE: (AT) We combine the menu item and <Modal> in this component
// which seems superfluous, but it is because the parent component is not
// and can't be a React Component (see actionSubMenu comment) so this menu
// item needs to be a React Component in order to manage its own state
const AssignMenuItemAndModal = ({ action, actionClaims, validUsers }) => {
  const reactNodeKey = batchClaimsNodeKey(actionClaims);
  const [assignModalVisible, setAssignModalVisible] = useState(false);

  return (
    <span key={`${action}-menu-item-modal-outer-span-${reactNodeKey}`}>
      <span
        onClick={() => {
          setAssignModalVisible(true);
        }}
        key={`${action}-menu-item-modal-inner-span-1-${reactNodeKey}`}
      >
        {action === 'assign' ? 'Assign' : 'Un-Assign'} User(s){' '}
        {actionClaims.length > 1 ? `(${actionClaims.length})` : ''}
      </span>
      <span key={`${action}-menu-item-modal-inner-span-2-${reactNodeKey}`}>
        <AssignModal
          key={`${action}-modal-${reactNodeKey}`}
          id={`${action}-modal-${reactNodeKey}`}
          assignModalVisible={assignModalVisible}
          setAssignModalVisible={setAssignModalVisible}
          action={action}
          actionClaims={actionClaims}
          validUsers={validUsers}
        />
      </span>
    </span>
  );
};

// Menu item to trigger the render of an Assign Or Unassign Modal
const assignMenuItem = ({ batchClaims, action }) => {
  const reactNodeKey = batchClaimsNodeKey(batchClaims);
  const validUsers = getValidUsersBasedOnAction(batchClaims, action);
  /**
   * When we are action === 'unassign' and we have a selection of valid users we must then filter
   * the batchclaims selected to only those that have the valid users
   * In the case of action === 'assign' we return true for all as we have no need to filter any claims
   */
  const validUsersSet = new Set(validUsers.map(({ id }) => id)); // helper to make look up faster when selecting correct valid batchClaims
  const actionClaims = batchClaims.filter(
    ({ activeAssignees: { nodes: assignees } }) => {
      if (action === 'assign') {
        return true;
      } else if (action === 'unassign') {
        return (
          assignees.length > 0 &&
          assignees.some(assignee => validUsersSet.has(assignee.id))
        );
      }
    }
  );

  return (
    <Menu.Item
      disabled={
        action === 'unassign' &&
        (validUsers.length === 0 || actionClaims.length === 0)
      }
      key={`${action}-menuitem-${reactNodeKey}`}
    >
      <AssignMenuItemAndModal
        key={`${action}-menuitem-modal-comp-${reactNodeKey}`}
        action={action}
        actionClaims={actionClaims}
        validUsers={validUsers}
      />
    </Menu.Item>
  );
};

const unassignMenuItem = ({
  firstName,
  lastName,
  auditorUserId,
  batchClaimId,
  assignUser,
  disabled,
  setLoading,
}) => {
  return (
    // returning <div> here causes antd errors, since it expects <Menu.Item>
    <Menu.Item
      key={`unassign-user-${auditorUserId}-claim-${batchClaimId}`}
      disabled={disabled}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await assignUser({
          batchClaimIds: [batchClaimId],
          auditorUserId,
          unassigned: true,
        });
        setLoading(false);
        if (!data && error) {
          const errorMessage = `${firstName} ${lastName} unassign failed`;
          console.error(`${errorMessage}: ${error.message}`);
          message.error(errorMessage);
        } else {
          message.success(`${firstName}
          ${lastName} unassign success`);
        }
      }}
    >
      Unassign {firstName} {lastName}
    </Menu.Item>
  );
};

const typeActions = {
  assign: {
    renderMenuItem: assignMenuItem,
  },
  singleUnassign: {
    // singleUnassign
    renderMenuItem: ({ batchClaims, setLoading }) => {
      // only allow unassigning when 1 claim is selected
      // implementing unassign for multiple claims is a little tricky
      // not worth the squeeze at the moment
      const batchClaimToUnassign =
        batchClaims.length === 1 ? batchClaims[0] : null;
      const [{ fetching: assignUserFetching }, assignUser] =
        useMutation(assignUserMutation);

      const batchClaimId = batchClaimToUnassign && batchClaimToUnassign.id;
      // only render unassigns if assignments exist
      return (
        batchClaimToUnassign &&
        batchClaimToUnassign.activeAssignees.nodes.map(
          ({ firstName, lastName, id: auditorUserId }) =>
            unassignMenuItem({
              firstName,
              lastName,
              auditorUserId,
              batchClaimId,
              assignUser,
              disabled: !batchClaimToUnassign,
              setLoading,
            })
        )
      );
    },
  },
  unassign: {
    renderMenuItem: assignMenuItem,
  },
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }) => (
  <Menu.SubMenu
    key={`assignment-submenu-${batchClaimsNodeKey(batchClaims)}`}
    title="Assign"
  >
    {Object.entries(typeActions)
      .filter(([action, _]) => {
        if (action === 'unassign') {
          // if user is allowed to unassign and the selected batchClaimLiist length exceeds 1
          // Then we allow for the bulk unassign functionality
          return (
            batchClaims.length > 1 &&
            permissions.claimActions['assign'].includes(action)
          );
        }
        return permissions.claimActions['assign'].includes(action);
      })
      .map(([action, { renderMenuItem }]) =>
        renderMenuItem({ batchClaims, setLoading, action })
      )}
  </Menu.SubMenu>
);

// The subMenu property must return a function and not a react component
// Or else unexpected behavior can occur such as a non-unique key error from antd
// https://ant.design/components/menu/#Notes-for-developers
const assign = {
  title: 'Assignees',
  subMenu: actionSubMenu,
};
export { assign };
