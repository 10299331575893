import { Tooltip } from 'antd';

const calculateWidthPercentages = (statuses, max, minWidth) => {
  const statusPercentage = [];
  const freeSpace =
    minWidth * statuses.length > 100 ? 0 : 100 - minWidth * statuses.length;
  statuses.forEach(([_, { totalCount }]) => {
    statusPercentage.push(minWidth + (totalCount / max) * freeSpace);
  });
  return statusPercentage;
};

export const ProgressBar = ({ stateType, statuses, max, minWidth }) => {
  const defaultMinWidth = minWidth && minWidth > 5 ? minWidth : 8;

  const containerStyles = {
    height: '1rem',
    backgroundColor: '#e0e0de',
    width: '100%',
    borderRadius: '.25rem',
    overflow: 'hidden',
  };

  const statusPercentage = calculateWidthPercentages(
    statuses,
    max,
    defaultMinWidth
  );
  return (
    <div id={`${stateType}-stateType-div`} style={containerStyles}>
      {statuses.map(
        (
          [
            stateName,
            {
              name: stateLabel, // currently unused as the description is used for the tooltip instead
              description: stateDescription,
              totalCount,
              primaryColor,
              strokeColor,
            },
          ],
          i
        ) => {
          const formattedName = stateName.toLowerCase().replaceAll('_', '-');
          const fillerStyles = {
            height: '100%',
            width: `${statusPercentage[i]}%`,
            backgroundColor: primaryColor,
            textAlign: 'center',
            display: 'inline-block',
            overflow: 'hidden',
          };

          const labelStyles = {
            color: strokeColor,
            fontSize: '0.75rem',
            display: 'flex',
            justifyContent: 'center',
          };

          return (
            <Tooltip
              key={`${stateType}-${formattedName}-tooltip`}
              title={`${stateDescription}`}
              color={primaryColor}
              id={`${stateType}-${formattedName}-tooltip`}
            >
              <div style={fillerStyles}>
                <span
                  id={`${stateType}-${formattedName}-value`}
                  style={labelStyles}
                  data-cy={`${stateType}-${formattedName}-value`}
                >
                  {totalCount}
                </span>
              </div>
            </Tooltip>
          );
        }
      )}
    </div>
  );
};
