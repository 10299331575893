{
  "name": "alaffia-app",
  "version": "1.9.4",
  "private": true,
  "repository": {
    "type": "git"
  },
  "license": "MIT",
  "source": "src/index.html",
  "dependencies": {
    "@alaffia-technology-solutions/alaffia-client-sdk": "0.1.15 ",
    "@ant-design/compatible": "^5.1.1",
    "@ant-design/icons": "5.0.1",
    "@datadog/browser-rum": "^4.32.0",
    "@emotion/css": "^11.11.2",
    "@emotion/react": "^11.11.3",
    "@emotion/styled": "^11.11.0",
    "@foxitsoftware/foxit-pdf-sdk-for-web-library-full": "9.2.1",
    "@graphql-typed-document-node/core": "^3.2.0",
    "@headlessui/react": "^1.4.1",
    "@heroicons/react": "^2.0.16",
    "@react-hookz/web": "^23.0.0",
    "@tailwindcss/line-clamp": "^0.4.4",
    "@urql/core": "^4.2.2",
    "@urql/devtools": "^2.0.3",
    "@urql/exchange-graphcache": "^6.3.3",
    "@urql/introspection": "^1.0.2",
    "accounting-js": "^1.1.1",
    "ag-grid-community": "^29.3.5",
    "ag-grid-enterprise": "^29.3.5",
    "ag-grid-react": "^29.3.5",
    "antd": "~5.3.0",
    "argparse": "^2.0.1",
    "dayjs": "^1.11.7",
    "dompurify": "^3.0.5",
    "dotenv": "^16.0.3",
    "emotion-css": "^1.0.5",
    "flat": "^5.0.2",
    "graphql": "^16.6.0",
    "graphql-tag": "^2.12.5",
    "handlebars": "^4.7.8",
    "html-react-parser": "^5.0.11",
    "immer": "^10.0.2",
    "interactjs": "^1.10.19",
    "jwt-decode": "^3.1.2",
    "lodash": "^4.17.21",
    "loglevel": "^1.8.1",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.40",
    "omit-deep-lodash": "^1.1.7",
    "qrcode.react": "^3.1.0",
    "react": "^18.2.0",
    "react-app-polyfill": "^3.0.0",
    "react-contexify": "^6.0.0",
    "react-csv": "^2.0.3",
    "react-dom": "^18.2.0",
    "react-draggable": "^4.4.6",
    "react-error-boundary": "^4.0.12",
    "react-grid-layout": "^1.3.4",
    "react-hotkeys-hook": "^4.3.8",
    "react-json-view-lite": "^0.9.6",
    "react-resizable": "^3.0.5",
    "react-router-dom": "^5.3.4",
    "react-split-grid": "^1.0.4",
    "react-virtualized-auto-sizer": "1.0.18",
    "react-window": "^1.8.9",
    "rxjs": "^7.8.0",
    "scroll-into-view": "^1.16.2",
    "slugid": "^3.2.0",
    "urql": "^4.0.6",
    "uuid": "^9.0.0",
    "wait-promise": "^0.4.1"
  },
  "scripts": {
    "start": "NODE_ENV=development parcel -p 3000 --open",
    "start-no-launch": "NODE_ENV=development parcel -p 3000",
    "start-no-launch-ssl": "NODE_ENV=development parcel --https -p 3000",
    "express-server-public-files": "node dev-util/express-server-dist-pub.js",
    "express-server-full-preview": "node dev-util/express-server-full-app.js",
    "parcel-dev-behind-express": "NODE_ENV=development parcel -p 3001",
    "build": "pnpm build:DEV",
    "build:_": "parcel build",
    "demo": "REACT_APP_DEMO=true pnpm start",
    "build:BETA": "env-cmd -f .env.beta pnpm build:_",
    "build:GAMMA": "env-cmd -f .env.gamma pnpm build:_",
    "build:DEV": "env-cmd -f .env.development pnpm build:_",
    "build:LOCAL": "env-cmd -f .env.local.cluster pnpm build:_",
    "build:DEV-LOCAL": "env-cmd -f .env.development.local pnpm build:_",
    "build:PROD": "env-cmd -f .env.production pnpm build:_",
    "test": "pnpm --shell-mode exec NODE_OPTIONS=--max_old_space_size=4096 jest",
    "test-ci": "pnpm --shell-mode exec NODE_OPTIONS=--max_old_space_size=4096 jest --silent",
    "test-old": "env-cmd -f .env.cypress.local ./ci/run-tests.sh 'terminal'",
    "start-test-server": "start-server-and-test start 3000",
    "test:ui": "env-cmd -f .env.cypress.local ./ci/run-tests.sh 'ui'",
    "test:local:terminal": "env-cmd -f .env.cypress.local ./ci/run-tests.sh 'ci' 'local'",
    "test:ci": "REACT_APP_API_URL='http://localhost:4000' ./ci/run-tests.sh 'ci' 'not-local'",
    "codegen": "graphql-codegen && node ./introspection-gen.js",
    "copy-schema": "cp ../graphql_api/schema.graphql ./schema.graphql",
    "rebuild-schema": "pnpm copy-schema && pnpm codegen",
    "checkVersions": "scripts/pre-commit-check-versions.sh \"$(pwd)/chart/Chart.yaml\"",
    "pretty": "npx prettier --check .",
    "foxit-lib-public-symlink": "pnpm exec ln -s \"$(pwd)/node_modules/@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib\" \"$(pwd)/public/foxit\"",
    "eject": "react-scripts eject",
    "build-image": "docker build --secret id=github_token,src=.secret.ghp.txt --no-cache --progress=plain --build-arg=\"env=.env.local.cluster\" -f Dockerfile . -t alaffia_f_e:local",
    "run-image-x": "docker run -d --network=host -p 3099:3000 --name alaffia_f_e_local_container --env-file=./.env.local.cluster alaffia_f_e:local",
    "run-image": "docker run -it -p 3099:3000 --env-file=./.env.local.cluster alaffia_f_e:local bash",
    "get-container-logs": "docker container logs alaffia_f_e_local_container",
    "stop": "docker stop alaffia_f_e_local_container",
    "container-remove": "docker stop alaffia_f_e_local_container | xargs docker rm",
    "copy-alaffia-client": "rsync --delete -vrplt  ../alaffia-client-sdk/dist/alaffia-technology-solutions-alaffia-client-sdk-* external/"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "prettier"
    ],
    "rules": {
      "no-unused-vars": "off",
      "react-hooks/exhaustive-deps": "off",
      "array-callback-return": "off",
      "default-case": "off",
      "no-lone-blocks": "off",
      "no-dupe-keys": "off"
    }
  },
  "browserslist": [
    ">0.2%",
    "not dead",
    "not ie <= 11",
    "not op_mini all"
  ],
  "devDependencies": {
    "@babel/core": "^7.22.8",
    "@babel/plugin-transform-react-jsx": "^7.20.13",
    "@babel/preset-env": "^7.20.2",
    "@babel/preset-react": "^7.18.6",
    "@babel/preset-typescript": "^7.22.5",
    "@emotion/babel-plugin": "^11.11.0",
    "@emotion/babel-plugin-jsx-pragmatic": "^0.2.1",
    "@graphql-codegen/cli": "^3.2.2",
    "@graphql-codegen/client-preset": "^2.1.1",
    "@graphql-codegen/urql-introspection": "^2.2.1",
    "@parcel/babel-preset-env": "2.9.3",
    "@parcel/config-default": "2.9.3",
    "@parcel/optimizer-terser": "2.9.3",
    "@parcel/reporter-dev-server": "2.9.3",
    "@parcel/resolver-glob": "2.9.3",
    "@parcel/transformer-inline-string": "2.9.3",
    "@testing-library/dom": "^9.3.4",
    "@testing-library/react": "^14.0.0",
    "@testing-library/react-hooks": "^8.0.1",
    "@testing-library/user-event": "^14.4.3",
    "@types/accounting": "^0.4.2",
    "@types/dompurify": "^3.0.2",
    "@types/jest": "^29.5.0",
    "@types/jest-expect-message": "^1.1.0",
    "@types/js-yaml": "^4.0.3",
    "@types/lodash": "^4.14.194",
    "@types/node": "^18.11.18",
    "@types/omit-deep-lodash": "^1.1.1",
    "@types/react": "^18.2.47",
    "@types/react-dom": "^18.2.18",
    "@types/react-grid-layout": "^1.3.2",
    "@types/react-resizable": "^3.0.4",
    "@types/react-router-dom": "^5.3.3",
    "@types/react-test-renderer": "^18.0.3",
    "@types/scroll-into-view": "^1.16.0",
    "@types/uuid": "^9.0.2",
    "@types/wait-promise": "^0.4.0",
    "@types/whatwg-fetch": "^0.0.33",
    "amazon-cognito-identity-js": "^6.1.2",
    "assert": "^2.0.0",
    "babel-jest": "^29.6.1",
    "babel-plugin-macros": "^3.1.0",
    "babel-plugin-transform-import-meta": "^2.2.1",
    "babel-plugin-twin": "^1.1.0",
    "browserify-zlib": "^0.2.0",
    "buffer": "^5.5.0||^6.0.0",
    "crypto-browserify": "^3.12.0",
    "cypress": "^12.5.0",
    "cypress-failed-log": "^2.10.0",
    "cypress-file-upload": "^5.0.8",
    "cypress-localstorage-commands": "^2.2.2",
    "env-cmd": "^10.1.0",
    "eslint-config-prettier": "^8.6.0",
    "events": "^3.1.0",
    "express": "^4.18.2",
    "express-http-proxy": "^2.0.0",
    "http-proxy-middleware": "^2.0.6",
    "https-browserify": "^1.0.0",
    "jest": "29.5.0",
    "jest-environment-jsdom": "29.5.0",
    "jest-expect-message": "^1.1.3",
    "js-yaml": "^4.1.0",
    "msw": "^1.3.1",
    "node-fetch": "^3.3.0",
    "node-sass": "^9.0.0",
    "os-browserify": "^0.3.0",
    "parcel": "2.9.3",
    "parcel-plugin-bundle-visualiser": "^1.2.0",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "parcel-resolver-ignore": "^2.1.5",
    "path-browserify": "^1.0.0",
    "postcss": "^8.4.21",
    "pre-commit": "^1.2.2",
    "prettier": "3.2.4",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "react-test-renderer": "18.2.0",
    "serve-static": "^1.15.0",
    "start-server-and-test": "^2.0.0",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.1.0",
    "tailwind-scrollbar": "^3.0.0",
    "tailwindcss": "^3.2.4",
    "timers-browserify": "^2.0.11",
    "ts-jest": "^29.0.5",
    "twin.macro": "^3.1.0",
    "type-fest": "^4.3.1",
    "typescript": "^5.2.2",
    "undici": "^6.5.0",
    "url": "^0.11.0",
    "util": "^0.12.3",
    "whatwg-fetch": "^3.6.19",
    "wonka": "^6.1.2"
  },
  "overrides": {
    "jest-environment-jsdom": "27.3.1"
  },
  "parcelIgnore": [
    "public/foxit/.+",
    "/public/foxit/.+",
    "foxit/UIExtension.full.js",
    "public/foxit/UIExtension.full.js",
    "public/foxit/lib/UIExtension.full.js",
    "UIExtension.full.js",
    "/foxit/UIExtension.css",
    "UIExtension.css"
  ],
  "staticFiles": [
    {
      "staticPath": "public/foxit/alaffia",
      "staticOutPath": "public/foxit/alaffia"
    },
    {
      "staticPath": "public/foxit/external",
      "staticOutPath": "public/foxit/external"
    },
    {
      "staticPath": "node_modules/@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib",
      "staticOutPath": "public/foxit/lib"
    },
    {
      "staticPath": "msw_mocks/mockServiceWorker.js",
      "env": {
        "NODE_ENV": "development"
      }
    }
  ],
  "pre-commit": [
    "checkVersions",
    "pretty"
  ],
  "msw": {
    "workerDirectory": "msw_mocks"
  },
  "imports": {
    "#public": "../public"
  },
  "alias": {
    "alaffia-client-sdk": "./external/alaffia-client-sdk/dist/AlaffiaClient.js"
  }
}
