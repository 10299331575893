import React, { useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import tw, { css } from 'twin.macro';
import { Switch } from '@headlessui/react';
import { SetDarkMode, UiOptions, UiOptionUpdateDispatch } from './useUiOptions';
import { DesignProvider } from './designProvider';

export function DarkModeButton() {
  const {
    setDarkMode,
    options: { darkMode },
  } = useContext(DesignProvider);

  return (
    <Switch
      checked={!!darkMode}
      onChange={() => setDarkMode(!darkMode)}
      tw="_m-1 _p-[1px] _border-0 _relative _inline-flex _h-[16px] _w-[16px] _shrink-0 _cursor-pointer _bg-transparent _rounded-full _transition-colors _duration-1000 _ease-in-out"
    >
      <span tw="_m-0 _p-0 _pointer-events-none _h-[16px] _w-[16px] _transform _rounded-full _transition _duration-300 _ease-in-out">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          tw="_w-[14px] _h-[14px] _stroke-antd-lt-colorPrimaryText dark:_stroke-antd-dk-colorPrimaryText"
        >
          {darkMode ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          )}
        </svg>
      </span>
    </Switch>
  );
}
