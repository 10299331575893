import { IPdfViewer } from './types';
import _log, { Logger } from 'loglevel';
import { BehaviorSubject } from 'rxjs';
const log: Logger & {
  css?: string;
} = _log.getLogger('ViewerBase');
// log.setLevel(log.levels.DEBUG, false);
log.css = 'color: brown';

export abstract class ViewerBase<T extends object> implements IPdfViewer<T> {
  protected instance: T;

  // 'private' rsjx subjects for notifying react via subj.next(xx) of changes via observables below
  protected pageNumberSubject = new BehaviorSubject<number>(0);
  protected documentIdSubject = new BehaviorSubject<string | null>(null);
  protected documentNameSubject = new BehaviorSubject<string | null>(null);
  protected loadingSubject = new BehaviorSubject<boolean>(false);

  // public rsjx observables for notifying react of changes - see PdfViewerProvider
  public readonly pageNumberObs = this.pageNumberSubject.asObservable();
  public readonly documentIdObs = this.documentIdSubject.asObservable();
  public readonly documentNameObs = this.documentNameSubject.asObservable();
  public readonly loadingObs = this.loadingSubject.asObservable();

  constructor(instance: T) {
    this.instance = instance;
  }

  getInstance() {
    return this.instance;
  }

  abstract closeDocument(): void;

  abstract getCurrentDocumentId(): string | null;

  abstract getCurrentDocumentName(): string | null;

  abstract getCurrentPageNumber(): number;

  abstract gotoPage(pageNumber: number): void;

  abstract loadDocument(args: {
    url: string;
    filename: string;
    documentId: string;
    fileSize: number;
    page?: number;
  }): Promise<{ documentId: string }>;
}
