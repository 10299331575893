export type MfaCodeArgs = { code: string };

export type ChangePwdArgs = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type ChallengeArgs = MfaCodeArgs | ChangePwdArgs;

export const isMfaCodeArgs = (args: ChallengeArgs): args is MfaCodeArgs =>
  (args as any)?.code;

export const isChangePwdArgs = (args: ChallengeArgs): args is ChangePwdArgs =>
  (args as any)?.oldPassword &&
  (args as any)?.newPassword &&
  (args as any)?.confirmPassword;
