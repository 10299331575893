import { JsonView } from 'react-json-view-lite';
import { FC } from 'react';
import tw, { css } from 'twin.macro';

/*
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
*/
const clsJv = css({
  ...tw`_p-0 _m-0`,
  '& .jv-container': tw`_p-2`,
  '& .jv-basicChildStyle': tw`_ml-3`,
  '& .jv-label': tw`_mr-1 _text-xs _text-gray-800 dark:_text-gray-200`,
  '& .jv-nullValue': tw`_text-orange-600  dark:_text-orange-200`,
  '& .jv-undefinedValue': tw`_text-red-800  dark:_text-red-200`,
  '& .jv-stringValue': tw`_cursor-text _select-text _text-amber-800  dark:_text-amber-300`,
  '& .jv-booleanValue': tw`_text-blue-700  dark:_text-blue-200`,
  '& .jv-numberValue': tw`_cursor-text _select-text _text-green-800  dark:_text-green-400`,
  '& .jv-otherValue': tw`_text-amber-400  dark:_text-amber-200`,
  '& .jv-expander': tw`_mr-1 _text-lg _font-bold _text-gray-700  dark:_text-gray-400`,
  '& .jv-punctuation': tw`_font-bold _text-blue-500  dark:_text-blue-400`,
  '& .jv-pointer': tw`_cursor-pointer `,
  // '& .jv-pointer': {},
});

const _styleMap = {
  container: 'jv-container',
  basicChildStyle: 'jv-basicChildStyle',
  label: 'jv-label',
  nullValue: 'jv-nullValue',
  undefinedValue: 'jv-undefinedValue',
  stringValue: 'jv-stringValue',
  booleanValue: 'jv-booleanValue',
  numberValue: 'jv-numberValue',
  otherValue: 'jv-otherValue',
  expander: 'jv-expander',
  punctuation: 'jv-punctuation',
  pointer: 'jv-pointer',
};

export type ObjectViewerProps = {
  objectToView: { [key: string]: any };
  styleMap?: typeof _styleMap;
  initialExpandLevel?: number;
};

export const ObjectViewer: FC<ObjectViewerProps> = ({
  objectToView,
  styleMap,
  initialExpandLevel = 1,
}) =>
  styleMap ? (
    <div>
      <JsonView
        data={objectToView}
        shouldInitiallyExpand={level => level < initialExpandLevel}
        style={styleMap}
      />
    </div>
  ) : (
    <div css={clsJv}>
      <JsonView
        data={objectToView}
        shouldInitiallyExpand={level => level < initialExpandLevel}
        style={_styleMap}
      />
    </div>
  );
