import React, { useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { DRG_ITEM, DRG_MODE, DrgFormItem } from './drgFormItem';
import {
  EditOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  SaveOutlined,
} from '@ant-design/icons';
const { get: getByPath } = require('lodash');
const { TextArea } = Input;

const borderColor = tw`  _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorderSecondary `;
const labelColor = tw`_text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary`;

const ssGrid = true; //todo: true === spread-sheet style grid per product feedback, remove variable once border 'look' final
const getClsGrid = (isSplitScreen: boolean) =>
  css([
    {
      ...(!isSplitScreen ? tw`_w-7/12` : {}),

      //...tw`_p-4 _pt-1`,

      '& div.wrapper': {
        ...tw`_m-0 _pt-1 _w-full _h-full _grid`,
        gridTemplateColumns: isSplitScreen
          ? '4fr 10fr 2fr 1fr 4fr 10fr 2fr'
          : '2fr 5fr 1fr',

        '& div.header': {
          fontSize: '0.95em',
          ...tw`_pl-1.5 _pt-4`,
          ...labelColor,
        },

        '& div.notes': {
          fontSize: '0.95em',
          ...tw`_pl-1.5 _mt-4`,
          ...labelColor,
        },

        '& div.spansLastTwoCols': {
          gridColumn: 'span 2', // '2 / 4',
        },

        '& div.spansAllCols': {
          gridColumn: 'span 3', // '1 / 4',
        },

        ...(!ssGrid
          ? {}
          : {
              '& .drg-row': {
                ...tw`_border-solid _border-t _border-l _border-r-0 _border-b-0`,
                ...borderColor,
              },
              '& .drg-row-end': {
                ...tw`_border-r`,
                ...borderColor,
              },
              '& .drg-row-last': {
                ...tw`_border-b`,
                ...borderColor,
              },
            }),

        '& .formItem': {
          ...tw`_m-0 _p-1.5`,
          ...(ssGrid
            ? {}
            : {
                ...tw`_border-solid _border-t _border-0 _border-r-0 _border-b-0`,
                ...borderColor,
              }),
          '& input': {
            fontSize: '0.85em',
          },
          '& input[disabled]': {
            ...tw`_border-0 _bg-transparent _cursor-default `,
            color: 'unset',
          },
          '& textarea': {
            ...tw`_mt-1.5`,
            fontSize: '0.95em',
            fontWeight: 'normal',
            color: 'unset',
          },
          '& textarea[disabled]': {
            ...tw`_border-0 _bg-transparent _cursor-default `,
            color: 'unset',
          },
        },

        // TODO we could enable diffing.... see drgFormItem class .changed
        '& .formItem.changed input': {
          ...tw`_line-through`,
        },
      },

      // show-grid - add class '.show-grid' to '.wrapper' div to debug div layout:
      // note that this doubles since it's attached to the divs which abut...
      // to show grid lines the best approach -- if all grids have lines like a spreadsheet or table --
      // is to set the bg-color of the grid container to the border color, apply real bg color to the div/cells
      // and set the grid gap to the desired border width...  but to selectively do grid lines on some sections
      // there doesn't seem to be a better way than tracking row/cell postion to apply partial borders e.g. borderTop, borderLeft
      // and add -bottom and -right on the ends...
      // '& .show-grid>div': {
      //   border: '1px dotted orange',
      //   zIndex: 999,
      // },
      // '& .show-grid': {
      //   gridColumn: '1/span all',
      //   gridRow: '1/span all',
      // },
    },
  ]);

const Header: React.FC<{ addlClass?: string; children: React.ReactNode }> = ({
  addlClass,
  children,
}) => (
  <div className={`header${addlClass ? ' ' + addlClass : ''}`}>{children}</div>
);

const Notes: React.FC<{
  fieldName: string;
  defaultValue: string;
  placeholderOnly: boolean;
}> = ({ fieldName, defaultValue, placeholderOnly }) => {
  return placeholderOnly ? (
    <>
      <div className="notes"></div>
      <div className="spansLastTwoCols"></div>
    </>
  ) : (
    <>
      <div className="notes">Notes</div>
      <div className="spansLastTwoCols">
        <Form.Item>
          <TextArea
            className="formItem"
            name={fieldName}
            autoSize={{ minRows: 1, maxRows: 4 }}
            defaultValue={defaultValue}
          ></TextArea>
        </Form.Item>
      </div>
    </>
  );
};

type Row = React.ReactElement[];
const writeRows = (rows: Row[]) => {
  rows.map(r => {
    r.map(f => <div className={'drg-row  drg-row-last drg-row-end'}>{f}</div>);
  });
};

export type DrgDxEntry = {
  code: string;
  description: string;
  presentOnAdmission?: 'Y' | 'N';
};
export type DrgProcEntry = { code: string; description: string };
export type DrgDataType = {
  drgDxs: DrgDxEntry[];
  procedures: DrgProcEntry[];
  overallDrg: DrgDxEntry;
  notes: {
    drgPrimaryNotes?: string;
    drgAddlNotes?: string;
    drgProcedureNotes?: string;
    drgOverallNotes?: string;
  };
};

export type DrgFormProps = {
  drgData?: DrgDataType;
  drgPriorVersionData?: DrgDataType;
  mode: DRG_MODE;
  editable: boolean;
  setEditable?: (editable: boolean) => void;
  isSplitScreen: boolean;
  saveReviewData?: (dataToSave: DrgDataType) => boolean;
};

// -- * TODO * --
// This form currently assumes drgData will be populated
// For the early demos, both drgData and drgPriorVersionData are populated
// To handle a new review (e.g. the first review of the original UB data)
// the fields that are loops will need to
//    a) default to at least one blank row, and,
//    b) have a 'more' button to add new rows...
// this change would also support creating a drg from scratch, but that doesn't seem like a use case...
export const DrgForm: React.FC<DrgFormProps> = ({
  drgData,
  drgPriorVersionData,
  mode,
  editable,
  setEditable,
  isSplitScreen,
  saveReviewData,
}) => {
  // TODO this all needs cleanup and has been through several shifts in prototyping
  // so there are prob dead variables and inconsistencies
  // it's also probably worthwhile re-thinking the relationship between current/prev values
  // and side-by-side vs using the 'extra' for prev values on edit -- e.g. can we represent a row
  // in a flatter way with less conditionals in each field configuration, or any similar restructuring that
  // reduces the complexity of having different display modes

  const onFinish = (values: any) => {
    // console.log('Form values:', values);
    saveReviewData &&
      saveReviewData({
        ...values,
        drgDxs: Object.values(values.drgDxs),
        procedures: Object.values(values.procedures),
      });
    setEditable && setEditable(false);
  };

  const clsGrid = useMemo(() => getClsGrid(isSplitScreen), [isSplitScreen]);

  const commonFldVals = { editable, isSplitScreen };

  const [form] = Form.useForm();

  const getCellCls = (endRow: boolean, lastRow: boolean) =>
    'drg-row' +
    (endRow ? ' drg-row-end' : '') +
    (lastRow ? ' drg-row-last' : '');

  const split = mode === DRG_MODE.REVIEW ? <div></div> : <></>;

  // if mode is review, we'll display side-by-side, otherwise whatever the mode is:
  const modes =
    mode === DRG_MODE.REVIEW && isSplitScreen
      ? [DRG_MODE.ORIGINAL, DRG_MODE.REVIEW]
      : [mode];

  const isShowingPrevValsPreview = (m: DRG_MODE) =>
    m === DRG_MODE.ORIGINAL && mode === DRG_MODE.REVIEW;

  const getLongestLength = (arrA: any[] | undefined, arrB: any[] | undefined) =>
    Math.max(arrA?.length || 0, arrB?.length || 0);

  const buildValueProps = (
    m: DRG_MODE,
    mainProp: string,
    subProp: string,
    index?: number
  ) => {
    const hasIndex = index !== null && index !== undefined;
    const dataKeyPartial = hasIndex
      ? [mainProp, index.toString(), subProp]
      : [mainProp, subProp];
    const path = hasIndex
      ? `${mainProp}[${index}].${subProp}`
      : `${mainProp}.${subProp}`;
    const currVal = getByPath(drgData, path);
    const prevVal = getByPath(drgPriorVersionData, path);
    return {
      mode: m,
      ...commonFldVals,
      ...(isShowingPrevValsPreview(m)
        ? {
            dataKey: ['drgPriorVersionData', ...dataKeyPartial],
            currentValue: prevVal,
          }
        : {
            dataKey: dataKeyPartial,
            currentValue: currVal,
            previousValue: prevVal,
          }),
    };
  };

  const writeDxRows = (
    sectionTitle: string,
    rows?: DrgDxEntry[],
    range?: [number, number?] | null
  ) => {
    const max =
      range && range?.length > 1 && range[1]
        ? range[1]
        : getLongestLength(drgData?.drgDxs, drgPriorVersionData?.drgDxs);

    const start = (range && range[0]) ?? 0;

    const jsxRows: React.ReactElement[] = [];

    for (let index = start; index < max; index++) {
      const lastRow = index >= max - 1;
      jsxRows.push(
        <>
          {modes.map(m => (
            <>
              <div className={getCellCls(false, lastRow)}>
                <DrgFormItem
                  type={DRG_ITEM.DIAG}
                  {...buildValueProps(m, 'drgDxs', 'code', index)}
                ></DrgFormItem>
              </div>
              <div className={getCellCls(false, lastRow)}>
                <DrgFormItem
                  type={DRG_ITEM.DESC}
                  {...buildValueProps(m, 'drgDxs', 'description', index)}
                ></DrgFormItem>
              </div>
              <div className={getCellCls(true, lastRow)}>
                <DrgFormItem
                  type={DRG_ITEM.POA}
                  {...buildValueProps(m, 'drgDxs', 'presentOnAdmission', index)}
                ></DrgFormItem>
              </div>
              {isShowingPrevValsPreview(m) && split}
            </>
          ))}
        </>
      );
    }

    return (
      <>
        <Header>{sectionTitle}</Header>
        <Header>Description</Header>
        <Header>POA</Header>
        {modes.length > 1 && (
          <>
            {split}
            <Header>{sectionTitle}</Header>
            <Header>Description</Header>
            <Header>POA</Header>
          </>
        )}
        {jsxRows}
      </>
    );
  };

  const writeProcedures = () => {
    const jsxRows: React.ReactElement[] = [];
    const max = getLongestLength(
      drgData?.procedures,
      drgPriorVersionData?.procedures
    );
    // (rows || []).map(({ code, description }, index) => {
    for (let index = 0; index < max; index++) {
      const lastRow = index >= max - 1;
      jsxRows.push(
        <>
          {modes.map(m => {
            return (
              <>
                <div className={getCellCls(false, lastRow)}>
                  <DrgFormItem
                    type={DRG_ITEM.PROC}
                    {...buildValueProps(m, 'procedures', 'code', index)}
                  ></DrgFormItem>
                </div>
                <div
                  className={getCellCls(true, lastRow) + ' spansLastTwoCols'}
                >
                  <DrgFormItem
                    type={DRG_ITEM.DESC}
                    {...buildValueProps(m, 'procedures', 'description', index)}
                  ></DrgFormItem>
                </div>
                {isShowingPrevValsPreview(m) && split}
              </>
            );
          })}
        </>
      );
    }

    return (
      <>
        <Header>Procedures</Header>
        <Header addlClass="spansLastTwoCols">Description</Header>
        {modes.length > 1 && (
          <>
            {split}
            <Header>Procedures</Header>
            <Header addlClass="spansLastTwoCols">Description</Header>
          </>
        )}

        {jsxRows}
      </>
    );
  };

  const writeAddRow = (action: () => void) => {
    {
      return (
        editable &&
        mode === DRG_MODE.REVIEW && (
          <>
            {modes.map(m => (
              <>
                {m === DRG_MODE.ORIGINAL ? (
                  <div className="spansAllCols"></div>
                ) : (
                  <div
                    className="spansAllCols"
                    tw="_p-0 _mt-[0px] _h-3 _leading-3 _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary"
                  >
                    <div tw="_w-8 _text-xs" onClick={() => action()}>
                      <PlusOutlined />
                    </div>
                    {/* <div></div>
                <div tw="_ml-auto">
                  
                </div> */}
                  </div>
                )}
                {isShowingPrevValsPreview(m) && split}
              </>
            ))}
          </>
        )
      );
    }
  };

  const writeResulting = (rows?: DrgDxEntry[]) => {
    return (
      <>
        <Header>Resulting DRG</Header>
        <Header addlClass="spansLastTwoCols">Description</Header>
        {modes.length > 1 && (
          <>
            {split}
            <Header>Resulting DRG</Header>
            <Header addlClass="spansLastTwoCols">Description</Header>
          </>
        )}

        {(rows || []).map(({ code, description }, index) => {
          const lastRow = index >= (rows?.length ?? 1) - 1;
          return (
            <>
              {modes.map(m => {
                return (
                  <>
                    <div className={getCellCls(false, lastRow)}>
                      <DrgFormItem
                        type={DRG_ITEM.DIAG}
                        {...buildValueProps(m, 'overallDrg', 'code')}
                      ></DrgFormItem>
                    </div>
                    <div
                      className={
                        getCellCls(true, lastRow) + ' spansLastTwoCols'
                      }
                    >
                      <DrgFormItem
                        type={DRG_ITEM.DESC}
                        {...buildValueProps(m, 'overallDrg', 'description')}
                      ></DrgFormItem>
                    </div>
                    {isShowingPrevValsPreview(m) && split}
                  </>
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  const writeNotes = (fieldName: string, isEditMode: boolean) => {
    return (
      <>
        {modes.map(m => {
          const itemProps = buildValueProps(m, 'notes', fieldName);
          return m === DRG_MODE.ORIGINAL ||
            (!editable && !itemProps.currentValue) ? (
            <>
              <div className="spansAllCols">
                {/* <div className="notes"></div>
              <div className="spansLastTwoCols"></div> */}
              </div>
              {isShowingPrevValsPreview(m) && split}
            </>
          ) : (
            <div className="spansAllCols" tw="_flex">
              <div className="notes">Notes:</div>
              <div tw="_flex-1 _pl-2 _ml-auto">
                <DrgFormItem type={DRG_ITEM.NOTES} {...itemProps}></DrgFormItem>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const writeTopHeader = () => (
    <>
      {modes.map(m => {
        return (
          <>
            <div className="spansAllCols" tw="_flex _pr-1">
              <div tw="_underline _font-bold">{m}</div>
              <div tw="_ml-auto  _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary">
                {!setEditable ||
                [DRG_MODE.ORIGINAL].includes(m) ? undefined : editable ? (
                  <SaveOutlined
                    onClick={() => onFinish(form.getFieldsValue(true))}
                  />
                ) : (
                  <EditOutlined onClick={() => setEditable(true)} />
                )}
              </div>
            </div>
            {isShowingPrevValsPreview(m) && split}
          </>
        );
      })}
    </>
  );

  const addRow = (target: string) => {
    const blank =
      target === 'drgDxs'
        ? { code: '', description: '', poa: '' }
        : { code: '', description: '' };
    const formValues = form.getFieldsValue(true);
    const values = {
      ...formValues,
      drgDxs:
        target === 'drgDxs'
          ? [...Object.values(formValues.drgDxs), blank]
          : Object.values(formValues.drgDxs),
      procedures:
        target === 'procedures'
          ? [...Object.values(formValues.procedures), blank]
          : Object.values(formValues.procedures),
    };
    // console.log(target, values);
    saveReviewData && saveReviewData(values);

    // vals[target].push[]
    //onFinish()
  };

  return (
    <>
      <div css={clsGrid}>
        <Form form={form} onFinish={onFinish} disabled={!editable}>
          <div className="wrapper lines">
            {/* <div className="wrapper show-grid"> */}

            {/* top header */}
            {writeTopHeader()}

            {/* Principal DX */}
            {writeDxRows('Principal Dx', drgData?.drgDxs, [0, 1])}
            {writeNotes('drgPrimaryNotes', editable)}

            {/* Secondary/additional Dxs- may need mod for whatever the b/e / datastruct is in future */}
            {writeDxRows('Secondary Dx', drgData?.drgDxs, [1])}
            {writeAddRow(() => addRow('drgDxs'))}
            {writeNotes('drgAddlNotes', editable)}

            {/* Procedures */}
            {writeProcedures()}
            {writeAddRow(() => addRow('procedures'))}
            {writeNotes('drgProcedureNotes', editable)}

            {/* Resulting DRG */}
            {writeResulting(drgData?.overallDrg ? [drgData?.overallDrg] : [])}
            {writeNotes('drgOverallNotes', editable)}
          </div>
        </Form>
      </div>
    </>
  );
};
