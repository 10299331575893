import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { gql } from 'urql';
import moment from 'moment-timezone';
import { Table, Collapse, Button, Tooltip, Tag, Empty } from 'antd';
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import accounting from 'accounting-js';

import { withFragment } from '../../../../util/urelay';
import { auditFindingFragment } from '../../../../fragments';
import { AuditFindingComment } from '../auditFinding/auditFindingComment';
import { UserContext } from '../../../context/user';
import { useRouteMatch } from 'react-router-dom';

const findingsTableQuery = gql`
  query FindingsTable($batchClaimId: UUID!, $afclFilter: AuditFindingFilter!) {
    batchClaim(id: $batchClaimId) {
      id
      auditFindings(filter: $afclFilter) {
        totalCount
        nodes {
          ...auditFindingFragment
        }
      }
      itemizedBill {
        id
        csv {
          id
          s3Key
          header {
            values
          }
          rows
        }
      }
    }
  }

  ${auditFindingFragment}
`;

const PureFindingsTable = props => {
  const {
    query,
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    readOnly,
    variables: { afclFilter },
  } = props;

  const [{ fetching, data, error }] = query;

  const {
    userType,
    workstation: {
      tabs: {
        Findings: { columns: userColumns },
      },
    },
  } = useContext(UserContext);

  const viewingReport =
    useRouteMatch('/reports/:auditFindingReportId/workspace') !== null;
  const showFindings = userType !== 'PROVIDER' || viewingReport;

  const noItemizedBillData = !data || data.batchClaim.itemizedBill === null;
  const auditFindings = !data ? [] : data.batchClaim.auditFindings.nodes;
  const ibLines = noItemizedBillData
    ? []
    : data.batchClaim.itemizedBill.csv.rows;

  const dataAccess = (auditFinding, dataIndex) =>
    Array.isArray(dataAccess)
      ? dataIndex.reduce((value, index) => value[index], auditFinding)
      : auditFinding[dataIndex];

  const addFiltering = column => ({
    filters: [
      ...auditFindings.reduce(
        (values, auditFinding) =>
          values.add(dataAccess(auditFinding, column.dataIndex)),
        new Set()
      ),
    ]
      .sort()
      .map(value => ({ text: value || '<EMPTY>', value })),
    onFilter: (value, auditFinding) =>
      auditFinding &&
      (value
        ? dataAccess(auditFinding, column.dataIndex).includes(value)
        : dataAccess(auditFinding, column.dataIndex) == value),
    ...column,
  });

  const columns = [
    {
      title: 'Source',
      key: 'source',
      dataIndex: 'auditFindingSeedType',
      align: 'center',
      render: auditFindingSeedType => (
        <Tag color="lightgray" css={css({ minWidth: '3em' })}>
          {auditFindingSeedType.includes('UB') ? 'UB' : 'IB'}
        </Tag>
      ),
    },
    {
      title: 'Denial Code',
      key: 'denialCode',
      dataIndex: ['auditFindingRuleTypeByAuditFindingRuleType', 'displayName'],
      render: (
        auditFindingRuleTypeName,
        { improperPaymentReason, auditorComment, auditFindingSeedType }
      ) => {
        const rationale = improperPaymentReason || auditorComment;
        return (
          <Tooltip title={rationale} placement="top">
            <Tag
              color={
                auditFindingSeedType === 'UB_CLAIM_LINE'
                  ? 'geekblue'
                  : 'magenta'
              }
            >
              {auditFindingRuleTypeName}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: 'Qty',
      key: 'quantity',
      dataIndex: 'improperPaymentUnitsCharged',
      align: 'center',
      sorter: (a, b) =>
        a.improperPaymentUnitsCharged - b.improperPaymentUnitsCharged,
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'improperPaymentCost',
      render: improperPaymentCost =>
        accounting.formatMoney(improperPaymentCost),
      sorter: (a, b) => a.improperPaymentCost - b.improperPaymentCost,
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ reviewedAuditFinding, reportedFindings, superseded }) => {
        return (
          <>
            {reviewedAuditFinding ? (
              reviewedAuditFinding.accepted ? (
                superseded ? (
                  <Tag color="lightgray">Superseded</Tag>
                ) : (
                  <Tag color="green">Accepted</Tag>
                )
              ) : (
                <Tag color="lightgray">Declined</Tag>
              )
            ) : (
              <Tag color="red">Needs Review</Tag>
            )}
            {reportedFindings.totalCount > 0 && (
              <Tag color="warning">Reported</Tag>
            )}
          </>
        );
      },
      filters: [
        'Accepted',
        'Superseded',
        'Declined',
        'Needs Review',
        'Reported',
      ].map(value => ({ text: value, value })),
      onFilter: (
        value,
        { reviewedAuditFinding, reportedFindings, superseded }
      ) =>
        Object.entries({
          Accepted:
            reviewedAuditFinding &&
            reviewedAuditFinding.accepted &&
            !superseded,
          Superseded: superseded,
          Declined: reviewedAuditFinding && !reviewedAuditFinding.accepted,
          'Needs Review': !reviewedAuditFinding,
          Reported: reportedFindings.totalCount > 0,
        }).filter(([key, condition]) => value === key && condition === true)
          .length > 0,
    },
    {
      title: <UserOutlined test-id="author-icon" size="small" />,
      key: 'author',
      dataIndex: ['author'],
      render: ({ id, firstName, lastName }, index) => (
        <div>{`${firstName} ${lastName}`}</div>
      ),
      filters: [
        ...auditFindings.reduce(
          (values, { author: { firstName, lastName } }) =>
            values.add(`${firstName} ${lastName}`),
          new Set()
        ),
      ]
        .sort()
        .map(value => ({ text: value, value })),
      onFilter: (value, { author: { firstName, lastName } }) =>
        value
          ? `${firstName} ${lastName}`.includes(value)
          : `${firstName} ${lastName}` == value,
    },
    {
      title: <ClockCircleOutlined test-id="created-at-icon" />,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('MM-DD-YY HH:mm:ss'),
      sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
  ].map(addFiltering);

  const detailedColumnTitles = noItemizedBillData
    ? []
    : data.batchClaim.itemizedBill.csv.header.values;

  const ibDetailColumns = [
    ...detailedColumnTitles
      .filter(title => !title.startsWith('ala_'))
      .map(title => ({
        title,
        dataIndex: title,
        key: title,
      })),
  ];

  return !fetching && auditFindings.length === 0 ? (
    <Empty />
  ) : (
    <Table
      id="findings_table"
      loading={fetching}
      bordered
      size="small"
      sticky={true}
      columns={columns.filter(
        c => userColumns.includes(c.key) || userColumns.includes('*')
      )}
      dataSource={auditFindings}
      rowKey={auditFinding => auditFinding.id}
      pagination={false}
      scroll={{
        y: 'calc(100vh - 55px - 160px - 32px - 35px)',
      }}
      // onRow={batchClaimLine => {
      //   const { auditFindings } = batchClaimLine;
      //   const style = auditFindings.nodes.length
      //     ? auditFindings.nodes.every(
      //         ({ reviewedAuditFinding, reviews }) =>
      //           reviewedAuditFinding ||
      //           reviews.nodes.some(
      //             ({ auditFinding: { reviewedAuditFinding } }) =>
      //               reviewedAuditFinding && reviewedAuditFinding.accepted
      //           )
      //       )
      //       ? { backgroundColor: '#b7eb8f' } // green-3
      //       : { backgroundColor: '#ffa39e' } // red-3
      //     : {};
      //   return {
      //     style: showFindings ? style : {},
      //   };
      // }}

      expandable={{
        columnWidth: 36,
        expandedRowRender: auditFinding => (
          <AuditFindingComment auditFinding={auditFinding} />
          // <ClaimWorkspaceAuditFindingList
          //   key={auditFinding.id}
          //   auditFindings={[auditFinding]}
          //   {...{
          //     setCreatingAuditFinding,
          //     setDecliningAuditFinding,
          //     readOnly,
          //     afclFilter,
          //     expandAllFindings: true,
          //   }}
          // />
        ),
        rowExpandable: () => showFindings,
      }}

      // possible improvement: show line info - this would also require decision about loading this for UB table, etc.
      //         <Table
      //           id={`audit_finding_${auditFinding.id}_details_table`}
      //           loading={fetching && !data}
      //           size="small"
      //           sticky={true}
      //           columns={ibDetailColumns}
      //           dataSource={ibLines.filter(({ nnIbLineId }) =>
      //             ibLineIds.has(nnIbLineId)
      //           )}
      //           rowKey={ibLine => ibLine.nnIbLineId}
      //           pagination={false}
      //         />
    />
  );
};

const FindingsTable = withFragment({
  displayName: 'FindingsTable',
  queryBuilder: () => findingsTableQuery,
  Component: PureFindingsTable,
});

export { FindingsTable, findingsTableQuery };
