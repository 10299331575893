import { CalendarTwoTone } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import moment from 'moment-timezone';

import { formatDateUTC } from '../../../audit/batchClaimOverview/eventHistoryTimeline';
import { DueDateButton } from '../../../claimActionMenu/actionTypes/dueDate';

const dueDate = {
  render: (batchClaim, { isCompact }) => {
    return <DueDateButton batchClaim={batchClaim} compact={isCompact} />;
  },
  dataType: 'date',
  onTableQuery: 'batchClaims',
  states: {
    DUE_DATE_SET: {
      name: 'Due Date Set',
      icon: <CalendarTwoTone twoToneColor="red" style={{ fontSize: '120%' }} />,
      timelineEventRendering: {
        description: userType => 'Due Date set',
        customLabel: ({ metaData: dueDateStr, createdAt }) => {
          return (
            <Tooltip title={formatDateUTC(createdAt)}>
              <strong>Due Date set</strong>
            </Tooltip>
          );
        },
        customRendering: ({ metaData: dueDateStr }) => {
          // time is always written in UTC but on recieving is converted to LOCAL
          return (
            <Tag>{moment(dueDateStr).utc().format('ddd MMM Do YYYY')}</Tag>
          );
        },
        renderingAllowed: ({ metaData: dueDateStr }) =>
          dueDateStr ? true : false,
      },
    },
    NO_DUE_DATE: {
      name: 'No Due Date',
      icon: <CalendarTwoTone twoToneColor="red" style={{ fontSize: '120%' }} />,
      timelineEventRendering: {
        description: userType => 'Due Date removed',
        customRendering: ({ metaData: dueDateStr }) => {
          // time is always written in UTC but on recieving is converted to LOCAL
          return (
            <Tag>{moment(dueDateStr).utc().format('ddd MMM Do YYYY')}</Tag>
          );
        },
        customLabel: ({ metaData: dueDateStr, createdAt }) => {
          return (
            <Tooltip title={formatDateUTC(createdAt)}>
              <strong>Due Date removed</strong>
            </Tooltip>
          );
        },
        renderingAllowed: ({ metaData: dueDateStr }) =>
          dueDateStr ? true : false,
      },
    },
  },
  // Note: Dont destructure date to ({dueDate}) as that clashes with the
  // name of the parent object which is also dueDate -- creating bugs
  filterBuilder: date => {
    // startDate, endDate are moment Objects or dateStrs -- in both cases it sets to Moment Obj
    let formattedStartDate = moment(date.dueDate[0]).utc().endOf('day').utc();
    let formattedEndDate = moment(date.dueDate[1]).utc().endOf('day').utc();

    // when range is the same date and user just wants one Date
    // we format the query as range between the start and end of that day
    if (formattedStartDate.diff(formattedEndDate) === 0) {
      formattedStartDate = moment(date.dueDate[0]).utc().startOf('day').utc();
      formattedEndDate = moment(date.dueDate[1]).utc().endOf('day').utc();
    }
    return {
      and: [
        { batchClaimDueDatesExist: true },
        {
          batchClaimStateById: {
            dueDate: { greaterThanOrEqualTo: formattedStartDate._d },
          },
        },
        {
          batchClaimStateById: {
            dueDate: { lessThanOrEqualTo: formattedEndDate._d },
          },
        },
      ],
    };
  },
};

export { dueDate };
