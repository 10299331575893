import { Button, Drawer, Space, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLog } from '../../common/log/useLog';
import tw, { css } from 'twin.macro';
import { batchClaimDocumentationFragment } from '../../fragments';
import { gql, useQuery } from 'urql';
import * as util from './documentViewerUtil';
import { arrayHasLen } from '../audit/util/array';
import { DocumentViewerNav } from './documentViewerNav';
import { UiAuditDocumentation } from './types';
import { orZero } from '../audit/util/orZero';
import { usePdfViewer } from '../../common/pdf/usePdfViewer';
import { createPortal } from 'react-dom';
import { SwClientContext } from '../../sw/swProvider';
import {
  SignedUrlUpdater,
  SignedUrlUpdaterResponse,
} from '../../sw/swIpc/client';
import { useDocuments } from './queries/useDocuments';
import pdfContainer from '../../pdf/container';

export const DocumentViewer = ({ claimId }: { claimId: string | null }) => {
  const log = useLog('documentViewer');
  // log.setLevel(log.levels.DEBUG);
  log.css = 'color: orange';

  const {
    pdfViewer,
    currentDocumentId,
    currentDocumentName,
    currentPageNumber,
    loading,
  } = usePdfViewer();

  useEffect(() => {
    if (pdfViewer?.pdfContainer) {
      if (loading) {
        log.trace('%c * loading disable interaction', log.css, loading);
        pdfViewer?.pdfContainer.disableInteraction();
      } else {
        log.trace('%c * loading enable interaction', log.css, loading);
        pdfViewer?.pdfContainer.allowInteraction();
      }
    }
  }, [loading, pdfViewer]);

  const [activeFileId, _setActiveFileId] = useState<string | null>(null);
  const setActiveFileId = useCallback(
    (caller: string, _activeFileId: string | null) => {
      log.trace('%c %s::setActiveFileId', log.css, caller, _activeFileId);
      _setActiveFileId(_activeFileId);
    },
    [_setActiveFileId]
  );

  const [activeFile, _setActiveFile] = useState<UiAuditDocumentation | null>(
    null
  );
  const setActiveFile = useCallback(
    (caller: string, _activeFile: UiAuditDocumentation | null) => {
      log.trace('%c %s::setActiveFile', log.css, caller, _activeFile);
      _setActiveFile(_activeFile);
    },
    [_setActiveFile]
  );

  useEffect(() => {
    if (currentDocumentId !== activeFileId) {
      setActiveFileId('currentDocId from viewer', currentDocumentId ?? null);
    }
  }, [currentDocumentId]);

  useEffect(() => {
    if (claimId && activeFileId && orZero(files?.length) > 0) {
      const fileExists = files.find(f => f.id === activeFileId) ?? null;
      log.trace(
        '%c check active file id',
        'color: purple',
        claimId,
        activeFileId,
        fileExists
      );
      if (!fileExists) {
        log.info('Clearing file id as collection has changed!!!');
        setActiveFileId('effectCheckAfId', activeFileId);
      }
    }
  }, [claimId, activeFileId]);

  useEffect(() => {
    if (!activeFileId && activeFile) {
      log.trace(
        '%c clear activeFile on activeFileId change to NULL',
        'color: red',
        activeFileId,
        files
      );
      setActiveFile('effectClearAfId', null);
    }
  }, [activeFileId]);

  // fetch handler:
  const [fetchDocuments, fetching] = useDocuments(claimId);
  const [files, setFiles] = useState<UiAuditDocumentation[]>([]);
  const fetchFiles = useCallback(
    () =>
      fetchDocuments(true)
        .then(_files => {
          return util.mapFilesToUiFiles(_files, claimId!);
        })
        .then(_files => {
          setFiles(oldFiles => _files);
          return _files;
        }),
    [fetchDocuments, claimId]
  );
  useEffect(() => {
    fetchFiles().then(_files => {
      log.debug('%cUpdated files', log.css, _files);
    });
  }, [fetchFiles, claimId]);

  // todo this could all be a hook that wraps e.g. useSwDocsClient and wires updater...
  const signedUrlUpdater: SignedUrlUpdater = useCallback<SignedUrlUpdater>(
    async (documentId: string) => {
      return fetchFiles().then(_files => {
        log.log(`Search ${documentId} in updated files`, _files);
        const f = _files.find(f1 => f1.id === documentId);
        if (!f) {
          return Promise.reject(
            `Document id for document ${documentId} not found in updated file collection!`
          );
        }
        return Promise.resolve<SignedUrlUpdaterResponse>({
          documentId,
          url: f.signedUrl,
          expires: f.expiresMs,
        });
      });
    },
    []
  );
  const swClient = useContext(SwClientContext);
  useEffect(() => {
    if (swClient) {
      swClient.registerSignedUrlUpdater(signedUrlUpdater);
    }
    return () => {
      if (swClient) {
        swClient.removeSignedUrlUpdater();
      }
    };
  }, [swClient, files]);

  useEffect(() => {
    if (activeFileId && arrayHasLen(files)) {
      const f = files.find(f => f.id === activeFileId) ?? null;
      log.debug(
        '%c set activeFile activeFileId change',
        'color: green',
        activeFileId,
        f,
        files
      );
      setActiveFile('effectSetAfOnAfIdChange', f);
    }
  }, [files, activeFileId]);

  const titleEl = pdfViewer?.getTitleHtmlElement();
  const navEl = pdfViewer?.getNavHtmlElement();
  const overlayEl = pdfViewer?.getOverlayHtmlElement();

  const [rewriteEnabled, setRewriteEnabled] = useState<boolean>(true);

  log.trace('%c docViewer render', log.css, claimId, activeFileId, {
    files,
  });
  return (
    // No outer container as it'll be rendered below the main screen, causing scrollbars...
    <>
      {overlayEl &&
        createPortal(
          <>
            {loading && (
              <div className="_w-full _h-full _grid _grid-cols-1 _place-content-center _justify-items-center">
                <Spin size={'large'}></Spin>
              </div>
            )}
          </>,
          overlayEl
        )}
      {titleEl &&
        createPortal(
          <div tw="_w-full _text-xs _bg-inherit _grid _grid-cols-[minmax(0,_1fr)_minmax(_min-content,min-content)] _place-content-center _justify-items-center">
            <div tw="_line-clamp-1 _overflow-ellipsis">
              {currentDocumentName}
            </div>
            <div tw="_ml-auto _bg-inherit _pr-3 _whitespace-nowrap _line-clamp-1 _overflow-ellipsis">
              {currentPageNumber ? `p. ${currentPageNumber}` : ''}
            </div>
          </div>,
          titleEl
        )}
      {navEl &&
        createPortal(
          // <div tw="_flex _flex-col _h-full">
          //   <div tw="_text-xs _text-antd-lt-colorTextSecondary _p-2">
          //     Rewrite URLs Enabled: [
          //     <span
          //       tw="_text-antd-lt-colorPrimaryText"
          //       onClick={() => {
          //         if (swClient && pdfViewer) {
          //           setActiveFile('togglerewrite', null);
          //           pdfViewer.closeDocument();
          //           swClient
          //             .setSignedUrlRewriteEnabled(!rewriteEnabled)
          //             .then(x => setRewriteEnabled(!!x));
          //         }
          //       }}
          //     >
          //       {rewriteEnabled ? 'true' : 'false'}
          //     </span>
          //     ]
          //   </div>

          <DocumentViewerNav
            claimId={claimId}
            activeFile={activeFile}
            files={files}
            setActiveFile={activeFile => setActiveFile('here', activeFile)}
            fetching={fetching}
          ></DocumentViewerNav>,
          // </div>
          navEl
        )}
    </>
  );
};
