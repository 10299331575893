import { useEffect, useState } from 'react';
import { gql } from 'urql';
import { css } from '@emotion/react';
import { useQuery, useMutation } from 'urql';
import { Menu, message, Modal, Spin, Form } from 'antd';
import { batchClaimsNodeKey, claimMenuItemDetails } from '../../util';
import { CreateReportPreview } from '../../../reports/reportPreview';
import { CreateReportForm } from '../../../createReportModal/createReportForm';
import { generateReportMutation } from '../../../../graphql/mutations';
import { reportServiceErr } from '../../../../util/errors';
import { getUiEnabledReportTypes } from './reportsUtil';

// nameclash - a lot of use of 'message' in this component
const alertMessage = message;

// TODO - move to queries and fragments
const reportableClaimsQuery = gql`
  query reportableClaims($batchClaimFilter: BatchClaimFilter!) {
    batchClaims(filter: $batchClaimFilter) {
      totalCount
      nodes {
        id
        icn
        claimNumber: icn
        providerId
        insurerId
        reportVersions {
          nodes {
            id
            title
            auditFindingReportType
            auditFindingReportSubtype
            isLatestVersion
          }
        }
      }
    }
  }
`;

const typeActions = {
  createReport: {
    title: 'Create Report',
    value: 'REPORT',
    actionCriteria: ({ batchClaimState: { reported, workflowClaimState } }) => {
      // IMPROVEMENT - populate with the exact sanitized claim filter from the report service
      // Or else this number may not match with the generate report modal. This is only
      // a UI improvement, no risk of bad reporting
      // const satisfied = !reported && workflowClaimState === 'COMPLETED';
      const satisfied = workflowClaimState === 'COMPLETED';
      return { satisfied };
    },
  },
};

/**
 * Takes an array of batch claims and confirms that all reportable
 * criteria are met. Returns an object with a success boolean and a message
 * That will be blank if all criteria are met
 * @param {*} batchClaims [BatchClaims]!
 * @returns { valid: Boolean!, message: String }
 */
const validateReportableClaims = batchClaims => {
  // when there are no claims to report
  if (!batchClaims.length) {
    return { valid: false, message: 'No claims to report' };
  }

  // can only submit a report with the same
  // providerId and insurerId
  const { insurerIds, providerIds } = batchClaims.reduce(
    (agg, { insurerId, providerId }) => {
      agg.insurerIds.add(insurerId);
      agg.providerIds.add(providerId);
      return agg;
    },
    { insurerIds: new Set(), providerIds: new Set() }
  );

  if (insurerIds.size === 1 && providerIds.size === 1) {
    return { valid: true };
  } else if (insurerIds.size > 1 || providerIds.size > 1) {
    return {
      valid: false,
      message: (
        <div>
          <div css={css({ padding: '.75em' })}>
            All claims must be from the same insurer and provider, consider
            adding an Insurer or Provider to your filter.
          </div>
          <div css={css({ padding: '.75em' })}>
            Found the following providers: {Array(...providerIds).join(', ')}
          </div>
          <div css={css({ padding: '.75em' })}>
            Found the following insurers: {Array(...insurerIds).join(', ')}`
          </div>
        </div>
      ),
    };
  } else {
    // something unexpected happened
    console.error('Error validating batch claims for report', batchClaims);
    return {
      valid: false,
      message: (
        <div css={css({ padding: '1em' })}>
          An unexpected error occurred, please contact support.
        </div>
      ),
    };
  }
};

const ReportGenerationModal = ({
  batchClaimIds,
  reportModalVisible,
  setReportModalVisible,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const reportableAuditFindingsFilter = {
    isActive: { equalTo: true },
    accepted: { equalTo: true },
    improperPaymentCost: { greaterThan: 0 },
    superseded: { equalTo: false },
  };

  const reportableBatchClaimsFilter = {
    and: [
      {
        id: {
          in: batchClaimIds,
        },
      },

      {
        auditFindings: {
          some: {
            isActive: { equalTo: true },
            accepted: { equalTo: true },
            improperPaymentCost: { greaterThan: 0 },
            superseded: { equalTo: false },
          },
        },
      },
      {
        workflowClaimStatesExist: true,
        batchClaimStateById: {
          workflowClaimState: {
            equalTo: 'COMPLETED',
          },
          // reported: { equalTo: false },
        },
      },
    ],
  };

  const [form] = Form.useForm();

  const [_, generateReport] = useMutation(generateReportMutation);

  // fetch batch claim data for report form
  const [
    {
      fetching: reportableBatchClaimsFetching,
      data: reportableBatchClaimsData,
    },
  ] = useQuery({
    query: reportableClaimsQuery,
    variables: {
      batchClaimFilter: reportableBatchClaimsFilter,
      auditFindingFilter: reportableAuditFindingsFilter,
    },
  });
  const loading = reportableBatchClaimsFetching && !reportableBatchClaimsData;
  const reportableBatchClaims = reportableBatchClaimsData?.batchClaims;

  // validate the queried claims
  const {
    valid: isSelectionReportable,
    message: selectionValidationFailedMessage,
    error: selectionValidationErrorMessage,
  } = !loading && validateReportableClaims(reportableBatchClaims.nodes);

  const [reportTypes, setReportTypes] = useState({});
  useEffect(() => {
    if (reportableBatchClaimsData?.batchClaims?.nodes?.length ?? 0 > 0) {
      const claims = reportableBatchClaimsData.batchClaims.nodes;
      const reportTypes = getUiEnabledReportTypes(claims);
      setReportTypes(reportTypes);
    } else {
      if (reportTypes.length === 0) {
        setReportTypes({});
      }
    }
  }, [reportableBatchClaimsData]);

  return (
    <Modal
      key={`report-generation-modal`}
      open={reportModalVisible}
      confirmLoading={confirmLoading}
      title="Create Report"
      onCancel={() => {
        setReportModalVisible(false);
      }}
      onOk={() => {
        if (!isSelectionReportable) {
          setReportModalVisible(false);
          return;
        }

        form.validateFields().then(async formData => {
          console.log(
            'Create report form is valid. Generating report',
            formData,
            'batchClaimFilter:',
            reportableBatchClaimsFilter,
            'auditFindingFilter:',
            reportableAuditFindingsFilter
          );

          const { primaryPiaId, reportType, reportSubtype } = formData;

          const reportData = {
            batchClaimIds: (reportableBatchClaims.nodes || []).map(
              rbc => rbc.id
            ),
            primaryPiaId,
            reportType,
            reportSubtype,
          };

          console.log('report data ', reportData);

          const { data, error } = await generateReport(reportData);

          if (data) {
            // IMPROVEMENT - Use the filter to update the reported claims in the UI
            alertMessage.success('Report Created!');
            setReportModalVisible(false);
          } else {
            console.error('Create Report Error =>', error);
            alertMessage.error(
              `Error creating report: ${reportServiceErr(error)}`
            );
            setReportModalVisible(false);
          }
        });
      }}
    >
      {loading && <Spin />}
      {!loading && isSelectionReportable ? (
        <div css={css({ padding: '0 1em' })}>
          {/* IMPROVEMENT: Add a clickable claim list to the report preview */}
          {/* IMPROVEMENT: Add Client Savings to the statistics */}
          <CreateReportPreview
            variables={{
              batchClaimFilter: reportableBatchClaimsFilter,
              auditFindingFilter: reportableAuditFindingsFilter,
            }}
          />
          <CreateReportForm form={form} reportTypes={reportTypes} />
        </div>
      ) : (
        selectionValidationErrorMessage || selectionValidationFailedMessage
      )}
    </Modal>
  );
};

const actionSubMenuItem = ({ value, batchClaims, actionCriteria, title }) => {
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const actionClaims = batchClaims.filter(i => actionCriteria(i).satisfied);
  const batchClaimIds = actionClaims.map(batchClaim => batchClaim.id);

  return (
    <Menu.Item
      key={`${value}-report-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
    >
      <span>
        <span
          onClick={() => {
            if (actionClaims.length > 0) setReportModalVisible(true);
          }}
          key={`${value}-report-menu-item-modal-${batchClaimsNodeKey(
            batchClaims
          )}`}
        >
          {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
        </span>
        <span>
          <ReportGenerationModal
            {...{ batchClaimIds, reportModalVisible, setReportModalVisible }}
          />
        </span>
      </span>
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions }) => {
  return (
    <Menu.SubMenu
      key={`report-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Report"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions['report'].includes(action)
        )
        .map(([action, { ...actionProps }]) =>
          actionSubMenuItem({
            action,
            batchClaims,
            ...actionProps,
          })
        )}
    </Menu.SubMenu>
  );
};

const report = {
  title: 'Report',
  subMenu: actionSubMenu,
};

export {
  report,
  ReportGenerationModal,
  CreateReportForm,
  CreateReportPreview,
  validateReportableClaims,
};
