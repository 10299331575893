import {
  DollarCircleOutlined,
  CreditCardOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { parseGroupedAggregatesQueryResIntoProgessStatProps } from '../../claimStateUtil';

const invoice = {
  auditProgress: {
    stateTypeLabel: 'Invoices',
    defaultLabel: 'No Invoices generated yet',
    tooltipLabel: 'Status of Invoices amongst claim(s)',
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: INVOICE_STATE) on
     *     the invoiceClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: ({ permissions }) => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `invoice: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
          ]
        }
      ) {
        groupedAggregates(groupBy: INVOICE_CLAIM_STATE) {
          keys
          distinctCount {
            id
          }
        }
      }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e PAID): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims,
        invoice: { groupedAggregates },
      },
      permissions,
      userType,
      queryableStates,
      defaultStates,
      states,
    }) => {
      let sumOfAggregates = 0;
      const formattedQueryResults = { NOT_PAID: 0, SENT: 0, PAID: 0 };
      groupedAggregates.forEach(({ keys: [keyName, _], distinctCount }) => {
        sumOfAggregates += parseInt(distinctCount.id);
        formattedQueryResults[keyName] = parseInt(distinctCount.id);
      });
      return parseGroupedAggregatesQueryResIntoProgessStatProps({
        totalClaims,
        sumOfAggregates,
        formattedQueryResults,
        permissions,
        userType,
        stateType: 'invoice',
        queryableStates,
        defaultStates,
        states,
      });
    },
  },
  queryableStates: ['NOT_PAID', 'SENT', 'PAID'],
  defaultStates: ['NOT_PAID'],
  dataType: 'enum',
  onTableQuery: 'batchClaims',
  states: {
    //defaultState
    NOT_PAID: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'No Invoice';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: 'green',
      icon: (
        <ExclamationCircleOutlined
          style={{ color: 'lightgray', fontSize: '120%' }}
        />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'No invoice to be Paid';
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: userType => 'Invoice revoked/Payment Refunded',
        renderingAllowed: event => true,
      },
    },
    SENT: {
      name: userType => {
        return {
          PROVIDER: 'Invoice Pending',
          PAYER: 'Invoice Pending',
          ADMINISTRATOR: 'Invoice Sent',
          AUDITOR: 'Invoice Sent',
        }[userType];
      },
      primaryColor: '#b0db42', //#3d9c35
      strokeColor: 'white',
      tagColor: 'green',
      icon: (
        <CreditCardOutlined style={{ color: '#b0db42', fontSize: '120%' }} />
      ),
      description: userType => {
        return {
          PROVIDER: 'Invoice Pending for payment',
          PAYER: 'Invoice Pending for payment',
          ADMINISTRATOR: 'Invoice Sent for payment',
          AUDITOR: 'Invoice Sent for payment',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { invoiceClaimState } }) =>
        invoiceClaimState === 'SENT',
      timelineEventRendering: {
        description: userType => 'Invoice was generated for payment',
        renderingAllowed: event => true,
      },
    },
    PAID: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Invoice is Paid';
      },
      primaryColor: '#52c41a', //#3d9c35
      strokeColor: 'white',
      tagColor: 'green',
      icon: (
        <DollarCircleOutlined style={{ color: '#52c41a', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Invoice has been paid';
      },
      displayCondition: ({ batchClaimState: { invoiceClaimState } }) =>
        invoiceClaimState === 'PAID',
      timelineEventRendering: {
        description: userType => 'Invoice for claim(s) Paid',
        renderingAllowed: event => true,
      },
    },
  },
  filterBuilder: values => {
    /* 
      POSSIBLE VALUES = [NOT_PAID, SENT, PAID]

      filter required for each value:
      NOT_PAID
        - invoiceClaimStatesExist = false
          -- or --
        - invoiceClaimState = NOT_PAID

      SENT | PAID
      - invoiceClaimStatesExist: true   
        -- and --
      - invoiceClaimState = SENT | PAID
    */

    if (values.includes('NOT_PAID'))
      /*
        when NOT_PAID and one of SENT | PAID are in values we add the 'OR'
        because for the case when invoiceClaimStatesExist is false that handles the 'NOT_PAID' scenerio
        and the pg function 'invoiceClaimState' handles the remaining scenerios
      */
      return {
        or: [
          { invoiceClaimStatesExist: false },
          { batchClaimStateById: { invoiceClaimState: { in: values } } },
        ],
      };
    else
      return {
        invoiceClaimStatesExist: true,
        batchClaimStateById: { invoiceClaimState: { in: values } },
      };
  },
};

export { invoice };
