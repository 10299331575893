import {
  MailOutlined,
  SendOutlined,
  CloudUploadOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { parseGroupedAggregatesQueryResIntoProgessStatProps } from '../../claimStateUtil';

const documentation = {
  auditProgress: {
    stateTypeLabel: 'Documentation',
    defaultLabel: 'No Docs Requested',
    tooltipLabel: 'Status of doc requests',
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: DOCUMENTATION_CLAIM_STATE) on
     *     the batchClaimState table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: ({ permissions }) => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `documentation: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
          ]
        }
      ) {
        groupedAggregates(groupBy: DOCUMENTATION_CLAIM_STATE) {
          keys
          distinctCount {
            id
          }
        }
      }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e REQUEST_DRAFTED): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims,
        documentation: { groupedAggregates },
      },
      permissions,
      userType,
      queryableStates,
      defaultStates,
      states,
    }) => {
      let sumOfAggregates = 0;
      const formattedQueryResults = {
        NO_REQUESTS: 0,
        REQUEST_DRAFTED: 0,
        AWAITING_UPLOAD: 0,
        DOCUMENTS_RECEIVED: 0,
      };
      groupedAggregates.forEach(({ keys: [keyName, _], distinctCount }) => {
        sumOfAggregates += parseInt(distinctCount.id);
        formattedQueryResults[keyName] = parseInt(distinctCount.id);
      });
      return parseGroupedAggregatesQueryResIntoProgessStatProps({
        totalClaims,
        sumOfAggregates,
        formattedQueryResults,
        permissions,
        userType,
        stateType: 'documentation',
        queryableStates,
        defaultStates,
        states,
      });
    },
  },
  defaultStates: ['NO_REQUESTS'],
  queryableStates: ['NO_REQUESTS', 'AWAITING_UPLOAD', 'DOCUMENTS_RECEIVED'],
  dataType: 'enum',
  onTableQuery: 'batchClaims',
  states: {
    // default state
    NO_REQUESTS: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) without Requests';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: 'lightgray',
      icon: (
        <FileExcelOutlined style={{ color: 'lightgray', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) that currently requires no documentation';
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: userType => 'Document Request Removed',
        renderingAllowed: event => true,
      },
    },
    AWAITING_UPLOAD: {
      name: userType => {
        return {
          PROVIDER: 'Claim(s) Awaiting Documentation',
          PAYER: 'Claim(s) Awaiting Documentation',
          ADMINISTRATOR: 'Documentation Requested',
          AUDITOR: 'Documentation Requested',
        }[userType];
      },
      primaryColor: '#40a9ff',
      strokeColor: 'white',
      tagColor: 'blue',
      icon: <SendOutlined style={{ color: '#40a9ff', fontSize: '120%' }} />,
      description: userType => {
        return {
          PROVIDER: 'Claim(s) awaiting documentation from Provider',
          PAYER: 'Claim(s) awaiting documentation from Provider',
          ADMINISTRATOR: 'Documentation Requested for claim(s)',
          AUDITOR: 'Documentation Requested for claim(s)',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { documentationClaimState } }) =>
        documentationClaimState === 'AWAITING_UPLOAD',
      timelineEventRendering: {
        renderingAllowed: event => true,
        description: userType => 'Documentation was Requested',
      },
    },
    DOCUMENTS_RECEIVED: {
      name: userType => {
        return {
          PROVIDER: 'Documentation Uploaded',
          PAYER: 'Documentation Uploaded',
          ADMINISTRATOR: 'Documentation Recieved',
          AUDITOR: 'Documentation Recieved',
        }[userType];
      },
      primaryColor: '#9254de',
      strokeColor: 'white',
      tagColor: 'purple',
      icon: (
        <CloudUploadOutlined style={{ color: '#9254de', fontSize: '120%' }} />
      ), // only provider uploads files and they don't have bulk uploads yet
      description: userType => {
        return {
          PROVIDER: 'Documentation Uploaded for claim(s)',
          PAYER: 'Documentation Uploaded for claim(s)',
          ADMINISTRATOR: 'Documentation Received for claim(s)',
          AUDITOR: 'Documentation Received for claim(s)',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { documentationClaimState } }) =>
        documentationClaimState === 'DOCUMENTS_RECEIVED',
      timelineEventRendering: {
        description: userType => 'Documentation was Uploaded',
        renderingAllowed: event => true,
      },
    },
  },
  filterBuilder: values => {
    /* 
      POSSIBLE VALUES = [NO_REQUESTS, AWAITING_UPLOAD, DOCUMENTS_RECEIVED]

      filter required for each value:
      NO_REQUESTS
        - documentationClaimStatesExist = false
          -- or --
        - documentationClaimState = NO_REQUESTS

      AWAITING_UPLOAD | DOCUMENTS_RECEIVED ---  NOTE: since a  documentRequestCreatedsExist is required for any doc upload
      - documentationClaimStatesExist: true   
        -- and --
      - documentationClaimState = AWAITING_UPLOAD | DOCUMENTS_RECEIVED
    */
    if (values.includes('NO_REQUESTS'))
      /*
        when NO_REQUESTS and one of AWAITING_UPLOAD | DOCUMENTS_RECEIVED are in values we add the 'OR'
        because for the case when documentRequestCreatedsExist is false that handles the 'NO_REQUESTS' scenerio
        and the pg function 'documentationClaimState' handles the remaining scenerios
      */
      return {
        or: [
          { documentRequestCreatedsExist: false },
          { batchClaimStateById: { documentationClaimState: { in: values } } },
        ],
      };
    else
      return {
        documentRequestCreatedsExist: true,
        batchClaimStateById: { documentationClaimState: { in: values } },
      };
  },
};

export { documentation };
