import tw, { css } from 'twin.macro';
import React, { FC } from 'react';
import { isEmpty, omit } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { objectPropToTitle } from './autodorFindingUtils';

const getIndent = (indent: number): string =>
  indent > 0 ? `indent-${Math.min(indent, 6)}` : '';

const clsDtable = css({
  ...tw`_flex _flex-row _gap-2 _mt-2`,
  '& .label': tw`_text-left _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary`,
  '&>.header': tw`_font-bold`,
  //  _line-clamp-2
  '& .value': tw`_text-left _leading-5 _align-baseline _my-auto 
      _p-0 _whitespace-normal _break-words`,
  '& .html-value': tw`_w-full _whitespace-pre-wrap`,
  '& .indent-1': tw`_pl-3`,
  '& .indent-2': tw`_pl-6`,
  '& .indent-3': tw`_pl-9`,
  '& .indent-4': tw`_pl-12`,
  '& .indent-5': tw`_pl-16`,
  '& .indent-6': tw`_pl-20`,
});

const stripSystemKeys = (object: Record<string, any>) =>
  omitDeep(object, ['__typename']);

export const hasDisplayableData = (object: Record<string, any>) => {
  const stripped: Record<string, any> = stripSystemKeys(object ?? {});
  const keys = Object.keys(stripped);
  return (
    keys.length > 0 && keys.some(k => !!stripped[k] && !isEmpty(stripped[k]))
  );
};

const mapObject = (
  title: string | null,
  object: Object,
  parents: string[] = [],
  indent: number = 1
): any => {
  // improvement: could also accept an 'order' param containing an array of
  // top-level (root) prop names in the desired display order
  const _object = stripSystemKeys(object);
  if (!hasDisplayableData(_object)) {
    return null;
  }
  return (
    <>
      {title && (
        <div css={clsDtable}>
          <div className={`label header${getIndent(indent)}`}>{title} </div>
        </div>
      )}
      {Object.entries(_object).map(([key, value], idx) => {
        if (typeof value === 'object') {
          if (hasDisplayableData(value)) {
            return mapObject(
              objectPropToTitle(key),
              value,
              [...parents, key],
              indent + 1
            );
          } else {
            return null;
          }
        }
        const _value = '' + value;
        return (
          <div css={clsDtable} key={key}>
            <div className={`label ${getIndent(indent + 1)}`}>
              {objectPropToTitle(key)}{' '}
            </div>
            <div className="value">{_value}</div>{' '}
          </div>
        );
      })}
    </>
  );
};

export const AutodorDynamicDetails: FC<{
  type: string;
  title: string | null;
  object?: Object | null | undefined;
}> = ({ type, title, object }) => {
  if (!object) {
    return <></>;
  }
  return (
    <div>
      <div>{mapObject(title, object, [type])}</div>
    </div>
  );
};
