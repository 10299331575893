/*
  const loggedIn = useObservableSubscription(
    alaffiaClient?.loginStatus?.pipe(
      map(loginStatus => loginStatus?.loggedIn ?? false)
    ),
    false
  );
 */

import { useObservableSubscription } from '../util/Observable';
import { useAlaffiaClient } from './useAlaffiaClient';
import { useEffect, useState } from 'react';
import { useLog } from '../common/log/useLog';
import {
  ExplicitLogoutEvent,
  LoginStatusEvent,
  LoginSuccessResult,
  LogoutEvent,
} from '@alaffia-technology-solutions/alaffia-client-sdk';

const isLogoutEvent = (
  data: LogoutEvent | LoginSuccessResult | undefined
): data is LogoutEvent => {
  return data?.type === 'SessionExpiredEvent' || data?.type === 'LogoutEvent';
};

const isExplicitLogoutEvent = (
  data: LogoutEvent | LoginSuccessResult | undefined
): data is ExplicitLogoutEvent => {
  return data?.type === 'LogoutEvent';
};

export const useLoginStatus = () => {
  const log = useLog('useLoginStatus');
  // log.setLevel('debug', false);

  const alaffiaClient = useAlaffiaClient();

  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  const latest = useObservableSubscription(alaffiaClient?.loginStatus, null);

  log.debug('%c useLoginStatus', 'color:blue', { latest });

  useEffect(() => {
    if (latest?.loggedIn && latest?.data && !isLogoutEvent(latest.data)) {
      if (userId && userId !== latest.data.userId) {
        setHasLoggedIn(false);
      } else {
        setHasLoggedIn(true);
      }
      setLoggedIn(latest.loggedIn);
      setUserId(latest.data.userId);
      setUserRole(latest.data.userRole);
    } else {
      // if (isExplicitLogoutEvent(latest?.data)) {
      //   setHasLoggedIn(false);
      // }
      setLoggedIn(false);
      setUserId(null);
      setUserRole(null);
    }
    return () => {
      log.debug(
        '%c useLoginStatus effect cleanup',
        'color: orange; background-color: blue',
        {
          loggedIn,
          hasLoggedIn,
          userId,
        }
      );
    };
  }, [latest]);

  log.debug('%c useLoginStatus render', 'color:blue', {
    loggedIn,
    hasLoggedIn,
    userId,
  });
  return {
    loggedIn,
    hasLoggedIn,
    userId,
    userRole,
  };
};
