import { gql, useMutation, useQuery } from 'urql';
import { Menu, message } from 'antd';
import { batchClaimState } from '../../../fragments';
import { batchClaimsNodeKey, claimMenuItemDetails } from '../util';

const setProviderSignOffMutation = gql`
  mutation setProviderSignOffMutation(
    $batchClaimIds: [UUID!]!
    $state: ProviderSignOffClaimStateEnum!
  ) {
    setProviderSignOffClaimStates(
      input: { batchClaimIds: $batchClaimIds, providerSignOffState: $state }
    ) {
      providerSignOffClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const setClientSignOffMutation = gql`
  mutation setClientSignOffMutation(
    $batchClaimIds: [UUID!]!
    $state: ClientSignOffClaimStateEnum!
  ) {
    setClientSignOffClaimStates(
      input: { batchClaimIds: $batchClaimIds, clientSignOffState: $state }
    ) {
      clientSignOffClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const actionSubMenuItem = ({
  batchClaims,
  setSignOff,
  action,
  value,
  actionCriteria,
  title,
  setLoading,
}) => {
  const actionClaims = batchClaims.filter(i => actionCriteria(i).satisfied);
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setSignOff({
          batchClaimIds: actionClaims.map(({ id }) => id),
          state: value,
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          message.error(errorMessage);
        } else {
          message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

// sign off actions
// two types of sign offs ( client, provider )
// each type has two possible values - sign off or no sign off
const typeActions = {
  setProviderSignOff: {
    mutation: setProviderSignOffMutation,
    values: [
      {
        title: 'Add Provider Sign Off',
        value: 'PROVIDER_SIGN_OFF',
        actionCriteria: ({
          batchClaimState: {
            reported,
            workflowClaimState,
            invoiceClaimState,
            negotiationClaimState,
            providerSignOffClaimState,
          },
        }) => {
          const satisfied =
            // must be reported
            reported &&
            // claim cant be signed off on while in progress
            workflowClaimState === 'COMPLETED' &&
            // claim cannot be paid
            invoiceClaimState !== 'PAID' &&
            // claim cannot be in negotiation
            negotiationClaimState !== 'NEGOTIATING' &&
            // claim is not already signed off
            providerSignOffClaimState !== 'PROVIDER_SIGN_OFF';

          return { satisfied };
        },
      },
      {
        title: 'Remove Provider Sign Off',
        value: 'PROVIDER_NO_SIGN_OFF',
        actionCriteria: ({
          batchClaimState: {
            invoiceClaimState,
            negotiationClaimState,
            providerSignOffClaimState,
          },
        }) => {
          const satisfied =
            // claim cannot be paid
            invoiceClaimState !== 'PAID' &&
            // claim cannot be in negotiation
            negotiationClaimState !== 'NEGOTIATING' &&
            // claim has provider sign off
            providerSignOffClaimState === 'PROVIDER_SIGN_OFF';

          return { satisfied };
        },
      },
    ],
    renderMenuItem: ({ batchClaims, mutation, action, setLoading, title }) => {
      const [{ fetching, error }, setSignOff] = useMutation(mutation);

      return typeActions[action].values.map(
        ({ title, value, actionCriteria }) =>
          actionSubMenuItem({
            batchClaims,
            setSignOff,
            action,
            actionCriteria,
            title,
            value,
            setLoading,
          })
      );
    },
  },
  setClientSignOff: {
    mutation: setClientSignOffMutation,
    values: [
      {
        title: 'Add Client Sign Off',
        value: 'CLIENT_SIGN_OFF',
        actionCriteria: ({
          batchClaimState: {
            reported,
            workflowClaimState,
            invoiceClaimState,
            negotiationClaimState,
            clientSignOffClaimState,
          },
        }) => {
          const satisfied =
            // must be reported
            reported &&
            // claim cant be signed off on while in progress
            workflowClaimState === 'COMPLETED' &&
            // claim cannot be paid
            invoiceClaimState !== 'PAID' &&
            // claim cannot be in negotiation
            negotiationClaimState !== 'NEGOTIATING' &&
            // claim is not already signed off
            clientSignOffClaimState !== 'CLIENT_SIGN_OFF';

          return { satisfied };
        },
      },
      {
        title: 'Remove Client Sign Off',
        value: 'CLIENT_NO_SIGN_OFF',
        actionCriteria: ({
          batchClaimState: {
            invoiceClaimState,
            negotiationClaimState,
            clientSignOffClaimState,
          },
        }) => {
          const satisfied =
            // claim cannot be paid
            invoiceClaimState !== 'PAID' &&
            // claim cannot be in negotiation
            negotiationClaimState !== 'NEGOTIATING' &&
            // claim has client sign off
            clientSignOffClaimState === 'CLIENT_SIGN_OFF';

          return { satisfied };
        },
      },
    ],
    renderMenuItem: ({ batchClaims, mutation, action, setLoading, title }) => {
      const [{ fetching, error }, setSignOff] = useMutation(mutation);

      return typeActions[action].values.map(
        ({ title, value, actionCriteria }) =>
          actionSubMenuItem({
            batchClaims,
            setSignOff,
            action,
            actionCriteria,
            title,
            value,
            setLoading,
          })
      );
    },
  },
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }) => (
  <Menu.SubMenu
    key={`sign-off-submenu-${batchClaimsNodeKey(batchClaims)}`}
    title="Sign Offs"
  >
    {Object.entries(typeActions)
      .filter(([action, _]) =>
        permissions.claimActions['signOff'].includes(action)
      )
      .map(([action, { renderMenuItem, ...actionProps }]) =>
        renderMenuItem({ batchClaims, action, setLoading, ...actionProps })
      )}
  </Menu.SubMenu>
);

// The subMenu property must return a function and not a react component
// Or else unexpected behavior can occur such as a non-unique key error from antd
// https://ant.design/components/menu/#Notes-for-developers
const signOff = {
  title: 'Sign Offs',
  subMenu: actionSubMenu,
};

export { signOff };
