import { Button } from 'antd';
import { css } from '@emotion/react';
import React, { useState } from 'react';
import TableColumnSelector from './tableColumnSelector';
import { ProfileOutlined } from '@ant-design/icons';

const clsFlexContainer = css({ display: 'flex' });
const clsIcon32 = css({ height: '32px' });
const clsFlex = css({
  flex: 'initial',
  fontSize: '120%',
  paddingLeft: '1%',
  alignItems: 'center',
});
const clsFlexIcon = [
  clsIcon32,
  css({
    display: 'flex',
    alignItems: 'center',
    height: '32px',
  }),
];
const clsGetIconStyle = showSelector => [
  showSelector
    ? css({ color: '#1890ff' })
    : css({ color: 'rgba(0, 0, 0, 0.25)' }),
  css({ fontSize: '1em', fontWeight: 'lighter' }),
];

/**
 * Wraps TableColumnSelector to provide an iconized version.
 * Takes a useState() output to reset the table based on what columns
 * are selected
 * @params columns - [<Antd.Column>] array of all of the columns that can be chosen
 * @params selectedColumns - [columnKeys] and array of column keys that are selected
 * @params disabledColumns - [columnKeys] and array of column keys that cannot be changed in the UI
 * @params setSelectedColumns - useState.set_ function to set selected columns
 * */
const TableColumnSelectorCollapsible = ({
  columns,
  selectedColumns,
  disabledColumns = [],
  setSelectedColumns,
}) => {
  const [showSelector, setShowSelector] = useState(false);
  return (
    <div css={clsFlexContainer}>
      {showSelector ? (
        <div css={clsFlex}>
          <TableColumnSelector
            columns={columns}
            selectedColumns={selectedColumns}
            disabledColumns={disabledColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </div>
      ) : undefined}
      <div css={clsFlexIcon}>
        <Button
          data-cy={'table-col-selector-icon'}
          css={clsIcon32}
          onClick={() => setShowSelector(show => !show)}
          type="secondary"
        >
          <ProfileOutlined
            css={clsGetIconStyle(showSelector)}
          ></ProfileOutlined>
        </Button>
      </div>
    </div>
  );
};

export default TableColumnSelectorCollapsible;
