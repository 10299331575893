import { useFindingModel } from '../model/finding';
import { BatchClaim, Claim } from '../../../../../gql/graphql';
import { UUID } from '../../../../../../types/ids';
import { FindingEditorOp } from '../types';
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { UserContext } from '../../../../context/user';
import { useClaimPostFindingRefresh } from '../../../queries/claim/useClaimFindingsAndLine';
import { usePaymentRate } from '../../../util/usePaymentRate';
import { FindingViewController } from './findingViewController';
import { useClassWithReducer } from '../../../../../common/reducer/useClassWithReducer';
import { useDenialCodes } from '../../../queries/denialCodes/useDenialCodes';
import { RefreshBatchClaimContext } from '../../../auditWorkspace';

export type UseFindingViewModelProps = {
  claimId?: UUID; // todo used?
  batchClaim?: BatchClaim;
  claim?: Claim;
  currentValuesRef: MutableRefObject<any>;
};

export type useFindingViewControllerProps = {
  config: UseFindingViewModelProps;
  currentOperationRef: MutableRefObject<FindingEditorOp | null>;
};

/**
 * useFindingViewController - essentially a factory method for building/returning
 * a configured ViewController class which acts as a bridge between the model (server data)
 * and the viewModel/view (display), providing common data transformations and operations
 * for all form types/implementations (viewModel can further customize these on a per-form basis)
 * @param config
 * @param currentOperationRef
 */
export const useFindingViewController = ({
  config,
  currentOperationRef,
}: useFindingViewControllerProps) => {
  // hooks and ctx values passed into the class
  const { id: authorId } = useContext(UserContext) as any;
  const paymentRateUtility = usePaymentRate(config.batchClaim ?? null);
  const findingModel = useFindingModel();
  const newClaimRefresher = useClaimPostFindingRefresh();
  const batchClaimRefresher: any = useContext(RefreshBatchClaimContext);
  const denialCodes = useDenialCodes();

  // 'Claim Refresh' logic -- wip -- may move to grapheCache ops or into the Model...
  // todo: hack - there's something weird about declining + reviewing (to re-accept)
  //  a finding in terms of how the attempt to invalidate batchClaim works in graphCache
  //  In short, it works for all other ops, but reviewing declined does not trigger refetch
  //  of batchClaim... after many hours I gave up on trying to find what's different... so
  //  for now this is adding hack 2 (calling an explicit batchClaim refresh) to hack 1 (the
  //  new claim refresher that gets separate individual IB lines (to avoid reloading the whole
  //  table) and gets claim.findings to update the findings in the cache.  This should all go
  //  away when we have a unified Claim resolver (and maybe a cache-write for the IB line finding ids)
  //  but we really need a way to unit test graphCache (e.g. via msw) to have safety on cache updates...
  const claimRefresher = useCallback(
    async ({
      claimId,
      ibLineIds,
    }: {
      claimId: string;
      ibLineIds: string[];
    }) => {
      return newClaimRefresher({
        claimId,
        ibLineIds,
      }).then(claimRefreshResult => {
        // TODO this refresh will be obsolete when we finish the cut-over to the Claim resolver...
        //  r/n it has the benefit of using the top-level Audit Workspace query, however the useContext fn
        //  doesn't return the promise so we don't wait for it -- we fire off the IB Claim refresh first and
        //  wait for it so that the refresh is more responsive there, but then call this which doesn't block,
        //  and return the IB Claim result...
        // network-only is key here, to catch all scenarios, per graphCache note above
        batchClaimRefresher({ requestPolicy: 'network-only' });
        return claimRefreshResult;
      });
    },
    []
  );

  // Instantiate the class
  const fvc = useMemo(() => {
    return new FindingViewController({
      findingModel,
      claimRefresher,
      paymentRateUtility,
      denialCodes,
      currentValuesRef: config.currentValuesRef,
    });
  }, []);

  // Wire the class to have it's state broadcast via useReducer(), so it can trigger re-renders
  const state = useClassWithReducer(fvc);

  // Update the claim if it changes...
  useEffect(() => {
    // TODO it might be better to have the class take individual setters and
    //  only become 'ready' when all are set, but there's still some async
    //  handling to consider, e.g. to ensure we're not ready when, say, 1 of 3 has
    //  been updated... (r/n the hook is invoked in the form vm so the scope should
    //  prevent this).
    if (config.batchClaim && config.claim && currentOperationRef.current) {
      fvc.init({
        claim: config.claim,
        batchClaim: config.batchClaim,
        operation: currentOperationRef.current,
        paymentRateUtility,
        authorId,
      });
    }
  }, [config, currentOperationRef, authorId, paymentRateUtility]);

  // return the class and the state
  //   -- class handles form/vm output and update logic/methods
  //   -- state provides form/vm input and is part of react rendering lifecycle
  return { controller: fvc, state };
};
