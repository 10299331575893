import { SWClient } from '../../sw/swIpc/client';
import { getSwClientObservable } from '../../sw/serviceWorkerClient';
import { IPdfViewer, ViewerLicenseFoxit } from '../types';
import _log, { Logger } from 'loglevel';
import { ViewerBase } from '../viewerBase';
import { isFunction, isNumber } from 'lodash';
import { filter, take } from 'rxjs';
const log: Logger & {
  css?: string;
} = _log.getLogger('pdfViewer');
// log.setLevel(log.levels.DEBUG, false);
log.css = 'color: purple';
const logEvts: Logger & {
  css?: string;
} = _log.getLogger('pdfViewerEvents');
const logProg: Logger & {
  css?: string;
} = _log.getLogger('pdfViewerProgress');

// todo: to get ts types, symlink the node_modules foxit folder into public/foxit, you can use
//  `pnpm foxit-lib-public-symlink`
// todo: we should probably use the declare for builds tho or confirm we don't rebundle it...

// use this in release builds
declare var PDFViewCtrl: any;
declare var preloadJrWorker: Function;

/**
 * PDFDocPermissions - values for viewerOptions.customs.getDocPermissions() below
 * *** Used to disable print button ***
 * Documentation is sparse and unhelpful, but some info is here, find 'user_permissions' in these pages:
 * https://developers.foxit.com/resources/pdf-sdk-web/api_reference/group___consts_p_d_f.html#gab3d2fa3aa2db6744586420940ba7ed77
 * https://developers.foxit.com/resources/pdf-sdk-web/api_reference/group___consts_p_d_f.html#gadef5453006a35ebba2a89e3f3bf5bb3d
 * There's also info on customizing the viewer here, sounds like we could potentially remove inappropriate buttons, etc:
 * https://webviewer-demo.foxitsoftware.com/docs/developer-guide/ui-extension/customization/customize-the-ui.html#customize-the-ui-layout-using-template
 * Note, PDFDocPermissions definitely exists in the JS lib but I couldn't find an exported instance; wbni we can find an export and use directly
 */
const PDFDocPermission: {
  AnnotForm: number;
  FillForm: number;
  ExtractAccess: number;
  Extract: number;
  Assemble: number;
  PrintHighQuality: number;
  PrintLowQuality: number;
  ModifyDocument: number;
} = {
  PrintLowQuality: 4,
  ModifyDocument: 8,
  Extract: 16,
  AnnotForm: 32,
  FillForm: 256,
  ExtractAccess: 512,
  Assemble: 1024,
  PrintHighQuality: 2048,
};

const foxitPath = `${window.location.protocol}//${window.location.host}/public/foxit`;
const libPath = `${foxitPath}/lib`;
let readyWorker: any = null;

const Events = PDFViewCtrl.ViewerEvents;
type PDFUI = UIExtension.PDFUI;
type PDFViewer = UIExtension.PDFViewer;
type PDFDoc = UIExtension.PDFDoc;

class FoxitPdfViewer extends ViewerBase<PDFUI> implements IPdfViewer<PDFUI> {
  private documentViewer: PDFUI;
  private documentId: string | null = null;
  private filename: string | null = null;
  private currentPageNumber: number = 0;
  private swClient: SWClient | undefined = undefined;

  constructor(instance: PDFUI) {
    super(instance);
    this.documentViewer = instance;
    this.wireEvents();
    getSwClientObservable()
      .pipe(
        filter(sw => !!sw),
        take(1)
      )
      .subscribe({
        next: swc => {
          log.trace('%c got swc', log.css, swc);
          this.swClient = swc;
        },
      });
  }

  private checkDv(caller: string) {
    if (!this.documentViewer) {
      log.error(`${caller}: no documentViewer`);
      return false;
    }
    return true;
  }

  private wireEvents() {
    if (!this.checkDv('wireEvents')) {
      return;
    }

    const listenTo = (
      event: string,
      handler: ((...args: any[]) => void) | null = null
    ) =>
      this.documentViewer.addViewerEventListener(event, (...args: any[]) => {
        const e = args[0] ?? {};
        const idx = e?.index ?? e.page?.index ?? null;
        if (handler && isFunction(handler)) {
          return handler(...args);
        } else {
          logEvts.debug(
            '%c listener %s: ' + event,
            'color: gray; background-color: lightyellow',
            idx ? ` [${idx}]` : '',
            { 'ⓘ': { ...args } }
          );
          return event;
        }
      });

    logEvts.debug('%c Wiring events', log.css);

    listenTo(Events.openFileFailed, (err, ...args) => {
      if (err && err.error === 403) {
        log.error('Document Viewer: Error opening file', err);
      }
      return false;
    });

    // listenTo(Events.renderPageSuccess);
    // listenTo(Events.pageLayoutRedraw);
    // listenTo('completed-render-visible-pages');
    listenTo(Events.renderFileFailed);

    listenTo(Events.openFileSuccess, (e: any) => {
      if (this.checkDv('documentLoaded')) {
        //n.b. this doesn't indicate that anything is visible to the user, just that it could access the file...
        logEvts.debug('%c Document Viewer: open-file-success', log.css, {
          dets: {
            docId: this.getCurrentDocumentId(),
            docName: this.getCurrentDocumentName(),
            page: this.getCurrentPageNumber(),
          },
        });
        this.documentIdSubject.next(this.getCurrentDocumentId());
        this.documentNameSubject.next(this.getCurrentDocumentName());
        this.pageNumberSubject.next(this.getCurrentPageNumber());
      }
    });

    listenTo(Events.pageNumberChange, (e: any) => {
      if (e && isNumber(e)) {
        this.pageNumberSubject.next(e);
      }
    });
  }

  async loadDocument({
    url,
    filename,
    documentId,
    expiresMs,
    fileSize,
    page = 0,
    sw,
  }: {
    url: string;
    filename: string;
    documentId: string;
    expiresMs: number;
    fileSize: number;
    page?: number;
    sw?: {
      clientId: string;
      instanceId: string;
    };
  }) {
    const p = new Promise<{ documentId: string }>((resolve, reject) => {
      try {
        log.debug('%c Load Document request', log.css, {
          args: {
            url,
            filename,
            documentId,
            expiresMs,
            fileSize,
            page,
          },
        });

        // new url -- if sw client
        const parsedUrl = new URL(url);
        const newUrl = [
          parsedUrl.protocol,
          '//',
          parsedUrl.host,
          parsedUrl.pathname,
        ].join('');

        return this.documentViewer
          .getPDFViewer()
          .then(async (pdfViewer: PDFViewer) => {
            if (documentId === this.documentId) {
              log.debug('%c requested doc is same as current doc ', log.css, {
                dets: {
                  documentId,
                  current: this.documentId,
                },
              });
              this.gotoPage(page);
              return resolve({ documentId });
            } else {
              log.debug('%c pdfv Calling close ', log.css);
              if (this.loadingSubject.value === true) {
                fetch('/ala-sw/___signalAlaRewrite')
                  .then(res => {
                    if (!res.ok) {
                      log.warn('Cancel signal rejected', res);
                    }
                  })
                  .catch(e => {
                    log.warn('Cancel signal error:', e);
                  });
              }
              await this.closeDocument()
                .then((...cbArgs: any[]) => {
                  log.debug('%c dv Close Done ', log.css, { cbArgs });
                })
                .catch((e: any) => {
                  log.error('closeError - dv:', e);
                });

              this.documentId = documentId;
              this.filename = filename;
              const fbytes = fileSize ?? 0;

              log.debug('%c load new doc', log.css, {
                dets: {
                  fileSize,
                  fbytes,
                  filename,
                  documentId,
                },
              });

              const isRewriteEanbled =
                await this.swClient?.isSignedUrlRewriteEnabled();
              const sw = isRewriteEanbled ? this.swClient?.getTxIds() : null;

              const chunkSize = 1048576 * 1.5; // 1MB * 1.5

              // // this is apparently just for client side rendering of things like edited pages/annotations...
              // this.documentViewer.registerProgressHandler( ...

              // todo we might be able to get progress with something like this, or use sw...
              // const chunks = Math.ceil(fbytes / chunkSize);
              // let currentChunk = 0;
              // const showProg = () => {
              //   currentChunk++;
              //   const percent = Math.floor((currentChunk / chunks) * 100);
              //   console.log(`---> ${percent}%`);
              //   return percent;
              // };
              // // in request -> prop get headers
              // get headers(): Record<string, any> {
              //   console.log('createHeaders');
              //   return {
              //     ...baseHeaders,
              //     'ala-percent': showProg(),
              //   };
              // },

              this.loadingSubject.next(true);
              this.documentViewer
                .openPDFByHttpRangeRequest(
                  {
                    range: {
                      // rewrite?
                      url: sw?.clientId ? newUrl : url,
                      headers: {
                        'ala-url-documentId': documentId,
                        ...(sw?.clientId
                          ? {
                              'ala-url-rewrite': url,
                              'ala-sw-instanceId': sw.instanceId,
                              'ala-sw-clientId': sw.clientId,
                              'ala-url-expires': expiresMs,
                            }
                          : {}),
                      },
                      chunkSize,
                    },

                    // DOC - this must be an int - postgraphile will return string if it's bigint so parse:
                    ...(fbytes > 0
                      ? {
                          size:
                            typeof fbytes === 'number'
                              ? fbytes
                              : parseInt(fbytes),
                        }
                      : {}),
                  },
                  {
                    fileName: filename,
                  }
                )
                .catch((e: any) => {
                  this.loadingSubject.next(false);
                  log.error('PDF Viewer - failed to load file - rejection:', e);
                  return reject(e);
                })
                .then((pdfDoc: PDFDoc) => {
                  log.debug('%c Document Loaded', log.css);
                  this.loadingSubject.next(false);
                  this.gotoPage(page);
                  return resolve({ documentId });
                });
            }
            pdfViewer.setEnableJS(false);
          })
          .catch((err: any) => log.error('Error loading file', err));
      } catch (e) {
        log.error(`Error loading file (try/catch): ${filename}`, e);
        reject(e);
      }
    });
    return p;
  }

  closeDocument() {
    if (!this.checkDv('closeDocument')) {
      return;
    }
    if (!this.documentViewer.pdfViewer?.currentPDFDoc) {
      log.debug('%c No current doc to close', log.css);
      return Promise.resolve();
    }
    this.documentId = null;
    this.filename = null;
    return this.documentViewer
      .close(
        (...args: any[]) =>
          log.trace('%c before close called', log.css, { args }),
        (...args: any[]) =>
          log.trace('%c after close called', log.css, { args })
      )
      .catch((e: any) => log.error('error closing document:', e));
  }

  gotoPage(pageNumber: number) {
    if (!this.checkDv('gotoPage')) {
      return;
    }
    log.trace('%c goto page', log.css, pageNumber);
    if (pageNumber > 0) {
      this.documentViewer
        .getPDFViewer()
        .then((pdfViewer: PDFViewer) =>
          pdfViewer.getPDFDocRender()?.goToPage(pageNumber - 1)
        );
    }
  }

  getCurrentDocumentName() {
    if (!this.checkDv('getCurrentDocumentName')) {
      return null;
    }
    // TODO return this.documentViewer.getDocument()?.getFilename() ?? null;
    return this.filename;
  }

  getCurrentDocumentId() {
    if (!this.checkDv('getCurrentDocumentId')) {
      return null;
    }
    // todo return this.documentViewer.getDocument()?.getDocumentId() ?? null;
    this.documentViewer.getPDFViewer().then((pdfViewer: PDFViewer) => {
      const currentPDFDoc = pdfViewer?.getCurrentPDFDoc();

      // TODO map result of getid() to filename/docid on load so that we
      //  can answer assuredly...

      const alaDocId = this.documentId;
      const foxitDocId = currentPDFDoc?.getId;

      currentPDFDoc?.isLinearized().then((isLinear: boolean) => {
        log.trace(
          '%c getCurrentDocId - linearized? %s',
          log.css + '; background-color: lightyellow',
          isLinear,
          {
            dets: {
              alaDocId,
              foxitDocId,
              currentPDFDoc,
            },
          }
        );
      });

      return currentPDFDoc;
    });
    return this.documentId;
  }

  getCurrentPageNumber() {
    if (!this.checkDv('getCurrentPageNumber')) {
      return 0;
    }
    // todo return this.documentViewer.getCurrentPage() ?? null;
    return this.currentPageNumber;
  }
}

// viewer class / singleton
let foxitPdfViewer: any | null = null;

export const createFoxitViewer = async (
  viewerEl: HTMLElement,
  viewerLicense: ViewerLicenseFoxit | null
) => {
  if (foxitPdfViewer) {
    return foxitPdfViewer;
  }
  const { sn: licenseSN, key: licenseKey } = viewerLicense || {};
  if (!licenseSN || !licenseKey) {
    log.error('%cMissing viewer license or key: ', log.css, {
      licenseSN,
      licenseKey,
      viewerLicense,
    });
  }
  if (viewerEl) {
    if (!readyWorker) {
      log.trace('%cLoading pdf worker', log.css);
      readyWorker = preloadJrWorker({
        workerPath: libPath + '/',
        enginePath: libPath + '/jr-engine/gsdk',
        fontPath: foxitPath + '/external/brotli',
        // fontPath: 'https://webpdf.foxitsoftware.com/webfonts',
        fontInfoPath: foxitPath + '/external/brotli/fontInfo.csv',
        licenseSN,
        licenseKey,
      });
    }

    const pdfui = new UIExtension.PDFUI({
      viewerOptions: {
        libPath,
        jr: {
          readyWorker: readyWorker,
        },

        customs: {
          // READONLY -- per best practice docs makes it render faster...:
          // getDocPermissions: function () {
          //   return 0;
          // },
          //
          // disable document downloading
          getAdditionalPerm: () => {
            return 0;
          },
          getDocPermissions: () => {
            // disable print via doc permissions -- see note at PDFDocPermissions declaration above
            // we can return 0, but it disables all toolbars (including, e.g., zoom) despite that
            // the flags for this function don't seem to indicate what happens with bits < 0b0100
            // specifying FillForm seems to leave most normal use toolbars avail while disabling print
            return PDFDocPermission.FillForm;
          },
        },
      },

      i18n: {
        absolutePath: libPath + '/locales',
      },
      renderTo: viewerEl,
      appearance: UIExtension.appearances.adaptive,
      // fragments: [],
      addons: libPath + '/uix-addons/allInOne.js',
    });

    return new FoxitPdfViewer(pdfui);
  }
};
