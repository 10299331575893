import React, { useContext, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { DesignProvider } from '../../../components/app/design/designProvider';
import { CSSInterpolation } from '@emotion/css';

export type TableStyleProviderProps = {
  width: number;
  height: number;
  style?: CSSInterpolation[] | CSSInterpolation;
  children: React.ReactElement;
};

const tableStyleProvider: React.FC<TableStyleProviderProps> = ({
  width,
  height,
  style,
  children,
}) => {
  const {
    options: { darkMode },
  } = useContext(DesignProvider);

  const cls = useMemo(() => {
    return css(style);
  }, [darkMode, style]);

  return (
    <div
      className={`ag-theme-alpine${darkMode ? '-dark' : ''}`}
      css={cls}
      style={{ width, height }}
    >
      {children}
    </div>
  );
};

export const TableStyleProvider = React.memo(tableStyleProvider);
