import { useClaim } from '../../queries/claim/useClaim';
import {
  AuditFinding,
  AutodorFinding,
  BatchClaim,
  Claim,
  Finding,
  ItemizedBillLine,
} from '../../../../gql/graphql';
import AutoSizer from 'react-virtualized-auto-sizer';
import tw, { css } from 'twin.macro';
import { IbTable, IbTableFunctions } from './ibTable/ibTable';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHashParams } from '../../../../common/navigation/useHashParam';
import { useWaitForRef } from '../../../../common/util/hooks/useWaitForRef';
import { fromSlugOrId } from '../../../../common/navigation/utils';
import { FindingContextProvider } from '../findingEdit/findingProvider';
import { Alert, Collapse } from 'antd';
import { omit } from 'lodash';
import { getRuleLabelForRuleType } from '../../shared/components/finding/autodorFindingUtils';
import { AutodorDynamicDetails } from '../../shared/components/finding/autodorDynamicDetails';
import { ObjectViewer } from '../../../../common/util/devUtil/objectViewer';
import { GraphQLError } from 'graphql/error';

type IbTabProps = {
  setCreatingAuditFinding: any;
  setDecliningAuditFinding: any;
  navigateToIbinRow: ({
    rowId,
    findingId,
  }: {
    rowId: string;
    findingId: string;
  }) => void;
  readOnly: boolean;
  variables: {
    batchClaimId: string;
    batchClaim: BatchClaim;
    auditFindings: AuditFinding[];
  };
  TableComponent: React.FC<any>;
  canCreateFindings: boolean;
  tabToolbarRef: any;
  showRow?: { rowId: string; findingId?: string };
};

export const IbTab = ({
  setCreatingAuditFinding,
  setDecliningAuditFinding,
  navigateToIbinRow,
  readOnly,
  variables: { batchClaimId, batchClaim, auditFindings },
  canCreateFindings,
  tabToolbarRef,
  showRow,
}: IbTabProps) => {
  const [{ fetching, data, error }, refreshQuery] = useClaim(batchClaimId);

  const claim = useMemo(() => data?.claim, [data]);

  const tableRef = useRef<IbTableFunctions>(null);

  const [hashParams] = useHashParams();

  const isTableRefLoaded = useWaitForRef(tableRef);

  // if we're loading tab, update the table based on hash params
  // n.b. this could be done in the table itself, but the original
  // design exposed an api on the table which is likley to be a useful pattern going forward
  useEffect(
    () => {
      if (isTableRefLoaded && (hashParams.rowId || hashParams.findingId)) {
        if (tableRef.current!.isGridReady()) {
          tableRef.current!.scrollToRowId(
            fromSlugOrId(hashParams.rowId),
            fromSlugOrId(hashParams.findingId)
          );
        } else {
          const unsub = tableRef?.current?.onReady(() => {
            tableRef.current!.scrollToRowId(
              fromSlugOrId(hashParams.rowId),
              fromSlugOrId(hashParams.findingId)
            );
          });
          return unsub;
        }
      }
    },
    // only on table ready -- don't update on hash params since that will interfere with user scrolling
    // e.g. timing of hash changes can cause this to reissue scroll command and cause the row to jump:
    [isTableRefLoaded]
  );

  if (error) {
    console.error('Error loading claim', error);
  }

  return error && !data ? (
    <div tw="_m-8">
      <Alert
        message="Error retrieving claim data"
        showIcon
        description={
          <div tw="_m-4">
            <div tw="_p-2">
              An unexpected error occurred loading this claim. Please contact
              support at
              <a
                tw="_p-2"
                href="mailto:support@alaffiahealth.com"
                target="_top"
              >
                support@alaffiahealth.com
              </a>
            </div>
            <div tw="_p-2">Message: {error?.message}</div>
          </div>
        }
        type="error"
      />
    </div>
  ) : (
    <FindingContextProvider
      claimId={claim?.id}
      claim={claim! as Claim}
      batchClaim={batchClaim}
    >
      {error && (
        <Alert
          message="Parts of this claim failed to load"
          showIcon
          closable={true}
          description={
            <>
              <div>
                Reloading the browser after a few moments may resolve the issue.
                If this error persists, please contact support at:
                <a
                  tw="_p-2"
                  href="mailto:support@alaffiahealth.com"
                  target="_top"
                >
                  support@alaffiahealth.com
                </a>
              </div>
              <div tw="_p-2">
                Message: {error?.message?.replaceAll('[GraphQL]', '')}
              </div>
            </>
          }
          type="error"
        />
      )}
      <div tw="_w-full _h-full _min-h-full _max-h-full">
        <AutoSizer defaultHeight={900} defaultWidth={1200}>
          {({
            height = 900,
            width = 1200,
          }: {
            height: number;
            width: number;
          }) => {
            const h = height || 900;
            const w = width || 1200;
            return (
              <div
                style={{
                  width: w,
                  height: h,
                  minHeight: h,
                }}
              >
                <IbTable
                  width={w - 8}
                  height={h - 8}
                  loading={fetching}
                  claim={claim! as Claim}
                  setCreatingAuditFinding={setCreatingAuditFinding}
                  setDecliningAuditFinding={setDecliningAuditFinding}
                  navigateToIbinRow={navigateToIbinRow}
                  readOnly={readOnly}
                  canCreateFindings={canCreateFindings}
                  tabToolbarRef={tabToolbarRef}
                  ref={tableRef}
                ></IbTable>
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </FindingContextProvider>
  );
};
