// make root path accessible
/* eslint-disable */
import { MockProvider } from './common/util/devMocks/mockProvider';

// @ts-ignore
global.__src = __dirname;

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Initialize Datadog
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../package.json';

!process.env.DEV_NO_DD &&
  datadogRum.init({
    applicationId: '6ee63b18-40d8-4679-b08a-1fbe4e4ba31c',
    clientToken: 'pub5180b6df804df179bc52d4462fbc4d27',
    site: 'datadoghq.com',
    service: 'alaffia-app',
    env: process.env.REACT_APP_DD_ENV || 'local',
    version,
    sampleRate: 100,
    trackInteractions: true,
  });

import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './components/app/app';
import { LogProvider } from './common/log/logProvider';
// N.B. importing SwClientInstance here to ensure it's loaded even if the
// <SwClientProvider> isn't included... the lib registers the provider and
//  the return value is an observable to get the value so there's no harm in
//  not using it here
// import SwClientInstance from './sw/serviceWorkerClient';
import { SwClientProvider } from './sw/swProvider';
import { AlaffiaContextProvider } from './alaffiaClient/alaffiaClientProvider';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <LogProvider>
    <SwClientProvider>
      <MockProvider>
        <BrowserRouter>
          <AlaffiaContextProvider>
            <App />
          </AlaffiaContextProvider>
        </BrowserRouter>
      </MockProvider>
    </SwClientProvider>
  </LogProvider>
);
