// external
import React, { useContext } from 'react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import tw, { css } from 'twin.macro';

import { ClaimWorkspaceAuditFinding } from '../audit/claimWorkspace/auditFinding/claimWorkspaceAuditFinding';
import { auditFindingWithReviewFragment } from '../../fragments';

// component library
import { Spin, Table, Collapse, Tag } from 'antd';

import { UserContext } from '../context/user';
import { ColTitle } from '../queues/util';
import { VersionReportButton } from './versionReportButton';
import { DownloadReportButton } from './downloadReportButton';

const clsReportSubtype = css({
  color: 'darkgray',
  fontSize: '.85em',

  '& .ant-tag': {
    minWidth: '44px',
    height: '1.8em',
    lineHeight: '1.8em',
    fontSize: '.9em',
    margin: '1px 2px',
  },
});

const clsReportType = css({
  color: 'darkgray',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-tag': { minWidth: '44px', margin: '2px' },
});

const ReportsVersionList = ({ batchClaimId, isLatestVersion }) => {
  const { reports, workstation } = useContext(UserContext);
  const [{ fetching, data, error }, refreshQuery] = useQuery({
    query: gql`
      query ReportsVersionListQuery($batchClaimId: UUID!) {
        batchClaim(id: $batchClaimId) {
          id
          ${
            isLatestVersion
              ? `reportVersions(filter:{isLatestVersion:{equalTo:true}}) {`
              : `reportVersions {`
          } 
            nodes {
              id
              title
              auditFindingReportType
              auditFindingReportSubtype
              isLatestVersion
              providerId
              insurerId
              primaryPia {
                id
                firstName
              }
              createdAt
              reportedFindings(
					      filter: { auditFinding: { batchClaimId: { equalTo: $batchClaimId } } }
				        orderBy: CREATED_AT_DESC
				      ) {
                nodes {
                  id
                  auditFinding {
                    ...auditFindingWithReviewFragment
                  }
                }
              }
            }
          }
        }
      }
      ${auditFindingWithReviewFragment}
    `,
    variables: {
      batchClaimId,
    },
  });

  const columns = [
    {
      title: 'Report Title',
      key: 'title',
      dataIndex: 'title',
      render: title => {
        return <div> {title}</div>;
      },
    },
    {
      title: 'Creation Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => {
        return <div>{moment(createdAt).utc().format('MM/DD/YYYY')}</div>;
      },
    },
    {
      title: 'Version',
      key: 'reportVersionNum',
      render: data => {
        return (
          <div>
            {data.reportVersionsCount > 1 && (
              <Tag> V{data.reportVersionNum}</Tag>
            )}
            {(data.isLatestVersion || data.reportVersionsCount === 1) && (
              <Tag>Latest</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Type of Report"
          title="Report Type"
          id="report-type"
        />
      ),
      default: true,
      key: 'auditFindingReportType',
      dataIndex: 'auditFindingReportType',
      render: (_, record) => {
        const { auditFindingReportType, auditFindingReportSubtype } = record;
        // todo extract tag colors -- perhaps both these columns for use in each place
        return (
          <div css={clsReportType}>
            <div>
              <Tag
                color={
                  auditFindingReportType === 'EOA'
                    ? 'blue'
                    : auditFindingReportType === 'IFR'
                      ? 'purple'
                      : 'yellow'
                }
              >
                {auditFindingReportType}
              </Tag>
            </div>
            <div css={clsReportSubtype}>
              {auditFindingReportType !== 'EOA' &&
                auditFindingReportSubtype !== 'PROCESSED' && (
                  <Tag color={'lightgray'}>
                    {auditFindingReportType === 'EOA' ||
                    auditFindingReportSubtype === 'PROCESSED'
                      ? ''
                      : auditFindingReportSubtype?.charAt(0).toUpperCase() +
                        auditFindingReportSubtype?.substring(1).toLowerCase()}
                  </Tag>
                )}
            </div>
          </div>
        );
      },
    },
    ...(!workstation.canVersionReports
      ? []
      : [
          {
            title: (
              <ColTitle title="Actions" id="reports-list-col-version-report" />
            ),
            default: true,
            key: 'reportActions',
            render: auditFindingReport => (
              <div tw="_flex _flex-row _space-x-2">
                {auditFindingReport.isLatestVersion && (
                  <VersionReportButton
                    auditFindingReport={auditFindingReport}
                    refreshFunction={() =>
                      refreshQuery({ requestPolicy: 'network-only' })
                    }
                  />
                )}
                <DownloadReportButton
                  reportId={auditFindingReport.id}
                ></DownloadReportButton>
              </div>
            ),
          },
        ]),
  ];

  // TODO this looks like "dead code" - it's used, but it looks like we've since
  //  restricted the query to 'latest===true' -- needs evaluation and removal if appropriate
  const filterReportVersions = data => {
    let latestFinalReport = -1;
    const reportVersions = [];
    data.batchClaim.reportVersions.nodes.forEach((node, i) => {
      if (
        reports.versionHistory === 'latest' &&
        node.auditFindingReportType === 'FINAL'
      ) {
        latestFinalReport = i; // we intend to find latest report version that is FINAL
      } else if (reports.versionHistory === 'full') {
        reportVersions.push(node); // when we want the full history we just select all
      }
    });

    // outside of loop
    if (reports.versionHistory === 'latest' && latestFinalReport > -1) {
      // gives us the latest FINAL report
      reportVersions.push(
        data.batchClaim.reportVersions.nodes[latestFinalReport]
      );
    } else if (
      reports.versionHistory === 'latest' &&
      data.batchClaim.reportVersions.nodes.length > 0
    ) {
      // when NO final reports exist we just take the latest one
      reportVersions.push(data.batchClaim.reportVersions.nodes[0]);
    }
    return reportVersions;
  };

  const reportVersions = !fetching ? filterReportVersions(data) : [];

  return (
    <div>
      {fetching ? (
        <Spin />
      ) : (
        <Table
          title={() => <h1>Reports</h1>}
          dataSource={reportVersions.map((node, i) => {
            node.reportVersionsCount = reportVersions.length;
            node.reportVersionNum =
              data.batchClaim.reportVersions.nodes.length - i;
            node.key = `reported-findings-table-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
            return node;
          })}
          loading={fetching}
          columns={columns}
          expandable={{
            columnWidth: 20,
            expandedRowRender: ({ reportedFindings }) => (
              <Collapse
                css={css`
                  font-size: 12px;
                `}
                id={`reported-findings-collapse-${reportedFindings.id}`}
                key={`reported-findings-collapse-${reportedFindings.id}`}
              >
                {reportedFindings.nodes.map(
                  ({ id: reportedFindingsId, auditFinding }, i) => {
                    return (
                      <ClaimWorkspaceAuditFinding
                        {...{
                          key: `collapse-panel-reported-findings-${reportedFindingsId}-audit-finding-${auditFinding.id}}-${i}`,
                          id: `collapse-panel-reported-findings-${reportedFindingsId}-audit-finding-${auditFinding.id}}-${i}`,
                          auditFindingId: auditFinding.id,
                          readOnly: true,
                        }}
                        hideReviewStatus={true}
                      />
                    );
                  }
                )}
              </Collapse>
            ),
            rowExpandable: ({ reportedFindings }) =>
              reportedFindings.nodes.length > 0,
          }}
        />
      )}
    </div>
  );
};

export { ReportsVersionList };
