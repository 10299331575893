import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { LoginModal } from './loginModal';
import {
  LoginChallengeResult,
  LoginFailResult,
  LoginResult,
} from '../../../alaffia-client-sdk';
import { Header } from '../components/app/header';
import { useLog } from '../common/log/useLog';
import { useAlaffiaClient } from './useAlaffiaClient';
import { useLoginStatus } from './useLoginStatus';
import { message } from 'antd';
import { ChallengeArgs, isChangePwdArgs, isMfaCodeArgs } from './types';
import { AuthUserForceChangePassword } from '@alaffia-technology-solutions/alaffia-client-sdk';
import { ErrorBoundary } from '../common/error/errorBoundary';

const errMsgForErrResp = (resp: LoginFailResult) => {
  return (
    resp?.responseBody?.error_description ??
    resp?.responseBody?.error?.message ??
    'Login failed - verify credentials and try again'
  );
};

export const AlaffiaLogin: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const log = useLog('AlaffiaLogin');
  // log.setLevel('debug', false);

  const alaffiaClient = useAlaffiaClient();
  const { loggedIn, hasLoggedIn, userId, userRole } = useLoginStatus();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastError, setLastError] = useState<undefined | string>(undefined);
  const [pendingChallenge, setPendingChallenge] = useState<null | LoginResult>(
    null
  );
  const [pendingTotpConfig, setPendingTotpConfig] =
    useState<null | LoginChallengeResult>(null);

  useEffect(() => {
    if (!isOpen && loggedIn !== null && !loggedIn && !hasLoggedIn) {
      setIsOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    log.trace('loginStatus effect, loggedIn:', {
      loggedIn,
      hasLoggedIn,
      userId,
    });
    if (loggedIn === null) {
      // do nothing - we haven't loaded the client yet to determine if a login can be restored...
    } else if (loggedIn || hasLoggedIn) {
      log.debug('logged in TRUE:', loggedIn);
      setPendingChallenge(null);
      setLastError(undefined);
      setIsOpen(false);
    } else {
      log.debug('logged in FALSE:', loggedIn);
      setPendingChallenge(null);
      setLastError(undefined);
      setIsOpen(true);
    }
    return () => {
      log.debug('loginStatus EFFECT cleanup', {
        loggedIn,
        hasLoggedIn,
        userId,
      });
    };
  }, [loggedIn, hasLoggedIn, userId]);

  const login = useCallback(
    async ({ user, pass }: { user: string; pass: string }) => {
      setIsOpen(false);
      alaffiaClient
        .login(user, pass)
        .then((r: LoginResult) => {
          if (r.predicates.isLoginSuccess(r)) {
            log.trace('login success', r);
            setLastError(undefined);
            setPendingChallenge(null);
            setIsOpen(false);
          } else if (r.predicates.isLoginFail(r)) {
            log.trace('login failed at user/pass', r);
            setLastError(errMsgForErrResp(r));
            setIsOpen(true);
          } else {
            setLastError(undefined);
            setPendingChallenge(r);
            setIsOpen(true);
          }
        })
        .catch((e: any) => {
          log.error('Login error', e);
          message.error(
            'Login failed - error communicating with server \n' + e.message ??
              '(unknown)'
          );
          setIsOpen(true);
        });
    },
    [alaffiaClient]
  );

  const challenge = useCallback(
    async (args: ChallengeArgs) => {
      setIsOpen(false);
      if (!pendingChallenge) {
        throw new Error('No pending login challenge!');
      } else {
        const fn = pendingChallenge?.predicates.isLoginChallenge(
          pendingChallenge
        )
          ? async () => {
              if (isMfaCodeArgs(args)) {
                return alaffiaClient.loginChallenge(
                  pendingChallenge!,
                  args.code
                );
              } else {
                throw new Error('MFA code is required');
              }
            }
          : pendingChallenge?.predicates?.isConfigureMfa(pendingChallenge)
            ? async () => {
                if (isMfaCodeArgs(args)) {
                  return alaffiaClient.configureMfa(
                    pendingChallenge!,
                    args.code
                  );
                } else {
                  throw new Error('MFA code is required');
                }
              }
            : pendingChallenge?.predicates?.isForceChangePassword(
                  pendingChallenge
                )
              ? async () => {
                  if (isChangePwdArgs(args)) {
                    return alaffiaClient.forceChangePassword(
                      pendingChallenge,
                      args.oldPassword,
                      args.newPassword
                    );
                  } else {
                    throw new Error(
                      `All fields are required to change password`
                    );
                  }
                }
              : async () => {
                  return Promise.resolve().then(() => {
                    throw new Error('Unknown pending challenge type');
                  });
                };

        fn()
          .then((r: LoginResult) => {
            if (r.predicates.isLoginSuccess(r)) {
              log.trace('login success', r);
              setLastError(undefined);
              setPendingChallenge(null);
              setIsOpen(false);
            } else if (r.predicates.isLoginFail(r)) {
              log.error('login failed at user/pass', r);
              setLastError(errMsgForErrResp(r));
              setIsOpen(true);
            } else {
              setLastError(undefined);
              setPendingChallenge(r);
              setIsOpen(true);
            }
          })
          .catch((e: any) => {
            log.error('login error', e);
            message.error(
              'Login failed - error communicating with server \n' + e.message ??
                '(unknown)'
            );
            setIsOpen(true);
          });
      }
    },
    [alaffiaClient, pendingChallenge]
  );

  return loggedIn === null ? (
    <div tw="_w-full _h-full _max-h-full _max-w-full _overflow-y-auto _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer"></div>
  ) : !loggedIn && !hasLoggedIn ? (
    <div tw="_fixed _inset-0">
      <Header />
      <ErrorBoundary boundaryDescription="AlaffiaLogin [never logged in]">
        <LoginModal
          loginResult={pendingChallenge}
          login={login}
          isOpen={isOpen}
          setIsOpen={(b: boolean) => setIsOpen(b)}
          challenge={challenge}
          lastError={lastError}
        ></LoginModal>
      </ErrorBoundary>
    </div>
  ) : (
    <div tw="_w-full _h-full _max-h-full _max-w-full _overflow-y-auto _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer">
      {children}

      <ErrorBoundary boundaryDescription="AlaffiaLogin [not logged in]">
        <LoginModal
          loginResult={pendingChallenge}
          login={login}
          isOpen={isOpen}
          setIsOpen={(b: boolean) => setIsOpen(b)}
          challenge={challenge}
          lastError={lastError}
        ></LoginModal>
      </ErrorBoundary>
    </div>
  );
};
