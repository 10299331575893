import React, { useEffect, useState } from 'react';
import { Form, Select, Space, Tooltip } from 'antd';

import { useQuery } from 'urql';
import { piaListQuery } from '../../graphql/queries';

const CreateReportForm = props => {
  const { form, reportTypes } = props;

  const [{ fetching, data: piaListData }, ,] = useQuery({
    query: piaListQuery,
  });
  const piaList =
    !fetching &&
    piaListData?.auditorUsers.nodes.map(({ firstName, lastName, id }) => ({
      label: `${firstName} ${lastName}`,
      value: id,
    }));

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    labelAlign: 'left',
  };

  const reportType = Form.useWatch('reportType', form);
  const [reportSubtypeOptions, setReportSubtypeOptions] = useState([]);
  useEffect(() => {
    if (!reportType) {
      return;
    }
    const opts = !reportType
      ? []
      : Object.values(reportTypes[reportType]?.subtypes);

    if (opts.length === 1) {
      opts[0].label = 'Not applicable';
    }
    const currentSubtype = form.getFieldsValue('reportSubtype');
    if (!opts.find(o => o.value === currentSubtype)) {
      form.setFieldsValue({ reportSubtype: opts[0]?.value });
    }
    setReportSubtypeOptions(opts);
  }, [reportType]);

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', justifyContent: 'center' }}
    >
      <Form
        name="create_report_form"
        form={form}
        autoComplete="off"
        initialValues={{
          reportType: null,
          primaryPiaId: null,
        }}
      >
        <Form.Item
          name="reportType"
          label="Type"
          rules={[{ required: true, message: 'Select the type of report' }]}
          {...layout}
        >
          <Select
            showSearch
            placeholder={'Select report type'}
            optionFilterProp="label"
            // options={Object.keys(reportTypes).map(k => ({
            //   label: k,
            //   value: k,
            //   disabled: reportTypes[k].disabled,
            //   tooltip: reportTypes[k].disabled
            //     ? reportTypes[k].existingClaimsWithReportType.join(',')
            //     : undefined,
            // }))}
          >
            {Object.keys(reportTypes).map(k => (
              <Select.Option
                key={k}
                value={k}
                disabled={reportTypes[k].disabled}
              >
                {reportTypes[k].disabled ? (
                  <Tooltip
                    title={`Reported ${k}: ${reportTypes[
                      k
                    ].existingClaimsWithReportType.join(',')}`}
                  >
                    <>{k}</>
                  </Tooltip>
                ) : (
                  <>{k}</>
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="reportSubtype"
          label="Sub Type"
          rules={[
            {
              required: reportSubtypeOptions?.length > 1,
              message: 'Select the sub type of report',
            },
          ]}
          {...layout}
        >
          <Select
            showSearch
            placeholder={'Select report sub type'}
            optionFilterProp="label"
            options={reportSubtypeOptions}
            disabled={reportSubtypeOptions?.length < 2}
          />
        </Form.Item>

        <Form.Item
          label="Primary PIA"
          shouldUpdate={true}
          name="primaryPiaId"
          required={true}
          rules={[{ required: true, message: 'Must select a primary PIA' }]}
          {...layout}
        >
          <Select
            key="id"
            default={null}
            disabled={fetching}
            placeholder={'Select a Primary Auditor'}
            options={piaList}
          />
        </Form.Item>
      </Form>
    </Space>
  );
};

export { CreateReportForm };
