import { UserDeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Tag, Tooltip, Avatar } from 'antd';

const AssigneeAvatarGroup = ({ activeAssignees }) => (
  <Avatar.Group maxCount={3}>
    {activeAssignees.nodes.map(({ firstName, lastName, id }) => (
      <Tooltip
        title={`${firstName} ${lastName}`}
        key={`assignee-tooltip-${id}`}
      >
        <Avatar key={`assignee-avatar-${id}`}>
          {firstName[0]}
          {lastName[0]}
        </Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
);

const assignment = {
  render: ({ activeAssignees }) => {
    return activeAssignees.nodes.length > 0 ? (
      <AssigneeAvatarGroup activeAssignees={activeAssignees} />
    ) : (
      '-'
    );
  },
  dataType: 'auditorUser',
  onTableQuery: 'batchClaims',
  states: {
    USER_ASSIGNED: {
      name: 'Assign User',
      icon: <UserAddOutlined />,
      timelineEventRendering: {
        description: userType => 'User Assigned',
        renderingAllowed: event => true,
        customRendering: ({ metaData: assigneeName }) => (
          <span>
            <Tag>{assigneeName}</Tag>
            <strong>assigned</strong>
          </span>
        ),
      },
    },
    USER_UNASSIGNED: {
      name: 'Un-assign User',
      icon: <UserDeleteOutlined />,
      timelineEventRendering: {
        description: userType => 'User Un-assigned',
        renderingAllowed: event => true,
        customRendering: ({ metaData: assigneeName }) => (
          <span>
            <Tag>{assigneeName}</Tag>
            <strong>un-assigned</strong>
          </span>
        ),
      },
    },
  },
  filterBuilder: values => {
    if (values.includes('NO_ASSIGNEES')) {
      return {
        or: [
          { batchClaimAssigneesExist: false }, // DOC (MV): isAssigned is slow for unassigned
          { isAssigned: { equalTo: false } },
        ],
      };
      // } else if (values.includes('ASSIGNED')) {  // Improvement (MV): would be nice to search by any assignee, needs UI, not currently tested
      //   return {
      //     and: [
      //       { batchClaimAssigneesExist: true },
      //       { isAssigned: { equalTo: true } },
      //     ],
      //   };
    } else {
      return {
        and: [
          { batchClaimAssigneesExist: true },
          { isAssigned: { equalTo: true } },
          {
            batchClaimAssignees: {
              some: {
                auditorUser: {
                  firstName: {
                    in: values.map(fullName => fullName.split(' ')[0]),
                  },
                  lastName: {
                    in: values.map(fullName => fullName.split(' ')[1]),
                  },
                },
              },
            },
          },
        ],
      };
    }
  },
};

export { assignment };
