const DB_NAME = 'db_prefs';
const NAMESPACE = 'ns_prefs';
const KEYPATH = '__id';
const IDB_READONLY = 'readonly';
const IDB_READWRITE = 'readwrite';

/**
 * Simple wrapper functions for IndexedDB to persist values client side for user prefs, etc
 * Tested with string keys and string/number/array/object values
 * No database delete/maintenance/upgrade functionality to speak of
 * Database and namespace (schema) and keypaths are hardcoded for simplicity but could be changed to handle multiple
 * Anything more ambitious would suggest considering a robust package, e.g. localforage, dexie, indexeddb
 *
 * */

interface StoreInfo {
  db: IDBDatabase;
  tx: IDBTransaction;
  store: IDBObjectStore;
}

/**
 * Sets a value in IndexedDB browser-local storage
 * DO NOT STORE RESTRICTED DATA!
 * @param key - key to id and later subsequently retrieve value
 * @param value - <any> value to store;  specify undefined to delete the entry; supports null
 * @returns {Promise<string>} - the key provided is returned for convenience
 * @type {(key: string, value: any) => string}
 * */
export async function idbSetValue(
  key: string,
  value: any = undefined
): Promise<string> {
  if (value === undefined) {
    return idbDeleteValue(key);
  }
  if (!!value && value[KEYPATH] && value[KEYPATH] !== key) {
    // we wrap the value provided in an outer object with KEYPATH set to the KEY provided, so there shouldn't be a conflict,
    // but for sanity sake, reject any requests to set values with a KEYPATH property that does not match the KEY provided.
    return Promise.reject(
      `Value property '${KEYPATH}' must match provided key (${KEYPATH} is used as primary key)`
    );
  }
  return getStore(IDB_READWRITE)
    .then(({ store, tx }) => {
      store.put({ [KEYPATH]: key, value }); // .put() will 'upsert' (.add() will error on repeated same key)
      return tx;
    })
    .then(
      tx =>
        new Promise((resolve, reject) => {
          tx.onerror = reject;
          tx.oncomplete = evt => resolve(key);
        })
    );
}

/**
 * Retrieves a value from IndexedDB browser-local storage
 * @param key - key to retrieve a previously-stored value
 * @returns {Promise<any>} - the value, if any, stored; returns undefined if key does not exist; supports null
 * @type {(key: string, value: any) => string}
 * */
export async function idbGetValue(key: string): Promise<any> {
  return getStore(IDB_READONLY)
    .then(({ store }) => store.get(key))
    .then(idbRequest => {
      return new Promise((resolve, reject) => {
        idbRequest.onerror = reject;
        idbRequest.onsuccess = event => {
          const target = event.target as IDBRequest;
          resolve(target.result && target.result.value);
        };
      });
    });
}

/**
 * Attempts to unwrap errors from indexDB onError events, which are returned to the caller as-is for forensic purposes
 * @param event - typically the error returned from the promise rejection
 * @returns {Promise<any>} - the error nested within the event, if present, else returns parameter
 * @type {(key: string, value: any) => string}
 * */
export function idbTryUnwrapErrorEvent(event: any): any {
  return event && event.type === 'error' && event.target && event.target.error
    ? event.target.error
    : event;
}

// to keep surface area small, use idbSetValue(key, undefined)...
async function idbDeleteValue(key: string): Promise<string> {
  return getStore(IDB_READWRITE)
    .then(({ store }) => store.delete(key))
    .then(idbRequest => {
      return new Promise((resolve, reject) => {
        idbRequest.onerror = reject;
        idbRequest.onsuccess = event =>
          resolve((event.target as IDBRequest).result);
      });
    });
}

// n.b. https://github.com/microsoft/TypeScript/issues/28293  -- the current implementation returns super-class Event
// which requires casting to use in typescript, so cast the result to the appropriate idb type, e.g.:
// let database = (evt.target as IDBOpenDBRequest).result;

async function getStore(mode: IDBTransactionMode): Promise<StoreInfo> {
  return opendb()
    .then(db => ({ db, tx: db.transaction([NAMESPACE], mode) }))
    .then(obj => ({ ...obj, store: obj.tx.objectStore(NAMESPACE) }));
}

async function opendb(): Promise<IDBDatabase> {
  if (!window.indexedDB) {
    return Promise.reject(
      'Your browser does not support a current version of indexedDB'
    );
  }
  return new Promise((resolve, reject) => {
    const dbRequest = window.indexedDB.open(DB_NAME, 1);

    dbRequest.onupgradeneeded = (evt: IDBVersionChangeEvent) => {
      const db = (evt.target as IDBOpenDBRequest).result;
      if (db) {
        if (db && !db.objectStoreNames.contains(NAMESPACE)) {
          db.createObjectStore(NAMESPACE, { keyPath: KEYPATH });
        }
      }
    };

    dbRequest.onsuccess = (evt: Event) => {
      const db = (evt.target as IDBOpenDBRequest).result;
      if (db) {
        resolve(db);
      }
    };

    dbRequest.onerror = reject;
  });
}
