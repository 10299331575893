import { DownOutlined } from '@ant-design/icons';
import React from 'react';

import { Dropdown, Menu, Button } from 'antd';

import { UserContext } from '../context/user';

import * as actionTypes from './actions';
import { css } from '@emotion/react';

/**
 * A claim action menu rendered based on the permissions
 * of the logged in user
 * @param batchClaims <BatchClaim>[]
 * @param setIsActionLoading () -> Boolean
 * */
const ClaimActionMenu = ({ batchClaims, setIsActionLoading, isVerbose }) => {
  const { id: userId, permissions } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (setIsActionLoading) {
      setIsActionLoading(loading);
    }
  }, [loading]);

  // check that this is a permitted type of action type (e.g. workflow)
  const permittedActionTypes = Object.entries(actionTypes).filter(
    ([type, _]) => permissions.claimActions[type].length > 0
  );
  return (
    <Dropdown
      css={css`
        ${permittedActionTypes.length > 0
          ? 'display: inline-block;'
          : 'display: none;'}
      `}
      trigger={'click'}
      disabled={loading || (batchClaims && batchClaims.length == 0)}
      overlay={
        <Menu data-cy={'claim-action-dropdown-menu'}>
          {permittedActionTypes.map(([type, { subMenu }]) =>
            subMenu({ batchClaims, permissions, userId, setLoading })
          )}
        </Menu>
      }
    >
      <Button
        data-cy={`claim-action-dropdown-button`}
        loading={loading}
        type="primary"
      >
        {isVerbose && 'Actions'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export { ClaimActionMenu };
