import React, { createContext } from 'react';

import { Global } from '@emotion/react';
import tw, { css, GlobalStyles } from 'twin.macro';
import { ConfigProvider, App as AntdApp } from 'antd';
import {
  Reset,
  SetBorderRadius,
  SetDarkMode,
  SetFontSize,
  SetShowMenu,
  UiOptionActions,
  UiOptions,
  useUiOptions,
} from './useUiOptions';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';
import { BodyBgColorUpdater } from './BodyBgColorUpdater';

import { useHotkeys } from 'react-hotkeys-hook';

export interface IDesignProvider {
  options: UiOptions;
  toggleDarkMode: () => void;
  setDarkMode: (enabled: boolean) => void;
  setBorderRadius: (borderRadius: number) => void;
  setFontSize: (fontSize: number) => void;
  reset: () => void;
}

export const DesignProvider = createContext<IDesignProvider>(
  {} as IDesignProvider
);

export type DesignSystemProviderProps = {
  children?: React.ReactNode;
};

/**
 * Provides theme management for antd and tailwind -- using the same colors
 * See the tailwind.readme.md in the root for details about how the colors are
 * loaded in the tailwind.config.js; they are read using the tailwind config
 * resolver below, and provided back to the antd config provider to control its theme.
 * @param children
 * @constructor
 */
export const DesignSystemProvider = ({
  children,
}: DesignSystemProviderProps) => {
  const twConfig = React.useMemo(
    () => resolveConfig(tailwindConfig as any),
    []
  );

  const [uiOptions, _updateUiOption] = useUiOptions();

  const updateUiOption = (opt: UiOptionActions) => {
    _updateUiOption(opt);
  };

  const clsScrollbar = React.useMemo(
    () =>
      css(tw`
    _scrollbar-thin 
    _scrollbar-thumb-antd-lt-colorBorder dark:_scrollbar-thumb-antd-dk-colorBorder
    _scrollbar-track-antd-lt-colorBorderSecondary dark:_scrollbar-track-antd-dk-colorBorderSecondary
    _scrollbar-corner-antd-lt-colorBorderSecondary dark:_scrollbar-corner-antd-dk-colorBorderSecondary
    _scrollbar-thumb-rounded-md
    `),
    []
  );

  const designProvider = React.useMemo(
    () => ({
      options: uiOptions,
      toggleDarkMode: () =>
        updateUiOption(new SetDarkMode(!uiOptions.darkMode)),
      setDarkMode: (enabled: boolean) =>
        updateUiOption(new SetDarkMode(enabled)),
      setBorderRadius: (borderRadius: number) =>
        updateUiOption(new SetBorderRadius(borderRadius)),
      setFontSize: (fontSize: number) =>
        updateUiOption(new SetFontSize(fontSize)),
      reset: () => updateUiOption(new Reset()),
    }),
    [uiOptions]
  );

  const antdTokens = React.useMemo(
    () => twConfig?.theme?.antd || { light: {}, dark: {} },
    [twConfig]
  );

  const getAgGridAlpineTheme = (theme: 'light' | 'dark') => ({
    '--ag-cell-horizontal-padding': '0.75rem',
    '--ag-header-height': '36px',
    '--ag-alpine-active-color': antdTokens[theme].colorPrimaryBorder,
    '--ag-background-color': antdTokens[theme].colorBgContainer,
    '--ag-odd-row-background-color': antdTokens[theme].colorBgElevated,
    '--ag-row-hover-color': antdTokens[theme].colorPrimaryBg,
    '--ag-selected-row-background-color': antdTokens[theme].colorInfoBg, // .colorPrimaryBgHover,  //_bg-antd-lt-colorInfoBg
    '--ag-header-background-color': antdTokens[theme].bgColor,
    '--ag-border-color': antdTokens[theme].colorBorderSecondary,
    '--ag-value-change-value-highlight-background-color':
      antdTokens[theme].colorPrimary,
    '.ag-header-icon.ag-header-label-icon.ag-filter-icon': tw`_text-antd-lt-colorHighlight dark:_text-antd-dk-colorHighlight`,
    '.ag-sort-indicator-container': {
      '& .ag-sort-order, .ag-sort-ascending-icon, .ag-sort-descending-icon': tw`_text-antd-lt-colorWarningText dark:_text-antd-dk-colorWarningText`,
    },
  });

  const agGridThemes = React.useMemo(
    () => ({
      '.ag-root-wrapper': {
        ...tw`_p-0 _m-0`,
        borderRadius: `${antdTokens.light.borderRadius}px`,
      },
      '.ag-theme-alpine': getAgGridAlpineTheme('light'),
      '.ag-theme-alpine-dark': getAgGridAlpineTheme('dark'),
    }),
    [antdTokens]
  );

  const agGridFindingRowClasses = React.useMemo(
    () => ({
      '.ala-ag-row-style-accepted-finding': {
        ...tw`_bg-antd-lt-green-3 dark:_bg-antd-dk-green-3`,
      },
      '.ala-ag-row-style-needs-review-finding': {
        ...tw`_bg-antd-lt-red-3 dark:_bg-antd-dk-red-4`,
      },
    }),
    [antdTokens]
  );

  useHotkeys('ctrl+shift+alt+m', () =>
    updateUiOption(new SetDarkMode(!uiOptions.darkMode))
  );
  useHotkeys('ctrl+shift+meta+m', () =>
    updateUiOption(new SetShowMenu(!uiOptions.showMenu))
  );

  return (
    <DesignProvider.Provider value={designProvider}>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          // algorithm: uiOptions.darkMode ? darkAlgorithm : defaultAlgorithm,
          token: {
            ...(uiOptions.darkMode ? antdTokens.dark : antdTokens.light),
            fontSizeHeading3: 18,
            ...(uiOptions.fontSize &&
            uiOptions.fontSize >= 5 &&
            uiOptions.fontSize !== 14
              ? ((f: number) => ({
                  fontSize: f,
                  fontSizeSM: f - 2,
                  fontSizeLG: f + 2,
                  fontSizeXL: f > 14 ? f + 4 : f + 6,
                  fontSizeHeading1: f * 2 + 8,
                  fontSizeHeading2: f * 2,
                  fontSizeHeading3: f + 8,
                  fontSizeHeading4: f + 4,
                  fontSizeHeading5: f + 2,
                }))(uiOptions.fontSize)
              : {}),
            ...(uiOptions.borderRadius === 2
              ? {
                  borderRadius: 2,
                  borderRadiusXS: 1,
                  borderRadiusSM: 2,
                  borderRadiusLG: 3,
                  borderRadiusOuter: 1,
                }
              : {}),
          },
        }}
      >
        <BodyBgColorUpdater />
        <AntdApp style={{ width: '100%', height: '100%' }}>{children}</AntdApp>
        <Global
          styles={css([
            {
              body: {
                width: '100%',
                height: '100%',
                fontSize: '1rem',
                color: '#595c97',
                ...clsScrollbar,
              },
            },
            // Not sure why clsScrollbar needs be applied twice but one seems to get thumb/shape and the other track/bg
            clsScrollbar,
            // make agGrid match our theme
            agGridThemes,
            // agGridFindingRowClasses,
          ])}
        />
      </ConfigProvider>
    </DesignProvider.Provider>
  );
};
