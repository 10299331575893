// external
import { React, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Global, css } from '@emotion/react';
import { useQuery, useMutation } from 'urql';
import gql from 'graphql-tag';
import { EditProfile } from './editProfile';

// component library
import {
  Layout,
  Avatar,
  Card,
  Divider,
  Button,
  Row,
  Col,
  notification,
} from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import { SetupTOTP } from './setupTOTP';
import { AlaffiaClientContext } from '../../alaffiaClient/alaffiaClientProvider';
import { useLog } from '../../common/log/useLog';

// internal
const { Meta } = Card;

const GetProfileQuery = gql`
  query ($uid: UUID!) {
    auditorUser(id: $uid) {
      id
      firstName
      lastName
      email
      userType
      userCognitoSettings {
        username
        preferredMfaSetting
      }
    }
  }
`;

const Profile = () => {
  const log = useLog('UserProfile');
  // log.setLevel(log.levels.DEBUG, false);

  const alaffiaClient = useContext(AlaffiaClientContext);
  const uid = alaffiaClient.userId;
  const [isEdit, setIsEdit] = useState(false);
  const [totpData, setTotpData] = useState(null);

  const [{ fetching, data, error }, refreshProfileQuery] = useQuery({
    query: GetProfileQuery,
    variables: {
      uid,
    },
  });

  const handleMfaChange = async () => {
    const response = await alaffiaClient.fetchAuthenticatedPost(
      '/totp',
      undefined
    );

    const dataMfa = await response.json();
    log.debug('PROFILE edit TOTP response', { dataMfa });

    const { seed } = dataMfa;
    //If we did not get the seed, usually network, or jwt expired issue, then return...
    if (!seed) return;

    const { email } = data.auditorUser;
    const thisUrl = window.location.host;
    const link = `otpauth://totp/${thisUrl}:${email}?secret=${seed}&issuer=${thisUrl}`;

    log.debug('PROFILE edit TOTP info', { link, seed, dataMfa });

    setTotpData({ link, seed });

    setIsEdit(true);
  };

  const loading = !data && fetching;

  return (
    <div>
      {!loading ? (
        <Layout id="profile_layout">
          <Layout.Content id="profile_layout_content">
            <Card
              loading={fetching}
              style={{ width: '45%' }}
              extra={
                <Button
                  icon={<LogoutOutlined />}
                  style={{ width: '100%', color: 'red' }}
                  onClick={() => {
                    alaffiaClient?.logout();
                    window.location.reload();
                  }}
                >
                  <Link to="/"> Logout</Link>
                </Button>
              }
              title="My Alaffia Profile"
            >
              <Meta
                // avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                avatar={<Avatar size={64} icon={<UserOutlined />} />}
                title={
                  data.auditorUser.firstName + ' ' + data.auditorUser.lastName
                }
                description={data ? data.auditorUser.email : ''}
              />
              <div style={{ paddingTop: '10px' }}>
                {data.auditorUser.userType}
              </div>
              <Divider />
              <Row>
                <h3>Authentication Settings</h3>
              </Row>
              <Row>
                <div style={{ width: '100%' }}>
                  {data.auditorUser.userCognitoSettings?.preferredMfaSetting ===
                  'SOFTWARE_TOKEN_MFA' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ maxWidth: '50%' }}>
                        <p>Token Based Authentication Enabled!</p>
                        <div style={{ display: 'flex' }}>
                          <ExclamationCircleOutlined
                            style={{ paddingRight: '5px' }}
                          />
                          <p style={{ fontStyle: 'italic', fontSize: '11px' }}>
                            You can reset your token if you are seeing issues
                            with your software token login.
                          </p>
                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Button shape="round" onClick={handleMfaChange}>
                          Reset Token
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ maxWidth: '50%' }}>
                        <p>SMS Authentication Enabled</p>
                        <div style={{ display: 'flex' }}>
                          <ExclamationCircleOutlined
                            style={{ paddingRight: '5px' }}
                          />
                          <p style={{ fontStyle: 'italic', fontSize: '11px' }}>
                            Alaffia strongly recommends that everyone set up
                            token based MFA, as it is the most secure and
                            reliable form of log in.
                          </p>
                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Button shape="round" onClick={handleMfaChange}>
                          Set up Token MFA
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </Card>
          </Layout.Content>
          <Global
            style={css`
              #profile_layout {
                height: 100vh;
                max-height: 100vh;
              }

              #profile_layout_content {
                height: 100vh;
                max-height: 100vh;
                margin: 16px 16px;
                overflow: scroll;
              }

              .ant-card {
                border-radius: 6px;
                box-shadow:
                  0 4px 10px 0 rgba(20, 19, 34, 0.03),
                  0 0 10px 0 rgba(20, 19, 34, 0.02);
              }
            `}
          />

          <SetupTOTP
            visible={isEdit}
            closeModal={() => setIsEdit(false)}
            data={totpData}
            refreshProfileQuery={refreshProfileQuery}
          />
          {/* AT 4/9/21 - Commenting because we are not allowing profile
          editing at this time */}
          {/* 
            <EditProfile
              visible={isEdit}
              closeEdit={closeEdit}
              saveCloseEdit={saveCloseEdit}
              data={data}
            /> 
          */}
        </Layout>
      ) : (
        <div />
      )}
    </div>
  );
};

export { Profile };

// Saving this code for later
// related to editing the auditorUser profile
{
  /* <Row> */
}
{
  /* TODO - Bring this data back in 
Commenting out for now to avoid confusion
and to get to MVP  */
}
{
  /* <Col span={12}>
  <div>
    <div style={{ paddingTop: '10px' }}>
      Mobile Phone (Used for 2FA):{' '}
      {data ? data.auditorUser.userProfile.mobilePhoneNumber : ''}
    </div>
    <div style={{ paddingTop: '10px' }}>
      Work Phone:{' '}
      {data ? data.auditorUser.userProfile.workPhoneNumber : ''}
    </div>
    <div style={{ paddingTop: '10px' }}>Company Name:</div>
    <div style={{ paddingTop: '1px' }}>
      {data ? data.auditorUser.userProfile.company : ''}
    </div>
  </div>
</Col> */
}
{
  /* TODO - Bring this data back in 
Commenting out for now to avoid confusion
and to get to MVP  */
}
{
  /* <Col span={8}>
  <div style={{ paddingTop: '10px' }}>Home Address: </div>
  <div style={{ paddingTop: '1px' }}>
    {data ? data.auditorUser.userProfile.addressStreet : ''}
  </div>
  <div style={{ paddingTop: '1px' }}>
    {data ? data.auditorUser.userProfile.addressCity : ''}{' '}
    {data ? data.auditorUser.userProfile.addressState : ''}{' '}
    {data ? data.auditorUser.userProfile.addressZipcode : ''}
  </div>
</Col> 
</Row>
<Divider /> */
}

// const saveCloseEdit = (values, totpVerified) => {
//   updateProfile(values, totpVerified);
//   setIsEdit(false);
// };

// const updateProfile = async (values, totpVerified) => {
//   const patch = { ...values };

//   let profileInputs;
//   profileInputs = Object.create({});
//   profileInputs['avatar'] = ''; //This is needed until we are setting this..
//   for (let key in patch) {
//     if (key !== 'mfaType') profileInputs[key] = patch[key] ? patch[key] : '';
//   }
//   await updateProfileMutation({ profileInputs });

//   if (patch.mfaType !== currentMfa) {
//     if (patch.mfaType === 'TOTP' && !totpVerified) {
//       notification['warning']({
//         message: 'TOTP not set',
//         description:
//           'All Profile edits successfully updated except for TOTP, MFA not set due to Verification failure.',
//       });
//       return;
//     }

//     if (patch.mfaType !== undefined) {
//       await setMFAPrefs(patch.mfaType);
//     }
//   }
// };
