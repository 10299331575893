import accounting from 'accounting-js';
import tw, { css } from 'twin.macro';
import { FindingAdjustments } from '../../util/findingAdjustments';
import { Fragment } from 'react';

export type IbinIbLineAdjustmentsProps = {
  ibLineAdjustments: FindingAdjustments[];
  paymentRate: number;
};

const clsPaymentAdjusted = css({
  ...tw`_pr-0.5 _text-antd-lt-colorWarningText dark:_text-antd-dk-colorWarningText`,
});

const isSet = (v: any) => v !== null && v !== undefined;

const isIpcOverride = (x: FindingAdjustments) =>
  isSet(x.overrides.improperPaymentCost) &&
  x.overrides.improperPaymentCost !== x.improperPaymentCost;

export const IbinIbLineAdjustments: React.FC<IbinIbLineAdjustmentsProps> = ({
  ibLineAdjustments,
  paymentRate,
}) => (
  <div key="IbinIbLineAdjustments-display" tw="_grid _gap-0.5 _grid-cols-12">
    {ibLineAdjustments?.map(x => (
      <Fragment key={'row' + x.ibLineId}>
        <div
          key={'id' + x.ibLineId}
          tw="_col-span-2 _italic _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          Line {x.ibLineNumber}
        </div>
        <div key={'ipuc' + x.ibLineId}>
          {x.improperPaymentUnitsCharged}/{x.unitsBilled}
        </div>
        <div
          key={'at' + x.ibLineId}
          tw="_italic _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          @
        </div>
        <div key={'da' + x.ibLineId} tw="_col-span-2">
          <span
            css={
              !!x.overrides.amountCharged &&
              x.overrides.amountCharged !== x.amountCharged &&
              clsPaymentAdjusted
            }
          >
            {accounting.formatMoney(x.discrepantAmount)}
          </span>
        </div>
        <div
          key={'x' + x.ibLineId}
          tw="_italic _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          {isIpcOverride(x) ? '' : 'x'}
        </div>
        <div
          key={'pr' + x.ibLineId}
          tw="_col-span-2 _italic _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          {isIpcOverride(x)
            ? '(override)'
            : accounting.toFixed(paymentRate * 100, 1) + '%'}
        </div>
        <div
          key={'eq' + x.ibLineId}
          tw="_col-span-1 _italic _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          =
        </div>
        <div
          key={'ipc' + x.ibLineId}
          tw="_col-span-2 _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary"
        >
          <span css={isIpcOverride(x) && clsPaymentAdjusted}>
            {accounting.formatMoney(
              x.overrides.improperPaymentCost ?? x.improperPaymentCost
            )}
          </span>
        </div>
      </Fragment>
    ))}
  </div>
);
