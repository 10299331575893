import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'urql';
import { useHistory } from 'react-router-dom';
import { Table, Spin, Card, Row, Col, Collapse, Button, Empty } from 'antd';

import { css } from '@emotion/react';

import { generateClaimListColumnList } from './claimListTableColumns';
import { AuditProgress } from '../audit/auditProgress/auditProgress';
import { ViewDocumententationModal } from '../audit/claimWorkspace/viewDocumententationModal';
import { BatchClaimOverviewDrawer } from '../audit/batchClaimOverview/batchClaimOverviewDrawer';
import { ClaimActionMenu } from '../claimActionMenu/claimActionMenu';
import { ReportGenerationModal } from '../claimActionMenu/actionTypes/reports/report';

import { UserContext } from '../context/user';
import { filterMapByValue } from '../../util/collectionUtils';
import { useUserPreference } from '../../util/preferences';
import TableColumnSelectorCollapsible from '../misc/tableColumnSelectorCollapsible';
import TitleWithDescription from '../misc/titleWithDescription';

import { generateSortEnumOnSort, flattenOrderByArgsObj } from './util';
import { PdfViewerContext } from '../../common/pdf/pdfViewerProvider';
import { DocumentViewerNav } from '../documentViewer/documentViewerNav';
import { createPortal } from 'react-dom';
import { DocumentViewer } from '../documentViewer/documentViewer';
import { usePdfViewer } from '../../common/pdf/usePdfViewer';
import { useLog } from '../../common/log/useLog';

const clsFlex = css({ flex: 'initial', paddingLeft: '1%' });
const clsFlexRight = css({ marginLeft: 'auto' });
const clsFlexContainer = css({ display: 'flex' });

const ClaimListTable = ({
  tableName,
  tableDescription,
  buttons,
  batchClaimFilter,
  setDocumentationViewerProps,
}) => {
  const log = useLog('ClaimListTable');
  const history = useHistory();
  const { Panel } = Collapse;
  const {
    permissions,
    permissions: { baseQueryType, userDefaultFilter },
    id: userId,
    userType,
    claimList: { query: claimListQuery, columns: allowedClaimListColumns },
  } = React.useContext(UserContext);

  const [reportModalVisible, setReportModalVisible] = useState(false);

  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [orderByArgs, setOrderByArgs] = useState(
    [] // TYPE: [ {columnKey: <str>, sortEnums: [sortEnum:<GQL orderByArgs>] }, ...]
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });
  const [viewDocumentNamesModalVisible, setViewDocumentNamesModalVisible] =
    React.useState(false);

  const [clickedOnBatchClaim, setClickedOnBatchClaim] = useState(null);
  const [showClaimDetails, setShowClaimDetails] = useState(false);
  const [selectedBatchClaims, setSelectedBatchClaims] = useState(null);

  // state for the selected columns - filter columns by 'default' from user permissions
  // to determine whether this column should show when the page loads.
  const [customColumnSelection, _setCustomColumnSelection] = useState(
    new Set(
      filterMapByValue(allowedClaimListColumns, col => col.default).keys()
    )
  );
  const setCustomColumnSelection = cols => {
    setsavedUserColPrefs(cols);
    _setCustomColumnSelection(new Set(cols));
  };
  const disabledColumns = useMemo(
    () =>
      Array.from(
        filterMapByValue(allowedClaimListColumns, col => col.disabled).keys()
      ),
    [allowedClaimListColumns]
  );

  // column selection persistence -- this can be componentized as we implement it in other places
  const [savedUserColPrefs, setsavedUserColPrefs, savedUserColPrefsErrors] =
    useUserPreference(`colpref.claimsList.${userType}.${encodeURI(tableName)}`);
  useLayoutEffect(() => {
    if (!!savedUserColPrefs) {
      _setCustomColumnSelection(new Set(savedUserColPrefs));
    }
  }, [savedUserColPrefs]);
  useEffect(() => {
    if (!!savedUserColPrefsErrors) {
      log.warn(
        'Failed to persist column selection in local storage',
        savedUserColPrefsErrors
      );
    }
  }, [savedUserColPrefsErrors]);

  const [isActionLoading, setIsActionLoading] = React.useState(false); // to remove

  const rowSelection = permissions.allowMultiSelect
    ? {
        type: 'checkbox',
        onChange: (_, selectedRows) => {
          // We store references to the batchClaim instead of the actual batchClaim so that
          // the cache can update the batchClaim obj associated with id when actions occur
          setSelectedBatchClaims(
            selectedRows.map(selectedRow => selectedRow.id)
          );
        },
      }
    : null;

  // combine the search filter with the default filters from the
  // from the user configuration
  const combinedFilter = {
    and: [
      ...batchClaimFilter.and, // all batchClaimFilters from search are placed in an and array by default
    ],
  };
  // placing the userDefaultFilter into the and array ensures no key collisions
  if (
    userDefaultFilter.batchClaimFilter &&
    Object.keys(userDefaultFilter.batchClaimFilter).length > 0
  )
    combinedFilter.and.push(userDefaultFilter.batchClaimFilter);

  log.debug('filter --> ', {
    batchClaimFilter: combinedFilter,
    ...paginationArgs,
    orderBy: flattenOrderByArgsObj(orderByArgs),
  });

  const [{ fetching, error, data: batchClaimsData }, claimsRefreshQuery] =
    useQuery({
      query: claimListQuery,
      variables: {
        //TODO: change class name
        batchClaimFilter: combinedFilter,
        ...paginationArgs,
        orderBy:
          Object.values(orderByArgs).length > 0
            ? flattenOrderByArgsObj(orderByArgs)
            : null,
      },
      paused: !batchClaimFilter,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    });

  const { pdfViewer } = usePdfViewer();
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const setDocumentationViewerPropsNew = useCallback(
    props => {
      if (!props?.variables?.batchClaimId || !pdfViewer.ready) {
        log.info('No batchClaimId or pdfViewer not ready');
      }
      setSelectedClaimId(props.variables.batchClaimId);
      pdfViewer.setIsOpen(true, props.variables.batchClaimId);
    },
    [pdfViewer]
  );

  const columns = batchClaimsData
    ? generateClaimListColumnList({
        batchClaimsData,
        permissions,
        baseQueryType,
        allowedClaimListColumns,
        props: {
          isActionLoading,
          setIsActionLoading,
          setDocumentationViewerProps: setDocumentationViewerPropsNew,
          setClickedOnBatchClaim,
          setShowClaimDetails,
          setViewDocumentNamesModalVisible,
        },
        customColumnSelection,
      })
    : [];

  if (error) {
    log.error(
      'Error processing claimListQuery with following error:',
      error,
      ' --> with variables passed',
      combinedFilter
    );
  }

  return (
    <div>
      {(fetching || batchClaimsData) && (
        <div>
          <Collapse defaultActiveKey={['auditProgressStatsSubMenu']}>
            <Panel header="Progress Statistics" key="auditProgressStatsSubMenu">
              <Row>
                <Col span={4}></Col>
                <Col span={14}>
                  <Card size={'small'} bordered={false}>
                    <AuditProgress
                      // when loading ends it triggers a refresh
                      refreshTrigger={!isActionLoading}
                      // It's called 'combined' as its assumed that this filter hsa been combined with any userDefaultFilter defined
                      combinedBatchClaimFilter={combinedFilter}
                    />
                  </Card>
                </Col>
                <Col span={6}></Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      )}
      {batchClaimsData && !error ? (
        <div
          css={css`
            padding-right: 5px;
            overflow: auto;
          `}
          id="claim-list-table-antd-table-div"
          data-cy="claim-list-table-antd-table-div"
        >
          <Table
            id="claim-list-table-antd-table"
            data-cy="claim-list-table-antd-table"
            loading={fetching}
            rowSelection={rowSelection}
            title={() => (
              <div css={clsFlexContainer}>
                <TitleWithDescription
                  title={tableName}
                  description={tableDescription}
                ></TitleWithDescription>
                {permissions.allowMultiSelect && (
                  <div css={[clsFlex, clsFlexRight]}>
                    {
                      // Since We store references to the batchClaim instead of the actual batchClaim
                      // we must calculate which claims to pass using the ids stored in selectedBatchClaims
                      // This way the cache can update the batchClaim obj associated with id when actions occur
                      <ClaimActionMenu
                        setIsActionLoading={setIsActionLoading}
                        batchClaims={
                          (selectedBatchClaims &&
                            batchClaimsData[
                              baseQueryType.batchClaims
                            ].nodes.filter(({ id }) =>
                              selectedBatchClaims.includes(id)
                            )) ||
                          []
                        }
                        isVerbose={true}
                      />
                    }
                  </div>
                )}
                {buttons &&
                  buttons.map((button, i) => (
                    <div key={i} css={[clsFlex, clsFlexRight]}>
                      {button}
                    </div>
                  ))}
                <div css={[clsFlex, clsFlexRight]}>
                  {permissions.claimActions.report.includes('createReport') && (
                    <span>
                      <Button
                        data-cy="claim-list-report-all-button"
                        onClick={() => setReportModalVisible(true)}
                      >
                        Report All
                      </Button>
                    </span>
                  )}
                </div>
                <div css={[clsFlex, clsFlexRight]}>
                  <TableColumnSelectorCollapsible
                    columns={columns}
                    selectedColumns={Array.from(customColumnSelection.keys())}
                    disabledColumns={disabledColumns}
                    setSelectedColumns={setCustomColumnSelection}
                  />
                </div>
              </div>
            )}
            onRow={batchClaim => {
              return {
                onDoubleClick: () => {
                  history.push(`/workspace/${batchClaim.id}`);
                },
              };
            }}
            columns={columns.filter(c => customColumnSelection.has(c.key))}
            dataSource={batchClaimsData[baseQueryType.batchClaims].nodes.map(
              (node, i) => {
                node.key = `claim-list-table-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
                return node;
              }
            )}
            pagination={{
              ...paginationOptions,
              current: currentPageNumber,
              // only bottom left because when the table width
              // begins to exceed the length of page the top right bar looks off
              position: ['bottomLeft'],
              total:
                batchClaimsData &&
                batchClaimsData[baseQueryType.batchClaims].totalCount,
              pageSizeOptions:
                userType === 'ADMINISTRATOR'
                  ? ['10', '20', '30', '40', '50', '100', '150', '200']
                  : ['10', '20', '30', '40', '50'],
              showSizeChanger: true,
              showTotal: (total, range) => <div>{total} Total Claims</div>,
              onChange: (nextPage, pageSize) => {
                setPaginationOptions({ pageSize });
                setPaginationArgs({
                  first: pageSize,
                  offset:
                    nextPage > 1
                      ? (nextPage - 1) * paginationOptions.pageSize
                      : null,
                });
                setCurrentPageNumber(nextPage);
                claimsRefreshQuery();
              },
            }}
            onChange={(pagination, filters, sorterObj, extra) => {
              // On every new change to sorter we are either removing a sort, changing a sort, or adding a new one
              // We need to reflect this addition, removal, or change in the orderBy state while MAINTAINING order of the enums as they were clicked
              if (sorterObj && Object.keys(sorterObj).length > 0) {
                log.log('ON SORT CHANGE:', sorterObj);
                const newOrderByArgs = generateSortEnumOnSort(
                  Array.isArray(sorterObj) ? sorterObj : [sorterObj],
                  orderByArgs
                );
                setOrderByArgs(newOrderByArgs);
                claimsRefreshQuery();
              }
            }}
          />
          {/* renders the drawer */}
          <BatchClaimOverviewDrawer
            batchClaimId={clickedOnBatchClaim ? clickedOnBatchClaim.id : null}
            onClose={() => setShowClaimDetails(false)}
            isVisible={showClaimDetails && clickedOnBatchClaim}
            setClaimActionMenuIsActionLoading={setIsActionLoading}
            isActionLoading={isActionLoading}
          />

          {reportModalVisible && (
            <ReportGenerationModal
              {...{
                batchClaimFilter,
                reportModalVisible,
                setReportModalVisible,
              }}
            />
          )}

          <DocumentViewer claimId={selectedClaimId}></DocumentViewer>
          {/*{pdfViewerCtx.getViewer('documentViewer').isOpen &&*/}
          {/*  pdfViewerCtx.getViewer('documentViewer').getNavHtmlElement() &&*/}
          {/*  createPortal(*/}
          {/*    <div>yo</div>,*/}
          {/*    pdfViewerCtx.getViewer('documentViewer').getNavHtmlElement()*/}
          {/*  )}*/}
          {/*  <ViewDocumententationModal*/}
          {/*    viewDocumentNamesModalVisible={viewDocumentNamesModalVisible}*/}
          {/*    setViewDocumentNamesModalVisible={setViewDocumentNamesModalVisible}*/}
          {/*    batchClaimId={clickedOnBatchClaim ? clickedOnBatchClaim.id : null}*/}
          {/*  />*/}
        </div>
      ) : (
        <div css={{ textAlign: 'center', marginTop: '5%' }}>
          {error && !fetching ? (
            <Empty
              size="large"
              description={
                <span>
                  There was an error processing this search. Please review
                  search criteria and values passed - If error persists contact{' '}
                  <a href="mailto:support@alaffiahealth.com" target="_top">
                    support@alaffiahealth.com
                  </a>
                </span>
              }
            />
          ) : (
            <Spin size="large" />
          )}
        </div>
      )}
    </div>
  );
};

export { ClaimListTable };
