import {
  ColDef,
  GetDetailRowData,
  ICellRendererParams,
  IDetailCellRendererParams,
} from 'ag-grid-enterprise';
import { Finding, ItemizedBillLine } from '../../../../../gql/graphql';
import { findingToAF } from '../../../queries/claim/interimUtils';
import {
  CommentDetailCellRenderer,
  commentTableDetailRendererParams,
} from './commentTableDetailRenderer';
import {
  // AuditFindingActions,
  AuditFindingTags,
} from '../../../claimWorkspace/auditFinding/claimWorkspaceAuditFinding';
import { Typography } from 'antd';
import { ibTableDefaultColumnDef } from '../../../claimWorkspace/itemizedBillTab/ibTable/ibTableColumns';
import { LineClampedCell } from '../../../../../common/table/ag/cell/lineClampedCell';
import {
  FindingDisplayAugmented,
  IBGridLine,
} from '../../../claimWorkspace/itemizedBillTab/ibTable/types';
import { DefaultColumn } from '../../../../../common/table/ag/cols/defaultColumn';
import { lineClampedCellRenderer } from '../../../../../common/table/ag/cell/lineClampedCellRenderer';
import { AuditFindingActions } from './AuditFindingActions';
const { Text } = Typography;

export const detailsDefautlCol: ColDef<FindingDisplayAugmented> = {
  ...DefaultColumn,
  cellRenderer: lineClampedCellRenderer,
};

/*
getDetailRowData: GetDetailRowData<TData, TDetail>;
interface GetDetailRowData<TData = any, TDetail = any> {
    (params: GetDetailRowDataParams<TData, TDetail>) : void
}
interface GetDetailRowDataParams<TData = any, TDetail = any> {
  // Row node for the details request. 
  node: IRowNode<TData>;
  // Data for the current row. 
  data: TData;
  // Success callback: pass the rows back for the grid request.  
  successCallback(rowData: TDetail[]): void;
}
  */

export const createAuditFindingDetailsTableRendererParams: (params: {
  getDetailRowData: GetDetailRowData<IBGridLine, FindingDisplayAugmented>;
  context: any;
}) => Partial<
  IDetailCellRendererParams<IBGridLine, FindingDisplayAugmented>
> = ({ getDetailRowData, context }) =>
  ({
    refreshStrategy: 'rows',
    detailGridOptions: {
      context,
      masterDetail: true,
      getRowId: params => params.data.id,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      detailCellRenderer: CommentDetailCellRenderer,
      detailCellRendererParams: commentTableDetailRendererParams,
      columnDefs: [
        { cellRenderer: 'agGroupCellRenderer', width: 50 },
        {
          headerName: '',
          headerTooltip: 'Denial Code',
          field: 'auditFindingRuleType',
          minWidth: 190,
          flex: 2,
          suppressMenu: true,
          cellRenderer: (props: ICellRendererParams<any, any>) =>
            !props.data ? (
              <div></div>
            ) : (
              <AuditFindingTags
                auditFinding={{
                  ...findingToAF(
                    // TODO we need a new component here..
                    !!props.data.denialCode
                      ? { ...props.data, ruleType: props.data.denialCode }
                      : props.data,
                    props.context?.current?.denialCodes ?? {}
                  ),
                }}
              ></AuditFindingTags>
            ),
        },
        {
          headerName: '',
          headerTooltip: 'Summary',
          field: 'detailRowSummary',
          flex: 5,
          cellRenderer: (props: ICellRendererParams<any, any>) => (
            <LineClampedCell>{props?.value}</LineClampedCell>
          ),
        },
        {
          headerName: '',
          headerTooltip: 'Actions',
          flex: 3,
          field: 'improperPaymentCost',
          type: 'rightAligned',
          cellRenderer: (
            props: ICellRendererParams<FindingDisplayAugmented, any, any>
          ) => {
            return !props.data ? (
              <div></div>
            ) : (
              <AuditFindingActions
                auditFinding={props.data}
                {...props.context?.current?.createFindingFunctions}
                ibData={[props.data.ibLine]}
              ></AuditFindingActions>
            );
          },
        },
      ],
      defaultColDef: detailsDefautlCol,
      headerHeight: 8,
      detailRowHeight: 275,
    },
    getDetailRowData,
    // the as IDetailCellRenderParams<...> here is per their doc... looks like they didn't work it out either...:
  }) as Partial<IDetailCellRendererParams<IBGridLine, FindingDisplayAugmented>>;
