import * as stateTypes from './stateTypes';

/*
  This function aggregates all states within each of the stateType objects
  it then returns an object representing all states
  where state's name is it's key that maps to that state's props as determined in the stateType files under 'claimState/stateTypes' 

  NOTE: StateTypes are defined by the set of keys on the object returned by stateTypes.js which aggregates all the files under 'claimState/stateTypes'.
        Each file represents a unique StateType containing a set of 'states' that define all possible states that any given StateType can be in

  return value format:
  {
    <stateName i.e 'IN_PROGRESS'>:{
      stateType, // i.e 'workflow'
      ...stateProps // i.e primaryColor, displayCondition, icon, timelineEventRendering, etc
    }
  }
*/
const getAllStatePropsAsObject = () => {
  const statesConfigObject = {};
  Object.entries(stateTypes)
    .map(([stateType, { states }]) =>
      Object.entries(states).map(([stateName, state]) => ({
        stateType,
        stateName,
        state,
      }))
    )
    .flat()
    .forEach(({ stateType, stateName, state }) => {
      statesConfigObject[stateName] = {
        stateType,
        stateName,
        state,
      };
    });
  return statesConfigObject;
};

/*
  This function gets all states within all the stateTypes included in the `stateTypesToShow` array (if `stateTypesToShow` isn't passed all stateTypes are selected)
  before then filtering out all valid states based on the user's permissions
  --> it then finally returns an array with every valid state's props 

  NOTE: StateTypes are defined by the set of keys on the object returned by stateTypes.js which aggregates all the files under 'claimState/stateTypes'.
        Each file represents a unique StateType containing a set of 'states' that define all possible states that any given StateType can be in

  input:
    - permissions: <Permissions Object: from user.js Context> --> (* required param *)
    - stateTypesToShow <String[] of stateTypes the function should return> --> (* Optional param *)
      - if null/not passed all stateTypes are shown
    - dataTypes <String[] of data types of stateTypes the function should return> --> (* Optional param *)
      - if not passed stateTypes within the enum type is returned
    
  
  return value format:
  [
    { 
      stateName, // i.e 'IN_PROGRESS'
      fieldName, // i.e 'workflow'
      ...stateProps // i.e primaryColor, displayCondition, icon, timelineEventRendering, etc
    }
  ]
*/
const getPermittedStatesAsArray = ({
  permissions,
  stateTypesToShow,
  dataTypes,
}) => {
  /* filtered on:
      - user permissions 
      - which stateTypes that are passed in as params
      - which dataTypes that are passed in as params
  */
  const filteredStateTypeArray = Object.entries(stateTypes).filter(
    ([stateType, { dataType }]) => {
      // We 1st check if this user is permitted to see this state type via the user permissions object
      let isValid =
        permissions.claimStatesToShow[stateType] &&
        permissions.claimStatesToShow[stateType].size > 0;

      // if 'dataTypes' is passed then we validate that the dataType of field is included (i.e stateType is of dataType 'enum')
      if (isValid && dataTypes) isValid = dataTypes.includes(dataType);

      // if 'stateTypesToShow' is passed we validate that the stateType is included (i.e we only want the 'workflow' and 'signOff' stateTypes)
      if (isValid && stateTypesToShow)
        isValid = stateTypesToShow.includes(stateType);
      return isValid;
    }
  );
  // Now for each stateType we retrieve the individual states and filter them as well on permissions
  return filteredStateTypeArray
    .map(([stateType, { states, filterBuilder }]) =>
      Object.entries(states)
        .filter(([stateName, state]) => {
          return permissions.claimStatesToShow[stateType].has(stateName);
        })
        .map(([stateName, state]) => ({
          filterBuilder,
          stateType,
          stateName,
          state,
        }))
    )
    .flat();
};

/*
  Parses the results of an auditProgress sub query for a particular stateType that used the groupedAggregate resolver to get results
*/
const parseGroupedAggregatesQueryResIntoProgessStatProps = ({
  stateType,
  totalClaims: { totalCount }, // the total number of batchClaims returned by batchClaimFilter in auditProgress query
  sumOfAggregates,
  formattedQueryResults,
  permissions,
  userType,
  queryableStates,
  defaultStates,
  states,
}) => {
  const progressStatProps = {};
  queryableStates.forEach(stateName => {
    if (permissions.claimStatesToShow[stateType].has(stateName)) {
      progressStatProps[stateName] = {
        /* 
         totalCount is set to 0 as a placeholder to be overwritten later if the count for this particular state is greater than 0
         
         If trying to populate the default state or if a number exists in formattedQueryResults[stateName] greater than 0
         then we overwrite the 0 recorded for 'totalCount' for this state using the logic below
        */
        totalCount: 0,
        name: states[stateName].name(userType),
        primaryColor: states[stateName].primaryColor,
        strokeColor: states[stateName].strokeColor,
        tagColor: states[stateName].tagColor,
        icon: states[stateName].icon,
        description: states[stateName].description(userType),
      };
      /*
        NOTE: the groupedAggregate resolver only returns the 'unique keys' that it finds as ENUM values stored in the column that it aggregates on.
        Sometimes the default state of any given stateType is not explicitly represented in the database and so is not always returned as a 'unique key'. 
          --> We attempt to infer the value associated with the default state by using the sumOfAggregates param that counts up the total number of 'unique key' counts
      */
      if (defaultStates.includes(stateName)) {
        /* EXPLICITLY SET DEFAULT VALUES indicates all the claims for which there exists a record in the DB of some action being taken on that stateType where that
         * action EXPLICITLY set the claims state to it's "default" state for that stateType
         *  i.e refunding an invoice or setting a claim that was on hold to no longer be on hold
         *  --> If multiple actions have been taken on a claim and therefore that claim was in "INOICE_PAID" state.
         *      If someone now refunds that invoice and EXPLICITLY sets the claim state to be "INVOICE_NOT_PAID"
         *      Then we would count that as a claim that has been explicitly set to "default" state
         */
        const explicitDefaultStateCount = formattedQueryResults[stateName] || 0;

        /* IMPLICITLY SET DEFAULT VALUES indicates all the claims for which there is no record in the DB of any action being taken on that stateType yet
         *
         *  i.e If no workflow actions have been taken yet
         *  --> if NO workflow action has been taken on a particular claim, that would mean there is
         *      no record in the workflow_claim_state table for that claim in the DB
         *      When no such record exists we say the state of that claim is in it's "default" state
         *      and therefore we have implicitly set the state as it's default state of CLAIM_RECEIVED
         *
         *  And so the implicit count is all claims in the database where the claim is in the "default" state by the fact that no record of that statType exists for that claim
         */
        const implicitDefaultStateCount = totalCount - sumOfAggregates;

        // the sum of claims implicitly and explicitly set to default is the number of claims in total that are in the default state
        progressStatProps[stateName].totalCount =
          implicitDefaultStateCount + explicitDefaultStateCount; // if all states for this stateType have a count of 0 this should be totalCount - 0 = totalCount
      } else if (formattedQueryResults[stateName])
        progressStatProps[stateName].totalCount =
          formattedQueryResults[stateName];
    }
  });

  return progressStatProps;
};

// converts a JS Object to GQL filter as a string for generating gql query strings
const objFilterToGQLStr = filter => {
  if (Object.entries(filter).length > 1) {
    return Object.entries(filter)
      .map(
        ([key, value]) =>
          `{${key}:${JSON.stringify(value).replaceAll('"', '')}}`
      )
      .join('\n');
  }
  return JSON.stringify(filter).replaceAll('"', '');
};

export {
  getPermittedStatesAsArray,
  getAllStatePropsAsObject,
  parseGroupedAggregatesQueryResIntoProgessStatProps,
  objFilterToGQLStr,
};
