import { OperationResult, useClient } from 'urql';
import { claimQueryDoc } from './documents.gql';
import { useCallback, useState } from 'react';
import { AuditDocumentation } from '../../../gql/graphql';

export const useDocuments = (
  claimId?: string | null
): [(networkOnly?: boolean) => Promise<AuditDocumentation[]>, boolean] => {
  const client = useClient();
  const [fetching, setFetching] = useState<boolean>(false);
  const fetchDocuments = useCallback(
    async (networkOnly: boolean = false) => {
      if (!claimId) {
        return Promise.reject('useDocuments: claimId is not set');
      }
      setFetching(true);
      try {
        return client
          .query(
            claimQueryDoc,
            {
              claimId,
            },
            networkOnly ? { requestPolicy: 'network-only' } : {}
          )
          .toPromise()
          .then(result => {
            setFetching(false);
            if (result.error) {
              console.error(
                `Error fetching documents for claim ${claimId}`,
                result.error
              );
              return Promise.reject(result.error);
            }
            return result?.data?.batchClaim?.auditDocumentation ?? [];
          })
          .catch(e => {
            console.error(`Error fetching documents for claim ${claimId}`, e);
            setFetching(false);
            return Promise.reject(e);
          });
      } catch (e) {
        console.error(`Unexpected error fetching documents for ${claimId}`, e);
        setFetching(false);
        return Promise.reject(e);
      }
    },
    [claimId, client]
  );
  return [fetchDocuments, fetching];
};
