import tw, { css } from 'twin.macro';
import Draggable from 'react-draggable';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';
import { FC, ReactNode, useRef } from 'react';

type DragModalProps = {
  open: boolean;
  close: () => void;
  modalProps?: Omit<ModalFuncProps, 'visible'> | Omit<ModalProps, 'visible'>;
  children: ReactNode;
};

export const DragModal: FC<DragModalProps> = ({
  open,
  close,
  modalProps,
  children,
}) => {
  const draggableRef = useRef(null);

  const props = {
    ...modalProps,
    open: open,
    onCancel: close,
    css: css({ ...tw`_w-full _cursor-move` }),
    maskClosable: false,
    mask: false,
    destroyOnClose: true,
    // used to implement react-draggable
    // fix eslintjsx-a11y/mouse-events-have-key-events
    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
    onFocus: () => {},
    onBlur: () => {},
    // The badly-named 'cancel' prop accepts a selector for elements that shouldn't start drag
    // https://www.npmjs.com/package/react-draggable - search 'cancel'
    modalRender: (modal: React.ReactNode) => (
      <Draggable
        nodeRef={draggableRef}
        cancel={
          'input, textarea, button, .rc-virtual-list, .ant-form-item-extra'
        }
      >
        <div ref={draggableRef}>{modal ?? <></>}</div>
      </Draggable>
    ),
  };

  // modal is destroyOnClose to clear state when cancelling and opening another type
  return <Modal {...props}>{children}</Modal>;
};
