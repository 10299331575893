import React from 'react';
import { Button } from 'antd';

const Login = props => {
  return (
    <div className="center-wrapper" style={{ marginTop: 45 }}>
      <Button
        id="login-button"
        data-cy="login"
        type="primary"
        href={process.env.REACT_APP_COGNITO_AUTH_URL}
      >
        Login
      </Button>
      <div>
        <p>
          By logging in, you are agreeing to the{' '}
          <a href="https://alaffiahealth.com/privacy-policy/">
            Alaffia Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export { Login };
