import { Form, InputNumber } from 'antd';
import { FormExtra } from '../../../../shared/components/form/formExtra';
import React from 'react';
import tw, { css } from 'twin.macro';
import { inputPropsCurrencyFormatter } from '../../../../util';
import { asMoney } from '../../../../util/format';
import { isSet } from '../../../../util/isSet';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';

export type BilledAmountProps = CommonFieldProps & {
  flagged?: boolean;
  addlExtraJsx?: JSX.Element;
  formLayout?: any;
};

export const BilledAmount: React.FC<BilledAmountProps> = ({
  dataKey,
  label = 'Billed Amount',
  initialValues,
  formLayout,
  addlExtraJsx = undefined,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<number>(
    dataKey,
    initialValues
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      {...formLayout}
      label={label}
      rules={[
        {
          required: true,
          message: 'Enter the charge amount per date of service',
        },
      ]}
      extra={
        <FormExtra visible={isSet(existingFindingValue) || !!addlExtraJsx}>
          <div tw="_flex">
            <div tw="_min-w-[3em]">
              {isSet(existingFindingValue) && asMoney(existingFindingValue)}
            </div>
            <div tw="_pl-0.5">{addlExtraJsx}</div>
          </div>
        </FormExtra>
      }
      initialValue={isSet(defaultValue) && defaultValue}
    >
      <InputNumber
        style={{ width: '50%' }}
        min={1}
        {...inputPropsCurrencyFormatter}
      />
    </Form.Item>
  );
};
