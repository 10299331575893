export const SW_RECEIVER_CHANNEL_NAME = 'service-worker-receiver-channel';

export const getClientReceiverBroadcastChannelName = (clientId: string) =>
  `client-receiver-${clientId}`;

export type IPCDataType = {
  type: string;
  tx: {
    clientId: string;
    instanceId?: string;
    operationId?: string;
  };
  payload: Record<string, any>;
};
