import { Button, Skeleton } from 'antd';
import { FC, ReactElement, useCallback, useState } from 'react';
import Split, { SplitProps } from 'react-split-grid';
import tw, { styled, css } from 'twin.macro';
import AutoSizer from 'react-virtualized-auto-sizer';

const splitterLayout = css({
  display: 'grid',
  gridTemplateColumns: '1fr 10px 1fr',
  ...tw`_h-full _max-h-full _min-h-full _w-full`,

  '& .gutter-col': {
    gridRow: '1/-1',
    cursor: 'col-resize',
    ...tw`_bg-antd-lt-colorFill
     _w-1 _h-full _max-h-full _min-h-full _rounded-md _shadow-md
     _border _border-solid _border-antd-lt-colorBorderSecondary`,
  },

  '& .gutter-col-1': {
    gridColumn: 2,
  },
});

export type VerticalSplitterProps = {
  left: ReactElement;
  right: ReactElement;
};

export const VerticalSplitter: FC<VerticalSplitterProps> = ({
  left,
  right,
}) => {
  const [gridTemplateColumns, setGridTemplateColumns] = useState<string[]>([
    '1fr',
    '10px',
    '1fr',
  ]);

  const [isSplit, setIsSplit] = useState<boolean>(true);

  const onDrag = useCallback(
    (direction: string, track: number, gridTemplateString: string) => {
      if (!isSplit) {
        return;
      }
      console.log('drag', direction, track, gridTemplateString);
      setGridTemplateColumns(gridTemplateString.split(' '));
    },
    []
  );
  // @ts-ignore
  return (
    <div tw="_h-full _w-full _max-h-full _min-h-full _flex _flex-col">
      <div>
        <Button onClick={() => setIsSplit(false)}>single</Button>
        <Button onClick={() => setIsSplit(true)}>split</Button>
      </div>
      <div tw="_flex-1">
        <div tw="_h-full _max-h-full _min-h-full">
          {/* <Split> does not for some reason define children as a
          prop despite this is from the sample usage, so ignore for now: */}
          {/* @ts-ignore */}
          <Split
            minSize={100}
            snapOffset={20}
            dragInterval={20}
            gridTemplateColumns={
              isSplit ? gridTemplateColumns.join(' ') : '1fr'
            }
            onDrag={onDrag}
          >
            {({
              getGridProps,
              getGutterProps,
            }: {
              getGridProps: any;
              getGutterProps: any;
            }) => (
              <div css={splitterLayout} {...getGridProps()}>
                <div tw="_p-1 _h-full _min-h-full _max-h-full">
                  <AutoSizer defaultWidth={25} defaultHeight={25}>
                    {(props: any) => {
                      const { height: h, width: w } = props;
                      return !h || h <= 25 ? (
                        <Skeleton active={true} />
                      ) : (
                        <div
                          data-mv={`w:${w},h:${h}`}
                          style={{
                            minHeight: h,
                            height: h,
                            width: w,
                          }}
                        >
                          {left}
                        </div>
                      );
                    }}
                  </AutoSizer>
                </div>
                {isSplit && (
                  <>
                    <div
                      className="gutter-col gutter-col-1"
                      {...getGutterProps('column', 1)}
                    />
                    <div tw="_p-1 _h-full _max-h-full _min-h-full">
                      <AutoSizer defaultWidth={25} defaultHeight={25}>
                        {({
                          height: h,
                          width: w,
                        }: {
                          height: number;
                          width: number;
                        }) => {
                          return !h || h <= 25 ? (
                            <Skeleton active={true} />
                          ) : (
                            <div
                              data-mv={`w:${w},h:${h}`}
                              style={{
                                minHeight: h,
                                height: h,
                                width: w,
                              }}
                            >
                              {right}
                            </div>
                          );
                        }}
                      </AutoSizer>
                    </div>
                  </>
                )}
              </div>
            )}
          </Split>
        </div>
      </div>
    </div>
  );
};
