import { FC, useMemo } from 'react';
import { Typography, Form, Select, Space, Divider } from 'antd';
import { FormExtra } from '../../../../shared/components/form/formExtra';
import accounting from 'accounting-js';
import { css } from 'twin.macro';
import { RevCodeBclInfo } from '../../types';
import { RevCodesForSelectedIbLines } from '../../viewController/revCodeUtils';
import { UUID } from '../../../../../../../types/ids';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';
const { Option } = Select;
const { Text } = Typography;

const revCodeInfoToOption = (infos: RevCodeBclInfo[]) =>
  infos.map(({ id, revCode, revDescription, unitCharge }: RevCodeBclInfo) => {
    return (
      <Option
        key={id}
        value={id}
        title={revDescription}
        disabled={unitCharge <= 0}
      >
        <Space
          split={<Divider type="vertical" />}
          css={css`
            min-width: 100%;
          `}
        >
          <div>{revCode}</div>
          <div>{accounting.formatMoney(unitCharge)}</div>
          <div
            css={css`
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              min-width: 8em;
              max-width: 8em;
            `}
          >
            {revDescription}
          </div>
        </Space>
      </Option>
    );
  });

export type RevCodeProps = CommonFieldProps & {
  revCodes: RevCodesForSelectedIbLines;
};

export const RevCode: FC<RevCodeProps> = ({
  dataKey,
  label = 'Rev. Code',
  revCodes,
  initialValues,
  formLayout,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<UUID>(
    dataKey,
    initialValues
  );

  const form = Form.useFormInstance();
  const revCodeFieldValue = Form.useWatch(formKey(dataKey).name);

  const revCodeOptions = useMemo(() => {
    return revCodes.recommended.length > 0 ? (
      <>
        <Select.OptGroup
          key="recommended-rev-code-options"
          label="UB codes matching selected IB lines"
        >
          {revCodeInfoToOption(revCodes.recommended)}
        </Select.OptGroup>
        <Select.OptGroup
          key="other-rev-code-options"
          label="Other codes available on UB"
        >
          {revCodeInfoToOption(revCodes.other)}
        </Select.OptGroup>
      </>
    ) : (
      revCodeInfoToOption(revCodes.other)
    );
  }, [revCodes]);

  const existingRevCodeValue = useMemo(
    () =>
      existingFindingValue && revCodes
        ? revCodes.byId[existingFindingValue]
        : undefined,
    [existingFindingValue, revCodes]
  );

  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      rules={[{ required: true, message: 'Select a rev. code' }]}
      initialValue={defaultValue}
      extra={
        <FormExtra visible={!!existingFindingValue}>
          {revCodeFieldValue !== existingFindingValue ? (
            <Text type="danger" tw="_text-xs">
              {`${existingRevCodeValue?.revCode} - Warning: Changing Rev. Code will decline the exiting finding and create a new finding!`}
            </Text>
          ) : (
            existingRevCodeValue && existingRevCodeValue.revCode
          )}
        </FormExtra>
      }
    >
      <Select
        placeholder="Select Rev Code"
        virtual={false}
        showSearch={true}
        optionFilterProp={'title'}
      >
        {revCodeOptions}
      </Select>
    </Form.Item>
  );
};
