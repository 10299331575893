import { Button, Form, message, Modal, Select } from 'antd';
import { useState } from 'react';
import { useMutation } from 'urql';
import { versionReportMutation } from '../../graphql/mutations';
import { reportServiceErr } from '../../util/errors';
import tw, { css } from 'twin.macro';

// TODO (MV) this def should be queried from gql or moved to ctx/user
const reportTypes = {
  MDH: {
    PRELIMINARY: { label: 'Preliminary', value: 'PRELIMINARY' },
    FINAL: { label: 'Final', value: 'FINAL' },
  },
  EOA: {
    PROCESSED: { label: 'Not applicable', value: 'PROCESSED' },
  },
  IFR: {
    PROCESSED: { label: 'Not applicable', value: 'PROCESSED' },
  },
};

const getReportTypeInfo = (reportType, reportSubtype) =>
  !reportTypes[reportType]
    ? // no report type:
      { label: `${reportType}.${reportSubtype}`, disabled: true }
    : // subtype ?
      !reportTypes[reportType][reportSubtype]
      ? // no subtype:
        { label: `${reportTypes[reportType]}.${reportSubtype}`, disabled: true }
      : // type is defined:
        reportTypes[reportType][reportSubtype];

const isReportVersionable = (reportType, reportSubtype) => {
  const reportTypeInfo = getReportTypeInfo(reportType, reportSubtype);
  return reportTypeInfo ? !reportTypeInfo.disabled : false;
};

/**
 * Renders a button to version a reportId
 * onlick shows a confirmation modal and then sends a version
 * report request
 * @param {reportId} UUID!
 */
const VersionReportButton = ({ auditFindingReport, refreshFunction }) => {
  const { auditFindingReportType, auditFindingReportSubtype, title, id } =
    auditFindingReport;

  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const [_, versionReport] = useMutation(versionReportMutation);

  const [form] = Form.useForm();

  return (
    <>
      <Button
        disabled={
          !isReportVersionable(
            auditFindingReportType,
            auditFindingReportSubtype
          )
        }
        onClick={() => setConfirmationModalVisible(true)}
      >
        <span tw="_text-xs">Version Report</span>
      </Button>
      <Modal
        open={confirmationModalVisible}
        onCancel={() => {
          form.resetFields();
          setConfirmationModalVisible(false);
        }}
        onOk={() => {
          form.validateFields().then(async ({ reportSubtype }) => {
            console.log('Versioning Report: ', id);

            const { error } = await versionReport({
              reportId: id,
              reportType: auditFindingReportType,
              reportSubtype,
            });

            if (error) {
              // the report service will return an error if there are no claims
              // that have updated audit findings since the last report. This is not
              // really an error - but just something we should display to the user
              // IMPROVEMENT - use the same query the report service does to check if there
              // is anything to report before the form is submitted
              if (error.message?.includes('no claim data')) {
                message.warning('No new findings to report.');
              } else {
                console.error('Error versioning report:', error);
                message.error(
                  `Error versioning report: ${reportServiceErr(error)}`
                );
              }
            } else {
              if (refreshFunction) {
                refreshFunction();
              }
              message.success('Report Versioned');
            }

            setConfirmationModalVisible(false);
            form.resetFields();
          });
        }}
      >
        <div
          css={css`
            margin-top: 5%;
            margin-bottom: 2%;
          `}
        >
          {`Create a new version of ${
            getReportTypeInfo(auditFindingReportType, auditFindingReportSubtype)
              ?.label
          } report ${title}?`}
        </div>
        <Form name="version-report-form" form={form}>
          <Form.Item
            name="reportSubtype"
            label="Type"
            rules={[{ required: true, message: 'Select the type of report' }]}
            initialValue={
              getReportTypeInfo(
                auditFindingReportType,
                auditFindingReportSubtype
              )?.value ||
              Object.values(reportTypes[auditFindingReportType] ?? [])[0]?.value
            }
          >
            <Select
              placeholder={'Select report type'}
              options={Object.values(reportTypes[auditFindingReportType] ?? [])}
              disabled={
                Object.values(reportTypes[auditFindingReportType] ?? [])
                  .length < 2
              }
            ></Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export { VersionReportButton };
