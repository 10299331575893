export const arrayHasLen = <T>(arr: unknown): arr is T[] =>
  Array.isArray(arr) && arr.length > 0 && !!nonNullArr<T>(arr);

export const nonNullArr = <T>(
  array: (T | null | undefined)[] | null | undefined
) =>
  array === null || array === undefined
    ? []
    : array.filter(
        (e: any): e is Exclude<typeof e, null | undefined> =>
          e !== null && e !== undefined
      );

const isString = (val: unknown): val is string => typeof val === 'string';
export const isNonNullStringArray = (arr: unknown): arr is string[] => {
  if (!arr || !Array.isArray(arr)) {
    return false;
  }
  const tmp = arr.filter(isString);
  return tmp.length > 0 && tmp.length === arr.length;
};
