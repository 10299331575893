import React, { FC } from 'react';
import { FindingInfo } from '../../../claimWorkspace/itemizedBillTab/ibTable/types';
import { Tag, Tooltip } from 'antd';
import { css } from '@emotion/react';
import { AuditFindingSeedType } from '../../../claimWorkspace/createAuditFinding/auditFindingUtil';
import { arrayHasLen } from '../../../util/array';
import tw from 'twin.macro';
import { AutodorIcon } from '../../../../misc/autodorIcon';

export type DenialCodeTagsProps = {
  findingInfos: FindingInfo[];
};

type UniqueInfo = FindingInfo & {
  count: number;
};

export const DenialCodeTags: FC<DenialCodeTagsProps> = ({ findingInfos }) => {
  if (!arrayHasLen(findingInfos)) {
    return null;
  }
  const uniqueInfos = findingInfos
    .filter(fi => fi.accepted || fi.type === 'suggestion')
    .reduce<Record<string, UniqueInfo>>((acc, fi) => {
      if (acc[fi.denialCode]) {
        acc[fi.denialCode].count++;
      } else {
        acc[fi.denialCode] = { count: 1, ...fi };
      }
      return acc;
    }, {});

  return (
    <>
      {Object.values(uniqueInfos).map(
        ({
          type,
          accepted,
          needsReview,
          seedType,
          denialCode,
          label,
          count,
        }) => {
          const isUB = seedType === AuditFindingSeedType.UB_CLAIM_LINE;
          const c = isUB ? 'geekblue' : 'magenta';
          return (
            <Tooltip
              color={c}
              key={isUB ? 'UB' + label : 'IB' + label}
              title={`${isUB ? 'UB' : 'IB'} - ${label} ${
                count > 1 ? `(${count})` : ''
              }`}
            >
              <Tag
                color={c}
                css={css({
                  ...tw`_my-[1px] _mx-[2px] _text-xs`,
                  ...(type === 'suggestion'
                    ? tw`_bg-antd-lt-gold-2! dark:_bg-antd-dk-gold-6!`
                    : {}),
                })}
                icon={
                  type === 'suggestion' ? (
                    <span tw=" _rounded-full ">
                      <AutodorIcon width={12}></AutodorIcon>
                    </span>
                  ) : undefined
                }
              >
                <span tw="_pl-0.5 _align-middle">{label}</span>
              </Tag>
            </Tooltip>
          );
        }
      )}
    </>
  );
};
