import {
  CacheExchangeOpts,
  Data,
  DataFields,
  cacheExchange,
  Cache,
} from '@urql/exchange-graphcache';

// import { auditsQuery } from './components/audits/audits_unused';
// import { auditWorkspaceQuery, claimListQuery } from './fragments';

// import { minifyIntrospectionQuery } from '@urql/introspection';
// import fatSchema from './schema.json';

import { claimQueryDoc } from './components/audit/queries/claim/claim.gql';
import schema from '../introspection.min.json';
import { AuditFinding } from './gql/graphql';

const invalidateQueries = (
  operationName: string,
  cache: Cache,
  invalidates: { field: string; args: any }[]
) =>
  invalidates.forEach(invalidate => {
    console.debug(
      '%c%s: Invaliding query: %s %O',
      'color: chartreuse; font-weight: bold;',
      operationName,
      invalidate.field,
      invalidate.args
    );
    cache.invalidate('Query', invalidate.field, invalidate.args);
  });

// Idea: introduce a function here to get the graphCache, that supports
// an 'observer' interface/arg that gets notified with read-only props
// when each member of keys/queries/mutations gets called, maybe at the end
// of each function, e.g. containing {request: {result, args, cache, info}, response}
// where request is the inputs, and response is optional, up to the writer (although
// we could introduce utils to wrap/return the values) to provide some detail on the
// update...  Or, we coule introduce classes, e.g. Query, Mutation, Key and internalize
// all updates wrapped with protected members that can be overridden to observe updates
// Purpose here would be primarily testing, but could also allow some interesting things,
// like the ability to colocate the cache function with the code it pertains to...
const graphCache: Partial<CacheExchangeOpts> = {
  // schema: minifyIntrospectionQuery(fatSchema.data),
  schema: schema as CacheExchangeOpts['schema'],
  keys: {
    NpiProvider: data => '' + data.npi,
    BatchClaimStateAggregates: data => null,
    BatchClaimStateSumAggregates: data => null,
    ReportAggregate: data => null,
    AuditFindingAggregates: data => null,
    AuditFindingSumAggregates: data => null,
    BatchClaimAggregates: data => null,
    BatchClaimSumAggregates: data => null,
    BucketClaimAggregates: data => null,
    // BucketClaimSumAggregates: data => null,
    AuditFindingReviewsConnection: data => null,
    BucketClaimsConnection: data => null,
    BatchClaimsConnection: data => null,
    BatchClaimLinesConnection: data => null,
    AuditFindingsConnection: data => null,
    ProviderUserFacilitiesConnection: data => null,
    SupportingDocumentation: data => null,
    //AuditorBucketTotalCount: data => null,
    // BucketClaimsTotalCount: data => null,
    // ClaimDocumentation: data => null,
    AuditFindingRuleType: data => {
      return '' + data!.type;
    },
    S3SignedUpload: data => null,
    // FileData: data => null,
    // ProcessedFile: data => null,
    FoxitCredentials: data => null,
    HistoryTimelineEvents: data => null,
    ClaimIntakeResponse: data => null,
    CsvRow: data => '' + data.alaRowId ?? data.headerRowId,
    ActiveStatistics: data => null,
    HistoricStatistics: data => null,
    LatestReview: data => null,
    Autodor: data => null,
    AutodorFinding: data => (data.id as string) ?? null,
    AutodorMetadata: data => null,
    MUEAuditRule: data => null,
    PTPAuditRule: data => null,
    AutodorSink: data => null,
    AuditRule: data => null,
    MUEAuditRuleRationale: data => null,
    NonBillableAuditRule: data => null,
    AskAutodorMatchedText: data => null,
    AskAutodorFileResponse: data => null,
    AskAutodorFileVectorsExistResponse: data => null,
    UserCognitoSettings: data => null,
  },
  resolvers: {
    Query: {
      auditorUser: (parent, args) => ({
        __typename: 'AuditorUser',
        id: args.id,
      }),
      auditorBucket: (parent, args) => ({
        __typename: 'AuditorBucket',
        id: args.id,
      }),
      auditFindingReview: (parent, args) => ({
        __typename: 'AuditFindingReview',
        id: args.id,
      }),
      auditFinding: (parent, args) => ({
        __typename: 'AuditFinding',
        id: args.id,
      }),
      finding: (parent, args) => ({
        __typename: 'Finding',
        id: args.id,
      }),
      batchClaimLine: (parent, args) => ({
        __typename: 'BatchClaimLine',
        id: args.id,
      }),
      batchClaim: (parent, args) => ({ __typename: 'BatchClaim', id: args.id }),
      bucketClaim: (parent, args) => ({
        __typename: 'BucketClaim',
        id: args.id,
      }),
      auditFindingReport: (parent, args) => ({
        __typename: 'AuditFindingReport',
        id: args.id,
      }),
    },
  },
  updates: {
    Mutation: {
      createAuditFindingReview: (result, args, cache, info) => {
        // console.log("----------- cache update createAuditFindingReview -------------", cache);
      },
      createAuditorBucket: (result, args, cache, info) => {
        // console.log("----------- cache update createAuditorBucket -------------", cache);
        // const { auditorBucket } = result.createAuditorBucket;
        // const afclFilter = { isActive: { equalTo: true } };
        // cache.updateQuery(
        //   {
        //     query: auditsQuery,
        //     variables: {
        //       afclFilter,
        //     },
        //   },
        //   data => {
        //     // add new bucket at the beginning of the array,
        //     // since buckets are ordered by most recently created
        //     if (!data) data = { auditorBuckets: { nodes: [] } };
        //     if (!data.auditorBuckets) data.auditorBuckets = { nodes: [] };
        //     if (!data.auditorBuckets.nodes) data.auditorBuckets.nodes = [];
        //     data.auditorBuckets.nodes.unshift(auditorBucket);
        //     return data;
        //   }
        // );
      },

      createIbLineFindings: (result, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createAuditFindings as any).auditFindings[0]
          ?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };
          // const batchClaimArgs = { batchClaimId: claimId };
          // const cl = cache.resolve('Query', 'claim', claimArgs);
          invalidateQueries(info.fieldName, cache, [
            // { field: 'claim', args: claimArgs },
            { field: 'batchClaim', args: claimArgs },
          ]);
          // const queryProps = cache.inspectFields({ __typename: 'Query' });
          // console.log(
          //   `%c----------- ${info.fieldName}: cache update invoked -------------\n` +
          //     '%O\n' +
          //     '----------- -------------------- -------------\n',
          //   'color: purple; font-weight: bold;',
          //   { result, args, cache, info, cl }
          // );
          return;
        }
        // console.log(`${info.fieldName}: no claim id found`, {
        //   result,
        //   args,
        //   cache,
        //   info,
        // });
      },

      createUbClaimLineFindings: (result, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createAuditFindings as any).auditFindings[0]
          ?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };
          // const batchClaimArgs = { batchClaimId: claimId };
          // const cl = cache.resolve('Query', 'claim', claimArgs);
          invalidateQueries(info.fieldName, cache, [
            // { field: 'claim', args: claimArgs },
            { field: 'batchClaim', args: claimArgs },
          ]);
          // const queryProps = cache.inspectFields({ __typename: 'Query' });
          // console.log(
          //   `%c----------- ${info.fieldName}: cache update invoked -------------\n` +
          //     '%O\n' +
          //     '----------- -------------------- -------------\n',
          //   'color: purple; font-weight: bold;',
          //   { result, args, cache, info, cl }
          // );
          return;
        }
        // console.log(`${info.fieldName}: no claim id found`, {
        //   result,
        //   args,
        //   cache,
        //   info,
        // });
      },

      createReviewedAuditFinding: (result: DataFields, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createReviewedAuditFinding as any)
          .reviewedAuditFinding?.auditFinding?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };
          // const cl = cache.resolve('Query', 'claim', claimArgs);
          invalidateQueries(info.fieldName, cache, [
            // { field: 'claim', args: claimArgs },
            { field: 'batchClaim', args: claimArgs },
          ]);
          //   console.log(
          //     `%c----------- ${info.fieldName}: cache update invoked -------------\n` +
          //       '%O\n' +
          //       '----------- -------------------- -------------\n',
          //     'color: purple; font-weight: bold;',
          //     { result, args, cache, info, cl }
          //   );
          return;
        }
        // console.log(`${info.fieldName}: no claim id found`, {
        //   result,
        //   args,
        //   cache,
        //   info,
        // });
      },

      versionAuditFindings: (result: DataFields, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimIds: string[] = (
          (result?.versionAuditFindings as any).auditFindings ?? []
        )
          .map((af: AuditFinding) => af.batchClaimId)
          .filter((id: string) => !!id);

        if (claimIds.length > 0 && claimIds[0]) {
          const claimArgs = { id: claimIds[0] };

          // const findingIds: string[] = (
          //   (result?.versionAuditFindings as any).auditFindings ?? []
          // )
          //   .map((af: AuditFinding) => af.id)
          //   .filter((id: string) => !!id);
          //
          // const fIdArgs = { id: findingIds[0] };
          //
          // const afByBcIdArgs = {
          //   batchClaimId: { in: { claimIds } },
          // };
          // const cl = cache.resolve('Query', 'claim', claimArgs);
          // const afs = cache.resolve('Query', 'auditFinding', fIdArgs);
          // const fs = cache.resolve('' + cl, 'findings', fIdArgs);
          //
          // const cqd = cache.readQuery({
          //   query: claimQueryDoc,
          //   variables: {
          //     id: claimIds[0],
          //   },
          // });
          //
          // const queryProps = cache.inspectFields({ __typename: 'Query' });
          // console.log(
          //   `%c----------- ${info.fieldName}: cache update invoked -------------\n` +
          //     '%O\n' +
          //     '----------- -------------------- -------------\n',
          //   'color: purple; font-weight: bold;',
          //   { result, args, cache, info, cl, afs, fs, queryProps, cqd }
          // );
          // cache.invalidate('Query', 'claim', { id: claimIds[0] });
          // cache.invalidate('Query', 'batchClaim', { id: claimIds[0] });
          invalidateQueries(info.fieldName, cache, [
            // { field: 'claim', args: claimArgs },
            { field: 'batchClaim', args: claimArgs },
          ]);
        }
      },

      /*      createAuditFindings: (result, args, cache, info) => {
        const claimId = (result?.createAuditFindings as any).auditFindings[0]
          ?.batchClaimId;
        console.log(
          `%c----------- ${info.fieldName}: cache update invoked -------------\n` +
            '%O\n' +
            '----------- -------------------- -------------\n',
          'color: orange; font-weight: bold;',
          { result, args, cache, info }
        );

        invalidateQueries(info.fieldName, cache, [
          // { field: 'claim', args: claimArgs },
          { field: 'batchClaim', args: { id: claimId } },
        ]);

        // when creating a new afcl, we need to update values in the cache accordingly
        // specifically, we need to update the batchClaimLine.auditFindings(filter: {...}) field
        // FIXME - need to better understand what this code is doing
        // and decide if we need this anymore
        // if (!result.createAuditFinding) {
        //   console.error('Cache: error with createAuditFinding');
        //   if ('updateAuditFinding' in result && !result.updateAuditFinding) {
        //     const {
        //       auditFinding: { id },
        //     } = result.updateAuditFinding;
        //     cache.invalidate({ __typename: 'AuditFinding', id });
        //   }
        //   return;
        // }
        // if ('updateAuditFinding' in result && !result.updateAuditFinding) {
        //   console.error('Cache: error with updateAuditFinding');
        //   // invalidate the newly created audit finding claim line if the update operation did not succeed as well
        //   // TODO add better docs
        //   const {
        //     auditFinding: { id },
        //   } = result.createAuditFinding;
        //   cache.invalidate({ __typename: 'AuditFinding', id });
        //   return;
        // }
        // // read the existing batchClaimLine from the cache
        // const { auditFinding } = result.createAuditFinding;
        // const auditorBucketId =
        //   auditFinding.batchClaim.bucketClaim.auditorBucket.id;
        // const { batchClaimId } = auditFinding;
        // const batchClaimFilter = {
        //   bucketClaimExists: true,
        //   bucketClaim: {
        //     auditorBucketId: { equalTo: auditorBucketId },
        //   },
        // };
        // const claimListQueryArgs = {
        //   batchClaimFilter,
        //   afclFilter: { isActive: { equalTo: true } },
        // };
        // if (auditFinding.isOriginal) {
        //   // Update the claim list audit findings per claim badges
        //   cache.updateQuery(
        //     {
        //       query: auditWorkspaceQuery,
        //       variables: claimListQueryArgs,
        //     },
        //     data => {
        //       if (!data) throw new Error('Cache Error');
        //       data.batchClaims.nodes.filter(
        //         ({ id }) => id === batchClaimId
        //       )[0].auditFindings.totalCount += 1;
        //       return data;
        //     }
        //   );
        // }
        // // FIXME
        // // somehow, the auditorBucket audit dollar value in the auditDetails component
        // // will not update with the new determined improper payment cost
        // // forcibly invalidating the cache seems to do the trick
        // cache.invalidate({ __typename: 'AuditorBucket', id: auditorBucketId });
      },
      */
    },
  },
};

export { graphCache };
