import Handlebars from 'handlebars';
import DOMPurify from 'dompurify';
import { get, isObject, isPlainObject, mapValues, set } from 'lodash';

const textOnlySanitizerConfig = {
  ALLOWED_TAGS: ['#text'],
};

const getSanitizedSubset = (
  object: Record<string, any>,
  keys: string[],
  sanitizerConfig: any
) => {
  const result = {};
  for (let k of keys) {
    const kval = get(object, k);
    set(
      result,
      k,
      kval && typeof kval === 'string'
        ? DOMPurify.sanitize(kval, sanitizerConfig)
        : kval
    );
  }
  return result;
};

export const applyTemplate = (
  template: string,
  object: Record<string, any>,
  omitHtml: boolean = true
) => {
  let _template: HandlebarsTemplateDelegate | null = null;
  try {
    _template = Handlebars.compile(DOMPurify.sanitize(template));
  } catch (e: any) {
    console.error(
      `applyHbsTemplate:  Failed to parse handlebars template ${template}`,
      e
    );
    return `Error parsing template: ${e?.message ?? e.toString()}`;
  }
  try {
    if (omitHtml) {
      const variables = getHandlebarsVariables(template);
      const subset = getSanitizedSubset(
        object,
        variables,
        textOnlySanitizerConfig
      );
      return DOMPurify.sanitize(_template(subset), textOnlySanitizerConfig);
    } else {
      return DOMPurify.sanitize(_template(object));
    }
  } catch (e: any) {
    console.error(
      `applyHbsTemplate:  Failed to parse handlebars template ${template}`,
      e
    );
    return `Error parsing template: ${e?.message ?? e.toString()}`;
  }
};

/**
 * Gets the variables used in the passed-in Handlebars template.
 * @param template
 * FROM: https://stackoverflow.com/users/1870884/tina / https://stackoverflow.com/questions/19800602/how-to-see-all-available-variables-in-handlebars-template
 */
export function getHandlebarsVariables(template: string): string[] {
  // n.b. the result returned here does not include variables used as helper input
  // but not emitted in the template
  try {
    const ast: hbs.AST.Program = Handlebars.parseWithoutProcessing(
      // probably unnecessary -- but why not...:
      DOMPurify.sanitize(template)
    );

    return ast.body
      .filter(({ type }: hbs.AST.Statement) => type === 'MustacheStatement')
      .map((statement: hbs.AST.Statement) => {
        const moustacheStatement: hbs.AST.MustacheStatement =
          statement as hbs.AST.MustacheStatement;
        const paramsExpressionList =
          moustacheStatement.params as hbs.AST.PathExpression[];
        const pathExpression =
          moustacheStatement.path as hbs.AST.PathExpression;

        return paramsExpressionList[0]?.original || pathExpression.original;
      });
  } catch (e) {
    console.error('getHandlebarsVariables parse error', e);
    return [];
  }
}
