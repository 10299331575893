import { graphql } from '../../gql';

/* strip types
 : .*$
*/

export const reportDownloadQueryDoc = graphql(`
  query reportDownloadQuery($reportId: UUID!) {
    auditFindingReport(id: $reportId) {
      id
      title
      downloadUrl
    }
  }
`);
