import { Button, Form, Input, Space, Tooltip } from 'antd';
import { AskAutodorPromptTemplateSelector } from './askAutodorPromptTemplates';
import { AskAutodorFileChooser } from './askAutodorFileChooser';
import React, {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useAskAutodor } from './useAskAutodor';
import { AuditDocumentation } from '../../../gql/graphql';
import { UiAskAutodorResponse } from './types';
const { TextArea } = Input;
import tw, { css } from 'twin.macro';
import { useLog } from '../../../common/log/useLog';

type AskAutodorFormProps = {
  document: AuditDocumentation | null;
  documents: AuditDocumentation[];
  setDocument: (document: AuditDocumentation) => void;
  responses: UiAskAutodorResponse[];
  setResponses: Dispatch<SetStateAction<UiAskAutodorResponse[]>>;
  // loading: boolean;
};

export const AskAutodorForm: FC<AskAutodorFormProps> = ({
  document,
  documents,
  setDocument,
  responses,
  setResponses,
  // loading,
}) => {
  const log = useLog('AskAutodorForm');
  const [templatePrompt, setTemplatePrompt] = useState('');
  const [form] = Form.useForm();
  const prompt = Form.useWatch('prompt', form);
  useEffect(() => {
    form.setFieldsValue({ prompt: templatePrompt });
  }, [templatePrompt]);

  const askAutodor = useAskAutodor({
    documentId: document?.id ?? '',
    prompt,
    responses,
    setResponses,
  });
  const onFinish = (values: any) => {
    log.debug('Form values:', values);
    log.debug('prompt', prompt);
    askAutodor.runQuery();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      size="small"
      initialValues={{ requiredMarkValue: 'required' }}
      disabled={askAutodor.loading}
      onFinish={onFinish}
    >
      <Form.Item
        css={css({
          ...tw`_my-1 _w-full _border _border-solid _border-fuchsia-50`,
          '& label': tw`_w-full`,
        })}
        name="prompt"
        labelCol={{ style: { width: '100%' } }}
        label={
          <div tw="_w-full _flex _gap-3 _border _border-solid _border-gray-50">
            <div tw="_whitespace-nowrap">Input a prompt:</div>
            <AskAutodorPromptTemplateSelector
              setTemplatePrompt={setTemplatePrompt}
            />
          </div>
        }
        // rules={[{ required: true, message: 'Please enter a prompt' }]}
      >
        <TextArea
          allowClear={true}
          placeholder="Ask a question, include specific details to 'prompt' AI to narrow the search..."
          rows={4}
        />
      </Form.Item>
      <div tw="_flex">
        <div
          title={`${document?.fileName} (${document?.id})`}
          tw="_text-xs _text-antd-lt-colorTextTertiary dark:_text-antd-lt-colorTextTertiary _flex-1 _self-auto _my-1 _mt-1.5"
        >
          {/*{document?.fileName ?? 'No document selected'}*/}
          <AskAutodorFileChooser
            documents={documents}
            document={document}
            setDocument={setDocument}
          ></AskAutodorFileChooser>
        </div>
        <Form.Item tw="_self-auto !_my-1 !_mt-1.5">
          <Tooltip
            title={
              askAutodor.currentPromptAnswered &&
              'This prompt already has a response below; modify the prompt to resubmit'
            }
          >
            <Button
              type="default"
              htmlType="submit"
              disabled={!prompt || askAutodor.currentPromptAnswered}
            >
              Ask!
            </Button>
          </Tooltip>
        </Form.Item>
      </div>
    </Form>
  );
};
