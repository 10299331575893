import { css } from '@emotion/react';

// This file is the content of the tailwindcss preflight (node_modules/tailwindcss/lib/css/preflight.css)
// wrapped in emotion's css() function -- it's here so that we can modify it (e.g. comment out settings that produce
// specificity problems) and import it locally as a class on a specific element/parent element
//
// Tailwind normally loads these resets -- however, they are opinionated and can conflict with component libs like antd
// which is our primary lib for most controls, so it's turned off in the tailwind config.  As a result we may need to add
// some of these settings (individually or as a whole) to classes to get the expected behavior -- for example, because
// tailwind's reset zeros margin/padding/borders, code copied from examples is often a couple pixels off without accounting
// for some of these resets...
//
// NOTE that the conversion below to js was a best-guess effort and validity of -webkit-blah-blah to camelcase may not be accurate...
// NOTE that commented styles were commented as they had too-high specificity here and/or didn't make sense for anticipated use
//
// We should investigate moving this into a tailwind layer, e.g. @component, where it would be easier to
// override as necessary... as a true preflight the specificity is low, but when added as a class the specificity is
// much higher so importing it this way may require !important on the child styles -- like button backgrounds...

export const tailwindPreflight = css({
  '& *, & *::before, & *::after': {
    boxSizing: 'border-box' /* 1 */,
    borderWidth: 0 /* 2 */,
    borderStyle: 'solid' /* 2 */,
    borderColor: "theme('borderColor.DEFAULT', currentColor)" /* 2 */,
  },

  '& *::before, & *::after': {
    '--tw-content': '',
  },

  '& *': {
    lineHeight: '1.5',
    WebkitTextSizeAdjust: '100%',
    MozTabSize: 4,
    tabSize: 4,
  },

  '& hr': {
    height: '0',
    color: 'inherit',
    borderTopWidth: '1px',
  },

  '& abbr:where([title])': {
    textDecoration: 'underline dotted',
  },

  '& h1, h2, h3, h4, h5, h6': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },

  '& a': {
    color: 'inherit',
    textDecoration: 'inherit',
  },

  '& b, & strong': {
    fontWeight: 'bolder',
  },

  '& small': {
    fontSize: '80%',
  },

  '& sub, & sup': {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
  },

  '& sub': {
    bottom: '-0.25em',
  },

  '& sup': {
    top: '-0.5em',
  },

  '& table': {
    textIndent: 0,
    borderColor: 'inherit',
    borderCollapse: 'collapse',
  },

  '& button, & input, & optgroup, & select, & textarea': {
    fontFamily: 'inherit',
    fontSize: '100%',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    // color: 'inherit',
    // margin: 0,
    // padding: 0,
    borderWidth: 0,
  },

  '& button, & select': {
    textTransform: 'none',
  },

  "& button, & [type='button'], & [type='reset'], & [type='submit']": {
    WebkitAppearance: 'button',
    // backgroundColor: 'transparent',
    // backgroundImage: 'none',
  },

  '& :-moz-focusring': {
    outline: 'auto',
  },

  '& :-moz-ui-invalid': {
    boxShadow: 'none',
  },

  '& progress': {
    verticalAlign: 'baseline',
  },

  '& ::WebkitInnerSpinButton, & ::WebkitOuterSpinButton': {
    height: 'auto',
  },

  "& [type='search']": {
    WebkitAppearance: 'textfield',
    outlineOffset: '-2px',
  },

  '& ::WebkitSearchDecoration': {
    WebkitAppearance: 'none',
  },

  '& ::WebkitFileUploadButton': {
    WebkitAppearance: 'button',
    font: 'inherit',
  },

  '& summary': {
    display: 'list-item',
  },

  '& blockquote, & dl, & dd, & h1, & h2, & h3, & h4, & h5, & h6, & hr, & figure, & p, & pre':
    {
      margin: 0,
    },

  '& fieldset': {
    margin: 0,
    padding: 0,
  },

  '& legend': {
    padding: 0,
  },

  '& ol, & ul, & menu': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  '& textarea': {
    resize: 'vertical',
  },

  '& input::placeholder, & textarea::placeholder': {
    opacity: 1,
    color: "theme('colors.gray.400', #9ca3af)",
  },

  "& button, & [role='button']": {
    cursor: 'pointer',
  },

  '& :disabled': {
    cursor: 'default',
  },

  '& img, & svg, & video, & canvas, & audio, & iframe, & embed, & object': {
    display: 'block',
    verticalAlign: 'middle',
  },

  '& img, & video': {
    maxWidth: '100%',
    height: 'auto',
  },

  '& [hidden]': {
    display: 'none',
  },
});
