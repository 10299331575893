import { Checkbox, Form, InputNumber, Tooltip } from 'antd';
import React from 'react';
import { isSet } from '../../../../util/isSet';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';

export type RepriceCheckboxProps = CommonFieldProps & {
  hoverText: string;
  disabled: boolean;
};

export const RepriceCheckbox: React.FC<RepriceCheckboxProps> = ({
  dataKey,
  label = 'Reprice?',
  hoverText,
  disabled,
  initialValues,
  formLayout,
  className,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<boolean>(
    dataKey,
    initialValues
  );
  return (
    <Tooltip title={hoverText}>
      <Form.Item
        {...formKey(dataKey)}
        {...formLayout}
        className={className}
        valuePropName="checked"
        initialValue={isSet(defaultValue) && defaultValue}
        tooltip={<div>{hoverText}</div>}
      >
        <Checkbox disabled={disabled}>{label}</Checkbox>
      </Form.Item>
    </Tooltip>
  );
};
