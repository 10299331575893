import { RefObject, useEffect, useState } from 'react';

export const useWaitForRef = <T>(
  ref: RefObject<T>,
  interval: number = 300,
  refReady: (ref: RefObject<T>) => boolean = ref => !!ref.current
) => {
  const [isRefLoaded, setIsRefLoaded] = useState(false);

  useEffect(() => {
    let ignore = false;
    new Promise(function (resolve) {
      (function waitOnCondition(cnt: number = 0) {
        if (refReady(ref)) {
          return resolve(cnt);
        }
        setTimeout(() => waitOnCondition(cnt), interval);
      })();
    }).then(cnt => {
      if (!ignore) {
        setIsRefLoaded(true);
      }
    });
    return () => {
      ignore = true;
    };
  }, [ref, interval, refReady]);

  return isRefLoaded;
};
