import { Form, InputNumber } from 'antd';
import { FormExtra } from '../../../../shared/components/form/formExtra';
import React from 'react';
import tw, { css } from 'twin.macro';
import { inputPropsCurrencyFormatter } from '../../../../util';
import { asMoney } from '../../../../util/format';
import { isSet } from '../../../../util/isSet';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';

export type ImproperPaymentCostProps = CommonFieldProps & {
  flagged?: boolean;
  addlExtraJsx?: JSX.Element;
  formLayout?: any;
};

export const ImproperPaymentCost: React.FC<ImproperPaymentCostProps> = ({
  dataKey,
  label = 'Adjustment Amount',
  initialValues,
  formLayout,
  flagged = false,
  addlExtraJsx = undefined,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<number>(
    dataKey,
    initialValues
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      css={css({
        ...(flagged
          ? {
              '& .ant-input-number': tw`!_border !_border-solid _border-antd-lt-colorWarningText dark:_border-antd-dk-colorWarningText`,
            }
          : {}),
      })}
      rules={[{ required: true, message: 'Enter the adjustment amount ($)' }]}
      extra={
        <FormExtra visible={isSet(existingFindingValue) || !!addlExtraJsx}>
          <div tw="_flex">
            <div tw="_min-w-[3em]">
              {isSet(existingFindingValue) && asMoney(existingFindingValue)}
            </div>
            <div tw="_pl-0.5">{addlExtraJsx}</div>
          </div>
        </FormExtra>
      }
      initialValue={isSet(defaultValue) && defaultValue}
    >
      <InputNumber style={{ width: '50%' }} {...inputPropsCurrencyFormatter} />
    </Form.Item>
  );
};
