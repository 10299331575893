import Handlebars from 'handlebars';
import DOMPurify from 'dompurify';
import { v5 } from 'uuid';
import htmlToReact from '../../../../../../common/html/util';
import { getHandlebarsVariables } from '../../../../../../common/templates/handlebarsTemplateUtil';

/**
 * HandlebarsUtil - not exported
 * this class is used as a singleton to hold compiled templates and
 * remain configured between invocations (e.g. helpers are registered once)
 * if cache size becomes an issue we may need to implement cache logic
 * or allow it to be instantiated and held by the caller for performance concerns
 */
class HandlebarsUtil {
  private hb = Handlebars;
  private templates: Record<string, any> = {};
  constructor(
    private sanitizeConfig: DOMPurify.Config & {
      RETURN_DOM_FRAGMENT?: false | undefined;
      RETURN_DOM?: false | undefined;
    } = { FORBID_ATTR: ['href'] }
  ) {
    console.debug('Creating dynamic templates singleton');
    this.registerHelpers();
  }

  private registerHelpers = () => {
    this.hb.registerHelper('isEqual', (a, b, options) => {
      return a == b;
    });
  };

  getAsString = (template: string, record: Record<string, any>): string => {
    const uuid = v5(template, 'b4ed6580-0d07-45be-b8c0-34e47fcd3ed3');
    if (!this.templates[uuid]) {
      console.debug('compiling template %s', uuid, {
        template,
        initialRecordKeys: Object.keys(record),
      });
      try {
        // sanitize the input template and cache the compiled result...
        this.templates[uuid] = this.hb.compile(DOMPurify.sanitize(template));
      } catch (e: any) {
        console.error(`Failed to parse dynamic template ${template}`, e);
        return `<div style='color: red'>Error parsing template: ${
          e?.message ?? e.toString()
        }</div>`;
      }
    }
    if (!this.templates[uuid]) {
      console.error(
        `Unexpected: Template not in cache but did not trigger compilation: ${template}`
      );
      return `<div style='color: red'>Error - unexpected: template is not in cache!</div>`;
    }

    try {
      // also sanitize the output content, just in case a template combines with record content to produce unsafe html
      return DOMPurify.sanitize(
        this.templates[uuid](record),
        this.sanitizeConfig
      );
    } catch (e: any) {
      console.error(`Failed to parse handlebars template ${template}`, e);
      return `<div style='color: red'>Error parsing template: ${
        e?.message ?? e.toString()
      }</div>`;
    }
  };

  getAsComponents = (
    template: string,
    record: Record<string, any>
  ): string | JSX.Element | JSX.Element[] =>
    htmlToReact(this.getAsString(template, record));

  getHandlebarsVariables = getHandlebarsVariables;
}

/**
 * Provides Handlebars compilation to HTML string or JSX elements, caching
 * templates as used; attempts to sanitize input strings using Dompurify.
 */
export const handlebarsUtil = new HandlebarsUtil();
