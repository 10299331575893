import { readFileSync } from 'fs';
export const mue = readFileSync(__dirname + '/../hbs/mueAuditRule.hbs', 'utf8');
export const ptp = readFileSync(__dirname + '/../hbs/ptpAuditRule.hbs', 'utf8');
export const nonBillable = readFileSync(
  __dirname + '/../hbs/nonBillableAuditRule.hbs',
  'utf8'
);

/*
    Purpose:
        Provides the stored HBS templates as string consts
        (this need may go away if/when we store these templates, e.g., in the db)
    Why?:
        Parcel recommends inlining code with `bundle-text:` transform, which works
        fine with parcel, e.g.:
            `import mue from 'bundle-text:./hbs/mueAuditRule.hbs';`
        However, we'd need to write a custom module mapper/resolver to use this with
        Jest, and further, bundle-text causes parcel to parse/build deps trees for the
        imported content, which would be useful in some cases but unnecessary here.

        These fs ops are in a .js file as placing them in a .ts file causes the
        parcel compile to fail with "_fs.readFileSync is not a function" -- most
        likely due to some esmModuleInterop settings (which need to be evaluated
        carefully before changing).  An improvement would be to get ts working, and
        import directly where needed -- however per the possibility of storing these
        as config artifacts (in the db, e.g.) it may not be worthwhile for this
        particular feature.

 */
