import React, { useEffect, useState } from 'react';
import { Modal, Select, Space } from 'antd';
import { gql, useMutation } from 'urql';
import { css } from '@emotion/react';

const onboardUserMutation = gql`
  mutation sendUserOnboardNotification(
    $userId: UUID!
    $emailTemplate: MessageSentInputEmailTemplateType!
  ) {
    sendUserOnboardNotification(
      input: { userId: $userId, emailTemplate: $emailTemplate }
    ) {
      messageCreatedId
      notificationKind
      notifications {
        messageSentId
        email {
          success
          response
          rejected
        }
        recipientUserEmail
        recipientUserId
      }
    }
  }
`;

const OnboardUserModal = ({
  user,
  visible,
  close,
  refresh,
  success,
  error,
}) => {
  const { userType } = user || {};
  const userIsExternal = ['PAYER', 'PROVIDER'].includes(userType);
  const defaultTemplate = userIsExternal
    ? 'ONBOARD_COMMERCIAL'
    : 'ONBOARD_INTERNAL';

  const [emailTemplate, setEmailTemplate] = useState(defaultTemplate);

  useEffect(() => {
    // update default if user changes (needed b/c parent renders component always)
    if (emailTemplate !== defaultTemplate) {
      setEmailTemplate(defaultTemplate);
    }
  }, [user]);

  const [{ fetching, error: onboardUserMutationError }, onboardUser] =
    useMutation(onboardUserMutation);

  return (
    <Modal
      title="Onboard User"
      style={{ padding: '15px' }}
      open={visible}
      onOk={async () => {
        const { data, error: onboardUserError } = await onboardUser({
          userId: user.id,
          emailTemplate,
        });

        if (onboardUserError) {
          console.log('User onboard Error', data, error);
          error(`Error During Onboarding! ${onboardUserError}`);
        } else {
          success('User Onboarded, Email Sent!');
          refresh();
          close();
        }
      }}
      onCancel={() => close()}
    >
      <Space
        direction="vertical"
        size="large"
        css={css({
          width: '100%',
        })}
      >
        <div>Send onboarding email to {user?.userType} user:</div>
        <div css={css({ fontWeight: 'bold' })}>
          {user?.firstName} {user?.lastName}
        </div>
        <div>
          <Space direction="horizontal" size="large">
            <span>Email Template:</span>
            <Select
              style={{ width: 120 }}
              onChange={setEmailTemplate}
              disabled={!userIsExternal}
              value={emailTemplate}
              options={[
                { label: 'Commercial', value: 'ONBOARD_COMMERCIAL' },
                { label: 'MDH', value: 'ONBOARD_MDH' },
                {
                  label: 'Internal',
                  value: 'ONBOARD_INTERNAL',
                  disabled: true,
                },
              ]}
            ></Select>
          </Space>
        </div>
      </Space>
    </Modal>
  );
};

export { OnboardUserModal };
