import {
  FilterChangedEvent,
  IStatusPanelParams,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { LineClampedCell } from '../cell/lineClampedCell';
import tw from 'twin.macro';
import { useEffect, useState } from 'react';
import { f } from 'msw/lib/glossary-de6278a9';

export const StatusBarRowCount = <T,>(props: IStatusPanelParams) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const dataChanged = (event: RowDataUpdatedEvent<T>) => {
    setTotalCount(() => event.api.getDisplayedRowCount() ?? 0);
  };

  const selectionChanged = (event: SelectionChangedEvent<T>) => {
    setSelectedCount(() => event.api.getSelectedNodes()?.length ?? 0);
  };

  const filterChanged = (event: FilterChangedEvent) => {
    setFilteredCount(() => event.api.getDisplayedRowCount() ?? 0);
  };

  useEffect(() => {
    props.api.addEventListener('rowDataUpdated', dataChanged);
    props.api.addEventListener('selectionChanged', selectionChanged);
    props.api.addEventListener('filterChanged', filterChanged);
    return () => {
      props.api.removeEventListener('rowDataUpdated', dataChanged);
      props.api.removeEventListener('selectionChanged', selectionChanged);
      props.api.removeEventListener('filterChanged', filterChanged);
    };
  }, []);

  return (
    <LineClampedCell>
      <span tw="_px-4 _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary">
        {selectedCount > 0 ? `${selectedCount} selected` : ''}
      </span>
      <span>
        {filteredCount > 0 ? (
          <>
            <span tw="_pr-0.5">{filteredCount}</span>/
            <span tw="_pl-0.5">{totalCount} displayed</span>
          </>
        ) : (
          `${totalCount} rows`
        )}
      </span>
    </LineClampedCell>
  );
};
