import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import { CreateUserModal } from './createUserModal';
import { OnboardUserModal } from './onboardUserModal';
import { useQuery, gql } from 'urql';

const query = gql`
  query getUsers {
    auditorUsers(orderBy: CREATED_AT_DESC) {
      nodes {
        id
        createdAt
        email
        userType
        firstName
        lastName
        provider {
          name
          id
        }
        insurerUsers {
          nodes {
            id
            auditorUserId
            insurerId
          }
        }
        providerUserFacilities {
          nodes {
            id
            provider {
              id
              name
            }
          }
        }
        userInviteCreateds(orderBy: CREATED_AT_DESC) {
          nodes {
            id
            messageId
            auditorUserId
          }
        }
        messageSentsByRecipientUserId(orderBy: CREATED_AT_DESC, first: 1) {
          nodes {
            id
            createdAt
          }
        }
      }
    }
  }
`;

const Users = () => {
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query,
    requestPolicy: 'network-only',
  });

  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [onboardModalVisible, setOnboardModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const successMessage = text => message.success(text, 15);
  const errorMessage = text => message.error(text, 15);

  useEffect(() => {
    console.log('refreshing....');
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [refresh]);

  const userColumns = [
    {
      title: 'Name',
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Type',
      dataIndex: 'userType',
    },
    {
      title: 'Facilities',
      render: ({ provider, providerUserFacilities }) => {
        if (provider) {
          return `${provider.name} (${provider.id})`;
        }
        if (providerUserFacilities) {
          return providerUserFacilities.nodes.map(
            ({ provider: { name, id } }) => `${name} (${id})\n\n`
          );
        }
        return '-';
      },
    },
    {
      title: 'Created',
      render: ({ createdAt }) => moment.utc(createdAt).format('MM/DD/YY'),
    },
    {
      title: 'Onboarded',
      render: ({ messageSentsByRecipientUserId }) =>
        messageSentsByRecipientUserId.nodes.length !== 0
          ? moment
              .utc(messageSentsByRecipientUserId.nodes[0].createdAt)
              .format('MM/DD/YY')
          : '-',
    },
    {
      title: 'Actions',
      render: user => (
        <div>
          <Button
            onClick={() => {
              setSelectedUser(user);
              setOnboardModalVisible(true);
            }}
          >
            Onboard
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Tooltip title="Create New User">
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={() => setCreateUserModalVisible(true)}
        />
      </Tooltip>
      <Table
        dataSource={data ? data.auditorUsers.nodes : []}
        columns={userColumns}
        loading={fetching}
        rowKey={'id'}
      />
      <CreateUserModal
        visible={createUserModalVisible}
        close={() => setCreateUserModalVisible(false)}
        refresh={() => setRefresh(true)}
      />
      <OnboardUserModal
        user={selectedUser}
        visible={onboardModalVisible}
        close={() => setOnboardModalVisible(false)}
        refresh={() => setRefresh(true)}
        success={successMessage}
        error={errorMessage}
      />
    </div>
  );
};

export { Users };
