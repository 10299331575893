import { Select } from 'antd';
import { css } from '@emotion/react';

/**
 * Renders a multiselect list for a list of columns in a table.
 * Takes a useState() output to reset the table based on what columns
 * are selected
 * @params columns - [<Antd.Column>] array of all of the columns that can be chosen
 * @params selectedColumns - [columnKeys] and array of column keys that are selected
 * @params disabledColumns - [columnKeys] and array of column keys that cannot be changed in the UI
 * @params setSelectedColumns - useState.set_ function to set selected columns
 */
const TableColumnSelector = ({
  columns,
  selectedColumns,
  disabledColumns = [],
  setSelectedColumns,
}) => (
  <Select
    css={css`
      width: 200px;
    `}
    virtual={false}
    mode={'multiple'}
    showArrow={true}
    value={selectedColumns}
    onChange={cols => setSelectedColumns(cols)}
    maxTagCount={0}
    maxTagPlaceholder={`${selectedColumns.length} Columns Selected`}
    data-cy={'table-col-selector'}
  >
    {columns.map(({ title, key }) => {
      const _title = title?.props?.title || title || key; // if component try input prop
      return (
        <Select.Option
          title={_title}
          value={key}
          key={key}
          disabled={disabledColumns.includes(key)}
          data-cy={'table-col-selector-opt_' + key}
        >
          {_title}
        </Select.Option>
      );
    })}
  </Select>
);

export default TableColumnSelector;
