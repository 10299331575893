import { Typography } from 'antd';
import { AskAutodorIcon } from './askAutodorIcon';
const { Text } = Typography;

import tw from 'twin.macro';
import { css } from '@emotion/react';

export const AskAutodorLogo = () => (
  <div tw="_flex-1">
    <AskAutodorIcon></AskAutodorIcon>

    <Text tw="_px-2.5">Ask Autodor</Text>
  </div>
);
