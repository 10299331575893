import React, { FC, useContext } from 'react';
import { DragModal } from '../../../../../common/modal/dragModal';
import { FindingForm } from './findingForm';
import { FindingContext } from '../findingProvider';

export const FindingModal: FC<{ open: boolean }> = ({ open }) => {
  const { findingEditor } = useContext(FindingContext);
  return (
    <DragModal
      open={open}
      close={findingEditor.hideModal}
      modalProps={{ title: findingEditor.title, footer: null }}
    >
      {findingEditor.isReady() ? (
        <FindingForm
          onCancel={() => findingEditor.hideModal()}
          findingEditor={findingEditor}
        ></FindingForm>
      ) : (
        <div>Finding editor is not configured</div>
      )}
    </DragModal>
  );
};
