import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import tw from 'twin.macro';
import { Spin } from 'antd';

export const LoadingOverlay = (
  props: ICellRendererParams & { loadingMessage: string }
) => {
  return (
    <Spin tip={props.loadingMessage} size="large">
      <div
        className="content"
        tw="_p-40 _w-2/3 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary"
      />
    </Spin>
  );
};
