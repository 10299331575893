import React, { useCallback, useContext, useMemo } from 'react';
import { Table, Button, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { MatchedTextDisplay } from './types';
import { useMatchedTextDisplayTypes } from './useAskAutodor';
import {
  AskAutodorMatchedText,
  AuditDocumentation,
} from '../../../gql/graphql';
import { PdfViewerContext } from '../../../common/pdf/pdfViewerProvider';
import { createPortal } from 'react-dom';
import { useLog } from '../../../common/log/useLog';
import { auditDocToUiAuditDoc } from '../../documentViewer/documentViewerUtil';

const clsRow = css(tw`_p-0 _m-0`);
const CellComponent: React.FC<any> = ({ ...props }) => (
  <td {...props} tw="!_p-2.5 _m-0">
    {props.children}
  </td>
);

function columns(
  setDocumentationViewerProps: Function,
  claimId: string,
  documents: AuditDocumentation[]
): ColumnsType<MatchedTextDisplay> {
  return [
    {
      title: 'Info',
      dataIndex: 'pageNumber',
      key: 'pageNumber',
      width: '5em',
      render: (_, record) => {
        const fileNameOfMatchedDocument =
          record?.matchedDocument?.fileName || null;
        const idOfMatchedDocument = record?.documentId || null;
        return (
          <div tw="_text-xs _p-1 _px-0 _m-0">
            <div tw="_flex">
              <Tooltip
                placement="left"
                title={`Jump to page ${record.pageNumber} in ${
                  idOfMatchedDocument || 'the document'
                } being referenced here`}
              >
                <Button
                  shape="circle"
                  size="small"
                  type="link"
                  onClick={(e: any) => {
                    e.preventDefault();
                    setDocumentationViewerProps({
                      openToSpecificFileByFileName: idOfMatchedDocument, // if no file returned it will be request the 1st file in list of files
                      openToSpecificPage: record.pageNumber,
                      visible: true,
                      variables: {
                        claimId,
                      },
                    });
                  }}
                >
                  pp. {record.pageNumber}
                </Button>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    Table.EXPAND_COLUMN,
    // {
    //   title: 'Text',
    //   dataIndex: 'text',
    //   key: 'text',
    //   render: (_text, record) => (
    //     <div tw="_flex _flex-col _space-x-4">
    //       <div tw="_text-xs _line-clamp-2 _mb-1.5">{record.text}</div>
    //       <div tw="_text-[0.70em] _font-light _absolute  _bottom-0.5 _right-1 _text-antd-lt-colorTextSecondary">
    //         {documents?.find(d => d.id === record?.documentId)?.fileName ??
    //           'Unknown document'}
    //       </div>
    //     </div>
    //   ),
    // },
  ];
}

export type ResultTableProps = {
  matches: AskAutodorMatchedText[];
  setDocumentationViewerProps: Function;
  claimId: string;
  documents: AuditDocumentation[];
};

export const AskAutodorResultTable: React.FC<ResultTableProps> = ({
  matches,
  setDocumentationViewerProps,
  claimId,
  documents,
}) => {
  /*
  {
                      openToSpecificFileByFileName: idOfMatchedDocument, // if no file returned it will be request the 1st file in list of files
                      openToSpecificPage: record.pageNumber,
                      visible: true,
                      variables: {
                        batchClaimId,
                      },
                    }
   */

  const log = useLog('askAutodorResultTable');
  const pdfViewer = useContext(PdfViewerContext);
  // const [navEl, setNavEl] = React.useState<HTMLElement | null>(null);
  const setDocumentationViewerPropsNew = useCallback(
    (props: any) => {
      const viewer = pdfViewer.getViewer('documentViewer');

      const _doc = documents?.find(
        d => d.id === props?.openToSpecificFileByFileName
      );
      const doc = _doc ? auditDocToUiAuditDoc(_doc, claimId) : null;

      log.trace('doc visible', viewer.isOpen, props, doc);

      if (doc) {
        viewer.setIsOpen(true, claimId);
        viewer
          .loadDocument({
            url: doc.signedUrl,
            filename: doc.fileName,
            fileSize: doc.size,
            documentId: doc.id,
            expiresMs: doc.expiresMs,
          })
          .then(result => {
            log.trace(
              'got doc loaded result, setting page - loaded result:',
              result
            );
            viewer.setPage(props?.openToSpecificPage);
          })
          .catch(err => {
            log.error('Error loading pdf doc', err);
          });
      }
    },
    [documents, pdfViewer, matches]
  );

  const keyedMatches: MatchedTextDisplay[] =
    useMatchedTextDisplayTypes(matches);
  const cols = columns(setDocumentationViewerPropsNew, claimId, documents);
  return (
    <>
      <Table
        showHeader={false}
        pagination={false}
        columns={cols}
        components={{ body: { cell: CellComponent } }}
        expandable={{
          // showExpandColumn: false,
          // expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) => (
            <div
              onClick={e => onExpand(record, e)}
              tw="_flex _flex-col _space-x-4 _text-start"
            >
              <div tw="_text-xs _line-clamp-2 _mb-1.5">{record.text}</div>
              <div tw="_text-[0.70em] _font-light _absolute  _bottom-0.5 _right-1 _text-antd-lt-colorTextSecondary">
                {documents?.find(d => d.id === record?.documentId)?.fileName ??
                  'Unknown document'}
              </div>
            </div>
          ),
          // expanded ? (
          //   <div onClick={e => onExpand(record, e)}>yo</div>
          // ) : (
          //   <div onClick={e => onExpand(record, e)}>uh</div>
          // ),
          expandedRowRender: record => (
            <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
              {record.textMatched}
            </p>
          ),
          rowExpandable: record => true,
        }}
        dataSource={keyedMatches}
      />
      {/*{!!navEl &&*/}
      {/*  createPortal(*/}
      {/*    <div*/}
      {/*      style={{ width: '200px' }}*/}
      {/*      tw="_w-64 _h-64 _bg-amber-300 _text-red-400 _min-w-fit"*/}
      {/*    >*/}
      {/*      {' '}*/}
      {/*      or that one...*/}
      {/*    </div>,*/}
      {/*    navEl*/}
      {/*  )}*/}
    </>
  );
};
