import React from 'react';
import { gql, useMutation } from 'urql';
import { Col, Row, Button, Tooltip, Space, Tag, Collapse } from 'antd';
import {
  CloseCircleOutlined,
  EditOutlined,
  CalendarTwoTone,
  CheckCircleOutlined,
} from '@ant-design/icons';
import accounting from 'accounting-js';
import { dateString, confidenceToString } from '../../util';
import { UserContext } from '../../../context/user';

import {
  auditFindingWithReviewFragment,
  reviewedAuditFindingFragment,
  adminAndAuditorWorkstationBatchClaimFragment,
} from '../../../../fragments';

const createReviewedAuditFindingMutation = gql`
  mutation createReviewedAuditFinding(
    $reviewedAuditFinding: ReviewedAuditFindingInput!
  ) {
    createReviewedAuditFinding(
      input: { reviewedAuditFinding: $reviewedAuditFinding }
    ) {
      reviewedAuditFinding {
        ...reviewedAuditFindingFragment
        auditFinding {
          ...auditFindingWithReviewFragment
          # update determined and potential amounts in the cache
          batchClaim {
            # because ONLY auditors & Admins can manipluate findings (Create, Decline, Review, etc) we don't select the return fragment based on users
            # and return the 'adminAndAuditorWorkstationBatchClaimFragment' by default
            ...adminAndAuditorWorkstationBatchClaimFragment
            auditFindingCount: auditFindings(
              filter: { isActive: { equalTo: true } }
            ) {
              totalCount
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
  ${reviewedAuditFindingFragment}
  ${auditFindingWithReviewFragment}
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

const UBClaimAuditFinding = props => {
  const {
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    ubClaimFindings,
    batchClaim,
    afclFilter,
    readOnly,
    setViewingClaimLevelFinding,
  } = props;

  const { id: authorId, permissions } = React.useContext(UserContext);

  const [{ fetching }, createReviewedAuditFinding] = useMutation(
    createReviewedAuditFindingMutation
  );

  return (
    <div data-cy="claim-level-audit-finding-container">
      {ubClaimFindings.nodes.map(originalAuditFinding => {
        const auditFinding = originalAuditFinding.reviews.nodes.length
          ? {
              ...originalAuditFinding.reviews.nodes[0].auditFinding,
              original: {
                auditFinding: originalAuditFinding,
              },
            }
          : originalAuditFinding;

        const reviewed =
          auditFinding.reviewedAuditFinding &&
          !auditFinding.reviewedAuditFinding.deleted &&
          auditFinding.reviewedAuditFinding;
        const canCreateFindings =
          permissions.findingsActions.includes('create') ||
          permissions.findingsActions.includes('decline') ||
          permissions.findingsActions.includes('review') ||
          permissions.findingsActions.includes('dispute');
        return (
          <div key={originalAuditFinding.id}>
            <Row
              style={{
                marginTop: '1%',
                marginRight: '1%',
                marginBottom: '1%',
                marginLeft: '1%',
              }}
              justify="space-between"
              gutter={[0, 16]}
            >
              <Col span={4}></Col>
              <Col
                style={{
                  border: '1px solid #ff4d4f',
                  paddingTop: '10px',
                  paddingRight: '10px',
                  paddingBottom: '10px',
                  paddingLeft: '10px',
                  borderRadius: '9px',
                }}
                span={16}
              >
                <Row style={{ marginTop: '10px' }}>
                  <Col span={8}></Col>
                  <Col
                    style={{ display: 'flex', justifyContent: 'center' }}
                    span={8}
                  >
                    <h4>Claim Level Review Finding</h4>
                  </Col>
                  <Col
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    span={8}
                  >
                    {(!reviewed || !reviewed.accepted) && (
                      <Tooltip title="Accept">
                        <Button
                          data-cy="claim-level-audit-finding-btn-accept"
                          type={'primary'}
                          size="small"
                          style={
                            readOnly
                              ? ''
                              : {
                                  backgroundColor: '#52c41a',
                                  borderColor: '#52c41a',
                                }
                          }
                          disabled={!canCreateFindings || readOnly}
                          icon={
                            <CheckCircleOutlined
                              style={{ color: 'white' }}
                              onClick={event => {
                                // If you don't want click extra trigger collapse, you can prevent this:
                                event.stopPropagation();
                                // create an reviewedAuditFinding
                                createReviewedAuditFinding({
                                  reviewedAuditFinding: {
                                    auditFindingId: auditFinding.id,
                                    authorId,
                                    accepted: true,
                                  },
                                  afclFilter,
                                });
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    )}
                    {(!reviewed || reviewed.accepted) && (
                      <Tooltip title="Decline">
                        <Button
                          data-cy="claim-level-audit-finding-btn-decline"
                          size="small"
                          disabled={!canCreateFindings || readOnly}
                          type={'primary'}
                          danger={!readOnly}
                          icon={
                            <CloseCircleOutlined
                              style={{ color: 'white' }}
                              onClick={event => {
                                setDecliningAuditFinding({
                                  visible: true,
                                  auditFinding,
                                });
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    )}

                    <Tooltip title="Review">
                      <Button
                        data-cy="claim-level-audit-finding-btn-review"
                        type={'primary'}
                        size="small"
                        disabled={!canCreateFindings || readOnly}
                        style={{ marginLeft: '5px' }}
                        icon={
                          <EditOutlined
                            onClick={event => {
                              setCreatingAuditFinding({
                                visible: true,
                                title: 'Review Finding',
                                auditFinding,
                                auditFindingSeedType:
                                  auditFinding.auditFindingSeedType,
                              });
                            }}
                          />
                        }
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align="center">
                    <Tag>{batchClaim.claimType}</Tag>
                    {/* FIXME - duplicate code from claimWorkspaceAuditFinding */}
                    {batchClaim.claimType === 'Inpatient Hopital Claim' &&
                    batchClaim.dateAdmit ? (
                      <Tooltip
                        title={`Date Admitted${
                          batchClaim.dateDischarge ? ` - Date Discharged` : ''
                        }`}
                      >
                        <Space>
                          <CalendarTwoTone />
                          {dateString(
                            batchClaim.dateAdmit,
                            batchClaim.dateDischarge
                          )}
                        </Space>
                      </Tooltip>
                    ) : (
                      <Tooltip title={`Dates of Service`}>
                        <Space>
                          <CalendarTwoTone twoToneColor="red" />
                          {dateString(
                            batchClaim.dateOfServiceStart,
                            batchClaim.dateOfServiceEnd
                          )}
                        </Space>
                      </Tooltip>
                    )}
                  </Col>
                </Row>
                <Row
                  justify="space-around"
                  style={{
                    marginTop: '1%',
                    marginRight: '1%',
                    marginBottom: '1%',
                    marginLeft: '1%',
                  }}
                  gutter={[0, 16]}
                >
                  <Col span={12}>
                    <div
                      data-cy="claim-level-audit-finding-adjustmentAmount"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Adjustment Amount:{' '}
                      {accounting.formatMoney(auditFinding.improperPaymentCost)}
                    </div>
                  </Col>

                  <Col span={12}>
                    <div
                      data-cy="claim-level-audit-finding-discrepantQuantity"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      Discrepant Quantity:{' '}
                      {auditFinding.improperPaymentUnitsCharged}
                    </div>
                  </Col>
                </Row>
                <Row
                  justify="space-around"
                  style={{
                    marginTop: '1%',
                    marginRight: '1%',
                    marginBottom: '1%',
                    marginLeft: '1%',
                  }}
                  gutter={[0, 16]}
                >
                  <Col span={12}>
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      data-cy="claim-level-audit-finding-allowedAmount"
                    >
                      Allowed Amount:{' '}
                      {accounting.formatMoney(batchClaim.amountReimbursed)}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ display: 'flex', justifyContent: 'center' }}
                      data-cy="claim-level-audit-finding-billedAmount"
                    >
                      Billed Amount:{' '}
                      {accounting.formatMoney(batchClaim.amountCharged)}
                    </div>
                  </Col>
                </Row>
                <Collapse
                  id="ub_claim_audit_finding_type"
                  style={{
                    marginTop: '2%',
                    marginRight: '2%',
                    marginBottom: '2%',
                    marginLeft: '2%',
                  }}
                  accordion
                >
                  <Collapse.Panel
                    id="ub_claim_audit_finding_reason"
                    header={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{ float: 'left' }}
                          data-cy="claim-level-audit-finding-description"
                        >
                          {
                            auditFinding
                              .auditFindingRuleTypeByAuditFindingRuleType
                              .description
                          }
                        </span>
                        <span
                          data-cy={'claim-level-audit-finding-status-tags'}
                          style={{ marginLeft: '170px' }}
                        >
                          {auditFinding.reviewedAuditFinding ? (
                            auditFinding.reviewedAuditFinding.accepted ? (
                              <Tag color="green">Accepted</Tag>
                            ) : (
                              <Tag color="lightgray">Declined</Tag>
                            )
                          ) : (
                            <Tag color="red">Needs Review</Tag>
                          )}
                          <Tag>
                            {confidenceToString(auditFinding.confidence)}
                          </Tag>
                        </span>
                      </div>
                    }
                  >
                    <span data-cy="claim-level-audit-finding-improperPaymentReason">
                      {auditFinding.improperPaymentReason}
                    </span>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col span={4}>
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                ></div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export { UBClaimAuditFinding };
