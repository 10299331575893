// external
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Global, css } from '@emotion/react';
import { useQuery } from 'urql';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { formatMoney } from 'accounting-js';

// component library
import { Spin, Table, Typography, Tag, Tooltip, Button } from 'antd';

// internal
import { UserContext } from '../context/user';
import TableColumnSelector from '../misc/tableColumnSelector';
import { VersionReportButton } from '../reports/versionReportButton';

import { ColTitle, formatDateRange } from '../queues/util';

const clsReportSubtype = css({
  color: 'darkgray',
  fontSize: '.85em',

  '& .ant-tag': {
    minWidth: '44px',
    height: '1.8em',
    lineHeight: '1.8em',
    fontSize: '.9em',
    margin: '1px 2px',
  },
});

const clsReportType = css({
  color: 'darkgray',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-tag': { minWidth: '44px', margin: '2px' },
});

const Reports = () => {
  const history = useHistory();

  const { Title } = Typography;
  const {
    reports: { reportFilter, columns: reportColumns },
  } = useContext(UserContext);

  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });

  const [{ fetching, data, error }, refreshQuery] = useQuery({
    query: gql`
      query reports(
        $reportFilter: AuditFindingReportFilter
        $first: Int!
        $offset: Int
      ) {
        auditFindingReports(
          filter: $reportFilter
          first: $first
          offset: $offset
          orderBy: CREATED_AT_DESC
        ) {
          totalCount
          nodes {
            id
            title
            createdAt
            auditFindingReportType
            auditFindingReportSubtype
            determinedImproperPayments {
              count
              sum
              startDate
              endDate
            }
            primaryPia {
              id
              firstName
              lastName
            }
            author {
              id
              firstName
              lastName
            }
            # FIXME this needs to sum only for claim line amount charges that have audit findings!!!
            batchClaims {
              totalCount
              nodes {
                id
                icn
              }
              aggregates {
                sum {
                  amountReimbursed
                  amountCharged
                }
              }
            }
            reportedFindings {
              totalCount
            }
            provider {
              name
              id
              npi
            }
            insurer {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      reportFilter: { ...reportFilter },
      ...paginationArgs,
    },
  });

  const paginationConfig = {
    current: currentPageNumber,
    position: ['bottomLeft'],
    pageSizeOptions: ['10', '25', '30', '50'],
    showSizeChanger: true,
    total: data && data.auditFindingReports.totalCount,
    onChange: (nextPage, pageSize) => {
      setPaginationOptions({ pageSize });
      setPaginationArgs({
        first: pageSize,
        offset:
          nextPage > 1 ? (nextPage - 1) * paginationOptions.pageSize : null,
      });
      setCurrentPageNumber(nextPage);
      refreshQuery();
    },
    ...paginationOptions,
  };

  const columns = [
    {
      title: (
        <ColTitle tooltipTitle="Report Title" title="Title" id="report-title" />
      ),
      default: true,
      key: 'title',
      dataIndex: 'title',
      render: title => {
        return <span data-cy="report-list-title-cell"> {title}</span>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Type of Report"
          title="Report Type"
          id="report-type"
        />
      ),
      default: true,
      key: 'auditFindingReportType',
      dataIndex: 'auditFindingReportType',
      render: (_, record) => {
        const { auditFindingReportType, auditFindingReportSubtype } = record;
        // todo extract tag colors -- perhaps both these columns for use in each place
        return (
          <div css={clsReportType}>
            <div>
              <Tag
                color={
                  auditFindingReportType === 'EOA'
                    ? 'blue'
                    : auditFindingReportType === 'IFR'
                      ? 'purple'
                      : 'yellow'
                }
              >
                {auditFindingReportType}
              </Tag>
            </div>
            <div css={clsReportSubtype}>
              {auditFindingReportType !== 'EOA' &&
                auditFindingReportSubtype !== 'PROCESSED' && (
                  <Tag color={'lightgray'}>
                    {auditFindingReportType === 'EOA' ||
                    auditFindingReportSubtype === 'PROCESSED'
                      ? ''
                      : auditFindingReportSubtype?.charAt(0).toUpperCase() +
                        auditFindingReportSubtype?.substring(1).toLowerCase()}
                  </Tag>
                )}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Author of Report"
          title="Author"
          id="report-author"
        />
      ),
      default: true,
      key: 'author',
      dataIndex: 'author',
      render: author => {
        return <Tag> {`${author.firstName} ${author.lastName}`}</Tag>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Total Billed Amount in Report"
          title="Total Billed Amount"
          id="total-report-billed-amount"
        />
      ),
      default: true,
      key: 'totalBilledAmount',
      dataIndex: 'batchClaims',
      render: ({ aggregates }) => {
        return formatMoney(aggregates.sum.amountCharged);
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Total Allowed Amount in Report"
          title="Total Allowed Amount"
          id="total-report-allowed-amount"
        />
      ),
      default: true,
      key: 'totalAllowedAmount',
      dataIndex: 'batchClaims',
      render: ({ aggregates }) => {
        return formatMoney(aggregates.sum.amountReimbursed);
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Total Client Savings on Report"
          title="Total Client Savings"
          id="total-adjustment-amount-cost"
        />
      ),
      default: true,
      key: 'totalDeterminedImproperPayments',
      dataIndex: 'determinedImproperPayments',
      render: determinedImproperPayments => {
        return formatMoney(determinedImproperPayments.sum);
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Date the latest version of report was created"
          title="Creation Date"
          id="report-created-at"
        />
      ),
      default: true,
      key: 'reportCreatedAt',
      dataIndex: 'createdAt',
      render: createdAt => {
        return <div>{moment(createdAt).utc().format('MM/DD/YYYY')}</div>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Primary PIA for Report"
          title="Primary PIA"
          id="report-primary-pia"
        />
      ),
      default: true,
      key: 'reportPrimaryPia',
      dataIndex: 'primaryPia',
      render: primaryPia => {
        return <Tag> {`${primaryPia.firstName} ${primaryPia.lastName}`}</Tag>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Total number of findings within the report"
          title="Findings Count"
          id="total-number-of-findings-in-report"
        />
      ),
      default: true,
      key: 'reportedFindingsCount',
      dataIndex: 'reportedFindings',
      render: ({ totalCount }) => {
        return <span> {totalCount}</span>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Number of Claims in Report"
          title="Claims Count"
          id="number-of-claims"
        />
      ),
      default: true,
      key: 'numberOfClaims',
      dataIndex: 'batchClaims',
      render: batchClaims => {
        return <span>{batchClaims.totalCount}</span>;
      },
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Insurer of the Report"
          title="Insurer"
          id="report-insurer"
        />
      ),
      default: true,
      key: 'insurer',
      dataIndex: 'insurer',
      render: ({ name, id }) => (
        <Tooltip title={`ID: ${id}`}>
          <div
            key={`claim-list-provider-name-cell-${name}`}
            id={`claim-list-provider-name-cell-${name}`}
          >
            {name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="Provider on Report's name, ID, & NPI number"
          title="Provider"
          id="report-provider"
        />
      ),
      default: true,
      key: 'provider',
      dataIndex: 'provider',
      render: ({ name, id, npi }) => (
        <Tooltip title={`ID: ${id}, NPI: ${npi}`}>
          <div
            key={`claim-list-provider-name-cell-${npi}`}
            id={`claim-list-provider-name-cell-${npi}`}
          >
            {name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        <ColTitle
          tooltipTitle="First & last date of the claims in the report"
          title="Claim Date Range"
          id="claim-date-range"
        />
      ),
      default: true,
      key: 'claimDateRange',
      dataIndex: 'determinedImproperPayments',
      render: ({ startDate, endDate }) => (
        <div
          key={`report-date-of-service-cell`}
          id={`report-date-of-service-cell`}
        >
          {formatDateRange(startDate, endDate)}
        </div>
      ),
    },
    {
      title: <ColTitle title="Actions" id="reports-list-col-version-report" />,
      default: true,
      key: 'reportActions',
      render: auditFindingReport => (
        <VersionReportButton
          auditFindingReport={auditFindingReport}
          refreshFunction={() =>
            refreshQuery({ requestPolicy: 'network-only' })
          }
        />
      ),
    },
  ].filter(({ key }) => {
    return reportColumns.includes(key);
  });

  // state for the selected columns - filter columns by 'default'
  // to determine whether this column should show when the page loads.
  // This state is an array of column.key values
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter(col => col.default).map(i => i.key)
  );

  return (
    <div>
      {fetching ? (
        <Spin />
      ) : (
        <Table
          title={() => (
            <div>
              <span>
                <Title
                  level={4}
                  css={css`
                    margintop: 10px;
                    marginbottom: 10px;
                    float: left;
                  `}
                >
                  Reports
                </Title>
              </span>
              <span
                css={css`
                  padding-left: 1%;
                `}
              >
                <TableColumnSelector
                  columns={columns}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </span>
            </div>
          )}
          dataSource={data.auditFindingReports.nodes.map((node, i) => {
            node.key = `reported-findings-table-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
            return node;
          })}
          loading={fetching}
          columns={columns.filter(({ key }) => selectedColumns.includes(key))}
          pagination={paginationConfig}
          onRow={report => {
            return {
              onDoubleClick: () => {
                history.push(
                  `/claims/${report.title} Report/${JSON.stringify({
                    claimNumber: report.batchClaims.nodes.map(({ icn }) => icn),
                  })}`
                );
              },
            };
          }}
        />
      )}
    </div>
  );
};

export { Reports };
