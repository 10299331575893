import { Draft, produce, setAutoFreeze } from 'immer';
import { cloneDeep, get, set } from 'lodash';

//setAutoFreeze(false);  // it's possible to return non-read-only types but wouldn't make sense here

// Improvements/thoughts:
//  1. see typing file for options for the property typing -- can we type the
//     generic actions with this based on state type?
//  2. we might want multiple of these in a class, e.g. different state slices
//     that trigger render separately, consider repackaging
//  3. wrapping dispatch, we could run the reducer twice on two immer drafts,
//     and return the first immediately/synchronously to the caller, so it's state
//     is updated instantly, but this has some implications for the order of ops
//     when communication is bi-di, e.g. when the state listener might send back
//     an update based on outdated info b/c the value has already changed...
//     (the advantage tho is the two would have eventual consistency but the
//     caller could use the value without react batching delay...)
//  4. we can simplify the interface, e.g. per the 'multiple' statement above,
//     maybe this all can:
//        ** expose a 'create' function that returns a 'state obj'
//        ** createState<T>(state:T, reactReducer?: ReturnType<useReducer<T>>) =>
//          {state, setState( dottedPath: {(e.g. keyof nested T props)},
//          value: {(type of dottedPath's target)} ), onStateChange: Observable<T>

export type StaticAction<T> = {
  type: 'static';
  path: string;
  value: T;
};

export type FunctionAction<S, T> = {
  type: 'fn';
  path: string;
  fn: (prev: T, fullState: S) => T;
};

export type GenericAction<S, T> = StaticAction<T> | FunctionAction<S, T>;

export class Action<T> implements StaticAction<T> {
  readonly type = 'static';
  constructor(
    public readonly path: string,
    public readonly value: T
  ) {}
}

export class ActionFunction<S, T> implements FunctionAction<S, T> {
  readonly type = 'fn';
  constructor(
    public readonly path: string,
    public readonly fn: (prev: T, fullState: S) => T
  ) {}
}

export const isStaticAction = <S, T>(
  action: GenericAction<S, T>
): action is StaticAction<T> => action.type === 'static';
export const isFunctionAction = <S, T>(
  action: GenericAction<S, T>
): action is FunctionAction<S, T> => action.type === 'fn';

export const createReducer = <StateType extends Object>(
  setter: (
    state: StateType,
    actionPath: string,
    action: GenericAction<StateType, any>
  ) => void
) => {
  const callSetter = <T>(
    newState: StateType,
    action: GenericAction<StateType, T>
  ) => {
    setter(newState, action.path, action);
  };

  return <T>(state: StateType, action: GenericAction<StateType, T>) => {
    // The idea (lodash set + immer) inspired by this article:
    // https://itnext.io/handling-complex-form-state-using-react-hooks-45370515e47
    // Actions are not strongly typed here, but the setState function provided by useClassWithReducer
    // is typed to the specific properties (including nested) of the state path provided

    if (isFunctionAction(action)) {
      const readonlyFullState: Readonly<StateType> = Object.freeze(
        cloneDeep(state)
      );

      const newState = produce(state, (draft: Draft<StateType>) => {
        if (!action.path || action.path === '') {
          action.fn(draft as unknown as T, readonlyFullState);
        } else {
          set(
            draft,
            action.path,
            action.fn(get(draft, action.path), readonlyFullState)
          );
        }
      });

      callSetter(newState, action);
      return newState;
    }

    if (isStaticAction(action)) {
      const newState = produce(state, (draft: StateType) => {
        set<T>(draft, action.path, action.value);
      });
      callSetter(newState, action);
      return newState;
    }

    console.error('genericReducer: Unknown action type:', action);
    return state;
  };
};
