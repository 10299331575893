import React, { PropsWithChildren, useMemo, useState } from 'react';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Col, Row, Space, Statistic, theme } from 'antd';
import dayjs from 'dayjs';
import accounting from 'accounting-js';

// todo
import { useHotkeys } from 'react-hotkeys-hook';

const styleStat = { fontSize: '1.15em' };
const clsLabel = css([
  tw`_pr-2 _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary`,
  { fontSize: '0.95em' },
]);

type StatProps = { label: string; highlight?: boolean; value: string | number };

export type DrgHeaderData = {
  claimType: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  providerName: string;
  providerLocation: string;
  amountCharged: number;
  amountReimbursed: number;
  patientAge: number;
  patientGender: string;
  adjustedAllowedAmount: number;
  savings: number;
};

export type DrgTabProps = {
  headerData: DrgHeaderData;
  renderViewDocumentsButton: () => React.ReactElement;
};

export const DrgHeader: React.FC<DrgTabProps> = ({
  headerData,
  renderViewDocumentsButton,
}) => {
  const {
    claimType,
    dateOfServiceStart,
    dateOfServiceEnd,
    providerName,
    providerLocation,
    amountCharged,
    amountReimbursed,
    patientAge,
    patientGender,
    adjustedAllowedAmount,
    savings,
  } = headerData;

  const {
    token: { colorInfoTextActive },
  } = theme.useToken();
  const Stat: React.FC<StatProps> = ({ label, highlight, value }) => (
    <Statistic
      title={<span css={clsLabel}>{label}</span>}
      valueStyle={{
        ...styleStat,
        ...(highlight
          ? { color: colorInfoTextActive, fontWeight: 'bold' }
          : {}),
      }}
      value={value}
    />
  );

  const [look, setLook] = useState('1');
  useHotkeys('ctrl+shift+alt+1', () => setLook('1'));
  useHotkeys('ctrl+shift+alt+2', () => setLook('2'));
  useHotkeys('ctrl+shift+alt+3', () => setLook('3'));
  useHotkeys('ctrl+shift+alt+4', () => setLook('4'));
  useHotkeys('ctrl+shift+alt+5', () => setLook('5'));

  if (look === '1') {
    return (
      <div tw="_mb-7 _min-w-[900px]">
        <div tw="_p-0 _pb-4 _pt-0.5">{renderViewDocumentsButton()}</div>

        <Row tw="_mb-4 _mt-0 ">
          <Col span={15} tw="_min-w-[574px]">
            {/* --- LEFT BOXES --- */}
            <Row>
              <Col
                span={24}
                tw="_p-2 _rounded-sm _border _border-solid  _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
              >
                <Row>
                  <Col span={7}>
                    <span css={clsLabel}>Claim DOS:</span>
                    <span>
                      {dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                    </span>
                  </Col>
                  <Col span={8}>
                    <span css={clsLabel}>Provider:</span>
                    <span> {providerName}</span>
                  </Col>
                  <Col span={9}>
                    <span css={clsLabel}>Provider Location:</span>
                    <span> {providerLocation}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row tw="_mt-2 _mb-2">
              <Col span={24}>{/* --- BLANK ROW --- */}</Col>
            </Row>
            <Row>
              <Col
                span={24}
                tw="_p-2 _border _border-solid  _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
              >
                <Row tw="_mb-1">
                  <Col span={8}>
                    <Stat label="Claim Type" value={claimType} />
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <Stat
                      label="Admit Date"
                      value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                    />
                  </Col>
                </Row>
                <Row tw="_mb-1">
                  <Col span={8}>
                    <Stat label="Patient Age" value={patientAge} />
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <Stat
                      label="Discharge Date"
                      value={dayjs(dateOfServiceEnd).format('MM/DD/YYYY')}
                    />
                  </Col>
                </Row>
                <Row tw="_mb-1">
                  <Col span={8}>
                    <Stat label="Patient Gender" value={patientGender} />
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <Stat
                      label="Admit Date"
                      value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* --- MID / V GAP SPACE --- */}
          <Col span={2}></Col>
          <Col
            span={6}
            tw="_p-2 _border-4 _border-double  _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
          >
            {/* --- RIGHT BOX --- */}
            <Row tw="_mb-1">
              <Col span={2}></Col>
              <Col span={19}>
                <Stat
                  label="Billed Amount"
                  value={accounting.formatMoney(amountCharged)}
                />
              </Col>
              <Col span={1}></Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={2}></Col>
              <Col span={19}>
                <Stat
                  label="Allowed Amount"
                  value={accounting.formatMoney(amountReimbursed)}
                />
              </Col>
              <Col span={1}></Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={2}></Col>
              <Col span={19}>
                <Stat
                  label="Adj. Allowed Amount"
                  highlight={true}
                  value={accounting.formatMoney(adjustedAllowedAmount)}
                />
              </Col>
              <Col span={1}></Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={2}></Col>
              <Col span={19}>
                <Stat
                  label="Savings"
                  highlight={true}
                  value={accounting.formatMoney(savings)}
                />
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
          <Col span={1}></Col>
        </Row>
      </div>
    );
  }

  if (look === '2') {
    return (
      <div>
        <Row tw="_mb-4 _mt-0">
          {/* <Col span={2} /> */}
          <Col span={24}>
            <Space size="large">
              <span>
                <Space>
                  <span css={clsLabel}>Claim DOS:</span>
                  <span>{dayjs(dateOfServiceStart).format('MM/DD/YYYY')}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Name:</span>
                  <span> {providerName}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Location:</span>
                  <span> {providerLocation}</span>
                </Space>
              </span>
            </Space>
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Claim Type" value={claimType} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Admit Date"
              value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Billed Amount"
              value={accounting.formatMoney(amountCharged)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Patient Age" value={patientAge} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Discharge Date"
              value={dayjs(dateOfServiceEnd).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Allowed Amount"
              value={accounting.formatMoney(amountReimbursed)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Patient Gender" value={patientGender} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Admit Date"
              value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Adjusted Allowed Amount"
              highlight={true}
              value={accounting.formatMoney(adjustedAllowedAmount)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={18} />
          <Col span={6}>
            <Stat
              label="Savings"
              highlight={true}
              value={accounting.formatMoney(savings)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  if (look === '3') {
    return (
      <div>
        <Row tw="_mb-4 _mt-0">
          {/* <Col span={2} /> */}
          <Col span={24}>
            <Space size="large">
              <span>
                <Space>
                  {/* <span css={clsLabel}>Claim DOS:</span> */}
                  <span>{renderViewDocumentsButton()}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Claim DOS:</span>
                  <span>{dayjs(dateOfServiceStart).format('MM/DD/YYYY')}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Name:</span>
                  <span> {providerName}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Location:</span>
                  <span> {providerLocation}</span>
                </Space>
              </span>
            </Space>
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Claim Type" value={claimType} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Admit Date"
              value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Billed Amount"
              value={accounting.formatMoney(amountCharged)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Patient Age" value={patientAge} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Discharge Date"
              value={dayjs(dateOfServiceEnd).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Allowed Amount"
              value={accounting.formatMoney(amountReimbursed)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={6}>
            <Stat label="Patient Gender" value={patientGender} />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Admit Date"
              value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
            />
          </Col>
          <Col span={3} />
          <Col span={6}>
            <Stat
              label="Adjusted Allowed Amount"
              highlight={true}
              value={accounting.formatMoney(adjustedAllowedAmount)}
            />
          </Col>
        </Row>
        <Row tw="_mb-1">
          <Col span={18} />
          <Col span={6}>
            <Stat
              label="Savings"
              highlight={true}
              value={accounting.formatMoney(savings)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  if (look === '4') {
    return (
      <div>
        <Row tw="_mb-4 _mt-0">
          {/* <Col span={2} /> */}
          <Col
            span={12}
            tw="_p-2 _border-2 _border-solid  _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
          >
            <Space>
              <span>
                <Space>
                  <span css={clsLabel}>Claim DOS:</span>
                  <span>{dayjs(dateOfServiceStart).format('MM/DD/YYYY')}</span>
                </Space>
              </span>
              <span tw="_pl-10">
                <Space>
                  <span css={clsLabel}>Provider Name:</span>
                  <span> {providerName}</span>
                </Space>
              </span>
              <span tw="_pl-10">
                <Space>
                  <span css={clsLabel}>Provider Location:</span>
                  <span> {providerLocation}</span>
                </Space>
              </span>
            </Space>
          </Col>
          <Col span={12}></Col>
        </Row>

        <Row>
          <Col
            span={12}
            tw="_p-2 _border-2 _border-solid _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
          >
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Claim Type" value={claimType} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Admit Date"
                  value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Patient Age" value={patientAge} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Discharge Date"
                  value={dayjs(dateOfServiceEnd).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Patient Gender" value={patientGender} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Admit Date"
                  value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
          </Col>

          <Col span={5}></Col>
          <Col
            span={5}
            tw="_p-2 _border-4 _border-double _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
          >
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Billed Amount"
                  value={accounting.formatMoney(amountCharged)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Allowed Amount"
                  value={accounting.formatMoney(amountReimbursed)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Adjusted Allowed Amount"
                  highlight={true}
                  value={accounting.formatMoney(adjustedAllowedAmount)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Savings"
                  highlight={true}
                  value={accounting.formatMoney(savings)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </div>
    );
  }

  if (look === '5') {
    return (
      <div>
        <Row tw="_mb-4 _mt-0">
          {/* <Col span={2} /> */}
          <Col span={24}>
            <Space size="large">
              <span>
                <Space>
                  <span css={clsLabel}>Claim DOS:</span>
                  <span>{dayjs(dateOfServiceStart).format('MM/DD/YYYY')}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Name:</span>
                  <span> {providerName}</span>
                </Space>
              </span>
              <span tw="_pl-12">
                <Space>
                  <span css={clsLabel}>Provider Location:</span>
                  <span> {providerLocation}</span>
                </Space>
              </span>
            </Space>
          </Col>
        </Row>

        <Row>
          <Col span={14}>
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Claim Type" value={claimType} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Admit Date"
                  value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Patient Age" value={patientAge} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Discharge Date"
                  value={dayjs(dateOfServiceEnd).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={8}>
                <Stat label="Patient Gender" value={patientGender} />
              </Col>
              <Col span={8} />
              <Col span={8}>
                <Stat
                  label="Admit Date"
                  value={dayjs(dateOfServiceStart).format('MM/DD/YYYY')}
                />
              </Col>
            </Row>
          </Col>

          <Col span={2}></Col>
          <Col
            span={6}
            tw="_p-2 _border-2 _border-solid _border-antd-lt-colorBorder dark:_border-antd-dk-colorBorder"
          >
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Billed Amount"
                  value={accounting.formatMoney(amountCharged)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Allowed Amount"
                  value={accounting.formatMoney(amountReimbursed)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Adjusted Allowed Amount"
                  highlight={true}
                  value={accounting.formatMoney(adjustedAllowedAmount)}
                />
              </Col>
            </Row>
            <Row tw="_mb-1">
              <Col span={24}>
                <Stat
                  label="Savings"
                  highlight={true}
                  value={accounting.formatMoney(savings)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={2}></Col>
        </Row>
      </div>
    );
  }

  return <div>unknown look</div>;
};
