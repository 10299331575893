import { inputPropsCurrencyFormatter } from '../../audit/util';
import { validate as uuidValidate } from 'uuid';

//dayjs is the drop-in immutable replacement for moment, used by antd5,
//    see https://alaffiahealth.atlassian.net/browse/EN-1031
// import moment from 'moment-timezone';
import utc from 'dayjs/plugin/utc'; //dayjs requires enabling this plugin to use the .utc() calls
import dayjs from 'dayjs';
dayjs.extend(utc);

const generateFormattedMoneyRangeString = (
  v,
  searchFieldName,
  lowerBoundKeyword,
  upperBoundKeyword,
  { useSymbols, min, max }
) => {
  const lowerBound = v[lowerBoundKeyword]
    ? inputPropsCurrencyFormatter.formatter(v[lowerBoundKeyword])
    : min;

  const upperBound = v[upperBoundKeyword]
    ? inputPropsCurrencyFormatter.formatter(v[upperBoundKeyword])
    : max;

  if (lowerBound && upperBound) {
    return useSymbols === true
      ? `${lowerBound} < ${searchFieldName} < ${upperBound}`
      : `${searchFieldName}: greater than ${lowerBound} and less than ${upperBound}`;
  } else if (upperBound)
    return useSymbols === true
      ? `${searchFieldName} < ${upperBound}`
      : `${searchFieldName}: greater than 0 and less than ${upperBound}`;
  else if (lowerBound)
    return useSymbols === true
      ? `${searchFieldName} > ${lowerBound}`
      : `${searchFieldName}: greater than ${lowerBound}`;
  return `${searchFieldName}: No Bounds`;
};

const stringReplace = (str, replaceKeys, replacements) => {
  return str.replace(/%\w+%/g, i => {
    return replacements[replaceKeys[i]];
  });
};

/**
 *
 * @param {string} uuid Expected type for \"UUID\" -- 32 hexadecimal characters, with 4 hyphens
 * @returns boolean value determined using 'validate' function from 'uuid' library
 */
const isUuid = uuid => {
  try {
    return uuidValidate(uuid);
  } catch (error) {
    return false;
  }
};

const getDisplayNamesOfStatesArray = (states, values, userType) => {
  return Object.entries(states)
    .filter(([stateName, state]) => values.includes(stateName))
    .map(([stateName, { name }]) => name(userType))
    .join(', ');
};

const arrayOfLabelsFromLabelValueMap = (map, values) => {
  return Object.entries(map)
    .filter(([value, label]) => values.includes(value))
    .map(([value, label]) => label)
    .join(', ');
};

const isValidInteger = (v, { min, max }) => {
  let isValid = v !== null && typeof v === 'number';
  if (isValid && min) isValid = v > min;
  if (isValid && max) isValid = v < max;
  return isValid;
};

const getUTCDateObj = date => {
  return dayjs(date).utc().endOf('day').utc();
};

const getDisplayNameOfDateRange = (
  dateTypeName,
  startDate,
  endDate,
  momentFormat
) => {
  const formattedStartDate = getUTCDateObj(startDate);
  const formattedEndDate = getUTCDateObj(endDate);

  // when range is the same date
  if (formattedStartDate.diff(formattedEndDate) === 0) {
    return `${dateTypeName}: ${formattedStartDate.format(momentFormat)}`;
  } else
    return `${dateTypeName}: ${formattedStartDate.format(
      momentFormat
    )} - ${formattedEndDate.format(momentFormat)}`;
};

const generateOptionsArrForEnumClaimState = (statesArr, userType) => {
  return statesArr.map(([state, { name }]) => ({
    label: name(userType),
    value: state,
  }));
};

const filterClaimStatesSearchOptionsByPermission = (
  states,
  field,
  { claimStatesToShow } // the permissions object destructured
) => {
  return Object.entries(states).filter(([state, _]) => {
    return claimStatesToShow[field].has(state);
  });
};

const generateDefaultValue = (filter, field, typeDef) => {
  if (filter.filterMap.has(field)) {
    return filter.filterMap.get(field).value;
  } else {
    const value = {};
    typeDef.selects.forEach(({ key, defaultValue }) => {
      if (defaultValue) {
        value[key] = defaultValue;
      }
    });
    return value;
  }
};

export {
  stringReplace,
  getDisplayNamesOfStatesArray,
  isValidInteger,
  generateFormattedMoneyRangeString,
  getUTCDateObj,
  getDisplayNameOfDateRange,
  arrayOfLabelsFromLabelValueMap,
  generateOptionsArrForEnumClaimState,
  filterClaimStatesSearchOptionsByPermission,
  generateDefaultValue,
  isUuid,
};
