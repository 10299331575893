import { useEffect, useState } from 'react';
import { IToolPanelParams, RowClickedEvent } from 'ag-grid-community';
import tw, { css } from 'twin.macro';
import { IBGridLine } from '../../../../components/audit/claimWorkspace/itemizedBillTab/ibTable/types';
import { toSlug, fromSlugOrId } from '../../../navigation/utils';
import { ObjectViewer } from '../../../util/devUtil/objectViewer';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { message } from 'antd';

export const DevToolbar = (props: IToolPanelParams) => {
  const [numFindings, setNumFindings] = useState(0);

  const [clickedRow, setClickedRow] = useState<IBGridLine>();

  const updateTotals = () => {
    let findingCount = 0;

    props.api.forEachNode(function (rowNode) {
      const data = rowNode.data;
      if (data?.findings?.length > 0) {
        findingCount += data.findings.length;
      }
    });

    setNumFindings(findingCount);
  };

  const rowSelected = (rowClickedEvent: RowClickedEvent) => {
    setClickedRow(() => rowClickedEvent?.node?.data ?? {});
  };

  useEffect(() => {
    props.api.addEventListener('modelUpdated', updateTotals);
    props.api.addEventListener('rowClicked', rowSelected);
    return () => {
      props.api.removeEventListener('modelUpdated', updateTotals);
      props.api.removeEventListener('rowClicked', rowSelected);
    };
  }, []);

  const MENU_ID = 'menu-id';
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleItemClick({ event, props, triggerEvent, data }: any) {
    return navigator.clipboard
      .writeText(JSON.stringify(props.data, null, 3))
      .catch(err => {
        message.error(`Failed to copy text to clipboard`);
        console.error(err);
      });
  }

  return (
    <div tw="_p-2 _h-full _min-h-screen _w-full _min-w-[400px] _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer">
      <h3 tw="_pt-8 _p-4 _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary">
        Row Info
      </h3>
      <div tw="_p-1">
        <span tw="_font-bold">ID:</span>
        <span tw="_pl-4 _cursor-text _select-text">
          {fromSlugOrId(toSlug(clickedRow?.id))}
        </span>
      </div>
      <div tw="_p-1">
        <span tw="_font-bold">Slug:</span>
        <span tw="_pl-4 _cursor-text _select-text">
          {toSlug(clickedRow?.id ?? '')}
        </span>
      </div>
      <div tw="_p-4">Total Findings (all rows): {numFindings}</div>
      <h3 tw="_pt-8 _p-4 _text-antd-lt-colorPrimary dark:_text-antd-dk-colorPrimary">
        Row Data
      </h3>
      <div tw="_h-fit _w-fit">
        {!clickedRow ? (
          <div tw="_p-4 _italic">Click a row to show data</div>
        ) : (
          <div
            onContextMenu={event => {
              show({ event, props: { data: clickedRow } });
            }}
          >
            <ObjectViewer objectToView={clickedRow ?? {}}></ObjectViewer>
          </div>
        )}
      </div>
      <Menu id={MENU_ID}>
        <Item onClick={handleItemClick}>Copy as JSON</Item>
      </Menu>
    </div>
  );
};
