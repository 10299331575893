import { BehaviorSubject } from 'rxjs';
import { IPdfViewer, ViewerLicense } from './types';
import { createFoxitViewer } from './impl/foxit';
import _log, { Logger } from 'loglevel';
const log = _log.getLogger('pdf/viewer');

const instanceSubject = new BehaviorSubject<IPdfViewer<any> | null>(null);
const PdfViewerInstance = instanceSubject.asObservable();

let isPdfViewerEnabling = false;

export const enablePdfViewer = (viewerLicense: ViewerLicense): boolean => {
  if (isPdfViewerEnabling) {
    log.debug(
      '--- viewer:enablePdfViewer called while already enabling ---',
      viewerLicense
    );
    // return false // ???
  }
  isPdfViewerEnabling = true;

  const viewerEl = document.getElementById('pdfviewer');

  if (!viewerEl) {
    console.error('Failed to instantiate viewer, no viewer element found.');
  }

  const viewer_impl = 'foxit';
  // const viewer_impl = localStorage.getItem('pdf_viewer') ?? 'foxit';

  if (viewerEl) {
    switch (viewer_impl) {
      case 'foxit':
        createFoxitViewer(viewerEl, viewerLicense).then(instance => {
          log.trace('---- load foxit instance ------', instance);
          isPdfViewerEnabling = false;
          instanceSubject.next(instance ?? null);
        });
        break;
      default:
        log.warn('Unexpected error:  viewer type is not defined!', viewer_impl);
        createFoxitViewer(viewerEl, viewerLicense).then(instance => {
          log.debug('---- load foxit instance (default) ------', instance);
          isPdfViewerEnabling = false;
          instanceSubject.next(instance ?? null);
        });
    }
  }
  log.trace(
    'enablePdfViewer --> return after scheduling promise',
    isPdfViewerEnabling
  );
  return true;
};

export default PdfViewerInstance;
