// import { graphqlClient } from '../../../app/app';
import { gql } from 'urql';
import { batchClaimState } from '../../../../fragments';
import { getAlaffiaClient } from '../../../../alaffiaClient/alaffiaClientProvider';

const toFirstLetterUpper = str =>
  str
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b[a-z](?=[a-z]{2})/g, l => l.toUpperCase());

const uploadDocumentToS3 = async ({ uploadUrl, file, tags }) => {
  try {
    return fetch(uploadUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'x-amz-tagging': tags,
      },
    }).then(async res => Promise.resolve(file));
  } catch (err) {
    console.error('error uploading documents', err);
    // TODO pipe error to logger
    return Promise.reject({
      file,
      error: new Error('Error uploading document'),
    });
  }
};

// Gets upload URL and creates tags for s3 upload
// Ouput matches input of uploadDocumentToS3()
const getDocumentUploadParams = async ({ file, batchClaimId }) => {
  try {
    const fileName = file.name;
    const fileType = file.type;
    const documentUploadKind = file.documentUploadKind;

    const input = {
      fileName,
      fileType,
      documentUploadKind,
    };

    const graphqlClient = getAlaffiaClient()?.getGraphqlClient();
    if (!graphqlClient) {
      throw new Error('No graphql client found');
    }

    const { data: batchClaimUploadData, error } = await graphqlClient
      .query(
        gql`
          query getSignedDocumentUploadUrl(
            $batchClaimId: UUID!
            $input: S3DocumentUpload!
          ) {
            batchClaim(id: $batchClaimId) {
              id
              supportingDocumentation {
                signedUpload(input: $input) {
                  url
                  tags
                }
              }
              ...batchClaimState
            }
          }
          ${batchClaimState}
        `,
        { batchClaimId, input },
        {
          // IMPORTANT !!!
          // It is critical that this is kept as a network only call
          // to prevent cached upload URLs from being re-used
          requestPolicy: 'network-only',
        }
      )
      .toPromise();

    if (error) {
      console.error(error);
      // TODO pipe error to logger
      return Promise.reject({
        file,
        error:
          error.graphQLErrors.length &&
          error.graphQLErrors[0].message === 'Document already exists'
            ? new Error(
                `Document with file name ${fileName} already exists. File names must be unique.`
              )
            : new Error('Network error.\nPlease try again.'),
      });
    }

    const {
      batchClaim: {
        supportingDocumentation: {
          signedUpload: { url: uploadUrl, tags },
        },
      },
    } = batchClaimUploadData;

    return {
      uploadUrl,
      file,
      tags,
    };
  } catch (e) {
    console.error(e);
    return Promise.reject({
      file,
      error: e,
    });
  }
};

// File upload handler
// Returns a rejected promise in the event of errors
// Returns a resolved promise if upload is successful
const uploadFile = async ({ file, batchClaimId, userId }) => {
  if (!file.documentUploadKind) {
    console.log('Must select document kind when uploading a file');
    return Promise.reject({
      file,
      error: new Error('Document Kind Must Be Selected'),
    });
  }

  return getDocumentUploadParams({
    file,
    batchClaimId,
    userId,
  }).then(uploadDocumentToS3);
};

export { toFirstLetterUpper, uploadFile };
