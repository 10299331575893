import React, { FC } from 'react';
import tw, { css } from 'twin.macro';
import { handlebarsUtil } from './handlebarsUtil';
import { isSet } from '../../../../util/isSet';
import { isObject, omit, pick } from 'lodash';
import {
  AutodorDynamicDetails,
  hasDisplayableData,
} from '../autodorDynamicDetails';
import { Collapse } from 'antd';
import { getRuleLabelForRuleType } from '../autodorFindingUtils';
import { isNonNullStringArray, nonNullArr } from '../../../../util/array';
import { TemplateDefinition } from './ruleDisplayTypes';

const defaultStyles = css({
  ...tw`_whitespace-normal _break-words`,
  '& .label': tw`_text-left _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary`,
  '& .header': tw`_font-bold _text-left _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary`,
  '& .value': tw`_text-left _leading-5 _align-baseline _my-auto 
      _p-0 _whitespace-normal _break-words`,
  '& .html-value': tw`_w-full _whitespace-pre-wrap`,
  '& .indent-1': tw`_pl-3`,
  '& .indent-2': tw`_pl-6`,
  '& .indent-3': tw`_pl-9`,
  '& .indent-4': tw`_pl-12`,
  '& .indent-5': tw`_pl-16`,
  '& .indent-6': tw`_pl-20`,
  '& .bold': tw`_font-bold`,
  '& .flex': tw`_flex _flex-row _gap-2`,
  '& .pad-x': tw`_px-2`,
  '& .pad-l': tw`_pl-2`,
  '& .pad-r': tw`_pr-2`,
  '& .pad-y': tw`_py-2`,
  '& .clamp-1': tw`_line-clamp-1`,
  '& .clamp-2': tw`_line-clamp-2`,
  '& .clamp-3': tw`_line-clamp-3`,
  '& .clamp-4': tw`_line-clamp-4`,
  '& .clamp-5': tw`_line-clamp-5`,
  '& .scroll': tw`_overflow-auto _w-full _h-24`,
  '& .italic': tw`_italic`,
  '& .primary': tw`_text-antd-lt-colorPrimaryText dark:_text-antd-dk-colorPrimaryText`,
  '& .error': tw`_text-antd-lt-colorError dark:_text-antd-dk-colorError`,
});

export type RuleTypeDisplayProps = {
  ruleType?: string | null;
  rule?: { [key: string]: any } | null;
  template: TemplateDefinition['template'];
  showExtraProps?: TemplateDefinition['showExtraProps'];
};

export const RuleTypeDisplay: FC<RuleTypeDisplayProps> = ({
  ruleType,
  rule,
  template,
  showExtraProps = false,
}) => {
  if (!isSet(rule) || !isSet(ruleType) || !isSet(template)) {
    return (
      <div tw="_text-antd-lt-colorError dark:_text-antd-dk-colorError">
        Template config is incorrect for {ruleType}
      </div>
    );
  } else {
    const extraPropsToShow = isNonNullStringArray(showExtraProps)
      ? pick(rule ?? {}, showExtraProps)
      : showExtraProps
        ? omit(rule ?? {}, handlebarsUtil.getHandlebarsVariables(template))
        : {};

    const components = handlebarsUtil.getAsComponents(template, rule!);

    // n.b. the getHandlebarsVariables function above doesn't filter props used only as input to
    //  helpers, e.g. if it's only used in an #if but not {{ref'd}} -- to hide props in templates
    //  that are used in calculations but not displayed, assign them to an attr e.g.
    //  <... title={{myPropNotToShowInExtra}} />
    if (showExtraProps && hasDisplayableData(extraPropsToShow)) {
      return (
        <>
          <div css={defaultStyles}>{components}</div>
          <div tw="_pt-2">
            <Collapse
              defaultActiveKey={['']}
              ghost
              css={css({
                '& .ant-collapse-header': tw`_p-0! _m-0! _font-bold! _text-antd-lt-colorTextTertiary! dark:_text-antd-dk-colorTextTertiary!`,
              })}
            >
              <Collapse.Panel
                header={`Additional ${getRuleLabelForRuleType(ruleType)} info`}
                key="addl-rule-fields"
              >
                <AutodorDynamicDetails
                  type={ruleType ?? 'Unknown'}
                  title={''}
                  object={extraPropsToShow}
                ></AutodorDynamicDetails>
              </Collapse.Panel>
            </Collapse>
          </div>
        </>
      );
    }
    return <div css={defaultStyles}>{components}</div>;
  }
};
