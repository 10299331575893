import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import log, { Logger } from 'loglevel';

const ll = process.env.REACT_APP_LOG_LEVEL ?? 'warn';
if (ll) {
  const llk = log.levels[ll.toUpperCase() as keyof typeof log.levels];
  log.setLevel(llk ?? log.levels.WARN, false);
}

const canListLoggers = (): boolean => {
  return window.location.host === 'localhost:3000';
};

type LogCtx = {
  getLog: (name: string) => Logger;
};
export const LogContext = React.createContext<LogCtx>({} as LogCtx);

export const LogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // log.setLevel(log.levels.SILENT); // TODO
  log.info('Default log level:', log.getLevel());

  const loggers = useMemo(
    () => ({
      ...(canListLoggers()
        ? {
            listLoggers: () => {
              const loggers = log?.getLoggers();
              if (!loggers) {
                console.log('No loggers loaded');
              }
              console.log(Object.keys(loggers).join('\n'));
            },
            listLogLevelOverrides: () => {
              const loggers = log?.getLoggers();
              if (!loggers) {
                console.log('No loggers loaded');
              } else {
                console.log(
                  Object.keys(loggers)
                    .map(k => [k, localStorage.getItem('loglevel:' + k)])
                    .filter(arr => !!arr[1])
                    .map(arr => {
                      console.log(`${arr[0]}: ${arr[1]}`);
                    })
                );
              }
            },
          }
        : {}),
      clearLogLevelOverrides: () => {
        const loggers = log?.getLoggers();
        if (!loggers) {
          console.log('No loggers loaded');
        } else {
          Object.keys(loggers).map(k => [
            k,
            localStorage.removeItem('loglevel:' + k),
          ]);
        }
      },
      setLogLevel: (level: string, ...loggerNames: string[]) => {
        const levels = Object.keys(log.levels);
        if (!levels.includes(level.toUpperCase())) {
          console.warn(
            `Unknown log level '${level}', request ignored.  Available levels: ${levels.join(
              ', '
            )}`
          );
          return;
        }

        const unknownLoggers: string[] = [];
        if (loggerNames && loggerNames.length) {
          const lev = level.toUpperCase() as keyof typeof log.levels;
          console.log(
            'Setting log level for loggers',
            loggerNames.join(', '),
            lev
          );
          for (const loggerName of loggerNames) {
            const l = log.getLoggers()[loggerName];
            if (!l) {
              unknownLoggers.push(loggerName);
              return;
            }
            l.setLevel(lev);
            console.log(`Set ${loggerName} level to ${lev}`);
          }
        }

        if (unknownLoggers.length) {
          console.warn(
            `Unknown loggers ${unknownLoggers.join(', ')} ignored`,
            canListLoggers() ? Object.keys(log.getLoggers()) : ''
          );
        }
      },
    }),
    []
  );

  useEffect(() => {
    (window as any).loggers = loggers;
  }, [loggers]);

  const ctx = useMemo<LogCtx>(() => {
    return { getLog: (name: string) => log.getLogger(name) };
  }, []);
  return <LogContext.Provider value={ctx}>{children}</LogContext.Provider>;
};
