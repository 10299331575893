import {
  AutodorFinding,
  AutodorMetadata,
} from '../../../claimWorkspace/findingEdit/types';
import { autodorToFindingConfig } from './autodorDetails/autodorToFindingConfig';
import { get } from 'lodash';

export const objectPropToTitle = (str: string): string =>
  (
    // based on comments here: https://dev.to/suyashdev/camelcase-to-normal-string-in-javascript-592g
    (str = str.replaceAll(/_/g, ' ').replace(/[A-Z]/g, ' $&')),
    str[0].toUpperCase() + str.slice(1)
  );

export const getRuleTypeFromAutodorMetadata = (
  autodorMetadata: AutodorMetadata
) =>
  (autodorMetadata?.rule?._type ??
    'genericRule') as keyof AutodorMetadata['rule'];

export const getRuleFromAutodorMetadata = (
  autodorMetadata: AutodorMetadata
) => {
  if (!!autodorMetadata?.rule) {
    const type = getRuleTypeFromAutodorMetadata(autodorMetadata);
    if (autodorMetadata?.rule[type]) {
      return autodorMetadata.rule[type];
    }
  }
  return undefined;
};

export const getGenericRuleFromAutodorMetadata = (
  autodorMetadata: AutodorMetadata
) => autodorMetadata?.rule?.genericRule ?? {};

export const getRuleLabelForRuleType = (
  ruleType: string | null | undefined
) => {
  if (!ruleType) {
    return '';
  }
  const ruleLabel: string | undefined = get(
    autodorToFindingConfig,
    `${ruleType}.label`
  );
  return ruleLabel ?? objectPropToTitle(ruleType);
};

export const getRuleLabelForFinding = (finding: AutodorFinding) => {
  const ruleType = finding?.autodorMetadata?.rule?._type;
  return getRuleLabelForRuleType(ruleType);
};

export const getRuleForAutodorFinding = (finding: AutodorFinding) =>
  finding?.autodorMetadata?.rule;
