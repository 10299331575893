import { ColDef } from 'ag-grid-community';

export const DefaultColumn: ColDef = {
  enableRowGroup: true,
  sortable: true,
  resizable: true,
  filter: true,
  wrapText: true,
  wrapHeaderText: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab'],
};
