import tw from 'twin.macro';
import { css } from '@emotion/react';
import { DrgDataType, DrgForm } from './drgForm';
import { DRG_MODE } from './drgFormItem';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { Form } from 'antd';

export type DrgContentProps = {
  drgData?: DrgDataType;
  drgPriorVersionData?: DrgDataType;
  askAutodorOpen: boolean;
  saveReviewData: (dataToSave: DrgDataType) => boolean;
};

export const DrgContent: React.FC<DrgContentProps> = ({
  drgData,
  drgPriorVersionData,
  askAutodorOpen,
  saveReviewData,
}) => {
  const [editable, setEditable] = useState(false);
  const isSplitScreen = askAutodorOpen ? false : true;

  return (
    <div tw="_pr-0.5">
      <DrgForm
        isSplitScreen={isSplitScreen}
        mode={DRG_MODE.REVIEW}
        editable={editable}
        setEditable={setEditable}
        drgData={drgData}
        drgPriorVersionData={drgPriorVersionData}
        saveReviewData={saveReviewData}
      ></DrgForm>
    </div>
  );
};
