import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { tailwindPreflight } from '../components/app/design/tw-preflight';
import { Icon } from '../components/app/icon';
import { keyframes } from '@emotion/react';
import { Divider } from 'antd';
import QRCode from 'qrcode.react';
import { LoginResult } from '@alaffia-technology-solutions/alaffia-client-sdk';
import { ChallengeArgs } from './types';
import tw, { css } from 'twin.macro';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;
const fadeInSecs = 0.5;
const iconCss = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  '& img': {
    animation: `${fadeIn} ${fadeInSecs}s ease`,
    flex: '0 0 auto',
    width: '48px',
    height: 'auto',
  },
});

const minWidth = '375px';
const maxWidth = '50vw';
// className="_box _p-4 _min-w-80 _w-full _max-w-md _transform _overflow-hidden _rounded-2xl _text-left _align-middle _shadow-xl _transition-all _bg-antd-lt-colorBgContainer dark:_bg-antd-lt-colorBgContainer"
const clsPanel = css({
  minWidth,
  maxWidth,
  ...tw`_w-full _p-4 _transform _overflow-hidden _rounded-2xl _text-left _align-middle _shadow-xl _transition-all`,
  ...tw`_bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer`,
});

// className="_block _text-gray-700 _text-sm font-bold mb-2"
const clsLabel = css({
  ...tw`_block _text-gray-700 _text-sm _mb-2`,
});

// className="_shadow _appearance-none _border _border-red-500 _rounded _w-full _py-2 _px-3 _text-gray-700 _mb-3 _leading-tight focus:_outline-none focus:_shadow-outline"
const clsInput = css({
  ...tw`_shadow _appearance-none _border  _rounded _w-full _py-2 _px-3 _mb-3 _leading-tight`,
  ...tw`_border-antd-lt-colorPrimaryBorder focus:_border-antd-lt-colorBorderSecondary _text-antd-lt-colorText _placeholder-antd-lt-colorTextPlaceholder`,
  ...tw`dark:(_border-antd-dk-colorPrimaryBorder focus:_border-antd-dk-colorBorderSecondary _text-antd-dk-colorText _placeholder-antd-dk-colorTextPlaceholder)`,
  ...tw`disabled:(_border-gray-200 _shadow-none _border-solid _text-gray-400)`,
  '&:placeholder-shown': tw`_border-antd-lt-colorErrorBorderHover dark:(_border-antd-dk-colorErrorBorderHover)`,
});

const TermsAndConditions = () => (
  <div tw="_px-4 _whitespace-nowrap _text-xs _flex _items-center _text-antd-lt-colorTextSecondary  dark:_text-antd-dk-colorTextSecondary ">
    By logging in, you are agreeing to the{' '}
    <a
      tw="_px-2 _text-antd-lt-colorLink dark:_text-antd-dk-colorLink"
      target="_blank"
      href="https://alaffiahealth.com/privacy-policy/"
    >
      Alaffia Privacy Policy
    </a>
  </div>
);

export const LoginModal: FC<{
  loginResult: LoginResult | null;
  login({ user, pass }: { user: string; pass: string }): void;
  challenge(args: ChallengeArgs): void;
  isOpen: boolean;
  setIsOpen(b: boolean): void;
  lastError?: string;
}> = ({ loginResult, login, challenge, isOpen, setIsOpen, lastError }) => {
  function closeModal() {
    setIsOpen(false);
  }

  // 'current' (old) pwd isn't currently needed for our two idp, cognito & kc-dev
  const requireOldPwd = false;

  // if an idp does req, we can have a disabled version on this flag
  const isKcForceChangePwd =
    loginResult?.predicates.isForceChangePassword(loginResult) &&
    loginResult?.idpType === 'keycloak-dev-only';

  let signinMessage = !loginResult
    ? 'Sign in'
    : loginResult?.predicates?.isConfigureMfa(loginResult)
      ? 'Save MFA'
      : loginResult?.predicates?.isLoginChallenge(loginResult)
        ? 'Continue'
        : loginResult?.predicates?.isForceChangePassword(loginResult)
          ? 'Change Password'
          : 'Sign in';

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      if (
        loginResult?.predicates.isLoginChallenge(loginResult) ||
        loginResult?.predicates.isConfigureMfa(loginResult)
      ) {
        challenge({
          code: data.get('code') as string,
        });
      } else if (loginResult?.predicates.isForceChangePassword(loginResult)) {
        challenge({
          oldPassword: !requireOldPwd
            ? 'unused-old-password'
            : (data.get('oldPassword') as string),
          newPassword: data.get('newPassword') as string,
          confirmPassword: data.get('confirmPassword') as string,
        });
      } else {
        login({
          user: data.get('username') as string,
          pass: data.get('password') as string,
        });
      }
    },
    [login, challenge, loginResult]
  );

  // NOTE the {/* @ts-ignore */} suppress error that seems related to using "jsxImportSource": "@emotion/react" tsconfig:
  //    TS2590: Expression produces a union type that is too complex to represent.
  // Switching "jsxImportSource" to just "react" removes the error but then every instance of css={...} gets flagged...
  return (
    <div css={tailwindPreflight}>
      {/* @ts-ignore */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="_vrelative _z-10" onClose={closeModal}>
          {/* @ts-ignore */}
          <Transition.Child
            enter="_ease-out _duration-900"
            enterFrom="_opacity-0"
            enterTo="_opacity-100"
            leave="_ease-in duration-200"
            leaveFrom="_opacity-100"
            leaveTo="_opacity-0"
          >
            <div className="_fixed _inset-0 _bg-black/25" />
          </Transition.Child>

          <div className="_fixed _inset-0 _overflow-y-auto">
            <div className="_flex _min-h-full _items-center _justify-center _p-4 _text-center">
              <Transition.Child
                enter="_ease-out _duration-300"
                enterFrom="_opacity-0 _scale-95"
                enterTo="_opacity-100 _scale-100"
                leave="_ease-in _duration-200"
                leaveFrom="_opacity-100 _scale-100"
                leaveTo="_opacity-0 _scale-95"
              >
                <Dialog.Panel css={clsPanel}>
                  <Dialog.Title
                    as="h3"
                    className="_text-lg _font-medium _leading-6 _text-gray-600"
                  >
                    <div className="_flex _h-16">
                      <div className="_w-16">
                        <Icon
                          className={''}
                          css={iconCss}
                          data-cy="header-icon"
                        ></Icon>
                      </div>
                      <div className="_w-full _place-self-center _text-center">
                        Login to Alaffia
                      </div>
                      <div className="_w-16"></div>
                    </div>
                  </Dialog.Title>
                  <div className="_mt-2 _text-sm">
                    <div className="w-full max-w-xs">
                      <form
                        onSubmit={handleLogin}
                        className="_px-8 _pt-6 _pb-8 _mb-4"
                      >
                        {
                          // ** MFA **
                          loginResult?.predicates?.isConfigureMfa(
                            loginResult
                          ) ? (
                            <div className="_mb-6">
                              <div style={{ paddingTop: '10px' }}>
                                1. Install a compatible app on your mobile
                                device or computer
                              </div>
                              <Divider />
                              <div style={{ paddingTop: '10px' }}>
                                2. Use your virtual MFA app and your devices
                                camera to scan the QR code
                              </div>
                              <div style={{ paddingTop: '10px' }}>
                                <img src={loginResult?.totpQrCode ?? ''} />
                              </div>
                              <div style={{ paddingTop: '5px' }}>
                                Alternatively you can type in the secret key:{' '}
                                {loginResult?.totpSecret ?? ''}
                              </div>
                              <Divider />
                              <div style={{ paddingTop: '10px' }}>
                                3. Type the next MFA code below from your
                                virtual MFA app
                              </div>
                              <div className="_mb-6">
                                <label css={clsLabel} htmlFor="password">
                                  MFA Code
                                </label>
                                <input
                                  css={clsInput}
                                  name="code"
                                  id="code"
                                  type="password"
                                  placeholder="Enter MFA code"
                                  tabIndex={1}
                                  autoFocus
                                  autoComplete="one-time-code"
                                  required
                                />
                              </div>
                            </div>
                          ) : loginResult?.predicates?.isLoginChallenge(
                              loginResult
                            ) ? (
                            <div className="_mb-6">
                              <label css={clsLabel} htmlFor="password">
                                MFA Code
                              </label>
                              <input
                                css={clsInput}
                                name="code"
                                id="code"
                                placeholder="Enter MFA Code"
                                type="password"
                                tabIndex={1}
                                autoFocus
                                autoComplete="one-time-code"
                                required
                              />
                            </div>
                          ) : loginResult?.predicates?.isForceChangePassword(
                              loginResult
                            ) ? (
                            <>
                              <div className="_mb-4">
                                <label css={clsLabel} htmlFor="username">
                                  Username
                                </label>
                                <input
                                  css={clsInput}
                                  name="username"
                                  id="username"
                                  placeholder="Enter user name"
                                  defaultValue={
                                    loginResult?.authParams.username ?? ''
                                  }
                                  disabled={!!loginResult?.authParams.username}
                                  autoFocus
                                  tabIndex={1}
                                  type="text"
                                  autoComplete="username"
                                  required
                                  title={
                                    'username: ' +
                                      loginResult?.authParams.username ?? ''
                                  }
                                />
                              </div>
                              {requireOldPwd && (
                                <div className="_mb-6">
                                  <label css={clsLabel} htmlFor="oldPassword">
                                    Current Password
                                  </label>
                                  {/* keycloak does not support old password! */}
                                  <input
                                    css={clsInput}
                                    name="oldPassword"
                                    id="oldPassword"
                                    placeholder="Enter current password"
                                    disabled={isKcForceChangePwd}
                                    {...(isKcForceChangePwd
                                      ? { value: 'Not required for keycloak' }
                                      : {})}
                                    type={
                                      isKcForceChangePwd ? 'text' : 'password'
                                    }
                                    autoComplete="current-password"
                                    tabIndex={2}
                                  />
                                </div>
                              )}
                              <div className="_mb-6">
                                <label css={clsLabel} htmlFor="newPassword">
                                  New Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="newPassword"
                                  id="newPassword"
                                  placeholder="Enter new password"
                                  type="password"
                                  autoComplete="new-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>

                              <div className="_mb-6">
                                <label css={clsLabel} htmlFor="confirmPassword">
                                  Confirm New Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  placeholder="Confirm new password"
                                  type="password"
                                  autoComplete="new-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="_mb-4">
                                <label css={clsLabel} htmlFor="username">
                                  Username
                                </label>
                                <input
                                  css={clsInput}
                                  name="username"
                                  id="username"
                                  placeholder="Enter user name"
                                  autoFocus
                                  tabIndex={1}
                                  type="text"
                                  autoComplete="username"
                                  required
                                />
                              </div>
                              <div className="_mb-6">
                                <label css={clsLabel} htmlFor="password">
                                  Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="password"
                                  id="password"
                                  placeholder="Enter password"
                                  type="password"
                                  autoComplete="current-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>
                            </>
                          )
                        }
                        <div className="_flex _justify-between">
                          <div className=""></div>
                          <button
                            tabIndex={3}
                            type="submit"
                            className="_ml-auto _inline-flex _justify-center _rounded-md _border _border-transparent _bg-blue-100 _px-4 _py-2 _text-sm _font-medium _text-blue-900 hover:_bg-blue-200 focus:_outline-none focus-visible:_ring-2 focus-visible:_ring-blue-500 focus-visible:_ring-offset-2"
                            onClick={closeModal}
                          >
                            {signinMessage}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="_p-2 _text-xs _text-antd-lt-colorError dark:_text-antd-dk-colorError">
                    {lastError}
                  </div>
                  <TermsAndConditions />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
