import { registerSw } from './swRegister';
import { BehaviorSubject } from 'rxjs';
import { SWClient } from './swIpc/client';

const instanceSubject = new BehaviorSubject<SWClient | undefined>(undefined);
const SwClientInstance = instanceSubject.asObservable();
let isInited = false;
const registerServiceWorker = async () => {
  isInited = true;
  return registerSw().then(swReg => {
    swReg.swClient.getVersion().then((v: any) => {
      console.debug(
        `%cActive sw version: ${v.version}`,
        'color:white; background-color:red'
      );
    });
    instanceSubject.next(swReg.swClient);
  });
};

export const getSwClientObservable = () => {
  if (!isInited) {
    registerServiceWorker().catch(e => console.error('sw init error', e));
  }
  return SwClientInstance;
};
