// used with extractIbLineFindingError
import { createUpdateAuditFindingErrorHandler } from '../../shared';
import React, { ReactNode } from 'react';
import { Typography } from 'antd';
const { Text } = Typography;
import { css } from 'twin.macro';
import { Input, Modal, Radio, RadioChangeEvent, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AuditFindingRuleType } from '../types';
import {
  extractIbLineFindingError,
  ExtractIbLineFindingErrorResult,
  IbinErrorInfo,
} from '../../util';
import { createAuditFindingError } from '../../../../../util/errors';
import { nonNullArr } from '../../../util/array';

const getErrorMessagesForFindingInfo = (
  findingInfoArr: IbinErrorInfo[] | undefined
) => {
  if (findingInfoArr && nonNullArr<IbinErrorInfo>(findingInfoArr)) {
    return findingInfoArr.map(fi => {
      return (
        <>
          <Text strong={true}>
            Active IB line findings exist on selected lines.
          </Text>{' '}
          {/*See the IBIN tab for a full view of findings.*/}
        </>
      );
    });
  }
  return null;
};

const getErrorMessagesForError = (error: any) => {
  return error.graphQLErrors
    ? error.graphQLErrors.map(
        ({ message, path }: { message: string; path: string }) =>
          //display all the erros in the errors array
          //TODO: update to audit_finding_pkey
          //check if the error is a primary key error - which indicates
          //that this finding is a duplicate
          message.match(/audit_finding_claim_line_pkey/g)
            ? `This finding already exists, please review the existing finding`
            : `Unexpected System Error, please contact support.\n ${message}`
      )
    : //if all else fails, log to the console with error details
      'Create Claim Review Finding Error: ' + error.message;
};

const ErrorInfo = ({ error }: { error: Error }) => {
  const info = extractIbLineFindingError(error);
  if (info) {
    const findingMessages = getErrorMessagesForFindingInfo(info.findingInfo);
    if (findingMessages && findingMessages.length > 0) {
      return (
        <>
          {/*<Text italic={true}>Failed to save findings - {info.message}:</Text>*/}
          {findingMessages.map((findingMessage: string | ReactNode) => (
            <div>{findingMessage}</div>
          ))}
        </>
      );
    }
  }
  return <div>{getErrorMessagesForError(error)}</div>;
};

export const showFindingSaveError = (error: any) => {
  return Modal.error({
    // icon: <ExclamationCircleOutlined />,
    title: 'Failed to save finding',
    content: <ErrorInfo error={error}></ErrorInfo>,
    // okText: 'Replace Rationale',
    onOk: () => {
      console.log('ok');
    },
    // cancelText: 'Keep',
    onCancel: () => {
      console.log('cancel');
    },
  });
};
