import {
  Form,
  Select,
  InputNumber,
  Input,
  Descriptions,
  Divider,
  Space,
  Typography,
} from 'antd';
const { Text, Link } = Typography;
import accounting from 'accounting-js';
import { css } from '@emotion/react';
import moment from 'moment-timezone';

import { inputPropsCurrencyFormatter } from '../../util';
import React from 'react';
import { FormExtra } from '../../shared/components/form/formExtra';

const { Option } = Select;

const toDate = date =>
  date !== null && date !== undefined ? moment.utc(date) : '';
const toFormattedDate = date => {
  const d = toDate(date);
  return d ? d.format('MM/DD/YY') : date;
};

const dateRange = batchClaim => {
  const { dateOfServiceStart, dateAdmit, dateOfServiceEnd, dateDischarge } =
    batchClaim;

  let startDate = moment.min(
    [dateOfServiceStart, dateAdmit]
      .filter(date => date !== null && date !== undefined)
      .map(date => moment.utc(date))
  );

  const endDate = moment.max(
    [dateOfServiceEnd, dateDischarge]
      .filter(date => date !== null && date !== undefined)
      .map(date => moment.utc(date))
  );

  const dates = [];
  while (!startDate.isAfter(endDate, 'day')) {
    dates.push(moment(startDate));
    startDate.add(1, 'day');
  }

  return dates;
};

const batchClaimLineItemFormItems = ({
  batchClaim,
  originalAuditFinding,
  batchClaimLine,
  auditFindingSeedType,
  revCodeWarning,
}) => {
  const originalBcli =
    originalAuditFinding?.batchClaimLineItemAudit?.batchClaimLineItem;
  const originalBcl =
    originalAuditFinding &&
    batchClaim.batchClaimLines.nodes.find(
      en => en.id === originalAuditFinding.batchClaimLineId
    );

  return (
    <>
      <Divider>Itemized Charge</Divider>
      {!batchClaimLine ? (
        <Form.Item
          key="batchClaimLineItemFormValues.revCodeValue"
          name="batchClaimLineItemFormValues.revCodeValue"
          label="Rev. Code"
          rules={[{ required: true, message: 'Select a rev. code' }]}
          initialValue={
            originalBcl && `${originalBcl.id}:${originalBcl.revCode}`
          }
          extra={
            <FormExtra visible={!!originalAuditFinding}>
              {revCodeWarning ? (
                <Text type="danger">
                  {originalBcl && originalBcl.revCode} - {revCodeWarning}
                </Text>
              ) : (
                originalBcl && originalBcl.revCode
              )}
            </FormExtra>
          }
        >
          <Select>
            {batchClaim.batchClaimLines.nodes.map(
              ({ id, revCode, revDescription, unitCharge }) => {
                return (
                  <Option
                    key={id}
                    value={`${id}:${revCode}`}
                    title={revDescription}
                  >
                    <Space
                      split={<Divider type="vertical" />}
                      css={css`
                        min-width: 100%;
                      `}
                    >
                      <div>{revCode}</div>
                      <div>{accounting.formatMoney(unitCharge)}</div>
                      <div
                        css={css`
                          text-overflow: ellipsis;
                          overflow: hidden;
                          white-space: nowrap;
                          min-width: 8em;
                          max-width: 8em;
                        `}
                      >
                        {revDescription}
                      </div>
                    </Space>
                  </Option>
                );
              }
            )}
          </Select>
        </Form.Item>
      ) : (
        <Descriptions column={3}>
          <Descriptions.Item span={1} label="Rev. Code">
            <></>
          </Descriptions.Item>
          <Descriptions.Item span={2}>
            <Space
              split={<Divider type="vertical" />}
              css={css`
                min-width: 100%;
              `}
            >
              <div>{batchClaimLine.revCode}</div>
              <div>{accounting.formatMoney(batchClaimLine.unitCharge)}</div>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Form.Item
        key="batchClaimLineItemFormValues.dateOfServiceStart"
        name="batchClaimLineItemFormValues.dateOfServiceStart"
        label="Date of Service"
        rules={[{ required: true, message: 'Enter the date of service' }]}
        initialValue={toFormattedDate(originalBcli?.dateOfServiceStart)}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {toFormattedDate(originalBcli?.dateOfServiceStart)}
          </FormExtra>
        }
      >
        <Select placeholder="Select the applicable date of service">
          {dateRange(batchClaim).map(date => (
            <Option key={date.toISOString()} value={date.format('MM/DD/YY')}>
              {date.format('MM/DD/YY')}
            </Option>
          ))}
        </Select>
        {/* <DatePicker
      showToday={false}
      format={'MM/DD/YY'}
      defaultPickerValue={moment.utc(dateOfServiceStart)}
      disabledDate={validDateFilter}
    /> */}
      </Form.Item>
      {/* <Form.Item
    name="dateOfServiceEnd"
    label="Date of Service (End)"
    rules={[
      { required: false, message: 'Enter the date of service (end)' },
    ]}
  >
    <DatePicker
      showToday={false}
      format={'MM/DD/YY'}
      defaultPickerValue={moment.utc(dateOfServiceEnd)}
      disabledDate={validDateFilter}
    />
  </Form.Item> */}
      <Form.Item
        key="batchClaimLineItemFormValues.procedureCode"
        name="batchClaimLineItemFormValues.procedureCode"
        label="CPT/HCPCS Code"
        rules={[{ required: false, message: 'Enter the CPT/HCPCS code' }]}
        initialValue={originalBcli?.procedureCode}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {originalBcli?.procedureCode}
          </FormExtra>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="batchClaimLineItemFormValues.itemNumber"
        name="batchClaimLineItemFormValues.itemNumber"
        label="IB Item Number"
        rules={[{ required: false, message: 'Enter the item number' }]}
        initialValue={originalBcli?.itemNumber}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {originalBcli?.itemNumber}
          </FormExtra>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="batchClaimLineItemFormValues.description"
        name="batchClaimLineItemFormValues.description"
        label="Service Name"
        rules={[
          { required: true, message: 'Enter the description of the line item' },
        ]}
        initialValue={originalBcli?.description}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {originalBcli?.description}
          </FormExtra>
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        key="batchClaimLineItemFormValues.units"
        name="batchClaimLineItemFormValues.units"
        label="Billed Quantity"
        rules={[
          {
            required: true,
            message: 'Enter the number of quantity per date of service',
          },
        ]}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {originalBcli?.units}
          </FormExtra>
        }
        initialValue={originalBcli?.units || 1}
      >
        <InputNumber style={{ width: '50%' }} precision={0} min={1} />
      </Form.Item>
      <Form.Item
        key="batchClaimLineItemFormValues.unitCharge"
        name="batchClaimLineItemFormValues.unitCharge"
        label="Billed Amount"
        rules={[
          {
            required: true,
            message: 'Enter the charge amount per date of service',
          },
        ]}
        initialValue={originalBcli?.unitCharge || 1}
        extra={
          <FormExtra visible={!!originalAuditFinding}>
            {originalBcli?.unitCharge && !isNaN(originalBcli?.unitCharge)
              ? inputPropsCurrencyFormatter.formatter(originalBcli?.unitCharge)
              : ''}
          </FormExtra>
        }
      >
        <InputNumber
          style={{ width: '50%' }}
          min={1}
          {...inputPropsCurrencyFormatter}
        />
      </Form.Item>
      {/*  //DOC: (MV) this doesn't seem to display pre-US184, it seems like UB_CLAIM_LINE always echos thru here*/}
      {/*         presumably b/c of the way the modal/form updates... Shows post US184 but the values aren't real */}
      {/*         so hiding it for now pending understanding the goal...  The formatting is updated a/o 7/2022 */}
      {/*{auditFindingSeedType === 'IB_CLAIM_LINE' ? (*/}
      {/*  <>*/}
      {/*    <Divider />*/}
      {/*    <Descriptions column={3}>*/}
      {/*      <Descriptions.Item label="Total Billed Quantity"></Descriptions.Item>*/}
      {/*      <Descriptions.Item span={2}>{0}</Descriptions.Item>*/}
      {/*      <Descriptions.Item label="Total Billed Amount"></Descriptions.Item>*/}
      {/*      <Descriptions.Item span={2}>*/}
      {/*        {accounting.formatMoney(100)}*/}
      {/*      </Descriptions.Item>*/}
      {/*    </Descriptions>*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  []*/}
      {/*)}*/}
    </>
  );
};

export { batchClaimLineItemFormItems };
