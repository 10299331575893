import React from 'react';
import tw, { css } from 'twin.macro';

const clsCellStyle = css({
  ...tw`_p-0 _m-0 _w-full _h-full _overflow-hidden _flex`,
  '&>div': tw`_line-clamp-2  _leading-5 _align-baseline _my-auto 
      _p-0 _whitespace-normal _break-words _text-xs`,
  'div.ag-right-aligned-cell &>div': tw`_ml-auto`,
});
type LineClampedCellProps = { children: React.ReactNode; className?: string };
const _LineClampedCell: React.FC<LineClampedCellProps> = ({
  children,
  className,
}) => (
  <div css={clsCellStyle} {...(className ? { className } : {})}>
    <div>{children}</div>
  </div>
);
export const LineClampedCell = React.memo(_LineClampedCell);
