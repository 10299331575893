import { InitialEditFindingValues } from '../viewController/types';

export type CommonFieldProps = {
  dataKey: string;
  label?: string;
  initialValues: InitialEditFindingValues;
  formLayout?: any;
  className?: string;
};

export const dataKeyToFieldName = (dataKey: string) =>
  dataKey.includes('.') ? dataKey.split('.') : dataKey;

export const formKey = (dataKey: string) => ({
  name: dataKeyToFieldName(dataKey),
  key: dataKey,
});
