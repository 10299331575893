import { AlaffiaClient } from '@alaffia-technology-solutions/alaffia-client-sdk';
import { AlaffiaClientContext } from './alaffiaClientProvider';
import { useContext } from 'react';

/** Returns an {@link AlaffiaClient} from {@link AlaffiaClientContext}.
 *
 * @remarks
 * `useAlaffiaClient` is a convenience hook returning the {@link AlaffiaClient} defined on {@link AlaffiaClientContext}.
 */
export const useAlaffiaClient = (): AlaffiaClient => {
  const client = useContext<AlaffiaClient>(AlaffiaClientContext);

  if (!client || Object.keys(client).length === 0) {
    throw new Error(
      'useAlaffiaClient: no client found in context - did you forget to wrap your app in AlaffiaClientProvider?'
    );
  }

  return client;
};
