import React, { FC, useMemo } from 'react';
import { Typography, Form, Select, Space, Divider } from 'antd';
import { AuditFindingSeedType } from '../../../createAuditFinding/auditFindingUtil';
import { DenialCodes } from '../../../../queries/denialCodes/useDenialCodes';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';
import { AuditFindingRuleType } from '../../../../../../gql/graphql';
const { Option } = Select;
const { Text } = Typography;

const auditFindingTypeDisplayName = {
  UB_CLAIM: 'Entire Claim',
  UB_CLAIM_LINE: 'Rev. Code',
  IB_CLAIM_LINE: 'Itemized',
};

const denialCodeToOption = ({
  displayName,
  description,
  type: denialCode,
}: {
  displayName: string;
  description: string;
  type: string;
}) => (
  <Option
    key={denialCode}
    value={denialCode}
    // if we shorten description we should include rationale in popup and search:
    title={`${displayName}: ${description}`}
    disabled={
      denialCode === 'UB_CLAIM_LINE_CPT_MOD' ||
      denialCode === 'UB_CLAIM_LINE_CPT_ICD10'
    }
  >
    <span tw="_font-bold">{displayName + ': '}</span>
    {description}
  </Option>
);

export type DenialCodeProps = CommonFieldProps & {
  seedType: AuditFindingSeedType;
  denialCodes: DenialCodes;
  formLayout?: any;
};

export const DenialCode: FC<DenialCodeProps> = ({
  dataKey,
  label = 'Denial Code',
  initialValues,
  seedType,
  denialCodes,
  formLayout,
}) => {
  const { defaultValue, existingFindingValue } =
    useInitialValues<AuditFindingRuleType>(dataKey, initialValues);

  // loading if reference data is not available
  const loading = denialCodes.fetching || !denialCodes.loaded;

  const options = useMemo(
    () => (
      <Select.OptGroup
        key={seedType}
        label={auditFindingTypeDisplayName[seedType]}
      >
        {(
          (!!existingFindingValue &&
          denialCodes.byType[seedType + '_DEPRECATED']
            ? [
                ...(denialCodes.byType[seedType] || []),
                ...(denialCodes.byType[seedType + '_DEPRECATED'] || []),
              ]
            : denialCodes.byType[seedType]) || []
        ).map(en =>
          denialCodeToOption({
            displayName: en.displayName!,
            description: en.description!,
            type: en.type!,
          })
        )}
      </Select.OptGroup>
    ),
    [denialCodes, seedType, existingFindingValue]
  );

  return (
    <>
      <Form.Item
        {...formKey(dataKey)}
        label={label}
        rules={[{ required: true, message: 'Select a denial code' }]}
        initialValue={existingFindingValue || defaultValue}
        {...formLayout}
      >
        <Select
          placeholder="Select Denial Code"
          loading={loading && !denialCodes.byType[seedType]}
          disabled={!seedType}
          virtual={false}
          showSearch={true}
          // filterOption={true}
          optionFilterProp={'title'}
        >
          {!loading && options}
        </Select>
      </Form.Item>
      {/*TODO THIS IS DEPRECATED SINCE ~1 YEAR - DO WE STILL NEED CAPABILITY FOR HISTORIC?*/}
      {/* Denial Code Specific Items */}
      {/*{auditType?.items}*/}
    </>
  );
};
