import React, { useContext, useState } from 'react';
import { InputNumber, Modal } from 'antd';
import tw, { css } from 'twin.macro';
import { ClassNames } from '@emotion/react';
import { Switch } from '../../../common/tw/Switch';
import { UiOptions, UiOptionUpdateDispatch } from './useUiOptions';
import { DesignProvider, IDesignProvider } from './designProvider';

export function UiOptionsDialog({
  showButton,
}: {
  showButton: boolean;
  buttonLabel: string;
  uiOptions: UiOptions;
  updateUiOption: UiOptionUpdateDispatch;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const designProvider: IDesignProvider = useContext(DesignProvider);
  const { options: opts } = designProvider;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  // note the ClassNames/cx prob isn't in use atm, but leaving it here for ref - https://emotion.sh/docs/class-names
  return (
    <ClassNames>
      {({ css, cx }) => (
        <>
          {showButton && (
            <button
              tw="_w-4 _h-4 _bg-transparent _border-0 _m-0 _mr-2 _p-[2px]"
              onClick={() => setIsModalOpen(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                tw="_w-[16px] _h-[16px] _stroke-antd-lt-colorPrimaryText dark:_stroke-antd-dk-colorPrimaryText"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                />
              </svg>
            </button>
          )}
          <Modal
            title="UI Options"
            open={isModalOpen}
            onOk={handleOk}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div>
              Note: this dialog is a prototype intended for use validating
              internal theme wiring between multiple frameworks (it may never be
              released as a feature)
            </div>
            <div tw="_pt-6 _grid _grid-flow-row-dense _grid-cols-2 _grid-rows-3 _gap-4">
              <div>Dark Mode</div>
              <div>
                <Switch
                  checked={opts.darkMode}
                  onChange={checked => designProvider.setDarkMode(checked)}
                />
              </div>

              <div>Use v4 square-er borderRadius </div>
              <div>
                <Switch
                  checked={opts.borderRadius === 2}
                  onChange={checked =>
                    designProvider.setBorderRadius(checked ? 2 : 6)
                  }
                />
              </div>

              <div>Font Size</div>
              <div>
                <InputNumber
                  min={9}
                  max={16}
                  value={opts.fontSize ?? 14}
                  onChange={fs => designProvider.setFontSize(fs ?? 14)}
                />
              </div>

              <div>Reset to defaults</div>
              <div>
                <button
                  tw="_rounded _px-4 _py-0.5 _border-0 hover:_bg-amber-400 _bg-antd-lt-colorWarning dark:_bg-antd-lt-colorWarning"
                  onClick={() => designProvider.reset()}
                >
                  reset
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </ClassNames>
  );
}
