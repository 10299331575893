import { idbGetValue, idbSetValue, idbTryUnwrapErrorEvent } from './indexedDB';
import { useEffect, useState } from 'react';

export function useUserPreference(name) {
  const [pref, setPref] = useState(undefined);
  const [error, setError] = useState(null);
  const [needsUpdate, setNeedsUpdate] = useState(null);

  useEffect(() => {
    loadPreference(
      name,
      result => setPref(result),
      err => setError(err)
    );
  }, [needsUpdate]);

  function savePref(update) {
    savePreference(
      name,
      update,
      result => setNeedsUpdate(update),
      err => setError(err)
    );
  }

  return [pref, savePref, error];
}

// DOC: (MV) these exported functions are primarily for tests but can be used if needed in non-effect code.
// if changed (or call semantics in above hook change) the test should be updated.

export function loadPreference(key, onSuccess, onError) {
  idbGetValue(key)
    .then(result => onSuccess(result))
    .catch(err => onError(idbTryUnwrapErrorEvent(err)));
}

export function savePreference(key, value, onSuccess, onError) {
  idbSetValue(key, value)
    .then(result => onSuccess(result))
    .catch(err => onError(idbTryUnwrapErrorEvent(err)));
}
