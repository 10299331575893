import { gql, useClient, useQuery } from 'urql';
import { useCallback, useContext } from 'react';
import {
  AutodorFindingFragment,
  FindingFragment,
  ItemizedBillLineFragment,
} from './stdFrags.stdgql';
import { UserContext } from '../../../context/user';

export const useClaimPostFindingRefresh = () => {
  const { userType }: any = useContext(UserContext);
  const client = useClient();
  const doRefresh = useCallback(
    async ({
      claimId,
      ibLineIds,
    }: {
      claimId: string;
      ibLineIds: string[];
    }) => {
      if (userType === 'PROVIDER') {
        throw new Error(
          'Unexpected permissions error, please contact support.'
        );
      }
      const q = gql`
        query claimFindingsAndLine($claimId: UUID, $ibLineIds: [UUID!]!) {
          claim(id: $claimId) {
            __typename
            id
            icn
            findings {
              ...FindingFragment
            }
            autodor {
              claimId
              sinks {
                s3Key
                timestamp
              }
              autodorFindings {
                ...autodorFindingFragment
              }
            }
          }
          itemizedBillLines(ids: $ibLineIds) {
            ...ItemizedBillLineFragment
          }
        }
        ${FindingFragment}
        ${ItemizedBillLineFragment}
        ${AutodorFindingFragment}
      `;

      return client
        .query(
          q,
          {
            claimId,
            ibLineIds,
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise();
    },
    [client]
  );

  return doRefresh;
};
