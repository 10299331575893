import { IbinIbLineAdjustments } from '../../createAuditFinding/ibinIbLineAdjustments';
import React, { FC } from 'react';
import { EditFindingAdjustments } from '../viewController/types';

type IbLineAdjustmentInfoProps = {
  adjustments: EditFindingAdjustments | undefined;
};

export const IbLineAdjustmentsInfo: FC<IbLineAdjustmentInfoProps> = ({
  adjustments,
}) => {
  return (
    <div>
      <div tw="_italic">Findings to create:</div>
      <div tw="_h-24 _max-h-24 _text-xs _py-2 _pl-3 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary">
        {adjustments?.paymentRate &&
        adjustments?.improperPaymentUnitsCharged > 0 ? (
          <div tw="_h-20 _max-h-20 _overflow-y-auto">
            {
              <IbinIbLineAdjustments
                paymentRate={adjustments?.paymentRate}
                ibLineAdjustments={adjustments?.ibLineAdjustments ?? []}
              ></IbinIbLineAdjustments>
            }
          </div>
        ) : (
          <div tw="_pl-2 _py-2 _italic _text-xs _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary">
            Select Rev Code and Discrepant Quantity to display finding
            calculations
          </div>
        )}
      </div>
    </div>
  );
};
