import React, { useMemo } from 'react';
import { message } from 'antd';
import { gql, useClient, useQuery } from 'urql';
import { UserContext } from '../context/user';
import { uploadFile, ClaimIntakeUpload } from './util';
import { UploadClaimModalUi } from './uploadClaimModalUi';

type Insurer = {
  id: string;
  name: string;
  clientAbbreviation?: string;
};

const insurersForUserQuery = gql`
  query insurersForUser($id: UUID!) {
    auditorUser(id: $id) {
      id
      userType
      lastName
      firstName
      insurerUsers(orderBy: INSURER_BY_INSURER_ID__NAME_ASC) {
        nodes {
          id
          insurerId
          insurer {
            id
            name
            clientAbbreviation
          }
        }
      }
    }
  }
`;

const insurersForAdminQuery = gql`
  query insurers {
    insurers(orderBy: NAME_ASC) {
      nodes {
        id
        name
        clientAbbreviation
      }
    }
  }
`;

const UploadClaimModal = ({
  open,
  setOpen,
  setUploadClaimPending,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  setUploadClaimPending: (i: number) => void;
}) => {
  const { id, userType }: { id: string; userType: string } =
    React.useContext(UserContext)!; // info: we can remove the ! (not-null-assertion) when context is typed

  const graphqlClient = useClient();

  const [loading, setLoading] = React.useState(false);
  const [uploadList, setUploadList] = React.useState<ClaimIntakeUpload[]>([]);
  const [history, setHistory] = React.useState<ClaimIntakeUpload[]>([]);
  const [insurerId, setInsurerId] = React.useState<string | null>(null);
  const [claimNumber, setClaimNumber] = React.useState<string | null>(null);

  /**
   * copy the completed (by default) transactions to the history list and prune the current session list
   * @param clearCurrent -- resets anything that would have been kept (e.g. reset()); default true
   * @param excludeStatuses  -- array of upload.metadata.statuses not to move to history; default ['ready']
   */
  const copyToHistory = (
    clearCurrent = true,
    excludeStatuses = ['ready'],
    callback?: (uploadListUpdated: {
      addToHistory: ClaimIntakeUpload[];
      keepInCurrent: ClaimIntakeUpload[];
    }) => void
  ) => {
    setUploadList(batchSafeUploadList => {
      const { addToHistory, keepInCurrent } = batchSafeUploadList.reduce<{
        addToHistory: ClaimIntakeUpload[];
        keepInCurrent: ClaimIntakeUpload[];
      }>(
        (acc, upload) => {
          if (excludeStatuses.includes(upload?.metadata?.status ?? '')) {
            if (!clearCurrent) {
              acc.keepInCurrent.push(upload);
            }
          } else {
            acc.addToHistory.push(upload);
          }
          return acc;
        },
        { addToHistory: [], keepInCurrent: [] }
      );
      setHistory(batchSafeHistory => [...batchSafeHistory, ...addToHistory]);
      // and return any leftovers as replacement for the current list:
      if (callback && typeof callback === 'function') {
        callback({
          addToHistory,
          keepInCurrent,
        });
      }
      return [...keepInCurrent];
    });
  };

  const [uploadInProgress, setUploadInProgress] = React.useState(false);

  const isAdmin = userType === 'ADMINISTRATOR';

  // FIXME: this is currently network only because with schema introspection enabled in the graphCache,
  //  it returns the result with data.auditorUser.insurerUsers.nodes[0].insurer === null
  //  this is most likely because at some point there's a query that returns null for this, or there's some id resolve
  //  mismatch in graphCache... needs investigation, however, forcing network-only for now resolves the issue.
  const [{ fetching, data }, refetchInsurersQuery] = useQuery(
    isAdmin
      ? {
          query: insurersForAdminQuery,
          variables: {},
          requestPolicy: 'network-only',
        }
      : {
          query: insurersForUserQuery,
          variables: { id },
          requestPolicy: 'network-only',
        }
  );

  const insurers: Insurer[] =
    fetching || !data
      ? []
      : isAdmin
        ? data.insurers.nodes
        : data.auditorUser.insurerUsers.nodes.map(
            (insUser: { insurer: Insurer }) => insUser.insurer
          );

  const insurerName = useMemo(
    () => insurers.find(ins => ins.id === insurerId)?.name ?? null,
    [insurerId]
  );

  const isSameUpload = (
    upload1: ClaimIntakeUpload,
    upload2: ClaimIntakeUpload
  ) => upload1.uploadFile.uid === upload2.uploadFile.uid;

  const uploadFilesHandler = async () => {
    if (uploadList.length === 0) return;

    setLoading(true);
    setUploadInProgress(true);

    // filter to list to upload/retry by status
    const toUploadFilter = (upload: ClaimIntakeUpload) =>
      upload.metadata.status !== 'success';

    // Show individual file uploading
    setUploadList(batchSafeUploadList =>
      batchSafeUploadList.map(f => {
        return toUploadFilter(f)
          ? { ...f, metadata: { ...f.metadata, status: 'pending' } }
          : { ...f };
      })
    );

    // Wait for all of the uploads to resolve/reject
    const completed = Promise.allSettled(
      // Filter out uploads that have already succeeded
      uploadList.filter(toUploadFilter).map(async upload =>
        uploadFile(upload, graphqlClient)
          .then(successUpload => {
            addOrUpdate(successUpload);
            return Promise.resolve(successUpload);
          })
          .catch(errorUpload => {
            console.log(
              `Upload failed for ${errorUpload.uploadFile.name}${
                errorUpload.error ? ` -- ${errorUpload.error}` : ''
              }`,
              errorUpload
            );
            addOrUpdate(errorUpload);
            return Promise.reject(errorUpload);
          })
      )
    ).then(completed => {
      setLoading(false);
      setUploadInProgress(false);
    });
    return completed;
  };

  const setUploadKind = (updatedUpload: ClaimIntakeUpload, kind: string) => {
    setUploadList(batchSafeUploadList => {
      const newList = batchSafeUploadList.map(existingUpload => {
        if (isSameUpload(existingUpload, updatedUpload)) {
          return {
            ...existingUpload,
            metadata: {
              ...existingUpload.metadata,
              documentUploadKind: kind,
            },
          };
        }
        return existingUpload;
      });

      return markDuplicates(newList);
    });
  };

  const isDuplicateNameAndKind = (
    upload: ClaimIntakeUpload,
    otherUploads: ClaimIntakeUpload[]
  ) =>
    Boolean(upload.metadata.documentUploadKind) &&
    otherUploads.filter(
      otherUpload =>
        otherUpload.uploadFile.name === upload.uploadFile.name &&
        otherUpload.metadata.documentUploadKind ===
          upload.metadata.documentUploadKind &&
        otherUpload.uploadFile.uid !== upload.uploadFile.uid
    ).length > 0;

  const isDuplicateUB = (
    upload: ClaimIntakeUpload,
    otherUploads: ClaimIntakeUpload[]
  ) =>
    upload.metadata.documentUploadKind !== 'UB04_CLAIM_FORM'
      ? false
      : otherUploads.filter(
          otherUpload =>
            otherUpload.metadata.documentUploadKind === 'UB04_CLAIM_FORM' &&
            otherUpload.uploadFile.uid !== upload.uploadFile.uid
        ).length > 0;

  const markDuplicates = (
    newUploadList: ClaimIntakeUpload[]
  ): ClaimIntakeUpload[] =>
    newUploadList.map(upload => {
      const duplicateNameAndKind = isDuplicateNameAndKind(
        upload,
        newUploadList
      );
      const duplicateUB = isDuplicateUB(upload, newUploadList);
      if (
        duplicateNameAndKind !== upload.metadata.duplicateNameAndKind ||
        duplicateUB !== upload.metadata.duplicateUB
      ) {
        return {
          ...upload,
          uploadFile: upload.uploadFile,
          metadata: { ...upload.metadata, duplicateNameAndKind, duplicateUB },
        };
      }
      return upload;
    });

  const addOrUpdate = (newUpload: ClaimIntakeUpload) => {
    setUploadList(batchSafeUploadList => {
      let found = false;
      const newList = [
        ...batchSafeUploadList.map(existingUpload => {
          if (isSameUpload(existingUpload, newUpload)) {
            found = true;
            return {
              ...existingUpload,
              metadata: { ...existingUpload.metadata, ...newUpload.metadata },
              uploadFile: newUpload.uploadFile, // N.B. we do NOT spread since it doesn't have many 'own' properties
            };
          }
          return existingUpload;
        }),
      ];
      if (!found) {
        newList.push(newUpload);
      }

      // mark dupes in new list and return
      return markDuplicates(newList);
    });
  };

  const removeUpload = (uploadToRemove: ClaimIntakeUpload) => {
    setUploadList(batchSafeUploadList => {
      const existingEntry =
        batchSafeUploadList.find(existingUpload =>
          isSameUpload(existingUpload, uploadToRemove)
        ) ?? null;

      if (existingEntry && existingEntry.metadata.status === 'pending') {
        console.log(
          'Skipping remove for in-progress upload',
          uploadToRemove.uploadFile.name
        );
        return batchSafeUploadList;
      }

      // merge with any metadata updates that may have happened but not rendered yet:
      const mergedEntryToRemove = existingEntry
        ? {
            ...existingEntry,
            metadata: {
              ...existingEntry.metadata,
              ...uploadToRemove.metadata,
            },
          }
        : uploadToRemove;

      const newList = [
        ...batchSafeUploadList.filter(
          existingUpload => !isSameUpload(existingUpload, uploadToRemove)
        ),
      ];

      if (existingEntry && mergedEntryToRemove.metadata.status !== 'ready') {
        setHistory(batchSafeHistory => [
          ...batchSafeHistory,
          mergedEntryToRemove,
        ]);
      }
      return markDuplicates(newList);
    });
  };

  const addFileToUploadList = (newUpload: ClaimIntakeUpload) => {
    addOrUpdate(newUpload);
    return false;
  };

  const reset = () => copyToHistory();

  const close = () => {
    copyToHistory(false, ['ready', 'pending', 'error'], updated => {
      setUploadClaimPending(updated.keepInCurrent.length);
      if (updated.keepInCurrent.length > 0) {
        message.warning(
          'Some Claim Upload files were not uploaded, reopen the dialog to see pending uploads'
        );
      }
      setOpen(false);
    });
  };

  const props = {
    open,
    loading,
    uploadList,
    history,
    uploadInProgress,
    insurers,
    insurerName,
    insurerId,
    setInsurerId,
    claimNumber,
    setClaimNumber,
    uploadFilesHandler,
    setUploadKind,
    addFileToUploadList,
    addOrUpdate,
    removeUpload,
    reset,
    close,
  };

  return <UploadClaimModalUi {...props}></UploadClaimModalUi>;
};

export type UploadClaimModalUiProps = {
  open: boolean;
  loading: boolean;
  uploadList: ClaimIntakeUpload[];
  history: ClaimIntakeUpload[];
  uploadInProgress: boolean;
  insurers: Insurer[];
  insurerName: string | null;
  insurerId: string | null;
  setInsurerId: (insurerId: string) => void;
  claimNumber: string | null;
  setClaimNumber: (claimNumber: string) => void;
  uploadFilesHandler: () => void;
  setUploadKind: (upload: ClaimIntakeUpload, uploadKind: string) => void;
  addFileToUploadList: (file: any) => void;
  addOrUpdate: (newUpload: ClaimIntakeUpload) => void;
  removeUpload: (upload: ClaimIntakeUpload) => void;
  reset: () => void;
  close: () => void;
};

export { UploadClaimModal };
