import { IDetailCellRendererParams } from 'ag-grid-community';
import { Finding } from '../../../../../gql/graphql';
import { FindingComment } from './findingComment';
import { AutodorFindingComment } from './autodorFindingComment';
import React from 'react';
import tw from 'twin.macro';
import { isAutodorFinding } from '../../../util/findingType';

const commentDetailCellRenderer = (props: any) => {
  const { findings } = props.getDetailRowData(props);
  const denialCodes = props.context?.current?.denialCodes ?? {};
  const finding = findings[0];
  return finding ? (
    <div tw="_pl-2 _w-full _h-[275px] _overflow-auto">
      <div tw="_h-fit">
        {isAutodorFinding(finding as unknown as Finding) ? (
          <AutodorFindingComment
            autodorFinding={finding}
          ></AutodorFindingComment>
        ) : (
          <FindingComment auditFinding={finding} />
        )}
      </div>
    </div>
  ) : (
    <div>No finding data</div>
  );
};
export const CommentDetailCellRenderer = commentDetailCellRenderer; // React.memo(commentDetailCellRenderer);

export const commentTableDetailRendererParams: Partial<
  IDetailCellRendererParams<Finding, Finding>
> = {
  refreshStrategy: 'rows',
  detailGridOptions: {
    getRowId: (params: any) => params.data?.id,
  },
  getDetailRowData: params => {
    return {
      findings: [params.data],
    };
  },
};
