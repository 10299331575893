import React from 'react';
import tw, { css } from 'twin.macro';
import moment from 'moment-timezone';
import { Space, Typography, Avatar, Descriptions, Tooltip } from 'antd';
import { Comment } from '@ant-design/compatible';
import { ClockCircleOutlined, RobotOutlined } from '@ant-design/icons';
import { UserContext } from '../../../../context/user';
import { AutodorFinding } from '../../../../../gql/graphql';
import { RuleDetails } from './autodorDetails/ruleDetails';
import { getRuleLabelForFinding } from './autodorFindingUtils';

const { Text } = Typography;

const autodorFindingComment = ({
  autodorFinding,
}: {
  autodorFinding: AutodorFinding;
}) => {
  const {
    improperUnits,
    createdAt,
    autodorMetadata: {
      units,
      unitCharge,
      procedureCode,
      procedureCodeModifier,
      rule: { _type: ruleType },
    },
  } = autodorFinding;

  const {
    workstation: { hides },
  } = React.useContext(UserContext) as any;

  const toParagraphs = (rationale: string) =>
    rationale
      ? rationale
          .split('\n')
          .map((text, i) => <Typography key={i}>{text}</Typography>)
      : null;

  const commentProps = {
    ...(hides.auditFindingComment.author
      ? {}
      : {
          author: 'Autodor',
          avatar: <Avatar icon={<RobotOutlined tw="_pt-1.5" />} />,
        }),
    content: (
      <div tw="_flex _mr-8 _pt-2 _h-full">
        <div tw="_basis-5/12 _min-w-[300px] _pl-4">
          <Space direction="vertical" size="small">
            <Descriptions title="" size="small" column={1}>
              <Descriptions.Item
                label="Type"
                key="autodorFindingType"
                data-cy="audit-finding-meta-data-description-autodorFindingType"
                contentStyle={{ fontWeight: 'extrabold' }}
              >
                <div
                  tw="_line-clamp-2  _leading-5 _align-baseline _my-auto
      _p-0 _whitespace-normal _break-words"
                >
                  {getRuleLabelForFinding(autodorFinding)}
                </div>
              </Descriptions.Item>

              <Descriptions.Item
                label="Discrepant Quantity"
                key="improperPaymentUnitsCharged"
                data-cy="audit-finding-meta-data-description-improperPaymentUnitsCharged"
                contentStyle={{ fontWeight: 'extrabold' }}
              >
                {improperUnits}
              </Descriptions.Item>

              <Descriptions.Item
                label="Procedure Code"
                key="procedureCode"
                data-cy="audit-finding-meta-data-description-procedureCode"
                contentStyle={{ fontWeight: 'extrabold' }}
              >
                {procedureCode}
              </Descriptions.Item>

              {procedureCodeModifier && (
                <Descriptions.Item
                  label="Procedure Code Modifier"
                  key="procedureCodeModifier"
                  data-cy="audit-finding-meta-data-description-procedureCodeModifier"
                  contentStyle={{ fontWeight: 'extrabold' }}
                >
                  {procedureCodeModifier}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Space>
        </div>

        <div tw="_basis-7/12  _min-w-[300px] _pl-4">
          <RuleDetails
            autodorMetadata={autodorFinding.autodorMetadata}
          ></RuleDetails>
        </div>
      </div>
    ),
    datetime: (
      <Tooltip title={moment.utc(createdAt).format('MM-DD-YY HH:mm:ss')}>
        <Space>
          <ClockCircleOutlined />
          <span data-cy="audit-finding-comment-created">
            {moment.utc(createdAt).format('MM-DD-YY')}
          </span>
        </Space>
      </Tooltip>
    ),
  };

  return <Comment {...commentProps} />;
};

export const AutodorFindingComment = React.memo(autodorFindingComment);
