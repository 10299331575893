import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Checkbox,
  Descriptions,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import tw, { css } from 'twin.macro';
import accounting from 'accounting-js';
import { createFormItems } from './auditTypeFormItems';
import { batchClaimLineItemFormItems } from './batchClaimLineItemFormItems';
import { IbexIbLineFormItems } from './ibexIbLineFormItems';
import { inputPropsCurrencyFormatter } from '../../util';
import { usePaymentRate } from '../../util/usePaymentRate';
import { useDenialCodes } from '../../queries/denialCodes/useDenialCodes';
import { min } from 'lodash';
import {
  areIbLinesEquivalent,
  calculateAdjustmentAmount,
  calculateDiscrepantAmount,
  findingAdjustmentsFromCsvLines,
  findingAdjustmentsFromIbLines,
} from '../../util/findingAdjustments';
import { IbinIbLineAdjustments } from './ibinIbLineAdjustments';
import { FormExtra } from '../../shared/components/form/formExtra';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
};
const formLayoutWide = {
  ...formLayout,
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formLayoutFull = {
  ...formLayout,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const clsLabel = css({
  fontWeight: 'bold',
});

const money = val => accounting.formatMoney(val);

const improperUnitsField = (originalAuditFinding, max = null) => (
  <Form.Item
    key="improperPaymentUnitsCharged"
    name="improperPaymentUnitsCharged"
    label="Discrepant Quantity"
    rules={[
      {
        required: true,
        message: 'Enter the improper number of units charged',
      },
    ]}
    extra={
      <FormExtra visible={!!originalAuditFinding}>
        {originalAuditFinding &&
          originalAuditFinding.improperPaymentUnitsCharged}
      </FormExtra>
    }
    initialValue={
      (originalAuditFinding &&
        originalAuditFinding.improperPaymentUnitsCharged) ??
      1
    }
  >
    <InputNumber
      style={{ width: '50%' }}
      precision={0}
      min={1}
      {...(max && { max })}
    />
  </Form.Item>
);

const improperPaymentCostField = (
  originalAuditFinding,
  flagged = false,
  addlExtraJsx = undefined
) => {
  return (
    <Form.Item
      key="improperPaymentCost"
      name="improperPaymentCost"
      label="Adjustment Amount"
      css={css({
        ...(flagged
          ? {
              '& .ant-input-number': tw`!_border !_border-solid _border-antd-lt-colorWarningText dark:_border-antd-dk-colorWarningText`,
            }
          : {}),
      })}
      rules={[{ required: true, message: 'Enter the adjustment amount ($)' }]}
      extra={
        <FormExtra visible={!!originalAuditFinding || addlExtraJsx}>
          <div tw="_flex">
            <div tw="_min-w-[3em]">
              {originalAuditFinding &&
                money(originalAuditFinding.improperPaymentCost)}
            </div>
            <div tw="_pl-0.5">{addlExtraJsx}</div>
          </div>
        </FormExtra>
      }
      initialValue={
        originalAuditFinding && originalAuditFinding.improperPaymentCost
      }
    >
      <InputNumber style={{ width: '50%' }} {...inputPropsCurrencyFormatter} />
    </Form.Item>
  );
};

const adjustmentFormItems = ({
  originalAuditFinding,
  discrepantAmount,
  paymentRate,
  numFindings,
}) => (
  <>
    {improperUnitsField(originalAuditFinding)}
    <Descriptions column={2}>
      <Descriptions.Item span={1} label="Discrepant Amount">
        <></>
      </Descriptions.Item>
      <Descriptions.Item span={1}>
        {Number.isFinite(discrepantAmount) ? money(discrepantAmount) : '-'}
      </Descriptions.Item>
      <Descriptions.Item span={1} label="Payment Rate">
        <></>
      </Descriptions.Item>
      <Descriptions.Item
        contentStyle={paymentRate === null ? { color: 'lightgray' } : {}}
        span={1}
      >
        {paymentRate === null
          ? '(select Rev Code for rate)'
          : `${accounting.toFixed(paymentRate * 100, 2)}%`}
      </Descriptions.Item>
      {numFindings > 1 && [
        <Descriptions.Item
          key="totalDiscrepantAmount"
          span={1}
          label="Total Discrepant Amount"
        >
          <></>
        </Descriptions.Item>,
        <Descriptions.Item key="numFindings" span={1}>
          {Number.isFinite(discrepantAmount)
            ? `${numFindings} findings X ${money(discrepantAmount)} = ${money(
                discrepantAmount * numFindings
              )}`
            : '-'}
        </Descriptions.Item>,
      ]}
    </Descriptions>
    {improperPaymentCostField(originalAuditFinding)}
  </>
);

const piaFormItems = ({ originalAuditFinding }) => (
  <>
    {/* <Form.Item
      key="confidence"
      name="confidence"
      label="Confidence"
      rules={[{ required: true }]}
      extra={
        originalAuditFinding &&
        confidenceToString(originalAuditFinding.confidence)
      }
    >
      <Radio.Group
        options={[
          { label: 'Auto', value: 80.0 },
          { label: 'Semi-Auto', value: 50.0 },
          { label: 'Complex Review', value: 25.0 },
        ]}
        optionType="button"
      />
    </Form.Item> */}
    <Form.Item
      key="improperPaymentReason"
      name="improperPaymentReason"
      label="Rationale"
      rules={[
        {
          required: true,
          message: 'Enter a rationale for this finding',
        },
      ]}
      initialValue={
        originalAuditFinding && originalAuditFinding.improperPaymentReason
      }
      extra={
        <FormExtra visible={!!originalAuditFinding}>
          <div tw="_whitespace-pre-line _max-h-12 _overflow-y-auto">
            {originalAuditFinding?.improperPaymentReason}
          </div>
        </FormExtra>
      }
      {...formLayoutFull}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  </>
);

const generalFormItems = ({
  batchClaim,
  originalAuditFinding,
  denialCodesByType,
  loading,
  auditFindingSeedType,
  auditFindingRuleType,
}) => {
  const denialCodeToOption = ({
    displayName,
    description,
    type: denialCode,
    clsLabel,
  }) => (
    <Option
      key={denialCode}
      value={denialCode}
      // if we shorten description we should include rationale in popup and search:
      title={`${displayName}: ${description}`}
      disabled={
        denialCode === 'UB_CLAIM_LINE_CPT_MOD' ||
        denialCode === 'UB_CLAIM_LINE_CPT_ICD10'
      }
    >
      <span css={clsLabel}>{displayName + ': '}</span>
      {description}
    </Option>
  );

  const auditType = createFormItems(
    auditFindingRuleType,
    batchClaim,
    originalAuditFinding
  );

  const auditFindingTypeDisplayName = {
    UB_CLAIM: 'Entire Claim',
    UB_CLAIM_LINE: 'Rev. Code',
    IB_CLAIM_LINE: 'Itemized',
  };
  const options = () => (
    <Select.OptGroup
      key={auditFindingSeedType}
      label={auditFindingTypeDisplayName[auditFindingSeedType]}
    >
      {(
        (originalAuditFinding &&
        denialCodesByType[auditFindingSeedType + '_DEPRECATED']
          ? [
              ...(denialCodesByType[auditFindingSeedType] || []),
              ...(denialCodesByType[auditFindingSeedType + '_DEPRECATED'] ||
                []),
            ]
          : denialCodesByType[auditFindingSeedType]) || []
      ).map(en =>
        denialCodeToOption({
          ...en,
          clsLabel,
        })
      )}
    </Select.OptGroup>
  );

  const currentSeedType =
    originalAuditFinding?.auditFindingSeedType || auditFindingSeedType;
  const initialSeedType = useRef(currentSeedType);

  return (
    <>
      <Form.Item
        key="auditFindingSeedType"
        name="auditFindingSeedType"
        label="Type"
        extra={<FormExtra visible={false}></FormExtra>}
        rules={[{ required: true, message: 'Select a finding type' }]}
        initialValue={
          originalAuditFinding?.auditFindingSeedType || currentSeedType
        }
        {...formLayoutWide}
      >
        <Radio.Group
          disabled={originalAuditFinding?.auditFindingRuleType}
          options={
            {
              UB_CLAIM: [{ label: 'Entire Claim', value: 'UB_CLAIM' }],
              UB_CLAIM_LINE: [
                { label: 'Rev. Code', value: 'UB_CLAIM_LINE' },
                { label: 'Itemized', value: 'IB_CLAIM_LINE' },
              ],
              IB_CLAIM_LINE: [{ label: 'Itemized', value: 'IB_CLAIM_LINE' }],
            }[initialSeedType.current]
          }
          optionType="button"
        />
      </Form.Item>
      <Form.Item
        key="auditFindingRuleType"
        name="auditFindingRuleType"
        label="Denial Code"
        rules={[{ required: true, message: 'Select a denial code' }]}
        initialValue={
          originalAuditFinding?.auditFindingRuleType || auditFindingRuleType
        }
        {...formLayoutWide}
      >
        <Select
          placeholder="Select Denial Code"
          loading={loading && !denialCodesByType[auditFindingSeedType]}
          disabled={!auditFindingSeedType}
          virtual={false}
          showSearch={true}
          // filterOption={true}
          optionFilterProp={'title'}
        >
          {!loading && options()}
        </Select>
      </Form.Item>
      {/* Denial Code Specific Items */}
      {auditType?.items}
    </>
  );
};

const CreateClaimLineAuditFindingForm = props => {
  const {
    batchClaim,
    batchClaimLine,
    originalAuditFinding,
    onFinish,
    onFinishFailed,
    form,
    auditFindingSeedType: initialAuditFindingSeedType,
    numFindings = 1,
    isIbexTab: isIbinTab,
    ibData,
    ibLineAdjustments,
    setIbLineAdjustments,
  } = props;

  const denialCodes = useDenialCodes();

  // loading if reference data is not available
  const loading = denialCodes.fetching || !denialCodes.loaded;

  // read the seed type
  const auditFindingSeedType =
    Form.useWatch('auditFindingSeedType', form) || initialAuditFindingSeedType;

  // the type of form to display
  const IbLineFormType = useMemo(
    () =>
      !auditFindingSeedType?.startsWith('IB_')
        ? 'NONE'
        : isIbinTab
          ? 'IBEX_FORM'
          : 'IB_CSV_FORM',
    [auditFindingSeedType]
  );

  // read the selected denial code from the form
  const frmAuditFindingRuleType =
    Form.useWatch('auditFindingRuleType', form) ||
    originalAuditFinding?.auditFindingRuleType;

  // watch form values to dynamically compute the discrepant and adjustment amounts
  const frmImproperPaymentUnitsCharged = Form.useWatch(
    'improperPaymentUnitsCharged',
    form
  );

  const frmImproperPaymentCost = Form.useWatch('improperPaymentCost', form);
  const frmBilledAmountOverride = Form.useWatch('billedAmountOverride', form);

  const frmAmountCharged =
    Form.useWatch('batchClaimLineItemFormValues.unitCharge', form) ??
    originalAuditFinding?.batchClaimLineItemAudit?.batchClaimLineItem
      ?.unitCharge ??
    batchClaimLine?.unitCharge;

  const frmUnitsBilled =
    Form.useWatch('batchClaimLineItemFormValues.units', form) ||
    originalAuditFinding?.batchClaimLineItemAudit?.batchClaimLineItem?.units ||
    batchClaimLine?.units;

  // used by paymentRate on IB lines...
  const revCodeFieldValue = Form.useWatch(
    'batchClaimLineItemFormValues.revCodeValue',
    form
  );

  const paymentRateUtility = usePaymentRate(batchClaim);

  const paymentRate = useMemo(() => {
    if (batchClaimLine && revCodeFieldValue) {
      console.warn(
        'Unexpected: batchClaimLine and revCode selector are both present; payment rate will be sourced from batchClaimLine.'
      );
    }

    if (batchClaimLine) {
      return paymentRateUtility.getActiveLineRate(batchClaimLine);
    }

    const isReviewingExistingIbTabFinding =
      originalAuditFinding && !batchClaimLine;

    const [bclIdFromRevCodeField, _revCode] =
      revCodeFieldValue?.length > 0 // create-mode on IB so field is populated, use it:
        ? revCodeFieldValue?.split(':')
        : [null, null];

    if (!isReviewingExistingIbTabFinding && !bclIdFromRevCodeField) {
      return null;
    }

    const bclIdToUse = isReviewingExistingIbTabFinding
      ? originalAuditFinding.batchClaimLineId
      : bclIdFromRevCodeField;

    // if there's a line provided, we're in UB-tab, whether AF is UB/IB... use line provided; else use line calculated above
    const bclForCalculation =
      paymentRateUtility.isPaymentRateLineLevel && bclIdToUse && !batchClaimLine
        ? batchClaim.batchClaimLines.nodes.find(en => en.id === bclIdToUse)
        : batchClaimLine;

    return paymentRateUtility.getActiveLineRate(bclForCalculation);
  }, [revCodeFieldValue, batchClaimLine, paymentRateUtility]);

  const revCodeWarning = useMemo(() => {
    if (!originalAuditFinding || batchClaimLine) {
      // batchClaimLine's presence indicates we're on the UB tab and can't change rev code, no AF means new finding...
      return null;
    }
    // we're editing ib finding, find the original finding and compare the field value to the finding's value
    const [bclIdFromRevCodeField, _revCode] =
      revCodeFieldValue?.length > 0 // create-mode on IB so field is populated, use it:
        ? revCodeFieldValue?.split(':')
        : [null, null];
    const originalBcl = batchClaim.batchClaimLines?.nodes.find(
      en => en.id === originalAuditFinding.batchClaimLineId
    );
    const originalBclId = originalBcl?.id ?? null;

    return originalBclId === bclIdFromRevCodeField
      ? undefined
      : 'Warning: Changing Rev. Code will decline the exiting finding and create a new finding!';
  }, [revCodeFieldValue, batchClaimLine]);

  const maxUnits = useMemo(
    () => ((ibData?.length ?? 0) > 0 ? min(ibData.map(x => x.units)) : null),
    [ibData]
  );

  const ibLinesAreEquivalent = useMemo(
    () => isIbinTab && areIbLinesEquivalent(ibData),
    [ibData]
  );

  const [isRepricing, setIsRepricing] = useState(false);
  const toggleRepricing = () => {
    {
      lastCalculatedFieldIpc.current = calculateAdjustmentAmount(
        calculateDiscrepantAmount({
          improperPaymentUnitsCharged: frmImproperPaymentUnitsCharged,
          amountCharged: ibData[0].totalCharge,
          unitsBilled: ibData[0].units,
        }),
        paymentRate
      );
      setIsRepricing(_isRepricing => !_isRepricing);
    }
  };
  useEffect(() => {
    if (
      !!originalAuditFinding?.batchClaimLineItemAudit?.batchClaimLineItem &&
      !isNaN(paymentRate) &&
      ibLinesAreEquivalent &&
      !isRepricing
    ) {
      const bcli =
        originalAuditFinding.batchClaimLineItemAudit.batchClaimLineItem;
      const hasOriginalAmountCharged =
        bcli.originalBilledAmount !== null &&
        bcli.originalBilledAmount !== undefined;
      const origTotalChargePerFindingSnapshot = hasOriginalAmountCharged
        ? bcli.originalBilledAmount
        : ibData[0].totalCharge;
      const isAmountChargedDifferent =
        bcli.unitCharge !== origTotalChargePerFindingSnapshot;
      const calcIpc = calculateAdjustmentAmount(
        calculateDiscrepantAmount({
          improperPaymentUnitsCharged:
            originalAuditFinding.improperPaymentUnitsCharged,
          amountCharged: origTotalChargePerFindingSnapshot,
          unitsBilled: ibData[0].units,
        }),
        paymentRate
      );
      const isIpcDifferent =
        originalAuditFinding.improperPaymentCost !== calcIpc;
      const _isRepricing = isAmountChargedDifferent || isIpcDifferent;
      if (_isRepricing) {
        lastCalculatedFieldIpc.current = calculateAdjustmentAmount(
          calculateDiscrepantAmount({
            improperPaymentUnitsCharged:
              originalAuditFinding.improperPaymentUnitsCharged,
            amountCharged:
              originalAuditFinding.batchClaimLineItemAudit.batchClaimLineItem
                .unitCharge,
            unitsBilled: ibData[0].units,
          }),
          paymentRate
        );
        setIsRepricing(true);
      }
    }
  }, [originalAuditFinding]);

  // calculate the discrepant amount and adjustment amount,
  // set in state on modal for use in creating finding input payload
  useEffect(() => {
    if (isIbinTab) {
      // for IBIN tab, map the values from the selected lines, per line
      setIbLineAdjustments(
        findingAdjustmentsFromIbLines({
          ibLines: ibData,
          paymentRate,
          improperPaymentUnitsCharged: frmImproperPaymentUnitsCharged,
          improperPaymentCost: frmImproperPaymentCost,
          amountChargedOverride: frmBilledAmountOverride,
          improperPaymentCostOverride: frmImproperPaymentCost,
        })
      );
    } else {
      // for the csv tab, it's whatever the form values are, for all findings...
      setIbLineAdjustments(
        findingAdjustmentsFromCsvLines({
          amountCharged: frmAmountCharged,
          unitsBilled: frmUnitsBilled,
          paymentRate,
          improperPaymentUnitsCharged: frmImproperPaymentUnitsCharged,
          improperPaymentCost: frmImproperPaymentCost,
        })
      );
    }
  }, [
    originalAuditFinding,
    frmAmountCharged,
    frmUnitsBilled,
    ibData,
    revCodeFieldValue,
    paymentRate,
    frmImproperPaymentUnitsCharged,
    frmImproperPaymentCost,
    frmBilledAmountOverride,
  ]);

  // update the adjustment amount whenever discrepant amount or payment rate changes
  React.useEffect(() => {
    if (
      isRepricing &&
      ibLineAdjustments &&
      (!isIbinTab || ibLinesAreEquivalent) &&
      !!paymentRate
    ) {
      form.setFieldsValue({
        improperPaymentCost:
          ibLineAdjustments[0]?.overrides?.improperPaymentCost ??
          originalAuditFinding?.improperPaymentCost ??
          ibLineAdjustments[0]?.improperPaymentCost,
      });
    }
  }, [isRepricing, paymentRate, originalAuditFinding]);

  // calculate the target IPC based on the ibLine (for comparison and UI '[use]' conv. fn)
  const calculatedIbinOverrideIpc = useMemo(() => {
    if (
      isIbinTab &&
      isRepricing &&
      !isNaN(paymentRate) &&
      frmBilledAmountOverride
    ) {
      return calculateAdjustmentAmount(
        calculateDiscrepantAmount({
          improperPaymentUnitsCharged: frmImproperPaymentUnitsCharged,
          amountCharged: frmBilledAmountOverride,
          unitsBilled: ibData[0].units,
        }),
        paymentRate
      );
    }
  }, [
    isRepricing,
    paymentRate,
    frmBilledAmountOverride,
    frmImproperPaymentUnitsCharged,
  ]);

  // same as existing finding if exists:
  const lastCalculatedFieldIpc = useRef(0);

  // calculate the target IPC based on the form values
  const calculatedFieldIpc = useMemo(() => {
    if (
      frmImproperPaymentUnitsCharged &&
      frmAmountCharged &&
      frmUnitsBilled &&
      !isNaN(paymentRate)
    ) {
      const discrepantAmount = calculateAdjustmentAmount(
        calculateDiscrepantAmount({
          improperPaymentUnitsCharged: frmImproperPaymentUnitsCharged,
          amountCharged: frmAmountCharged,
          unitsBilled: frmUnitsBilled,
        }),
        paymentRate
      );
      return discrepantAmount;
    }
  }, [
    frmImproperPaymentUnitsCharged,
    frmUnitsBilled,
    frmAmountCharged,
    revCodeFieldValue,
  ]);

  useLayoutEffect(() => {
    if (isIbinTab) {
      if (isRepricing) {
        if (
          !frmImproperPaymentCost &&
          originalAuditFinding?.improperPaymentCost
        ) {
          // we have a value from the original finding, default the field to that:
          form.setFieldsValue({
            improperPaymentCost: originalAuditFinding?.improperPaymentCost,
          });
        } else {
          // determine if we can update the field automatically - if edited, we should not, but show calc helper
          // does the field value === the LAST auto-calculated IPC (e.g. when Adj Billed Amt changed)
          const fieldMatchesPrevCalc =
            money(lastCalculatedFieldIpc.current) ===
            money(frmImproperPaymentCost);
          // does the field value == the current auto-calculated IPC
          const fieldMatchesCurrentCalc =
            frmImproperPaymentCost === calculatedIbinOverrideIpc;
          if (
            (!frmImproperPaymentCost && !lastCalculatedFieldIpc.current) ||
            (fieldMatchesPrevCalc && !fieldMatchesCurrentCalc)
          ) {
            lastCalculatedFieldIpc.current = calculatedIbinOverrideIpc;
            form.setFieldsValue({
              improperPaymentCost: calculatedIbinOverrideIpc,
            });
          }
        }
      }
    } else {
      if (
        !frmImproperPaymentCost ||
        !lastCalculatedFieldIpc.current ||
        frmImproperPaymentCost !== calculatedFieldIpc
      ) {
        lastCalculatedFieldIpc.current = calculatedFieldIpc;
        form.setFieldsValue({ improperPaymentCost: calculatedFieldIpc });
      }
    }
  }, [calculatedIbinOverrideIpc, calculatedFieldIpc]);

  const lastSelectedRuleType = useRef(frmAuditFindingRuleType);

  const denialCodesByType = useMemo(
    () => denialCodes?.byType ?? {},
    [denialCodes]
  );

  // improvement: this probably isn't needed if type is tied to tab selected during create
  // handle switching seed types by selecting equivalent rule or clearing rule type
  useEffect(() => {
    if (originalAuditFinding) {
      return;
    }
    const lastRule = lastSelectedRuleType.current;
    const lastRuleId =
      lastRule && lastRule.type.substring(lastRule.claimDataType.length + 1);
    if (
      auditFindingSeedType &&
      lastRule?.claimDataType &&
      auditFindingSeedType !== lastRule.claimDataType
    ) {
      if (lastRuleId) {
        const equivRuleType = (
          denialCodesByType[auditFindingSeedType] || []
        ).find(code => code.type === auditFindingSeedType + '_' + lastRuleId);
        if (equivRuleType) {
          form.setFieldsValue({ auditFindingRuleType: equivRuleType.type });
          return;
        }
      }
      form.setFieldsValue({ auditFindingRuleType: '' });
    }
  }, [auditFindingSeedType]);

  const skipRationaleChangePromptForTypes = useRef([frmAuditFindingRuleType]);
  // handle changes to rule type by applying new rationale template
  useEffect(() => {
    if (loading) {
      return;
    }
    const currentRule = denialCodes.byKey[frmAuditFindingRuleType];
    const lastRule = lastSelectedRuleType.current;
    lastSelectedRuleType.current = currentRule;
    const currentRationale =
      form.getFieldValue('improperPaymentReason') ??
      originalAuditFinding?.improperPaymentReason;

    const setNewRationale = () =>
      form.setFieldsValue({
        improperPaymentReason: currentRule?.rationaleTemplate || '',
      });

    if (!lastRule) {
      // it's a new finding, no need to check if the rationale was modified.
      setNewRationale();
    }

    // check if the rationale template has been modified and give user opts
    if (lastRule && lastRule !== frmAuditFindingRuleType) {
      if (currentRationale && lastRule.description !== currentRationale) {
        if (
          skipRationaleChangePromptForTypes.current.includes(
            frmAuditFindingRuleType
          )
        ) {
          // we already prompted for this rule type and the user kept the rationale, don't prompt again
          return;
        }

        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          title: 'Replace edited rationale?',
          content: (
            <div
              css={css({
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                padding: '0 0.5em 0 0.5em',
                '> div > div': {
                  margin: '1em 0',
                },
              })}
            >
              <div>
                <div>
                  The default rationale template for the previous denial code
                  selection has been modified
                </div>
                <div>
                  Choose Replace to overwrite it with the new default rationale,
                  or Keep to preserve the existing text and update it manually
                </div>
              </div>
            </div>
          ),
          okText: 'Replace Rationale',
          onOk() {
            skipRationaleChangePromptForTypes.current.push(
              frmAuditFindingRuleType
            );
            setNewRationale();
          },
          cancelText: 'Keep',
          onCancel() {
            skipRationaleChangePromptForTypes.current.push(
              frmAuditFindingRuleType
            );
            // leave the existing modified text
          },
        });
      } else {
        // the existing rationale was not modified, go ahead and change without prompting
        setNewRationale();
      }
    }
  }, [frmAuditFindingRuleType]);

  // form is preserve=false to clear state between invocations,
  // the tracking of things through Form.useWatch() and managing them
  // doesn't appear to get cleared correctly when form.resetFields() is called
  return (
    <Form
      key={'CreateAuditFindingForm'}
      name="create_audit_finding_form"
      form={form}
      css={css({ '& .ant-form-item': tw`_mb-0` })}
      preserve={false}
      autoComplete="off"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        confidence: originalAuditFinding
          ? originalAuditFinding.confidence
          : 50.0,
      }}
      {...(auditFindingSeedType === 'UB_CLAIM' ? formLayoutWide : formLayout)}
    >
      {/* General */}
      {generalFormItems({
        auditFindingSeedType,
        denialCodesByType,
        batchClaim,
        originalAuditFinding,
        loading,
        auditFindingRuleType: frmAuditFindingRuleType,
      })}
      {/* IB-CSV Batch Claim Line Item */}
      {IbLineFormType === 'IB_CSV_FORM' &&
        batchClaimLineItemFormItems({
          batchClaim,
          originalAuditFinding,
          batchClaimLine, // changes rev code display if IB finding created on UB tab
          auditFindingSeedType,
          revCodeWarning,
        })}
      {/* IBEX (auto-populated, readonly) */}
      {IbLineFormType === 'IBEX_FORM' &&
        IbexIbLineFormItems({
          batchClaim,
          originalAuditFinding,
          batchClaimLine, // changes rev code display if IB finding created on UB tab
          auditFindingSeedType,
          revCodeWarning,
          isIbexTab: isIbinTab,
          ibData,
        })}
      {/* Improper Payment */}
      {auditFindingSeedType !== 'UB_CLAIM' && !isIbinTab ? (
        <>
          <Divider>Adjustment</Divider>
          {adjustmentFormItems({
            originalAuditFinding,
            discrepantAmount: ibLineAdjustments[0]?.discrepantAmount,
            paymentRate,
            numFindings,
          })}
        </>
      ) : isIbinTab ? (
        <>
          <Divider tw="!_mb-0 _pb-0">Adjustment</Divider>

          <div tw="_flex">
            <div></div>
            <Tooltip
              title={
                !ibLinesAreEquivalent
                  ? 'Repricing requires all selected lines to have the same values'
                  : !revCodeFieldValue
                    ? 'Select Rev Code to enable repricing'
                    : 'Select to adjust billed/adjustment amount'
              }
            >
              <Checkbox
                tw="_ml-auto !_text-xs"
                checked={isRepricing}
                disabled={!ibLinesAreEquivalent || !revCodeFieldValue}
                onChange={() => toggleRepricing()}
              >
                Reprice?
              </Checkbox>
            </Tooltip>
          </div>
          {improperUnitsField(originalAuditFinding, maxUnits)}

          {ibLinesAreEquivalent && isRepricing && (
            <Form.Item
              key="billedAmountOverride"
              name="billedAmountOverride"
              label="Adj. Billed Amount"
              rules={[
                {
                  required: true,
                  message: 'Enter the charge amount per date of service',
                },
              ]}
              initialValue={
                originalAuditFinding?.batchClaimLineItemAudit
                  ?.batchClaimLineItem?.unitCharge ?? ibData[0]?.totalCharge
              }
              extra={
                <FormExtra visible={!!originalAuditFinding}>
                  {originalAuditFinding?.batchClaimLineItemAudit
                    ?.batchClaimLineItem?.unitCharge ?? ' '}
                </FormExtra>
              }
            >
              <InputNumber
                style={{ width: '50%' }}
                min={1}
                {...inputPropsCurrencyFormatter}
              />
            </Form.Item>
          )}

          {ibLinesAreEquivalent &&
            isRepricing &&
            improperPaymentCostField(
              originalAuditFinding,
              money(calculatedIbinOverrideIpc) !==
                money(frmImproperPaymentCost),
              money(calculatedIbinOverrideIpc) !==
                money(frmImproperPaymentCost) ? (
                <div>
                  <span tw="_text-antd-lt-colorWarningText dark:_text-antd-dk-colorWarningText">
                    {`${frmImproperPaymentUnitsCharged} @ ${accounting.toFixed(
                      paymentRate * 100,
                      1
                    )}% = ${money(calculatedIbinOverrideIpc)}`}
                  </span>
                  <span
                    tw="_pl-1 _text-antd-lt-colorLink dark:_text-antd-dk-colorLink"
                    onClick={() => {
                      lastCalculatedFieldIpc.current =
                        calculatedIbinOverrideIpc;
                      form.setFieldsValue({
                        improperPaymentCost: calculatedIbinOverrideIpc,
                      });
                    }}
                  >
                    [use]
                  </span>
                </div>
              ) : undefined
            )}

          <div tw="_italic">Findings to create:</div>
          <div tw="_h-24 _max-h-24 _text-xs _py-2 _pl-3 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary">
            {!!revCodeFieldValue && frmImproperPaymentUnitsCharged > 0 ? (
              <div tw="_h-20 _max-h-20 _overflow-y-auto">
                {
                  <IbinIbLineAdjustments
                    paymentRate={paymentRate}
                    ibLineAdjustments={ibLineAdjustments}
                    overrideImproperPaymentCost={frmImproperPaymentCost}
                  ></IbinIbLineAdjustments>
                }
              </div>
            ) : (
              <div tw="_pl-2 _py-2 _italic _text-xs _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary">
                Select Rev Code and Discrepant Quantity to display finding
                calculations
              </div>
            )}
          </div>
        </>
      ) : (
        []
      )}
      {/* PIA Input */}
      {piaFormItems({
        originalAuditFinding,
      })}
    </Form>
  );
};

export { CreateClaimLineAuditFindingForm };
