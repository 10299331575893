import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { Statistic, Tooltip } from 'antd';
import { formatMoney } from 'accounting-js';
import { css } from '@emotion/react';

// styling
const statStyle = css`
  display: flex;
  justify-content: flex-start;
  padding-left: 16px;
  padding-top: 10px;
`;

const valueRenderFromDisplayType = {
  count: value => value,
  dollar: value => formatMoney(value, { symbol: '' }),
};

/**
 * Returns a statistic component given a value and other configuration items
 * @returns AntdStatistic
 */
const StatStatic = ({
  title,
  value,
  units,
  description,
  showTooltip,
  antdTooltipProps,
  antdRenderProps,
  prefix,
}) => {
  const formattedTitle = title.replaceAll(' ', '-').toLowerCase();
  return (
    <div
      id={`stat-panel-stat-inner-wrapper-div-${formattedTitle}`}
      key={`stat-panel-stat-inner-wrapper-div-${formattedTitle}`}
      css={statStyle}
    >
      <Tooltip
        id={`stat-panel-stat-tooltip-${formattedTitle}`}
        key={`stat-panel-stat-tooltip-${formattedTitle}`}
        title={showTooltip ? description : null}
        {...{ ...antdTooltipProps }}
      >
        <Statistic
          id={`stat-panel-stat-antd-stat-${formattedTitle}`}
          key={`stat-panel-stat-antd-stat-${formattedTitle}`}
          data-cy={`stat-panel-stat-antd-stat-${formattedTitle}`}
          title={title}
          // if the value is greater than 0 - show the value
          // if the value is zero, check if it's dollars and format the display
          // else return the value as given from the api
          value={value > 0 ? value : units === 'DOLLARS' ? '0.00' : value}
          {...{ ...antdRenderProps, prefix }}
        />
      </Tooltip>
    </div>
  );
};

/**
 * Generates a statistic component given a query to run
 * @returns AntdStatistic
 */
const StatWithQuery = ({
  filter,
  title,
  description,
  query,
  baseQueryType,
  reducer,
  displayType,
  showTooltip,
  antdTooltipProps,
  antdRenderProps,
  prefix,
}) => {
  const [value, setValue] = useState(null);
  const [{ data, fetching, error }] = useQuery({
    query,
    variables: { ...filter },
  });

  useEffect(() => {
    if (!fetching && data) {
      // execute the reducer function to retrieve the stat value
      setValue(reducer({ data, baseQueryType }));
    }
  }, [fetching]);

  const formattedTitle = title.replaceAll(' ', '-').toLowerCase();
  return (
    <div
      id={`stat-panel-stat-inner-wrapper-div-${formattedTitle}`}
      key={`stat-panel-stat-inner-wrapper-div-${formattedTitle}`}
      css={statStyle}
    >
      <Tooltip
        id={`stat-panel-stat-tooltip-${formattedTitle}`}
        key={`stat-panel-stat-tooltip-${formattedTitle}`}
        title={showTooltip ? description : null}
        {...{ ...antdTooltipProps }}
      >
        <Statistic
          id={`stat-panel-stat-antd-stat-${formattedTitle}`}
          key={`stat-panel-stat-antd-stat-${formattedTitle}`}
          data-cy={`stat-panel-stat-antd-stat-${formattedTitle}`}
          loading={fetching}
          title={title}
          value={valueRenderFromDisplayType[displayType](value)}
          // renders suffix, precision, valueStyle (css), style (css), etc
          {...{ ...antdRenderProps, prefix }}
        />
      </Tooltip>
    </div>
  );
};

/**
 * An Alaffia statistic component with optional title, tooltip and icon. Runs the query
 * with filter that is passed as an argument. Wraps the antd <Statistic />
 * Where more than one stat is displayed together, prefer an aggregate query
 * at the parent level (example: dashboardActiveStatistics in statsPanel)
 * @param filter <Any<Filter>>
 * @param title <String>
 * @param description <String>
 * @param query <GraphqlQuery>
 * @param reducer a function that takes the query output and returns the stat value
 * @param displayType 'dollar' or 'count'
 * @param showTooltip <Boolean>
 * @param antdTooltipProps additional antd tooltip props
 * @param antdRenderProps additional antd render props
 * @param prefix <Icon> or <String>, what should render alongside the stat
 * @returns JSX styled statistic component
 */
const Stat = args => {
  if (args.query) {
    return <StatWithQuery {...args} />;
  } else {
    return <StatStatic {...args} />;
  }
};

export { Stat };
