import { InitialEditFindingValues } from '../viewController/types';
import { useMemo } from 'react';
import { get } from 'lodash';

export const useInitialValues = <T>(
  dataKey: string,
  initialValues: InitialEditFindingValues
) => {
  const values: { defaultValue: T; existingFindingValue: T } = useMemo(() => {
    const { defaultValues, existingFindingValues } = initialValues;
    const defaultValue: T = get(defaultValues, dataKey);
    const existingFindingValue: T = get(existingFindingValues ?? {}, dataKey);
    return { defaultValue, existingFindingValue };
  }, [dataKey, initialValues]);
  return values;
};
