import {
  AutodorFinding,
  ConvertAutodorFindingOp,
  ReviewFindingOp,
} from '../../../claimWorkspace/findingEdit/types';
import React, { FC, useContext } from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { FindingContext } from '../../../claimWorkspace/findingEdit/findingProvider';
import { ItemizedBillLine } from '../../../../../gql/graphql';
import { AuditFindingSeedType } from '../../../claimWorkspace/createAuditFinding/auditFindingUtil';

type LineConvertAutodorFindingButtonProps = {
  fetching: boolean;
  readOnly: boolean;
  autodorFinding: AutodorFinding;
  ibData: ItemizedBillLine[];
};

export const LineConvertAudotorFindingButton: FC<
  LineConvertAutodorFindingButtonProps
> = ({ fetching, readOnly, autodorFinding, ibData }) => {
  const { findingEditor } = useContext(FindingContext);
  const operation: ConvertAutodorFindingOp = {
    autodorFinding,
    operation: 'convert',
    seedType: AuditFindingSeedType.IB_CLAIM_LINE,
    ibData,
  };
  return readOnly ? null : (
    <Tooltip title="Review and convert to finding" key="tt_convert-autodor">
      <Button
        data-cy={'claim-line-finding-btn-convert-autodor'}
        loading={fetching}
        type={'primary'}
        size="small"
        onClick={() => {
          findingEditor.showModal({ operation });
        }}
        disabled={readOnly}
        icon={<EditOutlined />}
      />
    </Tooltip>
  );
};
