// external
import { useContext } from 'react';

// component library
import { Row, Col } from 'antd';

// internal
// user context
import { UserContext } from '../context/user';

// dashboard components
import { Queues } from '../queues/queues';
import { FacilityQueues } from './defaultQueues/facilityQueues';
import { ProviderFacilityQueues } from './defaultQueues/providerFacilityQueues';
import { AuditorClientQueues } from './defaultQueues/auditorClientQueues';
import { StatsPanel } from './stats/statsPanel';

const availableComponents = {
  statsPanel: (
    <StatsPanel
      key="stats-panel-dashboard-component"
      id="stats-panel-dashboard-component"
    />
  ),
  facilityQueues: (
    <FacilityQueues
      key="facility-queues-dashboard-component"
      id="facility-queues-dashboard-component"
    />
  ),
  providerFacilityQueues: (
    <ProviderFacilityQueues
      key="provider-facility-queues-dashboard-component"
      id="provider-facility-queues-dashboard-component"
    />
  ),
  auditorClientQueues: (
    <AuditorClientQueues
      key="auditor-client-queues-dashboard-component"
      id="auditor-client-queues-dashboard-component"
    />
  ),
  queues: (
    <Queues key="queues-dashboard-component" id="queues-dashboard-component" />
  ),
};

const Dashboard = () => {
  const {
    dashboard: { components },
  } = useContext(UserContext);

  // NOTE: Ordering of dashboard components comes from user context components array
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>{components.map(c => availableComponents[c])}</Col>
      </Row>
    </div>
  );
};

export { Dashboard };
