import { Divider, Form } from 'antd';
import React, { FC } from 'react';
import {
  EditFindingAdjustments,
  EditFindingValues,
  FormSetFieldsFn,
  InitialEditFindingValues,
} from '../../viewController/types';
import { ImproperPaymentUnits } from '../fields/improperPaymentUnits';
import { ImproperPaymentCost } from '../fields/improperPaymentCost';
import { BilledAmount } from '../fields/billedAmount';
import { IbLineAdjustmentsInfo } from '../ibLineAdjustmentInfo';
import { ItemizedBillLine } from '../../../../../../gql/graphql';
import { IbLineInfo } from '../../modal/ibLineInfo';
import { RepriceCheckbox } from '../fields/repriceCheckbox';
import tw, { css } from 'twin.macro';
import accounting from 'accounting-js';

export type IbLineAdjustmentsProps = {
  initialValues: InitialEditFindingValues;
  adjustments?: EditFindingAdjustments;
  ibLines: ItemizedBillLine[];
  setFields: FormSetFieldsFn<EditFindingValues>;
  formLayout?: any;
};

export const IbLineAdjustments: FC<IbLineAdjustmentsProps> = props => {
  const { initialValues, adjustments, ibLines, formLayout, setFields } = props;
  const { paymentRate, ibLinesAreEquivalent, canReprice, isRepricing } =
    adjustments ?? ({} as EditFindingAdjustments);

  const repriceCheckboxHoverText =
    !adjustments || !paymentRate
      ? 'Select Rev Code to enable repricing'
      : !ibLinesAreEquivalent
        ? 'Repricing requires all selected lines to have the same values'
        : 'Select to adjust billed/adjustment amount';

  const form = Form.useFormInstance();

  return (
    <div css={css({ '& .ant-form-item': tw`_mb-4` })}>
      <IbLineInfo ibLines={ibLines}></IbLineInfo>

      <Divider tw="!_mb-0 _pb-0">Adjustment</Divider>

      <div tw="_flex">
        <div></div>
        <RepriceCheckbox
          tw="_ml-auto !_text-xs"
          dataKey="metadata.isRepricing"
          initialValues={initialValues}
          hoverText={repriceCheckboxHoverText}
          disabled={!canReprice}
        ></RepriceCheckbox>
      </div>

      <ImproperPaymentUnits
        dataKey="auditFindingValues.improperPaymentUnitsCharged"
        initialValues={initialValues}
        maxUnits={adjustments?.maxUnits}
        formLayout={formLayout}
      ></ImproperPaymentUnits>

      {ibLinesAreEquivalent && isRepricing && (
        <>
          <BilledAmount
            dataKey="metadata.amountChargedOverride"
            initialValues={initialValues}
            label="Adj. Billed Amount"
            formLayout={formLayout}
          ></BilledAmount>

          <ImproperPaymentCost
            dataKey={'metadata.improperPaymentCostOverride'}
            label={'Adjustment Amount'}
            initialValues={initialValues}
            formLayout={formLayout}
            addlExtraJsx={
              adjustments?.ibLineAdjustments![0].overrides?.isIPCOverride ? (
                <div>
                  <span tw="_text-antd-lt-colorWarningText dark:_text-antd-dk-colorWarningText">
                    {`${adjustments?.improperPaymentUnitsCharged} @ ${accounting.toFixed(
                      paymentRate * 100,
                      1
                    )}% = ${accounting.formatMoney(
                      adjustments?.ibLineAdjustments![0].improperPaymentCost
                    )}`}
                  </span>
                  <span
                    tw="_pl-1 _text-antd-lt-colorLink dark:_text-antd-dk-colorLink"
                    onClick={(...args) => {
                      setFields(
                        {
                          metadata: {
                            improperPaymentCostOverride:
                              adjustments?.ibLineAdjustments![0]
                                .improperPaymentCost,
                          },
                        },
                        true
                      );
                    }}
                  >
                    [use]
                  </span>
                </div>
              ) : undefined
            }
          ></ImproperPaymentCost>
        </>
      )}

      <IbLineAdjustmentsInfo adjustments={adjustments}></IbLineAdjustmentsInfo>
    </div>
  );
};
