import { validate as uuidValidate } from 'uuid';
import { encode, decode } from 'slugid';

type UUID = string;

const regexUuid =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export const isUuid = (maybeUuid: unknown): maybeUuid is UUID => {
  if (typeof maybeUuid !== 'string' || maybeUuid.length !== 36) {
    return false;
  }
  return regexUuid.test(maybeUuid) && uuidValidate(maybeUuid);
};

export const fromSlugOrId = (
  slugOrId: string | null | undefined
): UUID | null => {
  if (!slugOrId || typeof slugOrId !== 'string') {
    return null;
  }
  if (isUuid(slugOrId)) {
    return slugOrId;
  }
  const decompressed = decode(slugOrId);
  if (isUuid(decompressed)) {
    return decompressed;
  }
  return null;
};

export const toSlug = (id: UUID | null | undefined): string | null => {
  if (!id || typeof id !== 'string' || id.length !== 36 || !uuidValidate(id)) {
    return null;
  }
  return encode(id);
};
