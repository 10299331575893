import React, { useState } from 'react';
import { css } from '@emotion/react';
import { Select, Typography, Spin } from 'antd';

import { generateDefaultValue } from './util';

const RangeSelectFilterComponent = ({
  displayName,
  query, // queryData, isQueryFetching are unused in 'RangeSelectFilterComponent'
  filter,
  field,
  typeDef,
  renderComponent,
  options,
  tag,
  userContext, // passed from the user.js context
}) => {
  const getDefaultValue = keywords => {
    const obj = {};
    keywords.forEach(keyword => {
      obj[keyword] =
        !filter.filterMap.has(field) ||
        [undefined, null].includes(filter.filterMap.get(field).value)
          ? null
          : typeDef.defaultValueGenerator
            ? typeDef.defaultValueGenerator(filter.filterMap.get(field).value)
            : filter.filterMap.get(field).value[keyword];
    });
    return obj;
  };

  const [values, setValues] = React.useState(
    getDefaultValue(typeDef.filterType.map(({ keyword }) => keyword))
  ); // always returns an object with keywords that map to values

  return (
    <div>
      <Typography.Title level={5}>{displayName}:</Typography.Title>
      <div
        css={css`
          margin-top: 5px;
        `}
      >
        {typeDef.filterType.map(({ keyword, label }, i) => (
          <span
            key={`${displayName
              .toLowerCase()
              .replaceAll(' ', '-')}-${keyword}-search-outer-span`}
            id={`${displayName
              .toLowerCase()
              .replaceAll(' ', '-')}-${keyword}-search-outer-span`}
            css={css`
              padding-right: 15px;
            `}
          >
            <span
              key={`${displayName
                .toLowerCase()
                .replaceAll(' ', '-')}-${keyword}-search-label-span`}
              id={`${displayName
                .toLowerCase()
                .replaceAll(' ', '-')}-${keyword}-search-label-span`}
              css={css`
                padding-right: 4px;
              `}
            >
              {label}:
            </span>
            {typeDef.rangeSelectComponent({
              key: `${displayName
                .toLowerCase()
                .replaceAll(' ', '-')}-${keyword}-search-item`,
              id: `${displayName
                .toLowerCase()
                .replaceAll(' ', '-')}-${keyword}-search-item`,
              onChange: value => {
                const newValues = { ...values };
                if (typeDef.isValueValid(value)) {
                  newValues[keyword] = value; // updates the value
                } else {
                  delete newValues[keyword];
                }
                setValues(newValues);
                filter.add(
                  field,
                  newValues,
                  displayName,
                  typeDef,
                  tag,
                  renderComponent,
                  options,
                  query // likely undefined/null
                );
              },
              defaultValue: getDefaultValue([keyword])[keyword],
            })}
            <br />
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

const SelectFilterComponent = ({
  displayName,
  query,
  queryData,
  isQueryFetching,
  filter, //batchClaimSearchFilter Class instance
  field, // searchableField name
  typeDef, // Static information & functions required unique to this searchable Field types [filterBuilder, selectType]
  options, // The function for calculating the options of the select
  renderComponent,
  tag,
  userContext, // passed from the user.js context
}) => {
  const { userType, permissions } = userContext;
  const additionalProps = {};

  // When we define a searchableField to get it's selectable values list from a query (instead of statically set)
  // and the query Object has the `autoComplete` field added to it.
  // Allowing the user to manage which values are returned based on partial search
  if (query && query.autoComplete) {
    const {
      getResults,
      autoCompleteState: { input, setInput },
    } = query.autoComplete;

    additionalProps.dropdownRender = node => {
      const meetsAutoCompleteReq =
        query.autoComplete.meetsAutoCompleteRequirement({
          input,
          userType: userContext.userType,
        });

      const results = !meetsAutoCompleteReq.result
        ? null
        : getResults({
            query,
            isQueryFetching,
            queryData,
            typeDef,
          });

      const msg = isQueryFetching
        ? 'Searching...'
        : !meetsAutoCompleteReq.result
          ? meetsAutoCompleteReq.reason
          : !results
            ? `No results for '${input}'`
            : undefined;

      return (
        <div
          css={css`
            ${!results || !meetsAutoCompleteReq.result || true
              ? 'padding-left: 5px;'
              : ''}
          `}
        >
          {msg}
          {results && node}
        </div>
      );
    };
    additionalProps.onSearch = v => {
      setInput(v);
    };
    // re-add the option filtering input value if they click away and back,
    // since otherwise the list is still filtered (potentially by the control,
    // e.g. post-query, like searching 1111 then filtering results to 111122
    // without retriggering the query), so seeing the filter string clarifies
    // that the field is still filtered:
    additionalProps.searchValue = input;
  }

  additionalProps.autoClearSearchValue = false;

  return (
    <div data-cy={`search-filter-add-container-${field}`}>
      <Typography.Title level={5}>{displayName}:</Typography.Title>
      <Select
        data-cy={`search-filter-add-select-${field}`}
        id={`search-filter-add-select-id-${field}`}
        css={css`
          width: 100%;
          margin-top: 5px;
        `}
        // split the input on paste and uses these as delimiters (NOTE: order matters)
        //  - For some reason I can't explain, stand-alone \r in input won't parse correctly unless it's arg 0 here (mv)
        tokenSeparators={['\r', '\r\n', '\n', ', ', ',']}
        placeholder={`Select ${displayName}`}
        loading={isQueryFetching || false}
        mode={typeDef.selectType}
        optionFilterProp="label" // always search label
        showSearch={true} // allow search for single-selects too
        defaultValue={
          filter.filterMap.has(field) ? filter.filterMap.get(field).value : []
        }
        /*
          If the searchableField doesnt use a query then the field's options function will
          only use the 'typeDefs' param passed
        */
        options={options({
          field,
          query,
          isQueryFetching,
          queryData,
          typeDef,
          permissions,
          userType,
        })}
        onChange={value => {
          filter.add(
            field,
            value,
            displayName,
            typeDef,
            tag,
            renderComponent,
            options,
            query
          );
        }}
        {...additionalProps}
      />
    </div>
  );
};

/**
 *  This component is not currently in use - removed reference in searchableFields.js for 'reportId' search
 *  as of Oct 2022 - removed after commit d2298b1aa23b93244a8b148cb9464bf2451356ef
 *  Keeping this implementation as we may want it in the future.
 */
/*
const MultipleSelectFilterComponent = ({
  displayName,
  query, // query
  queryData,
  isQueryFetching,
  refreshQuery, // if we need to refresh query
  filter, // batchClaimSearchFilter Class instance
  field, // searchableField name
  typeDef, //  information & functions required unique to this searchable Field types [selectDisplayName, filterBuilder, selectType]
  options, // The function for calculating the options of the select
  renderComponent,
  tag,
  userContext, // passed from the user.js context
}) => {
  const { userType, permissions } = userContext;
  const additionalProps = {};

  const [searchValue, setSearchValue] = React.useState(() => {
    // Using the initializing method as described in the REACT docs
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const defaultValues = generateDefaultValue(filter, field, typeDef);
    return defaultValues;
  });
  const [optionsArr, setOptionsArr] = React.useState(() => {
    // Using the initializing method as described in the REACT docs
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const initialOptions = typeDef.selects.map(select =>
      select.option({
        field,
        query,
        isQueryFetching,
        queryData,
        typeDef,
        select,
        permissions,
        userType,
        searchValue,
      })
    );
    return initialOptions;
  });

  return isQueryFetching || false ? (
    <Spin />
  ) : (
    <div>
      <Typography.Title level={5}>{displayName}:</Typography.Title>
      {typeDef.selects.map((select, i) => {
        return (
          <div
            css={css`
              margin-top: 2%;
            `}
          >
            <h4
              id={`multi-select-h4-${field}-${select.key}-${i}`}
              key={`multi-select-h4-${field}-${select.key}-${i}`}
            >
              {select.selectDisplayName}
            </h4>
            <Select
              css={css`
                width: 100%;
                margin-top: 5px;
              `}
              key={`multi-select-search-select-${field}-${select.key}-${i}`}
              id={`multi-select-search-select-${field}-${select.key}-${i}`}
              placeholder={`Select ${select.selectDisplayName}`}
              mode={select.selectType}
              defaultValue={
                filter.filterMap.has(field)
                  ? filter.filterMap.get(field).value[select.key]
                  : select.defaultValue || []
              }
              // If the searchableField doesn't use a query then the field's options function will
              //  only use the 'selects' param passed
              value={searchValue[select.key]} // by default this is the result of generateDefaultValue()
              options={optionsArr[i]}
              onChange={value => {
                const searchValueTemp = select.clearOtherSelectsWhenChanged
                  ? {}
                  : searchValue;
                searchValueTemp[select.key] = value;

                const isValidEntry = typeDef.selects.every(
                  ({ key }) => key in searchValueTemp
                );
                // we add to the filter only on valid entries
                if (isValidEntry) {
                  filter.add(
                    field,
                    searchValueTemp,
                    displayName,
                    typeDef,
                    tag,
                    renderComponent,
                    options,
                    query
                  );
                }

                // re-generate options
                const optionsTemp = typeDef.selects.map(select =>
                  select.option({
                    field,
                    query,
                    isQueryFetching,
                    queryData,
                    typeDef,
                    select,
                    permissions,
                    userType,
                    searchValue: searchValueTemp,
                  })
                );
                setSearchValue(searchValueTemp);
                setOptionsArr(optionsTemp);
                // an option to trigger a refresh
                if (select.refreshTrigger && refreshQuery) {
                  select.refreshTrigger(typedef, searchValueTemp, refreshQuery);
                }
              }}
              {...additionalProps}
            />
          </div>
        );
      })}
    </div>
  );
};
*/

export {
  RangeSelectFilterComponent,
  SelectFilterComponent,
  // MultipleSelectFilterComponent,
};
