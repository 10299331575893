import {
  Form,
  Select,
  InputNumber,
  Input,
  Descriptions,
  Divider,
  Space,
  Typography,
} from 'antd';
const { Text, Link } = Typography;
import accounting from 'accounting-js';
import tw, { css } from 'twin.macro';
import moment from 'moment-timezone';
import { inputPropsCurrencyFormatter } from '../../util';
import { useMemo } from 'react';
import { ItemizedBillLine } from '../../../../gql/graphql';
import { uniq } from 'lodash';
import { FormExtra } from '../../shared/components/form/formExtra';
const { Option } = Select;

export type IbexIbLineFormItemsProps = {
  batchClaim: any;
  originalAuditFinding: any;
  batchClaimLine: any;
  auditFindingSeedType: any;
  revCodeWarning: any;
  isIbexTab: boolean;
  ibData: any;
};

type RevCodeBclInfo = {
  id: string;
  revCode: string;
  revDescription: string;
  unitCharge: number;
};

// TODO dupe with bcliFormItems
const toDate = (date: any) =>
  date !== null && date !== undefined ? moment.utc(date) : '';
const toFormattedDate = (date: any) => {
  const d = toDate(date);
  return d ? d.format('MM/DD/YY') : date;
};

export const IbexIbLineFormItems: React.FC<IbexIbLineFormItemsProps> = ({
  batchClaim,
  originalAuditFinding,
  batchClaimLine,
  auditFindingSeedType,
  revCodeWarning,
  isIbexTab,
  ibData: _ibData,
}) => {
  const ibData = _ibData
    ? _ibData
    : [originalAuditFinding?.findingItemizedBillData ?? {}];

  console.debug('ibdata', ibData);

  const originalBcli =
    originalAuditFinding?.batchClaimLineItemAudit?.batchClaimLineItem;
  const originalBcl =
    originalAuditFinding &&
    batchClaim.batchClaimLines.nodes.find(
      (en: any) => en.id === originalAuditFinding.batchClaimLineId
    );

  const revCodes = useMemo(() => {
    const onSelectedIbLines: string[] = (ibData ?? [])
      .filter((ibLine: any) => ibLine?.revCode ?? false)
      .map((ibLine: any) => ibLine.revCode)
      .reduce((acc: string[], curr: any) => {
        if (!acc.includes(curr)) {
          acc.push(curr);
        }
        return acc;
      }, []);
    const revCodeBclInfos: RevCodeBclInfo[] = (
      batchClaim?.batchClaimLines?.nodes ?? []
    ).map(({ id, revCode, revDescription, unitCharge }: RevCodeBclInfo) => ({
      id,
      revCode,
      revDescription,
      unitCharge,
    }));
    const recommended = revCodeBclInfos.filter(bcl =>
      onSelectedIbLines.includes(bcl.revCode)
    );

    const other = revCodeBclInfos.filter(
      bcl => !onSelectedIbLines.includes(bcl.revCode)
    );

    return { recommended, other, revCodeBclInfos, onSelectedIbLines };
  }, [ibData, batchClaim.batchClaimLines.nodes]);

  const revCodeInfoToOption = (infos: RevCodeBclInfo[]) =>
    infos.map(({ id, revCode, revDescription, unitCharge }: RevCodeBclInfo) => {
      return (
        <Option key={id} value={`${id}:${revCode}`} title={revDescription}>
          <Space
            split={<Divider type="vertical" />}
            css={css`
              min-width: 100%;
            `}
          >
            <div>{revCode}</div>
            <div>{accounting.formatMoney(unitCharge)}</div>
            <div
              css={css`
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                min-width: 8em;
                max-width: 8em;
              `}
            >
              {revDescription}
            </div>
          </Space>
        </Option>
      );
    });

  const revCodeOptions = useMemo(() => {
    return revCodes.recommended.length > 0 ? (
      <>
        <Select.OptGroup
          key={auditFindingSeedType}
          label="UB codes matching selected IB lines"
        >
          {revCodeInfoToOption(revCodes.recommended)}
        </Select.OptGroup>
        <Select.OptGroup
          key={auditFindingSeedType}
          label="Other codes available on UB"
        >
          {revCodeInfoToOption(revCodes.other)}
        </Select.OptGroup>
      </>
    ) : (
      revCodeInfoToOption(revCodes.other)
    );
  }, [revCodes]);

  const initialRevCodeValue = originalBcl
    ? `${originalBcl.id}:${originalBcl.revCode}`
    : revCodes.recommended.length === 1 &&
        revCodes.onSelectedIbLines.length === 1
      ? `${revCodes.recommended[0].id}:${revCodes.recommended[0].revCode}`
      : undefined;

  const clsIbex = css({
    ...tw`_mb-2`,
    '& .field-extra-txt': tw`_text-antd-lt-colorWarning dark:_text-antd-dk-colorWarning`,
    '& input.displayonly': tw`_pointer-events-none _text-antd-lt-colorPrimaryText`,
    '& input.ant-input-number-input': tw`_pointer-events-none _text-antd-lt-colorPrimaryText`,
  });

  const isMulti = ibData.length > 1;

  const multiMsg = isMulti ? '<multiple>' : null;

  const toJoined = (
    key: keyof ItemizedBillLine,
    formatter?: (v: any) => string
  ) =>
    uniq(
      ibData
        .filter((en: ItemizedBillLine) => !!en)
        .map((en: ItemizedBillLine) =>
          formatter ? formatter(en[key]) : en[key]
        )
    ).join(', ');

  return (
    <>
      <Divider>Itemized Charge</Divider>
      {!batchClaimLine ? (
        <Form.Item
          key="batchClaimLineItemFormValues.revCodeValue"
          name="batchClaimLineItemFormValues.revCodeValue"
          label="Rev. Code"
          rules={[{ required: true, message: 'Select a rev. code' }]}
          initialValue={initialRevCodeValue}
          extra={
            <FormExtra visible={!!originalAuditFinding}>
              {revCodeWarning ? (
                <Text type="danger">
                  {originalBcl && originalBcl.revCode} - {revCodeWarning}
                </Text>
              ) : (
                originalBcl && originalBcl.revCode
              )}
            </FormExtra>
          }
        >
          <Select
            placeholder="Select Rev Code"
            virtual={false}
            showSearch={true}
            // filterOption={true}
            optionFilterProp={'title'}
          >
            {revCodeOptions}
          </Select>
        </Form.Item>
      ) : (
        <Descriptions column={3}>
          <Descriptions.Item span={1} label="Rev. Code">
            <></>
          </Descriptions.Item>
          <Descriptions.Item span={2}>
            <Space
              split={<Divider type="vertical" />}
              css={css`
                min-width: 100%;
              `}
            >
              <div>{batchClaimLine.revCode}</div>
              <div>{accounting.formatMoney(batchClaimLine.unitCharge)}</div>
            </Space>
          </Descriptions.Item>
        </Descriptions>
      )}

      <div
        css={css({
          ...tw`_grid _grid-cols-8 _text-xs`,
          '& > div': tw`_px-2 _py-0.5`,
          '& > div.val': tw`_col-span-6 _h-4 _line-clamp-1 _break-all`,
          '& > div.lbl': tw`_col-span-2 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary`,
        })}
      >
        <div className="lbl">Date of Service</div>
        <div className="val">{toJoined('dateOfService', toFormattedDate)}</div>

        <div className="lbl">CPT/HCPCS Code</div>
        <div className="val">{toJoined('procedureCode')}</div>

        <div className="lbl">IB Item Number</div>
        <div className="val">{toJoined('itemNumber')}</div>

        <div className="lbl">Service Name</div>
        <div className="val">{toJoined('description')}</div>

        <div className="lbl">Billed Quantity</div>
        <div className="val">{toJoined('units')}</div>

        <div className="lbl">Billed Amount</div>
        <div className="val">{toJoined('totalCharge')}</div>
      </div>
    </>
  );
};
