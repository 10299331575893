import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Tooltip, Select, AutoComplete, Input } from 'antd';
import type { MenuProps } from 'antd';
import { LineClampedCell } from '../../../common/table/ag/cell/lineClampedCell';
import { cloneElement, ReactElement, useState } from 'react';
import tw, { css } from 'twin.macro';

type AskAutodorPromptTemplateSelectorMenuItem = {
  label: string;
  idx: number;
  setTemplatePrompt: Function;
};

const optionStyle = css({
  ...tw`_p-0 _m-0 _w-full _h-full _overflow-hidden _flex _items-center`,
  '&>div.itm-no': tw`_p-0 _m-0  _mr-1 _min-w-fit _text-[0.75em] _font-extralight _pr-1 
               _text-antd-lt-colorTextTertiary 
               dark:_text-antd-dk-colorTextTertiary`,
  '&>div': {
    ...tw`_line-clamp-4  _leading-5 _align-baseline _my-auto 
          _p-0 _whitespace-normal _break-words _text-xs`,
    '&>span': tw`_font-semibold _pr-1.5`,
  },
});

export const AskAutodorPromptTemplateSelectorMenuItem = ({
  label,
  idx,
  setTemplatePrompt,
}: AskAutodorPromptTemplateSelectorMenuItem) => {
  return (
    <div
      css={optionStyle}
      onClick={() => {
        setTemplatePrompt(label);
      }}
    >
      <div className="itm-no">{idx}</div>
      <div>
        <span>Ask:</span>
        {label}
      </div>
    </div>
  );
};

const prompts: string[] = [
  `Summarize the patient's hospital course in detail`,
  `What is the patient's principal diagnosis based on their hospital course?`,
  `What conditions was the patient diagnosed with during their hospitalization?`,
  `What procedure took place on "DATE"?`,
  `What was the patient's indication for "PROCEDURE"?`,
  `As an insurance company, draft a denial letter for [INSERT DETAILS]. Include details from the medical record to support your denial`,
  `What "level of care" has been ordered for the patient?`,
  `Does the documentation support the "level of care" that was ordered for the patient? If so, list the rationale`,
];

function menuItems(
  setTemplatePrompt: any
): { label: ReactElement; value: string; key: string }[] {
  let idxAdj = 1;
  return prompts.map((prompt: string, index: number) => {
    if (prompt === '') {
      idxAdj--;
      return {
        label: (
          <div>
            <hr />
          </div>
        ),
        key: '' + index,
        value: '',
      };
    } else {
      return {
        label: (
          <AskAutodorPromptTemplateSelectorMenuItem
            label={prompt}
            idx={index + idxAdj}
            setTemplatePrompt={setTemplatePrompt}
          />
        ),
        key: '' + index,
        value: prompt,
      };
    }
  });
}

type AskAutodorPromptTemplates = {
  setTemplatePrompt: Function;
};

export const AskAutodorPromptTemplateSelector = ({
  setTemplatePrompt,
}: AskAutodorPromptTemplates) => {
  const [value, setValue] = useState('');
  const items: { label: ReactElement; value: string; key: string }[] =
    menuItems((prompt: string) => {
      setValue('');
      setTemplatePrompt(prompt);
    });

  //  -- AUTO COMPLETE --
  // return (
  //   <div css={css({ ...tw`_w-full`, '& .aa-autocomplete': tw`_w-full` })}>
  //     <AutoComplete
  //       // @ts-ignore
  //       listHeight="500px" //not apparently a published prop from rc-select (?)
  //       className="ant-select aa-autocomplete"
  //       options={items}
  //       allowClear={true}
  //       autoClearSearchValue={true}
  //       onChange={value => setTemplatePrompt(value)}
  //       placeholder="Choose a prompt..."
  //       filterOption={(inputValue, option) =>
  //         option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
  //       }
  //     />
  //   </div>
  // );

  //  -- SELECT --
  // return (
  //   <Select
  //     placeholder="Select Denial Code"
  //     style={{ width: 275 }}
  //     virtual={false}
  //     showSearch={true}
  //     // filterOption={true}
  //     optionFilterProp={'title'}
  //     options={items!}
  //   ></Select>
  // );

  //  -- DROPDOWN --
  return (
    <>
      <Dropdown
        menu={{
          items,
          style: { width: '475px' },
        }}
        css={css({
          ...tw`_w-full`,
        })}
        // getPopupContainer={() => document.getElementById('aa-prompt-menu')!} // must be in correct z-index!
        dropdownRender={menu => {
          // cloneElement - can pass props as second arg, e.g style...
          return (
            <div
              css={css({
                ...tw`_w-full _overflow-y-auto _h-[550px]`,
                // ...tw`_border _border-solid _border-orange-600`,
                '& ul.ant-dropdown-menu.ant-dropdown-menu-root': {
                  ...tw`_w-[600px]`,
                  // ...tw`_m-2 _border _border-solid _border-green-600`,
                },
              })}
            >
              {cloneElement(menu as ReactElement)}
            </div>
          );
        }}
      >
        <Button
          size="small"
          tw="_bg-transparent _shadow-none  _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
        >
          Prompt Questions
          <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
