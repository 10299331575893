import { Tooltip } from 'antd';
import moment from 'moment';

/**
 * Returns a formatted date range string
 * @param (start) DateTime (UTC)
 * @param (end) DateTime (UTC)
 */
const formatDateRange = (start, end) => {
  if (!start && !end) return null;
  const dateFormat = 'MM/DD/YY';
  return start && end
    ? `${moment.utc(start).format(dateFormat)} - ${moment
        .utc(end)
        .format(dateFormat)}`
    : moment.utc(start).format(dateFormat);
};

const getAllbatchClaimLineCodes = (codeType, batchClaimsData) => {
  let codes = [];
  const seen = { '<Empty>': true };
  batchClaimsData.batchClaims.nodes.forEach(({ batchClaimLines }) => {
    codes = codes.concat(
      batchClaimLines[codeType]
        .filter(code => {
          return seen[code.keys[0]] ? false : (seen[code.keys[0]] = true);
        })
        .map(code => {
          return {
            text: code.keys[0],
            value: code.keys[0],
          };
        })
    );
  });
  return codes;
};

const renderMultipleTags = (
  tagArray,
  tagRenderFunction,
  moreTagRenderFunction,
  moreTagLimit = 2
) => {
  if (tagArray.length > 0) {
    const tagsHtmlArray = tagArray
      .slice(0, moreTagLimit)
      .map(tagArrayNode => tagRenderFunction(tagArrayNode));

    if (tagArray.length > moreTagLimit)
      return tagsHtmlArray.concat(moreTagRenderFunction(tagArray));
    else return tagsHtmlArray;
  } else return <i>N/A</i>;
};

/* Assumes that items is an array of objects with format: 
      {
        text: <str>,
        value: <str>,
      }
*/
const unique = items => {
  const seen = {};
  return items.filter(item => {
    return seen[item.value] ? false : (seen[item.value] = true);
  });
};

/**
 *  @param {string} title - the display title for the column
 *  @param {string} id - the id to populate into the node
 *  @param {string} tooltipTitle - Optional param for a tooltip
 *  @param {Object} tooltipAntdProps - Optional param for an object that can be passed as custom antd tooltip Component props
 * @returns jsx component for column title for an antd <Table>
 */
const ColTitle = ({ title, id, tooltipTitle, tooltipAntdProps }) => {
  return !tooltipTitle ? (
    <div
      id={`claim-list-col-${id}`}
      key={`claim-list-col-${id}`}
      data-cy={`claim-list-col-${id}`}
    >
      {title}
    </div>
  ) : (
    <>
      <Tooltip
        id={`claim-list-col-tooltip-${id}`}
        key={`claim-list-col-tooltip-${id}`}
        data-cy={`claim-list-col-tooltip-${id}`}
        {...tooltipAntdProps}
        title={tooltipTitle}
      >
        <div
          id={`claim-list-col-${id}`}
          key={`claim-list-col-${id}`}
          data-cy={`claim-list-col-${id}`}
        >
          {title}
        </div>
      </Tooltip>
    </>
  );
};

/**
 * Rebuilds the orderByArgs held in component state based on updated input from antd column header click
 * @param sorterArr - generated by antd on column click
 * @param orderByArgs - the existing state of the sort arguments
 * @returns {*[]} - the updated orderByArgs for component state and query generation
 */
const generateSortEnumOnSort = (sorterArr, orderByArgs) => {
  // iterated through the sortArr that comes from antd and extracts the sort values we need
  const incomingSortObjColumnsSort = {};
  let newSortingArgs = [];
  sorterArr.forEach(sorter => {
    const { column, columnKey, order } = sorter;
    const values = column && column.sorter.compare();
    if (values)
      incomingSortObjColumnsSort[columnKey] = values.map(
        value => `${value}_${order === 'descend' ? 'DESC' : 'ASC'}`
      );
  });
  // We now want to rebuild orderByArgs using the data collected in incomingSortObjColumnsSort
  // orderByArgs is the stateObject: [ {columnKey: <str>, sortEnums: [sortEnums] }, ...]
  if (Object.keys(incomingSortObjColumnsSort).length === 0) return [];
  else {
    Object.entries(incomingSortObjColumnsSort).forEach(
      ([columnKey, sortEnums]) => {
        if (
          !orderByArgs.map(({ columnKey }) => columnKey).includes(columnKey)
        ) {
          // a new sort field has been added as current state Object doesnt have this columnKey
          newSortingArgs = [
            ...orderByArgs,
            {
              columnKey,
              sortEnums,
            },
          ];
          return;
        }
      }
    );
    if (newSortingArgs.length === 0) {
      orderByArgs.forEach(({ columnKey }) => {
        if (!incomingSortObjColumnsSort[columnKey]) {
          // means that this columnKey value has been removed from sortObj
          newSortingArgs = [
            ...orderByArgs.filter(
              ({ columnKey: sortArgColumnKey }) =>
                sortArgColumnKey !== columnKey
            ),
          ];
        }
      });
    }
    if (newSortingArgs.length === 0) {
      // means a change to an existing columnKey occured --> We must rebuild the array with this new value
      orderByArgs.forEach(sortingArg => {
        newSortingArgs.push({
          columnKey: sortingArg.columnKey,
          sortEnums: incomingSortObjColumnsSort[sortingArg.columnKey],
        });
      });
    }
    return newSortingArgs;
  }
};

const flattenOrderByArgsObj = orderByObj => {
  const orderByArr = [];
  orderByObj.forEach(({ columnKey, sortEnums }) => {
    orderByArr.push(...sortEnums);
  });
  return [...new Set(orderByArr)]; // By putting it in a Set we remove any potential duplicates that may occur
};

/**
 * Returns the number of week days (skipping all weekend days -- not including additional days from long weekends) between the 2 js dateTime objects passed in
 * @param (d1) start DateTime
 * @param (d2) end DateTime
 * @returns integer >= 0 on success and null if parameter d1 or d2 are null/undefined
 */
const numberOfWeekDaysBetweenTwoDates = (d1, d2) => {
  if (!d1 || !d2) {
    console.log(
      `error: parameters d1 or d2 has an invalid value of null or undefined --> d1: ${d1}, d2: ${d2}`
    );
    return null;
  }
  // assumes we are getting Js dateTime and correct timezone (EST)
  d1.setDate(d1.getDate() + (d1.getHours() >= 17 ? 1 : 0));
  d2.setDate(d2.getDate() + (d2.getHours() >= 17 ? 1 : 0));

  // set the respective time of days to the start of the day
  d1.setHours(12, 0, 0, 0);
  d2.setHours(12, 0, 0, 0);

  const totalDaysElapsed = Math.ceil(
    (d2.valueOf() - d1.valueOf()) / 1000 / 60 / 60 / 24
  );

  const wholeWeeksElapsed = Math.floor(totalDaysElapsed / 7);
  let numberOfBusinessDays = wholeWeeksElapsed * 5;

  // If not even number of weeks, calc remaining non weekend days left
  if (totalDaysElapsed % 7) {
    d1.setDate(d1.getDate() + wholeWeeksElapsed * 7);
    while (d1.valueOf() < d2.valueOf()) {
      // If day isn't a Sunday or Saturday, add to business days
      if (d1.getDay() !== 0 && d1.getDay() !== 6) {
        numberOfBusinessDays += 1;
      }
      d1.setDate(d1.getDate() + 1);
    }
  }
  return numberOfBusinessDays;
};

export {
  unique,
  renderMultipleTags,
  getAllbatchClaimLineCodes,
  ColTitle,
  formatDateRange,
  generateSortEnumOnSort,
  flattenOrderByArgsObj,
  numberOfWeekDaysBetweenTwoDates,
};
