import { graphql } from '../../../gql';
import { useQuery } from 'urql';
import { FragmentType, useFragment } from '../../../gql/fragment-masking';
import { askAutodorFileVectorsExistQuery } from './askAutodor.gql';

export const useAskAutodorFileVectorsExist = (documentIds: string[]) => {
  const useQueryResult = useQuery({
    query: askAutodorFileVectorsExistQuery,
    variables: {
      input: {
        documentIds: documentIds ?? [],
      },
    },
    pause: !documentIds || documentIds?.length === 0,
    requestPolicy: 'network-only',
  });

  return useQueryResult;
};
