import { Modal, Form, Button, Input, Select, message } from 'antd';
import { useState } from 'react';
import { useQuery, gql, useMutation } from 'urql';

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const successMessage = text => message.success(text, 15);
const errorMessage = text => message.error(text, 15);

// queries and mutations
const facilitiesQuery = gql`
  query createUser_getFacilities {
    providers(orderBy: NAME_ASC) {
      nodes {
        name
        id
      }
    }
  }
`;

const insurersQuery = gql`
  query createUser_getInsurers {
    insurers(orderBy: NAME_ASC) {
      nodes {
        name
        id
      }
    }
  }
`;

const createUserMutation = gql`
  mutation createUser(
    $username: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $userType: UserTypeEnum!
    $facilities: [String!]
    $insurers: [UUID!]
  ) {
    createUser(
      input: {
        username: $username
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        facilities: $facilities
        insurers: $insurers
        userType: $userType
        readOnly: false # field not in use
        internalOnly: false # field not in use
      }
    ) {
      user {
        id
      }
    }
  }
`;

const CreateUserModal = ({ visible, close, refresh }) => {
  // form data
  const [
    {
      data: facilityList,
      fetching: facilitiesListFetching,
      error: facilityListQueryError,
    },
  ] = useQuery({ query: facilitiesQuery });

  const [
    {
      data: insurersList,
      fetching: insurersListFetching,
      error: insurersListQueryError,
    },
  ] = useQuery({ query: insurersQuery });

  // form validation
  const [form] = Form.useForm();
  const [userType, setUserType] = useState(false);

  const [
    { fetching: createUserFetching, error: createUserMutationError },
    createUser,
  ] = useMutation(createUserMutation);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const FacilitiesSelect = () => (
    <Form.Item
      id="facilities"
      name="facilities"
      label="Facilities"
      required
      rules={[
        { required: true, message: 'Add facilities for new provider user' },
      ]}
    >
      <Select placeholder="Select Facilities" mode="multiple">
        {facilityList.providers.nodes.map(({ name, id }) => (
          <Select.Option value={id}>{`${name} (${id})`}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const InsurersSelect = () => (
    <Form.Item
      id="insurers"
      name="insurers"
      label="Insurers"
      required={true}
      rules={[{ required: true, message: 'Select insurers for payer user' }]}
    >
      <Select placeholder="Select Insurers" mode="multiple">
        {insurersList.insurers.nodes.map(({ name, id }) => (
          <Select.Option value={id}>{`${name} (${id})`}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const CommonFields = () => (
    <>
      <Form.Item
        id="email"
        name="email"
        label="Email"
        required
        rules={[
          {
            required: true,
            message: 'Please enter a valid email address',
            validator: (_, value) =>
              validateEmail(value) ? Promise.resolve() : Promise.reject(),
          },
        ]}
      >
        <Input placeholder="User Email" />
      </Form.Item>
      <Form.Item
        id="username"
        name="username"
        label="Username"
        required
        rules={[
          {
            require: true,
            message: 'Enter a username longer than 2 characters',
            validator: (_, value) =>
              value?.length > 2 ? Promise.resolve() : Promise.reject(),
          },
        ]}
      >
        <Input placeholder="New Username" />
      </Form.Item>

      <Form.Item
        id="firstName"
        name="firstName"
        label="First Name"
        required
        rules={[{ required: true, message: "Enter new user's first name" }]}
      >
        <Input placeholder="User First Name" />
      </Form.Item>
      <Form.Item
        id="lastName"
        name="lastName"
        label="Last Name"
        required
        rules={[{ required: true, message: "Enter new user's last name" }]}
      >
        <Input placeholder="User Last Name" />
      </Form.Item>
      <Form.Item
        id="phoneNumber"
        name="phoneNumber"
        label="Mobile Phone"
        required
        rules={[
          {
            required: true,
            message: 'Enter valid phone number of 10 digits',
            validator: (_, value) =>
              value?.length === 10 && /^[0-9]/.test(value)
                ? Promise.resolve()
                : Promise.reject(),
          },
        ]}
      >
        <Input placeholder="User Phone Number" />
      </Form.Item>
    </>
  );

  const onFinish = async form =>
    form
      .validateFields()
      .then(async values => {
        // convert phone number to string expected by server
        values.phoneNumber = `+1${values.phoneNumber}`;
        const { data, error: createUserError } = await createUser({
          ...values,
        });
        if (createUserError) {
          errorMessage(`Error Creating User! ${createUserError}`);
        } else {
          successMessage(`User Created!`);
          form.resetFields();
          refresh();
          close();
        }
      })
      .catch(errorInfo => {
        console.error('invalid create user form:', errorInfo);
      });

  return (
    <Modal open={visible} footer={null} onCancel={() => close()}>
      <Form
        form={form}
        {...formItemLayout}
        style={{ padding: '15px' }}
        requiredMark="true"
        labelAlign="left"
        onFinish={() => onFinish(form)}
      >
        <Form.Item
          id="userType"
          name="userType"
          label="User Type"
          rules={[{ required: true, message: 'Select user type' }]}
        >
          <Select placeholder="Select User Type" onChange={e => setUserType(e)}>
            <Select.Option value="AUDITOR">Auditor</Select.Option>
            <Select.Option value="PROVIDER">Provider</Select.Option>
            <Select.Option value="ADMINISTRATOR">Administrator</Select.Option>
            <Select.Option value="PAYER">Payer</Select.Option>
          </Select>
        </Form.Item>
        {userType === 'PAYER' && <InsurersSelect />}
        {userType === 'PROVIDER' && <FacilitiesSelect />}
        {userType && (
          <>
            <CommonFields />
            <Form.Item>
              <Button htmlType="submit">Create</Button>
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export { CreateUserModal };
