import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Modal, Spin } from 'antd';
import { useQuery } from 'urql';
import { UserContext } from '../context/user';

const FilterWithQuery = props => {
  const {
    displayName,
    filter,
    filterModal,
    renderComponent,
    query,
    query: { gql, queryFilter },
    field,
    tag,
    typeDef,
    options,
    userContext,
  } = props;

  const [input, setInput] = React.useState(null);
  const queryArguments = { query: gql };
  const lastQueryVariablesRef = useRef({});

  if (queryFilter) {
    queryArguments['variables'] = {
      ...queryArguments['variables'],
      ...queryFilter({
        input,
        userType: userContext.userType,
        permissions: userContext.permissions,
        search: userContext.search,
      }),
    };
  }
  if (query.autoComplete) {
    // DOC: (mv) ideally shouldTrigger would be atomic & owned by field def, however that'd result in code
    // duplication in the def (or weird 'this' calls like searchableFields.fieldName.someOtherFunc())
    // we might want to adopt another pattern for autoComplete if we use the query feature often
    const shouldTrigger =
      query.autoComplete.meetsAutoCompleteRequirement({
        input,
        userType: userContext.userType,
      }).result &&
      !query.autoComplete.isCurrentAutoCompleteResultValid({
        input,
        lastQueryVariables: lastQueryVariablesRef.current,
      });

    if (shouldTrigger) {
      lastQueryVariablesRef.current = {};
    }

    queryArguments['pause'] = !shouldTrigger;

    // dynamically added to searchableFieldType Obj
    query.autoComplete['autoCompleteState'] = {
      input,
      setInput,
    };
  }

  const [
    { data: queryData, error: queryError, fetching: isQueryFetching },
    refreshQuery,
  ] = useQuery({
    ...queryArguments,
  });

  useEffect(() => {
    lastQueryVariablesRef.current = queryError
      ? {}
      : queryArguments?.variables || {};
  }, [queryData]);

  return (
    <Spin spinning={isQueryFetching} size={'large'}>
      {renderComponent({
        displayName,
        query,
        queryData,
        isQueryFetching,
        queryError,
        lastQueryVariables: lastQueryVariablesRef.current,
        filter,
        filterModal,
        renderComponent,
        tag,
        typeDef,
        options,
        field,
        userContext,
        refreshQuery,
      })}
    </Spin>
  );
};

const FilterWithoutQuery = props => {
  const {
    filter,
    displayName,
    filterModal,
    typeDef,
    renderComponent,
    field,
    tag,
    options,
    userContext,
  } = props;

  return renderComponent({
    displayName,
    filter,
    filterModal,
    typeDef,
    renderComponent,
    field,
    tag,
    options,
    userContext,
  });
};

const SearchFieldModal = props => {
  const userContext = React.useContext(UserContext);
  const {
    filterModal: {
      displayName,
      visible,
      query,
      renderComponent,
      field,
      tag,
      typeDef,
      options,
    },
    setFilterModal,
    filter,
    setFilterSelectModalVisible,
  } = props;

  return (
    <Modal
      data-cy={`search-filter-modal-${field}`}
      open={visible}
      okButtonProps={{ 'data-cy': 'search-filter-modal-btn-ok' }}
      onOk={() => {
        setFilterModal({
          displayName: null,
          visible: false,
          query: null,
          field: null,
          typeDef: null,
          tag: null,
          renderComponent: null,
          options: null,
        });
        setFilterSelectModalVisible(true);
      }}
      cancelButtonProps={{ 'data-cy': 'search-filter-modal-btn-cancel' }}
      onCancel={() => {
        setFilterModal({
          displayName: null,
          visible: false,
          query: null,
          field: null,
          typeDef: null,
          tag: null,
          renderComponent: null,
          options: null,
        });
      }}
      destroyOnClose={true}
    >
      <div css={{ marginTop: '20px' }}>
        {query ? (
          <FilterWithQuery
            displayName={displayName}
            renderComponent={renderComponent}
            tag={tag}
            filter={filter}
            query={query}
            field={field}
            typeDef={typeDef}
            options={options}
            userContext={userContext}
          />
        ) : (
          <FilterWithoutQuery
            displayName={displayName}
            filter={filter}
            renderComponent={renderComponent}
            field={field}
            tag={tag}
            typeDef={typeDef}
            options={options}
            userContext={userContext}
          />
        )}
      </div>
    </Modal>
  );
};

export { SearchFieldModal };
