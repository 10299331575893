import { Dispatch, Key, SetStateAction, useMemo, useState } from 'react';
import {
  MatchedTextDisplay,
  UiAskAutodorFileResponse,
  UiAskAutodorFileResponseError,
  UiAskAutodorResponse,
} from './types';
import { useClient } from 'urql';
import { v5 as uuidv5 } from 'uuid';
import { askAutodorFileQuery } from './askAutodor.gql';
import { AskAutodorMatchedText } from '../../../gql/graphql';
import { PartialDeep } from 'type-fest';
import { useLog } from '../../../common/log/useLog';
import { omit } from 'lodash';

// TODO this is just based on initial results... check if the service gets the time/file/text as separate values we can keep sep?
const regex =
  // a date with optional leading 0s m/d/yy or mm/dd/yy   | time as xx:xx    | non-space, then / or \, then a file name ($1) rest ($2)
  /((?:0?[1-9]|1[0-2])\/(?:0?[1-9]|1[0-9]|2[0-9]|3[01])\/\d{2}, \d\d:\d\d (?:AM|PM)) [^ ]*[/\\]([^ /\\]*\.\w{2,5}) ([^]*)/;
const parseTextMatched = (
  key: Key,
  matchedText: AskAutodorMatchedText
): MatchedTextDisplay => {
  const { textMatched } = matchedText;
  const regexGroups = textMatched?.match(regex);
  const addl =
    !regexGroups || regexGroups.length < 4
      ? { text: textMatched, time: '', file: '' }
      : { text: regexGroups[3], time: regexGroups[1], file: regexGroups[2] };
  return { key, ...matchedText, ...addl };
};

export const useMatchedTextDisplayTypes = (
  matchedTexts: AskAutodorMatchedText[]
) => {
  return useMemo<MatchedTextDisplay[]>(
    () =>
      matchedTexts.map((m: AskAutodorMatchedText, i: number) =>
        // TODO:  Use generated key (uuidv5?) or see if b/e has a natural key:
        parseTextMatched('key_' + i, m)
      ),
    [matchedTexts]
  );
};

export type UseAskAutodorArgs = {
  documentId: string;
  prompt: string;
  responses: UiAskAutodorResponse[];
  setResponses: Dispatch<SetStateAction<UiAskAutodorResponse[]>>;
};

export const useAskAutodor = ({
  documentId,
  prompt,
  responses,
  setResponses,
}: UseAskAutodorArgs) => {
  const log = useLog('useAskAutodor');
  // log.setLevel(log.levels.DEBUG, false);

  const currentPromptHash = useMemo(
    () =>
      !prompt || !documentId
        ? ''
        : uuidv5(
            `${prompt}~~~${documentId}`,
            '833ea207-2f7c-43f9-816e-7aa41df39e1a'
          ),
    [prompt, documentId]
  );

  const client = useClient();

  const runQuery = () => {
    // keep hash:
    const key = currentPromptHash;
    const pendingResponse: UiAskAutodorResponse = {
      key,
      loading: true,
      response: {
        responseId: '',
        documentId,
        pages: [],
        pagesShown: 0,
        pagesTotal: 0,
        prompt,
        response: '',
      },
    };
    setResponses(existingResponses => [pendingResponse, ...existingResponses]);
    client
      .query(
        askAutodorFileQuery,
        {
          input: { documentId, prompt },
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then(result => {
        log.debug('aa result', result); // { data: ... }
        if (result.error) {
          log.error('result.error', result.error);
          setResponses((existingResponses: UiAskAutodorResponse[]) => {
            return [
              ...existingResponses.map(er => {
                const err = result.error?.message?.replaceAll('[GraphQL]', '');

                // error.graphQLErrors[0].extensions.payload.gptSearchServiceResponse.reason
                const payload: any =
                  result.error?.graphQLErrors[0]?.extensions?.payload;
                log.trace('setResponse payload', payload);
                const reason = payload?.gptSearchServiceResponse?.reason ?? err;
                if (er.key === key) {
                  const errFileResponse: UiAskAutodorFileResponseError = {
                    ...er.response,
                    documentId,
                    prompt:
                      result.data?.askAutodorFile?.prompt ??
                      er.response?.prompt ??
                      '',
                    error: true,
                    errorMessage: reason ?? 'Unknown error',
                    pages: [],
                    pagesShown: 0,
                    pagesTotal: 0,
                  };
                  const errResponse: UiAskAutodorResponse = {
                    ...er,
                    loading: false,
                    response: errFileResponse,
                  };
                  return errResponse;
                }
                return er;
              }),
            ];
          });
        }
        const isAskAutodorFileResponse = (
          aaResponse: unknown
        ): aaResponse is UiAskAutodorFileResponse => {
          return !!(aaResponse as any).askAutodorFile?.responseId;
        };
        if (result.data && isAskAutodorFileResponse(result.data)) {
          /*
          pages:
          type AskAutodorMatchedText {
            confidence: Float
            documentId: UUID!
            pageNumber: Int
            textMatched: String
          }
           */
          setResponses((existingResponses: UiAskAutodorResponse[]) => {
            // existingResponses.find(er => er.key === key);
            return [
              ...existingResponses.map(er => {
                if (er.key === key) {
                  // TODO remove this when we're confident we won't crash the rendering with too many:
                  const allPages = result.data?.askAutodorFile?.pages;
                  const pages = allPages ? allPages.slice(0, 500) : [];

                  const fileResponse: UiAskAutodorFileResponse = {
                    ...omit(result.data?.askAutodorFile, 'pages'),
                    pages,
                    pagesShown: pages.length,
                    pagesTotal: allPages?.length ?? 0,
                    // it's maddening to have to force this here but even using
                    // isAskAutodorFileResponse predicate above it's not narrowing...:
                    responseId: result.data!.askAutodorFile!.responseId,
                    documentId,
                    prompt: result.data?.askAutodorFile?.prompt ?? '',
                  };

                  const answer = {
                    ...er,
                    loading: false,
                    response: fileResponse,
                  };

                  log.trace(
                    `%c new aa response - pages: ${fileResponse.pages?.length}`,
                    'color: blue',
                    { answer, allPages }
                  );

                  return answer;
                }
                return er;
              }),
            ];
          });
        } else {
          // todo what should we do here? is it just an error like no data or no confidence?:
          log.info('Ask Autodor response has no data', result);
        }
      })
      .catch(e => log.error('catch e', e));
  };

  const loading = !!responses.find(r => r.loading);
  const currentPromptAnswered = !!responses.find(
    r => r.key === currentPromptHash
  );

  return {
    currentPromptHash,
    currentPromptAnswered,
    runQuery,
    loading,
    responses,
  };
};
