// external
import { React, useContext, useState } from 'react';
import { SetupTOTP } from './setupTOTP';

// component library
import { Modal, Form, Input, Radio, notification } from 'antd';
import { AlaffiaClientContext } from '../../alaffiaClient/alaffiaClientProvider';
import { useLog } from '../../common/log/useLog';

// internal

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const EditProfile = props => {
  const log = useLog('EditProfile');
  // log.setLevel(log.levels.DEBUG, false);

  const [form] = Form.useForm();

  let { visible, data } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [totpData, setTotpData] = useState(null);
  const [totpVerified, setTotpVerified] = useState(false);

  const alaffiaClient = useContext(AlaffiaClientContext);

  //Close from SetupTOTP
  const closeEdit = () => {
    //Reset radio back to SMS since we are canceling
    form.resetFields(['mfaType']);
    setIsEdit(false);
  };

  //Save Close From SetupTOTP
  const saveCloseEdit = async status => {
    setTotpVerified(true);
    setIsEdit(false);
  };

  const onFinishFailed = errorInfo => {
    console.error('EditProfile: onFinishFailed', errorInfo);
  };

  const cancelProfileEdit = () => {
    form.resetFields();
    props.closeEdit();
  };

  const okProfileEdit = async () => {
    //Also pass back if MFA Verification error
    return form.validateFields().then(async values => {
      form.resetFields();
      props.saveCloseEdit(values, totpVerified);
    });
  };

  //Get accessToken and then open Token Validation screen
  const handleMfaChange = async e => {
    const { value } = e.target;
    if (value === 'TOTP') {
      // const response = await fetch(`${process.env.REACT_APP_API_URL}/totp`, {
      //   method: 'POST',
      //   body: JSON.stringify({}),
      //   headers: {
      //     'Content-Type': 'application/json',
      //     authorization: `Bearer ${localStorage.getItem(`auth.accessToken`)}`,
      //   },
      // });

      const response = await alaffiaClient.fetchAuthenticatedPost(
        '/totp',
        undefined
      );

      const dataMfa = await response.json();

      const { seed } = dataMfa;
      //If we did not get the seed, usually network, or jwt expired issue, then return...
      if (!seed) return;

      const { email } = data.auditorUser;
      const thisUrl = window.location.host;

      const link = `otpauth://totp/${thisUrl}:${email}?secret=${seed}&issuer=${thisUrl}`;

      // const link = `otpauth://totp/app.dev-alaffia.io:${email}?secret=${seed}&issuer=app.dev-alaffia.io`;

      setTotpData({ link, seed });

      setIsEdit(true);
    }
  };

  return (
    <div>
      <Modal
        title="Edit Profile Information"
        open={visible}
        destroyOnClose={true}
        closable={true}
        okText="Submit"
        //footer={null}
        onCancel={cancelProfileEdit}
        onOk={okProfileEdit}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinishFailed={onFinishFailed}
        >
          {/* <Form.Item
                        name={['mobilePhoneNumber']}
                        label="Mobile Phone Number"
                        rules={[
                            {
                                pattern: new RegExp("^([0-9]+-)*[0-9]+$"),
                                message: 'Input mobile phone number using digits and/or hyphens',
                            },
                        ]}
                    >
                        <Input
                            placeholder={data ? data.auditorUser.userProfile.mobilePhoneNumber : ""}
                            style={{
                                width: '50%',
                            }}
                        />
                    </Form.Item> */}
          <Form.Item
            name={['workPhoneNumber']}
            label="Work Phone Number"
            rules={[
              {
                pattern: new RegExp('^([0-9]+-)*[0-9]+$'),
                message: 'Input work phone number using digits and/or hyphens',
              },
            ]}
          >
            <Input
              placeholder={
                data ? data.auditorUser.userProfile.workPhoneNumber : ''
              }
              style={{
                width: '50%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={['company']}
            label="Company Name"
            rules={[
              {
                message: 'Please input your company name',
              },
            ]}
          >
            <Input
              placeholder={data ? data.auditorUser.userProfile.company : ''}
              style={{
                width: '60%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={['addressStreet']}
            label="Street Address"
            rules={[
              {
                message: 'Please input your street address',
              },
            ]}
          >
            <Input
              placeholder={
                data ? data.auditorUser.userProfile.addressStreet : ''
              }
              style={{
                width: '80%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={['addressCity']}
            label="City"
            rules={[
              {
                message: 'Please input your city',
              },
            ]}
          >
            <Input
              placeholder={data ? data.auditorUser.userProfile.addressCity : ''}
              style={{
                width: '50%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={['addressState']}
            label="State"
            rules={[
              {
                max: 2,
                message: 'Use 2 Character State',
              },
            ]}
          >
            <Input
              placeholder={
                data ? data.auditorUser.userProfile.addressState : ''
              }
              style={{
                width: '20%',
              }}
            />
          </Form.Item>
          <Form.Item
            name={['addressZipcode']}
            label="Zip Code"
            rules={[
              {
                message: 'Please input your zipcode',
              },
            ]}
          >
            <Input
              placeholder={
                data ? data.auditorUser.userProfile.addressZipcode : ''
              }
              style={{
                width: '40%',
              }}
            />
          </Form.Item>
          Two Factor Authentication Type:
          <Form.Item
            name={['mfaType']}
            label="Select One"
            initialValue={data ? data.auditorUser.userProfile.mfaType : ''}
          >
            <Radio.Group name="mfaType" onChange={handleMfaChange}>
              <Radio value={'SMS'}>SMS</Radio>
              <Radio value={'TOTP'}>Token Based</Radio>
              {/* <Radio value={'NONE'}>None</Radio> */}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <SetupTOTP
        visible={isEdit}
        closeEdit={closeEdit}
        saveCloseEdit={saveCloseEdit}
        data={totpData}
      />
    </div>
  );
};

export { EditProfile };
