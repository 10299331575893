import { css } from '@emotion/react';
import React from 'react';

const clsFlex = css({ flex: 'initial' });
const clsLarge = css({ fontSize: '125%' });
const clsTitleContainer = css({
  display: 'flex',
  flexDirection: 'column',
  flex: 'auto',
});
const clsTitle = css({
  marginBottom: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  lineHeight: '.9em',
});
const clsDescription = css({
  fontSize: '.9em',
  lineHeight: '.9em',
  marginTop: 0,
  paddingTop: 0,
  paddingLeft: 0,
  color: 'rgba(0, 0, 0, 0.45)',
});

const TitleWithDescription = ({ title, description }) => {
  return (
    <div css={[clsTitleContainer]}>
      <div css={[clsFlex, clsTitle]}>
        <h1 css={clsLarge}>{title}</h1>
      </div>
      <div css={[clsFlex, clsDescription]}>{description}</div>
    </div>
  );
};

export default TitleWithDescription;
