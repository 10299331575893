import React, { createContext, FC, ReactNode, useMemo } from 'react';
import { useLog } from '../common/log/useLog';
import { useObservableSubscription } from '../util/Observable';
import { SWClient } from './swIpc/client';
import { getSwClientObservable } from './serviceWorkerClient';

export const SwClientContext = createContext<SWClient | undefined>(undefined);

export const SwClientProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const log = useLog('pdfViewerProvider');
  // log.setLevel(log.levels.DEBUG, false);

  const swClient = useObservableSubscription(getSwClientObservable());

  const renderCount = React.useRef(0);
  renderCount.current++;
  log.debug(
    '%c (%s) render sw client provider',
    'color: #3333FF; background-color: orange',
    renderCount.current
  );

  return (
    <SwClientContext.Provider value={swClient}>
      {children}
    </SwClientContext.Provider>
  );
};
