import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Button, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { AuditDocumentation } from '../../../gql/graphql';

type AskAutodorFileChooserMenuItem = {
  label: string;
  value: AuditDocumentation;
  setDocument: Function;
};

export const AskAutodorFileChooserMenuItem = ({
  label,
  value,
  setDocument,
}: AskAutodorFileChooserMenuItem) => {
  return (
    <Tooltip title={`Select one of these prompt questions to ask autodor!`}>
      <div
        onClick={() => {
          setDocument(value);
        }}
      >
        {/*<strong>Ask Audodor:</strong>*/}
        {label}
      </div>
    </Tooltip>
  );
};

function menuItems(
  documents: AuditDocumentation[],
  setDocument: any
): MenuProps['items'] {
  return documents.map((ad: AuditDocumentation, index: number) => {
    return {
      label: (
        <AskAutodorFileChooserMenuItem
          label={ad.fileName}
          value={ad}
          setDocument={setDocument}
        />
      ),
      key: index,
    };
  });
}

type AskAutodorPromptTemplates = {
  documents: AuditDocumentation[];
  document: AuditDocumentation | null;
  setDocument: Function;
};

export const AskAutodorFileChooser = ({
  documents,
  document,
  setDocument,
}: AskAutodorPromptTemplates) => {
  const items: MenuProps['items'] = menuItems(documents, setDocument);
  return (
    <Dropdown menu={{ items }}>
      <Button
        size="small"
        tw="_bg-transparent _shadow-none  _text-antd-lt-colorTextTertiary dark:_text-antd-dk-colorTextTertiary"
      >
        {document?.fileName ?? 'No document selected'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
