import React from 'react';
import { css } from 'twin.macro';

export type FormExtraProps = {
  children?: React.ReactNode;
  minHeight?: React.CSSProperties['height'];
  className?: string;
  visible: boolean;
};

export const FormExtra = ({
  children,
  visible,
  className,
  minHeight = '1.5em',
}: FormExtraProps) => (
  <div
    tw="_text-[0.75em]"
    css={css({
      minHeight,
      // border: '1px dashed',
    })}
    className={className}
  >
    {visible ? children : <></>}
  </div>
);
