import { createContext } from 'react';
import { UserContext } from '../context/user';
import { useSubject } from '../../util/Observable';
import { useLog } from '../../common/log/useLog';

export const UnhandledRejectionObservable = createContext(null);

export const UnhandledRejectionsCatch = ({ children }) => {
  const log = useLog('UnhandledRejectionsCatch');
  const [next, observable] = useSubject();
  window.onunhandledrejection = err => {
    const reason =
      err?.reason?.message || err?.reason?.reason || err?.message || null;
    const msg = `Unhandled promise rejection${reason ? ': ' + reason : ''}`;
    // DOC: enable more or less visibility based on env
    if (process.env.REACT_APP_DD_ENV === 'development') {
      log.warn(msg, err);
    } else {
      // in prod, always debug log, so we have for troubleshooting
      console.debug(msg, err);
    }
    err.preventDefault();
    next(err);
  };

  return (
    <UnhandledRejectionObservable.Provider value={observable}>
      {children}
    </UnhandledRejectionObservable.Provider>
  );
};
