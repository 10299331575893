import { Dispatch, Reducer, useCallback, useEffect, useReducer } from 'react';
import {
  Action,
  ActionFunction,
  createReducer,
  GenericAction,
} from './genericReducer';
import { FieldPath, FieldPathValue } from '../typing/propPath';
import { isFunction } from 'lodash';

export type ClassWithReducer<StateType extends Object> = {
  getInitialState: () => StateType;
  setDispatcher: (dispatchers: ClassWithReducerDispatchers<StateType>) => void;
  reducer: ReturnType<typeof createReducer<StateType>>;
};

type SetStateFnValueType<
  StateType extends object,
  N extends FieldPath<StateType>,
> = FieldPathValue<StateType, N>;
type SetStateFnFunctionType<
  StateType extends object,
  N extends FieldPath<StateType>,
> = (slice: N, fullState: StateType) => FieldPathValue<StateType, N>;
export type SetStateFn<StateType extends object> = {
  <N extends FieldPath<StateType>>(
    path: N,
    value:
      | SetStateFnValueType<StateType, N>
      | SetStateFnFunctionType<StateType, N>
  ): void;
};

export type ClassWithReducerDispatchers<StateType extends object> = {
  dispatch: Dispatch<GenericAction<StateType, any>>;
  setState: SetStateFn<StateType>;
  replaceState: (state: StateType) => void;
};

export const useClassWithReducer = <StateType extends object>(
  classInstance: ClassWithReducer<StateType>
): StateType => {
  const [state, dispatch] = useReducer<
    Reducer<StateType, GenericAction<StateType, any>>,
    any
  >(
    classInstance.reducer,
    {} as StateType,
    arg => (classInstance.getInitialState() as StateType) ?? ({} as StateType)
  );

  const setState = useCallback(
    function setState<N extends FieldPath<StateType>>(
      path: N,
      value:
        | FieldPathValue<StateType, N>
        | ((slice: N, fullState: StateType) => FieldPathValue<StateType, N>)
    ) {
      if (isFunction(value)) {
        dispatch(new ActionFunction<StateType, N>(path, value));
      } else {
        dispatch(new Action<StateType>(path, value));
      }
    },
    [classInstance, dispatch]
  );

  const replaceState = useCallback(
    function replaceState<StateType extends Object>(state: StateType) {
      dispatch(
        new ActionFunction('', (prev, fullState) => {
          // this is probably unnecessary but getting typescript to accept
          // the new state T as StateType in genericReducer when returning it
          // as the result of immer .produce() proved tricky... rather than jump
          // out of immer and just return a plain object, the shortcut for now is
          // this bit of excess computation:
          Object.keys(prev).forEach(k => {
            delete prev[k];
          });
          Object.keys(state).forEach(
            k => (prev[k] = state[k as keyof StateType])
          );
        })
      );
    },
    [classInstance, dispatch]
  );

  useEffect(() => {
    classInstance.setDispatcher({
      dispatch,
      setState,
      replaceState,
    });
  }, [classInstance, dispatch]);

  return state;
};
