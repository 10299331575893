import React, { createContext, FC, useContext, useMemo, useRef } from 'react';
import { FindingEditor, useFindingEditor } from './useFindingEditor';
import { UUID } from '../../../../../types/ids';
import { FindingModal } from './modal/findingModal';
import {
  BatchClaim,
  Claim,
  WorkflowClaimStateEnum,
} from '../../../../gql/graphql';
import { UserContext } from '../../../context/user';
import { UserType } from './types';

export type FindingContextType = {
  findingEditor: FindingEditor;
  inlineOpenId: string | null;
  claimState: WorkflowClaimStateEnum | null;
  canViewAutodorFindings: boolean;
  showAutodorFindingsDefault: boolean;
};

export const FindingContext = createContext<FindingContextType>(null!);

type FindingsContextProps = {
  children: React.ReactNode;
  claimId: UUID;
  batchClaim: BatchClaim;
  claim: Claim;
};

export const getAutodorVisibilitySettings = (
  claimState: string,
  userType: string
) => {
  // spec is 'not completed or archived' but let's whitelist & catch additions in a test:
  const defaultVisibleStates = ['CLAIM_RECEIVED', 'TO_DO', 'IN_PROGRESS'];

  const canViewAutodorFindings: boolean =
    ['ADMINISTRATOR', 'AUDITOR'].includes(userType) ||
    // for payer, we'll also visually hide them when completed/archived:
    ('PAYER' === userType && defaultVisibleStates.includes(claimState));

  const showAutodorFindingsDefault: boolean =
    !!claimState &&
    !!canViewAutodorFindings &&
    defaultVisibleStates.includes(claimState);
  return { canViewAutodorFindings, showAutodorFindingsDefault };
};

export const FindingContextProvider: FC<FindingsContextProps> = ({
  claimId,
  children,
  batchClaim,
  claim,
}) => {
  const last = useRef({ claimId, batchClaim, claim, children });
  last.current = { claimId, batchClaim, claim, children };

  const user: any = useContext(UserContext);
  const userType: UserType = useMemo(() => user!.userType, []);

  const [findingEditor, isModalOpen, inlineOpenId] = useFindingEditor({
    claimId,
    batchClaim,
    claim,
  });

  const claimState =
    (batchClaim as any).batchClaimState?.workflowClaimState ?? null;

  const { canViewAutodorFindings, showAutodorFindingsDefault } =
    getAutodorVisibilitySettings(claimState, userType);

  const value: FindingContextType = {
    findingEditor,
    inlineOpenId,
    claimState,
    canViewAutodorFindings,
    showAutodorFindingsDefault,
  };

  return (
    <FindingContext.Provider value={value}>
      {children}
      <FindingModal open={isModalOpen}></FindingModal>
    </FindingContext.Provider>
  );
};
