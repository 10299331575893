import React, { useContext, useMemo, useState, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import {
  Button,
  Tag,
  Space,
  Row,
  Col,
  Statistic,
  Tabs,
  Tooltip,
  Badge,
} from 'antd';
import { ReportsVersionList } from '../../reports/reportsVersionList';
import {
  CalendarTwoTone,
  AuditOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { ClaimWorkspaceHeader } from './claimWorkspaceHeader';
import { ClaimWorkspaceTable } from './claimWorkspaceTable';
import { ItemizedBillTable } from './itemizedBillTab/itemizedBillTable';
import { IbTab } from './itemizedBillTab/ibTab';
import { CreateAuditFindingModal } from './createAuditFinding/createAuditFindingModal';
import { DeclineAuditFindingModal } from './createAuditFinding/declineAuditFindingModal';
import { UBClaimAuditFinding } from './auditFinding/ubClaimAuditFinding';
import { ViewDocumententationModal } from './viewDocumententationModal';
import { dateString } from '../util';
import { UserContext } from '../../context/user';
import { FindingsTable } from './findingsTab/findingsTable';
import { RefreshBatchClaimContext } from '../auditWorkspace';
import { useHashParams } from '../../../common/navigation/useHashParam';
import { DrgTab, DrgReviewDataDefault } from './drg/drgTab';
import { ViewDocumentsButton } from '../documents/viewDocumentsButton';
import { DocumentViewer } from '../../documentViewer/documentViewer';

const PureClaimWorkspace = props => {
  const { style, setDocumentationViewerProps, batchClaim } = props; // todo rewire style prop?
  const ctxRefresh = useContext(RefreshBatchClaimContext);
  const user = React.useContext(UserContext);
  const history = useHistory();
  const batchClaimId = batchClaim.id;
  const afclFilter = {
    isActive: { equalTo: true },
  };

  const {
    batchClaimState: { workflowClaimState },
    auditDocumentation: files,
    ubClaimFindings,
  } = batchClaim || {
    auditDocumentation: [],
  };

  const viewingReport =
    useRouteMatch('/reports/:auditFindingReportId/workspace') !== null;

  const [creatingAuditFinding, setCreatingAuditFinding] = React.useState({
    visible: false,
    auditFindingSeedType: null,
  });
  const [decliningAuditFinding, setDecliningAuditFinding] = React.useState({
    visible: false,
  });

  const [claimLevelFindingModalVisible, setClaimLevelFindingModalVisible] =
    React.useState(false);
  const [viewDocumentNamesModalVisible, setViewDocumentNamesModalVisible] =
    React.useState(false);
  const [viewingClaimLevelFinding, setViewingClaimLevelFinding] =
    React.useState(false);

  const [askAutodorOpen, setAskAutodorOpen] = React.useState(false);
  // drg screen variables:
  const [drgReviewData, setDrgReviewData] =
    React.useState(DrgReviewDataDefault);

  const readOnly = workflowClaimState !== 'IN_PROGRESS' || viewingReport;

  const onOk = e => {
    setClaimLevelFindingModalVisible(false);
  };

  const renderViewDocumentsButton = () => (
    <>
      <ViewDocumentsButton claimId={batchClaimId}></ViewDocumentsButton>

      {/*<Button*/}
      {/*  id="old-view-documents-button"*/}
      {/*  icon={<FileSearchOutlined />}*/}
      {/*  onClick={() => {*/}
      {/*    const { viewDocs, viewDocList } = user.permissions;*/}
      {/*    if (viewDocs && viewDocList) {*/}
      {/*      setDocumentationViewerProps({*/}
      {/*        openToSpecificFileByFileName: null, // null defaults to 1st file in list of files*/}
      {/*        openToSpecificPage: 1,*/}
      {/*        visible: true,*/}
      {/*        variables: { batchClaimId },*/}
      {/*      });*/}
      {/*    } else if (viewDocList) {*/}
      {/*      setViewDocumentNamesModalVisible(true);*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  css={{*/}
      {/*    padding: '0 7px',*/}
      {/*    fontSize: '12px',*/}
      {/*    lineHeight: '20px',*/}
      {/*    height: 'auto',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  oVD*/}
      {/*</Button>*/}
    </>
  );

  const renderClaimFindingToggle = () =>
    viewingClaimLevelFinding ? (
      <Button
        id="create-ub04-claim-audit-finding"
        data-cy="claim-level-audit-finding-hide"
        type="primary"
        icon={<AuditOutlined />}
        css={{
          padding: '0 7px',
          fontSize: '12px',
          lineHeight: '20px',
          height: 'auto',
          marginLeft: '5px',
        }}
        onClick={() => setViewingClaimLevelFinding(false)}
      >
        {viewingClaimLevelFinding ? 'View Claim Info' : 'View Claim Finding'}
      </Button>
    ) : (
      <Badge count={ubClaimFindings.nodes.length} size="small">
        <Button
          id="create-ub04-claim-audit-finding"
          data-cy="claim-level-audit-finding-show"
          type="primary"
          icon={<AuditOutlined />}
          css={{
            padding: '0 7px',
            fontSize: '12px',
            lineHeight: '20px',
            height: 'auto',
            marginLeft: '5px',
          }}
          onClick={() => setViewingClaimLevelFinding(true)}
        >
          {viewingClaimLevelFinding ? 'View Claim Info' : 'View Claim Finding'}
        </Button>
      </Badge>
    );

  const renderClaimAuditFindingButton = () =>
    ubClaimFindings.nodes.length > 0 ? (
      renderClaimFindingToggle()
    ) : (
      <Button
        id="create-ub04-claim-audit-finding"
        data-cy="claim-level-audit-finding-create"
        type="primary"
        icon={<AuditOutlined />}
        css={{
          padding: '0 7px',
          fontSize: '12px',
          lineHeight: '20px',
          height: 'auto',
          marginLeft: '5px',
        }}
        disabled={readOnly}
        onClick={() =>
          setCreatingAuditFinding({
            visible: true,
            auditFindingSeedType: 'UB_CLAIM',
          })
        }
      >
        Claim Level Finding
      </Button>
    );

  const renderClaimInfo = userType => (
    <div tw="_pr-6">
      <div tw="_pt-2 _flex _flex-row _justify-between _items-center">
        <div>
          <Tag>
            {/* "Inpatient Hospital Claim" is spelled "Inpatient Hopital Claim" in mdh
              raw data, so we correct for that here */}
            {
              {
                'Outpatient Hospital Claim': 'Outpatient Hospital Claim',
                'Inpatient Hopital Claim': 'Inpatient Hospital Claim',
              }[batchClaim.claimType]
            }
          </Tag>
          <Tooltip title={`Dates of Service`}>
            <Space>
              <CalendarTwoTone twoToneColor="red" />
              {dateString(
                batchClaim.dateOfServiceStart,
                batchClaim.dateOfServiceEnd
              )}
            </Space>
          </Tooltip>
        </div>
        <div>
          {batchClaim.claimType === 'Inpatient Hopital Claim' &&
            batchClaim.dateAdmit && (
              <Tooltip
                title={`Date Admitted${
                  batchClaim.dateDischarge ? ` - Date Discharged` : ''
                }`}
              >
                <Space>
                  <CalendarTwoTone />
                  {dateString(batchClaim.dateAdmit, batchClaim.dateDischarge)}
                </Space>
              </Tooltip>
            )}
        </div>
      </div>
      <div tw="_flex _flex-row">
        <div tw="_w-1/2">
          {!user.workstation.showOnlyBilledAndAllowedAmount && (
            <>
              <Statistic
                tw="_flex _flex-row _items-baseline"
                title={
                  <div
                    id={'claim-workstation-potential-client-savings'}
                    css={{ marginRight: 29 }}
                  >
                    Potential Savings
                  </div>
                }
                prefix="$"
                value={batchClaim.batchClaimState.potentialClientSavings}
                precision={2}
              />
              <Statistic
                tw="_flex _flex-row _items-baseline"
                title={
                  <div
                    id={'claim-workstation-determined-client-realized-savings'}
                    css={{ marginRight: 29 }}
                  >
                    {batchClaim.batchClaimState.isRealized
                      ? 'Realized Savings'
                      : 'Determined Savings'}
                  </div>
                }
                prefix="$"
                value={batchClaim.batchClaimState.determinedClientSavings}
                precision={2}
              />
              <Statistic
                tw="_flex _flex-row _items-baseline"
                title={
                  <div
                    id={'claim-workstation-determined-adj-allowed-amount'}
                    css={{ marginRight: 10 }}
                  >
                    Adjusted Allowed Amount
                  </div>
                }
                prefix="$"
                value={
                  batchClaim.batchClaimState.determinedAdjustedAllowedAmount
                }
                precision={2}
              />
            </>
          )}
        </div>
        <div tw="_w-1/2">
          <Statistic
            tw="_flex _flex-row-reverse _items-baseline"
            title={<div css={{ marginLeft: 26 }}>Billed Amount</div>}
            prefix="$"
            value={batchClaim.amountCharged}
            precision={2}
          />
          <Statistic
            tw="_flex _flex-row-reverse _items-baseline"
            title={<div css={{ marginLeft: 5 }}>Allowed Amount</div>}
            prefix="$"
            value={batchClaim.amountReimbursed}
            precision={2}
          />
        </div>
      </div>
    </div>
  );

  const renderClaimLevelAuditFinding = () => (
    <UBClaimAuditFinding
      {...{
        setCreatingAuditFinding,
        setDecliningAuditFinding,
        ubClaimFindings,
        batchClaim,
        afclFilter,
        readOnly,
        setViewingClaimLevelFinding,
      }}
    />
  );

  const ClaimTab = userType => {
    return (
      <div>
        <Row>
          <div>
            {
              // claim has files
              files.length > 0 &&
                // user can view docs or doc names
                (user.permissions.viewDocs || user.permissions.viewDocList) &&
                renderViewDocumentsButton()
            }
            {/* Only allow 1 claim level audit finding */}
            {!viewingReport &&
              (user.permissions.findingsActions.includes('create') ||
                user.permissions.findingsActions.includes('decline') ||
                user.permissions.findingsActions.includes('review') ||
                user.permissions.findingsActions.includes('dispute')) &&
              renderClaimAuditFindingButton()}
          </div>
          {user.permissions.findingsActions.includes('view') &&
          ubClaimFindings.nodes.length > 0 &&
          viewingClaimLevelFinding ? (
            <Col span={24} align="middle">
              {renderClaimLevelAuditFinding()}
            </Col>
          ) : (
            <Col span={24} align="middle">
              {renderClaimInfo(userType)}
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const ubClaimFindingAccepted = ubClaimFindings =>
    ubClaimFindings.nodes.some(({ accepted, reviews }) =>
      // if the claim level finding has a review
      // (this is the latest review: see auditFindingReviewsFragment and auditFindingFragment)
      // then return whether or not the latest review is accepted. If there is not a review,
      // then return the accepted value for the original
      reviews.nodes.length > 0
        ? reviews.nodes[0].auditFinding.accepted
        : accepted
    );

  // The findings button will be disabled if
  // - The claim is not IN_PROGRESS
  // - The user is not a ADMIN or AUDITOR
  // - There exists an accepted UB Claim Finding
  const readOnlyAuditFindings = // -> iife
    workflowClaimState !== 'IN_PROGRESS' ||
    user.userType === 'PROVIDER' ||
    user.userType === 'PAYER' ||
    ubClaimFindingAccepted(ubClaimFindings);

  const tabToolbarRef = React.useRef(null);

  const navigateToIbinRow = useCallback(({ rowId, findingId }) => {
    // console.log('navigateToIbinRow', rowId, findingId, selectedTab);
    hashBuilder
      .reset()
      .add('IBIN', true)
      .add('rowId', rowId)
      .add('findingId', findingId)
      .commit();
  }, []);

  const tabs = {
    ...(![
      // n.b. DRG tab mockup is gated to claim 7:
      '80073b1d-3b2a-5237-8ebb-1ab61db66d62', // DEMO claim 7 - claim # dc00007
    ].includes(batchClaimId)
      ? {}
      : {
          DRG: (
            <>
              <DrgTab
                batchClaim={batchClaim}
                renderViewDocumentsButton={renderViewDocumentsButton}
                askAutodorOpen={askAutodorOpen}
                drgReviewData={drgReviewData}
                setDrgReviewData={setDrgReviewData}
              />
            </>
          ),
        }),
    UB: (
      <div tw="_h-full _flex _flex-col">
        <div tw="_w-full">
          <ClaimTab userType={user.userType} />
        </div>
        <div tw="_grow">
          <div tw="_w-full _h-full">
            <ClaimWorkspaceTable
              {...{
                setCreatingAuditFinding,
                setDecliningAuditFinding,
                navigateToIbinRow,
                readOnly: readOnlyAuditFindings,
                variables: {
                  batchClaimId,
                  afclFilter,
                },
                batchClaim,
              }}
            />
          </div>
        </div>
      </div>
    ),
    IB: (
      <div tw="_h-full">
        <ItemizedBillTable
          {...{
            setCreatingAuditFinding,
            setDecliningAuditFinding,
            navigateToIbinRow,
            readOnly: readOnlyAuditFindings,
            variables: {
              batchClaimId,
              afclFilter,
            },
          }}
        />
      </div>
    ),
    IBIN: (
      <div tw="_box-border _p-0 _m-0 _min-h-full _h-full">
        <IbTab
          {...{
            setCreatingAuditFinding,
            setDecliningAuditFinding,
            navigateToIbinRow,
            readOnly: readOnlyAuditFindings,
            variables: {
              batchClaimId,
              batchClaim,
              auditFindings: batchClaim?.auditFindings?.nodes ?? [],
              afclFilter,
            },
          }}
          tabToolbarRef={tabToolbarRef}
        />
      </div>
    ),
    Findings: (
      <FindingsTable
        {...{
          setCreatingAuditFinding,
          setDecliningAuditFinding,
          navigateToIbinRow,
          readOnly: readOnlyAuditFindings,
          variables: {
            batchClaimId,
            afclFilter,
          },
        }}
      />
    ),
    Reports: (
      <ReportsVersionList batchClaimId={batchClaimId} isLatestVersion={false} />
    ),
  };

  const visibleTabs = useMemo(() => user.workstation.tabs, [user]);

  // Extracts selectedTabName from URL
  // If no valid tab selection is in the URL then it is set to 'UB' by default
  const tabKeys = Object.keys(tabs);
  const [hashParams, hashBuilder] = useHashParams([tabKeys]);
  const selectedTab = Object.keys(hashParams).find(hp => tabKeys.includes(hp)); // history.location.hash.replace('#', '').split('&')[0];
  const setSelectedTab = tab => {
    // history.replace(`${history.location.pathname}#${tab}`);
    hashBuilder.reset().add(tab, true).commit();
    ctxRefresh();
  };
  React.useEffect(() => {
    // console.log(
    //   '%cselectedTab',
    //   'font-weight:bold; font-style:italic;',
    //   selectedTab
    // );
    if (!(selectedTab in tabs) || !(selectedTab in visibleTabs)) {
      // set default selected tab to 'UB'
      setSelectedTab('UB');
    }
  }, [selectedTab]);

  const filteredTabs = useMemo(() => {
    const ft = Object.keys(tabs)
      .filter(key => {
        return key in visibleTabs;
      })
      .map(key => ({
        key,
        label: key,
        children: undefined,
      }));
    // console.log('filtered tabs' + ft);
    return ft;
  }, [visibleTabs]); // tabs is const so no need in deps

  const clsFull = css(
    tw`_box-border _h-full _min-h-full _max-h-full _w-full  _min-w-full`
  );

  return (
    <div tw="_h-full _max-h-full _min-h-full _scrollbar-none _flex _flex-col _px-2">
      <div>
        <ClaimWorkspaceHeader
          batchClaim={batchClaim}
          variables={{ afclFilter }}
          askAutodorOpen={askAutodorOpen}
          setAskAutodorOpen={setAskAutodorOpen}
          setDocumentationViewerProps={setDocumentationViewerProps}
        />
      </div>
      <div tw="_flex _flex-row">
        <Tabs
          activeKey={selectedTab}
          items={filteredTabs}
          size="small"
          onChange={setSelectedTab}
          className="content_row_card"
          style={{ width: '100%' }}
          destroyInactiveTabPane={false}
          tabBarExtraContent={{
            right: (
              <div tw="_max-w-sm _h-8 _p-2 _max-h-8 _overflow-hidden">
                <div ref={tabToolbarRef}></div>
              </div>
            ),
          }}
        ></Tabs>
      </div>
      <div tw="_grow">{tabs[selectedTab]}</div>

      <ViewDocumententationModal
        viewDocumentNamesModalVisible={viewDocumentNamesModalVisible}
        setViewDocumentNamesModalVisible={setViewDocumentNamesModalVisible}
        batchClaimId={batchClaimId}
      />
      {user.permissions.findingsActions.includes('create') && (
        <CreateAuditFindingModal
          {...{
            ...creatingAuditFinding,
            setCreatingAuditFinding,
            batchClaim,
            afclFilter,
          }}
        />
      )}
      <DeclineAuditFindingModal
        {...{
          ...decliningAuditFinding,
          setDecliningAuditFinding,
          afclFilter,
        }}
      />
      <DocumentViewer claimId={batchClaimId}></DocumentViewer>
    </div>
  );
};

const ClaimWorkspace = PureClaimWorkspace;

export { ClaimWorkspace, PureClaimWorkspace };
