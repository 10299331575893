import React, { FC, useContext, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { AskAutodorIcon } from './askAutodorIcon';
import { useAskAutodorFileVectorsExist } from './useAskAutodorFileVectorsExist';
import { AskAutodorDrawer } from './askAutodorDrawer';
import { AuditDocumentation } from '../../../gql/graphql';
import { UserContext } from '../../context/user';

export const AskAutodorButton: FC<{
  documents: AuditDocumentation[] | null | undefined;
  askAutodorOpen: boolean;
  setAskAutodorOpen: (val: boolean) => void;
  claimId: string;
  setDocumentationViewerProps: Function; // todo
}> = ({
  documents,
  askAutodorOpen,
  setAskAutodorOpen,
  claimId,
  setDocumentationViewerProps,
}) => {
  const { userType } = useContext(UserContext) as any;

  const documentIds = useMemo(
    () => documents?.map(d => d.id) ?? [],
    [documents]
  );
  const [{ fetching: fetching, data, error }] =
    useAskAutodorFileVectorsExist(documentIds);
  // const availableDocuments =
  //   data?.askAutodorFileVectorsExist?.documentIds ?? [];
  const availableDocuments: AuditDocumentation[] = useMemo(
    () =>
      (documents ?? []).filter(
        // d => true
        d =>
          (data?.askAutodorFileVectorsExist?.documentIds ?? []).includes(d.id)
      ),
    [data]
  );

  return userType === 'PROVIDER' ? (
    <></>
  ) : (
    <>
      <Tooltip
        title={
          userType === 'PAYER'
            ? "Ask your Account Manager about 'Ask Autodor,' Alaffia's AI enabled assistant for claim review"
            : error
              ? `Failed to load Ask Autodor document list: ${error.message}`
              : availableDocuments?.length > 0
                ? `${availableDocuments.length} documents available`
                : 'No documents available to search'
        }
      >
        <Button
          tw="_w-11 _p-1 _mx-3"
          onClick={() => setAskAutodorOpen(!askAutodorOpen)}
          disabled={!(availableDocuments.length > 0)}
        >
          <AskAutodorIcon />
        </Button>
      </Tooltip>
      <AskAutodorDrawer
        documents={availableDocuments ?? []}
        open={askAutodorOpen}
        setOpen={setAskAutodorOpen}
        claimId={claimId}
        setDocumentationViewerProps={setDocumentationViewerProps}
      ></AskAutodorDrawer>
    </>
  );
};
