import { useState, useContext } from 'react';
import { css } from '@emotion/react';
import { Layout, Button, Modal, Tag, Tooltip, theme } from 'antd';
import { SearchFieldModal } from './searchFieldModal';
import { useHistory, useParams } from 'react-router-dom';

import { UserContext } from '../context/user';
import { BatchClaimSearchFilter } from './batchClaimSearchFilter';
import { SearchFieldsMenu } from './searchable/searchFieldsMenu';

const tagStyle = css`
  cursor: pointer;
  margin: 5px;
`;

const FilterTags = ({ filter, setFilterModal }) => {
  const { userType } = useContext(UserContext);
  const tags = [];
  filter.filterMap.forEach(
    (
      {
        filterFragment,
        displayName,
        value,
        typeDef,
        tag,
        renderComponent,
        query,
        options,
      },
      field
    ) =>
      tags.push({
        tagKey: tag.tagKey,
        tooltipTitle: tag.tagTooltipTitle(value, userType),
        tagText: tag.tagText(value, userType),
        typeDef,
        filterFragment,
        value,
        field,
        renderComponent,
        tag,
        query,
        options,
        displayName,
      })
  );
  return (
    <span>
      {tags.map(({ tagKey, tooltipTitle, tagText, ...filterProps }) => {
        return (
          <Tooltip
            id={`${tagKey}-tag-tooltip`}
            key={`${tagKey}-tag-tooltip`}
            title={tooltipTitle}
          >
            <Tag
              id={`${tagKey}-${filterProps.field}-filtertag`}
              key={`${tagKey}-${filterProps.field}-filtertag`}
              css={tagStyle}
              closable={setFilterModal} // means we can edit
              onClose={() => filter.remove(filterProps.field)}
              onClick={() => {
                if (setFilterModal) {
                  setFilterModal({
                    visible: true,
                    ...filterProps,
                  });
                }
              }}
            >
              {tagText}
            </Tag>
          </Tooltip>
        );
      })}
    </span>
  );
};

const Search = ({ searchBarVisible }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const history = useHistory();
  const [filterSelectModalVisible, setFilterSelectModalVisible] =
    useState(false);
  const [filterModal, setFilterModal] = useState({
    displayName: null,
    visible: false,
    query: null,
    field: null,
    typeDef: null,
    tag: null,
    renderComponent: null,
    options: null,
  });

  const { searchFilter } = useParams();

  const [filter] = useState(
    new BatchClaimSearchFilter(searchFilter ? JSON.parse(searchFilter) : null)
  );

  return (
    <div
      css={{
        display: searchBarVisible ? 'block' : 'none',
      }}
    >
      <Layout.Header
        id="app_search_header"
        css={css({
          backgroundColor: colorBgContainer,
          borderBottom: '1px #f0f0f0 solid',
        })}
      >
        <Button
          data-cy="search-filter-run-search"
          size={'small'}
          disabled={filter.filterMap.size === 0}
          onClick={() => history.push('/search/' + filter.searchUrl)}
        >
          Search
        </Button>
        {filter && (
          <FilterTags filter={filter} setFilterModal={setFilterModal} />
        )}

        <Button
          size={'small'}
          type="dashed"
          data-cy="search-filter-open-add-filter-modal"
          onClick={() => setFilterSelectModalVisible(true)}
        >
          + Filter
        </Button>
      </Layout.Header>
      <Modal
        open={filterSelectModalVisible}
        onCancel={() => setFilterSelectModalVisible(false)}
        onOk={() => setFilterSelectModalVisible(false)}
        okButtonProps={{ 'data-cy': 'search-filter-select-field-modal-btn-ok' }}
        cancelButtonProps={{
          'data-cy': 'search-filter-select-field-modal-modal-btn-ok',
        }}
      >
        <SearchFieldsMenu
          setFilterSelectModalVisible={setFilterSelectModalVisible}
          setFilterModal={setFilterModal}
        />
      </Modal>
      <SearchFieldModal
        setFilterSelectModalVisible={setFilterSelectModalVisible}
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        filter={filter}
      />
    </div>
  );
};

export { Search, FilterTags };
