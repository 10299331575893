import {
  MinusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

// WE ARE KEEPING FINDINGS EXEMPT FROM EVENT HISTORY TIMELINE (this may change one day...)
const findings = {
  auditProgress: {
    stateTypeLabel: 'Findings',
    defaultLabel: 'No Findings yet',
    tooltipLabel: 'Status of audit findings within claim(s)',

    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that queries the auditFindng table filtered on a $batchClaimFilter and counts
     *     the number of findings for each queryable state definition below
     */
    buildProgressSubQuery: ({ permissions }) => {
      const query = `
        findings_FINDINGS_TO_REVIEW: auditFindings(
          filter: {
            and: [{ batchClaim: $batchClaimFilter }, { isActive: { equalTo: true } }]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_SUPERSEDED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { superseded: { equalTo: true } }
            ]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_REVIEWED_DECLINED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { accepted: { equalTo: false } }
              {
                or: [
                  { reviewedAuditFindingsExist: true }
                  { auditFindingReviewsByOriginalIdExist: true }
                ]
              }
            ]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_REVIEWED_ACCEPTED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { accepted: { equalTo: true } }
              {
                or: [
                  { reviewedAuditFindingsExist: true }
                  { auditFindingReviewsByOriginalIdExist: true }
                ]
              }
            ]
          }
        ) {
          totalCount
        }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e FINDINGS_TO_REVIEW): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: { totalClaims, ...restOfData },
      permissions,
      userType,
      queryableStates,
      states,
    }) => {
      const progressStats = {};
      queryableStates.forEach(stateName => {
        // allowed to show this state for this user
        if (permissions.claimStatesToShow.findings.has(stateName)) {
          progressStats[stateName] = {
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            description: states[stateName].description(userType),
          };
          progressStats[stateName].totalCount =
            restOfData[`findings_${stateName}`].totalCount;
        }
      });
      return progressStats;
    },
  },
  queryableStates: [
    'FINDINGS_TO_REVIEW',
    'FINDINGS_SUPERSEDED',
    'FINDINGS_REVIEWED_DECLINED',
    'FINDINGS_REVIEWED_ACCEPTED',
  ],
  defaultStates: ['FINDINGS_TO_REVIEW'],
  dataType: 'enum',
  onTableQuery: 'auditFindings',
  states: {
    FINDINGS_TO_REVIEW: {
      name: userType => {
        return 'To Review';
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Findings that require review';
      },
      timelineEventRendering: {
        renderingAllowed: event => false,
      },
    },
    FINDINGS_SUPERSEDED: {
      name: userType => {
        return 'Superseded';
      },
      primaryColor: '#faad14',
      strokeColor: 'white',
      icon: (
        <MinusCircleOutlined style={{ color: '#faad14', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Findings that have been superseded';
      },
      timelineEventRendering: {
        description: userType => 'Finding was Superseded',
        renderingAllowed: event => false,
      },
    },
    FINDINGS_REVIEWED_DECLINED: {
      name: userType => {
        return 'Reviewed & Declined';
      },
      primaryColor: '#fa541c',
      strokeColor: 'white',
      icon: (
        <CloseCircleOutlined style={{ color: '#fa541c', fontSize: '120%' }} />
      ),
      description: userType => {
        return 'Findings that have been Reviewed & Declined';
      },
      timelineEventRendering: {
        description: userType => 'Finding was Reviewed & Declined',
        renderingAllowed: event => false,
      },
    },
    FINDINGS_REVIEWED_ACCEPTED: {
      name: userType => {
        return 'Reviewed & Accepted';
      },
      primaryColor: '#52c41a',
      strokeColor: 'white',
      icon: (
        <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '120%' }} />
      ),
      description: userType => {
        return 'Findings that have been Reviewed & Accepted';
      },
      timelineEventRendering: {
        description: userType => 'Finding was Reviewed & Accepted',
        renderingAllowed: event => false,
      },
    },
  },
  filterBuilder: values => {
    /* 
      POSSIBLE VALUES = [FINDINGS_TO_REVIEW, FINDINGS_SUPERSEDED,  FINDINGS_REVIEWED_DECLINED,  FINDINGS_REVIEWED_ACCEPTED]

      filter required for each value:
      FINDINGS_TO_REVIEW
        - isActive: { equalTo: true }
          -- and --
        - reviewedAuditFindingsExist: false
          -- and --
        - auditFindingReviewsByOriginalIdExist: false
          -- and --
        - superseded: { equalTo: false }

      FINDINGS_SUPERSEDED
        - isActive: { equalTo: true }
          -- and --
        - superseded: { equalTo: true }

      FINDINGS_REVIEWED_DECLINED
        - isActive: { equalTo: true }
          -- and --
        - accepted: { equalTo: false },          
          -- and --
        - superseded: { equalTo: false }
          -- and --
        - or: [
            { reviewedAuditFindingsExist: true },
            { auditFindingReviewsByOriginalIdExist: true },
          ],
      
      FINDINGS_REVIEWED_ACCEPTED
        - isActive: { equalTo: true }
          -- and --
        - accepted: { equalTo: true },          
          -- and --
        - superseded: { equalTo: false }
          -- and --
        - or: [
            { reviewedAuditFindingsExist: true },
            { auditFindingReviewsByOriginalIdExist: true },
          ],
    */

    const filter = {
      isActive: { equalTo: true }, // all findings need this
    };

    /* 
      if 'FINDINGS_SUPERSEDED' is the only value, then we need to filter on 'superseded'. Otherwise we dont have to
      this is because all values require 'superseded': false except for 'FINDINGS_SUPERSEDED'
      thus if values includes 'FINDINGS_SUPERSEDED' and something else, that means we want ANY claim with 'superseded': true OR false
    */
    if (values.includes('FINDINGS_SUPERSEDED') && values.length === 1)
      filter['superseded'] = { equalTo: true };

    /**
      if 'FINDINGS_REVIEWED_ACCEPTED' or 'FINDINGS_REVIEWED_DECLINED' are in values that means we want claims that have been reviewed
      However if values has bothe 'ACCEPTED' & 'DECLINED' that means we don't filter on accpetance

     */
    if (
      values.includes('FINDINGS_REVIEWED_ACCEPTED') ||
      values.includes('FINDINGS_REVIEWED_DECLINED')
    ) {
      if (
        !(
          values.includes('FINDINGS_REVIEWED_ACCEPTED') &&
          values.includes('FINDINGS_REVIEWED_DECLINED')
        ) // if both 'ACCEPTED' and 'DECLINED' are NOT in value
      ) {
        filter['accepted'] = {
          equalTo: values.includes('FINDINGS_REVIEWED_ACCEPTED'),
        };
        filter['or'] = [
          { reviewedAuditFindingsExist: true },
          { auditFindingReviewsByOriginalIdExist: true },
        ];
      } else {
        /* 
          because 'FINDINGS_TO_REVIEW' requires auditFindingReviewsByOriginalIdExist: false && reviewedAuditFindingsExist: false 
          and 'FINDINGS_REVIEWED' allows auditFindingReviewsByOriginalIdExist: trye || reviewedAuditFindingsExist: true
          that means if both a 'FINDINGS_REVIEWED' is in values and 'FINDINGS_TO_REVIEW' is in value we return 
            auditFindingReviewsByOriginalIdExist: false || reviewedAuditFindingsExist: false
          but otherwise return 
            auditFindingReviewsByOriginalIdExist: false && reviewedAuditFindingsExist: false 
        */
        if (values.includes('FINDINGS_TO_REVIEW')) {
          filter['reviewedAuditFindingsExist'] = false;
          filter['auditFindingReviewsByOriginalIdExist'] = false;
        }
      }
    }
    return filter;
  },
};

export { findings };
