import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DrgHeader, DrgHeaderData } from './drgHeader';
import { DrgDataType, DrgForm } from './drgForm';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { DrgContent } from './drgContent';
import { useMeasure, useResizeObserver } from '@react-hookz/web';
import { UserContext } from '../../../context/user';

const originalData: DrgDataType = {
  drgDxs: [
    {
      code: 'A419',
      description: 'SEPSIS, UNSPECIFIED ORGANISM',
      presentOnAdmission: 'N',
    },
    {
      code: 'U071',
      description: 'COVID-19',
      presentOnAdmission: 'Y',
    },
    {
      code: 'J80',
      description: 'ACUTE RESPIRATORY DISTRESS SYNDROM',
      presentOnAdmission: 'Y',
    },
  ],

  procedures: [
    {
      code: '5A1955Z',
      description: 'RESPIRATORY VENTILATION, GREATER THAN 96 CONSECUTIVE HOURS',
    },
  ],
  overallDrg: {
    code: '870',
    description: 'SEPTICEMIA OR SEVERE SEPSIS WITH MV >96 HOURS',
  },

  notes: {},
};

export const DrgReviewDataDefault: DrgDataType = {
  drgDxs: [
    {
      code: 'U071',
      description: 'COVID-19',
      presentOnAdmission: 'Y',
    },
    {
      code: 'A419',
      description: 'SEPSIS, UNSPECIFIED ORGANISM',
      presentOnAdmission: 'N',
    },
    {
      code: 'J80',
      description: 'ACUTE RESPIRATORY DISTRESS SYNDROM',
      presentOnAdmission: 'Y',
    },
  ],

  procedures: [
    {
      code: '5A1955Z',
      description: 'RESPIRATORY VENTILATION, GREATER THAN 96 CONSECUTIVE HOURS',
    },
  ],
  overallDrg: {
    code: '207',
    description:
      'RESPIRATORY SYSTEM DIAGNOSIS WITH VENTILATOR SUPPORT >96 HOURS',
  },

  notes: {
    // drgPrimaryNotes:
    //   'this is a better primary dx since there is a ton of justification for doing it this way see all this documentation',
  },
};

export type DrgTabProps = {
  batchClaim: any;
  askAutodorOpen: boolean;
  renderViewDocumentsButton: () => React.ReactElement;
  drgReviewData: DrgDataType;
  setDrgReviewData: (drgData: DrgDataType) => void;
};
export const DrgTab: React.FC<DrgTabProps> = ({
  batchClaim,
  askAutodorOpen,
  renderViewDocumentsButton,
  drgReviewData: reviewData,
  setDrgReviewData: setReviewData,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // const {
  //   claimType,
  //   dateOfServiceStart,
  //   dateOfServiceEnd,
  //   provider,
  //   amountCharged,
  //   amountReimbursed,
  // } = batchClaim;

  const headerData: DrgHeaderData = {
    claimType: 'Inpatient',
    dateOfServiceStart: '2021-01-18',
    dateOfServiceEnd: '2021-03-05',
    providerName: 'Alaffia Hospital',
    providerLocation: 'NY, NY',
    amountCharged: 211_603.93,
    amountReimbursed: 90_850.16,
    patientAge: 54,
    patientGender: 'Male',
    adjustedAllowedAmount: 80_974.41,
    savings: 9875.75,
  };

  // const dataSrc = batchClaim.batchClaimLines.nodes[0];
  // const [reviewData, setReviewData] = useState<DrgDataType>(DrgReviewDataDefault);

  const saveReviewData = useCallback(
    (dataToSave: DrgDataType) => {
      setReviewData({ ...dataToSave });
      return true;
    },
    [reviewData]
  );
  const user: any = React.useContext(UserContext);

  const {
    batchClaimState: { workflowClaimState },
    auditDocumentation: files,
    ubClaimFindings,
  } = batchClaim || {
    auditDocumentation: [],
  };

  const renderDocs = () => {
    // claim has files
    return (files.length > 0 &&
      // user can view docs or doc names
      user.permissions.viewDocs) ||
      user.permissions.viewDocList ? (
      renderViewDocumentsButton()
    ) : (
      <></>
    );
  };

  return (
    <div
      ref={ref}
      tw="_pr-2 _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer _overflow-auto _h-full"
    >
      <DrgHeader
        headerData={headerData}
        renderViewDocumentsButton={renderDocs}
      ></DrgHeader>
      <DrgContent
        drgData={reviewData}
        drgPriorVersionData={originalData}
        askAutodorOpen={askAutodorOpen}
        saveReviewData={saveReviewData}
      ></DrgContent>
    </div>
  );
};
