import { useState, useEffect } from 'react';
import {
  Descriptions,
  Space,
  Empty,
  Statistic,
  message,
  Typography,
} from 'antd';
import { CalendarTwoTone } from '@ant-design/icons';
import { auditFindingMetaDataFromRuleTypes } from './util.js';
import { dateString } from '../../util';
import tw from 'twin.macro';

const { Text } = Typography;

const renderMetaDataPanel = ({
  metaData,
  auditFinding,
  style,
  bcli,
  bcliData,
}) => (
  <>
    {metaData.length || bcli ? (
      <Space direction="vertical" style={style}>
        {bcli ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space
              style={{ justifyContent: 'space-between', width: '100%' }}
              align="start"
            >
              <Statistic
                title="Billed Amount"
                data-cy="audit-finding-meta-data-description-unitCharged"
                prefix="$"
                value={bcli.unitCharge}
                precision={2}
              />
              <Space>
                <CalendarTwoTone twoToneColor="red" />
                <div data-cy="audit-finding-meta-data-description-dateOfService">
                  {dateString(bcli.dateOfServiceStart, bcli.dateOfServiceEnd)}
                </div>
              </Space>
            </Space>
            <Statistic
              title="Quantity"
              data-cy="audit-finding-meta-data-description-quantity"
              value={bcli.units}
            />
            <Descriptions title="" size="small" column={1}>
              {bcliData
                .filter(([bcliDataKey]) => bcli[bcliDataKey] !== null)
                .map(([bcliDataKey, { label, formatter = value => value }]) => (
                  <Descriptions.Item label={label} key={bcliDataKey}>
                    {formatter(bcli[bcliDataKey])}
                  </Descriptions.Item>
                ))}
            </Descriptions>
            <Text type="secondary">Description:</Text>
            <span
              tw="_whitespace-normal"
              data-cy="audit-finding-meta-data-description-unitCharged"
            >
              {bcli.description}
            </span>
          </Space>
        ) : (
          <div />
        )}
        {metaData.length > 0 && (
          <>
            <div className="audit_finding_comment_title">Finding Details</div>
            <Descriptions title="" size="small" column={1}>
              {metaData.map(
                ([metaDataKey, { label, formatter = value => value }]) => (
                  <Descriptions.Item label={label} key={metaDataKey}>
                    {formatter(auditFinding[metaDataKey])}
                  </Descriptions.Item>
                )
              )}
            </Descriptions>
          </>
        )}
      </Space>
    ) : (
      <Empty description="no additional information" />
    )}
  </>
);

// DOC - (MV) a/o US184 (8/2022) we're moving to standard form for most denial codes
// so removed the error handler/effect on missing meta-data fields.  The error
// handling was a great example of managing missing UI config for data responses
// and was removed after commit de102719 if needed.

const AuditFindingMetaDataDescription = props => {
  const { auditFinding, style } = props;
  const { auditFindingRuleType } = auditFinding;
  const { batchClaimLineItemAudit } = auditFinding;
  const bcli = batchClaimLineItemAudit?.batchClaimLineItem;

  const bcliData = Object.entries({
    procedureCode: { label: 'CPT/HCPCS Code' },
    itemNumber: { label: 'Item Number' },
  });

  // DOC - (MV) we store the configuration for rendering some meta-data fields in
  // auditFindingMetaDataFromRuleTypes, but most types listed there support
  // historical data; if there's no metadata for a type it's just a standard version
  // so there's no longer a need for error handling if the UI doesn't have a type
  return (
    <div data-cy="audit-finding-meta-data-description-content">
      {
        //  If it's the first load and the metaData object exists, then we render the metaDataPanel
        renderMetaDataPanel({
          metaData: Object.entries(
            auditFindingMetaDataFromRuleTypes(auditFindingRuleType) || {}
          ),
          auditFinding,
          style,
          bcli,
          bcliData,
        })
      }
    </div>
  );
};

export { AuditFindingMetaDataDescription };
