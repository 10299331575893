import { graphql } from '../../../../gql';
import { useQuery } from 'urql';
import { FragmentType, useFragment } from '../../../../gql/fragment-masking';
import { claimQueryDoc, providerClaimQueryDoc } from './claim.gql';
import { useContext } from 'react';
import { UserContext } from '../../../context/user';

export const useClaim = (id: string) => {
  const { userType }: any = useContext(UserContext);
  const useQueryResult = useQuery({
    query: userType === 'PROVIDER' ? providerClaimQueryDoc : claimQueryDoc,
    variables: {
      id,
    },
    // requestPolicy: 'network-only',
  });

  return useQueryResult;
};
