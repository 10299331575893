import React, { useContext, useState } from 'react';
import { Menu, Button, Badge, Tooltip } from 'antd';
import tw, { css } from 'twin.macro';
import {
  UserOutlined,
  LogoutOutlined,
  HomeOutlined,
  FolderOutlined,
  FileDoneOutlined,
  AuditOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { UserContext } from '../context/user';

import { Link } from 'react-router-dom';
import { UploadClaimModal } from '../uploadClaims/uploadClaimModal';
import { VersionInfo } from './version.ver';
import { AlaffiaClientContext } from '../../alaffiaClient/alaffiaClientProvider';

const Permissioned = ({ components, permissions }) => {
  return components
    .filter(([component]) => {
      // console.log('perm', component.name);
      return permissions.navigation.includes(component.name);
    })
    .map(([component, props]) => component(props));
};

const Queues = () => (
  <Menu.Item
    id="navigationQueues"
    key="NavigationQueues"
    icon={<AuditOutlined />}
  >
    <Link to={`/queues`}>Queues</Link>
  </Menu.Item>
);

const Reports = () => (
  <Menu.Item
    key="NavigationReports"
    id="navigationReportsMenuItem"
    icon={<ReconciliationOutlined />}
  >
    <Link to={'/reports'}>Reports</Link>
  </Menu.Item>
);

const CreateAuditOrReport = ({ setCreatingReport }) => (
  <>
    <Menu.Item
      id="navigationCreateReportMenuItem"
      key="NavigationReportsCreate"
    >
      <Button
        icon={<FileDoneOutlined />}
        type="dashed"
        style={{ width: '100%' }}
        onClick={() => setCreatingReport(true)}
      >
        Create Report
      </Button>
    </Menu.Item>
  </>
);

const AuditsTab = ({ setCreatingReport, permissions }) => {
  return (
    <Menu.SubMenu
      id="navigationAuditsMenu"
      key="NavigationAuditsMenu"
      icon={<FolderOutlined />}
    >
      {Permissioned({
        components: [
          [Queues],
          [Reports],
          // FIXME this component causes a missing unique key warning in the Navigation component
          // [CreateAuditOrReport, { setCreatingAudit, setCreatingReport }],
        ],
        permissions,
      })}
    </Menu.SubMenu>
  );
};

const SearchBarToggle = ({ searchBarVisible, setSearchBarVisible }) => (
  <Menu.Item
    id="searchBarToggle"
    key="searchBarToggle"
    icon={<SearchOutlined />}
    onClick={() => {
      setSearchBarVisible(!searchBarVisible);
    }}
    selectable={'false'}
  >
    Search Claims
  </Menu.Item>
);

const HomeTab = ({ homeRoute }) => (
  <Menu.Item
    id="navigationDashboard"
    key="NavigationDashboard"
    icon={<HomeOutlined />}
  >
    <Link to={homeRoute}>Home</Link>
  </Menu.Item>
);

const ManageUsersTab = () => (
  <Menu.Item
    id="navigationUserManagement"
    key="NavigationUserManagement"
    icon={<TeamOutlined />}
  >
    <Link to="/users">Manage Users</Link>
  </Menu.Item>
);

const UserProfile = () => (
  <Menu.Item id="navigationProfileMenuItem" key="NavigationUserProfile">
    <Link to="/user/profile">Profile</Link>
  </Menu.Item>
);

const UserLogout = () => {
  const alaffiaClient = useContext(AlaffiaClientContext);
  return (
    <Menu.Item id="navigationLogoutMenuItem" key="NavigationUserLogout">
      <Button
        icon={<LogoutOutlined />}
        style={{ width: '100%', color: 'red' }}
        onClick={() => {
          alaffiaClient?.logout();
          window.location.reload();
        }}
      >
        Sign out
        {/*<Link to="/">Logout</Link>*/}
      </Button>
    </Menu.Item>
  );
};

const UserTab = ({ permissions }) => {
  return (
    <Menu.SubMenu
      id="navigationUserMenu"
      key="NavigationUser"
      icon={<UserOutlined />}
      title="User"
    >
      {Permissioned({
        components: [[UserProfile], [UserLogout]],
        permissions,
      })}
    </Menu.SubMenu>
  );
};

const UploadClaims = ({ setUploadClaimModalOpen, uploadClaimPending }) => {
  return (
    <Menu.Item
      id="navigationUploadClaims"
      key="NavigationUploadClaims"
      onClick={() => setUploadClaimModalOpen(true)}
      icon={
        <Badge
          size="small"
          dot={true}
          offset={[4, 12]}
          count={uploadClaimPending}
        >
          <UploadOutlined />
        </Badge>
      }
      title="Upload Claims"
    ></Menu.Item>
  );
};

const Navigation = props => {
  const { setCreatingReport, searchBarVisible, setSearchBarVisible } = props;
  const { permissions, claimIntakeEnabled } = React.useContext(UserContext);
  const [uploadClaimModalOpen, setUploadClaimModalOpen] = useState(false);
  const [uploadClaimPending, setUploadClaimPending] = useState(0);

  // TODO auto highlight Menu item based on router url
  return (
    <>
      <div tw="_h-full _max-h-full _flex _flex-col">
        <div tw="_grow">
          <Menu
            id="app_navigation"
            key="app_navigation"
            theme="light"
            mode="inline"
            css={css`
              height: 100%;
            `}
          >
            {/* <Menu.Item key="logo">
        <div className="logo" css={{ paddingBottom: 100 }} />
      </Menu.Item> */}
            {/**
             * This approach instead of vanilla JSX is needed to workaround
             * this note from antd's documentation on the <Menu/>
             * component:
             *    Your customized node should be wrapped by Menu.Item.
             *    Menu needs to collect its node structure, so its children should be Menu.* or encapsulated HOCs
             * */}
            {Permissioned({
              components: [
                [SearchBarToggle, { searchBarVisible, setSearchBarVisible }],
                [HomeTab, { homeRoute: permissions.homeRoute }],
                [AuditsTab, { setCreatingReport, permissions }],
                [ManageUsersTab],
                [UserTab, { permissions }],
                ...(claimIntakeEnabled
                  ? [
                      [
                        UploadClaims,
                        {
                          uploadClaimModalOpen,
                          setUploadClaimModalOpen,
                          setUploadClaimPending,
                          uploadClaimPending,
                        },
                      ],
                    ]
                  : []),
              ],
              permissions,
            })}
          </Menu>
        </div>
        <div
          css={css({
            ...tw` _text-xs _flex _items-center _justify-center _w-full _py-4
            _bg-antd-lt-colorBgContainer dark:(_bg-antd-dk-colorBgContainer)`,
            '& div.app-version-label':
              VersionInfo.gitDirty === 'D'
                ? tw`_text-antd-lt-colorErrorActive`
                : VersionInfo.gitTagDistance !== '0'
                  ? tw`_text-antd-lt-colorWarning`
                  : tw`_text-antd-lt-colorTextSecondary dark:(_text-antd-dk-colorTextSecondary)`,
          })}
        >
          {VersionInfo.gitTagFull.startsWith('%%') ? (
            <div>{/* app version placeholder */}</div>
          ) : (
            <Tooltip
              title={
                <div>
                  <div>Alaffia App v{VersionInfo.gitTag}</div>
                  {(VersionInfo.gitTagDistance ||
                    VersionInfo.gitDirty === 'D') && (
                    <div tw="_text-[0.75em] _font-light">
                      <div>{VersionInfo.gitTagFull}</div>
                    </div>
                  )}
                </div>
              }
            >
              <div className="app-version-label">v{VersionInfo.gitTag}</div>
            </Tooltip>
          )}
        </div>
      </div>
      <UploadClaimModal
        {...{
          open: uploadClaimModalOpen,
          setOpen: setUploadClaimModalOpen,
          setUploadClaimPending,
        }}
      />
    </>
  );
};

export {
  Navigation,
  HomeTab,
  AuditsTab,
  SearchBarToggle,
  Queues,
  Reports,
  CreateAuditOrReport,
  ManageUsersTab,
  UserTab,
  UserProfile,
  UserLogout,
  UploadClaims,
};
