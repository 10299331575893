import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import tw from 'twin.macro';
import { Empty } from 'antd';

export const NoDataOverlay = (
  props: ICellRendererParams & { noDataMessage: string }
) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span tw="_text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary">
          {props.noDataMessage}
        </span>
      }
    />
  );
};
