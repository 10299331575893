import {
  EditFindingAdjustments,
  EditFindingValues,
  FormSetFieldsFn,
} from '../viewController/types';
import { useEffect, useRef } from 'react';
import { get, set } from 'lodash';

const getDefaults = (
  isRepricing: boolean,
  adjustments?: EditFindingAdjustments
) => {
  return isRepricing
    ? {
        metadata: {
          amountChargedOverride: get(
            adjustments,
            'ibLineAdjustments.0.amountCharged',
            0
          ),
          improperPaymentCostOverride: get(
            adjustments,
            'ibLineAdjustments.0.improperPaymentCost',
            0
          ),
        },
      }
    : {
        metadata: {
          amountChargedOverride: undefined,
          improperPaymentCostOverride: undefined,
        },
      };
};

export type useIbFindingRepricingProps = {
  adjustments?: EditFindingAdjustments;
  defaultValues: EditFindingValues;
  setFields: FormSetFieldsFn<EditFindingValues>;
};

export const useIbFindingRepricing = ({
  adjustments,
  defaultValues,
  setFields,
}: useIbFindingRepricingProps) => {
  //
  // TODO this hook feels overcomplicated and maybe unnecessary...
  //  there's a lot of interpretation, inference here that is partly force by
  //  repricing being a bit of a hack in the data model... however, interpreting
  //  the data model should be on dialog init and part of default values, and
  //  if we can model the Adjustments object better and make sure it has all the
  //  data points it needs to handle adjustments, hopefully this code can simply
  //  handle the field defaults / reset on enabling/disabling isRepricing
  //  (the only reason that's not a top-level/controller concern is that it touches
  //  the particular form implementation -- e.g. inline v modal, antd wiring)

  const isInitialLoad = useRef(true);

  const lastAdjustments = useRef<EditFindingAdjustments | undefined>(
    adjustments
  );

  useEffect(() => {
    if (
      adjustments &&
      adjustments.isRepricing !== lastAdjustments.current?.isRepricing
    ) {
      // handle toggle of repricing by setting fields to defaults or undefined
      if (isInitialLoad.current) {
        isInitialLoad.current = false; // let the defaults remain but only the first time...
      } else {
        const defaults = getDefaults(adjustments.isRepricing, adjustments);
        setFields(defaults, true);
      }
    } else if (adjustments?.isRepricing) {
      // we can update the ipc field if it's not been changed by user
      // essentially, if it matches the calculated value for the amountChargedOverride,
      // it should continue to be updated to keep current with changes to the amountChargedOverride
      const sameIpcLastTime =
        lastAdjustments.current?.improperPaymentCostOverride ===
        adjustments?.improperPaymentCostOverride;
      const diffChargedAmountThisTime =
        lastAdjustments.current?.amountChargedOverride !==
        adjustments?.amountChargedOverride;
      const lineIpcMatchesTopLevelOverride =
        lastAdjustments.current?.improperPaymentCostOverride ===
        lastAdjustments.current?.ibLineAdjustments![0].improperPaymentCost;

      if (!adjustments?.ibLineAdjustments![0].overrides?.isIPCOverride) {
        setFields(
          set(
            {},
            'metadata.improperPaymentCostOverride',
            adjustments?.ibLineAdjustments![0].improperPaymentCost
          )
        );
      }
    }
    lastAdjustments.current = adjustments;
  }, [adjustments, setFields]);
};
