import { batchClaimState } from '../../../fragments';
import { gql, useMutation } from 'urql';
import { Menu, message } from 'antd';
import { batchClaimsNodeKey, claimMenuItemDetails } from '../util';

// mutation to update workflow workflowState
const setWorkflowStateMutation = gql`
  mutation workflowStateMutation(
    $batchClaimIds: [UUID!]!
    $workflowState: WorkflowClaimStateEnum!
  ) {
    setWorkflowClaimStates(
      input: { workflowState: $workflowState, batchClaimIds: $batchClaimIds }
    ) {
      workflowClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

// workflow actions
const typeActions = {
  setToDo: {
    title: 'To Do',
    state: 'TO_DO',
    actionCriteria: ({ batchClaimState: { workflowClaimState, reported } }) => {
      // any unreported claim thats IN_PROGRESS or CLAIM_RECEIVED is valid
      const satisfied =
        !reported &&
        ['IN_PROGRESS', 'CLAIM_RECEIVED'].includes(workflowClaimState);
      return { satisfied };
    },
  },
  setInProgress: {
    title: 'In Progress',
    state: 'IN_PROGRESS',
    actionCriteria: ({ batchClaimState: { workflowClaimState } }) => {
      // when closed, completed, in to we can set to in progress
      const satisfied = [
        'CLAIM_RECEIVED',
        'TO_DO',
        'COMPLETED',
        'CLOSED',
      ].includes(workflowClaimState);

      return { satisfied };
    },
  },
  setComplete: {
    title: 'Complete',
    state: 'COMPLETED',
    actionCriteria: ({ batchClaimState: { workflowClaimState } }) => {
      // an unreported claim in progress
      const satisfied = ['IN_PROGRESS'].includes(workflowClaimState);
      return { satisfied };
    },
  },
  setClosed: {
    title: 'Closed',
    state: 'CLOSED',
    actionCriteria: ({ batchClaimState: { workflowClaimState } }) => {
      // any open claim can be closed whenever
      const satisfied = workflowClaimState !== 'CLOSED';
      return { satisfied };
    },
  },
};

const workflowMenuItem = ({
  action,
  title,
  state,
  batchClaims,
  actionCriteria,
  setWorkflowState,
  setLoading,
}) => {
  // find the claims for which the action criteria is satisfied
  const actionClaims = batchClaims.filter(i => actionCriteria(i).satisfied);
  return (
    <Menu.Item
      key={`workflow-menuitem-${action}-${batchClaimsNodeKey(batchClaims)}`}
      disabled={
        // disabled if no claims satisfy the action criteria
        !actionClaims.length
      }
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setWorkflowState({
          workflowState: state,
          batchClaimIds: actionClaims.map(({ id }) => id),
        });
        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          message.error(errorMessage);
        } else {
          message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }) => {
  const [{ fetching, error }, setWorkflowState] = useMutation(
    setWorkflowStateMutation
  );

  return (
    <Menu.SubMenu
      key={`workflow-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Workflow"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions['workflow'].includes(action)
        )
        .map(([action, { ...actionProps }]) => {
          return workflowMenuItem({
            action,
            batchClaims,
            setWorkflowState,
            setLoading,
            ...actionProps,
          });
        })}
    </Menu.SubMenu>
  );
};

const workflow = {
  title: 'Workflow State',
  subMenu: actionSubMenu,
};

export { workflow, setWorkflowStateMutation };
