import { DoubleRightOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  List,
  Skeleton,
  Spin,
  Tooltip,
  Space,
  Rate,
} from 'antd';
import {
  UiAskAutodorFileResponse,
  UiAskAutodorFileResponseError,
  UiAskAutodorResponse,
} from './types';
import { AskAutodorResultTable } from './askAutodorResultTable';
import { useAskAutodor } from './useAskAutodor';
import React, { useState, useEffect } from 'react';
import { AskAutodorPromptTemplateSelector } from './askAutodorPromptTemplates';
import {
  AskAutodorMatchedText,
  AuditDocumentation,
} from '../../../gql/graphql';
import { nonNullArr } from '../util/array';
import { useAskAutodorRating } from './useAskAutodorRating';
import { AskAutodorFileChooser } from './askAutodorFileChooser';
import { AskAutodorForm } from './askAutodorForm';
import tw, { css } from 'twin.macro';

export type GptResponseItemType = {
  responseItem: UiAskAutodorFileResponse | UiAskAutodorFileResponseError;
  documents: AuditDocumentation[];
  claimId: string;
  setDocumentationViewerProps: Function;
};

export const GptResponseItem = ({
  responseItem,
  documents,
  claimId,
  setDocumentationViewerProps,
}: GptResponseItemType) => {
  const [responseRating, setResponseRating] = useState<number | undefined>(
    undefined
  );

  const [{ data, fetching, error }, _setRating] = useAskAutodorRating();

  const setRating = (rating: number) => {
    setResponseRating(rating);
    _setRating({
      input: { responseId: responseItem.responseId, rating },
    });
  };

  useEffect(() => {
    if (!!error) {
      console.error(
        `Error setting Ask Autodor response rating for responseId: ${responseItem.responseId} (rating value: ${responseRating})`,
        error
      );
    }
  }, [error]);

  return (
    <>
      {responseItem.error && (
        <div tw="_pb-2 _text-antd-lt-colorErrorText dark:_text-antd-dk-colorErrorText">
          {responseItem.errorMessage}
        </div>
      )}

      {!responseItem.error && (
        <>
          <div>
            <div tw="_flex _items-center">
              <div tw="_text-xs _font-light _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary">
                {responseItem?.pagesShown === responseItem?.pagesTotal
                  ? `${responseItem?.pagesShown} results`
                  : `Showing top ${responseItem?.pagesShown} of ${responseItem?.pagesTotal} results`}
              </div>
              <div tw="_ml-auto">
                <Rate
                  style={{ color: 'gold', fontSize: 16 }}
                  disabled={fetching}
                  value={responseRating}
                  onChange={(rating: number) => setRating(rating)}
                />
              </div>
            </div>
            <div tw="_pb-2  _text-antd-lt-colorSuccessText dark:_text-antd-dk-colorSuccessText">
              {responseItem.response}
            </div>
          </div>
          <AskAutodorResultTable
            setDocumentationViewerProps={setDocumentationViewerProps}
            documents={documents}
            matches={nonNullArr<AskAutodorMatchedText>(responseItem?.pages)}
            claimId={claimId}
          ></AskAutodorResultTable>
        </>
      )}
    </>
  );
};

export type AskAutodorProps = {
  documents: AuditDocumentation[];
  claimId: string;
  setDocumentationViewerProps: Function;
};

export const AskAutodor = ({
  documents,
  claimId,
  setDocumentationViewerProps,
}: AskAutodorProps) => {
  const [document, setDocument] = useState<AuditDocumentation | null>(
    documents[0] ?? null
  );
  const [responses, setResponses] = useState<UiAskAutodorResponse[]>([]);
  useEffect(() => {
    if (!document && documents.length > 0) {
      setDocument(documents[0]);
    }
  }, [documents]);

  return (
    <div>
      <div>
        <AskAutodorForm
          document={document}
          setDocument={setDocument}
          documents={documents}
          responses={responses}
          setResponses={setResponses}
        ></AskAutodorForm>
      </div>

      {responses?.length > 0 && (
        <div>
          <List
            itemLayout="horizontal"
            dataSource={responses}
            renderItem={({ key, loading, response }, index) => (
              <List.Item key={key}>
                <List.Item.Meta
                  avatar={
                    loading ? <Spin size="small" /> : <DoubleRightOutlined />
                  }
                  title={
                    <div tw="_flex">
                      <div>{response?.prompt ?? ''}</div>
                    </div>
                  }
                  description={
                    <Skeleton active loading={loading}>
                      <GptResponseItem
                        responseItem={response!}
                        documents={documents}
                        claimId={claimId}
                        setDocumentationViewerProps={
                          setDocumentationViewerProps
                        }
                      ></GptResponseItem>
                    </Skeleton>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};
