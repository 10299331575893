import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { Row, Col, Spin, Tooltip, Drawer, Button, message } from 'antd';
import { BatchClaimOverviewTriggerButton } from '../batchClaimOverview/batchClaimOverviewTriggerButton';
import { ClaimActionMenu } from '../../claimActionMenu/claimActionMenu';
import { UserContext } from '../../context/user';
import { BatchClaimOverviewDrawer } from '../batchClaimOverview/batchClaimOverviewDrawer';
import { EnumClaimStates } from '../../claimState/enumClaimStates';
import { CopyHover } from '../../../common/ux/copyHover';
import { AskAutodorDrawer } from '../askAutodor/askAutodorDrawer';
import { AskAutodorLogo } from '../askAutodor/askAutodorLogo';
import { AskAutodorIcon } from '../askAutodor/askAutodorIcon';
import { useAskAutodorFileVectorsExist } from '../askAutodor/useAskAutodorFileVectorsExist';
import { AskAutodorButton } from '../askAutodor/askAutodorButton';

const ClaimWorkspaceHeader = props => {
  const {
    batchClaim,
    batchClaim: { id: batchClaimId, icn },
    askAutodorOpen,
    setAskAutodorOpen,
    setDocumentationViewerProps,
  } = props;
  const [isActionLoading, setIsActionLoading] = React.useState(false); // triggers subsequent refresh, so initially false

  const { id, permissions, userType } = React.useContext(UserContext);

  const [batchClaimOverviewVisible, setBatchClaimOverviewVisible] =
    React.useState(false);
  const showBatchClaimOverviewDrawer = () => {
    setBatchClaimOverviewVisible(true);
  };
  const closeBatchClaimOverviewDrawer = () => {
    setBatchClaimOverviewVisible(false);
  };

  // // todo convert to use claim or a document-specific resolver when available:
  // const aaDocIds = useMemo(() => {
  //   return batchClaim?.auditDocumentation?.map(ad => ad.id);
  // }, [batchClaim]);
  //
  // // TODO
  // console.log(
  //   '%cBC / aaIDs: ids: %O, bc: %O',
  //   'color: orange',
  //   aaDocIds,
  //   batchClaim.auditDocumentation
  // );

  const grid = css({
    display: 'grid',
    // columns:  |- claim no (len<->1fr) -| |-  state tags (6fr) -| |- action btn (len<->1fr) -|
    gridTemplateColumns: `minmax(${
      icn.length + 10 // ICN-len-ch + 10-ch buffer for icon, copy, colon, space, etc.
    }ch, 1fr) 6fr minmax(50px, 1fr)`,
  });

  return !batchClaim ? (
    <Spin />
  ) : (
    <>
      <div
        css={grid}
        tw="_gap-1 _py-0 _m-0  _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer"
        id="auditor-workspace-card-title"
      >
        <div tw="_flex-none _h-9 _flex _items-center">
          <BatchClaimOverviewTriggerButton
            batchClaim={batchClaim}
            onClickFunction={showBatchClaimOverviewDrawer}
            tw="_mr-2"
          />
          <div className="_group" tw="_flex _flex-row _text-sm">
            <div tw="_pr-1 _text-antd-lt-colorTextSecondary dark:_text-antd-dk-colorTextSecondary">
              Claim:
            </div>
            <CopyHover
              textToCopy={icn}
              onCopyComplete={txt => message.success(`Copied '${txt}'`)}
              tw="_font-semibold"
            >
              {icn}
            </CopyHover>
          </div>
        </div>
        <div tw="_flex-auto _flex _items-center _justify-center">
          <EnumClaimStates
            stateTypesToShow={[
              'onHold',
              'workflow',
              'documentation',
              'providerActive',
              'signOff',
              'invoice',
              'negotiation',
              'report',
              'dispute',
            ]}
            batchClaim={batchClaim}
            useIcons={false}
            showNAText={false}
            key={`claim-workspace-header-state-events-row-1-${batchClaim.icn}`}
            id={`claim-workspace-header-state-events-row-1-${batchClaim.icn}`}
          />
        </div>
        <div tw=" _h-9 _justify-self-end">
          <div tw="_flex">
            <div>
              <AskAutodorButton
                documents={batchClaim.auditDocumentation ?? []}
                askAutodorOpen={askAutodorOpen}
                setAskAutodorOpen={setAskAutodorOpen}
                claimId={batchClaimId}
                setDocumentationViewerProps={setDocumentationViewerProps}
              ></AskAutodorButton>
            </div>
            <div>
              <ClaimActionMenu
                batchClaims={[batchClaim]}
                setIsActionLoading={setIsActionLoading}
              />
            </div>
          </div>
        </div>
      </div>

      {/* renders the drawer */}
      <BatchClaimOverviewDrawer
        id="claim-workspace-header-batch-claim-overview-drawer"
        key="claim-workspace-header-batch-claim-overview-drawer"
        onClose={closeBatchClaimOverviewDrawer}
        isVisible={batchClaimOverviewVisible}
        batchClaimId={batchClaim ? batchClaim.id : null}
        setClaimActionMenuIsActionLoading={setIsActionLoading}
        isActionLoading={isActionLoading}
      />
      {/*<AskAutodorDrawer*/}
      {/*  claimId={batchClaimId}*/}
      {/*  open={askAutodorOpen}*/}
      {/*  setOpen={setAskAutodorOpen}*/}
      {/*  setDocumentationViewerProps={setDocumentationViewerProps}*/}
      {/*></AskAutodorDrawer>*/}
    </>
  );
};

export { ClaimWorkspaceHeader };
