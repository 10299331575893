//@preval

/*
  ** antd / tailwind colors file **
  * This file contains all the colors / theme settings dumped from AntD using the v5 theme object
  * Tailwind imports these via tailwind.config.js and exposes them back to antd
  * This allows us to modify any color in the theme and use it consistenly in either tailwind or antd
  *
  * Initially tried to set this up with preval/preval macro but it didn't prevent building the output object
  * multiple times during build.... so the export at the end of the file was created by running the first step,
  * then running the getColors function on the tokens produced in step one, and copying the result back here...
  * If this antd + tailwind experiment works out we can refine the approach and build better tooling.
  *
  * [OUTDATED INFO BELOW:]
  * Workflow could be improved but for the moment it is:
  * - drop the script below in any component that's a child of DesignProvider
  * - capture the object logged to the console
  * - repeat for light/dark modes
  * - replace the ligth/dark collections below
  *
  * From there, the flow is:
  * - preval macro runs the rest of this file on build and replaces the code with a static export of the output
  * - tailwind.config.js imports these values
  * - DesignProvider loads these values through a tailwind util
  * - andt's theme is set with the values loaded from tailwind, in DesignProvider
  * - all components that are children of Design Provider can use either color set
  * - ant-d colors are provided in tailwind classes as _[type]-antd-[lt|dk]-[colorname],
  *       e.g. '_bg-antd-lt-colorPrimaryActive'

  // Add this function to any component child of DesignProvider to dump the current antd theme in format for this file:
  import { Button, InputNumber, Modal, theme } from 'antd';
  const { token: antDTokens } = theme.useToken();
  const antdColorsStems = [
    'color',
    'blue',
    'purple',
    'cyan',
    'green',
    'magenta',
    'pink',
    'red',
    'orange',
    'yellow',
    'volcano',
    'geekblue',
    'gold',
    'lime',
    'controlItemBg',
    'controlTmpOutline',
    'controlOutline',
  ];
  const splitColorsFromOtherThemeItems = (antDTokens: Object) =>
    Object.entries(antDTokens).reduce(
      (acc: { [key: string]: any }, [k, v]: [k: string, v: any]) => {
        if (antdColorsStems.some(stem => k.startsWith(stem))) {
          acc.colors[k] = v;
        } else {
          // if (!k.startsWith('_'))
          acc.tokens[k] = v;
        }
        return acc;
      },
      { colors: {}, tokens: {} }
    );
  console.log('Antd Theme:', splitColorsFromOtherThemeItems(antDTokens));

*
*
* // These themes are experiments with the brochure color palates - just keeping for ref for now:
*
*

const thmAla1 = {
  token: {
    // milk, primary red secondary blue
    colorPrimary: '#e5706a',
    colorBgBase: '#f5f1ee',
    colorBgLayout: '#EEE6E5',
    wireframe: false,
    fontSize: 12,
    colorInfo: '#364993',
    colorError: '#e5706a',
    colorSuccess: '#64af3f',
    colorWarning: '#e8a715',
    colorTextBase: '#364993',
  },
};

const thmAla2 = {
  token: {
    // 1 but with lighter bg
    colorPrimary: '#e5706a',
    colorBgBase: '#fefefe',
    colorBgLayout: '#f5f1ee',
    wireframe: false,
    fontSize: 13,
    colorInfo: '#364993',
    colorError: '#e5706a',
    colorSuccess: '#64af3f',
    colorWarning: '#e8a715',
    colorTextBase: '#364993',
  },
};

const thmAla2Dark = {
  token: {
    colorBgBase: '#0e0b0b',
    colorBgLayout: '#f5f1ee',
    wireframe: false,
    fontSize: 13,
    colorError: '#b95651',
    colorSuccess: '#466b34',
    colorWarning: '#d69d1a',
    colorTextBase: '#364993',
    colorPrimary: '#6a7fd6',
    colorInfo: '#38619a',
    colorText: '#3a56c7',
    colorTextSecondary: '#2e408a',
  },
};


 */

/*
const tokens = {
  light: {
    colors: {
      blue: '#1677ff',
      purple: '#722ED1',
      cyan: '#13C2C2',
      green: '#52C41A',
      magenta: '#EB2F96',
      pink: '#eb2f96',
      red: '#F5222D',
      orange: '#FA8C16',
      yellow: '#FADB14',
      volcano: '#FA541C',
      geekblue: '#2F54EB',
      gold: '#FAAD14',
      lime: '#A0D911',
      colorPrimary: '#3a56c7', //TODO TODO TODO '#3a56c7',
      colorSuccess: '#52c41a',
      colorWarning: '#faad14',
      colorError: '#ff4d4f',
      colorInfo: '#1677ff',
      colorTextBase: '#000',
      colorBgBase: '#fff',
      'blue-1': '#e6f4ff',
      blue1: '#e6f4ff',
      'blue-2': '#bae0ff',
      blue2: '#bae0ff',
      'blue-3': '#91caff',
      blue3: '#91caff',
      'blue-4': '#69b1ff',
      blue4: '#69b1ff',
      'blue-5': '#4096ff',
      blue5: '#4096ff',
      'blue-6': '#1677ff',
      blue6: '#1677ff',
      'blue-7': '#0958d9',
      blue7: '#0958d9',
      'blue-8': '#003eb3',
      blue8: '#003eb3',
      'blue-9': '#002c8c',
      blue9: '#002c8c',
      'blue-10': '#001d66',
      blue10: '#001d66',
      'purple-1': '#f9f0ff',
      purple1: '#f9f0ff',
      'purple-2': '#efdbff',
      purple2: '#efdbff',
      'purple-3': '#d3adf7',
      purple3: '#d3adf7',
      'purple-4': '#b37feb',
      purple4: '#b37feb',
      'purple-5': '#9254de',
      purple5: '#9254de',
      'purple-6': '#722ed1',
      purple6: '#722ed1',
      'purple-7': '#531dab',
      purple7: '#531dab',
      'purple-8': '#391085',
      purple8: '#391085',
      'purple-9': '#22075e',
      purple9: '#22075e',
      'purple-10': '#120338',
      purple10: '#120338',
      'cyan-1': '#e6fffb',
      cyan1: '#e6fffb',
      'cyan-2': '#b5f5ec',
      cyan2: '#b5f5ec',
      'cyan-3': '#87e8de',
      cyan3: '#87e8de',
      'cyan-4': '#5cdbd3',
      cyan4: '#5cdbd3',
      'cyan-5': '#36cfc9',
      cyan5: '#36cfc9',
      'cyan-6': '#13c2c2',
      cyan6: '#13c2c2',
      'cyan-7': '#08979c',
      cyan7: '#08979c',
      'cyan-8': '#006d75',
      cyan8: '#006d75',
      'cyan-9': '#00474f',
      cyan9: '#00474f',
      'cyan-10': '#002329',
      cyan10: '#002329',
      'green-1': '#f6ffed',
      green1: '#f6ffed',
      'green-2': '#d9f7be',
      green2: '#d9f7be',
      'green-3': '#b7eb8f',
      green3: '#b7eb8f',
      'green-4': '#95de64',
      green4: '#95de64',
      'green-5': '#73d13d',
      green5: '#73d13d',
      'green-6': '#52c41a',
      green6: '#52c41a',
      'green-7': '#389e0d',
      green7: '#389e0d',
      'green-8': '#237804',
      green8: '#237804',
      'green-9': '#135200',
      green9: '#135200',
      'green-10': '#092b00',
      green10: '#092b00',
      'magenta-1': '#fff0f6',
      magenta1: '#fff0f6',
      'magenta-2': '#ffd6e7',
      magenta2: '#ffd6e7',
      'magenta-3': '#ffadd2',
      magenta3: '#ffadd2',
      'magenta-4': '#ff85c0',
      magenta4: '#ff85c0',
      'magenta-5': '#f759ab',
      magenta5: '#f759ab',
      'magenta-6': '#eb2f96',
      magenta6: '#eb2f96',
      'magenta-7': '#c41d7f',
      magenta7: '#c41d7f',
      'magenta-8': '#9e1068',
      magenta8: '#9e1068',
      'magenta-9': '#780650',
      magenta9: '#780650',
      'magenta-10': '#520339',
      magenta10: '#520339',
      'pink-1': '#fff0f6',
      pink1: '#fff0f6',
      'pink-2': '#ffd6e7',
      pink2: '#ffd6e7',
      'pink-3': '#ffadd2',
      pink3: '#ffadd2',
      'pink-4': '#ff85c0',
      pink4: '#ff85c0',
      'pink-5': '#f759ab',
      pink5: '#f759ab',
      'pink-6': '#eb2f96',
      pink6: '#eb2f96',
      'pink-7': '#c41d7f',
      pink7: '#c41d7f',
      'pink-8': '#9e1068',
      pink8: '#9e1068',
      'pink-9': '#780650',
      pink9: '#780650',
      'pink-10': '#520339',
      pink10: '#520339',
      'red-1': '#fff1f0',
      red1: '#fff1f0',
      'red-2': '#ffccc7',
      red2: '#ffccc7',
      'red-3': '#ffa39e',
      red3: '#ffa39e',
      'red-4': '#ff7875',
      red4: '#ff7875',
      'red-5': '#ff4d4f',
      red5: '#ff4d4f',
      'red-6': '#f5222d',
      red6: '#f5222d',
      'red-7': '#cf1322',
      red7: '#cf1322',
      'red-8': '#a8071a',
      red8: '#a8071a',
      'red-9': '#820014',
      red9: '#820014',
      'red-10': '#5c0011',
      red10: '#5c0011',
      'orange-1': '#fff7e6',
      orange1: '#fff7e6',
      'orange-2': '#ffe7ba',
      orange2: '#ffe7ba',
      'orange-3': '#ffd591',
      orange3: '#ffd591',
      'orange-4': '#ffc069',
      orange4: '#ffc069',
      'orange-5': '#ffa940',
      orange5: '#ffa940',
      'orange-6': '#fa8c16',
      orange6: '#fa8c16',
      'orange-7': '#d46b08',
      orange7: '#d46b08',
      'orange-8': '#ad4e00',
      orange8: '#ad4e00',
      'orange-9': '#873800',
      orange9: '#873800',
      'orange-10': '#612500',
      orange10: '#612500',
      'yellow-1': '#feffe6',
      yellow1: '#feffe6',
      'yellow-2': '#ffffb8',
      yellow2: '#ffffb8',
      'yellow-3': '#fffb8f',
      yellow3: '#fffb8f',
      'yellow-4': '#fff566',
      yellow4: '#fff566',
      'yellow-5': '#ffec3d',
      yellow5: '#ffec3d',
      'yellow-6': '#fadb14',
      yellow6: '#fadb14',
      'yellow-7': '#d4b106',
      yellow7: '#d4b106',
      'yellow-8': '#ad8b00',
      yellow8: '#ad8b00',
      'yellow-9': '#876800',
      yellow9: '#876800',
      'yellow-10': '#614700',
      yellow10: '#614700',
      'volcano-1': '#fff2e8',
      volcano1: '#fff2e8',
      'volcano-2': '#ffd8bf',
      volcano2: '#ffd8bf',
      'volcano-3': '#ffbb96',
      volcano3: '#ffbb96',
      'volcano-4': '#ff9c6e',
      volcano4: '#ff9c6e',
      'volcano-5': '#ff7a45',
      volcano5: '#ff7a45',
      'volcano-6': '#fa541c',
      volcano6: '#fa541c',
      'volcano-7': '#d4380d',
      volcano7: '#d4380d',
      'volcano-8': '#ad2102',
      volcano8: '#ad2102',
      'volcano-9': '#871400',
      volcano9: '#871400',
      'volcano-10': '#610b00',
      volcano10: '#610b00',
      'geekblue-1': '#f0f5ff',
      geekblue1: '#f0f5ff',
      'geekblue-2': '#d6e4ff',
      geekblue2: '#d6e4ff',
      'geekblue-3': '#adc6ff',
      geekblue3: '#adc6ff',
      'geekblue-4': '#85a5ff',
      geekblue4: '#85a5ff',
      'geekblue-5': '#597ef7',
      geekblue5: '#597ef7',
      'geekblue-6': '#2f54eb',
      geekblue6: '#2f54eb',
      'geekblue-7': '#1d39c4',
      geekblue7: '#1d39c4',
      'geekblue-8': '#10239e',
      geekblue8: '#10239e',
      'geekblue-9': '#061178',
      geekblue9: '#061178',
      'geekblue-10': '#030852',
      geekblue10: '#030852',
      'gold-1': '#fffbe6',
      gold1: '#fffbe6',
      'gold-2': '#fff1b8',
      gold2: '#fff1b8',
      'gold-3': '#ffe58f',
      gold3: '#ffe58f',
      'gold-4': '#ffd666',
      gold4: '#ffd666',
      'gold-5': '#ffc53d',
      gold5: '#ffc53d',
      'gold-6': '#faad14',
      gold6: '#faad14',
      'gold-7': '#d48806',
      gold7: '#d48806',
      'gold-8': '#ad6800',
      gold8: '#ad6800',
      'gold-9': '#874d00',
      gold9: '#874d00',
      'gold-10': '#613400',
      gold10: '#613400',
      'lime-1': '#fcffe6',
      lime1: '#fcffe6',
      'lime-2': '#f4ffb8',
      lime2: '#f4ffb8',
      'lime-3': '#eaff8f',
      lime3: '#eaff8f',
      'lime-4': '#d3f261',
      lime4: '#d3f261',
      'lime-5': '#bae637',
      lime5: '#bae637',
      'lime-6': '#a0d911',
      lime6: '#a0d911',
      'lime-7': '#7cb305',
      lime7: '#7cb305',
      'lime-8': '#5b8c00',
      lime8: '#5b8c00',
      'lime-9': '#3f6600',
      lime9: '#3f6600',
      'lime-10': '#254000',
      lime10: '#254000',
      colorText: 'rgba(0, 0, 0, 0.88)',
      colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
      colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
      colorFill: 'rgba(0, 0, 0, 0.15)',
      colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
      colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
      colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
      colorBgLayout: '#f5f5f5',
      colorBgContainer: '#ffffff',
      colorBgElevated: '#ffffff',
      colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
      colorBorder: '#d9d9d9',
      colorBorderSecondary: '#f0f0f0',
      colorPrimaryBg: '#f0f5ff',
      colorPrimaryBgHover: '#e8eefa',
      colorPrimaryBorder: '#b7c7ed',
      colorPrimaryBorderHover: '#89a0e0',
      colorPrimaryHover: '#5f7ad4',
      colorPrimaryActive: '#273ba1',
      colorPrimaryTextHover: '#5f7ad4',
      colorPrimaryText: '#3a56c7',
      colorPrimaryTextActive: '#273ba1',
      colorSuccessBg: '#f6ffed',
      colorSuccessBgHover: '#d9f7be',
      colorSuccessBorder: '#b7eb8f',
      colorSuccessBorderHover: '#95de64',
      colorSuccessHover: '#95de64',
      colorSuccessActive: '#389e0d',
      colorSuccessTextHover: '#73d13d',
      colorSuccessText: '#52c41a',
      colorSuccessTextActive: '#389e0d',
      colorErrorBg: '#fff2f0',
      colorErrorBgHover: '#fff1f0',
      colorErrorBorder: '#ffccc7',
      colorErrorBorderHover: '#ffa39e',
      colorErrorHover: '#ff7875',
      colorErrorActive: '#d9363e',
      colorErrorTextHover: '#ff7875',
      colorErrorText: '#ff4d4f',
      colorErrorTextActive: '#d9363e',
      colorWarningBg: '#fffbe6',
      colorWarningBgHover: '#fff1b8',
      colorWarningBorder: '#ffe58f',
      colorWarningBorderHover: '#ffd666',
      colorWarningHover: '#ffd666',
      colorWarningActive: '#d48806',
      colorWarningTextHover: '#ffc53d',
      colorWarningText: '#faad14',
      colorWarningTextActive: '#d48806',
      colorInfoBg: '#e6f4ff',
      colorInfoBgHover: '#bae0ff',
      colorInfoBorder: '#91caff',
      colorInfoBorderHover: '#69b1ff',
      colorInfoHover: '#69b1ff',
      colorInfoActive: '#0958d9',
      colorInfoTextHover: '#4096ff',
      colorInfoText: '#1677ff',
      colorInfoTextActive: '#0958d9',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      colorWhite: '#fff',
      colorLink: '#1677ff',
      colorLinkHover: '#69b1ff',
      colorLinkActive: '#0958d9',
      colorFillContent: 'rgba(0, 0, 0, 0.06)',
      colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
      colorFillAlter: 'rgba(0, 0, 0, 0.02)',
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
      colorBorderBg: '#ffffff',
      colorSplit: 'rgba(5, 5, 5, 0.06)',
      colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
      colorTextHeading: 'rgba(0, 0, 0, 0.88)',
      colorTextLabel: 'rgba(0, 0, 0, 0.65)',
      colorTextDescription: 'rgba(0, 0, 0, 0.45)',
      colorTextLightSolid: '#fff',
      colorHighlight: '#ff4d4f',
      colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
      colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
      colorIcon: 'rgba(0, 0, 0, 0.45)',
      colorIconHover: 'rgba(0, 0, 0, 0.88)',
      colorErrorOutline: 'rgba(255, 38, 5, 0.06)',
      colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
      controlOutlineWidth: 2,
      controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
      controlItemBgActive: '#f0f5ff',
      controlItemBgActiveHover: '#e8eefa',
      controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
      controlTmpOutline: 'rgba(0, 0, 0, 0.02)',
      controlOutline: 'rgba(5, 88, 255, 0.06)',
    },
    tokens: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
      fontFamilyCode:
        "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
      fontSize: 14,
      lineWidth: 1,
      lineType: 'solid',
      motionUnit: 0.1,
      motionBase: 0,
      motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
      motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
      motionEaseInBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
      motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
      borderRadius: 6,
      sizeUnit: 4,
      sizeStep: 4,
      sizePopupArrow: 16,
      controlHeight: 32,
      zIndexBase: 0,
      zIndexPopupBase: 1000,
      opacityImage: 1,
      wireframe: false,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSizeXL: 20,
      fontSizeHeading1: 38,
      fontSizeHeading2: 30,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 16,
      lineHeight: 1.5714285714285714,
      lineHeightLG: 1.5,
      lineHeightSM: 1.6666666666666667,
      lineHeightHeading1: 1.2105263157894737,
      lineHeightHeading2: 1.2666666666666666,
      lineHeightHeading3: 1.3333333333333333,
      lineHeightHeading4: 1.4,
      lineHeightHeading5: 1.5,
      sizeXXL: 48,
      sizeXL: 32,
      sizeLG: 24,
      sizeMD: 20,
      sizeMS: 16,
      size: 16,
      sizeSM: 12,
      sizeXS: 8,
      sizeXXS: 4,
      controlHeightSM: 24,
      controlHeightXS: 16,
      controlHeightLG: 40,
      motionDurationFast: '0.1s',
      motionDurationMid: '0.2s',
      motionDurationSlow: '0.3s',
      lineWidthBold: 2,
      borderRadiusXS: 2,
      borderRadiusSM: 4,
      borderRadiusLG: 8,
      borderRadiusOuter: 4,
      fontSizeIcon: 12,
      lineWidthFocus: 4,
      controlInteractiveSize: 16,
      fontWeightStrong: 600,
      opacityLoading: 0.65,
      linkDecoration: 'none',
      linkHoverDecoration: 'none',
      linkFocusDecoration: 'none',
      controlPaddingHorizontal: 12,
      controlPaddingHorizontalSM: 8,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      paddingMD: 20,
      paddingLG: 24,
      paddingXL: 32,
      paddingContentHorizontalLG: 24,
      paddingContentVerticalLG: 16,
      paddingContentHorizontal: 16,
      paddingContentVertical: 12,
      paddingContentHorizontalSM: 16,
      paddingContentVerticalSM: 8,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      margin: 16,
      marginMD: 20,
      marginLG: 24,
      marginXL: 32,
      marginXXL: 48,
      boxShadow:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowSecondary:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowTertiary:
        '\n      0 1px 2px 0 rgba(0, 0, 0, 0.03),\n      0 1px 6px -1px rgba(0, 0, 0, 0.02),\n      0 2px 4px 0 rgba(0, 0, 0, 0.02)\n    ',
      screenXS: 480,
      screenXSMin: 480,
      screenXSMax: 575,
      screenSM: 576,
      screenSMMin: 576,
      screenSMMax: 767,
      screenMD: 768,
      screenMDMin: 768,
      screenMDMax: 991,
      screenLG: 992,
      screenLGMin: 992,
      screenLGMax: 1199,
      screenXL: 1200,
      screenXLMin: 1200,
      screenXLMax: 1599,
      screenXXL: 1600,
      screenXXLMin: 1600,
      boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)',
      boxShadowCard:
        '\n      0 1px 2px -2px rgba(0, 0, 0, 0.16),\n      0 3px 6px 0 rgba(0, 0, 0, 0.12),\n      0 5px 12px 4px rgba(0, 0, 0, 0.09)\n    ',
      boxShadowDrawerRight:
        '\n      -6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      -3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      -9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerLeft:
        '\n      6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerUp:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerDown:
        '\n      0 -6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 -3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 -9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowBottom: 'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)',
      _tokenKey: 'nu5b6i',
      _hashId: 'css-dev-only-do-not-override-tbaoyj',
    },
  },
  dark: {
    colors: {
      blue: '#1677ff',
      purple: '#722ED1',
      cyan: '#13C2C2',
      green: '#52C41A',
      magenta: '#EB2F96',
      pink: '#eb2f96',
      red: '#F5222D',
      orange: '#FA8C16',
      yellow: '#FADB14',
      volcano: '#FA541C',
      geekblue: '#2F54EB',
      gold: '#FAAD14',
      lime: '#A0D911',
      colorPrimary: '#344cac', //TODO TODO TODO '#344cac',
      colorSuccess: '#49aa19',
      colorWarning: '#d89614',
      colorError: '#dc4446',
      colorInfo: '#1668dc',
      colorTextBase: '#fff',
      colorBgBase: '#161616',
      'blue-1': '#111a2c',
      blue1: '#111a2c',
      'blue-2': '#112545',
      blue2: '#112545',
      'blue-3': '#15325b',
      blue3: '#15325b',
      'blue-4': '#15417e',
      blue4: '#15417e',
      'blue-5': '#1554ad',
      blue5: '#1554ad',
      'blue-6': '#1668dc',
      blue6: '#1668dc',
      'blue-7': '#3c89e8',
      blue7: '#3c89e8',
      'blue-8': '#65a9f3',
      blue8: '#65a9f3',
      'blue-9': '#8dc5f8',
      blue9: '#8dc5f8',
      'blue-10': '#b7dcfa',
      blue10: '#b7dcfa',
      'purple-1': '#1a1325',
      purple1: '#1a1325',
      'purple-2': '#24163a',
      purple2: '#24163a',
      'purple-3': '#301c4d',
      purple3: '#301c4d',
      'purple-4': '#3e2069',
      purple4: '#3e2069',
      'purple-5': '#51258f',
      purple5: '#51258f',
      'purple-6': '#642ab5',
      purple6: '#642ab5',
      'purple-7': '#854eca',
      purple7: '#854eca',
      'purple-8': '#ab7ae0',
      purple8: '#ab7ae0',
      'purple-9': '#cda8f0',
      purple9: '#cda8f0',
      'purple-10': '#ebd7fa',
      purple10: '#ebd7fa',
      'cyan-1': '#112123',
      cyan1: '#112123',
      'cyan-2': '#113536',
      cyan2: '#113536',
      'cyan-3': '#144848',
      cyan3: '#144848',
      'cyan-4': '#146262',
      cyan4: '#146262',
      'cyan-5': '#138585',
      cyan5: '#138585',
      'cyan-6': '#13a8a8',
      cyan6: '#13a8a8',
      'cyan-7': '#33bcb7',
      cyan7: '#33bcb7',
      'cyan-8': '#58d1c9',
      cyan8: '#58d1c9',
      'cyan-9': '#84e2d8',
      cyan9: '#84e2d8',
      'cyan-10': '#b2f1e8',
      cyan10: '#b2f1e8',
      'green-1': '#162312',
      green1: '#162312',
      'green-2': '#1d3712',
      green2: '#1d3712',
      'green-3': '#274916',
      green3: '#274916',
      'green-4': '#306317',
      green4: '#306317',
      'green-5': '#3c8618',
      green5: '#3c8618',
      'green-6': '#49aa19',
      green6: '#49aa19',
      'green-7': '#6abe39',
      green7: '#6abe39',
      'green-8': '#8fd460',
      green8: '#8fd460',
      'green-9': '#b2e58b',
      green9: '#b2e58b',
      'green-10': '#d5f2bb',
      green10: '#d5f2bb',
      'magenta-1': '#291321',
      magenta1: '#291321',
      'magenta-2': '#40162f',
      magenta2: '#40162f',
      'magenta-3': '#551c3b',
      magenta3: '#551c3b',
      'magenta-4': '#75204f',
      magenta4: '#75204f',
      'magenta-5': '#a02669',
      magenta5: '#a02669',
      'magenta-6': '#cb2b83',
      magenta6: '#cb2b83',
      'magenta-7': '#e0529c',
      magenta7: '#e0529c',
      'magenta-8': '#f37fb7',
      magenta8: '#f37fb7',
      'magenta-9': '#f8a8cc',
      magenta9: '#f8a8cc',
      'magenta-10': '#fad2e3',
      magenta10: '#fad2e3',
      'pink-1': '#291321',
      pink1: '#291321',
      'pink-2': '#40162f',
      pink2: '#40162f',
      'pink-3': '#551c3b',
      pink3: '#551c3b',
      'pink-4': '#75204f',
      pink4: '#75204f',
      'pink-5': '#a02669',
      pink5: '#a02669',
      'pink-6': '#cb2b83',
      pink6: '#cb2b83',
      'pink-7': '#e0529c',
      pink7: '#e0529c',
      'pink-8': '#f37fb7',
      pink8: '#f37fb7',
      'pink-9': '#f8a8cc',
      pink9: '#f8a8cc',
      'pink-10': '#fad2e3',
      pink10: '#fad2e3',
      'red-1': '#2a1215',
      red1: '#2a1215',
      'red-2': '#431418',
      red2: '#431418',
      'red-3': '#58181c',
      red3: '#58181c',
      'red-4': '#791a1f',
      red4: '#791a1f',
      'red-5': '#a61d24',
      red5: '#a61d24',
      'red-6': '#d32029',
      red6: '#d32029',
      'red-7': '#e84749',
      red7: '#e84749',
      'red-8': '#f37370',
      red8: '#f37370',
      'red-9': '#f89f9a',
      red9: '#f89f9a',
      'red-10': '#fac8c3',
      red10: '#fac8c3',
      'orange-1': '#2b1d11',
      orange1: '#2b1d11',
      'orange-2': '#442a11',
      orange2: '#442a11',
      'orange-3': '#593815',
      orange3: '#593815',
      'orange-4': '#7c4a15',
      orange4: '#7c4a15',
      'orange-5': '#aa6215',
      orange5: '#aa6215',
      'orange-6': '#d87a16',
      orange6: '#d87a16',
      'orange-7': '#e89a3c',
      orange7: '#e89a3c',
      'orange-8': '#f3b765',
      orange8: '#f3b765',
      'orange-9': '#f8cf8d',
      orange9: '#f8cf8d',
      'orange-10': '#fae3b7',
      orange10: '#fae3b7',
      'yellow-1': '#2b2611',
      yellow1: '#2b2611',
      'yellow-2': '#443b11',
      yellow2: '#443b11',
      'yellow-3': '#595014',
      yellow3: '#595014',
      'yellow-4': '#7c6e14',
      yellow4: '#7c6e14',
      'yellow-5': '#aa9514',
      yellow5: '#aa9514',
      'yellow-6': '#d8bd14',
      yellow6: '#d8bd14',
      'yellow-7': '#e8d639',
      yellow7: '#e8d639',
      'yellow-8': '#f3ea62',
      yellow8: '#f3ea62',
      'yellow-9': '#f8f48b',
      yellow9: '#f8f48b',
      'yellow-10': '#fafab5',
      yellow10: '#fafab5',
      'volcano-1': '#2b1611',
      volcano1: '#2b1611',
      'volcano-2': '#441d12',
      volcano2: '#441d12',
      'volcano-3': '#592716',
      volcano3: '#592716',
      'volcano-4': '#7c3118',
      volcano4: '#7c3118',
      'volcano-5': '#aa3e19',
      volcano5: '#aa3e19',
      'volcano-6': '#d84a1b',
      volcano6: '#d84a1b',
      'volcano-7': '#e87040',
      volcano7: '#e87040',
      'volcano-8': '#f3956a',
      volcano8: '#f3956a',
      'volcano-9': '#f8b692',
      volcano9: '#f8b692',
      'volcano-10': '#fad4bc',
      volcano10: '#fad4bc',
      'geekblue-1': '#131629',
      geekblue1: '#131629',
      'geekblue-2': '#161d40',
      geekblue2: '#161d40',
      'geekblue-3': '#1c2755',
      geekblue3: '#1c2755',
      'geekblue-4': '#203175',
      geekblue4: '#203175',
      'geekblue-5': '#263ea0',
      geekblue5: '#263ea0',
      'geekblue-6': '#2b4acb',
      geekblue6: '#2b4acb',
      'geekblue-7': '#5273e0',
      geekblue7: '#5273e0',
      'geekblue-8': '#7f9ef3',
      geekblue8: '#7f9ef3',
      'geekblue-9': '#a8c1f8',
      geekblue9: '#a8c1f8',
      'geekblue-10': '#d2e0fa',
      geekblue10: '#d2e0fa',
      'gold-1': '#2b2111',
      gold1: '#2b2111',
      'gold-2': '#443111',
      gold2: '#443111',
      'gold-3': '#594214',
      gold3: '#594214',
      'gold-4': '#7c5914',
      gold4: '#7c5914',
      'gold-5': '#aa7714',
      gold5: '#aa7714',
      'gold-6': '#d89614',
      gold6: '#d89614',
      'gold-7': '#e8b339',
      gold7: '#e8b339',
      'gold-8': '#f3cc62',
      gold8: '#f3cc62',
      'gold-9': '#f8df8b',
      gold9: '#f8df8b',
      'gold-10': '#faedb5',
      gold10: '#faedb5',
      'lime-1': '#1f2611',
      lime1: '#1f2611',
      'lime-2': '#2e3c10',
      lime2: '#2e3c10',
      'lime-3': '#3e4f13',
      lime3: '#3e4f13',
      'lime-4': '#536d13',
      lime4: '#536d13',
      'lime-5': '#6f9412',
      lime5: '#6f9412',
      'lime-6': '#8bbb11',
      lime6: '#8bbb11',
      'lime-7': '#a9d134',
      lime7: '#a9d134',
      'lime-8': '#c9e75d',
      lime8: '#c9e75d',
      'lime-9': '#e4f88b',
      lime9: '#e4f88b',
      'lime-10': '#f0fab5',
      lime10: '#f0fab5',
      colorText: 'rgba(255, 255, 255, 0.85)',
      colorTextSecondary: 'rgba(255, 255, 255, 0.65)',
      colorTextTertiary: 'rgba(255, 255, 255, 0.45)',
      colorTextQuaternary: 'rgba(255, 255, 255, 0.25)',
      colorFill: 'rgba(255, 255, 255, 0.18)',
      colorFillSecondary: 'rgba(255, 255, 255, 0.12)',
      colorFillTertiary: 'rgba(255, 255, 255, 0.08)',
      colorFillQuaternary: 'rgba(255, 255, 255, 0.04)',
      colorBgLayout: '#161616',
      colorBgContainer: '#2a2a2a',
      colorBgElevated: '#353535',
      colorBgSpotlight: '#585858',
      colorBorder: '#585858',
      colorBorderSecondary: '#464646',
      colorPrimaryBg: '#141623',
      colorPrimaryBgHover: '#191e37',
      colorPrimaryBorder: '#1f284a',
      colorPrimaryBorderHover: '#253265',
      colorPrimaryHover: '#5870c1',
      colorPrimaryActive: '#2d3f88',
      colorPrimaryTextHover: '#5870c1',
      colorPrimaryText: '#344cac',
      colorPrimaryTextActive: '#2d3f88',
      colorSuccessBg: '#162312',
      colorSuccessBgHover: '#1d3712',
      colorSuccessBorder: '#274916',
      colorSuccessBorderHover: '#306317',
      colorSuccessHover: '#306317',
      colorSuccessActive: '#3c8618',
      colorSuccessTextHover: '#6abe39',
      colorSuccessText: '#49aa19',
      colorSuccessTextActive: '#3c8618',
      colorErrorBg: '#2c1618',
      colorErrorBgHover: '#451d1f',
      colorErrorBorder: '#5b2526',
      colorErrorBorderHover: '#7e2e2f',
      colorErrorHover: '#e86e6b',
      colorErrorActive: '#ad393a',
      colorErrorTextHover: '#e86e6b',
      colorErrorText: '#dc4446',
      colorErrorTextActive: '#ad393a',
      colorWarningBg: '#2b2111',
      colorWarningBgHover: '#443111',
      colorWarningBorder: '#594214',
      colorWarningBorderHover: '#7c5914',
      colorWarningHover: '#7c5914',
      colorWarningActive: '#aa7714',
      colorWarningTextHover: '#e8b339',
      colorWarningText: '#d89614',
      colorWarningTextActive: '#aa7714',
      colorInfoBg: '#111a2c',
      colorInfoBgHover: '#112545',
      colorInfoBorder: '#15325b',
      colorInfoBorderHover: '#15417e',
      colorInfoHover: '#15417e',
      colorInfoActive: '#1554ad',
      colorInfoTextHover: '#3c89e8',
      colorInfoText: '#1668dc',
      colorInfoTextActive: '#1554ad',
      colorBgMask: 'rgba(0, 0, 0, 0.45)',
      colorWhite: '#fff',
      colorLink: '#1668dc',
      colorLinkHover: '#15417e',
      colorLinkActive: '#1554ad',
      colorFillContent: 'rgba(255, 255, 255, 0.12)',
      colorFillContentHover: 'rgba(255, 255, 255, 0.18)',
      colorFillAlter: 'rgba(255, 255, 255, 0.04)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.08)',
      colorBorderBg: '#2a2a2a',
      colorSplit: 'rgba(242, 242, 242, 0.14)',
      colorTextPlaceholder: 'rgba(255, 255, 255, 0.25)',
      colorTextDisabled: 'rgba(255, 255, 255, 0.25)',
      colorTextHeading: 'rgba(255, 255, 255, 0.85)',
      colorTextLabel: 'rgba(255, 255, 255, 0.65)',
      colorTextDescription: 'rgba(255, 255, 255, 0.45)',
      colorTextLightSolid: '#fff',
      colorHighlight: '#dc4446',
      colorBgTextHover: 'rgba(255, 255, 255, 0.12)',
      colorBgTextActive: 'rgba(255, 255, 255, 0.18)',
      colorIcon: 'rgba(255, 255, 255, 0.45)',
      colorIconHover: 'rgba(255, 255, 255, 0.85)',
      colorErrorOutline: 'rgba(46, 0, 5, 0.48)',
      colorWarningOutline: 'rgba(44, 27, 0, 0.59)',
      controlOutlineWidth: 2,
      controlItemBgHover: 'rgba(255, 255, 255, 0.08)',
      controlItemBgActive: '#141623',
      controlItemBgActiveHover: '#191e37',
      controlItemBgActiveDisabled: 'rgba(255, 255, 255, 0.18)',
      controlTmpOutline: 'rgba(255, 255, 255, 0.04)',
      controlOutline: 'rgba(0, 4, 29, 0.52)',
    },
    tokens: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
      fontFamilyCode:
        "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
      fontSize: 14,
      lineWidth: 1,
      lineType: 'solid',
      motionUnit: 0.1,
      motionBase: 0,
      motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
      motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
      motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
      motionEaseInBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
      motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
      borderRadius: 6,
      sizeUnit: 4,
      sizeStep: 4,
      sizePopupArrow: 16,
      controlHeight: 32,
      zIndexBase: 0,
      zIndexPopupBase: 1000,
      opacityImage: 1,
      wireframe: false,
      fontSizeSM: 12,
      fontSizeLG: 16,
      fontSizeXL: 20,
      fontSizeHeading1: 38,
      fontSizeHeading2: 30,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSizeHeading5: 16,
      lineHeight: 1.5714285714285714,
      lineHeightLG: 1.5,
      lineHeightSM: 1.6666666666666667,
      lineHeightHeading1: 1.2105263157894737,
      lineHeightHeading2: 1.2666666666666666,
      lineHeightHeading3: 1.3333333333333333,
      lineHeightHeading4: 1.4,
      lineHeightHeading5: 1.5,
      sizeXXL: 48,
      sizeXL: 32,
      sizeLG: 24,
      sizeMD: 20,
      sizeMS: 16,
      size: 16,
      sizeSM: 12,
      sizeXS: 8,
      sizeXXS: 4,
      controlHeightSM: 24,
      controlHeightXS: 16,
      controlHeightLG: 40,
      motionDurationFast: '0.1s',
      motionDurationMid: '0.2s',
      motionDurationSlow: '0.3s',
      lineWidthBold: 2,
      borderRadiusXS: 2,
      borderRadiusSM: 4,
      borderRadiusLG: 8,
      borderRadiusOuter: 4,
      fontSizeIcon: 12,
      lineWidthFocus: 4,
      controlInteractiveSize: 16,
      fontWeightStrong: 600,
      opacityLoading: 0.65,
      linkDecoration: 'none',
      linkHoverDecoration: 'none',
      linkFocusDecoration: 'none',
      controlPaddingHorizontal: 12,
      controlPaddingHorizontalSM: 8,
      paddingXXS: 4,
      paddingXS: 8,
      paddingSM: 12,
      padding: 16,
      paddingMD: 20,
      paddingLG: 24,
      paddingXL: 32,
      paddingContentHorizontalLG: 24,
      paddingContentVerticalLG: 16,
      paddingContentHorizontal: 16,
      paddingContentVertical: 12,
      paddingContentHorizontalSM: 16,
      paddingContentVerticalSM: 8,
      marginXXS: 4,
      marginXS: 8,
      marginSM: 12,
      margin: 16,
      marginMD: 20,
      marginLG: 24,
      marginXL: 32,
      marginXXL: 48,
      boxShadow:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowSecondary:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowTertiary:
        '\n      0 1px 2px 0 rgba(0, 0, 0, 0.03),\n      0 1px 6px -1px rgba(0, 0, 0, 0.02),\n      0 2px 4px 0 rgba(0, 0, 0, 0.02)\n    ',
      screenXS: 480,
      screenXSMin: 480,
      screenXSMax: 575,
      screenSM: 576,
      screenSMMin: 576,
      screenSMMax: 767,
      screenMD: 768,
      screenMDMin: 768,
      screenMDMax: 991,
      screenLG: 992,
      screenLGMin: 992,
      screenLGMax: 1199,
      screenXL: 1200,
      screenXLMin: 1200,
      screenXLMax: 1599,
      screenXXL: 1600,
      screenXXLMin: 1600,
      boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)',
      boxShadowCard:
        '\n      0 1px 2px -2px rgba(0, 0, 0, 0.16),\n      0 3px 6px 0 rgba(0, 0, 0, 0.12),\n      0 5px 12px 4px rgba(0, 0, 0, 0.09)\n    ',
      boxShadowDrawerRight:
        '\n      -6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      -3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      -9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerLeft:
        '\n      6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerUp:
        '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowDrawerDown:
        '\n      0 -6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 -3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 -9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
      boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)',
      boxShadowTabsOverflowBottom: 'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)',
      _tokenKey: '1yebm22',
      _hashId: 'css-dev-only-do-not-override-71nzkr',
    },
  },
};


// See note at top, despite trying to get babel-plugin-preval / preval macro to precompile this, it's still
// called repeatedly -- perhaps my misunderstanding (preval may be more useful in the app code rather than config)
// or maybe was misconfigured....  Ran this code on the tokens above to gen the export below, we can figure out proper tooling
// if the whole strategy seems worthwhile...
const genColors = () => {
  console.log('*** Building antd colors for tailwind use ***');
  return {
    ...Object.fromEntries(
      Object.entries(tokens.light.colors).map(([k, v]) => {
        // console.log('tw light ' + k);
        return ['antd-lt-' + k, v];
      })
    ),
    ...Object.fromEntries(
      Object.entries(tokens.dark.colors).map(([k, v]) => {
        // console.log('tw dark ' + k);
        return ['antd-dk-' + k, v];
      })
    ),
  };
};

module.exports = { colors: genColors(), tokens };

*/

// per note at top, for now, built this map by running the two steps manually so it can be static.
module.exports = {
  colors: {
    'antd-lt-blue': '#1677ff',
    'antd-lt-purple': '#722ED1',
    'antd-lt-cyan': '#13C2C2',
    'antd-lt-green': '#52C41A',
    'antd-lt-magenta': '#EB2F96',
    'antd-lt-pink': '#eb2f96',
    'antd-lt-red': '#F5222D',
    'antd-lt-orange': '#FA8C16',
    'antd-lt-yellow': '#FADB14',
    'antd-lt-volcano': '#FA541C',
    'antd-lt-geekblue': '#2F54EB',
    'antd-lt-gold': '#FAAD14',
    'antd-lt-lime': '#A0D911',
    'antd-lt-colorPrimary': '#3a56c7',
    'antd-lt-colorSuccess': '#52c41a',
    'antd-lt-colorWarning': '#faad14',
    'antd-lt-colorError': '#ff4d4f',
    'antd-lt-colorInfo': '#1677ff',
    'antd-lt-colorTextBase': '#000',
    'antd-lt-colorBgBase': '#fff',
    'antd-lt-blue-1': '#e6f4ff',
    'antd-lt-blue1': '#e6f4ff',
    'antd-lt-blue-2': '#bae0ff',
    'antd-lt-blue2': '#bae0ff',
    'antd-lt-blue-3': '#91caff',
    'antd-lt-blue3': '#91caff',
    'antd-lt-blue-4': '#69b1ff',
    'antd-lt-blue4': '#69b1ff',
    'antd-lt-blue-5': '#4096ff',
    'antd-lt-blue5': '#4096ff',
    'antd-lt-blue-6': '#1677ff',
    'antd-lt-blue6': '#1677ff',
    'antd-lt-blue-7': '#0958d9',
    'antd-lt-blue7': '#0958d9',
    'antd-lt-blue-8': '#003eb3',
    'antd-lt-blue8': '#003eb3',
    'antd-lt-blue-9': '#002c8c',
    'antd-lt-blue9': '#002c8c',
    'antd-lt-blue-10': '#001d66',
    'antd-lt-blue10': '#001d66',
    'antd-lt-purple-1': '#f9f0ff',
    'antd-lt-purple1': '#f9f0ff',
    'antd-lt-purple-2': '#efdbff',
    'antd-lt-purple2': '#efdbff',
    'antd-lt-purple-3': '#d3adf7',
    'antd-lt-purple3': '#d3adf7',
    'antd-lt-purple-4': '#b37feb',
    'antd-lt-purple4': '#b37feb',
    'antd-lt-purple-5': '#9254de',
    'antd-lt-purple5': '#9254de',
    'antd-lt-purple-6': '#722ed1',
    'antd-lt-purple6': '#722ed1',
    'antd-lt-purple-7': '#531dab',
    'antd-lt-purple7': '#531dab',
    'antd-lt-purple-8': '#391085',
    'antd-lt-purple8': '#391085',
    'antd-lt-purple-9': '#22075e',
    'antd-lt-purple9': '#22075e',
    'antd-lt-purple-10': '#120338',
    'antd-lt-purple10': '#120338',
    'antd-lt-cyan-1': '#e6fffb',
    'antd-lt-cyan1': '#e6fffb',
    'antd-lt-cyan-2': '#b5f5ec',
    'antd-lt-cyan2': '#b5f5ec',
    'antd-lt-cyan-3': '#87e8de',
    'antd-lt-cyan3': '#87e8de',
    'antd-lt-cyan-4': '#5cdbd3',
    'antd-lt-cyan4': '#5cdbd3',
    'antd-lt-cyan-5': '#36cfc9',
    'antd-lt-cyan5': '#36cfc9',
    'antd-lt-cyan-6': '#13c2c2',
    'antd-lt-cyan6': '#13c2c2',
    'antd-lt-cyan-7': '#08979c',
    'antd-lt-cyan7': '#08979c',
    'antd-lt-cyan-8': '#006d75',
    'antd-lt-cyan8': '#006d75',
    'antd-lt-cyan-9': '#00474f',
    'antd-lt-cyan9': '#00474f',
    'antd-lt-cyan-10': '#002329',
    'antd-lt-cyan10': '#002329',
    'antd-lt-green-1': '#f6ffed',
    'antd-lt-green1': '#f6ffed',
    'antd-lt-green-2': '#d9f7be',
    'antd-lt-green2': '#d9f7be',
    'antd-lt-green-3': '#b7eb8f',
    'antd-lt-green3': '#b7eb8f',
    'antd-lt-green-4': '#95de64',
    'antd-lt-green4': '#95de64',
    'antd-lt-green-5': '#73d13d',
    'antd-lt-green5': '#73d13d',
    'antd-lt-green-6': '#52c41a',
    'antd-lt-green6': '#52c41a',
    'antd-lt-green-7': '#389e0d',
    'antd-lt-green7': '#389e0d',
    'antd-lt-green-8': '#237804',
    'antd-lt-green8': '#237804',
    'antd-lt-green-9': '#135200',
    'antd-lt-green9': '#135200',
    'antd-lt-green-10': '#092b00',
    'antd-lt-green10': '#092b00',
    'antd-lt-magenta-1': '#fff0f6',
    'antd-lt-magenta1': '#fff0f6',
    'antd-lt-magenta-2': '#ffd6e7',
    'antd-lt-magenta2': '#ffd6e7',
    'antd-lt-magenta-3': '#ffadd2',
    'antd-lt-magenta3': '#ffadd2',
    'antd-lt-magenta-4': '#ff85c0',
    'antd-lt-magenta4': '#ff85c0',
    'antd-lt-magenta-5': '#f759ab',
    'antd-lt-magenta5': '#f759ab',
    'antd-lt-magenta-6': '#eb2f96',
    'antd-lt-magenta6': '#eb2f96',
    'antd-lt-magenta-7': '#c41d7f',
    'antd-lt-magenta7': '#c41d7f',
    'antd-lt-magenta-8': '#9e1068',
    'antd-lt-magenta8': '#9e1068',
    'antd-lt-magenta-9': '#780650',
    'antd-lt-magenta9': '#780650',
    'antd-lt-magenta-10': '#520339',
    'antd-lt-magenta10': '#520339',
    'antd-lt-pink-1': '#fff0f6',
    'antd-lt-pink1': '#fff0f6',
    'antd-lt-pink-2': '#ffd6e7',
    'antd-lt-pink2': '#ffd6e7',
    'antd-lt-pink-3': '#ffadd2',
    'antd-lt-pink3': '#ffadd2',
    'antd-lt-pink-4': '#ff85c0',
    'antd-lt-pink4': '#ff85c0',
    'antd-lt-pink-5': '#f759ab',
    'antd-lt-pink5': '#f759ab',
    'antd-lt-pink-6': '#eb2f96',
    'antd-lt-pink6': '#eb2f96',
    'antd-lt-pink-7': '#c41d7f',
    'antd-lt-pink7': '#c41d7f',
    'antd-lt-pink-8': '#9e1068',
    'antd-lt-pink8': '#9e1068',
    'antd-lt-pink-9': '#780650',
    'antd-lt-pink9': '#780650',
    'antd-lt-pink-10': '#520339',
    'antd-lt-pink10': '#520339',
    'antd-lt-red-1': '#fff1f0',
    'antd-lt-red1': '#fff1f0',
    'antd-lt-red-2': '#ffccc7',
    'antd-lt-red2': '#ffccc7',
    'antd-lt-red-3': '#ffa39e',
    'antd-lt-red3': '#ffa39e',
    'antd-lt-red-4': '#ff7875',
    'antd-lt-red4': '#ff7875',
    'antd-lt-red-5': '#ff4d4f',
    'antd-lt-red5': '#ff4d4f',
    'antd-lt-red-6': '#f5222d',
    'antd-lt-red6': '#f5222d',
    'antd-lt-red-7': '#cf1322',
    'antd-lt-red7': '#cf1322',
    'antd-lt-red-8': '#a8071a',
    'antd-lt-red8': '#a8071a',
    'antd-lt-red-9': '#820014',
    'antd-lt-red9': '#820014',
    'antd-lt-red-10': '#5c0011',
    'antd-lt-red10': '#5c0011',
    'antd-lt-orange-1': '#fff7e6',
    'antd-lt-orange1': '#fff7e6',
    'antd-lt-orange-2': '#ffe7ba',
    'antd-lt-orange2': '#ffe7ba',
    'antd-lt-orange-3': '#ffd591',
    'antd-lt-orange3': '#ffd591',
    'antd-lt-orange-4': '#ffc069',
    'antd-lt-orange4': '#ffc069',
    'antd-lt-orange-5': '#ffa940',
    'antd-lt-orange5': '#ffa940',
    'antd-lt-orange-6': '#fa8c16',
    'antd-lt-orange6': '#fa8c16',
    'antd-lt-orange-7': '#d46b08',
    'antd-lt-orange7': '#d46b08',
    'antd-lt-orange-8': '#ad4e00',
    'antd-lt-orange8': '#ad4e00',
    'antd-lt-orange-9': '#873800',
    'antd-lt-orange9': '#873800',
    'antd-lt-orange-10': '#612500',
    'antd-lt-orange10': '#612500',
    'antd-lt-yellow-1': '#feffe6',
    'antd-lt-yellow1': '#feffe6',
    'antd-lt-yellow-2': '#ffffb8',
    'antd-lt-yellow2': '#ffffb8',
    'antd-lt-yellow-3': '#fffb8f',
    'antd-lt-yellow3': '#fffb8f',
    'antd-lt-yellow-4': '#fff566',
    'antd-lt-yellow4': '#fff566',
    'antd-lt-yellow-5': '#ffec3d',
    'antd-lt-yellow5': '#ffec3d',
    'antd-lt-yellow-6': '#fadb14',
    'antd-lt-yellow6': '#fadb14',
    'antd-lt-yellow-7': '#d4b106',
    'antd-lt-yellow7': '#d4b106',
    'antd-lt-yellow-8': '#ad8b00',
    'antd-lt-yellow8': '#ad8b00',
    'antd-lt-yellow-9': '#876800',
    'antd-lt-yellow9': '#876800',
    'antd-lt-yellow-10': '#614700',
    'antd-lt-yellow10': '#614700',
    'antd-lt-volcano-1': '#fff2e8',
    'antd-lt-volcano1': '#fff2e8',
    'antd-lt-volcano-2': '#ffd8bf',
    'antd-lt-volcano2': '#ffd8bf',
    'antd-lt-volcano-3': '#ffbb96',
    'antd-lt-volcano3': '#ffbb96',
    'antd-lt-volcano-4': '#ff9c6e',
    'antd-lt-volcano4': '#ff9c6e',
    'antd-lt-volcano-5': '#ff7a45',
    'antd-lt-volcano5': '#ff7a45',
    'antd-lt-volcano-6': '#fa541c',
    'antd-lt-volcano6': '#fa541c',
    'antd-lt-volcano-7': '#d4380d',
    'antd-lt-volcano7': '#d4380d',
    'antd-lt-volcano-8': '#ad2102',
    'antd-lt-volcano8': '#ad2102',
    'antd-lt-volcano-9': '#871400',
    'antd-lt-volcano9': '#871400',
    'antd-lt-volcano-10': '#610b00',
    'antd-lt-volcano10': '#610b00',
    'antd-lt-geekblue-1': '#f0f5ff',
    'antd-lt-geekblue1': '#f0f5ff',
    'antd-lt-geekblue-2': '#d6e4ff',
    'antd-lt-geekblue2': '#d6e4ff',
    'antd-lt-geekblue-3': '#adc6ff',
    'antd-lt-geekblue3': '#adc6ff',
    'antd-lt-geekblue-4': '#85a5ff',
    'antd-lt-geekblue4': '#85a5ff',
    'antd-lt-geekblue-5': '#597ef7',
    'antd-lt-geekblue5': '#597ef7',
    'antd-lt-geekblue-6': '#2f54eb',
    'antd-lt-geekblue6': '#2f54eb',
    'antd-lt-geekblue-7': '#1d39c4',
    'antd-lt-geekblue7': '#1d39c4',
    'antd-lt-geekblue-8': '#10239e',
    'antd-lt-geekblue8': '#10239e',
    'antd-lt-geekblue-9': '#061178',
    'antd-lt-geekblue9': '#061178',
    'antd-lt-geekblue-10': '#030852',
    'antd-lt-geekblue10': '#030852',
    'antd-lt-gold-1': '#fffbe6',
    'antd-lt-gold1': '#fffbe6',
    'antd-lt-gold-2': '#fff1b8',
    'antd-lt-gold2': '#fff1b8',
    'antd-lt-gold-3': '#ffe58f',
    'antd-lt-gold3': '#ffe58f',
    'antd-lt-gold-4': '#ffd666',
    'antd-lt-gold4': '#ffd666',
    'antd-lt-gold-5': '#ffc53d',
    'antd-lt-gold5': '#ffc53d',
    'antd-lt-gold-6': '#faad14',
    'antd-lt-gold6': '#faad14',
    'antd-lt-gold-7': '#d48806',
    'antd-lt-gold7': '#d48806',
    'antd-lt-gold-8': '#ad6800',
    'antd-lt-gold8': '#ad6800',
    'antd-lt-gold-9': '#874d00',
    'antd-lt-gold9': '#874d00',
    'antd-lt-gold-10': '#613400',
    'antd-lt-gold10': '#613400',
    'antd-lt-lime-1': '#fcffe6',
    'antd-lt-lime1': '#fcffe6',
    'antd-lt-lime-2': '#f4ffb8',
    'antd-lt-lime2': '#f4ffb8',
    'antd-lt-lime-3': '#eaff8f',
    'antd-lt-lime3': '#eaff8f',
    'antd-lt-lime-4': '#d3f261',
    'antd-lt-lime4': '#d3f261',
    'antd-lt-lime-5': '#bae637',
    'antd-lt-lime5': '#bae637',
    'antd-lt-lime-6': '#a0d911',
    'antd-lt-lime6': '#a0d911',
    'antd-lt-lime-7': '#7cb305',
    'antd-lt-lime7': '#7cb305',
    'antd-lt-lime-8': '#5b8c00',
    'antd-lt-lime8': '#5b8c00',
    'antd-lt-lime-9': '#3f6600',
    'antd-lt-lime9': '#3f6600',
    'antd-lt-lime-10': '#254000',
    'antd-lt-lime10': '#254000',
    'antd-lt-colorText': 'rgba(0, 0, 0, 0.88)',
    'antd-lt-colorTextSecondary': 'rgba(0, 0, 0, 0.65)',
    'antd-lt-colorTextTertiary': 'rgba(0, 0, 0, 0.45)',
    'antd-lt-colorTextQuaternary': 'rgba(0, 0, 0, 0.25)',
    'antd-lt-colorFill': 'rgba(0, 0, 0, 0.15)',
    'antd-lt-colorFillSecondary': 'rgba(0, 0, 0, 0.06)',
    'antd-lt-colorFillTertiary': 'rgba(0, 0, 0, 0.04)',
    'antd-lt-colorFillQuaternary': 'rgba(0, 0, 0, 0.02)',
    'antd-lt-colorBgLayout': '#f5f5f5',
    'antd-lt-colorBgContainer': '#ffffff',
    'antd-lt-colorBgElevated': '#ffffff',
    'antd-lt-colorBgSpotlight': 'rgba(0, 0, 0, 0.85)',
    'antd-lt-colorBorder': '#d9d9d9',
    'antd-lt-colorBorderSecondary': '#f0f0f0',
    'antd-lt-colorPrimaryBg': '#f0f5ff',
    'antd-lt-colorPrimaryBgHover': '#e8eefa',
    'antd-lt-colorPrimaryBorder': '#b7c7ed',
    'antd-lt-colorPrimaryBorderHover': '#89a0e0',
    'antd-lt-colorPrimaryHover': '#5f7ad4',
    'antd-lt-colorPrimaryActive': '#273ba1',
    'antd-lt-colorPrimaryTextHover': '#5f7ad4',
    'antd-lt-colorPrimaryText': '#3a56c7',
    'antd-lt-colorPrimaryTextActive': '#273ba1',
    'antd-lt-colorSuccessBg': '#f6ffed',
    'antd-lt-colorSuccessBgHover': '#d9f7be',
    'antd-lt-colorSuccessBorder': '#b7eb8f',
    'antd-lt-colorSuccessBorderHover': '#95de64',
    'antd-lt-colorSuccessHover': '#95de64',
    'antd-lt-colorSuccessActive': '#389e0d',
    'antd-lt-colorSuccessTextHover': '#73d13d',
    'antd-lt-colorSuccessText': '#52c41a',
    'antd-lt-colorSuccessTextActive': '#389e0d',
    'antd-lt-colorErrorBg': '#fff2f0',
    'antd-lt-colorErrorBgHover': '#fff1f0',
    'antd-lt-colorErrorBorder': '#ffccc7',
    'antd-lt-colorErrorBorderHover': '#ffa39e',
    'antd-lt-colorErrorHover': '#ff7875',
    'antd-lt-colorErrorActive': '#d9363e',
    'antd-lt-colorErrorTextHover': '#ff7875',
    'antd-lt-colorErrorText': '#ff4d4f',
    'antd-lt-colorErrorTextActive': '#d9363e',
    'antd-lt-colorWarningBg': '#fffbe6',
    'antd-lt-colorWarningBgHover': '#fff1b8',
    'antd-lt-colorWarningBorder': '#ffe58f',
    'antd-lt-colorWarningBorderHover': '#ffd666',
    'antd-lt-colorWarningHover': '#ffd666',
    'antd-lt-colorWarningActive': '#d48806',
    'antd-lt-colorWarningTextHover': '#ffc53d',
    'antd-lt-colorWarningText': '#faad14',
    'antd-lt-colorWarningTextActive': '#d48806',
    'antd-lt-colorInfoBg': '#e6f4ff',
    'antd-lt-colorInfoBgHover': '#bae0ff',
    'antd-lt-colorInfoBorder': '#91caff',
    'antd-lt-colorInfoBorderHover': '#69b1ff',
    'antd-lt-colorInfoHover': '#69b1ff',
    'antd-lt-colorInfoActive': '#0958d9',
    'antd-lt-colorInfoTextHover': '#4096ff',
    'antd-lt-colorInfoText': '#1677ff',
    'antd-lt-colorInfoTextActive': '#0958d9',
    'antd-lt-colorBgMask': 'rgba(0, 0, 0, 0.45)',
    'antd-lt-colorWhite': '#fff',
    'antd-lt-colorLink': '#1677ff',
    'antd-lt-colorLinkHover': '#69b1ff',
    'antd-lt-colorLinkActive': '#0958d9',
    'antd-lt-colorFillContent': 'rgba(0, 0, 0, 0.06)',
    'antd-lt-colorFillContentHover': 'rgba(0, 0, 0, 0.15)',
    'antd-lt-colorFillAlter': 'rgba(0, 0, 0, 0.02)',
    'antd-lt-colorBgContainerDisabled': 'rgba(0, 0, 0, 0.04)',
    'antd-lt-colorBorderBg': '#ffffff',
    'antd-lt-colorSplit': 'rgba(5, 5, 5, 0.06)',
    'antd-lt-colorTextPlaceholder': 'rgba(0, 0, 0, 0.25)',
    'antd-lt-colorTextDisabled': 'rgba(0, 0, 0, 0.25)',
    'antd-lt-colorTextHeading': 'rgba(0, 0, 0, 0.88)',
    'antd-lt-colorTextLabel': 'rgba(0, 0, 0, 0.65)',
    'antd-lt-colorTextDescription': 'rgba(0, 0, 0, 0.45)',
    'antd-lt-colorTextLightSolid': '#fff',
    'antd-lt-colorHighlight': '#ff4d4f',
    'antd-lt-colorBgTextHover': 'rgba(0, 0, 0, 0.06)',
    'antd-lt-colorBgTextActive': 'rgba(0, 0, 0, 0.15)',
    'antd-lt-colorIcon': 'rgba(0, 0, 0, 0.45)',
    'antd-lt-colorIconHover': 'rgba(0, 0, 0, 0.88)',
    'antd-lt-colorErrorOutline': 'rgba(255, 38, 5, 0.06)',
    'antd-lt-colorWarningOutline': 'rgba(255, 215, 5, 0.1)',
    'antd-lt-controlOutlineWidth': 2,
    'antd-lt-controlItemBgHover': 'rgba(0, 0, 0, 0.04)',
    'antd-lt-controlItemBgActive': '#f0f5ff',
    'antd-lt-controlItemBgActiveHover': '#e8eefa',
    'antd-lt-controlItemBgActiveDisabled': 'rgba(0, 0, 0, 0.15)',
    'antd-lt-controlTmpOutline': 'rgba(0, 0, 0, 0.02)',
    'antd-lt-controlOutline': 'rgba(5, 88, 255, 0.06)',
    'antd-dk-blue': '#1677ff',
    'antd-dk-purple': '#722ED1',
    'antd-dk-cyan': '#13C2C2',
    'antd-dk-green': '#52C41A',
    'antd-dk-magenta': '#EB2F96',
    'antd-dk-pink': '#eb2f96',
    'antd-dk-red': '#F5222D',
    'antd-dk-orange': '#FA8C16',
    'antd-dk-yellow': '#FADB14',
    'antd-dk-volcano': '#FA541C',
    'antd-dk-geekblue': '#2F54EB',
    'antd-dk-gold': '#FAAD14',
    'antd-dk-lime': '#A0D911',
    'antd-dk-colorPrimary': '#344cac',
    'antd-dk-colorSuccess': '#49aa19',
    'antd-dk-colorWarning': '#d89614',
    'antd-dk-colorError': '#dc4446',
    'antd-dk-colorInfo': '#1668dc',
    'antd-dk-colorTextBase': '#fff',
    'antd-dk-colorBgBase': '#161616',
    'antd-dk-blue-1': '#111a2c',
    'antd-dk-blue1': '#111a2c',
    'antd-dk-blue-2': '#112545',
    'antd-dk-blue2': '#112545',
    'antd-dk-blue-3': '#15325b',
    'antd-dk-blue3': '#15325b',
    'antd-dk-blue-4': '#15417e',
    'antd-dk-blue4': '#15417e',
    'antd-dk-blue-5': '#1554ad',
    'antd-dk-blue5': '#1554ad',
    'antd-dk-blue-6': '#1668dc',
    'antd-dk-blue6': '#1668dc',
    'antd-dk-blue-7': '#3c89e8',
    'antd-dk-blue7': '#3c89e8',
    'antd-dk-blue-8': '#65a9f3',
    'antd-dk-blue8': '#65a9f3',
    'antd-dk-blue-9': '#8dc5f8',
    'antd-dk-blue9': '#8dc5f8',
    'antd-dk-blue-10': '#b7dcfa',
    'antd-dk-blue10': '#b7dcfa',
    'antd-dk-purple-1': '#1a1325',
    'antd-dk-purple1': '#1a1325',
    'antd-dk-purple-2': '#24163a',
    'antd-dk-purple2': '#24163a',
    'antd-dk-purple-3': '#301c4d',
    'antd-dk-purple3': '#301c4d',
    'antd-dk-purple-4': '#3e2069',
    'antd-dk-purple4': '#3e2069',
    'antd-dk-purple-5': '#51258f',
    'antd-dk-purple5': '#51258f',
    'antd-dk-purple-6': '#642ab5',
    'antd-dk-purple6': '#642ab5',
    'antd-dk-purple-7': '#854eca',
    'antd-dk-purple7': '#854eca',
    'antd-dk-purple-8': '#ab7ae0',
    'antd-dk-purple8': '#ab7ae0',
    'antd-dk-purple-9': '#cda8f0',
    'antd-dk-purple9': '#cda8f0',
    'antd-dk-purple-10': '#ebd7fa',
    'antd-dk-purple10': '#ebd7fa',
    'antd-dk-cyan-1': '#112123',
    'antd-dk-cyan1': '#112123',
    'antd-dk-cyan-2': '#113536',
    'antd-dk-cyan2': '#113536',
    'antd-dk-cyan-3': '#144848',
    'antd-dk-cyan3': '#144848',
    'antd-dk-cyan-4': '#146262',
    'antd-dk-cyan4': '#146262',
    'antd-dk-cyan-5': '#138585',
    'antd-dk-cyan5': '#138585',
    'antd-dk-cyan-6': '#13a8a8',
    'antd-dk-cyan6': '#13a8a8',
    'antd-dk-cyan-7': '#33bcb7',
    'antd-dk-cyan7': '#33bcb7',
    'antd-dk-cyan-8': '#58d1c9',
    'antd-dk-cyan8': '#58d1c9',
    'antd-dk-cyan-9': '#84e2d8',
    'antd-dk-cyan9': '#84e2d8',
    'antd-dk-cyan-10': '#b2f1e8',
    'antd-dk-cyan10': '#b2f1e8',
    'antd-dk-green-1': '#162312',
    'antd-dk-green1': '#162312',
    'antd-dk-green-2': '#1d3712',
    'antd-dk-green2': '#1d3712',
    'antd-dk-green-3': '#274916',
    'antd-dk-green3': '#274916',
    'antd-dk-green-4': '#306317',
    'antd-dk-green4': '#306317',
    'antd-dk-green-5': '#3c8618',
    'antd-dk-green5': '#3c8618',
    'antd-dk-green-6': '#49aa19',
    'antd-dk-green6': '#49aa19',
    'antd-dk-green-7': '#6abe39',
    'antd-dk-green7': '#6abe39',
    'antd-dk-green-8': '#8fd460',
    'antd-dk-green8': '#8fd460',
    'antd-dk-green-9': '#b2e58b',
    'antd-dk-green9': '#b2e58b',
    'antd-dk-green-10': '#d5f2bb',
    'antd-dk-green10': '#d5f2bb',
    'antd-dk-magenta-1': '#291321',
    'antd-dk-magenta1': '#291321',
    'antd-dk-magenta-2': '#40162f',
    'antd-dk-magenta2': '#40162f',
    'antd-dk-magenta-3': '#551c3b',
    'antd-dk-magenta3': '#551c3b',
    'antd-dk-magenta-4': '#75204f',
    'antd-dk-magenta4': '#75204f',
    'antd-dk-magenta-5': '#a02669',
    'antd-dk-magenta5': '#a02669',
    'antd-dk-magenta-6': '#cb2b83',
    'antd-dk-magenta6': '#cb2b83',
    'antd-dk-magenta-7': '#e0529c',
    'antd-dk-magenta7': '#e0529c',
    'antd-dk-magenta-8': '#f37fb7',
    'antd-dk-magenta8': '#f37fb7',
    'antd-dk-magenta-9': '#f8a8cc',
    'antd-dk-magenta9': '#f8a8cc',
    'antd-dk-magenta-10': '#fad2e3',
    'antd-dk-magenta10': '#fad2e3',
    'antd-dk-pink-1': '#291321',
    'antd-dk-pink1': '#291321',
    'antd-dk-pink-2': '#40162f',
    'antd-dk-pink2': '#40162f',
    'antd-dk-pink-3': '#551c3b',
    'antd-dk-pink3': '#551c3b',
    'antd-dk-pink-4': '#75204f',
    'antd-dk-pink4': '#75204f',
    'antd-dk-pink-5': '#a02669',
    'antd-dk-pink5': '#a02669',
    'antd-dk-pink-6': '#cb2b83',
    'antd-dk-pink6': '#cb2b83',
    'antd-dk-pink-7': '#e0529c',
    'antd-dk-pink7': '#e0529c',
    'antd-dk-pink-8': '#f37fb7',
    'antd-dk-pink8': '#f37fb7',
    'antd-dk-pink-9': '#f8a8cc',
    'antd-dk-pink9': '#f8a8cc',
    'antd-dk-pink-10': '#fad2e3',
    'antd-dk-pink10': '#fad2e3',
    'antd-dk-red-1': '#2a1215',
    'antd-dk-red1': '#2a1215',
    'antd-dk-red-2': '#431418',
    'antd-dk-red2': '#431418',
    'antd-dk-red-3': '#58181c',
    'antd-dk-red3': '#58181c',
    'antd-dk-red-4': '#791a1f',
    'antd-dk-red4': '#791a1f',
    'antd-dk-red-5': '#a61d24',
    'antd-dk-red5': '#a61d24',
    'antd-dk-red-6': '#d32029',
    'antd-dk-red6': '#d32029',
    'antd-dk-red-7': '#e84749',
    'antd-dk-red7': '#e84749',
    'antd-dk-red-8': '#f37370',
    'antd-dk-red8': '#f37370',
    'antd-dk-red-9': '#f89f9a',
    'antd-dk-red9': '#f89f9a',
    'antd-dk-red-10': '#fac8c3',
    'antd-dk-red10': '#fac8c3',
    'antd-dk-orange-1': '#2b1d11',
    'antd-dk-orange1': '#2b1d11',
    'antd-dk-orange-2': '#442a11',
    'antd-dk-orange2': '#442a11',
    'antd-dk-orange-3': '#593815',
    'antd-dk-orange3': '#593815',
    'antd-dk-orange-4': '#7c4a15',
    'antd-dk-orange4': '#7c4a15',
    'antd-dk-orange-5': '#aa6215',
    'antd-dk-orange5': '#aa6215',
    'antd-dk-orange-6': '#d87a16',
    'antd-dk-orange6': '#d87a16',
    'antd-dk-orange-7': '#e89a3c',
    'antd-dk-orange7': '#e89a3c',
    'antd-dk-orange-8': '#f3b765',
    'antd-dk-orange8': '#f3b765',
    'antd-dk-orange-9': '#f8cf8d',
    'antd-dk-orange9': '#f8cf8d',
    'antd-dk-orange-10': '#fae3b7',
    'antd-dk-orange10': '#fae3b7',
    'antd-dk-yellow-1': '#2b2611',
    'antd-dk-yellow1': '#2b2611',
    'antd-dk-yellow-2': '#443b11',
    'antd-dk-yellow2': '#443b11',
    'antd-dk-yellow-3': '#595014',
    'antd-dk-yellow3': '#595014',
    'antd-dk-yellow-4': '#7c6e14',
    'antd-dk-yellow4': '#7c6e14',
    'antd-dk-yellow-5': '#aa9514',
    'antd-dk-yellow5': '#aa9514',
    'antd-dk-yellow-6': '#d8bd14',
    'antd-dk-yellow6': '#d8bd14',
    'antd-dk-yellow-7': '#e8d639',
    'antd-dk-yellow7': '#e8d639',
    'antd-dk-yellow-8': '#f3ea62',
    'antd-dk-yellow8': '#f3ea62',
    'antd-dk-yellow-9': '#f8f48b',
    'antd-dk-yellow9': '#f8f48b',
    'antd-dk-yellow-10': '#fafab5',
    'antd-dk-yellow10': '#fafab5',
    'antd-dk-volcano-1': '#2b1611',
    'antd-dk-volcano1': '#2b1611',
    'antd-dk-volcano-2': '#441d12',
    'antd-dk-volcano2': '#441d12',
    'antd-dk-volcano-3': '#592716',
    'antd-dk-volcano3': '#592716',
    'antd-dk-volcano-4': '#7c3118',
    'antd-dk-volcano4': '#7c3118',
    'antd-dk-volcano-5': '#aa3e19',
    'antd-dk-volcano5': '#aa3e19',
    'antd-dk-volcano-6': '#d84a1b',
    'antd-dk-volcano6': '#d84a1b',
    'antd-dk-volcano-7': '#e87040',
    'antd-dk-volcano7': '#e87040',
    'antd-dk-volcano-8': '#f3956a',
    'antd-dk-volcano8': '#f3956a',
    'antd-dk-volcano-9': '#f8b692',
    'antd-dk-volcano9': '#f8b692',
    'antd-dk-volcano-10': '#fad4bc',
    'antd-dk-volcano10': '#fad4bc',
    'antd-dk-geekblue-1': '#131629',
    'antd-dk-geekblue1': '#131629',
    'antd-dk-geekblue-2': '#161d40',
    'antd-dk-geekblue2': '#161d40',
    'antd-dk-geekblue-3': '#1c2755',
    'antd-dk-geekblue3': '#1c2755',
    'antd-dk-geekblue-4': '#203175',
    'antd-dk-geekblue4': '#203175',
    'antd-dk-geekblue-5': '#263ea0',
    'antd-dk-geekblue5': '#263ea0',
    'antd-dk-geekblue-6': '#2b4acb',
    'antd-dk-geekblue6': '#2b4acb',
    'antd-dk-geekblue-7': '#5273e0',
    'antd-dk-geekblue7': '#5273e0',
    'antd-dk-geekblue-8': '#7f9ef3',
    'antd-dk-geekblue8': '#7f9ef3',
    'antd-dk-geekblue-9': '#a8c1f8',
    'antd-dk-geekblue9': '#a8c1f8',
    'antd-dk-geekblue-10': '#d2e0fa',
    'antd-dk-geekblue10': '#d2e0fa',
    'antd-dk-gold-1': '#2b2111',
    'antd-dk-gold1': '#2b2111',
    'antd-dk-gold-2': '#443111',
    'antd-dk-gold2': '#443111',
    'antd-dk-gold-3': '#594214',
    'antd-dk-gold3': '#594214',
    'antd-dk-gold-4': '#7c5914',
    'antd-dk-gold4': '#7c5914',
    'antd-dk-gold-5': '#aa7714',
    'antd-dk-gold5': '#aa7714',
    'antd-dk-gold-6': '#d89614',
    'antd-dk-gold6': '#d89614',
    'antd-dk-gold-7': '#e8b339',
    'antd-dk-gold7': '#e8b339',
    'antd-dk-gold-8': '#f3cc62',
    'antd-dk-gold8': '#f3cc62',
    'antd-dk-gold-9': '#f8df8b',
    'antd-dk-gold9': '#f8df8b',
    'antd-dk-gold-10': '#faedb5',
    'antd-dk-gold10': '#faedb5',
    'antd-dk-lime-1': '#1f2611',
    'antd-dk-lime1': '#1f2611',
    'antd-dk-lime-2': '#2e3c10',
    'antd-dk-lime2': '#2e3c10',
    'antd-dk-lime-3': '#3e4f13',
    'antd-dk-lime3': '#3e4f13',
    'antd-dk-lime-4': '#536d13',
    'antd-dk-lime4': '#536d13',
    'antd-dk-lime-5': '#6f9412',
    'antd-dk-lime5': '#6f9412',
    'antd-dk-lime-6': '#8bbb11',
    'antd-dk-lime6': '#8bbb11',
    'antd-dk-lime-7': '#a9d134',
    'antd-dk-lime7': '#a9d134',
    'antd-dk-lime-8': '#c9e75d',
    'antd-dk-lime8': '#c9e75d',
    'antd-dk-lime-9': '#e4f88b',
    'antd-dk-lime9': '#e4f88b',
    'antd-dk-lime-10': '#f0fab5',
    'antd-dk-lime10': '#f0fab5',
    'antd-dk-colorText': 'rgba(255, 255, 255, 0.85)',
    'antd-dk-colorTextSecondary': 'rgba(255, 255, 255, 0.65)',
    'antd-dk-colorTextTertiary': 'rgba(255, 255, 255, 0.45)',
    'antd-dk-colorTextQuaternary': 'rgba(255, 255, 255, 0.25)',
    'antd-dk-colorFill': 'rgba(255, 255, 255, 0.18)',
    'antd-dk-colorFillSecondary': 'rgba(255, 255, 255, 0.12)',
    'antd-dk-colorFillTertiary': 'rgba(255, 255, 255, 0.08)',
    'antd-dk-colorFillQuaternary': 'rgba(255, 255, 255, 0.04)',
    'antd-dk-colorBgLayout': '#161616',
    'antd-dk-colorBgContainer': '#2a2a2a',
    'antd-dk-colorBgElevated': '#353535',
    'antd-dk-colorBgSpotlight': '#585858',
    'antd-dk-colorBorder': '#585858',
    'antd-dk-colorBorderSecondary': '#464646',
    'antd-dk-colorPrimaryBg': '#141623',
    'antd-dk-colorPrimaryBgHover': '#191e37',
    'antd-dk-colorPrimaryBorder': '#1f284a',
    'antd-dk-colorPrimaryBorderHover': '#253265',
    'antd-dk-colorPrimaryHover': '#5870c1',
    'antd-dk-colorPrimaryActive': '#2d3f88',
    'antd-dk-colorPrimaryTextHover': '#5870c1',
    'antd-dk-colorPrimaryText': '#344cac',
    'antd-dk-colorPrimaryTextActive': '#2d3f88',
    'antd-dk-colorSuccessBg': '#162312',
    'antd-dk-colorSuccessBgHover': '#1d3712',
    'antd-dk-colorSuccessBorder': '#274916',
    'antd-dk-colorSuccessBorderHover': '#306317',
    'antd-dk-colorSuccessHover': '#306317',
    'antd-dk-colorSuccessActive': '#3c8618',
    'antd-dk-colorSuccessTextHover': '#6abe39',
    'antd-dk-colorSuccessText': '#49aa19',
    'antd-dk-colorSuccessTextActive': '#3c8618',
    'antd-dk-colorErrorBg': '#2c1618',
    'antd-dk-colorErrorBgHover': '#451d1f',
    'antd-dk-colorErrorBorder': '#5b2526',
    'antd-dk-colorErrorBorderHover': '#7e2e2f',
    'antd-dk-colorErrorHover': '#e86e6b',
    'antd-dk-colorErrorActive': '#ad393a',
    'antd-dk-colorErrorTextHover': '#e86e6b',
    'antd-dk-colorErrorText': '#dc4446',
    'antd-dk-colorErrorTextActive': '#ad393a',
    'antd-dk-colorWarningBg': '#2b2111',
    'antd-dk-colorWarningBgHover': '#443111',
    'antd-dk-colorWarningBorder': '#594214',
    'antd-dk-colorWarningBorderHover': '#7c5914',
    'antd-dk-colorWarningHover': '#7c5914',
    'antd-dk-colorWarningActive': '#aa7714',
    'antd-dk-colorWarningTextHover': '#e8b339',
    'antd-dk-colorWarningText': '#d89614',
    'antd-dk-colorWarningTextActive': '#aa7714',
    'antd-dk-colorInfoBg': '#111a2c',
    'antd-dk-colorInfoBgHover': '#112545',
    'antd-dk-colorInfoBorder': '#15325b',
    'antd-dk-colorInfoBorderHover': '#15417e',
    'antd-dk-colorInfoHover': '#15417e',
    'antd-dk-colorInfoActive': '#1554ad',
    'antd-dk-colorInfoTextHover': '#3c89e8',
    'antd-dk-colorInfoText': '#1668dc',
    'antd-dk-colorInfoTextActive': '#1554ad',
    'antd-dk-colorBgMask': 'rgba(0, 0, 0, 0.45)',
    'antd-dk-colorWhite': '#fff',
    'antd-dk-colorLink': '#1668dc',
    'antd-dk-colorLinkHover': '#15417e',
    'antd-dk-colorLinkActive': '#1554ad',
    'antd-dk-colorFillContent': 'rgba(255, 255, 255, 0.12)',
    'antd-dk-colorFillContentHover': 'rgba(255, 255, 255, 0.18)',
    'antd-dk-colorFillAlter': 'rgba(255, 255, 255, 0.04)',
    'antd-dk-colorBgContainerDisabled': 'rgba(255, 255, 255, 0.08)',
    'antd-dk-colorBorderBg': '#2a2a2a',
    'antd-dk-colorSplit': 'rgba(242, 242, 242, 0.14)',
    'antd-dk-colorTextPlaceholder': 'rgba(255, 255, 255, 0.25)',
    'antd-dk-colorTextDisabled': 'rgba(255, 255, 255, 0.25)',
    'antd-dk-colorTextHeading': 'rgba(255, 255, 255, 0.85)',
    'antd-dk-colorTextLabel': 'rgba(255, 255, 255, 0.65)',
    'antd-dk-colorTextDescription': 'rgba(255, 255, 255, 0.45)',
    'antd-dk-colorTextLightSolid': '#fff',
    'antd-dk-colorHighlight': '#dc4446',
    'antd-dk-colorBgTextHover': 'rgba(255, 255, 255, 0.12)',
    'antd-dk-colorBgTextActive': 'rgba(255, 255, 255, 0.18)',
    'antd-dk-colorIcon': 'rgba(255, 255, 255, 0.45)',
    'antd-dk-colorIconHover': 'rgba(255, 255, 255, 0.85)',
    'antd-dk-colorErrorOutline': 'rgba(46, 0, 5, 0.48)',
    'antd-dk-colorWarningOutline': 'rgba(44, 27, 0, 0.59)',
    'antd-dk-controlOutlineWidth': 2,
    'antd-dk-controlItemBgHover': 'rgba(255, 255, 255, 0.08)',
    'antd-dk-controlItemBgActive': '#141623',
    'antd-dk-controlItemBgActiveHover': '#191e37',
    'antd-dk-controlItemBgActiveDisabled': 'rgba(255, 255, 255, 0.18)',
    'antd-dk-controlTmpOutline': 'rgba(255, 255, 255, 0.04)',
    'antd-dk-controlOutline': 'rgba(0, 4, 29, 0.52)',
  },
  tokens: {
    light: {
      colors: {
        blue: '#1677ff',
        purple: '#722ED1',
        cyan: '#13C2C2',
        green: '#52C41A',
        magenta: '#EB2F96',
        pink: '#eb2f96',
        red: '#F5222D',
        orange: '#FA8C16',
        yellow: '#FADB14',
        volcano: '#FA541C',
        geekblue: '#2F54EB',
        gold: '#FAAD14',
        lime: '#A0D911',
        colorPrimary: '#3a56c7',
        colorSuccess: '#52c41a',
        colorWarning: '#faad14',
        colorError: '#ff4d4f',
        colorInfo: '#1677ff',
        colorTextBase: '#000',
        colorBgBase: '#fff',
        'blue-1': '#e6f4ff',
        blue1: '#e6f4ff',
        'blue-2': '#bae0ff',
        blue2: '#bae0ff',
        'blue-3': '#91caff',
        blue3: '#91caff',
        'blue-4': '#69b1ff',
        blue4: '#69b1ff',
        'blue-5': '#4096ff',
        blue5: '#4096ff',
        'blue-6': '#1677ff',
        blue6: '#1677ff',
        'blue-7': '#0958d9',
        blue7: '#0958d9',
        'blue-8': '#003eb3',
        blue8: '#003eb3',
        'blue-9': '#002c8c',
        blue9: '#002c8c',
        'blue-10': '#001d66',
        blue10: '#001d66',
        'purple-1': '#f9f0ff',
        purple1: '#f9f0ff',
        'purple-2': '#efdbff',
        purple2: '#efdbff',
        'purple-3': '#d3adf7',
        purple3: '#d3adf7',
        'purple-4': '#b37feb',
        purple4: '#b37feb',
        'purple-5': '#9254de',
        purple5: '#9254de',
        'purple-6': '#722ed1',
        purple6: '#722ed1',
        'purple-7': '#531dab',
        purple7: '#531dab',
        'purple-8': '#391085',
        purple8: '#391085',
        'purple-9': '#22075e',
        purple9: '#22075e',
        'purple-10': '#120338',
        purple10: '#120338',
        'cyan-1': '#e6fffb',
        cyan1: '#e6fffb',
        'cyan-2': '#b5f5ec',
        cyan2: '#b5f5ec',
        'cyan-3': '#87e8de',
        cyan3: '#87e8de',
        'cyan-4': '#5cdbd3',
        cyan4: '#5cdbd3',
        'cyan-5': '#36cfc9',
        cyan5: '#36cfc9',
        'cyan-6': '#13c2c2',
        cyan6: '#13c2c2',
        'cyan-7': '#08979c',
        cyan7: '#08979c',
        'cyan-8': '#006d75',
        cyan8: '#006d75',
        'cyan-9': '#00474f',
        cyan9: '#00474f',
        'cyan-10': '#002329',
        cyan10: '#002329',
        'green-1': '#f6ffed',
        green1: '#f6ffed',
        'green-2': '#d9f7be',
        green2: '#d9f7be',
        'green-3': '#b7eb8f',
        green3: '#b7eb8f',
        'green-4': '#95de64',
        green4: '#95de64',
        'green-5': '#73d13d',
        green5: '#73d13d',
        'green-6': '#52c41a',
        green6: '#52c41a',
        'green-7': '#389e0d',
        green7: '#389e0d',
        'green-8': '#237804',
        green8: '#237804',
        'green-9': '#135200',
        green9: '#135200',
        'green-10': '#092b00',
        green10: '#092b00',
        'magenta-1': '#fff0f6',
        magenta1: '#fff0f6',
        'magenta-2': '#ffd6e7',
        magenta2: '#ffd6e7',
        'magenta-3': '#ffadd2',
        magenta3: '#ffadd2',
        'magenta-4': '#ff85c0',
        magenta4: '#ff85c0',
        'magenta-5': '#f759ab',
        magenta5: '#f759ab',
        'magenta-6': '#eb2f96',
        magenta6: '#eb2f96',
        'magenta-7': '#c41d7f',
        magenta7: '#c41d7f',
        'magenta-8': '#9e1068',
        magenta8: '#9e1068',
        'magenta-9': '#780650',
        magenta9: '#780650',
        'magenta-10': '#520339',
        magenta10: '#520339',
        'pink-1': '#fff0f6',
        pink1: '#fff0f6',
        'pink-2': '#ffd6e7',
        pink2: '#ffd6e7',
        'pink-3': '#ffadd2',
        pink3: '#ffadd2',
        'pink-4': '#ff85c0',
        pink4: '#ff85c0',
        'pink-5': '#f759ab',
        pink5: '#f759ab',
        'pink-6': '#eb2f96',
        pink6: '#eb2f96',
        'pink-7': '#c41d7f',
        pink7: '#c41d7f',
        'pink-8': '#9e1068',
        pink8: '#9e1068',
        'pink-9': '#780650',
        pink9: '#780650',
        'pink-10': '#520339',
        pink10: '#520339',
        'red-1': '#fff1f0',
        red1: '#fff1f0',
        'red-2': '#ffccc7',
        red2: '#ffccc7',
        'red-3': '#ffa39e',
        red3: '#ffa39e',
        'red-4': '#ff7875',
        red4: '#ff7875',
        'red-5': '#ff4d4f',
        red5: '#ff4d4f',
        'red-6': '#f5222d',
        red6: '#f5222d',
        'red-7': '#cf1322',
        red7: '#cf1322',
        'red-8': '#a8071a',
        red8: '#a8071a',
        'red-9': '#820014',
        red9: '#820014',
        'red-10': '#5c0011',
        red10: '#5c0011',
        'orange-1': '#fff7e6',
        orange1: '#fff7e6',
        'orange-2': '#ffe7ba',
        orange2: '#ffe7ba',
        'orange-3': '#ffd591',
        orange3: '#ffd591',
        'orange-4': '#ffc069',
        orange4: '#ffc069',
        'orange-5': '#ffa940',
        orange5: '#ffa940',
        'orange-6': '#fa8c16',
        orange6: '#fa8c16',
        'orange-7': '#d46b08',
        orange7: '#d46b08',
        'orange-8': '#ad4e00',
        orange8: '#ad4e00',
        'orange-9': '#873800',
        orange9: '#873800',
        'orange-10': '#612500',
        orange10: '#612500',
        'yellow-1': '#feffe6',
        yellow1: '#feffe6',
        'yellow-2': '#ffffb8',
        yellow2: '#ffffb8',
        'yellow-3': '#fffb8f',
        yellow3: '#fffb8f',
        'yellow-4': '#fff566',
        yellow4: '#fff566',
        'yellow-5': '#ffec3d',
        yellow5: '#ffec3d',
        'yellow-6': '#fadb14',
        yellow6: '#fadb14',
        'yellow-7': '#d4b106',
        yellow7: '#d4b106',
        'yellow-8': '#ad8b00',
        yellow8: '#ad8b00',
        'yellow-9': '#876800',
        yellow9: '#876800',
        'yellow-10': '#614700',
        yellow10: '#614700',
        'volcano-1': '#fff2e8',
        volcano1: '#fff2e8',
        'volcano-2': '#ffd8bf',
        volcano2: '#ffd8bf',
        'volcano-3': '#ffbb96',
        volcano3: '#ffbb96',
        'volcano-4': '#ff9c6e',
        volcano4: '#ff9c6e',
        'volcano-5': '#ff7a45',
        volcano5: '#ff7a45',
        'volcano-6': '#fa541c',
        volcano6: '#fa541c',
        'volcano-7': '#d4380d',
        volcano7: '#d4380d',
        'volcano-8': '#ad2102',
        volcano8: '#ad2102',
        'volcano-9': '#871400',
        volcano9: '#871400',
        'volcano-10': '#610b00',
        volcano10: '#610b00',
        'geekblue-1': '#f0f5ff',
        geekblue1: '#f0f5ff',
        'geekblue-2': '#d6e4ff',
        geekblue2: '#d6e4ff',
        'geekblue-3': '#adc6ff',
        geekblue3: '#adc6ff',
        'geekblue-4': '#85a5ff',
        geekblue4: '#85a5ff',
        'geekblue-5': '#597ef7',
        geekblue5: '#597ef7',
        'geekblue-6': '#2f54eb',
        geekblue6: '#2f54eb',
        'geekblue-7': '#1d39c4',
        geekblue7: '#1d39c4',
        'geekblue-8': '#10239e',
        geekblue8: '#10239e',
        'geekblue-9': '#061178',
        geekblue9: '#061178',
        'geekblue-10': '#030852',
        geekblue10: '#030852',
        'gold-1': '#fffbe6',
        gold1: '#fffbe6',
        'gold-2': '#fff1b8',
        gold2: '#fff1b8',
        'gold-3': '#ffe58f',
        gold3: '#ffe58f',
        'gold-4': '#ffd666',
        gold4: '#ffd666',
        'gold-5': '#ffc53d',
        gold5: '#ffc53d',
        'gold-6': '#faad14',
        gold6: '#faad14',
        'gold-7': '#d48806',
        gold7: '#d48806',
        'gold-8': '#ad6800',
        gold8: '#ad6800',
        'gold-9': '#874d00',
        gold9: '#874d00',
        'gold-10': '#613400',
        gold10: '#613400',
        'lime-1': '#fcffe6',
        lime1: '#fcffe6',
        'lime-2': '#f4ffb8',
        lime2: '#f4ffb8',
        'lime-3': '#eaff8f',
        lime3: '#eaff8f',
        'lime-4': '#d3f261',
        lime4: '#d3f261',
        'lime-5': '#bae637',
        lime5: '#bae637',
        'lime-6': '#a0d911',
        lime6: '#a0d911',
        'lime-7': '#7cb305',
        lime7: '#7cb305',
        'lime-8': '#5b8c00',
        lime8: '#5b8c00',
        'lime-9': '#3f6600',
        lime9: '#3f6600',
        'lime-10': '#254000',
        lime10: '#254000',
        colorText: 'rgba(0, 0, 0, 0.88)',
        colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
        colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
        colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
        colorFill: 'rgba(0, 0, 0, 0.15)',
        colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
        colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
        colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
        colorBgLayout: '#f5f5f5',
        colorBgContainer: '#ffffff',
        colorBgElevated: '#ffffff',
        colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
        colorBorder: '#d9d9d9',
        colorBorderSecondary: '#f0f0f0',
        colorPrimaryBg: '#f0f5ff',
        colorPrimaryBgHover: '#e8eefa',
        colorPrimaryBorder: '#b7c7ed',
        colorPrimaryBorderHover: '#89a0e0',
        colorPrimaryHover: '#5f7ad4',
        colorPrimaryActive: '#273ba1',
        colorPrimaryTextHover: '#5f7ad4',
        colorPrimaryText: '#3a56c7',
        colorPrimaryTextActive: '#273ba1',
        colorSuccessBg: '#f6ffed',
        colorSuccessBgHover: '#d9f7be',
        colorSuccessBorder: '#b7eb8f',
        colorSuccessBorderHover: '#95de64',
        colorSuccessHover: '#95de64',
        colorSuccessActive: '#389e0d',
        colorSuccessTextHover: '#73d13d',
        colorSuccessText: '#52c41a',
        colorSuccessTextActive: '#389e0d',
        colorErrorBg: '#fff2f0',
        colorErrorBgHover: '#fff1f0',
        colorErrorBorder: '#ffccc7',
        colorErrorBorderHover: '#ffa39e',
        colorErrorHover: '#ff7875',
        colorErrorActive: '#d9363e',
        colorErrorTextHover: '#ff7875',
        colorErrorText: '#ff4d4f',
        colorErrorTextActive: '#d9363e',
        colorWarningBg: '#fffbe6',
        colorWarningBgHover: '#fff1b8',
        colorWarningBorder: '#ffe58f',
        colorWarningBorderHover: '#ffd666',
        colorWarningHover: '#ffd666',
        colorWarningActive: '#d48806',
        colorWarningTextHover: '#ffc53d',
        colorWarningText: '#faad14',
        colorWarningTextActive: '#d48806',
        colorInfoBg: '#e6f4ff',
        colorInfoBgHover: '#bae0ff',
        colorInfoBorder: '#91caff',
        colorInfoBorderHover: '#69b1ff',
        colorInfoHover: '#69b1ff',
        colorInfoActive: '#0958d9',
        colorInfoTextHover: '#4096ff',
        colorInfoText: '#1677ff',
        colorInfoTextActive: '#0958d9',
        colorBgMask: 'rgba(0, 0, 0, 0.45)',
        colorWhite: '#fff',
        colorLink: '#1677ff',
        colorLinkHover: '#69b1ff',
        colorLinkActive: '#0958d9',
        colorFillContent: 'rgba(0, 0, 0, 0.06)',
        colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
        colorFillAlter: 'rgba(0, 0, 0, 0.02)',
        colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
        colorBorderBg: '#ffffff',
        colorSplit: 'rgba(5, 5, 5, 0.06)',
        colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
        colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
        colorTextHeading: 'rgba(0, 0, 0, 0.88)',
        colorTextLabel: 'rgba(0, 0, 0, 0.65)',
        colorTextDescription: 'rgba(0, 0, 0, 0.45)',
        colorTextLightSolid: '#fff',
        colorHighlight: '#ff4d4f',
        colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
        colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
        colorIcon: 'rgba(0, 0, 0, 0.45)',
        colorIconHover: 'rgba(0, 0, 0, 0.88)',
        colorErrorOutline: 'rgba(255, 38, 5, 0.06)',
        colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
        controlOutlineWidth: 2,
        controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
        controlItemBgActive: '#f0f5ff',
        controlItemBgActiveHover: '#e8eefa',
        controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
        controlTmpOutline: 'rgba(0, 0, 0, 0.02)',
        controlOutline: 'rgba(5, 88, 255, 0.06)',
      },
      tokens: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
        fontFamilyCode:
          "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
        fontSize: 14,
        lineWidth: 1,
        lineType: 'solid',
        motionUnit: 0.1,
        motionBase: 0,
        motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
        motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
        motionEaseInBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
        motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
        motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
        borderRadius: 6,
        sizeUnit: 4,
        sizeStep: 4,
        sizePopupArrow: 16,
        controlHeight: 32,
        zIndexBase: 0,
        zIndexPopupBase: 1000,
        opacityImage: 1,
        wireframe: false,
        fontSizeSM: 12,
        fontSizeLG: 16,
        fontSizeXL: 20,
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 16,
        lineHeight: 1.5714285714285714,
        lineHeightLG: 1.5,
        lineHeightSM: 1.6666666666666667,
        lineHeightHeading1: 1.2105263157894737,
        lineHeightHeading2: 1.2666666666666666,
        lineHeightHeading3: 1.3333333333333333,
        lineHeightHeading4: 1.4,
        lineHeightHeading5: 1.5,
        sizeXXL: 48,
        sizeXL: 32,
        sizeLG: 24,
        sizeMD: 20,
        sizeMS: 16,
        size: 16,
        sizeSM: 12,
        sizeXS: 8,
        sizeXXS: 4,
        controlHeightSM: 24,
        controlHeightXS: 16,
        controlHeightLG: 40,
        motionDurationFast: '0.1s',
        motionDurationMid: '0.2s',
        motionDurationSlow: '0.3s',
        lineWidthBold: 2,
        borderRadiusXS: 2,
        borderRadiusSM: 4,
        borderRadiusLG: 8,
        borderRadiusOuter: 4,
        fontSizeIcon: 12,
        lineWidthFocus: 4,
        controlInteractiveSize: 16,
        fontWeightStrong: 600,
        opacityLoading: 0.65,
        linkDecoration: 'none',
        linkHoverDecoration: 'none',
        linkFocusDecoration: 'none',
        controlPaddingHorizontal: 12,
        controlPaddingHorizontalSM: 8,
        paddingXXS: 4,
        paddingXS: 8,
        paddingSM: 12,
        padding: 16,
        paddingMD: 20,
        paddingLG: 24,
        paddingXL: 32,
        paddingContentHorizontalLG: 24,
        paddingContentVerticalLG: 16,
        paddingContentHorizontal: 16,
        paddingContentVertical: 12,
        paddingContentHorizontalSM: 16,
        paddingContentVerticalSM: 8,
        marginXXS: 4,
        marginXS: 8,
        marginSM: 12,
        margin: 16,
        marginMD: 20,
        marginLG: 24,
        marginXL: 32,
        marginXXL: 48,
        boxShadow:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowSecondary:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowTertiary:
          '\n      0 1px 2px 0 rgba(0, 0, 0, 0.03),\n      0 1px 6px -1px rgba(0, 0, 0, 0.02),\n      0 2px 4px 0 rgba(0, 0, 0, 0.02)\n    ',
        screenXS: 480,
        screenXSMin: 480,
        screenXSMax: 575,
        screenSM: 576,
        screenSMMin: 576,
        screenSMMax: 767,
        screenMD: 768,
        screenMDMin: 768,
        screenMDMax: 991,
        screenLG: 992,
        screenLGMin: 992,
        screenLGMax: 1199,
        screenXL: 1200,
        screenXLMin: 1200,
        screenXLMax: 1599,
        screenXXL: 1600,
        screenXXLMin: 1600,
        boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)',
        boxShadowCard:
          '\n      0 1px 2px -2px rgba(0, 0, 0, 0.16),\n      0 3px 6px 0 rgba(0, 0, 0, 0.12),\n      0 5px 12px 4px rgba(0, 0, 0, 0.09)\n    ',
        boxShadowDrawerRight:
          '\n      -6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      -3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      -9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerLeft:
          '\n      6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerUp:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerDown:
          '\n      0 -6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 -3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 -9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowRight:
          'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowBottom:
          'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)',
        _tokenKey: 'nu5b6i',
        _hashId: 'css-dev-only-do-not-override-tbaoyj',
      },
    },
    dark: {
      colors: {
        blue: '#1677ff',
        purple: '#722ED1',
        cyan: '#13C2C2',
        green: '#52C41A',
        magenta: '#EB2F96',
        pink: '#eb2f96',
        red: '#F5222D',
        orange: '#FA8C16',
        yellow: '#FADB14',
        volcano: '#FA541C',
        geekblue: '#2F54EB',
        gold: '#FAAD14',
        lime: '#A0D911',
        colorPrimary: '#344cac',
        colorSuccess: '#49aa19',
        colorWarning: '#d89614',
        colorError: '#dc4446',
        colorInfo: '#1668dc',
        colorTextBase: '#fff',
        colorBgBase: '#161616',
        'blue-1': '#111a2c',
        blue1: '#111a2c',
        'blue-2': '#112545',
        blue2: '#112545',
        'blue-3': '#15325b',
        blue3: '#15325b',
        'blue-4': '#15417e',
        blue4: '#15417e',
        'blue-5': '#1554ad',
        blue5: '#1554ad',
        'blue-6': '#1668dc',
        blue6: '#1668dc',
        'blue-7': '#3c89e8',
        blue7: '#3c89e8',
        'blue-8': '#65a9f3',
        blue8: '#65a9f3',
        'blue-9': '#8dc5f8',
        blue9: '#8dc5f8',
        'blue-10': '#b7dcfa',
        blue10: '#b7dcfa',
        'purple-1': '#1a1325',
        purple1: '#1a1325',
        'purple-2': '#24163a',
        purple2: '#24163a',
        'purple-3': '#301c4d',
        purple3: '#301c4d',
        'purple-4': '#3e2069',
        purple4: '#3e2069',
        'purple-5': '#51258f',
        purple5: '#51258f',
        'purple-6': '#642ab5',
        purple6: '#642ab5',
        'purple-7': '#854eca',
        purple7: '#854eca',
        'purple-8': '#ab7ae0',
        purple8: '#ab7ae0',
        'purple-9': '#cda8f0',
        purple9: '#cda8f0',
        'purple-10': '#ebd7fa',
        purple10: '#ebd7fa',
        'cyan-1': '#112123',
        cyan1: '#112123',
        'cyan-2': '#113536',
        cyan2: '#113536',
        'cyan-3': '#144848',
        cyan3: '#144848',
        'cyan-4': '#146262',
        cyan4: '#146262',
        'cyan-5': '#138585',
        cyan5: '#138585',
        'cyan-6': '#13a8a8',
        cyan6: '#13a8a8',
        'cyan-7': '#33bcb7',
        cyan7: '#33bcb7',
        'cyan-8': '#58d1c9',
        cyan8: '#58d1c9',
        'cyan-9': '#84e2d8',
        cyan9: '#84e2d8',
        'cyan-10': '#b2f1e8',
        cyan10: '#b2f1e8',
        'green-1': '#162312',
        green1: '#162312',
        'green-2': '#1d3712',
        green2: '#1d3712',
        'green-3': '#274916',
        green3: '#274916',
        'green-4': '#306317',
        green4: '#306317',
        'green-5': '#3c8618',
        green5: '#3c8618',
        'green-6': '#49aa19',
        green6: '#49aa19',
        'green-7': '#6abe39',
        green7: '#6abe39',
        'green-8': '#8fd460',
        green8: '#8fd460',
        'green-9': '#b2e58b',
        green9: '#b2e58b',
        'green-10': '#d5f2bb',
        green10: '#d5f2bb',
        'magenta-1': '#291321',
        magenta1: '#291321',
        'magenta-2': '#40162f',
        magenta2: '#40162f',
        'magenta-3': '#551c3b',
        magenta3: '#551c3b',
        'magenta-4': '#75204f',
        magenta4: '#75204f',
        'magenta-5': '#a02669',
        magenta5: '#a02669',
        'magenta-6': '#cb2b83',
        magenta6: '#cb2b83',
        'magenta-7': '#e0529c',
        magenta7: '#e0529c',
        'magenta-8': '#f37fb7',
        magenta8: '#f37fb7',
        'magenta-9': '#f8a8cc',
        magenta9: '#f8a8cc',
        'magenta-10': '#fad2e3',
        magenta10: '#fad2e3',
        'pink-1': '#291321',
        pink1: '#291321',
        'pink-2': '#40162f',
        pink2: '#40162f',
        'pink-3': '#551c3b',
        pink3: '#551c3b',
        'pink-4': '#75204f',
        pink4: '#75204f',
        'pink-5': '#a02669',
        pink5: '#a02669',
        'pink-6': '#cb2b83',
        pink6: '#cb2b83',
        'pink-7': '#e0529c',
        pink7: '#e0529c',
        'pink-8': '#f37fb7',
        pink8: '#f37fb7',
        'pink-9': '#f8a8cc',
        pink9: '#f8a8cc',
        'pink-10': '#fad2e3',
        pink10: '#fad2e3',
        'red-1': '#2a1215',
        red1: '#2a1215',
        'red-2': '#431418',
        red2: '#431418',
        'red-3': '#58181c',
        red3: '#58181c',
        'red-4': '#791a1f',
        red4: '#791a1f',
        'red-5': '#a61d24',
        red5: '#a61d24',
        'red-6': '#d32029',
        red6: '#d32029',
        'red-7': '#e84749',
        red7: '#e84749',
        'red-8': '#f37370',
        red8: '#f37370',
        'red-9': '#f89f9a',
        red9: '#f89f9a',
        'red-10': '#fac8c3',
        red10: '#fac8c3',
        'orange-1': '#2b1d11',
        orange1: '#2b1d11',
        'orange-2': '#442a11',
        orange2: '#442a11',
        'orange-3': '#593815',
        orange3: '#593815',
        'orange-4': '#7c4a15',
        orange4: '#7c4a15',
        'orange-5': '#aa6215',
        orange5: '#aa6215',
        'orange-6': '#d87a16',
        orange6: '#d87a16',
        'orange-7': '#e89a3c',
        orange7: '#e89a3c',
        'orange-8': '#f3b765',
        orange8: '#f3b765',
        'orange-9': '#f8cf8d',
        orange9: '#f8cf8d',
        'orange-10': '#fae3b7',
        orange10: '#fae3b7',
        'yellow-1': '#2b2611',
        yellow1: '#2b2611',
        'yellow-2': '#443b11',
        yellow2: '#443b11',
        'yellow-3': '#595014',
        yellow3: '#595014',
        'yellow-4': '#7c6e14',
        yellow4: '#7c6e14',
        'yellow-5': '#aa9514',
        yellow5: '#aa9514',
        'yellow-6': '#d8bd14',
        yellow6: '#d8bd14',
        'yellow-7': '#e8d639',
        yellow7: '#e8d639',
        'yellow-8': '#f3ea62',
        yellow8: '#f3ea62',
        'yellow-9': '#f8f48b',
        yellow9: '#f8f48b',
        'yellow-10': '#fafab5',
        yellow10: '#fafab5',
        'volcano-1': '#2b1611',
        volcano1: '#2b1611',
        'volcano-2': '#441d12',
        volcano2: '#441d12',
        'volcano-3': '#592716',
        volcano3: '#592716',
        'volcano-4': '#7c3118',
        volcano4: '#7c3118',
        'volcano-5': '#aa3e19',
        volcano5: '#aa3e19',
        'volcano-6': '#d84a1b',
        volcano6: '#d84a1b',
        'volcano-7': '#e87040',
        volcano7: '#e87040',
        'volcano-8': '#f3956a',
        volcano8: '#f3956a',
        'volcano-9': '#f8b692',
        volcano9: '#f8b692',
        'volcano-10': '#fad4bc',
        volcano10: '#fad4bc',
        'geekblue-1': '#131629',
        geekblue1: '#131629',
        'geekblue-2': '#161d40',
        geekblue2: '#161d40',
        'geekblue-3': '#1c2755',
        geekblue3: '#1c2755',
        'geekblue-4': '#203175',
        geekblue4: '#203175',
        'geekblue-5': '#263ea0',
        geekblue5: '#263ea0',
        'geekblue-6': '#2b4acb',
        geekblue6: '#2b4acb',
        'geekblue-7': '#5273e0',
        geekblue7: '#5273e0',
        'geekblue-8': '#7f9ef3',
        geekblue8: '#7f9ef3',
        'geekblue-9': '#a8c1f8',
        geekblue9: '#a8c1f8',
        'geekblue-10': '#d2e0fa',
        geekblue10: '#d2e0fa',
        'gold-1': '#2b2111',
        gold1: '#2b2111',
        'gold-2': '#443111',
        gold2: '#443111',
        'gold-3': '#594214',
        gold3: '#594214',
        'gold-4': '#7c5914',
        gold4: '#7c5914',
        'gold-5': '#aa7714',
        gold5: '#aa7714',
        'gold-6': '#d89614',
        gold6: '#d89614',
        'gold-7': '#e8b339',
        gold7: '#e8b339',
        'gold-8': '#f3cc62',
        gold8: '#f3cc62',
        'gold-9': '#f8df8b',
        gold9: '#f8df8b',
        'gold-10': '#faedb5',
        gold10: '#faedb5',
        'lime-1': '#1f2611',
        lime1: '#1f2611',
        'lime-2': '#2e3c10',
        lime2: '#2e3c10',
        'lime-3': '#3e4f13',
        lime3: '#3e4f13',
        'lime-4': '#536d13',
        lime4: '#536d13',
        'lime-5': '#6f9412',
        lime5: '#6f9412',
        'lime-6': '#8bbb11',
        lime6: '#8bbb11',
        'lime-7': '#a9d134',
        lime7: '#a9d134',
        'lime-8': '#c9e75d',
        lime8: '#c9e75d',
        'lime-9': '#e4f88b',
        lime9: '#e4f88b',
        'lime-10': '#f0fab5',
        lime10: '#f0fab5',
        colorText: 'rgba(255, 255, 255, 0.85)',
        colorTextSecondary: 'rgba(255, 255, 255, 0.65)',
        colorTextTertiary: 'rgba(255, 255, 255, 0.45)',
        colorTextQuaternary: 'rgba(255, 255, 255, 0.25)',
        colorFill: 'rgba(255, 255, 255, 0.18)',
        colorFillSecondary: 'rgba(255, 255, 255, 0.12)',
        colorFillTertiary: 'rgba(255, 255, 255, 0.08)',
        colorFillQuaternary: 'rgba(255, 255, 255, 0.04)',
        colorBgLayout: '#161616',
        colorBgContainer: '#2a2a2a',
        colorBgElevated: '#353535',
        colorBgSpotlight: '#585858',
        colorBorder: '#585858',
        colorBorderSecondary: '#464646',
        colorPrimaryBg: '#141623',
        colorPrimaryBgHover: '#191e37',
        colorPrimaryBorder: '#1f284a',
        colorPrimaryBorderHover: '#253265',
        colorPrimaryHover: '#5870c1',
        colorPrimaryActive: '#2d3f88',
        colorPrimaryTextHover: '#5870c1',
        colorPrimaryText: '#344cac',
        colorPrimaryTextActive: '#2d3f88',
        colorSuccessBg: '#162312',
        colorSuccessBgHover: '#1d3712',
        colorSuccessBorder: '#274916',
        colorSuccessBorderHover: '#306317',
        colorSuccessHover: '#306317',
        colorSuccessActive: '#3c8618',
        colorSuccessTextHover: '#6abe39',
        colorSuccessText: '#49aa19',
        colorSuccessTextActive: '#3c8618',
        colorErrorBg: '#2c1618',
        colorErrorBgHover: '#451d1f',
        colorErrorBorder: '#5b2526',
        colorErrorBorderHover: '#7e2e2f',
        colorErrorHover: '#e86e6b',
        colorErrorActive: '#ad393a',
        colorErrorTextHover: '#e86e6b',
        colorErrorText: '#dc4446',
        colorErrorTextActive: '#ad393a',
        colorWarningBg: '#2b2111',
        colorWarningBgHover: '#443111',
        colorWarningBorder: '#594214',
        colorWarningBorderHover: '#7c5914',
        colorWarningHover: '#7c5914',
        colorWarningActive: '#aa7714',
        colorWarningTextHover: '#e8b339',
        colorWarningText: '#d89614',
        colorWarningTextActive: '#aa7714',
        colorInfoBg: '#111a2c',
        colorInfoBgHover: '#112545',
        colorInfoBorder: '#15325b',
        colorInfoBorderHover: '#15417e',
        colorInfoHover: '#15417e',
        colorInfoActive: '#1554ad',
        colorInfoTextHover: '#3c89e8',
        colorInfoText: '#1668dc',
        colorInfoTextActive: '#1554ad',
        colorBgMask: 'rgba(0, 0, 0, 0.45)',
        colorWhite: '#fff',
        colorLink: '#1668dc',
        colorLinkHover: '#15417e',
        colorLinkActive: '#1554ad',
        colorFillContent: 'rgba(255, 255, 255, 0.12)',
        colorFillContentHover: 'rgba(255, 255, 255, 0.18)',
        colorFillAlter: 'rgba(255, 255, 255, 0.04)',
        colorBgContainerDisabled: 'rgba(255, 255, 255, 0.08)',
        colorBorderBg: '#2a2a2a',
        colorSplit: 'rgba(242, 242, 242, 0.14)',
        colorTextPlaceholder: 'rgba(255, 255, 255, 0.25)',
        colorTextDisabled: 'rgba(255, 255, 255, 0.25)',
        colorTextHeading: 'rgba(255, 255, 255, 0.85)',
        colorTextLabel: 'rgba(255, 255, 255, 0.65)',
        colorTextDescription: 'rgba(255, 255, 255, 0.45)',
        colorTextLightSolid: '#fff',
        colorHighlight: '#dc4446',
        colorBgTextHover: 'rgba(255, 255, 255, 0.12)',
        colorBgTextActive: 'rgba(255, 255, 255, 0.18)',
        colorIcon: 'rgba(255, 255, 255, 0.45)',
        colorIconHover: 'rgba(255, 255, 255, 0.85)',
        colorErrorOutline: 'rgba(46, 0, 5, 0.48)',
        colorWarningOutline: 'rgba(44, 27, 0, 0.59)',
        controlOutlineWidth: 2,
        controlItemBgHover: 'rgba(255, 255, 255, 0.08)',
        controlItemBgActive: '#141623',
        controlItemBgActiveHover: '#191e37',
        controlItemBgActiveDisabled: 'rgba(255, 255, 255, 0.18)',
        controlTmpOutline: 'rgba(255, 255, 255, 0.04)',
        controlOutline: 'rgba(0, 4, 29, 0.52)',
      },
      tokens: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
        fontFamilyCode:
          "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
        fontSize: 14,
        lineWidth: 1,
        lineType: 'solid',
        motionUnit: 0.1,
        motionBase: 0,
        motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
        motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
        motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
        motionEaseInBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
        motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
        motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
        borderRadius: 6,
        sizeUnit: 4,
        sizeStep: 4,
        sizePopupArrow: 16,
        controlHeight: 32,
        zIndexBase: 0,
        zIndexPopupBase: 1000,
        opacityImage: 1,
        wireframe: false,
        fontSizeSM: 12,
        fontSizeLG: 16,
        fontSizeXL: 20,
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 16,
        lineHeight: 1.5714285714285714,
        lineHeightLG: 1.5,
        lineHeightSM: 1.6666666666666667,
        lineHeightHeading1: 1.2105263157894737,
        lineHeightHeading2: 1.2666666666666666,
        lineHeightHeading3: 1.3333333333333333,
        lineHeightHeading4: 1.4,
        lineHeightHeading5: 1.5,
        sizeXXL: 48,
        sizeXL: 32,
        sizeLG: 24,
        sizeMD: 20,
        sizeMS: 16,
        size: 16,
        sizeSM: 12,
        sizeXS: 8,
        sizeXXS: 4,
        controlHeightSM: 24,
        controlHeightXS: 16,
        controlHeightLG: 40,
        motionDurationFast: '0.1s',
        motionDurationMid: '0.2s',
        motionDurationSlow: '0.3s',
        lineWidthBold: 2,
        borderRadiusXS: 2,
        borderRadiusSM: 4,
        borderRadiusLG: 8,
        borderRadiusOuter: 4,
        fontSizeIcon: 12,
        lineWidthFocus: 4,
        controlInteractiveSize: 16,
        fontWeightStrong: 600,
        opacityLoading: 0.65,
        linkDecoration: 'none',
        linkHoverDecoration: 'none',
        linkFocusDecoration: 'none',
        controlPaddingHorizontal: 12,
        controlPaddingHorizontalSM: 8,
        paddingXXS: 4,
        paddingXS: 8,
        paddingSM: 12,
        padding: 16,
        paddingMD: 20,
        paddingLG: 24,
        paddingXL: 32,
        paddingContentHorizontalLG: 24,
        paddingContentVerticalLG: 16,
        paddingContentHorizontal: 16,
        paddingContentVertical: 12,
        paddingContentHorizontalSM: 16,
        paddingContentVerticalSM: 8,
        marginXXS: 4,
        marginXS: 8,
        marginSM: 12,
        margin: 16,
        marginMD: 20,
        marginLG: 24,
        marginXL: 32,
        marginXXL: 48,
        boxShadow:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowSecondary:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowTertiary:
          '\n      0 1px 2px 0 rgba(0, 0, 0, 0.03),\n      0 1px 6px -1px rgba(0, 0, 0, 0.02),\n      0 2px 4px 0 rgba(0, 0, 0, 0.02)\n    ',
        screenXS: 480,
        screenXSMin: 480,
        screenXSMax: 575,
        screenSM: 576,
        screenSMMin: 576,
        screenSMMax: 767,
        screenMD: 768,
        screenMDMin: 768,
        screenMDMax: 991,
        screenLG: 992,
        screenLGMin: 992,
        screenLGMax: 1199,
        screenXL: 1200,
        screenXLMin: 1200,
        screenXLMax: 1599,
        screenXXL: 1600,
        screenXXLMin: 1600,
        boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)',
        boxShadowCard:
          '\n      0 1px 2px -2px rgba(0, 0, 0, 0.16),\n      0 3px 6px 0 rgba(0, 0, 0, 0.12),\n      0 5px 12px 4px rgba(0, 0, 0, 0.09)\n    ',
        boxShadowDrawerRight:
          '\n      -6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      -3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      -9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerLeft:
          '\n      6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerUp:
          '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowDrawerDown:
          '\n      0 -6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 -3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 -9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
        boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowRight:
          'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)',
        boxShadowTabsOverflowBottom:
          'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)',
        _tokenKey: '1yebm22',
        _hashId: 'css-dev-only-do-not-override-71nzkr',
      },
    },
  },
};
