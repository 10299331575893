import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';
import React, {
  ErrorInfo,
  FC,
  ReactNode,
  useCallback,
  WeakValidationMap,
} from 'react';
import { Alert } from 'antd';
import { useHistory } from 'react-router-dom';

const Fallback = (props: FallbackProps) => {
  const history = useHistory();

  // then add this to the function that is called for re-rendering

  const { error, resetErrorBoundary } = props;
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div tw="_m-8 _p-16">
      <Alert
        message={
          <span tw="_inline-block _align-top">
            Error loading requested resource
          </span>
        }
        showIcon
        description={
          <div tw="_m-4">
            <div tw="_p-2">
              Oops! Something went wrong...{' '}
              <a onClick={() => history.go(0)}>Reloading the page</a> may
              resolve the issue.
            </div>
            <div tw="_p-2">
              If you received a link to this page, you may not have access to
              the resource. Contact the sender for more information.
            </div>
            <div tw="_p-2">
              If you continue to experience issues accessing this resource,
              <br /> please let us know at:
              <a
                tw="_p-2"
                href="mailto:support@alaffiahealth.com"
                target="_top"
              >
                support@alaffiahealth.com
              </a>
            </div>
            <div tw="_p-2">Message: {error?.message}</div>
          </div>
        }
        type="error"
      />
    </div>
  );
};

export const ErrorBoundary: FC<{
  boundaryDescription: string;
  children: ReactNode;
}> = ({ boundaryDescription, children }) => {
  const logError = useCallback(
    (error: Error, info: ErrorInfo) => {
      console.log(`ErrorBoundary Error (@ ${boundaryDescription}:`, {
        boundaryDescription,
        error,
        info,
      });
      console.error(error);
    },
    [boundaryDescription]
  );
  return (
    <ReactErrorBoundary FallbackComponent={Fallback} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
};
