import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react';
import { MockGqlCtx, MocksDefinition } from './types';
import { stringToBool } from '../fn/env';
import { MockedRequest } from 'msw';
import { isMswServer, isMswWorker } from './util';
import { useLog } from '../../log/useLog';
// import { LogContext } from '../components/shared/providers/logProvider';

const ENV_USE_DEV_MOCKS = stringToBool(process.env.REACT_APP_USE_DEV_MOCKS);

export const MockContext = createContext<MockGqlCtx>(getDisabledCtx());

export type MockProviderProps = {
  children: ReactNode;
  enableMocks?: boolean;
  initialMocks?: MocksDefinition;
  onUnhandledRequest?: (request: MockedRequest) => void;
  asServer?: boolean;
};

export const MockProvider: FC<MockProviderProps> = ({
  children,
  enableMocks,
  initialMocks,
  onUnhandledRequest,
  asServer,
}) => {
  const USE_MOCKS = enableMocks ?? ENV_USE_DEV_MOCKS;

  const log = useLog('MockProvider');
  // log.setLevel(log.levels.DEBUG, false);

  const [ctx, setCtx] = useState<MockGqlCtx | null>(null);

  const importClientMockProviderLib = import('./clientMockProviderLib');
  const importServerMockProviderLib = import('./serverMockProviderLib');

  useEffect(() => {
    if (USE_MOCKS) {
      log.info('Loading mocks, USE_MOCKS: %s', USE_MOCKS);
      // import(asServer ? './serverMockProviderLib' : './clientMockProviderLib')
      (asServer ? importServerMockProviderLib : importClientMockProviderLib)
        .then(({ default: enableMocksFn }) => enableMocksFn)
        .then(enableMocksFn =>
          enableMocksFn({ initialMocks, onUnhandledRequest })
        )
        .then(__ctx => {
          log.trace('Mock context set', { ctx });
          setCtx(__ctx);
        });
    }
    return () => {
      if (ctx) {
        log.trace('Stopping mock worker...');
        if (isMswWorker(ctx.worker)) {
          ctx.worker.stop();
        }
        if (isMswServer(ctx.worker)) {
          ctx.worker.close();
        }
      }
    };
  }, []);

  if (USE_MOCKS) {
    return !ctx ? (
      <div>loading msw...</div>
    ) : (
      <div tw="_border _border-dashed _border-amber-400">
        <MockContext.Provider value={ctx}>{children}</MockContext.Provider>
      </div>
    );
  } else {
    return (
      <MockContext.Provider value={getDisabledCtx()}>
        {children}
      </MockContext.Provider>
    );
  }
};

function throwNotInstantiatedError(
  functionName: string,
  addlInfo: string = ''
) {
  throw new Error(
    `MockGqlProvider::${functionName}: msw is not instantiated. Is 'USE_DEV_MOCKS' set?${
      addlInfo ? ' Additional Info: ' + addlInfo : ''
    }`
  );
}

function getDisabledCtx() {
  const defaultCtx: MockGqlCtx = {
    enabled: ENV_USE_DEV_MOCKS,
    ready: false,
    useQuery: queryDefinition =>
      throwNotInstantiatedError('useQuery', 'UseQuery: ctx not instantiated'),
    useMutation: queryDefinition =>
      throwNotInstantiatedError(
        'useMutation',
        'UseMutation: ctx not instantiated'
      ),
    worker: null,
    serviceWorkerRegistration: null,
  };
  return defaultCtx;
}
