import { ResizableBox } from 'react-resizable';
import { Spin } from 'antd';
import { DocumentFileMenu } from './documentFileMenu';
import React, { FC, useState } from 'react';
import { css } from '@emotion/react';
import { SetActiveFileFn, UiAuditDocumentation } from './types';
import pdfContainer from '../../pdf/container';
const styles = css({
  '& .resizable-box': {
    padding: '2px',
    position: 'relative',
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: '100%',
    '& .text': {
      textAlign: 'center',
    },
    '& .hover-handles .react-resizable-handle': {
      display: 'none',
    },
    '& .hover-handles:hover .react-resizable-handle': {
      display: 'block',
    },
    '& .react-resizable-handle': {
      position: 'absolute',
      width: '15px',
      height: '100%',
      // backgroundRepeat: 'no-repeat',
      // backgroundOrigin: 'content-box',
      boxSizing: 'border-box',
      // backgroundPosition: 'bottom right',
      padding: '0 3px 3px 0',
    },

    '& .react-resizable-handle-e': {
      top: 0,
      right: 0,
      cursor: 'ew-resize',
    },
  },
});

type DocumentViewerNavProps = {
  claimId: string | null;
  files: UiAuditDocumentation[];
  activeFile: UiAuditDocumentation | null;
  setActiveFile: SetActiveFileFn;
  fetching: boolean;
};

export const DocumentViewerNav: FC<DocumentViewerNavProps> = ({
  claimId,
  files,
  activeFile,
  setActiveFile,
  fetching,
}) => {
  const showFileNamesLoadingSpinner = files.length > 0 ? false : fetching;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="resizable-box-container" css={styles} tw="_h-full _w-fit">
      <ResizableBox
        className="resizable-box"
        axis="x"
        onResizeStart={() => pdfContainer.disableInteraction()}
        onResizeStop={() => pdfContainer.allowInteraction()}
        resizeHandles={['e']}
        width={
          collapsed
            ? DocumentFileMenu.COLLAPSED_WIDTH
            : DocumentFileMenu.EXPANDED_WIDTH
        }
        minConstraints={[
          collapsed
            ? DocumentFileMenu.COLLAPSED_WIDTH
            : DocumentFileMenu.EXPANDED_WIDTH * 0.85,
          100,
        ]}
        maxConstraints={[
          collapsed
            ? DocumentFileMenu.COLLAPSED_WIDTH
            : DocumentFileMenu.EXPANDED_WIDTH * 2.5,
          100,
        ]}
        // handle={<span className="custom-handle">x</span>}
        handleSize={[8, 2]}
      >
        <div tw="_h-full _bg-antd-lt-colorBgContainer dark:_bg-antd-dk-colorBgContainer">
          {/*<div tw="_h-full _max-w-[195px] _bg-antd-lt-colorBgContainer">*/}
          {showFileNamesLoadingSpinner ? (
            <div>
              <Spin style={{ display: 'block', padding: '5px' }} />
            </div>
          ) : (
            <DocumentFileMenu
              {...{
                claimId,
                files,
                activeFile,
                fetching,
                collapsed,
                setCollapsed,
              }}
            ></DocumentFileMenu>
          )}
        </div>
      </ResizableBox>
    </div>
  );
};
