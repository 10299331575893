import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormExtra } from '../../../../shared/components/form/formExtra';
import { CommonFieldProps, formKey } from '../util';
import { useInitialValues } from '../useInitialValues';

export type RationaleProps = CommonFieldProps & {};

export const Rationale: FC<RationaleProps> = ({
  dataKey,
  label = 'Rationale',
  initialValues,
  formLayout = {},
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<string>(
    dataKey,
    initialValues
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      rules={[
        {
          required: true,
          message: 'Enter a rationale for this finding',
        },
      ]}
      initialValue={defaultValue}
      extra={
        <FormExtra visible={!!existingFindingValue}>
          <div tw="_whitespace-pre-line _max-h-12 _overflow-y-auto">
            {existingFindingValue}
          </div>
        </FormExtra>
      }
      {...formLayout}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  );
};
