import { AuditDocumentation } from '../../gql/graphql';
import {
  UiAuditDocumentation,
  UiAuditDocumentationDisplayExtra,
} from './types';

const fileNameProcessPrefix = {
  ORIGINAL: 'Original',
  OCR: 'Searchable',
  ANNOTATED: 'Annotated',
};

// URL Timestamp helpers
export const extractEpochMillisecondsFromSignedUrl = (
  signedUrl: string
): number => {
  if (!signedUrl || signedUrl.length < 8) {
    return 0;
  }
  const expireTag =
    signedUrl.indexOf('X-Amz-Expires=') > -1 ? 'X-Amz-Expires=' : '&Expires=';

  const expireTagIdx = signedUrl.indexOf(expireTag) + expireTag.length;
  if (expireTagIdx === -1) {
    return 0;
  }
  const expiry = signedUrl.substring(
    expireTagIdx,
    signedUrl.indexOf('&', expireTagIdx)
  );
  if (Number.isNaN(expiry)) {
    return 0;
  }
  const ms = Number(expiry) * 1000;

  // To test fake expiry:
  // return ms - 60_000 * 118;  //e.g. 2 min from time of url expires

  return ms;
};

const getDisplayExtraProps = (
  file: AuditDocumentation
): UiAuditDocumentationDisplayExtra => {
  const fn =
    (file?.process && file.process != 'ORIGINAL' && file?.subFile) ||
    file.fileName ||
    '';
  const ext =
    (fn.indexOf('.') >= 0 &&
      fn.substring(fn.lastIndexOf('.') + 1)?.toLowerCase()) ||
    '';
  // DOC: (MV) - files with no filename / no extension...
  // there are some file entries in test/gamma with no filename & 0 size; there are NONE in prod a/o 9/7/2022,
  // however it's possible a PDF created on, e.g. a mac, might have no ext... assume it's displayable; there
  // is code to display the ID if no file name in documentFileMenu.js
  const isRenderable = ext === 'pdf' || ext === '';
  const fileDisplayType =
    (isRenderable && fileNameProcessPrefix[file.process]) ||
    `Unsupported file: ${ext}`;

  // expires
  const expiresMs = extractEpochMillisecondsFromSignedUrl(file.signedUrl);
  const expiresDate = new Date(expiresMs);
  const expires = expiresDate?.toLocaleTimeString() ?? '';
  return {
    ext,
    isRenderable,
    fileDisplayType,
    expiresMs,
    expires,
    expiresDate,
  };
};

export const auditDocToUiAuditDoc = (
  auditDoc: AuditDocumentation,
  claimId: string
): UiAuditDocumentation => {
  const answer = {
    claimId,
    ...auditDoc,
    originalUrl: auditDoc.signedUrl,
    ...getDisplayExtraProps(auditDoc),
  };
  return answer;
};

export const mapFilesToUiFiles = (
  auditDocs: AuditDocumentation[],
  claimId: string
) => {
  return auditDocs
    .map(auditDoc => auditDocToUiAuditDoc(auditDoc, claimId))
    .sort((a, b) => Number(b.isRenderable) - Number(a.isRenderable)); // renderable first
};
