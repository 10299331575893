import {
  CreateIbLineFindingsInput,
  createIbLineFindingsMutation,
  VersionAuditFindingsInput,
  versionAuditFindingsMutation,
} from '../../../../../gql/graphql';
import { useMemo } from 'react';
import { Client, OperationResult, useClient, useMutation } from 'urql';
import {
  createIbLineFindingsMutationDoc,
  versionFindingsMutationDoc,
} from './finding.gql';

export class FindingModel {
  constructor(private client: Client) {}

  public async createIbFinding(
    input: CreateIbLineFindingsInput
  ): Promise<
    OperationResult<
      createIbLineFindingsMutation,
      { input: CreateIbLineFindingsInput }
    >
  > {
    return this.client
      .mutation(createIbLineFindingsMutationDoc, { input })
      .toPromise();
  }

  public async versionIbFinding(
    input: VersionAuditFindingsInput
  ): Promise<
    OperationResult<
      versionAuditFindingsMutation,
      { input: VersionAuditFindingsInput }
    >
  > {
    return this.client
      .mutation(versionFindingsMutationDoc, { input })
      .toPromise();
  }
}

export const useFindingModel = () => {
  const client = useClient();

  const findingModel = useMemo(() => {
    return new FindingModel(client);
  }, []);

  return findingModel;
};
