import { useContext } from 'react';
import { LogContext } from './logProvider';
import { Logger } from 'loglevel';

type LoggerEx = Logger & {
  css?: string;
};

export const useLog = (logName: string): LoggerEx => {
  const log: LoggerEx = useContext(LogContext).getLog(logName);
  return log;
};
