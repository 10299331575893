import React from 'react';
import tw, { css } from 'twin.macro';
import { TableStyleProvider } from '../../../../common/table/ag/tableStyleProvider';

// alaffia light red: #E16E64
// alaffia red: #C14340
// alaffia light blue: #3860BA
// alaffia blue: #22367C
// alaffia ligth yellow: Hex #FBF559

// UB/IB table row styles -- for use in the ag-grid rowClassRules applied to the tables:
// (note use of '& ...' to scope styles to this container, and provide ag-grid class names within the scope)
const rowStyle = {
  '& .ag-row-focus': {
    backgroundColor: '#00000009',
    // ...tw`_bg-antd-lt-colorBgBase _border _border-solid _border-antd-lt-colorBorderSecondary dark:_border-antd-dk-colorBorderSecondary`,
  },
  '& .ala-ag-row-style-accepted-finding': {
    ...tw`_bg-antd-lt-green-3 dark:_bg-antd-dk-green-5`,
    '&.ag-row-focus': tw`_bg-opacity-75`,
  },
  '& .ala-ag-row-style-needs-review-finding': {
    ...tw`_bg-antd-lt-red-3 dark:_bg-antd-dk-red-4`,
    '&.ag-row-focus': tw`_bg-opacity-75`,
  },
  '& .ala-ag-row-style-stale-finding': {
    ...tw`_bg-antd-lt-colorWarning dark:_bg-antd-dk-colorWarning`,
    '&.ag-row-focus': tw`_bg-opacity-75`,
  },
  '& .ala-ag-row-style-autodor-finding': {
    ...tw`_bg-antd-lt-gold-3 dark:_bg-antd-dk-gold-6`,
    '&.ag-row-focus': tw`_bg-opacity-75`,
  },
  '& .ala-ag-row-style-is-group-header': {
    ...tw`_italic _text-antd-lt-colorTextLabel dark:_text-antd-dk-colorTextLabel`,
  },
};

// UB/IB lines table styles -- extends ag-grid theme styling in DesignProvider:
const tableStyle = {
  // main table:
  '& .ag-header-cell-label': tw`_text-xs`,
  // detail rows (e.g. child findings table nested under main ub/ib lines):
  '& .ag-details-row': {
    // padding and bg of findings table:
    ...tw`_pb-2.5 _pt-0 _px-0 _bg-antd-lt-colorBgContainerDisabled dark:_bg-antd-dk-colorBgContainerDisabled`,
    // child findings table wrapper:
    '& .ag-root-wrapper': {
      borderRadius: `0px`,
      ...tw`_p-0 _my-0 _mx-2`,
      // child findings table header:
      '& .ag-header': {
        ...tw`_bg-antd-lt-colorBgContainerDisabled dark:_bg-antd-dk-colorBgContainerDisabled`,
        '& .ag-header-cell-label': tw`_font-light _bg-transparent`,
        '& .ag-header-cell-resize:after': tw`_h-[80%] _top-[10%] _bg-antd-lt-colorBgTextHover dark:_bg-antd-dk-colorBgTextHover `,
      },
    },
    // set the container size -- otherwise the table will always have a larger min-height than rows, if just a few rows:
    '& .ag-center-cols-container': tw`!_min-h-[42px]`,
    '& .ag-center-cols-clipper': tw`!_min-h-[42px]`,
  },
};

export type TableStyleProviderProps = {
  width: number;
  height: number;
  children: React.ReactElement;
};

const claimLineTableStyleProvider: React.FC<TableStyleProviderProps> = ({
  width,
  height,
  children,
}) => {
  return (
    <TableStyleProvider {...{ width, height, style: [tableStyle, rowStyle] }}>
      {children}
    </TableStyleProvider>
  );
};

export const ClaimLineTableStyleProvider = React.memo(
  claimLineTableStyleProvider
);
