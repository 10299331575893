import React, { useEffect, useMemo } from 'react';
import { gql } from 'urql';
import { useRouteMatch } from 'react-router-dom';
import {
  Table,
  Button,
  Tooltip,
  Typography,
  Space,
  message,
  Result,
  theme,
  Alert,
} from 'antd';
import { css } from '@emotion/react';
import { AuditOutlined, LinkOutlined, AimOutlined } from '@ant-design/icons';
import accounting from 'accounting-js';
import { withFragment } from '../../../../util/urelay';
import { auditFindingFragment } from '../../../../fragments';
import { UserContext } from '../../../context/user';
import { ClaimWorkspaceAuditFindingList } from '../auditFinding/claimWorkspaceAuditFinding';
import { getTableRowStyle, toDenialCodesTagMap } from '../util';
import {
  createDenialCodeRowTags,
  createUpdateAuditFindingErrorHandler,
} from '../shared';
import AutoSizer from 'react-virtualized-auto-sizer';
import tw from 'twin.macro';
import { useHotkeys } from 'react-hotkeys-hook';

const { Text } = Typography;

const itemizedBillTableQuery = gql`
  query ItemizedBillTable(
    $batchClaimId: UUID!
    $afclFilter: AuditFindingFilter!
  ) {
    batchClaim(id: $batchClaimId) {
      id
      auditFindings(filter: $afclFilter) {
        totalCount
        nodes {
          ...auditFindingFragment
        }
      }
      itemizedBill {
        id
        csv {
          id
          s3Key
          header {
            values
          }
          rows
        }
      }
    }
  }

  ${auditFindingFragment}
`;

const PureItemizedBillTable = props => {
  const {
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    readOnly: ignoredReadOnly,
    query,
    variables: { afclFilter },
  } = props;

  const [readOnly, setReadOnly] = React.useState(true);
  const toggleReadOnly = React.useCallback(() => {
    if (!isInternalUser) {
      return;
    }
    if (ignoredReadOnly) {
      message.warning(
        'Cannot toggle read-only - claim state does not allow editing.'
      );
      return;
    }
    message.info(`Read-only: ${readOnly} -> ${!readOnly}`);
    setReadOnly(existingReadOnly => !existingReadOnly);
  }, [readOnly, ignoredReadOnly]);
  useHotkeys('ctrl+shift+meta+e', () => toggleReadOnly());

  const {
    token: { red3: NEEDS_REVIEW, green3: ACCEPTED },
  } = theme.useToken();
  const findingsRowColors = {
    NEEDS_REVIEW,
    ACCEPTED,
  };

  const viewingReport =
    useRouteMatch('/reports/:auditFindingReportId/workspace') !== null;
  const {
    userType,
    workstation: {
      tabs: {
        IB: { table: ibTableSettings },
      },
    },
  } = React.useContext(UserContext);

  const showFindings = userType !== 'PROVIDER' || viewingReport;

  const [nnIbLineFingerprints, setNnIbLineFingerprints] = React.useState([]);

  const [{ fetching, data, error }] = query;
  if (error) {
    console.error(`Error loading IB data: ${error.message ?? ''}`, error);
  }

  const isInternalUser = ['AUDITOR', 'ADMINISTRATOR'].includes(userType);
  const copyErrorInfo = async ({ error }) => {
    return navigator.clipboard
      .writeText(`\nError parsing IB data on claim: \n${window.location}`)
      .then(() => message.success('Link copied to clipboard!'));
  };

  const getIbLoadErrorText = e =>
    isInternalUser ? (
      <Text>
        Please post this error to the{' '}
        <a href="slack://channel?team=T018A2WLXC6&id=G01GXL0JXNG">
          #product-issues slack channel
        </a>
        , and include a link to the claim
        <Button
          size="small"
          type="link"
          onClick={() =>
            copyErrorInfo({
              error,
            })
          }
        >
          <LinkOutlined />
        </Button>
      </Text>
    ) : (
      <Text>
        Please email this error to{' '}
        <a href="support@alaffiahealth.com" target="_top">
          support@alaffiahealth.com
        </a>
        , and include a link to the claim
        <Button
          size="small"
          type="link"
          onClick={() =>
            copyErrorInfo({
              error,
            })
          }
        >
          <LinkOutlined />
        </Button>
      </Text>
    );

  const { s3Key, columnTitles, ibLines, ibFindingsMap, ibTableData } =
    useMemo(() => {
      const noIbData = !data || data.batchClaim.itemizedBill === null;
      const ib = noIbData ? null : data.batchClaim.itemizedBill;

      // Extract IB Line data from GraphQL query - filtering additional metadata rows that should not display
      const _columnTitles = noIbData ? [] : ib.csv.header.values;
      const hasOrigHeaders = _columnTitles.some(
        x => x.toLowerCase() === 'ala_is_header'
      );
      const ibRowFilter = ibLine => ibLine?.ala_is_header !== '1';

      const _ibLines = noIbData
        ? []
        : hasOrigHeaders // only filter if filter is defined as some IBs are large
          ? ib.csv.rows.filter(ibRowFilter)
          : ib.csv.rows; // if no filter just return rows

      // Create mapping of fingerprint to associated auditFindings
      const _ibFindingsMap = noIbData
        ? {}
        : data.batchClaim.auditFindings.nodes.reduce((acc, auditFinding) => {
            for (const {
              nnIbLine: { fingerprint },
            } of auditFinding.batchClaimLineItemAudit?.batchClaimLineItem
              .batchClaimLineItemNnIbLines.nodes || []) {
              acc[fingerprint] = !!acc[fingerprint]
                ? acc[fingerprint].concat(auditFinding)
                : [auditFinding];
            }
            return acc;
          }, {});

      // Combine ibLine data and auditFindings data
      // csv id is equivilant to the fingerprint value in the pg table `nn_ib_line` --> nnIbLineFingerprints --> is a unqiue identifer of csv row
      // Really the goal is to map this fingerprint to each associated bachtCLaimLineItem generated by findings
      const _ibTableData = _ibLines.map(ibLine => ({
        ...ibLine,
        hasAcceptedFindings: _ibFindingsMap[ibLine.fingerprint]?.some(
          af => af.isActive && af.accepted
        ),
        denialCode:
          // FIXME taking the first finding here is not
          // necessarily correct. Need to determine whether
          // displaying multiple denial codes is appropriate
          // or if the code can reflect a single associated finding
          _ibFindingsMap[ibLine.fingerprint]?.[0]
            ?.auditFindingRuleTypeByAuditFindingRuleType?.displayName,
        auditFindings: _ibFindingsMap[ibLine.fingerprint] || [],
      }));

      return {
        s3Key: noIbData ? '' : ib.csv.s3Key,
        columnTitles: _columnTitles,
        ibLines: _ibLines,
        ibFindingsMap: _ibFindingsMap,
        ibTableData: _ibTableData,
      };
    }, [data]);

  useEffect(() => {
    // This effect is used to de-select any findings that have the checkbox checked when
    // data is refreshed from the server (e.g. after create/accept/review finding) where
    // the action results in an active finding on the checked line, which makes the row
    // unselectable

    // get a list of ibLines with accepted findings
    const lineFingerprintsWithAcceptedFindings = ibTableData
      .filter(ibl => ibl.hasAcceptedFindings)
      .map(ibl => ibl.fingerprint);

    // call setState with the functional param (prevValue => {}) so we update the current value if batched renders
    // https://reactjs.org/docs/hooks-reference.html#functional-updates
    setNnIbLineFingerprints(prevNnIbLineFingerprints => {
      const newFingerprints = prevNnIbLineFingerprints.filter(
        fp => !lineFingerprintsWithAcceptedFindings.includes(fp)
      );
      // only return a new object if we found some selections to remove:
      return newFingerprints.length < prevNnIbLineFingerprints.length
        ? newFingerprints
        : prevNnIbLineFingerprints;
    });
  }, [ibFindingsMap]);

  const updateAuditFindingErrorHandler = createUpdateAuditFindingErrorHandler(
    findingInfoArr =>
      findingInfoArr.map(fi => {
        return (
          <>
            <Text strong={true}>
              Active IB line findings exist on selected lines.
            </Text>{' '}
            See the IBIN tab for a full view of findings.
          </>
        );
      })
  );

  // Create antd columns from the CSV's column space
  const columns = [
    ...columnTitles
      // remove backend columns (e.g. ala_table_page)
      .filter(title => !title.startsWith('ala_'))
      .map(title => ({
        title,
        dataIndex: title,
        key: title,
        // width: ???,
        filters: [
          ...ibTableData.reduce(
            (values, ibLine) => values.add(ibLine[title]),
            new Set()
          ),
        ]
          .sort()
          .map(value => ({ text: value || '<EMPTY>', value })),
        onFilter: (value, ibLine) =>
          ibLine &&
          (value ? ibLine[title].includes(value) : ibLine[title] == value),
        // filterSearch: true,
      })),
  ];

  if (showFindings) {
    columns.push(
      {
        title: 'Adj. Amount',
        dataIndex: 'adjustment',
        key: 'adjustment',
        render: (_, { auditFindings }) => (
          <Space direction="vertical">
            {auditFindings.length > 0 &&
              accounting.formatMoney(
                auditFindings
                  .filter(({ accepted }) => accepted)
                  .map(({ improperPaymentCost }) => improperPaymentCost)
                  .reduce((prev, curr) => {
                    return prev + curr;
                  }, 0)
              )}
          </Space>
        ),
      },
      {
        title: 'Denial Code',
        dataIndex: 'denialCode',
        key: 'denialCode',
        render: (_, { auditFindings }) =>
          auditFindings.length > 0
            ? createDenialCodeRowTags(toDenialCodesTagMap(auditFindings))
            : '',
      }
    );
  }

  const table = (w, h) => (
    <Table
      id="workspace-ib-table"
      data-cy="workspace-ib-table"
      loading={fetching}
      bordered
      size="small"
      sticky={true}
      columns={columns}
      dataSource={ibTableData}
      rowKey={ibLine => ibLine.fingerprint}
      rowSelection={
        readOnly
          ? false
          : {
              type: 'checkbox',
              fixed: true,
              selectedRowKeys: nnIbLineFingerprints,
              onChange: (selectedRowKeys, _selectedRows) => {
                setNnIbLineFingerprints(selectedRowKeys);
              },
              getCheckboxProps: ibLine => ({
                disabled: ibLine.hasAcceptedFindings,
              }),
              renderCell: (checked, ibLine, index, originNode) => (
                <Tooltip
                  color={'rgb(96,96,96,0.5)'}
                  mouseEnterDelay={1.75}
                  overlayInnerStyle={{
                    fontSize: '0.95em',
                    minHeight: '1.2em',
                    fontWeight: '300',
                  }}
                  overlayStyle={{
                    minHeight: '1.4em',
                    lineHeight: '1em',
                  }}
                  title={
                    ibLine.hasAcceptedFindings
                      ? 'disabled: an accepted finding exists'
                      : 'select rows to create findings'
                  }
                >
                  {originNode}
                </Tooltip>
              ),
            }
      }
      pagination={false}
      scroll={{
        y: h, // 'calc(100vh - 55px - 160px - 32px - 8px - 35px)',
      }}
      onRow={ibLine =>
        getTableRowStyle({
          showFindings,
          findings: ibLine.auditFindings,
          findingsRowColors,
        })
      }
      expandable={{
        columnWidth: 36,
        expandedRowRender: ibLine => {
          return (
            <ClaimWorkspaceAuditFindingList
              auditFindings={ibLine.auditFindings}
              {...{
                setCreatingAuditFinding,
                setDecliningAuditFinding,
                navigateToIbinRow,
                updateAuditFindingErrorHandler,
                readOnly,
                afclFilter,
                expandAllFindings: true, // there should be only one per row so expand finding detail by default
              }}
            />
          );
        },
        rowExpandable: ibLine =>
          showFindings && ibLine.auditFindings.length > 0,
      }}
      summary={_pageData => {
        return (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell>
                {ibTableSettings?.hides?.createFindingButton || (
                  <Button
                    data-test-id="create-ib-audit-finding-button"
                    type="secondary"
                    size="small"
                    icon={<AuditOutlined />}
                    disabled={readOnly || !nnIbLineFingerprints.length}
                    loading={fetching && !data}
                    onClick={() => {
                      setCreatingAuditFinding({
                        visible: true,
                        title: 'New Claim Review Finding',
                        auditFindingSeedType: 'IB_CLAIM_LINE',
                        updateAuditFindingErrorHandler,
                        nnIbLineFingerprints,
                        s3Key: s3Key,
                      });
                    }}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={columns.length}>
                {ibTableSettings?.hides?.filePath || (
                  <Text
                    data-test-id="ib-table-digital-ib-file-path"
                    italic
                    type="secondary"
                    css={css({ fontSize: '0.85em' })}
                  >
                    {s3Key}
                  </Text>
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        );
      }}
    />
  );

  return error ? (
    <Result
      status="warning"
      title="Failed to load IB data"
      subTitle={getIbLoadErrorText(error)}
    />
  ) : (
    <div tw="_w-full _h-full _min-h-full _max-h-full">
      <div tw="_pb-1.5">
        <Alert
          // size="small"
          message={
            <span tw="_text-xs">
              Warning: This IB tab is being retired and is read-only - to link
              to findings on the IBIN tab click the <AimOutlined /> icon on the
              finding below.
            </span>
          }
          type="warning"
          showIcon
          closable
        />
      </div>
      <div tw="_w-full _h-full _min-h-full _max-h-full">
        <AutoSizer defaultHeight={900} defaultWidth={1200}>
          {({ height, width }) => {
            const h = height || 900;
            const w = width || 1200;
            return (
              <div
                style={{
                  minHeight: h,
                  height: h,
                  width: w,
                }}
              >
                {/* todo account for table header/footer rather than hardcode */}
                {table(w - 16, h - (56 + 87))}
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

const ItemizedBillTable = withFragment({
  displayName: 'ItemizedBillTable',
  queryBuilder: () => itemizedBillTableQuery,
  Component: PureItemizedBillTable,
});

export { ItemizedBillTable, itemizedBillTableQuery };
