import { graphql } from '../../../gql';

export const askAutodorFileQuery = graphql(/* GraphQL */ `
  query askAutodorFileQuery($input: AskAutodorFileInput) {
    askAutodorFile(input: $input) {
      responseId
      prompt
      response
      pages {
        documentId
        textMatched
        pageNumber
        confidence
      }
    }
  }
`);

export const askAutodorFileVectorsExistQuery = graphql(/* GraphQL */ `
  query askAutodorFileVectorsExistQuery(
    $input: AskAutodorFileVectorsExistInput
  ) {
    askAutodorFileVectorsExist(input: $input) {
      documentIds
    }
  }
`);

export const askAutodorRatingMutation = graphql(/* GraphQL */ `
  mutation askAutodorRateResponseMutation(
    $input: AskAutodorRateResponseInput!
  ) {
    askAutodorRateResponse(input: $input) {
      responseRatingId
    }
  }
`);
