import { useCallback, useEffect, useRef } from 'react';
import { AuditFindingRuleType } from '../types';
import {
  DenialCode,
  DenialCodes,
} from '../../../queries/denialCodes/useDenialCodes';
import {
  rationaleUpdateModal,
  RationaleUpdateModalOptions,
} from './rationaleUpdateModal';
import { set } from 'lodash';
import {
  EditFindingState,
  EditFindingValues,
  FormAdapter,
} from '../viewController/types';

export type useDenialCodeChangesProps = {
  selectedRuleType: AuditFindingRuleType;
};

export const useDenialCodeChanges = (
  denialCodes: DenialCodes,
  state: EditFindingState,
  formAdapter: FormAdapter<EditFindingValues>
) => {
  const currentDenialCode = useRef<DenialCode>();

  useEffect(() => {
    if (
      !currentDenialCode.current &&
      state.existingFindingValues?.auditFindingValues?.auditFindingRuleType
    ) {
      currentDenialCode.current =
        denialCodes.byKey[
          state.existingFindingValues?.auditFindingValues?.auditFindingRuleType
        ];
    }
  }, [state.existingFindingValues, denialCodes]);

  const onDenialCodeChange = useCallback(
    (newRule: AuditFindingRuleType, currentRationale: string | undefined) => {
      if (!!newRule && newRule != currentDenialCode.current?.type) {
        const overwritePromise = new Promise<{
          ruleType: AuditFindingRuleType;
          choice: RationaleUpdateModalOptions;
        }>((resolve, reject) => {
          if (
            currentDenialCode.current?.rationaleTemplate !== currentRationale
          ) {
            rationaleUpdateModal(
              newRule,
              (
                ruleType: AuditFindingRuleType,
                choice: RationaleUpdateModalOptions
              ) => {
                resolve({ ruleType, choice });
              },
              () => {
                reject();
              }
            );
          } else {
            // overwrite
            resolve({
              ruleType: newRule,
              choice: RationaleUpdateModalOptions.REPLACE,
            });
          }
        });

        overwritePromise.then(
          // resolved:
          result => {
            // update the 'current' denial code -- in the 'resolve' so if they cancel
            // we don't trigger this code again when we revert denial code...
            currentDenialCode.current = denialCodes.byKey[newRule];

            //and get the new template
            const newRationale =
              currentDenialCode.current.rationaleTemplate ?? '';
            const oldRationale = currentRationale ?? '';

            const sep = '\n--\n';

            const updated =
              result.choice == RationaleUpdateModalOptions.APPEND_EXISTING
                ? newRationale.replace(/\n+$/, '') + sep + oldRationale
                : result.choice == RationaleUpdateModalOptions.APPEND_NEW
                  ? oldRationale.replace(/\n+$/, '') + sep + newRationale
                  : result.choice == RationaleUpdateModalOptions.REPLACE
                    ? newRationale
                    : null; // nothing to do if result.choice == RationaleUpdateModalOptions.KEEP_EXISTING

            if (updated !== null) {
              formAdapter.setValues(
                set({}, 'auditFindingValues.improperPaymentReason', updated)
              );
            }
          },
          // rejected:
          () => {
            formAdapter.setValues(
              set(
                {},
                'auditFindingValues.auditFindingRuleType',
                currentDenialCode.current?.type
              )
            );
          }
        );
      }
    },
    [denialCodes]
  );

  return onDenialCodeChange;
};
