import { graphql } from '../../../gql';

/* strip types
 : .*$
*/

const batchClaimDocumentationFragment = graphql(`
  fragment batchClaimDocumentationFragment on BatchClaim {
    id
    auditDocumentation {
      id
      subFile
      fileName
      process
      size
      signedUrl
      __typename
    }
  }
`);

export const claimQueryDoc = graphql(`
  query documentationViewer($claimId: UUID!) {
    batchClaim(id: $claimId) {
      ...batchClaimDocumentationFragment
    }
  }
`);
