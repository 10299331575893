import { gql } from 'urql';

// fragments
import { queueFragment } from './fragments';

// QUEUES

/**
 * A query for returning soft deleting a user's queues
 * restricted to only queues created by
 * the requesting user. Returns all
 * fields on <Queue>
 */
const softDeleteQueueMutation = gql`
  mutation softDeleteQueue($queueId: UUID!) {
    updateQueue(input: { id: $queueId, patch: { deleted: true } }) {
      queue {
        ...queueFragment
      }
    }
  }
  ${queueFragment}
`;

// REPORTS

/**
 * A mutation to generate an audit finding report via graphql
 * returns the report id and s3Url
 * @params batchClaimFilter: BatchClaimFilter!
 * @params auditFindingReportType: AuditFindingReportType!
 * @params primaryPiaId: UUID!
 */
const generateReportMutation = gql`
  mutation generateReport(
    $batchClaimIds: [UUID!]!
    $reportType: AuditFindingReportType!
    $reportSubtype: AuditFindingReportSubtype!
    $primaryPiaId: UUID!
  ) {
    generateReport(
      batchClaimIds: $batchClaimIds
      reportType: $reportType
      reportSubtype: $reportSubtype
      primaryPiaId: $primaryPiaId
    ) {
      auditFindingReportId
      auditFindingReportViewId
      reportUrl
    }
  }
`;

/**
 * A mutation to generate an audit finding report via graphql
 * returns the report id and s3Url
 * @params  reportId: UUID!
 * @params reportType: AuditFindingReportType!
 */
const versionReportMutation = gql`
  mutation versionReport(
    $reportId: UUID!
    $reportType: AuditFindingReportType!
    $reportSubtype: AuditFindingReportSubtype!
  ) {
    versionReport(
      reportId: $reportId
      reportType: $reportType
      reportSubtype: $reportSubtype
    ) {
      auditFindingReportId
      auditFindingReportViewId
      reportUrl
    }
  }
`;

export {
  softDeleteQueueMutation,
  generateReportMutation,
  versionReportMutation,
};
