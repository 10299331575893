import { List, Button, Spin, Modal } from 'antd';
import { gql, useQuery } from 'urql';

import { batchClaimDocumentationFragment } from '../../../fragments';

const ViewDocumententationModal = ({
  batchClaimId,
  viewDocumentNamesModalVisible,
  setViewDocumentNamesModalVisible,
}) => {
  const [{ fetching, data }, refetchQuery] = useQuery({
    query: gql`
      query GetUploadedBatchClaimFilenames($batchClaimId: UUID!) {
        batchClaim(id: $batchClaimId) {
          ...batchClaimDocumentationFragment
        }
      }
      ${batchClaimDocumentationFragment}
    `,
    variables: { batchClaimId },
    pause: batchClaimId === null,
  });

  return (
    <>
      <Modal
        title="Uploaded Claim Documents"
        open={viewDocumentNamesModalVisible}
        onCancel={() => setViewDocumentNamesModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setViewDocumentNamesModalVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <List
          size="large"
          loading={fetching && !data}
          dataSource={
            data
              ? data.batchClaim.auditDocumentation.map(
                  ({ fileName }) => fileName
                )
              : []
          }
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </>
  );
};

export { ViewDocumententationModal };
