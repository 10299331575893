// UUID
// We could, apparently, define something precise, but this is an exercise for global typing not tests:
// https://ybogomolov.me/type-level-uuid/
import {
  AuditFinding,
  BatchClaimLineItem,
  FindingItemizedBillData,
  ItemizedBillLine,
} from '../../../../gql/graphql';
import { batchClaimLineItemFormItems } from './batchClaimLineItemFormItems';
import { FindingAdjustments } from '../../util/findingAdjustments';

export type UUID = string;

export const or0 = (n: number | null | undefined) => n || 0;

export const acceptsFindings = (
  ibLine: ItemizedBillLine | null | undefined
) => {
  const hasAcceptedFindings = (ibLine?.findings || []).some(f => f.accepted);

  return (
    !!ibLine &&
    !hasAcceptedFindings &&
    or0(ibLine.units) > 0 &&
    or0(ibLine.totalCharge) > 0
  );
};

/*
input CreateAuditFindingValuesInput {
  auditFindingRuleType: String!
  confidence: BigFloat!
  improperPaymentCost: Float!
  improperPaymentReason: String!
  improperPaymentUnitsCharged: Int!
  metaDataAocPrimaryCode: String
  metaDataEmCorrectLevel: Int
  metaDataEmOriginalLevel: Int
  metaDataNcciMueAjudicationValue: Int
  metaDataNcciMueOhpMueValue: Int
  metaDataNcciPtpCode: String
  metaDataNcciPtpModifier: Int
  metaDataPricingCorrectPrice: BigFloat
  metaDataUnitsCorrectUnits: Int
}
input CreateBatchClaimLineItemValuesInput {
  dateOfServiceEnd: Datetime
  dateOfServiceStart: Datetime!
  description: String!
  id: UUID
  itemNumber: String
  nnIbLineValues: [CreateIbLineValueInput!]
  procedureCode: String
  revCode: String
  unitCharge: BigFloat!
  units: Int!
}
input CreateUbClaimFindingsInput {
  auditFindingValues: CreateAuditFindingValuesInput!
  authorId: UUID!
  batchClaimIds: [UUID!]!
}
input CreateUbClaimLineFindingsInput {
  auditFindingValues: CreateAuditFindingValuesInput!
  authorId: UUID!
  batchClaimId: UUID!
  batchClaimLineIds: [UUID!]!
}
input CreateIbLineFindingsInput {
  authorId: UUID!
  batchClaimId: UUID!
  batchClaimLineId: UUID!
  values: [CreateIbLineFindingsInputValue!]!
}

input CreateIbLineFindingsInputValue {
  auditFindingValues: CreateAuditFindingValuesInput!
  batchClaimLineItemValues: CreateBatchClaimLineItemValuesInput!
}
 */

interface CreateAuditFindingValuesInput {
  auditFindingRuleType?: string | null | undefined;
  confidence?: number | null | undefined;
  improperPaymentCost?: number | null | undefined;
  improperPaymentReason?: string | null | undefined;
  improperPaymentUnitsCharged?: number | null | undefined;
  metaDataAocPrimaryCode?: string | null | undefined;
  metaDataEmCorrectLevel?: number | null | undefined;
  metaDataEmOriginalLevel?: number | null | undefined;
  metaDataNcciMueAjudicationValue?: number | null | undefined;
  metaDataNcciMueOhpMueValue?: number | null | undefined;
  metaDataNcciPtpCode?: string | null | undefined;
  metaDataNcciPtpModifier?: number | null | undefined;
  metaDataPricingCorrectPrice?: number | null | undefined;
  metaDataUnitsCorrectUnits?: number | null | undefined;
}
interface CreateBatchClaimLineItemValuesInput {
  dateOfServiceEnd?: string | null | undefined;
  dateOfServiceStart?: string | null | undefined;
  description?: string | null | undefined;
  id?: UUID; // generated
  itemNumber?: string | null | undefined;
  nnIbLineValues?: { fingerprint: UUID; s3Key: string }[];
  procedureCode?: string | null | undefined;
  revCode?: string | null | undefined;
  unitCharge?: number | null | undefined;
  units?: number | null | undefined;
}
interface AuditFindingReviewValuesInput {
  auditFindingId: UUID;
  auditFindingValues: CreateAuditFindingValuesInput;
  batchClaimLineId?: UUID;
  batchClaimLineItemValues?: CreateBatchClaimLineItemValuesInput;
}
interface VersionAuditFindingsInput {
  auditFindingReviewValues: AuditFindingReviewValuesInput[];
  authorId: UUID;
}
/**
 * Historic values for auditFindings - when confirmed inactive
 * we can just remove the extends below on AuditFindingFormValues
 */
interface AuditFindingFormValuesDeprecated {
  metaDataNcciMueOhpMueValue?: number | null;
  metaDataNcciMueAjudicationValue?: number | null;
  metaDataNcciPtpCode?: string | null;
  metaDataNcciPtpModifier?: number | null;
  metaDataAocPrimaryCode?: string[] | null;
  metaDataEmOriginalLevel?: number | null;
  metaDataEmCorrectLevel?: number | null;
  metaDataPricingCorrectPrice?: number | null;
  metaDataUnitsCorrectUnits?: number | null;
}
export interface AuditFindingFormValues
  extends AuditFindingFormValuesDeprecated {
  auditFindingSeedType?: string;
  auditFindingRuleType?: string;
  improperPaymentUnitsCharged?: number;
  improperPaymentCost?: number;
  confidence?: number;
  improperPaymentReason?: string;
}
export interface BatchClaimLineItemFormValues {
  revCodeValue?: UUID | null;
  dateOfServiceStart?: string | null;
  procedureCode?: string | null;
  itemNumber?: string | null;
  description?: string | null;
  units?: number | null;
  unitCharge?: number | null;
}
export enum AuditFindingSeedType {
  UB_CLAIM = 'UB_CLAIM',
  UB_CLAIM_LINE = 'UB_CLAIM_LINE',
  IB_CLAIM_LINE = 'IB_CLAIM_LINE',
}

// IMPROVEMENT: Partial types to replace with full types when GQL typings enabled:
type AuditFindingLike = {
  id: UUID;
  batchClaimLineId: UUID;
  auditFindingSeedType: AuditFindingSeedType;
  revCode: string;
  auditFindingBeingVersioned?: {
    ibLineId: UUID;
  };
  batchClaimLineItemAudit?: {
    batchClaimLineItem: BatchClaimLineItem;
  };
  findingItemizedBillData?: FindingItemizedBillData;
};
type BatchClaimLineLike = {
  id: UUID;
  units: number;
  revCode: string;
};
type BatchClaimLike = {
  id: UUID;
  batchClaimLines: {
    nodes: BatchClaimLineLike[];
  };
  amountReimbursed: number;
};

const isDefined = (v: unknown) => v !== undefined && v !== null;

/**
 * predictate type-guard for narrowing e.g. auditFindingFormValues to CreateAuditFindingValuesInput
 * @param formValues
 */
const isValidCreateAuditFindingValuesInput = (
  formValues: unknown
): formValues is CreateAuditFindingValuesInput => {
  const t = formValues as CreateAuditFindingValuesInput;
  return (
    isDefined(t.auditFindingRuleType) &&
    // isDefined(t.confidence) &&
    isDefined(t.improperPaymentCost) &&
    isDefined(t.improperPaymentReason) &&
    isDefined(t.improperPaymentUnitsCharged)
  );
};

const extractCreateAuditFindingValuesInput = (
  afFormValues: AuditFindingFormValues
): CreateAuditFindingValuesInput => {
  if (!isValidCreateAuditFindingValuesInput(afFormValues)) {
    throw new Error(
      'Missing required properties to extract CreateAuditFindingInput'
    );
  }
  const {
    improperPaymentUnitsCharged,
    improperPaymentCost,
    auditFindingRuleType,
    confidence,
    improperPaymentReason,
    metaDataNcciMueOhpMueValue,
    metaDataNcciMueAjudicationValue,
    metaDataNcciPtpCode,
    metaDataNcciPtpModifier,
    metaDataAocPrimaryCode,
    metaDataEmOriginalLevel,
    metaDataEmCorrectLevel,
    metaDataPricingCorrectPrice,
    metaDataUnitsCorrectUnits,
  } = afFormValues;

  return {
    improperPaymentUnitsCharged,
    improperPaymentCost,
    auditFindingRuleType,
    confidence: confidence ?? 50,
    improperPaymentReason,
    metaDataNcciMueOhpMueValue,
    metaDataNcciMueAjudicationValue,
    metaDataNcciPtpCode,
    metaDataNcciPtpModifier,
    metaDataAocPrimaryCode:
      metaDataAocPrimaryCode && Array.isArray(metaDataAocPrimaryCode)
        ? metaDataAocPrimaryCode.join(',')
        : metaDataAocPrimaryCode,
    metaDataEmOriginalLevel,
    metaDataEmCorrectLevel,
    metaDataPricingCorrectPrice,
    metaDataUnitsCorrectUnits,
  };
};

/**
 * Extracts finding values using extractCreateAuditFindingValuesInput and removes null
 * properties, assuming they're non-required and no point round-tripping them
 * @param existingAuditFinding
 */
const extractExistingFindingValues = (existingAuditFinding: AuditFindingLike) =>
  Object.fromEntries(
    Object.entries(
      extractCreateAuditFindingValuesInput(existingAuditFinding)
    ).filter(([_k, v]) => v != null)
  );

const validateSeedType = (
  expectedSeedType: AuditFindingSeedType,
  auditFindingFormValues: AuditFindingFormValues
) => {
  if (auditFindingFormValues.auditFindingSeedType !== expectedSeedType) {
    throw new Error(
      `Seed type mismatch, expected UB_CLAIM_LINE, received ${auditFindingFormValues.auditFindingSeedType}`
    );
  }
};

export const createUbClaimFindingInput = ({
  authorId,
  batchClaim,
  auditFindingFormValues,
}: {
  authorId: string;
  batchClaim: BatchClaimLike;
  auditFindingFormValues: AuditFindingFormValues;
}) => {
  validateSeedType(AuditFindingSeedType.UB_CLAIM, auditFindingFormValues);
  const auditFindingValues = extractCreateAuditFindingValuesInput({
    ...auditFindingFormValues,
    // UB Claim: use the whole value of the claim for improper payment units/cost:
    improperPaymentUnitsCharged: batchClaim.batchClaimLines.nodes.reduce(
      (total: number, { units }: { units: number }) => total + units,
      0
    ),
    improperPaymentCost: batchClaim.amountReimbursed,
  });

  const input = {
    authorId,
    batchClaimIds: [batchClaim.id],
    auditFindingValues,
  };

  return input;
};

export const createUbClaimLineFindingInput = ({
  authorId,
  batchClaim,
  ubTabBatchClaimLineId, // via getBatchClaimLineId()
  auditFindingFormValues,
}: {
  authorId: string;
  batchClaim: BatchClaimLike;
  ubTabBatchClaimLineId: string;
  auditFindingFormValues: AuditFindingFormValues;
  propValues: any;
}) => {
  validateSeedType(AuditFindingSeedType.UB_CLAIM_LINE, auditFindingFormValues);
  const auditFindingValues = extractCreateAuditFindingValuesInput(
    auditFindingFormValues
  );

  const input = {
    authorId,
    batchClaimId: batchClaim.id,
    batchClaimLineIds: [ubTabBatchClaimLineId],
    auditFindingValues,
  };

  return input;
};

export const parseRevCodeValue = (bcliIdAndRevCodeFromForm: string) => {
  // IMPROVEMENT: improve the combobox implementation so that we don't need to do this:
  const [batchClaimLineId, revCode] = bcliIdAndRevCodeFromForm.split(':');
  return { batchClaimLineId, revCode };
};

type IbLineFindingInfoPropValues = {
  originalAuditFinding?: AuditFinding;
  nnIbLineFingerprints?: string[];
  ibData?: ItemizedBillLine[];
  ibLineAdjustments?: FindingAdjustments[];
  s3Key?: string;
};

type IbLineFindingInfo = {
  authorId: string;
  batchClaim: BatchClaimLike;
  auditFindingFormValues: AuditFindingFormValues;
  batchClaimLineItemFormValues: BatchClaimLineItemFormValues;
  propValues: IbLineFindingInfoPropValues;
};

const ibLineToBcliInput: (
  params: Pick<
    ItemizedBillLine,
    | 'dateOfService'
    | 'procedureCode'
    | 'procedureCodeModifier'
    | 'itemNumber'
    | 'description'
    | 'units'
    | 'totalCharge'
    | 'lineNumber'
    | 'versionFingerprint' // ib_line_version_fingerprint in bcli
  >
) => BatchClaimLineItemFormValues = ({
  dateOfService: dateOfServiceStart,
  procedureCode,
  procedureCodeModifier,
  itemNumber,
  description,
  units,
  totalCharge: unitCharge,
  lineNumber,
  versionFingerprint: ibLineVersionFingerprint,
}) => ({
  dateOfServiceStart,
  procedureCode,
  procedureCodeModifier,
  itemNumber,
  description,
  units,
  unitCharge,
  lineNumber,
  ibLineVersionFingerprint,
});

export const getValidatedIbLineAdjustments = (
  ibLine: ItemizedBillLine,
  ibLineAdjustments: FindingAdjustments[]
): Pick<
  FindingAdjustments,
  'improperPaymentUnitsCharged' | 'improperPaymentCost' | 'amountCharged'
> & { originalBilledAmount: number } => {
  const iblAdj =
    ibLineAdjustments?.find(f => f.ibLineId === ibLine.id) ||
    ({} as FindingAdjustments);
  const improperPaymentCost =
    iblAdj.overrides?.improperPaymentCost ?? iblAdj.improperPaymentCost;
  const amountCharged = iblAdj.overrides.amountCharged ?? iblAdj.amountCharged;
  const improperUnits = iblAdj.improperPaymentUnitsCharged;
  const errors = [
    ...(or0(iblAdj.amountCharged) <= 0 ? ['Billed Amount must be > 0'] : []),
    ...(or0(improperUnits) <= 0 ? ['Discrepant Quantity must be > 0'] : []),
    ...(or0(improperPaymentCost) <= 0 ? ['Adjustment Amount must be > 0'] : []),
  ];
  if (errors.length > 0) {
    throw new Error(
      `${errors.join('; ')}  (line: ${ibLine.lineNumber} - ${
        ibLine.description
      })`
    );
  }
  return {
    improperPaymentUnitsCharged: or0(improperUnits),
    improperPaymentCost: or0(improperPaymentCost),
    amountCharged: or0(amountCharged),
    originalBilledAmount: or0(iblAdj.amountCharged),
  };
};

export const createIbLineFindingInput = ({
  authorId,
  batchClaim,
  auditFindingFormValues,
  batchClaimLineItemFormValues,
  propValues,
}: IbLineFindingInfo) => {
  validateSeedType(AuditFindingSeedType.IB_CLAIM_LINE, auditFindingFormValues);

  const auditFindingValues = extractCreateAuditFindingValuesInput(
    (propValues?.ibLineAdjustments?.length || 0) > 0
      ? {
          // if it's an IBIN tab finding, we'll use the passed per-line improper units/cost values in the map below,
          // but they won't be in the form -- here just make sure we pass validation:
          improperPaymentUnitsCharged: 0,
          improperPaymentCost: 0,
          //add rest of values - if it's not IBIN tab finding, form will have improper values above and overwrite them:
          ...auditFindingFormValues,
        }
      : auditFindingFormValues
  );

  const { revCodeValue, ...batchClaimLineItemInputValues } =
    batchClaimLineItemFormValues;

  // IMPROVEMENT: improve the combobox implementation so that we don't need to do this:
  // const [batchClaimLineId, revCode] = (
  //   revCodeValue || batchClaimLine.id + ':' + batchClaimLine.revCode
  // ).split(':');
  const { batchClaimLineId, revCode } = parseRevCodeValue(revCodeValue!);

  if (!batchClaimLineId || !revCode) {
    const errMsg =
      'Create finding(s) failed; Rev Code/UB Line association could not be determined.  Please contact support.';
    throw new Error(
      `${errMsg} (revCode: ${revCode}, batchClaimLineId: ${batchClaimLineId}`
    );
  }

  const { nnIbLineFingerprints, s3Key } = propValues || {};

  const nnIbLineValues: { fingerprint: string; s3Key: string }[] | undefined =
    !s3Key
      ? undefined
      : nnIbLineFingerprints
          ?.filter(f => !!f)
          .map((fingerprint: string) => ({
            fingerprint,
            s3Key,
          }));

  const ibLines = propValues?.ibData || [];

  const input = {
    authorId,
    batchClaimId: batchClaim.id,
    batchClaimLineId,
    // make multiple findings, one for each ib line selected
    values:
      ibLines.length > 0
        ? ibLines.map(ibLine => {
            // if it's an ibex tab finding, use the passed per-line values
            const {
              improperPaymentUnitsCharged,
              improperPaymentCost,
              amountCharged,
              originalBilledAmount,
            } = getValidatedIbLineAdjustments(
              ibLine,
              propValues?.ibLineAdjustments || []
            );
            return {
              auditFindingValues: {
                ...auditFindingValues,
                improperPaymentUnitsCharged,
                improperPaymentCost,
              },
              batchClaimLineItemValues: {
                revCode,
                ...ibLineToBcliInput(ibLine),
                unitCharge: amountCharged,
                originalBilledAmount,
                ibLineId: ibLine.id,
                alaRowId: ibLine.alaRowId,
              },
            };
          })
        : nnIbLineValues && nnIbLineValues.length > 0
          ? nnIbLineValues.map(
              (nnIbLineValue: { fingerprint: string; s3Key: string }) => ({
                auditFindingValues,
                batchClaimLineItemValues: {
                  nnIbLineValues: [nnIbLineValue],
                  revCode,
                  ...batchClaimLineItemInputValues,
                  alaRowId: nnIbLineValue.fingerprint,
                },
              })
            )
          : [
              // make a single finding with nnIbLineValues set to null
              {
                auditFindingValues,
                batchClaimLineItemValues: {
                  nnIbLineValues,
                  revCode,
                  ...batchClaimLineItemInputValues,
                },
              },
            ],
  };

  return input;
};

interface VersionFindingInputArgs {
  authorId: string;
  batchClaim: BatchClaimLike;
  auditFindingBeingVersioned: AuditFindingLike;
  auditFindingFormValues: AuditFindingFormValues;
  propValues: any;
}
const validateVersionSeedType = (
  auditFindingFormValues: AuditFindingFormValues,
  auditFindingBeingVersioned: AuditFindingFormValues
) => {
  if (
    !!auditFindingFormValues.auditFindingSeedType && // no need check if no assignment
    auditFindingFormValues.auditFindingSeedType !==
      auditFindingBeingVersioned.auditFindingSeedType
  ) {
    const compVals = `new: ${auditFindingFormValues.auditFindingSeedType} / existing: ${auditFindingBeingVersioned.auditFindingSeedType}`;
    throw new Error(`Audit finding seed type cannot be changed! (${compVals})`);
  }
};

export const versionFindingInput = ({
  authorId,
  auditFindingBeingVersioned,
  auditFindingFormValues,
}: VersionFindingInputArgs): VersionAuditFindingsInput => {
  validateVersionSeedType(auditFindingFormValues, auditFindingBeingVersioned);
  const auditFindingValues = extractCreateAuditFindingValuesInput({
    ...extractExistingFindingValues(auditFindingBeingVersioned),
    ...auditFindingFormValues,
  });
  const auditFindingReviewValues = [
    {
      auditFindingId: auditFindingBeingVersioned.id,
      auditFindingValues,
    },
  ];
  const input = {
    authorId,
    auditFindingReviewValues,
  };
  return input;
};

interface VersionIbFindingInputArgs extends VersionFindingInputArgs {
  batchClaimLineItemFormValues: BatchClaimLineItemFormValues;
  batchClaimLine: BatchClaimLineLike;
  propValues: IbLineFindingInfoPropValues;
}

export const versionIbFindingInput = (
  input: VersionIbFindingInputArgs
): VersionAuditFindingsInput => {
  validateVersionSeedType(
    input.auditFindingFormValues,
    input.auditFindingBeingVersioned
  );
  const vi = versionFindingInput(input);

  const ibLineId =
    input.auditFindingBeingVersioned?.findingItemizedBillData?.ibLineId ??
    input.auditFindingBeingVersioned?.batchClaimLineItemAudit
      ?.batchClaimLineItem?.ibLineId;
  const alaRowId =
    input.auditFindingBeingVersioned?.findingItemizedBillData?.alaRowId ??
    input.auditFindingBeingVersioned?.batchClaimLineItemAudit
      ?.batchClaimLineItem?.alaRowId;

  const ibLines = input.propValues?.ibData || [];

  const { revCodeValue, ...batchClaimLineItemInputValues } =
    input.batchClaimLineItemFormValues;
  // for ib findings, the form won't populate the field unless it's changed, so
  // grab it off the existing auditFinding if undefined
  const { batchClaimLineId, revCode } = revCodeValue
    ? parseRevCodeValue(revCodeValue)
    : {
        batchClaimLineId: input.auditFindingBeingVersioned.batchClaimLineId,
        revCode:
          input.auditFindingBeingVersioned.batchClaimLineItemAudit
            ?.batchClaimLineItem?.revCode,
      };
  // We shouldn't be able to error here as revCode is populated for all rows a/o the IBIN tab release, and
  // the admin/auditor query includes it -- and obviously there should be an existing finding since we're
  // version specific here... but given the bug in EN-1361 let's make sure...
  if (!batchClaimLineId || !revCode) {
    const errMsg =
      'Version finding failed; Rev Code/UB Line association could not be determined.  Please contact support.';
    throw new Error(
      `${errMsg} (revCode: ${revCode}, batchClaimLineId: ${batchClaimLineId}, finding: ${input.auditFindingBeingVersioned.id}`
    );
  }

  // FIXME: this function still has the structure of supporting multi-finding-versioning below,
  // however it's mismatched in terms of only one finding being passed in, which is used for values
  // above and below... this is fine currently as we don't support multi-finding versioning for IB...
  // HOWEVER it is high on priority list to do so; this will require update & good testing, especially
  // regarding proper association of ibLine data / adjustments to the correct finding row...

  if (ibLines.length > 0) {
    // IBIN TAB finding review
    return {
      ...vi,
      auditFindingReviewValues: vi.auditFindingReviewValues.map(afrv => {
        // if it's an ibex tab finding, use the passed per-line values

        if (!ibLineId && !alaRowId) {
          throw new Error(
            'Missing Row IDs; no ibLineId/alaRowId found on finding!'
          );
        }

        const ibLine = ibLines.find(ibl =>
          ibLineId ? ibl.id === ibLineId : ibl.alaRowId === alaRowId
        );

        if (!ibLine) {
          throw new Error('ibLineId not found on finding!');
        }

        const {
          improperPaymentUnitsCharged,
          improperPaymentCost,
          amountCharged,
          originalBilledAmount,
        } = getValidatedIbLineAdjustments(
          ibLine,
          input.propValues?.ibLineAdjustments || []
        );
        return {
          ...afrv,
          auditFindingValues: {
            ...afrv.auditFindingValues,
            improperPaymentUnitsCharged,
            improperPaymentCost,
          },
          batchClaimLineId, // ci.batchClaimLineId, // input.batchClaimLine.id,
          batchClaimLineItemValues: {
            revCode,
            ...ibLineToBcliInput(ibLine),
            unitCharge: amountCharged,
            originalBilledAmount,
            ibLineId: ibLine.id,
            alaRowId: ibLine.alaRowId,
          },
        };
      }),
    };
  }

  // CSV TAB finding review
  return {
    ...vi,
    auditFindingReviewValues: vi.auditFindingReviewValues.map(afrv => ({
      ...afrv,
      batchClaimLineId, // ci.batchClaimLineId, // input.batchClaimLine.id,
      batchClaimLineItemValues: {
        ...batchClaimLineItemInputValues,
        revCode,
        // if(ibData)
        ibLineId,
        alaRowId,
      },
    })),
  };
};
