import { css } from '@emotion/react';
import { Spin } from 'antd';

const LoadingSpinner = () => (
  <div
    css={css`
      text-align: center;
      margin-top: 5%;
    `}
  >
    <Spin size="large" />
  </div>
);

export default LoadingSpinner;
