import interact from 'interactjs';
import { EventTypes } from '@interactjs/core/types';

const overlayActiveZIndex = '9999';
const containerShowZIndex = '3';
const containerShowVisibility = 'visible';

const CONTAINER_SELECTOR = 'div#pdfviewer-container';
const HANDLE_SELECTOR = 'div#pdfviewer-drag-handle';
const TITLE_SELECTOR = 'div#pdfviewer-title';
const NAV_SELECTOR = 'div#pdfviewer-nav';
const CLOSE_SELECTOR = 'div#pdfviewer-close';
const CLOSE_SELECTOR_LEFT = 'div#pdfviewer-close-left';
const OVERLAY_SELECTOR = 'div#pdfviewer-overlay';
const HRESIZE_SELECTOR = 'div#pdfviewer-hresize';

type GetEl = () => HTMLDivElement | null;
const getContainer: GetEl = () => document.querySelector(CONTAINER_SELECTOR);
const getHandle: GetEl = () => document.querySelector(HANDLE_SELECTOR);
const getTitle: GetEl = () => document.querySelector(TITLE_SELECTOR);
const getNav: GetEl = () => document.querySelector(NAV_SELECTOR);
const getClose: GetEl = () => document.querySelector(CLOSE_SELECTOR);
const getCloseLeft: GetEl = () => document.querySelector(CLOSE_SELECTOR_LEFT);
const getOverlay: GetEl = () => document.querySelector(OVERLAY_SELECTOR);
const getHResize: GetEl = () => document.querySelector(HRESIZE_SELECTOR);

type CVCArgs = { allowDrag: boolean };
const createViewerControls = ({ allowDrag }: CVCArgs) => {
  const toRestore = {
    container: {
      zIndex: '1',
      visibility: 'hidden',
    },
    overlay: {
      zIndex: '-2',
    },
  };
  const isAllowInteraction = () => {
    const overlay = getOverlay();
    if (!overlay) {
      return false;
    } // todo is this too frail?:
    return overlay.style.zIndex !== overlayActiveZIndex;
  };

  const disableInteraction = () => {
    const overlay = getOverlay();
    if (overlay) {
      toRestore.overlay.zIndex = overlay.style.zIndex;
      overlay.style.zIndex = overlayActiveZIndex;
    }
  };

  const allowInteraction = () => {
    const overlay = getOverlay();
    if (overlay) {
      overlay.style.zIndex = toRestore.overlay.zIndex;
    }
  };

  const isOpen = () => {
    const container = getContainer();
    if (!container) {
      return false;
    }
    return container.style.visibility === containerShowVisibility;
  };

  const open = () => {
    const container = getContainer();
    if (container && !isOpen()) {
      // toRestore.container.zIndex = container.style.zIndex;
      // container.style.zIndex = containerShowZIndex;
      toRestore.container.visibility = container.style.visibility;
      container.style.visibility = containerShowVisibility;
    }
  };

  const close = () => {
    const container = getContainer();
    if (container && isOpen()) {
      // container.style.zIndex = toRestore.container.zIndex;
      container.style.visibility = toRestore.container.visibility;
    }
  };

  if (getClose()) {
    getClose()!.addEventListener('click', close);
  }

  if (getCloseLeft()) {
    getCloseLeft()!.addEventListener('click', close);
  }

  // DRAG
  const draggable = {};

  if (allowDrag) {
    wireDrag([
      {
        eventList: ['dragstart', 'resizestart'],
        eventHandler: disableInteraction,
      },
      { eventList: ['dragend', 'resizeend'], eventHandler: allowInteraction },
    ]);
  }

  return {
    open,
    close,
    isOpen,
    isAllowInteration: isAllowInteraction,
    disableInteraction,
    allowInteraction,
    isDraggable: allowDrag,
    draggable,
    refs: {
      getContainer,
      getHandle,
      getTitle,
      getNav,
      getClose,
      getOverlay,
    },
  };
};

export type PdfContainer = ReturnType<typeof createViewerControls>;

const pdfContainer: PdfContainer = createViewerControls({ allowDrag: true });

export default pdfContainer;

///
function dragMoveListener(event: any) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

  // update the position attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}
///
function resizeMoveListener(event: any) {
  var target = event.target;
  var x = parseFloat(target.getAttribute('data-x')) || 0;
  var y = parseFloat(target.getAttribute('data-y')) || 0;

  // update the element's style
  target.style.width = event.rect.width + 'px';
  target.style.height = event.rect.height + 'px';

  // translate when resizing from top or left edges
  x += event.deltaRect.left;
  y += event.deltaRect.top;

  target.style.transform = 'translate(' + x + 'px,' + y + 'px)';

  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}
///
///
function wireDrag(
  events: { eventList: Interact.EventTypes; eventHandler: Interact.Listener }[]
) {
  const _interact = interact('div#pdfviewer-container');
  for (const event of events) {
    _interact.on(event.eventList, event.eventHandler);
  }
  _interact
    .resizable({
      // resize from all edges and corners
      edges: {
        left: true,
        right: getHResize()!,
        bottom: true,
        top: true,
      },
      ignoreFrom: 'div#pdfviewer-drag-handle',
      listeners: {
        move: resizeMoveListener,
      },
      modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
          outer: 'parent',
        }),

        // minimum size
        interact.modifiers.restrictSize({
          min: { width: 100, height: 50 },
        }),
      ],

      inertia: true,
    })
    .draggable({
      allowFrom: 'div#pdfviewer-drag-handle',
      listeners: { move: dragMoveListener },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true,
        }),
      ],
    });
}
