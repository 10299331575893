/**
 * THIS COMPONENT OUT OF DATE - JUST A PLACEHOLDER FOR ALT TYPE OF EDIT CONTROL
 */

import { FC, ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { Popover } from 'antd';
import { UUID } from '../../createAuditFinding/auditFindingUtil';
import { FindingContext } from '../findingProvider';
import {
  AuditFindingSeedType,
  ItemizedBillLine,
} from '../../../../../gql/graphql';

type FindingInlineProps = {
  seedType: AuditFindingSeedType;
  children: ReactNode;
  rowId: string;
  ibData?: ItemizedBillLine[];
  claimId: UUID;
};

export const FindingInline: FC<FindingInlineProps> = ({
  seedType,
  claimId,
  rowId,
  ibData,
  children,
}) => {
  const { findingEditor, inlineOpenId } = useContext(FindingContext);
  console.log('findingInline render', inlineOpenId);
  const isOpen = useMemo(() => inlineOpenId === rowId, [inlineOpenId]);
  return (
    <Popover
      onOpenChange={open => {
        console.log('open');
        findingEditor.showInline({
          rowId,
          operation: { operation: 'create', seedType, ibData },
        });
      }}
      open={isOpen}
      title={findingEditor.title + ' Inline'}
      trigger="click"
      key={rowId}
      // content={
      //   isOpen ? (
      //     <FindingForm
      //       onCancel={() => findingEditor.hideInline()}
      //       onSave={(values: any) => alert('todo convert to current code!')}
      //       findingEditor={findingEditor}
      //     ></FindingForm>
      //   ) : (
      //     <div>Is open flag is stale</div>
      //   )
      // }
    >
      {children}
    </Popover>
  );
};
