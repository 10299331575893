import { Drawer, Space, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { AuditDocumentation } from '../../../gql/graphql';
import { AskAutodor } from './askAutodor';
import { AskAutodorLogo } from './askAutodorLogo';
import pdfContainer from '../../../pdf/container';

const { Text } = Typography;

type AskAutodorDrawerOptions = {
  documents: AuditDocumentation[];
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  claimId: string;
  setDocumentationViewerProps: Function;
};

export const AskAutodorDrawer = ({
  documents,
  open,
  setOpen,
  claimId,
  setDocumentationViewerProps,
}: AskAutodorDrawerOptions) => {
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(500);

  const minWidth = 50;
  const maxWidth = 1000;
  // this threshold determines when we reset the Drawer width and thus trigger a re-render
  // re-rendering to often creates lag and re-rendering not enough makes the user need to drag long lengths before seeing a re-size (both are problematic)
  // generally 15-40 seems to be the acceptable range
  const resizeThreshold = 20;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  //        onResizeStart={() => pdfContainer.disableInteraction()}
  //         onResizeStop={() => pdfContainer.allowInteraction()}
  const onMouseDown = () => {
    // pdfContainer.disableInteraction();
    setIsResizing(true);
  };

  const onMouseUp = () => {
    // pdfContainer.allowInteraction();
    setIsResizing(false);
  };

  const onMouseMove = (e: any) => {
    if (isResizing) {
      const offsetRight =
        document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      if (
        offsetRight > minWidth &&
        offsetRight < maxWidth &&
        Math.abs(offsetRight - width) >= resizeThreshold
      ) {
        setWidth(offsetRight);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return (
    <Drawer
      title={
        <div tw="_flex">
          <AskAutodorLogo />
          <Space size="large" tw="_self-auto _text-xs">
            <Text tw="_text-xs" type="secondary">
              AI powered MR search
            </Text>
          </Space>
        </div>
      }
      placement={'right'}
      onClose={onClose}
      open={open}
      mask={false} // this enables that entities being overlaid by the drawer are still accessible without closing drawer
      bodyStyle={{
        marginTop: 0,
        marginBottom: 0,
        paddingTop: '0.75rem',
        paddingBottom: '3rem',
      }}
      headerStyle={{ paddingRight: '0.75rem' }}
      width={width}
      key={'autodor_drawer'}
    >
      <div
        style={{
          position: 'absolute',
          width: '2px',
          padding: '4px 0 0',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          cursor: 'ew-resize',
        }}
        onMouseDown={onMouseDown}
      />
      <AskAutodor
        setDocumentationViewerProps={setDocumentationViewerProps}
        claimId={claimId}
        documents={documents}
      ></AskAutodor>
    </Drawer>
  );
};
