import React from 'react';
import { Global, css } from '@emotion/react';
import moment from 'moment-timezone';

import { Space, Divider, Statistic, Typography, Avatar } from 'antd';
import { Comment } from '@ant-design/compatible';

import {
  ClockCircleOutlined,
  RobotOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { AuditFindingMetaDataDescription } from './auditFindingMetaDataDescription';
import { UserContext } from '../../../context/user';

const AuditFindingComment = props => {
  const {
    auditFinding,
    auditFinding: {
      improperPaymentCost,
      improperPaymentUnitsCharged,
      createdAt,
      auditorComment,
      improperPaymentReason,
      author: { firstName, lastName, userType },
    },
  } = props;

  const {
    workstation: { hides },
  } = React.useContext(UserContext);

  // Check that the review is not deleted, then set reviewed to the reviewedAuditFinding
  // otherwise null
  const reviewed =
    auditFinding.reviewedAuditFinding &&
    !auditFinding.reviewedAuditFinding.deleted &&
    auditFinding.reviewedAuditFinding;

  const toParagraphs = rationale =>
    rationale
      ? rationale
          .split('\n')
          .map((text, i) => <Typography key={i}>{text}</Typography>)
      : null;

  const commentProps = {
    ...(hides.auditFindingComment.author
      ? {}
      : {
          author: `${firstName} ${lastName}`,
          avatar: (
            <Avatar
              icon={userType === 'SYS' ? <RobotOutlined /> : <UserOutlined />}
            />
          ),
        }),
    content: (
      <Space
        direction="vertical"
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
          `}
        >
          <Space direction="vertical" style={{ flex: '2 1 auto' }}>
            <Statistic
              title="Adjustment Amount"
              data-cy="audit-finding-comment-adjustmentAmount"
              id="audit-finding-comment-adjustmentAmount"
              prefix="$"
              value={improperPaymentCost}
              precision={2}
            />
            <Statistic
              title="Discrepant Quantity"
              value={improperPaymentUnitsCharged}
            />
          </Space>
          <Divider
            type="vertical"
            css={css`
              height: 5em;
              alignself: center;
              flex: 1 1 auto;
            `}
          />
          <Space
            data-cy="audit-finding-comment-meta-desc-container"
            direction="vertical"
            style={{ flex: '2 1 auto' }}
          >
            <AuditFindingMetaDataDescription
              auditFinding={auditFinding}
              style={{ flex: '2 1 auto', alignSelf: 'center' }}
            />
          </Space>
        </div>
        <span className="audit_finding_comment_title">Rationale</span>
        <span data-cy="audit-finding-comment-rationale">
          {toParagraphs(improperPaymentReason || auditorComment)}
        </span>
        {reviewed && !reviewed.accepted && (
          <>
            <span className="audit_finding_comment_title">Declination</span>
            <span data-cy="audit-finding-comment-declination">
              {toParagraphs(reviewed.rationale)}
            </span>
          </>
        )}
        <Global
          style={css`
            .audit_finding_comment_title {
              margin-bottom: 4px;
              color: rgba(0, 0, 0, 0.45);
              font-size: 14px;
            }
          `}
        />
      </Space>
    ),
    datetime: (
      <Space>
        <ClockCircleOutlined />
        <span data-cy="audit-finding-comment-created">
          {moment.utc(createdAt).format('MM-DD-YY HH:mm:ss')}
        </span>
      </Space>
    ),
  };

  return <Comment {...commentProps} />;
};

export { AuditFindingComment };
