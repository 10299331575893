import { theme } from 'antd';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { Disclosure } from '@headlessui/react';

import resolveConfig from 'tailwindcss/resolveConfig';
import twConfig from '../../../../tailwind.config.js';

const tailwindConfig = resolveConfig(twConfig as any);

const getColorCss = (c: string) =>
  css({
    backgroundColor: c,
    '.text': {
      color: 'rgb(255, 255, 255)',
      mixBlendMode: 'difference',
    },
  });

const objectToKvColorArray = (prefix: string, object: Object) =>
  Object.entries<any>(object).reduce<{ name: string; color: string }[]>(
    (acc, [k, v]) => {
      if (v!.startsWith && (v.startsWith('#') || v.startsWith('rgb'))) {
        acc.push({
          name: `${(prefix && prefix + '-') ?? ''}${k}`,
          color: v as string,
        });
      }
      return acc;
    },
    []
  );

const nestedObjectToColorArray = (object: Object) =>
  Object.entries<any>(object).reduce<{ name: string; color: string }[]>(
    (acc, [k, v]) => {
      console.log(k, v);
      if (typeof v === 'object') {
        const subcolors = objectToKvColorArray(k, v);
        if (subcolors.length > 0) {
          acc.push(...subcolors);
        }
      } else if (v!.startsWith && v.startsWith('#')) {
        acc.push({ name: k, color: v as string });
      }
      return acc;
    },
    []
  );

export const Colors = () => {
  const { token } = theme.useToken();
  return (
    <>
      <Disclosure>
        <Disclosure.Button tw="_py-2 _w-full">
          tailwind theme colors
        </Disclosure.Button>
        <Disclosure.Panel tw="_text-gray-500">
          <div tw="_grid _grid-cols-3 _gap-y-4">
            <>
              {nestedObjectToColorArray(
                tailwindConfig?.theme?.colors ?? {}
              ).map(cObj => (
                <>
                  <div tw="_p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.name}</span>
                  </div>
                  <div tw="_col-span-2 _p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.color}</span>
                  </div>
                </>
              ))}
            </>
          </div>
        </Disclosure.Panel>
      </Disclosure>

      <Disclosure>
        <Disclosure.Button tw="_py-2 _w-full">
          tailwind theme tokens object
        </Disclosure.Button>
        <Disclosure.Panel tw="_text-gray-500">
          {/*<pre>{JSON.stringify(tailwindColors, null, 3)}</pre>*/}
          <pre>{JSON.stringify(tailwindConfig, null, 3)}</pre>
        </Disclosure.Panel>
      </Disclosure>

      <Disclosure>
        <Disclosure.Button tw="_py-2 _w-full">
          antd theme colors
        </Disclosure.Button>
        <Disclosure.Panel tw="_text-gray-500">
          <div tw="_grid _grid-cols-3 _gap-y-4">
            <>
              {objectToKvColorArray('', token ?? {}).map(cObj => (
                <>
                  <div tw="_p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.name}</span>
                  </div>
                  <div tw="_col-span-2 _p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.color}</span>
                  </div>
                </>
              ))}
            </>
          </div>
        </Disclosure.Panel>
      </Disclosure>
      <Disclosure>
        <Disclosure.Button tw="_py-2 _w-full">
          antd theme tokens object
        </Disclosure.Button>
        <Disclosure.Panel tw="_text-gray-500">
          <pre>{JSON.stringify(token, null, 3)}</pre>
        </Disclosure.Panel>
      </Disclosure>
    </>
  );
};
