import { useContext, useEffect } from 'react';
import { Timeline, Typography, Spin } from 'antd';
import moment from 'moment-timezone';
import { useQuery } from 'urql';

import { getAllStatePropsAsObject } from '../../claimState/claimStateUtil';
import { eventHistoryTimelineQuery } from '../../../fragments';
import { UserContext } from '../../context/user';

/**
 * @param {string} formatDate Date string in any timezone
 * @returns {string} Date String in local time
 */
const formatDateLocalTime = formatDate => {
  const dateInSeconds = parseInt(formatDate);
  const startOfToday = moment(
    moment(Date.now()).local().startOf('day')
  ).local();
  const secondsPassedSinceEvent = moment
    .duration(moment(Date.now()).local().diff(moment(dateInSeconds).local()))
    .asSeconds();

  switch (true) {
    case secondsPassedSinceEvent <= 60: // last minute
      return `less than 1 minutes ago`;
    case secondsPassedSinceEvent <= 3600: // last hour
      return `${Math.floor(secondsPassedSinceEvent / 60)} minutes ago`;
    case secondsPassedSinceEvent <
      moment
        .duration(moment(Date.now()).local().diff(startOfToday))
        .asSeconds(): // today
      return `Today at ${moment(dateInSeconds).local().format('h:mm a')}`;
    case secondsPassedSinceEvent >=
      moment
        .duration(moment(Date.now()).local().diff(startOfToday))
        .asSeconds() && secondsPassedSinceEvent <= 86400: // yesterday within 24 hours
      return `Yesterday at ${moment(dateInSeconds).local().format('h:mm a')}`;
    case true: // anything else
      return moment(dateInSeconds).local().format('ddd MMM Do YYYY, h:mm a');
  }
};

/**
 * @param {string} formatDate Date string in any timezone
 * @returns {string} Date String in UTC
 */
const formatDateUTC = formatDate => {
  const dateInSeconds = parseInt(formatDate);
  const startOfToday = moment(moment(Date.now()).utc().startOf('day')).utc();
  const secondsPassedSinceEvent = moment
    .duration(moment(Date.now()).utc().diff(moment(dateInSeconds).utc()))
    .asSeconds();

  switch (true) {
    case secondsPassedSinceEvent <= 60: // last minute
      return `less than 1 minutes ago`;
    case secondsPassedSinceEvent <= 3600: // last hour
      return `${Math.floor(secondsPassedSinceEvent / 60)} minutes ago`;
    case secondsPassedSinceEvent <
      moment.duration(moment(Date.now()).utc().diff(startOfToday)).asSeconds(): // today
      return `Today at ${moment(dateInSeconds).utc().format('h:mm a')}`;
    case secondsPassedSinceEvent >=
      moment
        .duration(moment(Date.now()).utc().diff(startOfToday))
        .asSeconds() && secondsPassedSinceEvent <= 86400: // yesterday within 24 hours
      return `Yesterday at ${moment(dateInSeconds).utc().format('h:mm a')}`;
    case true: // anything else
      return moment(dateInSeconds).utc().format('ddd MMM Do YYYY, h:mm a');
  }
};

const EventHistoryTimeline = ({
  batchClaim: { id: batchClaimId },
  refreshTrigger,
}) => {
  /*

    Returns a list of events that are in the format:

       eventHistoryTimeline(limit: $limit, offset: $offset) {
          events {
            id
            eventType
            action
            metaData
            createdAt: created_at
          }
        }

    NOTE: The gql custom resolver determines which events are returned 
          and how they are queried. Moreover it also filters out 
          certain events & eventTypes based on user role on the backend
          Details on how querying is done is in this file:
          https://github.com/Alaffia-Technology-Solutions/graphql_api/blob/kaan-ala-3277-change-states-to-enums-and-build-pg/src/historyTimeline/queryTemplate.js)

  */
  const [{ fetching, data }, refreshQuery] = useQuery({
    query: eventHistoryTimelineQuery,
    variables: { batchClaimId, limit: 30, offset: 0 },
    requestPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && refreshTrigger) {
      refreshQuery({ requestPolicy: 'network-only' });
    }
  }, [refreshTrigger]);

  const claimStateConfigObject = getAllStatePropsAsObject();

  const { userType } = useContext(UserContext);

  const validEvents = data
    ? data.batchClaim.eventHistoryTimeline.events.filter(
        ({ action, createdAt, metaData }) => {
          return (
            claimStateConfigObject[action].state.timelineEventRendering &&
            claimStateConfigObject[
              action
            ].state.timelineEventRendering.renderingAllowed({
              action,
              createdAt,
              metaData,
              userType,
            })
          );
        }
      )
    : [];
  return fetching ? (
    <Spin size="small" />
  ) : (
    <div data-cy="event-history-timeline-timeline-wrapper-div">
      <Typography.Title level={5} style={{ marginBottom: 25 }}>
        Claim State Event History Timeline (Local Timezone):
      </Typography.Title>

      <Timeline mode="left">
        {validEvents.length === 0 ? (
          <div>No Relevant Actions have taken place on this Claim yet...</div>
        ) : (
          validEvents.map(({ action, createdAt, metaData }, i) => {
            return (
              <Timeline.Item
                color={claimStateConfigObject[action].state.primaryColor}
                data-cy={`event-history-timeline-timeline-item-${action}-${i}`}
                id={`event-history-timeline-timeline-item-${action}-${i}`}
                key={`event-history-timeline-timeline-item-${action}-${i}`}
                dot={claimStateConfigObject[action].state.icon}
                label={
                  claimStateConfigObject[action].state.timelineEventRendering
                    .customLabel ? (
                    claimStateConfigObject[
                      action
                    ].state.timelineEventRendering.customLabel({
                      action,
                      createdAt,
                      metaData,
                      userType,
                    })
                  ) : (
                    <i style={{ fontSize: '90%' }}>
                      {formatDateLocalTime(createdAt)}
                    </i>
                  )
                }
              >
                {claimStateConfigObject[action].state.timelineEventRendering
                  .customRendering ? (
                  claimStateConfigObject[
                    action
                  ].state.timelineEventRendering.customRendering({
                    action,
                    createdAt,
                    metaData,
                    userType,
                  })
                ) : (
                  <strong>
                    {claimStateConfigObject[
                      action
                    ].state.timelineEventRendering.description(userType)}
                  </strong>
                )}
              </Timeline.Item>
            );
          })
        )}
      </Timeline>
    </div>
  );
};

export { EventHistoryTimeline, formatDateUTC, formatDateLocalTime };
