import MainMenu from './mainMenu';
import React, { useMemo } from 'react';
import { useUserPreference } from '../../util/preferences';
import tw from 'twin.macro';
import { FindingEdits } from './findingEdits';
import { HbsPreview } from './hbs/hbsPreview';
import { AA } from './aa';

interface ComponentOption {
  label: string;
  component: React.FC<{}>;
}

export const Playground = () => {
  const [chosenComponent, setChosenComponent, chosenComponentErrors] =
    useUserPreference(`playground.chosenComponent.label`); // persist

  const components = [
    {
      label: 'ask autodor',
      component: AA,
    },
    {
      label: 'finding modal',
      component: FindingEdits,
    },
    { label: 'hbs preview', component: HbsPreview },
    {
      label: 'default',
      component: () => (
        <div
          style={{
            width: '100%',
            minHeight: '100%',
            height: '100%',
            backgroundColor: 'red',
          }}
        >
          this is 2
        </div>
      ),
    },
  ];

  const CurrentComponent = useMemo(
    () =>
      components.find(c => c.label === '' + chosenComponent)?.component ??
      (() => <div>Choose a component</div>),
    [chosenComponent]
  );

  const setComponent = (component: ComponentOption) => {
    if (setChosenComponent) {
      setChosenComponent(component.label);
    }
  };

  return (
    <div
      data-id="pg-1"
      tw="_p-1 _flex _h-full _max-h-full _min-h-full _flex-col _bg-antd-lt-colorBgContainer dark:_bg-antd-lt-colorBgContainer"
    >
      <div data-id="pg-2">
        <MainMenu
          components={components}
          setComponent={setComponent}
        ></MainMenu>
      </div>
      <div data-id="pg-component-wrapper" tw="_flex-1">
        <CurrentComponent></CurrentComponent>
      </div>
    </div>
  );
};
