import React from 'react';
import { css, jsx } from '@emotion/react';
import tw from 'twin.macro';

const AmaVerbiage = () => (
  <div
    id="ama_verbiage"
    css={css({
      fontSize: 'xx-small',
      ...tw`_text-center _pl-2 _pr-2 _mt-0 _mb-0 _pt-0 _pb-0`,
      ...tw`_text-antd-dk-colorWarningBg dark:_text-antd-lt-colorWarningBg _bg-antd-lt-colorBgLayout dark:_bg-antd-dk-colorBgLayout`,
    })}
  >
    CPT® copyright 2019 American Medical Association. All rights reserved. Fee
    schedules, relative value units, conversion factors and/or related
    components are not assigned by the AMA, are not part of CPT, and the AMA is
    not recommending their use. The AMA does not directly or indirectly practice
    medicine or dispense medical services. The AMA assumes no liability for data
    contained or not contained herein. CPT is a registered trademark of the
    American Medical Association.
  </div>
);

export { AmaVerbiage };
