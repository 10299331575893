import {
  createReducer,
  GenericAction,
} from '../../../../../common/reducer/genericReducer';
import { extractRevCodes, revCodesForSelectedIbLines } from './revCodeUtils';
import { PaymentRateUtility } from '../../../util/usePaymentRate';
import { UUID } from '../../../../../../types/ids';
import {
  AuditFindingRuleType,
  AutodorFinding,
  Finding,
  FindingEditorOp,
  isConvertAutodorFindingOp,
  isReviewFindingOp,
} from '../types';
import {
  BatchClaim,
  BatchClaimLine,
  Claim,
  ItemizedBillLine,
} from '../../../../../gql/graphql';
import { cloneDeep, Dictionary, has, keyBy, pick, set } from 'lodash';
import { MutableRefObject } from 'react';
import { FindingModel } from '../model/finding';
import {
  ClassWithReducer,
  ClassWithReducerDispatchers,
  SetStateFn,
} from '../../../../../common/reducer/useClassWithReducer';
import { IbEditFindingStrategy } from './ib/ibEditFindingStrategy';
import { nonNullArr } from '../../../util/array';
import {
  EditFindingState,
  EditFindingStrategy,
  EditFindingStrategyControllerCommands,
  EditFindingValues,
  FindingInput,
  InitialEditFindingValues,
  ValueChangeHandler,
} from './types';
import { Observable, Subject } from 'rxjs';
import { AuditFindingSeedType } from '../../createAuditFinding/auditFindingUtil';
import { DenialCodes } from '../../../queries/denialCodes/useDenialCodes';

export type FieldUpdatesState = {
  fieldUpdates: any;
};

export type FindingViewControllerProps = {
  findingModel: FindingModel;
  paymentRateUtility: PaymentRateUtility;
  denialCodes: DenialCodes;
  currentValuesRef: MutableRefObject<any>;
  claimRefresher: any;
};

const isDispatcherOrThrow = (
  maybeDispatcher: unknown,
  callerName: string,
  callerArgs: any
): maybeDispatcher is ClassWithReducerDispatchers<EditFindingState> => {
  if (!maybeDispatcher) {
    const msg = `FindingViewController::${callerName}: State dispatcher must be set via setDispatcher prior to modifying state`;
    console.log(msg, callerArgs);
    throw new Error(msg);
  }
  return true;
};

export class FindingViewController
  implements ClassWithReducer<EditFindingState>
{
  private findingModel: FindingModel;
  private state: EditFindingState;
  private paymentRateUtility: PaymentRateUtility;
  private denialCodes: DenialCodes;
  private currentValuesRef: MutableRefObject<any>;
  private stateDispatcher:
    | ClassWithReducerDispatchers<EditFindingState>
    | undefined;
  private bclById: Dictionary<BatchClaimLine> = {};
  private dirty: string[] = [];
  private claimRefresher: any;
  private editStrategy: EditFindingStrategy;
  private claim: Claim | undefined;
  private batchClaim: BatchClaim | undefined;
  private bcls: BatchClaimLine[] = [];

  private eventSubject: Subject<any> = new Subject<any>();
  public events: Observable<any> = this.eventSubject.asObservable();

  private stateChangeSubject: Subject<EditFindingState> =
    new Subject<EditFindingState>();
  public stateChanges: Observable<EditFindingState> =
    this.stateChangeSubject.asObservable();

  constructor({
    findingModel,
    paymentRateUtility,
    denialCodes,
    currentValuesRef,
    claimRefresher,
  }: FindingViewControllerProps) {
    this.findingModel = findingModel;
    this.paymentRateUtility = paymentRateUtility;
    this.denialCodes = denialCodes;
    this.currentValuesRef = currentValuesRef;
    this.claimRefresher = claimRefresher;
    this.editStrategy = new IbEditFindingStrategy(); // TODO based on seed
    this.state = cloneDeep(initialState);
  }

  getState = (): EditFindingState => {
    return this.state;
  };

  getInitialState = (): EditFindingState => {
    return this.getState();
  };

  private setLocalState = (state: EditFindingState): EditFindingState => {
    // When constructing state, prior to sending thru reducer, do any initial computations here...

    // get the strategy's (IB/UB/Claim-level) default initial state
    const initialValues: InitialEditFindingValues = {
      defaultValues: this.editStrategy.getFieldDefaults(),
    };

    // modify state to include initial values
    if (state.operation) {
      if (isReviewFindingOp(state.operation)) {
        const finding: Finding = state.operation.finding;
        const bclId = finding.findingItemizedBillData?.batchClaimLineId;
        if (bclId) {
          state.batchClaimLineId = bclId;
          if (this.bclById[bclId]) {
            state.revCode = this.bclById[bclId].revCode;
            const pyRate = this.paymentRateUtility.getActiveLineRate(
              this.bclById[bclId]
            );
            if (pyRate !== null && pyRate !== undefined) {
              state.paymentRate = pyRate;
            } else {
              // TODO should we throw if no Py Rate?  We shouldn't have this situation??
            }
          }
        }

        initialValues.defaultValues =
          this.editStrategy.getFieldInitialValuesFromExistingFinding(
            finding,
            initialValues.defaultValues,
            state
          );
        initialValues.existingFindingValues = initialValues.defaultValues;
      }
      if (isConvertAutodorFindingOp(state.operation)) {
        const suggestion: AutodorFinding = state.operation.autodorFinding;
        initialValues.defaultValues =
          this.editStrategy.getFieldInitialValuesFromAutodorSuggestion(
            suggestion,
            initialValues.defaultValues,
            state
          );
        initialValues.existingFindingValues = initialValues.defaultValues;
      }
    }
    // n.b. this fn does not send it to the reducer, up to caller...
    return { ...state, ...initialValues };
  };

  replaceState = (state: EditFindingState) => {
    if (isDispatcherOrThrow(this.stateDispatcher, 'replaceState', state)) {
      const newState = this.setLocalState(state);
      console.debug('%creplace finding controller state, ', 'color: orange', {
        prevState: state,
        newState,
      });

      this.stateDispatcher?.replaceState(newState);

      // TODO this is a hack to get the ibline adjustments, need to make that callable from
      //  the setLocalState fn for initial values on reviewing an existing finding
      //  in short, if the ib strategy owns the ib adjustments it should be called in
      //  setLocalState above by calling ib strategy which in turn calls it's adustment handlers...
      if (newState.existingFindingValues?.metadata?.batchClaimLineId) {
        const ev = newState.existingFindingValues;
        const { metadata } = ev;
        const updates = {
          changedValues: {
            metadata: {
              // batchClaimLineId: ev.metadata?.batchClaimLineId,
              ...ev.metadata,
            },
          },
          allValues: {
            ...(ev ?? {}),
          },
        };
        setTimeout(() => {
          this.onValuesChange(updates);
        }, 0);
      }
    }
  };

  setState: SetStateFn<EditFindingState> = (path, value) => {
    if (
      isDispatcherOrThrow(this.stateDispatcher, 'setState', { path, value })
    ) {
      this.stateDispatcher.setState(path, value);
    }
  };

  setField: EditFindingStrategyControllerCommands['setField'] = (
    fieldPath,
    value
  ) => {
    const evt: { type: 'setFields'; fields: any } = {
      type: 'setFields',
      fields: set({}, fieldPath, value),
    };
    this.eventSubject.next(evt);
  };

  setFields: EditFindingStrategyControllerCommands['setFields'] = (
    fieldsObj: any
  ) => {
    const evt: { type: 'setFields'; fields: any } = {
      type: 'setFields',
      fields: fieldsObj,
    };
    this.eventSubject.next(evt);
  };

  sendEvent: EditFindingStrategyControllerCommands['sendEvent'] = event => {
    this.eventSubject.next(event);
  };

  private getCommandFunctionsForStrategy =
    (): EditFindingStrategyControllerCommands => {
      if (
        isDispatcherOrThrow(
          this.stateDispatcher,
          'getCommandFunctionsForStrategy',
          null
        )
      ) {
        return {
          setState: this.setState,
          setField: this.setField,
          setFields: this.setFields,
          sendEvent: this.sendEvent,
        };
      }
      throw new Error('unreachable'); // isDispatchOrThrow will throw if unset...
    };

  setDispatcher = (
    stateDispatcher: ClassWithReducerDispatchers<EditFindingState>
  ) => {
    this.stateDispatcher = stateDispatcher;
  };

  init = ({
    claim,
    batchClaim,
    operation,
    paymentRateUtility,
    authorId,
  }: {
    claim: Claim;
    batchClaim: BatchClaim;
    operation: FindingEditorOp;
    paymentRateUtility: PaymentRateUtility;
    authorId: UUID;
  }) => {
    this.claim = claim;
    this.batchClaim = batchClaim;
    this.bcls = nonNullArr(this.batchClaim?.batchClaimLines?.nodes ?? []);
    this.bclById = this.bcls ? keyBy<BatchClaimLine>(this.bcls, 'id') : {};
    const revCodesForSelectedLines = this.getRevCodesForSelectedIbLines(
      operation?.ibData
    );
    this.replaceState({
      ...cloneDeep(initialState),
      denialCodes: this.denialCodes,
      batchClaimId: claim.id,
      operation: operation,
      revCodesForSelectedLines,
      type: operation.seedType,
      authorId,
      ready: true,
    });
  };

  setSeedType = (seedType: EditFindingState['type']) => {
    this.setState('type', seedType);
    // todo also af values?
  };

  getSeedType = (): EditFindingState['type'] => {
    return this.state.type;
  };

  isReview = (): boolean => {
    return !!this.state.operation && isReviewFindingOp(this.state.operation);
  };

  isReady = (): boolean => {
    return !!this.batchClaim && !!this.claim && !!this.findingModel;
  };

  getFinding = (): Finding | undefined => {
    return this.state.operation && isReviewFindingOp(this.state.operation)
      ? this.state.operation.finding
      : undefined;
  };

  setBatchClaimLineId = (id: UUID) => {
    this.setState('batchClaimLineId', id);

    if (!!id) {
      this.setState('revCode', this.bclById[id]?.revCode);
      this.setPaymentRate(id);
    }
  };

  getBatchClaimLineId = (): UUID | null | undefined => {
    return this.state.batchClaimLineId;
  };

  private setPaymentRate = (batchClaimLineId?: UUID | null) => {
    const id = batchClaimLineId ?? this.getBatchClaimLineId();
    const bcl = id && this.bclById[id];
    const pyRate =
      !!bcl && !!this.paymentRateUtility
        ? this.paymentRateUtility.getActiveLineRate(bcl) ?? 0
        : 0;
    this.setState('paymentRate', pyRate);
  };

  getPaymentRate = () => {
    return this.state?.paymentRate ?? 0;
  };

  getRevCodesForSelectedIbLines = (ibLines?: ItemizedBillLine[]) => {
    const revCodes = revCodesForSelectedIbLines(
      extractRevCodes(this.bcls ?? []),
      ibLines || []
    );
    return revCodes;
  };

  getIbLineInfo = () => {
    return this.state.adjustments;
  };

  private updateStrategyOnValuesChange = (
    changedValues: any,
    allValues: any
  ) => {
    this.editStrategy.onValuesChange(
      this.getCommandFunctionsForStrategy(),
      changedValues,
      allValues
    );
  };

  onValuesChange: ValueChangeHandler<EditFindingValues> = ({
    changedValues,
    allValues,
  }) => {
    // keep this log - very useful for debugging:
    // console.log('onValuesChange', {
    //   [changedValues.metadata
    //     ? 'MD.' + Object.keys(changedValues.metadata).join('.')
    //     : '' + changedValues.auditFindingValues
    //     ? 'AF.' + Object.keys(changedValues.auditFindingValues ?? {}).join('_')
    //     : '']: { changedValues, allValues },
    // });
    if (this.currentValuesRef) {
      this.currentValuesRef.current = {
        changedValues: { ...changedValues },
        allValues: { ...allValues },
      };
    }

    if (changedValues?.metadata?.batchClaimLineId) {
      this.setBatchClaimLineId(changedValues.metadata.batchClaimLineId);
    }
    this.updateStrategyOnValuesChange(changedValues, allValues);
  };

  onFieldsChange = (fields: any) => {
    fields?.forEach((f: any) => {
      const n = Array.isArray(f.name) ? f.name.join('.') : f.name;
      if (f.touched && !this.dirty.includes(n)) {
        this.dirty.push(n);
      }
    });
  };

  createMutationInput = (values: EditFindingValues) => {
    return this.editStrategy.createMutationInput(values, this.state);
  };

  packageMutationResponse = async (input: FindingInput, result: any) => {
    return this.editStrategy.packageMutationResponse(
      input,
      result,
      this.state,
      this.claimRefresher
    );
  };

  save = async (values: EditFindingValues): Promise<any> => {
    const input = this.createMutationInput(values);
    if (!!input) {
      const packagedPromise = input.isReview
        ? this.editStrategy
            .commitVersionMutation(this.findingModel, input)
            .then(result => this.packageMutationResponse(input, result))
        : this.editStrategy
            .commitCreateMutation(this.findingModel, input)
            .then(result => this.packageMutationResponse(input, result));

      return packagedPromise.then((packagedResult: any) => {
        console.log('%cpackagedResult ', 'color: orange', packagedResult);
        if (packagedResult.result.error) {
          return Promise.reject(packagedResult.result.error);
        }
        return packagedResult;
      });
    }
  };

  // n.b. must use closure, lambda (=>) or .bind() for this ref:
  public reducer = createReducer<EditFindingState>(
    (
      newState: EditFindingState,
      actionPath: string,
      action: GenericAction<EditFindingState, any>
    ) => {
      // keep this log - it's very useful for state debugging
      // console.log('%c[%s]', 'color: green', actionPath || '<root>', {
      //   inf: {
      //     actionPath,
      //     newState,
      //     action,
      //   },
      // });
      this.state = newState;
      this.stateChangeSubject.next(newState);
    }
  );
}

const initialState: EditFindingState = {
  ready: false,
  type: AuditFindingSeedType.UB_CLAIM_LINE,
  operation: null,
  authorId: null,
  paymentRate: 0,
  defaultValues: {
    metadata: {
      type: AuditFindingSeedType.UB_CLAIM_LINE,
    },
    auditFindingValues: {},
  },
  denialCodes: {
    loaded: false,
    fetching: false,
    codes: [],
    byKey: {},
    byType: {},
  },
  revCodesForSelectedLines: {
    other: [],
    revCodeBclInfos: [],
    onSelectedIbLines: [],
    byId: {},
    recommended: [],
  },
};
