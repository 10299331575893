import { graphql } from '../../../../gql';
import { useQuery } from 'urql';
import { FragmentType, useFragment } from '../../../../gql/fragment-masking';

export const denialCodesQueryDoc = graphql(`
  query auditFindingRuleTypes {
    auditFindingRuleTypes {
      nodes {
        type
        claimDataType
        deprecated
        displayName
        description
        rationaleTemplate
      }
    }
  }
`);
