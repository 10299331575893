// @ts-ignore
import { AlaffiaClient } from '@alaffia-technology-solutions/alaffia-client-sdk';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { graphCache } from '../graphCache';

let alaffiaClient: AlaffiaClient | null = null;

export const AlaffiaClientContext = createContext<AlaffiaClient>(
  // {} as AlaffiaClient
  (() => {
    const alaC = new AlaffiaClient({
      apiUrl: `${process.env.REACT_APP_API_URL}`,
      graphqlOptions: {
        cacheExchangeOptions: graphCache,
        includeDevtoolsExchange: true,
      },
      logLevel: 'info',
    });
    // alaC.mocksEnabled = true;
    alaffiaClient = alaC;
    return alaC;
  })()
);

export const getAlaffiaClient = () => {
  return alaffiaClient;
};

export const AlaffiaContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  /*
   * Note that the loglevel package (or our use of it?) seems to end up with duplicate loggers,
   * if we instantiate the client twice, presumably because it's using some global variables
   * that don't get reset... We'll need to either resolve that at it's core (maybe using the lib's
   * no-conflict setting helps get an instance?) -- or we can keep this a singleton and implement
   * logic to change endpoint... e.g. logout, cleanup, and reset to new api server url...
   *
   * Anyway, for now, we'll play the leave-me-alone-i-know-what-im-doing game of not instantiating
   * the client twice when `useEffect` et al re-run in dev mode:
   */
  // const [client, setClient] = useState<AlaffiaClient | null>(null);
  // useEffect(() => {
  //   if (!client) {
  //     setClient((existing: AlaffiaClient | null) => {
  //       if (existing) {
  //         return existing;
  //       }
  //       const c = new AlaffiaClient({
  //         apiUrl: `${process.env.REACT_APP_API_URL}`,
  //         graphqlOptions: {
  //           cacheExchangeOptions: graphCache,
  //           includeDevtoolsExchange: true,
  //         },
  //         logLevel: 'info',
  //       });
  //       // TODO cannot currently do this in the real app as it intercepts everything
  //       // c.mocksEnabled = true;
  //       console.log('[AlaffiaContextProvider] instantiated client', c);
  //       return c;
  //     });
  //   }
  // }, [client]);

  return <>{children}</>;

  // return (
  //   <AlaffiaClientContext.Provider value={client!}>
  //     {children}
  //   </AlaffiaClientContext.Provider>
  // );
};
