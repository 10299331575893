import {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  KeyCreatorParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { FindingInfo, IBGridLine } from './types';
import { LineClampedCell } from '../../../../../common/table/ag/cell/lineClampedCell';
import accounting from 'accounting-js';
import { findingToAF } from '../../../queries/claim/interimUtils';
import { createDenialCodeRowTags } from '../../shared';
import { toDenialCodesTagMap } from '../../util';
import dayjs from 'dayjs';
import { Finding } from '../../../../../gql/graphql';
import { DefaultColumn } from '../../../../../common/table/ag/cols/defaultColumn';
import { lineClampedCellRenderer } from '../../../../../common/table/ag/cell/lineClampedCellRenderer';
import { DenialCodeTags } from '../../../shared/components/claimLine/denialCodeTags';

// TODO: timezones and utc?
const dateFormatter: ValueFormatterFunc<IBGridLine> = <T,>(
  params: ValueFormatterParams<T>
) => params?.value && dayjs(params.value).toDate().toLocaleDateString();

const currencyFormatter: ValueFormatterFunc<IBGridLine> = <T,>(
  params: ValueFormatterParams<T>
) => params?.value && accounting.formatMoney(params.value);

const aggImproperPaymentCost = (findings: Finding[]) =>
  (!!findings && Array.isArray(findings) ? findings : [])
    .filter((f: Finding) => !!f?.accepted)
    .map((f: Finding) => f?.improperPaymentCost ?? 0)
    .reduce((prev: number, curr: number) => {
      return prev + (curr ?? 0);
    }, 0);

const aggFindingDenialCodes = (findings: Finding[]) =>
  (!!findings && Array.isArray(findings) ? findings : [])
    .filter(f => !!f)
    .reduce((acc: string, f: Finding) => {
      // TODO -- there's no `context` passed for comparator, so we should do direct
      //  lookup to DC in the conversion to IbGridLine and augment the row with
      //  this collection rather than hacking to get the short name off the ruleType
      const rt = f?.ruleType ?? '';
      if (rt.startsWith('UB_CLAIM_LINE_') || rt.startsWith('IB_CLAIM_LINE_')) {
        acc += rt.substring(14) + ' ';
      } else {
        // UB_CLAIM_
        acc += rt.substring(9) + ' ';
      }
      return acc;
    }, '');

const aggFindingLabels = (findingInfos: FindingInfo[]) =>
  Array.from(
    (!!findingInfos && Array.isArray(findingInfos) ? findingInfos : [])
      .filter(f => !!f)
      .reduce((acc: Set<string>, f: FindingInfo) => {
        if (!acc.has(f.label)) {
          acc.add(f.label);
        }
        return acc;
      }, new Set<string>())
  )
    .sort()
    .join(' ');

const aggFindingSearchable = (findingInfos: FindingInfo[]) =>
  (!!findingInfos && Array.isArray(findingInfos) ? findingInfos : [])
    .filter(f => !!f)
    .reduce((acc: string[], f: FindingInfo) => {
      acc.push(f.searchText);
      return acc;
    }, [])
    // the search seems to tokenize words and search AND-ed terms
    // across all search input so using the † or some other unlikely
    // (or unprintable) character here (as a sep btwn findings) is
    // currently moot from the search perspective, but useful in debugging...
    .join(String.fromCharCode(0x2020)); // '†'

export const ibTableDefaultColumnDef: ColDef<IBGridLine> = {
  ...DefaultColumn,
  cellRenderer: lineClampedCellRenderer,
};

export const getIbTableColumns: (
  showFindings: boolean,
  readOnly: boolean
) => ColDef<IBGridLine>[] = (showFindings, readOnly) => [
  {
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      cellStyle: {
        textAlign: 'left',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    width: 40,
    minWidth: 34,
    headerName: 'Expand/Collapse',
    headerTooltip: 'Expand/Collapse',
    suppressMenu: true,
    filter: false,
    enableRowGroup: false,
    showDisabledCheckboxes: false,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation"></div>',
    },
  },
  {
    headerName: 'Line Number',
    headerTooltip: 'Line Number',
    field: 'lineNumber',
    valueFormatter: ({ value }: { value: any }) => value ?? '',
    width: 35,
    cellRenderer: undefined,
    cellStyle: {
      textAlign: 'left',
      paddingLeft: '4px',
      paddingRight: '4px',
      lineHeight: '1.25rem',
      fontSize: '0.65rem',
      color: 'gray',
      display: 'flex',
      alignItems: 'center',
      // verticalAlign: 'baseline',
    },
    suppressMenu: true,
    enableRowGroup: false,
    showDisabledCheckboxes: false,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        // '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        // '    ** <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        // '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    },
  },
  {
    headerCheckboxSelection: showFindings && !readOnly,
    checkboxSelection: true,
    showDisabledCheckboxes: false,
    headerCheckboxSelectionFilteredOnly: true,
    headerName: 'Checkbox Selection',
    headerTooltip: 'Checkbox Selection',
    enableRowGroup: false,
    filter: false,
    width: 42,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation"></div>',
    },
  },

  {
    headerName: 'Item',
    headerTooltip: 'Item Number',
    field: 'itemNumber',
    width: 110,
  },
  {
    headerName: 'Rev',
    headerTooltip: 'Rev Code',
    field: 'revCode',
    width: 100,
  },
  {
    headerName: 'Description',
    headerTooltip: 'Description (Service Name)',
    field: 'description',
    minWidth: 150,
    flex: 3,
    suppressSizeToFit: true,
  },
  {
    headerName: 'DoS',
    headerTooltip: 'Date of Service (start date)',
    valueFormatter: dateFormatter,
    field: 'dateOfService',
    width: 120,
    aggFunc: undefined,
    getQuickFilterText: dateFormatter,
  },
  {
    headerName: 'Units',
    headerTooltip: 'Units Administered',
    type: 'rightAligned',
    field: 'units',
    width: 90,
    aggFunc: 'sum',
  },
  {
    headerName: 'Total',
    headerTooltip: 'Total Charge for Units Administered',
    valueFormatter: currencyFormatter,
    type: 'rightAligned',
    field: 'totalCharge',
    width: 120,
    aggFunc: 'sum',
    getQuickFilterText: currencyFormatter,
  },
  {
    headerName: 'Proc',
    headerTooltip: 'Procedure Code',
    field: 'procedureCode',
    width: 100,
  },
  {
    headerName: 'Proc Mod',
    headerTooltip: 'Procedure Code Modifier',
    field: 'procedureCodeModifier',
    width: 90,
  },
  ...(!showFindings
    ? []
    : [
        {
          headerName: 'Adj. Amount',
          headerTooltip: 'Adjustment Amount',
          type: 'rightAligned',
          field: 'findings',
          width: 120,
          getQuickFilterText: (
            params: GetQuickFilterTextParams<IBGridLine, Finding[]>
          ) =>
            accounting.formatMoney(
              aggImproperPaymentCost(params?.data?.findings ?? [])
            ),
          comparator: (a: Finding[], b: Finding[]) => {
            return aggImproperPaymentCost(a ?? []) >
              aggImproperPaymentCost(b ?? [])
              ? 1
              : -1;
          },
          suppressMenu: true,
          filter: false,
          cellRenderer: (props: ICellRendererParams<any, any>) => {
            return (props?.data?.findings?.length ?? 0) > 0 ? (
              <LineClampedCell>
                {accounting.formatMoney(
                  aggImproperPaymentCost(props?.data?.findings ?? [])
                )}
              </LineClampedCell>
            ) : (
              ''
            );
          },
        },
        {
          headerName: 'Denial Code',
          headerTooltip: 'Denial Code',
          field: 'findingInfo',
          minWidth: 120,
          flex: 2,
          suppressSizeToFit: true,
          getQuickFilterText: (
            params: GetQuickFilterTextParams<IBGridLine, FindingInfo[]>
          ) => aggFindingSearchable(params?.value ?? []),
          comparator: (a: FindingInfo[], b: FindingInfo[]) =>
            aggFindingLabels(a ?? []) > aggFindingLabels(b ?? []) ? 1 : -1,
          filter: 'agSetColumnFilter',
          filterParams: {
            keyCreator: (params: KeyCreatorParams<FindingInfo>) =>
              params.value?.label ?? null,
            valueFormatter: (params: ValueFormatterParams<FindingInfo>) =>
              params.value?.label ?? null,
          },
          cellRenderer: (
            props: ICellRendererParams<IBGridLine, FindingInfo[]>
          ) => {
            return (
              <LineClampedCell>
                <DenialCodeTags findingInfos={props.value} />
              </LineClampedCell>
            );
          },
        },
      ]),
];
