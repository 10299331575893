import { ICellRendererParams } from 'ag-grid-community';
import { LineClampedCell } from './lineClampedCell';
import React from 'react';

const _lineClampedCellRenderer = <T,>(props: ICellRendererParams<T, any>) => (
  <LineClampedCell>
    {props.valueFormatted ?? false
      ? props.valueFormatted
      : props.value
        ? props.value
        : props.getValue
          ? props.getValue()
          : ''}
  </LineClampedCell>
);

export const lineClampedCellRenderer = React.memo(_lineClampedCellRenderer);
