import {
  CheckOutlined,
  EditOutlined,
  CloseOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { parseGroupedAggregatesQueryResIntoProgessStatProps } from '../../claimStateUtil';

const workflow = {
  auditProgress: {
    stateTypeLabel: 'Workflow',
    defaultLabel: 'No Claims in workflow state',
    tooltipLabel: 'Overall workflow status for all claims',
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: WORKFLOW_STATE) on
     *     the workflowClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: ({ permissions }) => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `workflow: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
          ]
        }
      ) {
        groupedAggregates(groupBy: WORKFLOW_CLAIM_STATE) {
          keys
          distinctCount {
            id
          }
        }
      }`;
      return query;
    },

    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e TO_DO)): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims,
        workflow: { groupedAggregates },
      },
      permissions,
      userType,
      queryableStates,
      defaultStates,
      states,
    }) => {
      let sumOfAggregates = 0;
      const formattedQueryResults = {
        CLAIM_RECEIVED: 0,
        TO_DO: 0,
        IN_PROGRESS: 0,
        COMPLETED: 0,
        CLOSED: 0,
      };
      groupedAggregates.forEach(({ keys: [keyName, _], distinctCount }) => {
        sumOfAggregates += parseInt(distinctCount.id);
        formattedQueryResults[keyName] = parseInt(distinctCount.id);
      });
      return parseGroupedAggregatesQueryResIntoProgessStatProps({
        totalClaims,
        sumOfAggregates,
        formattedQueryResults,
        permissions,
        userType,
        stateType: 'workflow',
        queryableStates,
        defaultStates,
        states,
      });
    },
  },
  queryableStates: [
    'CLAIM_RECEIVED',
    'TO_DO',
    'IN_PROGRESS',
    'COMPLETED',
    'CLOSED',
  ],
  dataType: 'enum',
  defaultStates: ['CLAIM_RECEIVED'],
  onTableQuery: 'batchClaims',
  states: {
    // Default State
    CLAIM_RECEIVED: {
      name: userType => {
        return 'Claim Received';
      },
      primaryColor: '#40a9ff',
      strokeColor: 'white',
      tagColor: 'blue',
      icon: <PlusOutlined style={{ color: '#40a9ff', fontSize: '120%' }} />,
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'New Claim(s) that have been received';
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }) =>
        workflowClaimState === 'CLAIM_RECEIVED',
      timelineEventRendering: {
        description: userType => 'Claim Received',
        renderingAllowed: event => false,
      },
    },
    TO_DO: {
      name: userType => {
        return {
          PROVIDER: 'Set For Review',
          PAYER: 'Set For Review',
          ADMINISTRATOR: 'To Do',
          AUDITOR: 'To Do',
        }[userType];
      },
      primaryColor: 'lightgray',
      strokeColor: 'white',
      tagColor: null, // makes the tag use the default grey in antd
      icon: (
        <PlayCircleOutlined style={{ color: 'lightgray', fontSize: '120%' }} />
      ),
      description: userType => {
        // different userTypes don't make a difference so we just return
        return {
          PROVIDER: 'Claim(s) set for review',
          PAYER: 'Claim(s) set for review',
          ADMINISTRATOR: 'Claim(s) set to To Do',
          AUDITOR: 'Claim(s) set to To Do',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }) =>
        workflowClaimState === 'TO_DO',
      timelineEventRendering: {
        description: userType => {
          return {
            PROVIDER: 'Claim is set for review',
            PAYER: 'Claim is set for review',
            ADMINISTRATOR: 'Claim is set to To Do',
            AUDITOR: 'Claim is set to To Do',
          }[userType];
        },
        renderingAllowed: event => true,
      },
    },
    IN_PROGRESS: {
      name: userType => {
        return {
          PROVIDER: 'Under Review',
          PAYER: 'Under Review',
          ADMINISTRATOR: 'In Progress',
          AUDITOR: 'In Progress',
        }[userType];
      },
      primaryColor: '#faad14',
      strokeColor: 'white',
      tagColor: 'gold',
      icon: <EditOutlined style={{ color: '#faad14', fontSize: '120%' }} />,
      description: userType => {
        return {
          PROVIDER: 'Claim(s) is under review',
          PAYER: 'Claim(s) is under review',
          ADMINISTRATOR: 'Claim(s) is In Progress',
          AUDITOR: 'Claim(s) is In Progress',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }) =>
        workflowClaimState === 'IN_PROGRESS',
      timelineEventRendering: {
        description: userType => {
          return {
            PROVIDER: 'Claim set to under review',
            PAYER: 'Claim set to under review',
            ADMINISTRATOR: 'Claim set to In Progress',
            AUDITOR: 'Claim set to In Progress',
          }[userType];
        },
        renderingAllowed: event => true,
      },
    },
    COMPLETED: {
      name: userType => {
        return {
          PROVIDER: 'Reviewed',
          PAYER: 'Reviewed',
          ADMINISTRATOR: 'Completed',
          AUDITOR: 'Completed',
        }[userType];
      },
      primaryColor: '#52c41a',
      strokeColor: 'white',
      tagColor: 'green',
      icon: <CheckOutlined style={{ color: '#52c41a', fontSize: '120%' }} />,
      description: userType => {
        return {
          PROVIDER: 'Review Complete for Claim(s)',
          PAYER: 'Review Complete for Claim(s)',
          ADMINISTRATOR: 'Claim(s) is Complete',
          AUDITOR: 'Claim(s) is Complete',
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }) =>
        workflowClaimState === 'COMPLETED',
      timelineEventRendering: {
        description: userType => {
          return {
            PROVIDER: 'Review Complete for Claim',
            PAYER: 'Review Complete for Claim',
            ADMINISTRATOR: 'Claim Completed',
            AUDITOR: 'Claim Completed',
          }[userType];
        },
        renderingAllowed: event => true,
      },
    },
    CLOSED: {
      name: userType => {
        // different userTypes don't make a difference so we just return
        return 'Archived';
      },
      primaryColor: '#fa541c', //#fa541c
      strokeColor: 'white',
      tagColor: 'volcano',
      icon: <CloseOutlined style={{ color: '#fa541c', fontSize: '120%' }} />,
      description: userType => {
        // different userTypes don't make a difference so we just return
        return 'Claim(s) have been Archived';
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }) =>
        workflowClaimState === 'CLOSED',
      timelineEventRendering: {
        description: userType => 'Claim Archived',
        renderingAllowed: event => true,
      },
    },
  },
  filterBuilder: values => {
    /* 
      POSSIBLE VALUES = [CLAIM_RECEIVED, TO_DO, IN_PROGRESS, COMPLETED, CLOSED]

      filter required for each value:
      CLAIM_RECEIVED
        - workflowClaimStatesExist = false ONLY

     TO_DO | IN_PROGRESS | COMPLETED | CLOSED 
      - workflowClaimStatesExist: true   
        -- and --
      - workflowClaimState = TO_DO | IN_PROGRESS | COMPLETED | CLOSED 
    */

    if (values.length === 1) {
      if (values.includes('CLAIM_RECEIVED'))
        return { workflowClaimStatesExist: false };
      else
        return {
          workflowClaimStatesExist: true,
          batchClaimStateById: {
            workflowClaimState: { equalTo: values[0] },
          },
        };
    } else if (values.includes('CLAIM_RECEIVED')) {
      return {
        or: [
          { workflowClaimStatesExist: false },
          {
            and: [
              { workflowClaimStatesExist: true },
              {
                batchClaimStateById: {
                  workflowClaimState: {
                    in: values.filter(v => v !== 'CLAIM_RECEIVED'),
                  },
                },
              },
            ],
          },
        ],
      };
    } else
      return {
        workflowClaimStatesExist: true,
        batchClaimStateById: {
          workflowClaimState: {
            in: values,
          },
        },
      };
  },
};

export { workflow };
