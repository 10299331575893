/** @type {import('tailwindcss').Config} */
const antdTokens = require('./tailwind-antd-tokens');

module.exports = {
  prefix: '_',
  darkMode: 'class',
  corePlugins: {
    preflight: false,
  },
  content: ['./src/**/*.{html,js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors: {
        ...antdTokens.colors,
      },
      antd: {
        dark: {
          ...antdTokens.tokens.dark.colors,
          ...antdTokens.tokens.dark.tokens,
        },
        light: {
          ...antdTokens.tokens.light.colors,
          ...antdTokens.tokens.light.tokens,
        },
      },
      gridTemplateColumns: {
        // Simple 16 column grid
        24: 'repeat(24, minmax(0, 1fr))',
      },
      gridColumnStart: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        18: '17',
        19: '18',
        20: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
        24: '24',
      },
      gridColumnEnd: {
        13: '13',
        14: '14',
        15: '15',
        16: '16',
        18: '17',
        19: '18',
        20: '19',
        20: '20',
        21: '21',
        22: '22',
        23: '23',
        24: '24',
      },
    },
  },
  plugins: [require('tailwind-scrollbar')({ nocompatible: true })],
};
