import { useContext, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './login';
import { Dashboard } from '../dashboard/dashboard';
import { Users } from '../user/users';
import { Profile } from '../user/profile';
import { AuditWorkspace } from '../audit/auditWorkspace';
import { Reports } from '../reports/reports';
import { Search } from '../search/search';
import { SearchResults } from '../search/searchResults';
import { Queue } from '../queues/queue';
import { Queues } from '../queues/queues';
import { UserContext } from '../context/user';
import { Colors } from './design/colors';
import { Playground } from '../../common/playground/playground';
import tw from 'twin.macro';
import { ErrorBoundary } from '../../common/error/errorBoundary';

const Routes = ({
  // auth,
  loggedIn,
  // setDocumentationViewerProps,
  searchBarVisible,
}) => {
  const { permissions, id, search } = useContext(UserContext) || {
    permissions: {
      // homeRoute: '/auth/login',
      routes: ['/'],
    },
  };

  const createSetDocumentationViewerProps = routesCtx => {
    return props => {
      console.error(
        `routes::${routesCtx}::setDocumentViewerProps DEPRECATED!`,
        props
      );
    };
  };

  // add dev routes if they exist
  // TODO: lazy load dev routes to split bundle or restructure build so that dev routes are not included in prod build
  const devRoutes = useMemo(() => {
    const dr = (process.env.REACT_APP_DEV_ROUTES ?? '')?.split(',');
    dr.length > 0 && console.log('Dev routes enabled', dr);
    return dr;
  }, []);
  const permittedRoutes = permissions.routes.concat(devRoutes);

  // assign the logged in user's allowed routes
  const routes = {
    '/users': <Users />,
    // '/auth/login': <Login />,
    '/user/profile': <Profile />,
    '/workspace/:batchClaimId': (
      <AuditWorkspace
        setDocumentationViewerProps={createSetDocumentationViewerProps(
          'AuditWorkspace'
        )}
      />
    ),
    '/claims/:tableName/:searchFilter': (
      <SearchResults
        setDocumentationViewerProps={createSetDocumentationViewerProps(
          'SearchResults:tablename'
        )}
      />
    ),
    '/search/:searchFilter': (
      <SearchResults
        setDocumentationViewerProps={createSetDocumentationViewerProps(
          'SearchResults'
        )}
      />
    ),

    '/queues': <Queues />,
    '/queue/:queueId': (
      <Queue
        setDocumentationViewerProps={createSetDocumentationViewerProps('Queue')}
      />
    ),
    '/reports': <Reports />,
    '/home': <Dashboard />,

    // routes available in dev mode only (per env check above)
    '/colors': <Colors />,
    '/playground': <Playground />,

    // catch-all
    '/': <Redirect to={permissions.homeRoute} />,

    // FIXME: (AT) - Assess the following routes / components
    // Notifications are unimplemented today, but coming!
    // '/user/notifications': <Notifications />,
  };

  return (
    <Switch>
      {Object.entries(routes)
        .filter(([route]) => permittedRoutes.includes(route))
        .map(([path, component]) => (
          <Route key={path} path={path}>
            {/*{path === '/auth/login' || loggedIn ? (*/}
            <div
              data-id="routes-wrapper"
              tw="_w-full _h-full _min-h-full _flex _flex-col"
            >
              <ErrorBoundary boundaryDescription={`Route [${path}]`}>
                <div>
                  {/*{path !== '/auth/login' && search.canSearchClaims ? (*/}
                  {loggedIn && search.canSearchClaims ? (
                    // this condition prevents app from crashing as Search uses the UserContext
                    <Search searchBarVisible={searchBarVisible} />
                  ) : null}
                </div>
                <div tw="_grow">{component}</div>
              </ErrorBoundary>
            </div>
            {/*: (*/}
            {/*  <Redirect to="/auth/login" />*/}
            {/*)*/}
            {/*}*/}
          </Route>
        ))}
    </Switch>
  );
};

export { Routes };
