import { Button } from 'antd';
import { createPortal } from 'react-dom';
import React, { useCallback, useContext, useRef } from 'react';
import { PdfViewerContext } from '../../../common/pdf/pdfViewerProvider';
import tw, { css } from 'twin.macro';
import { FileSearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../context/user';
import { PdfViewerControlsEx } from '../../../common/pdf/types';
import { useLog } from '../../../common/log/useLog';

export const ViewDocumentsButton: React.FC<{ claimId: string }> = ({
  claimId,
}) => {
  const log = useLog('viewDocumentsButton');
  const pdfCtx = useContext(PdfViewerContext);
  const user = React.useContext(UserContext) as unknown as Record<string, any>;

  const [pViewer, setPViewer] = React.useState<PdfViewerControlsEx | null>(
    null
  );

  const showViewer = useCallback(() => {
    const viewer = pdfCtx.getViewer('documentViewer');
    viewer.setIsOpen(true, claimId);
  }, [pdfCtx]);

  return (
    <>
      <Button
        id="view-documents-button"
        icon={<FileSearchOutlined />}
        onClick={() => {
          const { viewDocs, viewDocList } = user.permissions;
          if (viewDocs && viewDocList) {
            showViewer();
          } else if (viewDocList) {
            // TODO
            throw new Error('TODO');
            // setViewDocumentNamesModalVisible(true);
          }
        }}
        css={{
          padding: '0 7px',
          fontSize: '12px',
          lineHeight: '20px',
          height: 'auto',
        }}
      >
        View Documents
      </Button>
    </>
  );
};
