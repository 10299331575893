import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import { useFindingModalVm } from './useFindingModalVm';
import React, { FC, useCallback } from 'react';
import { FindingEditor } from '../useFindingEditor';
import tw, { css } from 'twin.macro';
import { RevCode } from '../form/fields/revCode';
import { DenialCode } from '../form/fields/denialCode';
import { Rationale } from '../form/fields/rationale';
import { SeedType } from '../form/fields/seedType';
import { useAntdFormAdapter } from '../form/useAntdFormAdapter';
import { EditFindingValues } from '../viewController/types';
import { FormProps } from 'antd/es/form/Form';
import { IbLineAdjustments } from '../form/sections/ibLineAdjustments';

const formLayout: Partial<FormProps> = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
  labelAlign: 'left',
};
const formLayoutWide: Partial<FormProps> = {
  ...formLayout,
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const formLayoutFull: Partial<FormProps> = {
  ...formLayout,
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout: Partial<FormProps> = {
  wrapperCol: { offset: 8, span: 16 },
};

export type FindingFormProps = {
  findingEditor: FindingEditor;
  onCancel: () => void;
};
export const FindingForm: FC<FindingFormProps> = ({
  findingEditor,
  onCancel,
}) => {
  const [form] = Form.useForm<EditFindingValues>();
  const [saving, setSaving] = React.useState(false);
  const {
    //
    formAdapter,
    valueChangeHandler,
    fieldChangeHandler,
  } = useAntdFormAdapter<EditFindingValues>(form);

  const {
    ready,
    type,
    initialValues,
    setFields,
    onFinish,
    revCodesForSelectedLines,
    adjustments,
    ibLines,
    denialCodes,
  } = useFindingModalVm(findingEditor, formAdapter, onCancel);

  const onReset = () => {
    form.resetFields();
  };

  const finish = useCallback(
    (values: any) => {
      setSaving(true);
      onFinish(values)
        .then(() => {
          setSaving(false);
        })
        .catch(e => {
          setSaving(false);
        });
    },
    [onFinish]
  );

  return !ready ? (
    <Spin></Spin>
  ) : (
    <Form<EditFindingValues>
      {...formLayout}
      form={form}
      name="finding-form-modal"
      css={css({ '& .ant-form-item': tw`_mb-4` })}
      onValuesChange={valueChangeHandler}
      onFieldsChange={fieldChangeHandler}
      onFinish={finish}
    >
      <SeedType
        dataKey="metadata.type"
        initialValues={initialValues}
        formLayout={formLayout}
      ></SeedType>
      <DenialCode
        dataKey="auditFindingValues.auditFindingRuleType"
        initialValues={initialValues}
        seedType={type}
        denialCodes={denialCodes}
        formLayout={formLayout}
      ></DenialCode>
      <RevCode
        dataKey="metadata.batchClaimLineId"
        revCodes={revCodesForSelectedLines}
        initialValues={initialValues}
        formLayout={formLayout}
      ></RevCode>

      {/*TODO add sections / switch to handle types (UB/IB/CSV) */}

      <IbLineAdjustments
        initialValues={initialValues}
        adjustments={adjustments}
        ibLines={ibLines || []}
        setFields={setFields}
        formLayout={formLayout}
      ></IbLineAdjustments>

      <Rationale
        dataKey="auditFindingValues.improperPaymentReason"
        initialValues={initialValues}
        formLayout={formLayoutFull}
      ></Rationale>

      <div tw="_flex">
        <div></div>
        <div tw="_ml-auto _space-x-4">
          <Button
            loading={saving}
            disabled={saving}
            htmlType="button"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            loading={saving}
            disabled={saving}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};
