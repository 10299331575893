import {
  CreateBatchClaimLineItemValuesInput,
  ItemizedBillLine,
} from '../../../../../gql/graphql';

type IbLineToBcliInputFn = {
  ({
    revCode,
    ibLine,
    amountCharged,
    originalBilledAmount,
  }: {
    revCode: string;
    ibLine: ItemizedBillLine;
    amountCharged: number;
    originalBilledAmount: number;
  }): CreateBatchClaimLineItemValuesInput;
};
export const ibLineToBcliInput: IbLineToBcliInputFn = ({
  revCode,
  ibLine,
  amountCharged,
  originalBilledAmount,
}) => ({
  alaRowId: ibLine.alaRowId,
  dateOfServiceStart: ibLine.dateOfService,
  description: ibLine.description ?? '',
  ibLineId: ibLine.id,
  ibLineVersionFingerprint: ibLine.versionFingerprint,
  itemNumber: ibLine.itemNumber,
  lineNumber: ibLine.lineNumber,
  // TODO do we need ever to repeat nnIbLineValues here?
  originalBilledAmount,
  procedureCode: ibLine.procedureCode,
  procedureCodeModifier: ibLine.procedureCodeModifier,
  revCode,
  unitCharge: amountCharged,
  units: ibLine.units ?? 0,
});
