import { batchClaimState } from '../../../fragments';
import { gql, useMutation, useQuery } from 'urql';
import { Menu, message } from 'antd';
import { batchClaimsNodeKey, claimMenuItemDetails } from '../util';

// mutation to update workflow state
const setNegotiationMutation = gql`
  mutation setNegotiationMutation(
    $batchClaimIds: [UUID!]!
    $state: NegotiationClaimStateEnum!
  ) {
    setNegotiationClaimStates(
      input: { negotiationState: $state, batchClaimIds: $batchClaimIds }
    ) {
      negotiationClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const typeActions = {
  setNegotiating: {
    title: 'In Negotiation',
    value: 'NEGOTIATING',
    actionCriteria: ({
      batchClaimState: {
        reported,
        invoiceClaimState,
        negotiationClaimState,
        clientSignOffClaimState,
        providerSignOffClaimState,
      },
    }) => {
      const satisfied =
        // must be reported
        reported &&
        // claim isn't already in negotiation
        negotiationClaimState !== 'NEGOTIATING' &&
        // both client and provider have not signed off
        clientSignOffClaimState !== 'CLIENT_SIGN_OFF' &&
        providerSignOffClaimState !== 'PROVIDER_SIGN_OFF' &&
        // cant set a paid claim to in negotiation
        invoiceClaimState !== 'PAID';
      return { satisfied };
    },
  },
  setNotNegotiating: {
    title: 'Negotiation Complete',
    value: 'NOT_NEGOTIATING',
    actionCriteria: ({ batchClaimState: { negotiationClaimState } }) => {
      const satisfied =
        /* 
          criteria is dependent on othe states -->
            cannot set to NOT_NEGOTIATING unless it is not NOT_NEGOTIATING
            however the default state of a claim is NOT_NEGOTIATING and in order to exit NOT_NEGOTIATING
            the criteria of SENT and NEGOTIATING must be made which is more stringent
        */
        negotiationClaimState !== 'NOT_NEGOTIATING';
      return { satisfied };
    },
  },
};

const actionSubMenuItem = ({
  action,
  value,
  batchClaims,
  actionCriteria,
  setNegotiation,
  title,
  setLoading,
}) => {
  const actionClaims = batchClaims.filter(i => actionCriteria(i).satisfied);

  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setNegotiation({
          state: value,
          batchClaimIds: actionClaims.map(({ id }) => id),
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          message.error(errorMessage);
        } else {
          message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }) => {
  const [{ fetching, error }, setNegotiation] = useMutation(
    setNegotiationMutation
  );
  return (
    <Menu.SubMenu
      key={`negotiation-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Negotiation"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions['negotiation'].includes(action)
        )
        .map(([action, { ...actionProps }]) =>
          actionSubMenuItem({
            action,
            batchClaims,
            setNegotiation,
            setLoading,
            ...actionProps,
          })
        )}
    </Menu.SubMenu>
  );
};

const negotiation = {
  title: 'Negotiation',
  subMenu: actionSubMenu,
};

export { negotiation };
