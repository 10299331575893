import { SetupWorker } from 'msw';
import { isObject } from 'lodash';
import { SetupServer } from 'msw/node';

export const isMswWorker = (
  mswSetupResult: any
): mswSetupResult is SetupWorker => {
  return (
    isObject(mswSetupResult) &&
    'start' in mswSetupResult &&
    'stop' in mswSetupResult
  );
};

export const isMswServer = (
  mswSetupResult: any
): mswSetupResult is SetupServer => {
  return (
    isObject(mswSetupResult) &&
    'listen' in mswSetupResult &&
    'close' in mswSetupResult
  );
};
